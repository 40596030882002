import React, { useEffect, useState } from 'react';
import { Container, List, Urgente } from './styles';
import { useParams, useNavigate } from "react-router-dom";
import loadingPhoto from "./../../../images/loading.gif"
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { TbUrgent } from "react-icons/tb"
import { format } from 'date-fns';

export default function HomeTecnicos() {

    const navigate = useNavigate();
    const { id } = useParams()
    const [loading, setLoading] = useState(true)
    const [orders, setOrders] = useState([])

    useEffect(() => {
        loadingOSs()
    }, [])

    async function loadingOSs() {
        await api.get(`/orders-open`).then((response) => {
            response.data.map((item) => {
                item.totalServices = 0
                item.items.map((data) => {
                    item.totalServices += data.amount
                })
            })
            setOrders(response.data)
        }).catch(({ response }) => {
            if (response) {
                if (response.data) {
                    if (response.data.message) {
                        toast.warn(response.data.message)
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            } else {
                toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
        })
        setLoading(false)
    }

    return (
        <Container>
            {
                loading ? (<img src={loadingPhoto} style={{ background: "transparent", height: 50, width: 50 }} />

                ) : (
                    <>
                        <h1>{orders.length} Solicitações em aberto</h1>
                        <List>
                            {orders.length == 0 && (
                                <h4 style={{ marginTop: 50, fontWeight: 500, width: "80%", textAlign: "center" }}>Nenhuma Ordem de Serviço em aberto no momento</h4>
                            )}
                            {orders.map((item) => {
                                return (
                                    <div>
                                        <div style={{ position: "relative" }}>
                                            <p style={{ fontSize: 12, marginBottom: 5 }}>Aberta em {format(new Date(item.create_at), "dd/MM/yyyy")}</p>
                                            <p style={{ display: "flex" }}>OS com {item.totalServices} serviço{item.totalServices > 1 && "s"} em aberto do setor {item.sector}</p>
                                            <button onClick={() => {
                                                navigate("/tecnicos/os/" + item.id)
                                            }}>
                                                Ver detalhes
                                            </button>
                                            <Urgente style={{ display: "flex", opacity: item.urgent ? 1 : 0, marginLeft: 12, background: "#eb4034", flexDirection: "row", width: item.urgent ? 100 : 1 }}>
                                                <TbUrgent color="#FFF" size={22} style={{ marginRight: 5 }} />
                                                <p>Urgente</p>
                                            </Urgente>
                                        </div>
                                    </div>
                                )
                            })}
                        </List>
                    </>
                )
            }



        </Container >
    )
} 