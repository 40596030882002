import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "./styles";
import logo from "../../images/logo.jpeg"
import { MdMenu, MdOutlineClose } from "react-icons/md"
import { useAuth } from "../../hooks/AuthContext";

export default function HeaderAdmin() {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [openMenu, setOpenMenu] = useState(false)

    return (
        <Container active={openMenu}>
            <img src={logo} onClick={() => {
                navigate(`/${user.type}/home`)
            }} />
            <div>
                <a onClick={() => {
                    setOpenMenu(false)
                    navigate(`/${user.type}/home`)
                }}>Home</a>
                <a onClick={() => {
                    setOpenMenu(false)
                    navigate(`/${user.type}/tecnicos`)
                }}>Técnicos</a>
                <a onClick={() => {
                    setOpenMenu(false)
                    navigate(`/${user.type}/clientes`)
                }}>Franqueados</a>
                <a onClick={() => {
                    setOpenMenu(false)
                    navigate(`/${user.type}/services`)
                }}>Serviços</a>
                <a onClick={() => {
                    setOpenMenu(false)
                    navigate(`/${user.type}/banners`)
                }}>Banners</a>
                <a onClick={() => {
                    setOpenMenu(false)
                    navigate(`/${user.type}/courses`)
                }}>Cursos</a>

                <a onClick={() => {
                    navigate("/contracts")
                }}>Contratos</a>

                <a onClick={() => {
                    navigate("/admin/credentials")
                }}>Credenciados</a>
                <div onClick={() => {
                    navigate(`/${user.type}/perfil`)
                }}>
                    <img src={user.photo ? user.photo_url : "https://png.pngtree.com/png-vector/20190710/ourlarge/pngtree-user-vector-avatar-png-image_1541962.jpg"} />
                </div>
            </div>
            <div className="icon" onClick={() => {
                setOpenMenu(!openMenu)
            }}>
                {
                    openMenu ? (
                        <MdOutlineClose size={30} color="#FFF" />
                    ) : (
                        <MdMenu size={30} color="#FFF" />
                    )
                }

            </div>
            <div className="perfil" onClick={() => {
                navigate(`/${user.type}/perfil`)
            }}>
                <img src={user.photo ? user.photo_url : "https://png.pngtree.com/png-vector/20190710/ourlarge/pngtree-user-vector-avatar-png-image_1541962.jpg"} />
            </div>
        </Container>
    )

}