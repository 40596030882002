import React, { useEffect } from "react";
import { Modal, Box } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  maxWidth: "80%",
  minHeight: 150,
  border: '0px solid #000',
  bgcolor: '#AAC8AA',
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
};

export default function ModalC({ visible, executar, children }) {

  return (
    <Modal
      open={visible}
      onClose={() => {
        executar()
      }}
    >
      <Box sx={style}>
        {children}
      </Box>
    </Modal>
  );
}