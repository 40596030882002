import React from 'react';
import { Container } from './styles';
import { MdMenu } from 'react-icons/md';
import { IoIosArrowBack} from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/AuthContext';

const Header = ({ onChange, sidebar }) => {
  const navigate = useNavigate();
  const { user } = useAuth();

  return (
    <Container>
      
      <button style={{ color: "#fff", position: "absolute", left: 20 }} onClick={() => {
        if (sidebar == false) {
          navigate(-1)
        } else {
          onChange()
        }
      }}>
      {sidebar == false ? (<IoIosArrowBack color="#FFF" size={40} />) : (<MdMenu color="#FFF" size={35} />)}
      </button>
      {user && (
        <button style={{ position: "absolute", right: 20 }} onClick={() => {
          navigate(`/${user.type == "admin" ? user.type : user.type+"s"}/perfil`)
        }}>
        <img src={user.photo ? user.photo_url : "https://png.pngtree.com/png-vector/20190710/ourlarge/pngtree-user-vector-avatar-png-image_1541962.jpg"} />
      </button>
      )}
      
    </Container>
  )
};

export default Header;
