import React, { useState } from "react";
import { Photo, Container, ViewLogin, Information, Input, Button, Link, ModalConfirm } from './styles';
import logo from "./../../../images/logo.jpeg"
import { useAuth } from '../../../hooks/AuthContext';
import api from "../../../services/api";
import { InputC } from "../../../components/simple";
import { toast } from "react-toastify";

export default function LoginSignature() {

    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const { signIn } = useAuth();

    return (
        <Container>
            <ViewLogin>
                <h2>Login Assinatura SST</h2>
                <Photo src={logo} />
                <Information>
                    Seja bem-vindo, faça o login para visualizar e solicitar novos serviços.
                </Information>
                <Input placeholder='Usuário' value={email} type="email" onChange={(text) => {
                    setEmail(text.target.value)
                }} />
                <Input placeholder='Senha' value={password} type="password" onChange={(text) => {
                    setPassword(text.target.value)
                }} />
                <Button onClick={async () => {
                    await signIn({ email, password, type: "cliente" })
                }}>
                    Fazer Login
                </Button>
                <Link onClick={async () => {
                    setIsOpen(true)
                }}>
                    Esqueci minha senha
                </Link>
            </ViewLogin>

            <ModalConfirm open={isOpen}>
                <div className="content">
                    <div className="content-header">
                        <p>Digite seu email para recuperar a sua senha</p>
                    </div>

                    <div className="content-main" style={{ flexDirection: "row", display: "flex", flexWrap: "wrap", justifyContent: "space-between", padding: "0px 0 15px 0" }}>
                        <InputC text={email} placeholder={"usuario@gmail.com"} width={"100%"} height={"55px"} executar={(text) => { setEmail(text) }} />
                    </div>
                    <footer className="content-footer">
                        <span onClick={() => {
                            setIsOpen(false);
                        }}
                        >
                            Cancelar
                        </span>
                        <button disabled={isLoading} type="button" onClick={async () => {
                            setIsLoading(true);
                            if (email) {
                                await api
                                    .post('/password-forgot', {
                                        email: email.toLowerCase().replace(/ /g, ''),
                                    })
                                    .then(async (response) => {
                                        toast.success("Email para concluir a recuperação de senha foi enviado com sucesso.")
                                        setIsOpen(false)
                                    })
                                    .catch(({ response }) => {
                                        if (response) {
                                            if (response.data) {
                                                if (
                                                    response.data.message
                                                ) {
                                                    toast.warn(response.data.message);
                                                } else {

                                                    toast.warn("Ocorreu um erro interno");
                                                }
                                            } else {

                                                toast.warn("Ocorreu um erro interno");
                                            }
                                        } else {

                                            toast.warn("Ocorreu um erro interno");
                                        }
                                    });
                            } else {
                                toast.warn('Preencha o email para recuperar sua senha');
                            }
                            setIsLoading(false);
                        }}>
                            Enviar Email
                        </button>
                    </footer>
                </div>
            </ModalConfirm>
        </Container>
    )
} 