import React from "react";
import { Routes, Route } from "react-router-dom";

import RouteFranqueados from './RouteFranqueados';
import RouteTecnicos from './RouteTecnicos';
import RoutePublic from './RoutePublic';
import RoutePrivate from "./RoutePrivate";
import RouteHybrid from "./RouteHybrid";


import HomeFranqueados from "../pages/clientes/Home";
import LoginFranqueados from "../pages/clientes/Login";
import SolicitacoesFranqueados from "../pages/clientes/Solicitacoes";
import PerfilFranqueados from "../pages/clientes/Perfil";
import FinanceiroFranqueados from "../pages/clientes/Financeiro";
import OSFranqueados from "../pages/clientes/OS";
import OSSolicitacao from "../pages/clientes/Solicitacao";

import HomeTecnicos from "../pages/tecnicos/Home";
import LoginTecnicos from "../pages/tecnicos/Login";
import SolicitacoesTecnicos from "../pages/tecnicos/Solicitacoes";
import PerfilTecnicos from "../pages/tecnicos/Perfil";
import FinanceiroTecnicos from "../pages/tecnicos/Financeiro";
import OSTecnicos from "../pages/tecnicos/OS";
import ListOSs from "../pages/tecnicos/ListOSs";

import LoginAdmin from "../pages/admin/Login";
import RouteAdmin from "./RouteAdmin";
import HomeAdmin from "../pages/admin/Home";

import ServicesAdmin from "../pages/admin/Services";
import TecnicosAdmin from "../pages/admin/Tecnicos";
import FranqueadosAdmin from "../pages/admin/Franqueados";
import PerfilAdmin from "../pages/admin/Perfil";
import FinanceiroAdmin from "../pages/admin/Financeiro";
import OSDetailsAdmin from "../pages/admin/OS";
import BannersAdmin from "../pages/admin/Banners";
import CoursesAdmin from "../pages/admin/Courses";
import LessonsAdmin from "../pages/admin/Lessons";
import LessonAdmin from "../pages/admin/Lesson";
import CoursesPublic from "../pages/course/Courses";
import CoursePublic from "../pages/course/Course";
import Contracts from "../pages/contracts/Contracts";
import Contract from "../pages/contracts/Contract";
import NewContract from "../pages/contracts/NewContract";
import ResetPassword from "../pages/public/ResetPassword";
import ContractsAdmin from "../pages/contracts/ContractsAdmin";
import Credential from "../pages/credentials/Credential";
import Credentials from "../pages/credentials/Credentials";
import NewCredential from "../pages/credentials/NewCredential";
import AdminCredentials from "../pages/credentials/AdminCredentials";
import EditCredential from "../pages/credentials/EditCredential";
import UserCredential from "../pages/credentials/UserCredential";
import Completed from "../pages/credentials/Completed";
import Login from "../pages/credentials/Login";
import Alteracao from "../pages/clientes/Alteracao";
import LoginSignature from "../pages/clientes/LoginSignature";
import ServicesOS from "../pages/admin/ServicesOS";
import Company from "../pages/company/Company";
import Companies from "../pages/company/Companies";
import EditCompany from "../pages/company/EditCompany";
import CompanyRenewal from "../pages/company/CompanyRenewal";
import CompanyTimeline from "../pages/company/CompanyTimeline";
import CreateTimeline from "../pages/company/CreateTimeline";
import AllOSsAdmin from "../pages/admin/AllOSs";
import NewEditFranqueado from "../pages/admin/NewEditFranqueado";

const Routers = () => {
  return (
    <Routes>
      <Route exact path="*" element={<RoutePublic />}>
        <Route exact path="*" element={<LoginFranqueados />} />
      </Route>
      <Route exact path="/signature/login" element={<RoutePublic />}>
        <Route exact path="/signature/login" element={<LoginSignature />} />
      </Route>
      <Route exact path="/resetpassword/:code" element={<RouteHybrid />}>
        <Route exact path="/resetpassword/:code" element={<ResetPassword />} />
      </Route>
      <Route exact path="/contract/:contract_id" element={<RouteHybrid />}>
        <Route exact path="/contract/:contract_id" element={<Contract />} />
      </Route>
      <Route exact path="/company/:company_id" element={<RouteHybrid sidebar={false} />}>
        <Route exact path="/company/:company_id" element={<EditCompany />} />
      </Route>
      {/* Credentials */}

      <Route exact path="/admin/credentials" element={<RouteAdmin />}>
        <Route exact path="/admin/credentials" element={<AdminCredentials />} />
      </Route>
      <Route exact path="/admin/franqueado/:id" element={<RouteAdmin />}>
        <Route exact path="/admin/franqueado/:id" element={<NewEditFranqueado />} />
      </Route>
      <Route exact path="/credential/login" element={<RouteHybrid sidebar={false} header={false}/>}>
        <Route exact path="/credential/login" element={<Login />} />
      </Route>
      <Route exact path="/credential" element={<RouteHybrid sidebar={false} header={false} />}>
        <Route exact path="/credential" element={<UserCredential />} />
      </Route>
      <Route exact path="/credentials" element={<RouteHybrid sidebar={false} header={false} />}>
        <Route exact path="/credentials" element={<Credentials />} />
      </Route>
      <Route exact path="/admin/credential/:id" element={<RouteAdmin />}>
        <Route exact path="/admin/credential/:id" element={<EditCredential />} />
      </Route>
      <Route exact path="/new-credential" element={<RouteHybrid sidebar={false} header={false} />}>
        <Route exact path="/new-credential" element={<NewCredential />} />
      </Route>
      <Route exact path="/completed/:id" element={<RouteHybrid sidebar={false} header={false} />}>
        <Route exact path="/completed/:id" element={<Completed />} />
      </Route>
      <Route exact path="/credential/:id" element={<RouteHybrid sidebar={false} header={false}/>}>
        <Route exact path="/credential/:id" element={<Credential />} />
      </Route>
      <Route exact path="/admin/new-credential" element={<RoutePrivate sidebar={false} header={false}/>}>
        <Route exact path="/admin/new-credential" element={<NewCredential privateRoute={true} />} />
      </Route>
      {/* Login */}

      <Route exact path="/login" element={<RoutePublic />}>
        <Route exact path="/login" element={<LoginFranqueados />} />
      </Route>
      <Route exact path="/tecnicos/login" element={<RoutePublic />}>
        <Route exact path="/tecnicos/login" element={<LoginTecnicos />} />
      </Route>
      <Route exact path="/admin/login" element={<RoutePublic />}>
        <Route exact path="/admin/login" element={<LoginAdmin />} />
      </Route>

      {/* Courses */}
      <Route exact path="/courses" element={<RoutePrivate />}>
        <Route exact path="/courses" element={<CoursesPublic />} />
      </Route>
      <Route exact path="/course/:course_id" element={<RoutePrivate sidebar={false} header={false} />}>
        <Route exact path="/course/:course_id" element={<CoursePublic />} />
      </Route>
      {/* Contracts */}

      <Route exact path="/contracts" element={<RoutePrivate />}>
        <Route exact path="/contracts" element={<Contracts />} />
      </Route>
      <Route exact path="/contracts/:user_id" element={<RoutePrivate />}>
        <Route exact path="/contracts/:user_id" element={<ContractsAdmin />} />
      </Route>

      <Route exact path="/new-contract" element={<RoutePrivate />}>
        <Route exact path="/new-contract" element={<NewContract />} />
      </Route>

      {/* Home */}

      <Route exact path="/tecnicos/home" element={<RouteTecnicos />}>
        <Route exact path="/tecnicos/home" element={<HomeTecnicos />} />
      </Route>
      <Route exact path="/clientes/home" element={<RouteFranqueados />}>
        <Route exact path="/clientes/home" element={<HomeFranqueados />} />
      </Route>
      <Route exact path="/admin/home" element={<RouteAdmin />}>
        <Route exact path="/admin/home" element={<HomeAdmin />} />
      </Route>

      <Route exact path="/tecnico/timeline" element={<RouteTecnicos />}>
        <Route exact path="/tecnico/timeline" element={<CompanyTimeline />} />
      </Route>
      <Route exact path="/cliente/timeline" element={<RouteFranqueados />}>
        <Route exact path="/cliente/timeline" element={<CompanyTimeline />} />
      </Route>
      <Route exact path="/admin/timeline" element={<RouteAdmin />}>
        <Route exact path="/admin/timeline" element={<CompanyTimeline />} />
      </Route>

      <Route exact path="/tecnico/timeline/:id" element={<RouteTecnicos />}>
        <Route exact path="/tecnico/timeline/:id" element={<CreateTimeline />} />
      </Route>
      <Route exact path="/cliente/timeline/:id" element={<RouteFranqueados />}>
        <Route exact path="/cliente/timeline/:id" element={<CreateTimeline />} />
      </Route>
      <Route exact path="/admin/timeline/:id" element={<RouteAdmin />}>
        <Route exact path="/admin/timeline/:id" element={<CreateTimeline />} />
      </Route>

      <Route exact path="/tecnico/renewal" element={<RouteTecnicos />}>
        <Route exact path="/tecnico/renewal" element={<CompanyRenewal />} />
      </Route>
      <Route exact path="/cliente/renewal" element={<RouteFranqueados />}>
        <Route exact path="/cliente/renewal" element={<CompanyRenewal />} />
      </Route>
      <Route exact path="/admin/renewal" element={<RouteAdmin />}>
        <Route exact path="/admin/renewal" element={<CompanyRenewal />} />
      </Route>

      {/* Company */}

      <Route exact path="/companies" element={<RoutePrivate />}>
        <Route exact path="/companies" element={<Companies />} />
      </Route>
      <Route exact path="/view-company/:company_id" element={<RoutePrivate sidebar={false}  header={false}/>}>
        <Route exact path="/view-company/:company_id" element={<Company />} />
      </Route>

      {/* OSs */}

      <Route exact path="/tecnicos/os/:id" element={<RouteTecnicos />}>
        <Route exact path="/tecnicos/os/:id" element={<OSTecnicos />} />
      </Route>
      <Route exact path="/clientes/os/:id" element={<RouteFranqueados />}>
        <Route exact path="/clientes/os/:id" element={<OSFranqueados />} />
      </Route>
      <Route exact path="/clientes/alteracao-os/:id" element={<RouteFranqueados />}>
        <Route exact path="/clientes/alteracao-os/:id" element={<Alteracao />} />
      </Route>
      <Route exact path="/solicitacao" element={<RouteFranqueados />}>
        <Route exact path="/solicitacao" element={<OSSolicitacao />} />
      </Route>
      <Route exact path="/clientes/solicitacoes" element={<RouteFranqueados />}>
        <Route exact path="/clientes/solicitacoes" element={<SolicitacoesFranqueados />} />
      </Route>
      <Route exact path="/tecnicos/solicitacoes" element={<RouteTecnicos />}>
        <Route exact path="/tecnicos/solicitacoes" element={<SolicitacoesTecnicos />} />
      </Route>
      <Route exact path="/tecnicos/open" element={<RouteTecnicos />}>
        <Route exact path="/tecnicos/open" element={<ListOSs />} />
      </Route>

      {/* Financeiro */}

      <Route exact path="/clientes/financeiro" element={<RouteFranqueados />}>
        <Route exact path="/clientes/financeiro" element={<FinanceiroFranqueados />} />
      </Route>
      <Route exact path="/tecnicos/financeiro" element={<RouteTecnicos />}>
        <Route exact path="/tecnicos/financeiro" element={<FinanceiroTecnicos />} />
      </Route>

      {/* Perfil */}

      <Route exact path="/tecnicos/perfil" element={<RouteTecnicos />}>
        <Route exact path="/tecnicos/perfil" element={<PerfilTecnicos />} />
      </Route>
      <Route exact path="/clientes/perfil" element={<RouteFranqueados />}>
        <Route exact path="/clientes/perfil" element={<PerfilFranqueados />} />
      </Route>
      <Route exact path="/admin/perfil" element={<RouteAdmin />}>
        <Route exact path="/admin/perfil" element={<PerfilAdmin />} />
      </Route>

      {/* Admin */}

      <Route exact path="/admin/services" element={<RouteAdmin />}>
        <Route exact path="/admin/services" element={<ServicesAdmin />} />
      </Route>
      <Route exact path="/admin/banners" element={<RouteAdmin />}>
        <Route exact path="/admin/banners" element={<BannersAdmin />} />
      </Route>
      <Route exact path="/admin/courses" element={<RouteAdmin />}>
        <Route exact path="/admin/courses" element={<CoursesAdmin />} />
      </Route>
      <Route exact path="/admin/lessons/:course_id" element={<RouteAdmin />}>
        <Route exact path="/admin/lessons/:course_id" element={<LessonsAdmin />} />
      </Route>
      <Route exact path="/admin/services-os/:id" element={<RouteAdmin />}>
        <Route exact path="/admin/services-os/:id" element={<ServicesOS />} />
      </Route>
      <Route exact path="/admin/lesson/:course_id/:id" element={<RouteAdmin />}>
        <Route exact path="/admin/lesson/:course_id/:id" element={<LessonAdmin />} />
      </Route>
      <Route exact path="/admin/tecnicos" element={<RouteAdmin />}>
        <Route exact path="/admin/tecnicos" element={<TecnicosAdmin />} />
      </Route>
      <Route exact path="/admin/clientes" element={<RouteAdmin />}>
        <Route exact path="/admin/clientes" element={<FranqueadosAdmin type={"franqueado"} nameType={"Franqueado"} nameTypes={"Franqueados"}/>} />
      </Route>
      <Route exact path="/admin/revenda" element={<RouteAdmin />}>
        <Route exact path="/admin/revenda" element={<FranqueadosAdmin type={"revenda"} nameType={"Revenda treinamentos"} nameTypes={"Revenda treinamentos"}/>}/>
      </Route>
      <Route exact path="/admin/assinatura" element={<RouteAdmin />}>
        <Route exact path="/admin/assinatura" element={<FranqueadosAdmin type={"assinatura"} nameType={"Usuário Assinatura"} nameTypes={"Usuários Assinatura"}/>} />
      </Route>
      <Route exact path="/admin/terceirize" element={<RouteAdmin />}>
        <Route exact path="/admin/terceirize" element={<FranqueadosAdmin type={"terceirize"} nameType={"Usuário Terceirize"} nameTypes={"Usuários Terceirize"}/>} />
      </Route>
      <Route exact path="/admin/financeiro-cliente/:id" element={<RouteAdmin />}>
        <Route exact path="/admin/financeiro-cliente/:id" element={<FinanceiroAdmin type={"user"} />} />
      </Route>
      <Route exact path="/admin/financeiro-tecnico/:id" element={<RouteAdmin />}>
        <Route exact path="/admin/financeiro-tecnico/:id" element={<FinanceiroAdmin type={"collaborator"} />} />
      </Route>

      <Route exact path="/admin/allos-cliente/:id" element={<RouteAdmin />}>
        <Route exact path="/admin/allos-cliente/:id" element={<AllOSsAdmin type={"user"} />} />
      </Route>
      <Route exact path="/admin/allos-tecnico/:id" element={<RouteAdmin />}>
        <Route exact path="/admin/allos-tecnico/:id" element={<AllOSsAdmin type={"collaborator"} />} />
      </Route>


      <Route exact path="/admin/os/:id" element={<RouteAdmin />}>
        <Route exact path="/admin/os/:id" element={<OSDetailsAdmin />} />
      </Route>

    </Routes>
  );
};

export default Routers;
