import React, { useEffect, useState } from 'react';
import { Container, List, Header } from './styles';
import { useNavigate } from "react-router-dom";
import loadingPhoto from "./../../../images/loading.gif"
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { InputC, ButtonC, SwitchC, InputNumberC, InputTel } from "../../../components/simple";
import { MdAddPhotoAlternate, MdDelete, MdEdit } from 'react-icons/md';
import { IconButton } from '@mui/material';
import ModalC from '../../../components/modal';


export default function TecnicosAdmin() {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true)
    const [visibleModal, setVisibleModal] = useState(false)
    const [visibleModalDelete, setVisibleModalDelete] = useState(false)
    const [visibleModalUsers, setVisibleModalUsers] = useState(false)
    const [enabled, setEnabled] = useState(true)
    const [tecnicos, setTecnicos] = useState([])
    const [tecnicosC, setTecnicosC] = useState([])
    const [usersCollaborator, setUsersCollaborator] = useState([])
    const [filtername, setFilterName] = useState("")
    const [name, setName] = useState("")
    const [phone_number, setPhone_number] = useState("")
    const [email, setEmail] = useState("")
    const [photo, setPhoto] = useState("")
    const [users, setUsers] = useState([])
    const [user_id, setUser_id] = useState("")
    const [password, setPassword] = useState("")
    const [sector, setSector] = useState("")
    const [id, setId] = useState("")

    useEffect(() => {
        listTecnicos()
        listFranqueados()
    }, [])

    async function listFranqueados() {
        await api.get(`/users`).then((response) => {
            let users = response.data
            users.map((item) => {
                item.label = item.name
                item.value = item.id
            })
            setUsers(users)
        }).catch(({ response }) => {
            if (response) {
                if (response.data) {
                    if (response.data.message) {
                        toast.warn(response.data.message)
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            } else {
                toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
        })
        setFilterName("")
    }

    async function listTecnicos() {
        setLoading(true)
        await api.get(`/collaborators`).then((response) => {
            setTecnicos(response.data)
            setTecnicosC(response.data)
        }).catch(({ response }) => {
            if (response) {
                if (response.data) {
                    if (response.data.message) {
                        toast.warn(response.data.message)
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            } else {
                toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
        })
        setFilterName("")
        setLoading(false)
    }


    async function loadUsersCollaborator(id) {
        await api.get(`/users-collaborator/${id}`).then((response) => {
            setUsersCollaborator(response.data)
            setVisibleModalUsers(true)
        }).catch(({ response }) => {
            if (response) {
                if (response.data) {
                    if (response.data.message) {
                        toast.warn(response.data.message)
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            } else {
                toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
        })
    }

    async function createAndEditTecnico() {

        if (id) {
            if (!name || !email || !phone_number || !sector) {
                toast.warn('Preencha todos os campos para realizar o cadastro');
            } else {
                const data = new FormData();
                data.append('name', name);
                data.append('phone_number', phone_number);
                data.append('password', password);
                data.append('enabled', enabled);
                data.append('user_id', user_id);
                data.append('email', email);
                data.append('sector', sector);
                if (photo.name) {
                    data.append('file', photo, photo.name);
                }
                await api.put(`/collaborator/${id}`, data)
                    .then(async (ok) => {
                        toast.success("Técnico editado com sucesso")
                        listTecnicos()
                        setVisibleModal(false)
                    }).catch(({ response }) => {
                        if (response) {
                            if (response.data) {
                                if (response.data.message) {
                                    toast.warn(response.data.message)
                                } else {
                                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                                }
                            } else {
                                toast.error("Erro Interno. verifique sua conexão e tente novamente")
                            }
                        } else {
                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                        }
                    })
            }
        } else {
            if (!name || !email || !password || !phone_number) {
                toast.warn('Preencha todos os campos para realizar o cadastro');
            } else {
                const data = new FormData();
                data.append('name', name);
                data.append('phone_number', phone_number);
                data.append('password', password);
                data.append('enabled', enabled);
                data.append('email', email);
                data.append('user_id', user_id);
                data.append('sector', sector);
                if (photo.name) {
                    data.append('file', photo, photo.name);
                }
                await api.post(`/collaborator`, data).then(async (ok) => {
                    toast.success("Técnico criado com sucesso")
                    listTecnicos()
                    setVisibleModal(false)
                }).catch(({ response }) => {
                    if (response) {
                        if (response.data) {
                            if (response.data.message) {
                                toast.warn(response.data.message)
                            } else {
                                toast.error("Erro Interno. verifique sua conexão e tente novamente")
                            }
                        } else {
                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                        }
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                })
            }
        }
    }

    async function deleteTecnico() {
        await api.delete(`/collaborator/${id}`)
            .then(() => {
                toast.success("Técnico excluido com sucesso")
                listTecnicos()
                setVisibleModalDelete(false)
            }).catch(({ response }) => {
                if (response) {
                    if (response.data) {
                        if (response.data.message) {
                            toast.warn(response.data.message)
                        } else {
                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                        }
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            })
    }

    function filtertecnicos(value) {
        let tecnicosCopy = tecnicosC
        let tecnicos = []
        tecnicosCopy.map((item) => {
            if (String(item.name)
                .toUpperCase()
                .indexOf(value.toUpperCase()) != -1) {
                tecnicos.push(item)
            }
        })
        setTecnicos(tecnicos)
    }

    function phoneFormat(phone) {
        phone = phone.replace(/[^\d]/g, "");
        if (phone.length >= 11) {
            return phone.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
        }
        if (phone.length >= 10) {
            return phone.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
        }
        return phone;
    }

    return (
        <Container>
            {
                loading ? (<img src={loadingPhoto} style={{ background: "transparent", height: 50, width: 50 }} />

                ) : (
                    <>
                        <h1>{tecnicos.length} Técnicos</h1>
                        <Header>
                            <input value={filtername} onChange={(text) => {
                                setFilterName(text.target.value)
                                filtertecnicos(text.target.value)
                            }} placeholder='Procurar por nome' />
                            <button onClick={() => {
                                setId("")
                                setName("")
                                setEmail("")
                                setUser_id("")
                                setEnabled(true)
                                setPassword("")
                                setPhoto("")
                                setPhone_number("")
                                setSector("")
                                setVisibleModal(true)
                            }}>
                                Novo Técnico
                            </button>
                        </Header>
                        <List>
                            {tecnicos.length == 0 && (
                                <h4 style={{ marginTop: 50, fontWeight: 500, width: "80%", textAlign: "center" }}>Nenhum técnico encontrado</h4>
                            )}
                            {tecnicos.map((item) => {
                                return (
                                    <div>
                                        <img src={item.photo ? item.photo_url : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__480.png"} />
                                        <div>
                                            <p>{item.name} - {phoneFormat(item.phone_number)}</p>
                                            <p style={{ color: "#0008", marginTop: 5 }}>{item.email}</p>
                                            <p style={{ color: "#000a", marginTop: 5 }}>Setor: {item.sector}</p>
                                            <p style={{ color: "#000a", marginTop: 5 }}>{item.total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} em OSs nos últimos 7 dias</p>
                                        </div>
                                        <button onClick={() => {
                                            navigate(`/admin/allos-tecnico/${item.id}`)
                                        }}>
                                            OSs
                                        </button>
                                        <button onClick={() => {
                                            navigate(`/admin/financeiro-tecnico/${item.id}`)
                                        }}>
                                            Financeiro
                                        </button>
                                        <button onClick={async () => {
                                            await loadUsersCollaborator(item.id)
                                        }}>
                                            Franqueados
                                        </button>
                                        <button onClick={() => {
                                            setId(item.id)
                                            setName(item.name)
                                            setEmail(item.email)
                                            setEnabled(item.enabled)
                                            setUser_id(item.user_id)
                                            setPhoto({ photo_url: item.photo_url })
                                            setPhone_number(item.phone_number)
                                            setPassword("")
                                            setSector(item.sector)
                                            setVisibleModal(true)
                                        }}>
                                        <MdEdit color="#FFF" size={20} />
                                        </button>
                                        <button onClick={() => {
                                            setId(item.id)
                                            setName(item.name)
                                            setVisibleModalDelete(true)
                                        }}>
                                        <MdDelete color="#FFF" size={20} />
                                        </button>
                                    </div>
                                )
                            })}
                        </List>
                    </>
                )
            }

            <ModalC visible={visibleModal} executar={() => { setVisibleModal(false) }}>
                <div style={{ paddingTop: 60, width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div style={{ marginTop: -190, width: 260, height: 160, borderRadius: 10, marginBottom: 25, marginBottom: 20, justifyContent: "center" }}>
                        <img style={{ width: 160, marginLeft: 50, height: 160, borderRadius: 10, background: "#fff", }} src={photo.photo_url ? photo.photo_url : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__480.png"} />
                        <IconButton style={{ width: 50, height: 50, bottom: 15 }}>
                            <label for="avatar" style={{ display: "flex", width: 50, height: 50, justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                                <MdAddPhotoAlternate color="#FFF" />
                                <input id="avatar" style={{ display: "none" }} type="file" accept="image/" onChange={(file) => {
                                    let photo = file.target.files[0]
                                    photo.photo_url = URL.createObjectURL(file.target.files[0])
                                    setPhoto(photo)
                                }
                                }
                                />
                            </label>
                        </IconButton>
                    </div>
                    <div style={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                        justifyContent: "space-between"
                    }}>
                        <InputC text={name} title={"Nome"} placeholder={"João"} width={"100%"} height={"55px"} executar={(text) => { setName(text) }} />
                        <h3 style={{
                            color: "#fff",
                            fontSize: 15,
                            width: "100%",
                            textAlign: "left",
                            margin: "10px 0 5px 0",
                            fontWeight: "bold"
                        }}>Setor</h3>
                        <select
                            value={sector}
                            style={{
                                width: "100%",
                                backgroundColor: "#FFF",
                                borderWidth: 1,
                                borderColor: "#ccc",
                                borderStyle: "solid",
                                color: "#444",
                                padding: "10px",
                                fontSize: 14,
                                borderRadius: 5,
                                fontWeight: "400",
                                paddingLeft: 8,
                            }}
                            onChange={(text) => {
                                setSector(text.target.value)
                            }}
                        >
                            <option disabled hidden value={""}>Seleciona uma opção</option>
                            <option value={"Todos"}>Todos</option>
                            <option value={"Serviços de segurança do Trabalho"}>Serviços de segurança do Trabalho</option>
                            <option value={"Revenda de treinamentos online"}>Revenda de treinamentos online</option>
                            <option value={"Serviços Medicina ocupacional"}>Serviços Medicina ocupacional</option>
                            <option value={"Credenciamento SST"}>Credenciamento SST</option>
                            <option value={"Assinatura Documentos SST"}>Assinatura Documentos SST</option>
                        </select>
                        <div style={{ width: "100%" }}>
                            <h3 style={{
                                color: "#fff",
                                fontSize: 15,
                                width: "100%",
                                textAlign: "left",
                                margin: "10px 0 5px 0",
                                fontWeight: "bold"
                            }}>Vincular a um franqueado</h3>
                            <select
                                value={user_id}
                                style={{
                                    width: "100%",
                                    backgroundColor: "#FFF",
                                    borderWidth: 1,
                                    borderColor: "#ccc",
                                    borderStyle: "solid",
                                    color: "#444",
                                    padding: "10px",
                                    fontSize: 14,
                                    borderRadius: 5,
                                    fontWeight: "400",
                                    paddingLeft: 8,
                                }}
                                onChange={(text) => {
                                    setUser_id(text.target.value)
                                }}
                            >
                                <option value={""}>Nenhum franqueado</option>
                                {users.map((item) => {
                                    return (
                                        <option value={item.id} label={item.name}>{item.name}</option>
                                    )
                                })}
                            </select> 
                        </div>
                        <InputC text={email} title={"Email"} placeholder={"usuario@gmail.com"} width={"100%"} height={"55px"} executar={(text) => { setEmail(text) }} />
                        <InputTel text={phone_number} title={"Telefone"} placeholder={"(00) 0000-0000"} width={"100%"} height={"55px"} executar={(text) => { setPhone_number(text) }} />
                        <InputC text={password} title={id ? "Nova Senha" : "Senha"} placeholder={"****"} width={"100%"} height={"55px"} executar={(text) => { setPassword(text) }} />
                        <SwitchC value={enabled} width={"100%"} executar={(value) => {
                            setEnabled(value)
                        }} title={"Receber OS automaticamente"} />
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: "center", marginTop: 25
                        }}>

                            <ButtonC text={""} title={"Salvar"}
                                width={"60%"} executar={() => {
                                    createAndEditTecnico()
                                }} />
                        </div>
                    </div>
                </div>

            </ModalC>
            <ModalC visible={visibleModalDelete} executar={() => { setVisibleModalDelete(false) }}>
                <div style={{ paddingTop: 0, width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <h3 style={{ textAlign: "center", color: "#fff" }}>Você deseja excluir o Técnico {name}?</h3>
                    <div style={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                        justifyContent: "space-between"
                    }}>
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: "center", marginTop: 25
                        }}>

                            <ButtonC text={""} title={"Confirmar"} background={"#bb4444"}
                                width={"70%"} executar={() => {
                                    deleteTecnico()
                                }} />
                        </div>
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: "center", marginTop: 15
                        }}>

                            <ButtonC text={""} title={"Cancelar"} background={"#888"}
                                width={"70%"} executar={() => {
                                    setVisibleModalDelete(false)
                                }} />
                        </div>
                    </div>
                </div>

            </ModalC>
            <ModalC visible={visibleModalUsers} executar={() => { setVisibleModalUsers(false) }}>
                <div style={{ paddingTop: 0, width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <h3 style={{ textAlign: "center", color: "#fff" }}>{usersCollaborator.length} Franqueados vinculados</h3>
                    <div style={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                        justifyContent: "space-between"
                    }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            color: "#FFF",
                            alignItems: "center",
                            justifyContent: "center",
                            margin: "25px 0",
                        }}>
                            {
                                usersCollaborator.map((item, index) => {
                                    return (
                                        <p style={{marginBottom: 4}}>{index+1}. {item.name}</p>
                                    )
                                })
                            }
                        </div>
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: "center", marginTop: 15
                        }}>

                            <ButtonC text={""} title={"Fechar"} background={"#888"}
                                width={"70%"} executar={() => {
                                    setVisibleModalUsers(false)
                                }} />
                        </div>
                    </div>
                </div>

            </ModalC>
        </Container>
    )
} 