import React, { useState } from 'react';
import { Container } from './styles';
import { useNavigate } from "react-router-dom";
import loadingBranco from "./../../../images/loadingBranco.gif"
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { InputC, ButtonC, InputTel, InputDate, InputCPF } from "../../../components/simple";
import { states } from '../../../services/states';
import { MdAddPhotoAlternate } from 'react-icons/md';
import { IconButton } from '@mui/material';
import HeaderAdmin from '../../../components/headerAdmin';


export default function NewCredential({ privateRoute }) {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false)
    const [services, setServices] = useState("")
    const [name, setName] = useState("")
    const [profession, setProfession] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [description, setDescription] = useState("")
    const [cities, setCities] = useState([])
    const [citiesC, setCitiesC] = useState([])
    const [phone_number, setPhone_number] = useState("")
    const [birthday, setBirthday] = useState("")
    const [city, setCity] = useState("")
    const [state, setState] = useState("")
    const [photo, setPhoto] = useState("")
    const [citiesServices, setCitiesServices] = useState("")
    const [stateC, setStateC] = useState("")

    async function createCredential() {
        setLoading(true)
        if (!photo || !name || !password || !description || !email || !phone_number || !birthday || !profession || !city || !state || !citiesServices || !services) {
            toast.warn('Preencha todos os campos, adicione uma foto e pelo menos uma cidade atendida e um serviço');
        } else {
            const data = new FormData();
            data.append('name', name);
            data.append('phone_number', phone_number);
            data.append('password', password);
            data.append('email', email);
            data.append('description', description);
            data.append('birthday', birthday);
            data.append('profession', profession);
            data.append('city', city);
            data.append('state', state);
            data.append('served_cities', citiesServices);
            data.append('services', services);
            if (photo.name) {
                data.append('file', photo, photo.name);
            }
            await api.post(`/credential`, data).then((response) => {
                toast.success("Cadastro realizado com sucesso")
                const { id, token } = response.data;
                localStorage.setItem('@guruseg-credential:token', token);
                localStorage.setItem('@guruseg-credential:id', id);
                navigate("/credential")
                window.open('https://www.guruseg.com.br/');
            }).catch(({ response }) => {
                if (response) {
                    if (response.data) {
                        if (response.data.message) {
                            toast.warn(response.data.message)
                        } else {
                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                        }
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            })

        }
        setLoading(false)
    }

    async function createAdminCredential() {
        setLoading(true)
        if (!name || !email) {
            toast.warn('Preencha pelo menos o nome e email');
        } else {
            const data = new FormData();
            data.append('name', name);
            data.append('phone_number', phone_number);
            data.append('password', password);
            data.append('email', email);
            data.append('description', description);
            data.append('birthday', birthday);
            data.append('profession', profession);
            data.append('city', city);
            data.append('state', state);
            data.append('served_cities', citiesServices);
            data.append('services', services);
            if (photo.name) {
                data.append('file', photo, photo.name);
            }
            await api.post(`/admin/credential`, data).then(async (ok) => {
                toast.success("Cadastro realizado com sucesso")
                navigate(-1)
            }).catch(({ response }) => {
                if (response) {
                    if (response.data) {
                        if (response.data.message) {
                            toast.warn(response.data.message)
                        } else {
                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                        }
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            })
        }
        setLoading(false)
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            {
                privateRoute && (
                    <HeaderAdmin />
                )
            }
            <Container>
                <h1>Cadastrar Credenciado</h1>

                <h2>Insira seus dados para se cadastrar</h2>
                <h3 style={{ marginTop: 5 }}>Foto do Credenciado</h3>
                <div style={{ width: 260, height: 160, borderRadius: 10, marginBottom: 25, marginBottom: 15, marginTop: 5, justifyContent: "center" }}>
                    <img style={{ width: 160, height: 160, borderRadius: 10, background: "#fff", }} src={photo.photo_url ? photo.photo_url : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__480.png"} />
                    <IconButton style={{ width: 50, height: 50, bottom: 15 }}>
                        <label for="avatar" style={{ display: "flex", width: 50, height: 50, justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                            <MdAddPhotoAlternate color="#666" />
                            <input id="avatar" style={{ display: "none" }} type="file" accept="image/" onChange={(file) => {
                                let photo = file.target.files[0]
                                photo.photo_url = URL.createObjectURL(file.target.files[0])
                                setPhoto(photo)
                            }
                            }
                            />
                        </label>
                    </IconButton>
                </div>
                <InputC text={name} title={"Nome"} placeholder={"nome"} width={"100%"} height={"55px"} color="#999" executar={(text) => { setName(text) }} />
                <InputC text={password} title={"Senha"} placeholder={"senha"} width={"100%"} height={"55px"} color="#999" executar={(text) => { setPassword(text) }} />
                <InputC text={profession} title={"Profissão"} placeholder={"profissão"} width={"100%"} height={"55px"} color="#999" executar={(text) => { setProfession(text) }} />
                <InputC text={email} title={"Email"} placeholder={"email"} width={"100%"} height={"55px"} color="#999" executar={(text) => { setEmail(text) }} />
                <InputDate text={birthday} title={"Data de nascimento"} placeholder={"Data de nascimento"} width={"100%"} height={"55px"} color="#999" executar={(text) => { setBirthday(text) }} />
                <InputTel text={phone_number} title={"Telefone ( Whatsapp )"} placeholder={"telefone ( whatsapp )"} width={"100%"} height={"55px"} color="#999" executar={(text) => { setPhone_number(text) }} />
                <InputC text={description} title={"Descrição"} placeholder={"descreva sobre o credenciado"} width={"100%"} color="#999" minHeight={120} multiline={true} executar={(text) => { setDescription(text) }} />
                <div style={{ width: "48%" }}>
                    <h3 style={{ marginTop: 10 }}>Estado</h3>
                    <select
                        value={state}
                        style={{
                            width: "100%",
                            backgroundColor: "#FFF",
                            borderWidth: 1,
                            borderColor: "#ccc",
                            borderStyle: "solid",
                            color: "#444",
                            padding: "10px",
                            fontSize: 14,
                            borderRadius: 5,
                            fontWeight: "400",
                            paddingLeft: 8,
                        }}
                        onChange={(text) => {
                            setState(text.target.value)
                            setCities(states.filter((item) => { return item.sigla == text.target.value })[0].cidades)
                        }}
                    >
                        <option value={""}>Selecione seu estado</option>
                        {
                            states.map((item) => {
                                return (
                                    <option value={item.sigla}>{item.nome}</option>
                                )
                            })
                        }
                    </select>
                </div>
                <div style={{ width: "48%" }}>
                    <h3 style={{ marginTop: 10 }}>Cidade</h3>
                    <select
                        disabled={state == ""}
                        value={city}
                        style={{
                            width: "100%",
                            backgroundColor: "#FFF",
                            borderWidth: 1,
                            borderColor: "#ccc",
                            borderStyle: "solid",
                            color: "#444",
                            padding: "10px",
                            fontSize: 14,
                            borderRadius: 5,
                            fontWeight: "400",
                            paddingLeft: 8,
                        }}
                        onChange={(text) => {
                            setCity(text.target.value)
                        }}
                    >
                        <option value={""}>Seleciona sua cidade</option>
                        {
                            cities.map((item) => {
                                return (
                   <option value={item}>{item}</option>
                                )
                            })
                        }
                    </select>
                </div>
                <h3 style={{ marginTop: 10 }}>Cidades em que você atende</h3>
                <h3 style={{ marginTop: 10 }}>Estado</h3>
                <select
                    value={stateC}
                    style={{
                        width: "100%",
                        backgroundColor: "#FFF",
                        borderWidth: 1,
                        borderColor: "#ccc",
                        borderStyle: "solid",
                        color: "#444",
                        padding: "10px",
                        fontSize: 14,
                        borderRadius: 5,
                        fontWeight: "400",
                        paddingLeft: 8,
                    }}
                    onChange={(text) => {
                        setStateC(text.target.value)
                        setCitiesC(states.filter((item) => { return item.sigla == text.target.value })[0].cidades)
                    }}
                >
                    <option value={""}>Seleciona o estado</option>
                   {
                       states.map((item) => {
                           return (
                               <option value={item.sigla}>{item.nome}</option>
                           )
                       })
                   }
                </select>

                <h3 style={{ marginTop: 10 }}>Adicione as cidades</h3>
                <select
                    disabled={stateC == ""}
                    value={""}
                    style={{
                        width: "100%",
                        backgroundColor: "#FFF",
                        borderWidth: 1,
                        borderColor: "#ccc",
                        borderStyle: "solid",
                        color: "#444",
                        padding: "10px",
                        fontSize: 14,
                        borderRadius: 5,
                        fontWeight: "400",
                        paddingLeft: 8,
                    }}
                    onChange={(text) => {
                        let result = citiesServices + text.target.value + ` - ${stateC};`
                        setCitiesServices(result)
                    }}
                >
                    <option value={""}>Adiciona uma cidade</option>
                    {
                        citiesC.map((item) => {
                            if (citiesServices.indexOf(item + ` - ${stateC};`) == -1) {
                                return (
                                  <option value={item}>{item}</option>
                                )
                            }
                        })
                    }
                </select>

                <div style={{ display: "flex", width: "100%", flexWrap: "wrap", justifyContent: "flex-start", marginTop: 10 }}>
                    {
                        citiesServices.split(";").map((item) => {
                            if (item) {
                                return (
                                  <div style={{ borderRadius: 5, fontSize: 14, display: "flex", justifyContent: "center", alignItems: "center", margin: "5px", padding: "5px 12px", background: "#f0f0f0" }}>
                                      {item}
                                      <h4
                                        onClick={() => {
                                            let citiesServicesC = citiesServices.replace(item + ";", "")
                                            setCitiesServices(citiesServicesC)
                                        }}
                                        style={{ marginLeft: 10, cursor: "pointer" }}>X</h4>
                                  </div>
                                )
                            }

                        })
                    }

                </div>
                <h3 style={{ marginTop: 10 }}>Selecione seus serviços</h3>
                <select
                    value={""}
                    style={{
                        width: "100%",
                        backgroundColor: "#FFF",
                        borderWidth: 1,
                        borderColor: "#ccc",
                        borderStyle: "solid",
                        color: "#444",
                        padding: "10px",
                        fontSize: 14,
                        borderRadius: 5,
                        fontWeight: "400",
                        paddingLeft: 8,
                    }}
                    onChange={(text) => {
                        let result = services + text.target.value + ";"
                        setServices(result)
                    }}
                >
                    <option value={""}>Adicione seus serviços</option>
                    {
                        services.indexOf("Treinamentos") == -1 && (
                            <option value={"Treinamentos"}>Treinamentos</option>
                        )
                    }
                    {
                        services.indexOf("Visita Técnica") == -1 && (
                            <option value={"Visita Técnica"}>Visita Técnica</option>
                        )
                    }
                    {
                        services.indexOf("Medições ambientais") == -1 && (
                            <option value={"Medições ambientais"}>Medições ambientais</option>
                        )
                    }
                    {
                        services.indexOf("Elaboração de PGR, PCMSO LTCAT") == -1 && (
                            <option value={"Elaboração de PGR, PCMSO LTCAT"}>Elaboração de PGR, PCMSO LTCAT</option>
                        )
                    }
                </select>
                <div style={{ display: "flex", width: "100%", flexWrap: "wrap", justifyContent: "flex-start", marginTop: 10 }}>
                    {
                        services.split(";").map((item) => {
                            if (item) {
                                return (
                                  <div style={{ borderRadius: 5, fontSize: 14, display: "flex", justifyContent: "center", alignItems: "center", margin: "5px", padding: "5px 12px", background: "#f0f0f0" }}>
                                      {item}
                                      <h4
                                        onClick={() => {
                                            let servicesC = services.replace(item + ";", "")
                                            setServices(servicesC)
                                        }}
                                        style={{ marginLeft: 10, cursor: "pointer" }}>X</h4>
                                  </div>
                                )
                            }

                        })
                    }

                </div>
                <div style={{ display: "flex", width: "100%", justifyContent: "space-between", margin: "35px 0" }}>

                    <ButtonC text={""} disabled={loading} title={loading == "accept" ? (
                                    <img src={loadingBranco} style={{ background: "transparent", height: 40, width: 40, margin: "0 40px" }} />
                                ) : "Cadastrar"}
                        width={"100%"} executar={() => {
                            if (privateRoute) {
                                createAdminCredential()
                            } else {
                                createCredential()
                            }

                        }} />
                </div>
            </Container>
        </div>
    )
} 