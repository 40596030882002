import styled from "styled-components";

export const Photo = styled.img`
  border-radius: 10px;
  max-height: 150px;
  max-width: 55%;
`;

export const Input = styled.input`
  border: none;
  background-color: #f0f0f0;
  height: 35px;
  border-radius: 10px;
  font-size: 16px;
  width: 75%;
  margin-bottom: 15px;
  text-align: center;
`;

export const Button = styled.button`
  border: none;
  background-color: #AAC8AA;
  height: 35px;
  border-radius: 10px;
  font-size: 17px;
  padding: 0 10px;
  width: 75%;
  margin-top: 10px;
  color: #FFF;
  text-align: center;
`;

export const Information = styled.h4`
  font-size: 16px;
  width: 50%;
  margin: 30px 0;
  text-align: center;
  @media only screen and (max-width: 600px) {
  width: 75%;
  }
`;

export const ViewLogin = styled.div`
max-width: 500px;
padding: 40px 0 60px 0;
border-radius: 10px;
width: 100%;
background-color: #fff;
display: flex;
flex-direction: column;
align-items: center;

h2{
  margin: 0 0 30px 0;
}


@media only screen and (max-width: 600px) {
  width: 95%;
padding: 30px 0 40px 0;
h2{
  font-size: 16px;
  margin: 0 0 20px 0;
}
  }
`;

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #363636;
  display: flex;
  justify-content: center;
  align-items: center;
`;
