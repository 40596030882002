import styled from "styled-components";
import { OutlinedInput, Button, Switch } from '@mui/material';

export const ViewInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px;
`;

export const SwitchMUI = styled(Switch)`
margin: 4px 0 0 8px;
`;

export const InputMUI = styled(OutlinedInput)`
  display: flex;
  width: 100%;
  flex: 1;
  background: #FFF;
  border-radius: 5px;
  height: 55px;
`;

export const InputLabelMUI = styled.h3`
  color: #FFF;
  font-size: 15px;
  margin: 10px 0 5px 0;
  font-weight: bold;
`;

export const ButtonMUI = styled(Button)`
  background:#f2760a;
  display: flex;
  width: 100%;
  height: 35px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 0 12px;

  p {
    font-size: 15px;
    color: #fff;
    margin: 0;
    text-transform: none;
  }

`;