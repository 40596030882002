import React, { useEffect, useState } from 'react';
import { Container, Risk, Risks, Images, Image, Setor } from './styles';
import { useNavigate, useParams } from "react-router-dom";
import loadingfile from "./../../../images/loading.gif"
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { InputC, ButtonC, InputTel } from "../../../components/simple";
import ModalC from '../../../components/modal';
import { useAuth } from '../../../hooks/AuthContext';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { MdAdd, MdDelete } from 'react-icons/md';
import { AutoComplete, Modal } from 'antd';
import { acidentes, biologicos, ergonomicos, fisicos, quimicos } from '../../../services/states';

const { confirm } = Modal;

export default function EditCompany() {

    const { user } = useAuth();
    const { company_id } = useParams();

    const navigate = useNavigate();

    const [id, setId] = useState("")
    const [company, setCompany] = useState(null)
    const [riskFisico, setRiskFisico] = useState([])
    const [riskErgonomico, setRiskErgonomico] = useState([])
    const [riskQuimico, setRiskQuimico] = useState([])
    const [riskBiologico, setRiskBiologico] = useState([])
    const [riskAcidente, setRiskAcidente] = useState([])
    const [riskFisicoC, setRiskFisicoC] = useState([])
    const [riskErgonomicoC, setRiskErgonomicoC] = useState([])
    const [riskQuimicoC, setRiskQuimicoC] = useState([])
    const [riskBiologicoC, setRiskBiologicoC] = useState([])
    const [riskAcidenteC, setRiskAcidenteC] = useState([])
    const [riskFisicoName, setRiskFisicoName] = useState("")
    const [riskErgonomicoName, setRiskErgonomicoName] = useState("")
    const [riskQuimicoName, setRiskQuimicoName] = useState("")
    const [riskBiologicoName, setRiskBiologicoName] = useState("")
    const [riskAcidenteName, setRiskAcidenteName] = useState("")
    const [companySector, setCompanySector] = useState([{
        name: "",
        description: "",
        companyScratchs: [],
        companyEmployees: [
            {
                name: "",
                epis: "",
                cbo: "",
                quantidade_colaboradores: 0,
                description: "",
                quantidade_colaboradores_m: 0,
                quantidade_colaboradores_f: 0,
            }
        ]
    }])

    const [name, setName] = useState("")
    const [doc, setDoc] = useState("")
    const [companyImages, setCompanyImages] = useState([[]])
    const [loading, setLoading] = useState(true)
    const [razao_social, setRazao_social] = useState("")
    const [nome_fantasia, setNome_Fantasia] = useState("")
    const [cnpj, setCnpj] = useState("")
    const [ramo_atividade, setRamo_atividade] = useState("")
    const [endereco, setEndereco] = useState("")
    const [cep, setCep] = useState("")
    const [nome_responsavel, setNome_responsavel] = useState("")
    const [cpf_responsavel, setCpf_responsavel] = useState("")
    const [contato_responsavel, setContato_responsavel] = useState("")
    const [message, setMessage] = useState("")
    const [observation, setObservation] = useState("")
    const [visibleModal, setVisibleModal] = useState(false)
    const [total, setTotal] = useState(0)
    const [totalM, setTotalM] = useState(0)
    const [status, setStatus] = useState("")

    useEffect(() => {
        let risk = []
        fisicos.map(item => {
            risk.push({
                label: item,
                value: item
            })
        }) 
        setRiskFisico(risk)
        setRiskFisicoC(risk)
        risk = []
        acidentes.map(item => {
            risk.push({
                label: item,
                value: item
            })
        }) 
        setRiskAcidente(risk)
        setRiskAcidenteC(risk)
        risk = []
        biologicos.map(item => {
            risk.push({
                label: item,
                value: item
            })
        }) 
        setRiskBiologico(risk)
        setRiskBiologicoC(risk)
        risk = []
        quimicos.map(item => {
            risk.push({
                label: item,
                value: item
            })
        }) 
        setRiskQuimico(risk)
        setRiskQuimicoC(risk)
        risk = []
        ergonomicos.map(item => {
            risk.push({
                label: item,
                value: item
            })
        }) 
        setRiskErgonomico(risk)
        setRiskErgonomicoC(risk)
    }, [])

    useEffect(() => {
       loadingCompany()
    }, [])

    useEffect(() => {
        getFuncionarios()
    }, [companySector])

    async function loadingCompany() {
        await api.get(`/company/${company_id}`).then((response) => {
            const company = response.data
            setCompany(company)
            setStatus(company.status)
            setId(company.id)
            if (company.status == "confirmado" || company.status == "expirado") {
                setMessage("Este formulário já foi preenchido")
            }
            if (company.razao_social) {
                setRazao_social(company.razao_social)
            }
            if (company.nome_fantasia) {
                setNome_Fantasia(company.nome_fantasia)
            }
            if (company.cnpj) {
                setCnpj(company.cnpj)
            }
            if (company.ramo_atividade) {
                setRamo_atividade(company.ramo_atividade)
            }
            if (company.observation) {
                setObservation(company.observation)
            }
            if (company.cep) {
                setCep(company.cep)
            }
            if (company.endereco) {
                setEndereco(company.endereco)
            }
            if (company.nome_responsavel) {
                setNome_responsavel(company.nome_responsavel)
            }
            if (company.cpf_responsavel) {
                setCpf_responsavel(company.cpf_responsavel)
            }
            if (company.contato_responsavel) {
                setContato_responsavel(company.contato_responsavel)
            }
            if (company.companySector.length == 0) {
                setCompanySector([
                    {
                        name: "",
                        description: "",
                        companyScratchs: [],
                        companyEmployees: [
                            {
                                name: "",
                                epis: "",
                                cbo: "",
                                quantidade_colaboradores: 0,
                                description: "",
                                quantidade_colaboradores_m: 0,
                                quantidade_colaboradores_f: 0,
                            }
                        ]
                    }])
            } else {
                let sectores = [...company.companySector]
                sectores.map((item) => {
                    if (item.companyEmployees.length == 0) {
                        item.companyEmployees = [
                            {
                                name: "",
                                epis: "",
                                cbo: "",
                                quantidade_colaboradores: 0,
                                description: "",
                                quantidade_colaboradores_m: 0,
                                quantidade_colaboradores_f: 0,
                            }
                        ]
                    }
                })
                setCompanySector(sectores)
                setCompanyImages(company.companyImages)
            }
        }).catch(({ response }) => {
            if (response) {
                if (response.data) {
                    if (response.data.message) {
                        setMessage(response.data.message)
                    } else {
                        setMessage("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    setMessage("Erro Interno. verifique sua conexão e tente novamente")
                }
            } else {
                setMessage("Erro Interno. verifique sua conexão e tente novamente")
            }
        })
        setLoading(false)
    }

    async function verifyCompany(signature) {
        let error = ""
        companySector.map((item, index) => {
            if (!error) {
                 if (!item.name || !item.description) {
                    error = `Preencha o nome e descrição do setor ${index + 1}`
                }
                item.companyEmployees.map((data, i) => {
                    if (!data.name || !data.description || !data.epis || !data.quantidade_colaboradores) {
                        error = `Preencha todos os campos da função ${i+1} no setor ${index + 1}`
                    }
                })
            }
        })
        if (error) {
            toast.warn(error)
        } else {
            if (user) {
                if (user.id == company.collaborador_id || signature) {
                    confirmCompany(false)
                } else {
                    setName("")
                    setDoc("")
                    setVisibleModal(true)
                }
            } else {
                setName("")
                setDoc("")
                setVisibleModal(true)
            }
            
            
        }
    }

    async function confirmCompany(signature) {
        let userC = user
        if (!userC) {
            userC = {id: ""}
        }
        if ((userC.id != company.collaborador_id || signature) && (!name || !doc)) {
            toast.warn("Preencha seu nome e documento para assinar o contrato")
        } else {
            await api.put(userC.id == company.collaborador_id ? signature ? `/company/${company_id}` : `/company-edit/${company_id}` : `/company/${company_id}`, {
                razao_social: razao_social,
                nome_fantasia: nome_fantasia,
                cnpj: cnpj,
                ramo_atividade: ramo_atividade,
                cep: cep,
                endereco: endereco,
                nome_responsavel: nome_responsavel,
                cpf_responsavel: cpf_responsavel,
                contato_responsavel: contato_responsavel,
                signature: `Assinado por ${name}, documentação: ${doc}`,
                companySector: companySector,
            }).then(() => {
                if (userC.id == company.collaborador_id && !signature) {
                    toast.success("Formulário editado com sucesso")
                }else {
                    setMessage("Formulário confirmado com sucesso")    
                }
                setVisibleModal(false)
            }).catch(({ response }) => {
                if (response) {
                    if (response.data) {
                        if (response.data.message) {
                            toast.warn(response.data.message)
                        } else {
                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                        }
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            })
        }
    }

    async function deleteFuncao(indexSetor, indexFuncao) {
        let companySectorC = [...companySector]
        let companyEmployees = []
        if(companySectorC[indexSetor].companyEmployees[indexFuncao].id){
            await api.delete(`/employe/${companySectorC[indexSetor].companyEmployees[indexFuncao].id}`)
            .then(() => {
            }).catch(({ response }) => {
                if (response) {
                    if (response.data) {
                        if (response.data.message) {
                            toast.warn(response.data.message)
                        } else {
                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                        }
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
                return ""
            })
        }
        companySectorC[indexSetor].companyEmployees.map((item, index)=>{
            if(index != indexFuncao){
                companyEmployees.push(item)
            }
        })
        companySectorC[indexSetor].companyEmployees = [...companyEmployees]
        setCompanySector(companySectorC)
        toast.success("Função excluida com sucesso")
    }

    function getFuncionarios(){
        let total = 0
        let totalM = 0
        companySector.map((item) => {
            item.companyEmployees.map(data => {
                total += parseInt(data.quantidade_colaboradores) || 0
                totalM += parseInt(data.quantidade_colaboradores_m) || 0
            })
        })
        setTotal(total)
        setTotalM(totalM)
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Container>
                {
                    loading ? (
                        <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                            <img src={loadingfile} style={{ background: "transparent", height: 50, width: 50 }} />
                        </div>
                    ) : (
                        <>
                            {
                                message ? ( 
                                    <h4 style={{
                                        width: "100%",
                                        padding: 15,
                                        background: "#f0f0f0",
                                        borderRadius: 10,
                                        height: 80,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        margin: "50px 0",
                                        textAlign: "center"
                                    }}>{message}</h4>
                                ) : (
                                    <>
                                        <h1>{status == "alteracao" ? "Alteração" : "Cadastro"} da Formulário</h1>
                                                <h2>CLIQUE NO BOTÃO ABAIXO PARA TIRAR SUAS DÚVIDAS SOBRE O FORMULÁRIO</h2>
                                                <ButtonC text={""} title={"ASSISTIR VIDEO"} background={"#008036"}
                                                width={"180px"} executar={() => {
                                                    window.location.href = "https://www.youtube.com/@focussegurancadotrabalho5442"
                                                }} />
                                        {
                                            observation && (
                                                <>
                                                    <h2>OBSERVAÇÃO DO FRANQUEADO</h2>
                                                    <h4 style={{
                                                        width: "100%",
                                                        padding: 15,
                                                        background: "#f0f0f0",
                                                        borderRadius: 10
                                                    }}>{observation}</h4>
                                                </>
                                            )
                                        }
                                        <h2>DADOS DA EMPRESA</h2>
                                        <InputC text={razao_social} title={"Razão Social"} maxWidth="500px" styleContent={{margin: "0 10px 20px 10px"}} placeholder={"razão social"} width={"100%"} height={"55px"} color="#999" executar={(text) => { setRazao_social(text) }} />
                                        <InputC text={nome_fantasia} title={"Nome Fantasia"} maxWidth="560px" styleContent={{margin: "0 10px 20px 10px"}} placeholder={"nome fantasia"} width={"100%"} height={"55px"} color="#999" executar={(text) => { setNome_Fantasia(text) }} />
                                        <InputC text={cnpj} title={"CNPJ / CAEPF"} maxWidth="370px" styleContent={{margin: "0 10px 20px 10px"}} placeholder={"cnpj / caepf"} width={"100%"} height={"55px"} color="#999" executar={(text) => { setCnpj(text) }} />
                                        <InputC text={ramo_atividade} title={"Ramo de Atividade"} maxWidth="370px" styleContent={{margin: "0 10px 20px 10px"}} placeholder={"ramo de atividade"} width={"100%"} height={"55px"} color="#999" executar={(text) => { setRamo_atividade(text) }} />
                                        <InputC text={cep} title={"CEP"} maxWidth="300px" styleContent={{margin: "0 10px 20px 10px"}} placeholder={"cep "} width={"100%"} height={"55px"} color="#999" executar={(text) => { setCep(text) }} />
                                        <InputC text={endereco} title={"Endereço"} placeholder={"endereço da empresa"} styleContent={{margin: "0 10px 20px 10px"}} width={"100%"} minHeight={"90px"} multiline={true} color="#999" executar={(text) => { setEndereco(text) }} />
                                        <InputC text={nome_responsavel} title={"Nome do Responsável Assinante"} maxWidth="400px" styleContent={{margin: "0 10px 20px 10px"}}  placeholder={"nome do responsável"} width={"100%"} color="#999" executar={(text) => { setNome_responsavel(text) }} />
                                        <InputC text={cpf_responsavel} title={"CPF do Responsável"} maxWidth="400px" styleContent={{margin: "0 10px 20px 10px"}} placeholder={"cpf do responsável"} width={"100%"} height={"55px"} color="#999" executar={(text) => { setCpf_responsavel(text) }} />
                                        <InputTel text={contato_responsavel} title={"Contato do Responsável"} maxWidth="240px" styleContent={{margin: "0 10px 20px 10px"}} placeholder={"telefone"} width={"100%"} height={"55px"} color="#999" executar={(text) => { setContato_responsavel(text) }} />

                                        <h2>QUADRO DE LEVANTAMENTO DE RISCOS OCUPACIONAIS </h2>
                                        {
                                            companySector.map((item, index) => {
                                                return (
                                                    <Setor key={index} style={{borderBottomWidth: (index+1) == companySector.length ? 0 : 2}}>
                                                        <h3>Setor {index+1}</h3>
                                                        <InputC text={item.name} maxWidth="500px" styleContent={{ margin: "0 10px 20px 10px" }} title={"Setor"} placeholder={"setor"} width={"100%"} height={"55px"} color="#999" executar={(text) => { 
                                                            let companySectorC = [...companySector]
                                                            companySectorC[index].name = text
                                                            setCompanySector(companySectorC)
                                                        }} />
                                                        <InputC text={item.description} maxWidth="540px" styleContent={{ margin: "0 10px 20px 10px" }} multiline={true} title={"Descrição do Setor"} placeholder={"descrição"} width={"100%"} minHeight={"55px"} color="#999" executar={(text) => {
                                                            let companySectorC = [...companySector]
                                                            companySectorC[index].description = text
                                                            setCompanySector(companySectorC)
                                                        }} />
                                                        
                                                        <h2>FUNÇÕES DO SETOR</h2>
                                                        {
                                                                item.companyEmployees.map((data, i) => {
                                                                    return(
                                                                        <>
                                                                        <h3 style={{ display: "flex", alignItems: "center", marginTop: 15, width: "100%"}}>Função {i+1}
                                                                            { !!i && (
                                                                                <button
                                                                                    onClick={() => {
                                                                                        confirm({
                                                                                          title: 'Deseja excluir essa função?',
                                                                                          icon: <ExclamationCircleFilled/>,
                                                                                          content: `Após essa ação, a função ${i+1} será excluida.`,
                                                                                          onOk() {
                                                                                            deleteFuncao(index, i);
                                                                                          },
                                                                                          onCancel() {},
                                                                                            cancelText: "Cancelar",
                                                                                        });
                                                                                    }}
                                                                                    style={{ width: 25, height: 25, marginLeft: 8, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#f2760a", borderRadius: 6 }}
                                                                                >
                                                                                    <MdDelete color="#fff" size={18} />
                                                                                </button>
                                                                            )}
                                                                        </h3>
                                                                            <InputC text={data.name} maxWidth="245px" styleContent={{ margin: "0 10px 20px 10px" }} title={"Função"} placeholder={"funcao"} width={"100%"} height={"55px"} color="#999" executar={(text) => { 
                                                                                let companySectorC = [...companySector]
                                                                                companySectorC[index].companyEmployees[i].name = text
                                                                                setCompanySector(companySectorC)
                                                                            }} />
                                                                            <InputC text={data.cbo} maxWidth="245px" styleContent={{ margin: "0 10px 20px 10px"}}  title={"N° CBO"} placeholder={"cbo"} width={"100%"} height={"55px"} color="#999" executar={(text) => { 
                                                                                let companySectorC = [...companySector]
                                                                                companySectorC[index].companyEmployees[i].cbo = text
                                                                                setCompanySector(companySectorC)
                                                                            }} />
                                                                            <InputC text={data.quantidade_colaboradores || ""} maxWidth="245px" styleContent={{ margin: "0 10px 20px 10px"}}  title={"QTD colaboradores"} placeholder={"0"} width={"100%"} height={"55px"} color="#999" executar={(text) => { 
                                                                                let companySectorC = [...companySector]
                                                                                if (parseInt(text) > 0) {
                                                                                    companySectorC[index].companyEmployees[i].quantidade_colaboradores =  parseInt(text) || ""
                                                                                    companySectorC[index].companyEmployees[i].quantidade_colaboradores_f = parseInt(text) || ""
                                                                                    companySectorC[index].companyEmployees[i].quantidade_colaboradores_m = ""
                                                                                } else {
                                                                                    companySectorC[index].companyEmployees[i].quantidade_colaboradores = ""
                                                                                    companySectorC[index].companyEmployees[i].quantidade_colaboradores_f = ""
                                                                                    companySectorC[index].companyEmployees[i].quantidade_colaboradores_m = ""
                                                                                }
                                                                                
                                                                                setCompanySector(companySectorC)
                                                                            }} />
                                                                            <InputC text={data.quantidade_colaboradores_m || ""} maxWidth="122px" styleContent={{ margin: "0 10px 20px 10px" }} title={"QTD M"} placeholder={"0"} width={"100%"} height={"55px"} color="#999" executar={(text) => { 
                                                                                let companySectorC = [...companySector]
                                                                                if (parseInt(text) > (parseInt(companySectorC[index].companyEmployees[i].quantidade_colaboradores) || 0)) {
                                                                                    toast.warn("Número de colaboradores masculinos é maior que o total de colaboradores")
                                                                                } else {
                                                                                    companySectorC[index].companyEmployees[i].quantidade_colaboradores_m = text ? parseInt(text) : ""
                                                                                    companySectorC[index].companyEmployees[i].quantidade_colaboradores_f = text ? companySectorC[index].companyEmployees[i].quantidade_colaboradores - parseInt(text) : ""
                                                                                    setCompanySector(companySectorC)
                                                                                }
                                                                                
                                                                            }} />
                                                                            <InputC text={data.quantidade_colaboradores_f || 0} maxWidth="122px" disabled={true} styleContent={{ margin: "0 10px 20px 10px"}}  title={"QTD F"} placeholder={"0"} width={"100%"} height={"55px"} color="#999" />
                                                                            <InputC text={data.description} maxWidth="520px" styleContent={{ margin: "0 10px 20px 10px"}} multiline={true} title={"Descrição das Atividades"} placeholder={"descrição"} width={"100%"} minHeight={"90px"} color="#999" executar={(text) => { 
                                                                                let companySectorC = [...companySector]
                                                                                companySectorC[index].companyEmployees[i].description = text
                                                                                setCompanySector(companySectorC)
                                                                            }} />
                                                                            <InputC text={data.epis} maxWidth="520px" styleContent={{ margin: "0 10px 20px 10px"}} multiline={true} title={"EPIS usados na função"} placeholder={"descrição"} width={"100%"} minHeight={"90px"} color="#999" executar={(text) => { 
                                                                                let companySectorC = [...companySector]
                                                                                companySectorC[index].companyEmployees[i].epis = text
                                                                                setCompanySector(companySectorC)
                                                                            }} />
                                                                            {item.companyEmployees.length != (i+1) && <hr/>}
                                                                        </>
                                                                    )
                                                            })
                                                        }
                                                        <h2 style={{ display: "flex", alignItems: "center", marginTop: 15, width: "100%"}}>Nova Função<button
                                                            onClick={() => {
                                                                let companySectorC = [...companySector]  
                                                                let companyImagesC = [...companyImages]  
                                                                companyImagesC.push([])
                                                                companySectorC[index].companyEmployees.push(
                                                                    {
                                                                        name: "",
                                                                        id: "",
                                                                        epis: "",
                                                                        cbo: "",
                                                                        quantidade_colaboradores: 0,
                                                                        description: "",
                                                                        quantidade_colaboradores_m: 0,
                                                                        quantidade_colaboradores_f: 0,
                                                                    })
                                                                setCompanyImages(companyImagesC)
                                                                setCompanySector(companySectorC)
                                                            }}
                                                            style={{ width: 30, height: 30, marginLeft: 8, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#f2760a", borderRadius: 6 }}>
                                                            <MdAdd color="#fff" size={24} />
                                                            </button>
                                                        </h2>
                                                        <Risks>
                                                            <h2>RISCOS DO SETOR</h2>
                                                            <div>
                                                            <Risk>
                                                                    <h2>Fisico</h2>
                                                                    <AutoComplete
                                                                        style={{ width: "100%", fontSize: 14, textAlign: "left" }}
                                                                        options={[...riskFisico]}
                                                                        value={riskFisicoName}
                                                                        notFoundContent={(<>Nenhum risco encontrado</>)}
                                                                        onSelect={(text, client) => {
                                                                            if (!item.companyScratchs.filter(item => item.name == client.value && item.type == "fisico").length) {
                                                                                let companySectorC = [...companySector]
                                                                                companySectorC[index].companyScratchs.push({
                                                                                    name: client.value,
                                                                                    type: "fisico"
                                                                                })
                                                                                setCompanySector(companySectorC)
                                                                            } else {
                                                                                toast.warn("Risco já adicionado nesse setor")
                                                                            }
                                                                            setRiskFisicoName("")
                                                                            setRiskFisico(riskFisicoC)
                                                                        }}
                                                                        onSearch={(text) => {
                                                                            setRiskFisicoName(text)
                                                                                if (text) {
                                                                                    let risk = riskFisicoC.filter((item) => {
                                                                                        return item.label.toUpperCase().indexOf(text.toUpperCase()) != -1
                                                                                    }) 
                                                                                    setRiskFisico([...risk])
                                                                                } else {
                                                                                    setRiskFisico(riskFisicoC)
                                                                                }
                                                                            
                                                                        }}
                                                                        placeholder="procurar por nome"
                                                                    />
                                                                    {
                                                                        item.companyScratchs.filter((item) => item.type == "fisico").map((risk) => {
                                                                            {
                                                                                return (
                                                                                    <>
                                                                                        <p>{risk.name}</p>
                                                                                        <input
                                                                                            type='text'
                                                                                            placeholder='qual a causa?'
                                                                                            value={risk.description}
                                                                                            onChange={(text) => {
                                                                                                let indexRisk = item.companyScratchs.map(e => e.name).indexOf(risk.name)
                                                                                                let companySectorC = [...companySector]
                                                                                                companySectorC[index].companyScratchs[indexRisk].description = text.target.value
                                                                                                setCompanySector(companySectorC)
                                                                                            }}
                                                                                        />
                                                                                        <MdDelete style={{ cursor: "pointer" }} onClick={() => {
                                                                                            let companySectorC = [...companySector]
                                                                                            companySectorC[index].companyScratchs = companySectorC[index].companyScratchs.filter(item => item.name != risk.name)
                                                                                            setCompanySector(companySectorC)
                                                                                        }}/>
                                                                                    </>
                                                                                )
                                                                            }
                                                                        })
                                                                    }
                                                                </Risk>
                                                                <Risk>
                                                                    <h2>Quimico</h2>
                                                                    <AutoComplete
                                                                        style={{ width: "100%", fontSize: 14, textAlign: "left" }}
                                                                        options={[...riskQuimico]}
                                                                        value={riskQuimicoName}
                                                                        notFoundContent={(<>Nenhum risco encontrado</>)}
                                                                        onSelect={(text, client) => {
                                                                            if (!item.companyScratchs.filter(item => item.name == client.value && item.type == "quimico").length) {
                                                                                let companySectorC = [...companySector]
                                                                                companySectorC[index].companyScratchs.push({
                                                                                    name: client.value,
                                                                                    type: "quimico"
                                                                                })
                                                                                setCompanySector(companySectorC)
                                                                            } else {
                                                                                toast.warn("Risco já adicionado nesse setor")
                                                                            }
                                                                            setRiskQuimicoName("")
                                                                            setRiskQuimico(riskQuimicoC)
                                                                        }}
                                                                        onSearch={(text) => {
                                                                            setRiskQuimicoName(text)
                                                                                if (text) {
                                                                                    let risk = riskQuimicoC.filter((item) => {
                                                                                        return item.label.toUpperCase().indexOf(text.toUpperCase()) != -1
                                                                                    }) 
                                                                                    setRiskQuimico([...risk])
                                                                                } else {
                                                                                    setRiskQuimico(riskQuimicoC)
                                                                                }
                                                                            
                                                                        }}
                                                                        placeholder="procurar por nome"
                                                                    />
                                                                    {
                                                                        item.companyScratchs.filter((item) => item.type == "quimico").map((risk) => {
                                                                            {
                                                                                return (
                                                                                    <>
                                                                                        <p>{risk.name}</p>
                                                                                        <input
                                                                                            type='text'
                                                                                            placeholder='qual a causa?'
                                                                                            value={risk.description}
                                                                                            onChange={(text) => {
                                                                                                let indexRisk = item.companyScratchs.map(e => e.name).indexOf(risk.name)
                                                                                                let companySectorC = [...companySector]
                                                                                                companySectorC[index].companyScratchs[indexRisk].description = text.target.value
                                                                                                setCompanySector(companySectorC)
                                                                                            }}
                                                                                        />
                                                                                        <MdDelete style={{ cursor: "pointer" }} onClick={() => {
                                                                                            let companySectorC = [...companySector]
                                                                                            companySectorC[index].companyScratchs = companySectorC[index].companyScratchs.filter(item => item.name != risk.name)
                                                                                            setCompanySector(companySectorC)
                                                                                        }}/>
                                                                                    </>
                                                                                )
                                                                            }
                                                                        })
                                                                    }
                                                                </Risk>
                                                                <Risk>
                                                                    <h2>Acidente</h2>
                                                                    <AutoComplete
                                                                        style={{ width: "100%", fontSize: 14, textAlign: "left" }}
                                                                        options={[...riskAcidente]}
                                                                        value={riskAcidenteName}
                                                                        notFoundContent={(<>Nenhum risco encontrado</>)}
                                                                        onSelect={(text, client) => {
                                                                            if (!item.companyScratchs.filter(item => item.name == client.value && item.type == "acidente").length) {
                                                                                let companySectorC = [...companySector]
                                                                                companySectorC[index].companyScratchs.push({
                                                                                    name: client.value,
                                                                                    type: "acidente"
                                                                                })
                                                                                setCompanySector(companySectorC)
                                                                            } else {
                                                                                toast.warn("Risco já adicionado nesse setor")
                                                                            }
                                                                            setRiskAcidenteName("")
                                                                            setRiskAcidente(riskAcidenteC)
                                                                        }}
                                                                        onSearch={(text) => {
                                                                            setRiskAcidenteName(text)
                                                                                if (text) {
                                                                                    let risk = riskAcidenteC.filter((item) => {
                                                                                        return item.label.toUpperCase().indexOf(text.toUpperCase()) != -1
                                                                                    }) 
                                                                                    setRiskAcidente([...risk])
                                                                                } else {
                                                                                    setRiskAcidente(riskAcidenteC)
                                                                                }
                                                                            
                                                                        }}
                                                                        placeholder="procurar por nome"
                                                                    />
                                                                    {
                                                                        item.companyScratchs.filter((item) => item.type == "acidente").map((risk) => {
                                                                            {
                                                                                return (
                                                                                    <>
                                                                                        <p>{risk.name}</p>
                                                                                        <input
                                                                                            type='text'
                                                                                            placeholder='qual a causa?'
                                                                                            value={risk.description}
                                                                                            onChange={(text) => {
                                                                                                let indexRisk = item.companyScratchs.map(e => e.name).indexOf(risk.name)
                                                                                                let companySectorC = [...companySector]
                                                                                                companySectorC[index].companyScratchs[indexRisk].description = text.target.value
                                                                                                setCompanySector(companySectorC)
                                                                                            }}
                                                                                        />
                                                                                        <MdDelete style={{ cursor: "pointer" }} onClick={() => {
                                                                                            let companySectorC = [...companySector]
                                                                                            companySectorC[index].companyScratchs = companySectorC[index].companyScratchs.filter(item => item.name != risk.name)
                                                                                            setCompanySector(companySectorC)
                                                                                        }}/>
                                                                                    </>
                                                                                )
                                                                            }
                                                                        })
                                                                    }
                                                                </Risk>
                                                                <Risk>
                                                                    <h2>Ergonomico</h2>
                                                                    <AutoComplete
                                                                        style={{ width: "100%", fontSize: 14, textAlign: "left" }}
                                                                        options={[...riskErgonomico]}
                                                                        value={riskErgonomicoName}
                                                                        notFoundContent={(<>Nenhum risco encontrado</>)}
                                                                        onSelect={(text, client) => {
                                                                            if (!item.companyScratchs.filter(item => item.name == client.value && item.type == "ergonomico").length) {
                                                                                let companySectorC = [...companySector]
                                                                                companySectorC[index].companyScratchs.push({
                                                                                    name: client.value,
                                                                                    type: "ergonomico"
                                                                                })
                                                                                setCompanySector(companySectorC)
                                                                            } else {
                                                                                toast.warn("Risco já adicionado nesse setor")
                                                                            }
                                                                            setRiskErgonomicoName("")
                                                                            setRiskErgonomico(riskErgonomicoC)
                                                                        }}
                                                                        onSearch={(text) => {
                                                                            setRiskErgonomicoName(text)
                                                                                if (text) {
                                                                                    let risk = riskErgonomicoC.filter((item) => {
                                                                                        return item.label.toUpperCase().indexOf(text.toUpperCase()) != -1
                                                                                    }) 
                                                                                    setRiskErgonomico([...risk])
                                                                                } else {
                                                                                    setRiskErgonomico(riskErgonomicoC)
                                                                                }
                                                                            
                                                                        }}
                                                                        placeholder="procurar por nome"
                                                                    />
                                                                    {
                                                                        item.companyScratchs.filter((item) => item.type == "ergonomico").map((risk) => {
                                                                            {
                                                                                return (
                                                                                    <>
                                                                                        <p>{risk.name}</p>
                                                                                        <input
                                                                                            type='text'
                                                                                            placeholder='qual a causa?'
                                                                                            value={risk.description}
                                                                                            onChange={(text) => {
                                                                                                let indexRisk = item.companyScratchs.map(e => e.name).indexOf(risk.name)
                                                                                                let companySectorC = [...companySector]
                                                                                                companySectorC[index].companyScratchs[indexRisk].description = text.target.value
                                                                                                setCompanySector(companySectorC)
                                                                                            }}
                                                                                        />
                                                                                        <MdDelete style={{ cursor: "pointer" }} onClick={() => {
                                                                                            let companySectorC = [...companySector]
                                                                                            companySectorC[index].companyScratchs = companySectorC[index].companyScratchs.filter(item => item.name != risk.name)
                                                                                            setCompanySector(companySectorC)
                                                                                        }}/>
                                                                                    </>
                                                                                )
                                                                            }
                                                                        })
                                                                    }
                                                                </Risk>
                                                                <Risk>
                                                                    <h2>Biologico</h2>
                                                                    <AutoComplete
                                                                        style={{ width: "100%", fontSize: 14, textAlign: "left" }}
                                                                        options={[...riskBiologico]}
                                                                        value={riskBiologicoName}
                                                                        notFoundContent={(<>Nenhum risco encontrado</>)}
                                                                        onSelect={(text, client) => {
                                                                            if (!item.companyScratchs.filter(item => item.name == client.value && item.type == "biologico").length) {
                                                                                let companySectorC = [...companySector]
                                                                                companySectorC[index].companyScratchs.push({
                                                                                    name: client.value,
                                                                                    description: "",
                                                                                    type: "biologico"
                                                                                })
                                                                                setCompanySector(companySectorC)
                                                                            } else {
                                                                                toast.warn("Risco já adicionado nesse setor")
                                                                            }
                                                                            setRiskBiologicoName("")
                                                                            setRiskBiologico(riskBiologicoC)
                                                                        }}
                                                                        onSearch={(text) => {
                                                                            setRiskBiologicoName(text)
                                                                                if (text) {
                                                                                    let risk = riskBiologicoC.filter((item) => {
                                                                                        return item.label.toUpperCase().indexOf(text.toUpperCase()) != -1
                                                                                    }) 
                                                                                    setRiskBiologico([...risk])
                                                                                } else {
                                                                                    setRiskBiologico(riskBiologicoC)
                                                                                }
                                                                            
                                                                        }}
                                                                        placeholder="procurar por nome"
                                                                    />
                                                                    {
                                                                        item.companyScratchs.filter((item) => item.type == "biologico").map((risk) => {
                                                                            {
                                                                                return (
                                                                                    <>
                                                                                        <p>{risk.name}</p>
                                                                                        <input
                                                                                            type='text'
                                                                                            placeholder='qual a causa?'
                                                                                            value={risk.description}
                                                                                            onChange={(text) => {
                                                                                                let indexRisk = item.companyScratchs.map(e => e.name).indexOf(risk.name)
                                                                                                let companySectorC = [...companySector]
                                                                                                companySectorC[index].companyScratchs[indexRisk].description = text.target.value
                                                                                                setCompanySector(companySectorC)
                                                                                            }}
                                                                                        />
                                                                                        <MdDelete style={{ cursor: "pointer" }} onClick={() => {
                                                                                            let companySectorC = [...companySector]
                                                                                            companySectorC[index].companyScratchs = companySectorC[index].companyScratchs.filter(item => item.name != risk.name)
                                                                                            setCompanySector(companySectorC)
                                                                                        }}/>
                                                                                    </>
                                                                                )
                                                                            }
                                                                        })
                                                                    }
                                                                </Risk> 
                                                            </div>
                                                        </Risks>
                                                        <h2>IMAGENS DO SETOR (máx 5)</h2>
                                                        <Images>
                                                            {
                                                                companyImages[index].length <= 4 && (
                                                                    <Image style={{position: "relative"}}>
                                                                        <h2 style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: 15, width: "100%" }}>Nova Imagem<br />
                                                                        <button 
                                                                            style={{ width: 30, height: 30, marginLeft: 8, display: "flex", alignItems: "center", justifyContent: "center", marginTop: 10, backgroundColor: "#f2760a", borderRadius: 6 }}>
                                                                        <MdAdd color="#fff" size={24} />
                                                                        </button>
                                                                        </h2>
                                                                        <input id="avatar" style={{
                                                                            cursor: "pointer", position: "absolute",
                                                                            width: "100%", height: "100%", opacity: 0
                                                                        }} multiple={false} type="file" accept="image/" onChange={async (file) => {
                                                                            let [filesUp] = Object.values(file.target.files)
                                                                            const data = new FormData();
                                                                            data.append('index', index);
                                                                            data.append('file', filesUp, filesUp.name);
                                                                            await api.post(`/company-image/${id}`, data).then(async (response) => {
                                                                                let companyImagesC = [...companyImages]  
                                                                                companyImagesC[index].push(response.data)
                                                                                setCompanyImages(companyImagesC)
                                                                                toast.success(`Imagem foi enviada com sucesso`)
                                                                            }).catch(({ response }) => {
                                                                                if (response) {
                                                                                    if (response.data) {
                                                                                        if (response.data.message) {
                                                                                            toast.warn(response.data.message)
                                                                                        } else {
                                                                                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                                                                                        }
                                                                                    } else {
                                                                                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                                                                                    }
                                                                                } else {
                                                                                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                                                                                }
                                                                            })
                                                            }} />
                                                                    </Image>
                                                                )
                                                            }
                                                            {companyImages[index].map((item) => {
                                                                return (
                                                                    <Image>
                                                                        <img src={item.photo_url} />
                                                                        <button 
                                                                            onClick={async () => {
                                                                                await api.delete(`/company-image/${item.id}`).then(async () => {
                                                                                    let companyImagesC = [...companyImages]  
                                                                                    companyImagesC[index] = companyImagesC[index].filter((data)=>{ return item.id != data.id})
                                                                                    setCompanyImages(companyImagesC)
                                                                                    toast.success(`Imagem foi deletada com sucesso`)
                                                                                }).catch(({ response }) => {
                                                                                    if (response) {
                                                                                        if (response.data) {
                                                                                            if (response.data.message) {
                                                                                                toast.warn(response.data.message)
                                                                                            } else {
                                                                                                toast.error("Erro Interno. verifique sua conexão e tente novamente")
                                                                                            }
                                                                                        } else {
                                                                                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                                                                                        }
                                                                                    } else {
                                                                                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                                                                                    }
                                                                                })
                                                                            }}
                                                                            style={{ width: 30, cursor: "pointer", height: 30, marginBottom: 25, display: "flex", alignItems: "center", justifyContent: "center", marginTop: 10, backgroundColor: "#f2760a", borderRadius: 6 }}>
                                                                        <MdDelete color="#fff" size={24} />
                                                                        </button>
                                                                    </Image>
                                                                )
                                                            })}
                                                        </Images>
                                                    </Setor>
                                                )
                                            })
                                        }
                                        <h2 style={{ display: "flex", alignItems: "center", marginTop: 15, width: "100%", justifyContent: "flex-end" }}><button
                                                onClick={() => {
                                                    
                                                    let companySectorC = [...companySector]  
                                                    let companyImagesC = [...companyImages]  
                                                    companySectorC.push({
                                                        name: "",
                                                        description: "",
                                                        companyScratchs: [],
                                                        companyEmployees: [
                                                            {
                                                                name: "",
                                                                id: "",
                                                                epis: "",
                                                                cbo: "",
                                                                quantidade_colaboradores: 0,
                                                                description: "",
                                                                quantidade_colaboradores_m: 0,
                                                                quantidade_colaboradores_f: 0
                                                            }
                                                        ]
                                                    })
                                                    companyImagesC.push([])
                                                    setCompanyImages(companyImagesC)
                                                    setCompanySector(companySectorC)
                                                }}
                                                style={{ width: 30, height: 30, marginRight: 8, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#f2760a", borderRadius: 6 }}>
                                                <MdAdd color="#fff" size={24} />
                                            </button>Novo Setor
                                        </h2>
                                        
                                        <h4>Total de Funionários: {total}</h4>
                                        <h4>Total de Funionários Femininos: {total - totalM || 0}</h4>
                                        <h4>Total de Funionários Masculinos: {totalM}</h4>
                                            
                                        <div style={{ display: "flex", width: "100%", justifyContent: "center", margin: "35px 0" }}>
                                            {
                                                user && user.id == company.collaborador_id && (
                                                    <ButtonC text={""} title={"Salvar"} style={{margin: "0 10px"}} height="50px"
                                                        width={"45%"} executar={() => {
                                                            verifyCompany(false)
                                                    }} />   
                                                )
                                            }
                                                <ButtonC text={""} title={"Salvar e Assinar"} style={{margin: "0 10px"}} height="50px"
                                                        width={"45%"} executar={() => {
                                                        if(user && user.id == company.collaborador_id){
                                                            setName("")
                                                            setDoc("")
                                                            setVisibleModal(true)
                                                        } else {
                                                            verifyCompany(false)
                                                        }
                                                }} />
                                        </div>
                                    </>        
                                )
                            }
                        </>
                        
                    )
                }
                <ModalC visible={visibleModal} executar={() => { setVisibleModal(false) }}>
                    <div style={{ paddingTop: 0, width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>

                        <p style={{
                            width: "90%",
                            fontSize: 17,
                            margin: "15px 0",
                            textAlign: "center", color: "#fff"
                        }}>Digite seu Nome e CPF para validar o formulário</p>

                        <InputC text={name} color="#FFF" title={"Nome do Responsável"} placeholder={"nome completo"} width={"100%"} height={"55px"} executar={(text) => { setName(text) }} />
                        <InputC text={doc} color="#FFF" title={"CPF do Responsável"} placeholder={"cpf"} width={"100%"} height={"55px"} executar={(text) => { setDoc(text) }} />
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: "space-between"
                        }}>
                            <div style={{
                                display: "flex",
                                flexWrap: "wrap",
                                width: "100%",
                                justifyContent: "center", marginTop: 25
                            }}>

                                <ButtonC text={""} title={"Validar Formulário"} background={(name && doc) ? "#25b03a" : "#25b03a98"}
                                    width={"70%"} executar={() => {
                                        confirmCompany(true)
                                    }} />
                            </div>
                            <div style={{
                                display: "flex",
                                flexWrap: "wrap",
                                width: "100%",
                                justifyContent: "center", marginTop: 15
                            }}>

                                <ButtonC text={""} title={"Cancelar"} background={"#888"}
                                    width={"70%"} executar={() => {
                                        setVisibleModal(false)
                                    }} />
                            </div>
                        </div>
                    </div>
                </ModalC>
            </Container>
        </div>
    )
} 