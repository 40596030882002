import styled from "styled-components";

export const Container = styled.div`
  width: 100%; 
  align-items: center;
  min-height: 100vh;
  display: flex;
  padding: 40px 0;
  flex-direction: column;

  h1{
    text-align: center;
    margin-bottom: 35px;
    color: #f2760a;
  }
  @media (max-width: 750px) {
  padding: 30px 0;
  width: 95%;
  h1{
    text-align: center;
    font-size: 22px;
    margin-bottom: 15px;
  }
  }
`;

export const List = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  div{
  border-bottom: 2px solid #f2760a;
  padding: 10px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 10px; 

      div{
        border: none;
        flex-direction: column;
  justify-content: center;
        padding: 0;
        margin: 0;
        p{
          margin: 0px;
          font-size: 14px;
          text-align: left;
        }
        div{
        border: none;
        flex-direction: row;
  justify-content: flex-start;
  align-items: center;
        padding: 0;
        margin: 0;
          margin-top: 5px;
        h1{
          margin: 0px;
          font-size: 16px;
          text-align: left;
        }
      }
      }

      button{
        padding: 6px 15px;
        max-height: 50px;
        border-radius: 5px;
        background-color: #f2760a;
        color: #fff;
        margin-left: 10px;
        max-width: 150px;
        align-items: center;
        justify-content: center;
    }

  }
`;
