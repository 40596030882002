import React, { useEffect, useState } from 'react';
import { Container, Services } from './styles';
import { useNavigate } from "react-router-dom";
import loadingfile from "./../../../images/loading.gif"
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { InputC, ButtonC, InputNumberC, InputTel } from "../../../components/simple";
import Header from '../../../components/headersd';
import HeaderAdmin from '../../../components/headerAdmin';
import ModalC from '../../../components/modal';
import { useAuth } from '../../../hooks/AuthContext';
import { MdAdd } from 'react-icons/md';
import { InputLabelMUI } from '../../../components/simple/styles';


export default function NewContract() {

    const { user } = useAuth();

    const navigate = useNavigate();


    const [nameService, setNameService] = useState("")
    const [valueService, setValueService] = useState(0)
    const [amountService, setAmountService] = useState(0)
    const [descriptionService, setDescriptionService] = useState("")

    const [loading, setLoading] = useState(false)
    const [services, setServices] = useState([])
    const [servicesContract, setServicesContract] = useState([])
    const [servicesObj, setServicesObj] = useState({})
    const [name, setName] = useState("")
    const [nameModal, setNameModal] = useState("")
    const [company, setCompany] = useState("")
    const [consultant, setConsultant] = useState("")
    const [phone_number, setPhone_number] = useState("")
    const [contact, setContact] = useState("")
    const [banking, setBanking] = useState("FORMA DE PAGAMENTO: Á VISTA\n\nPRAZO DE ENTREGA: EM ATÉ 24 HORAS")
    const [visibleModal, setVisibleModal] = useState(false)
    const [visibleModalService, setVisibleModalService] = useState(false)
    const [nameExtra, setNameExtra] = useState("")
    const [descriptionExtra, setDescriptionExtra] = useState("")
    const [valueExtra, setValueExtra] = useState(0)
    const [risco, setRisco] = useState(0)
    const [vidas, setVidas] = useState(0)
    const [desconto, setDesconto] = useState(0)
    const [value, setValue] = useState(0)
    const [valueToLife, setValueToLife] = useState(0)
    const [initialValue, setInitialValue] = useState(0)
    const [description, setDescription] = useState("")
    const [descriptionGM, setDescriptionGM] = useState("")
    const [visibleModalServiceGM, setVisibleModalServiceGM] = useState(false)

    useEffect(() => {

        if (user.type == "tecnico") {
            navigate(-1)
        } else {
            if (user.course_restricted) {
                toast.warn("Área restrita para franqueados")
                navigate(-1)
            } else {
                loadingServices()
            }
        }
    }, [])

    function totalValue() {
        let value = 0
        servicesContract.map((item) => {
            value += item.amount * item.value
        })
        return value
    }

    function getValueToLife(vidas) {
        if (!vidas) {
            vidas = 0
        }
        if (risco == 1 || risco == 2) {
            if (vidas <= 10) {
                setValueToLife(12)
            } else {
                if (vidas <= 20) {
                    setValueToLife(11.88)
                } else {

                } if (vidas <= 30) {
                    setValueToLife(11.76)
                } else {

                    setValueToLife(11.64)
                }
            }
        } else {
            if (vidas <= 10) {
                setValueToLife(15)
            } else {
                if (vidas <= 20) {
                    setValueToLife(14.85)
                } else {

                } if (vidas <= 30) {
                    setValueToLife(14.70)
                } else {

                    setValueToLife(14.55)
                }
            }
        }

    }

    async function loadingServices() {
        await api.get(`/services-client`).then((response) => {
            let servicesObj = {}
            response.data.map((item) => {
                servicesObj[item.name] = {
                    name: item.name,
                    description: item.description,
                    value: item.value,
                }
            })
            setServicesObj(servicesObj)
            setServices(response.data)
        }).catch(({ response }) => {
            if (response) {
                if (response.data) {
                    if (response.data.message) {
                        toast.warn(response.data.message)
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            } else {
                toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
        })
        setLoading(false)
    }

    async function createContract() {
        if (!name || !company || !contact || !consultant || !phone_number || !banking) {
            toast.warn('Preencha todos os campos básicos');
        } else {
            let items = []
            servicesContract.map((item) => {
                if (item.amount > 0) {
                    items.push(item)
                }
            })
            await api.post(`/contract`, {
                name,
                company,
                contact,
                consultant,
                phone_number,
                banking,
                services_gestao: descriptionGM,
                services: items,
                risk: parseFloat(risco),
                lifes: parseFloat(vidas),
                initial_value: parseFloat(initialValue),
                life_value: parseFloat(valueToLife),
                discount: parseFloat(desconto),
                service_name: nameExtra,
                service_value: parseFloat(valueExtra),
                service_description: descriptionExtra
            }).then(async (ok) => {
                toast.success("Contrato criada com sucesso")
                navigate(-1)
            }).catch(({ response }) => {
                if (response) {
                    if (response.data) {
                        if (response.data.message) {
                            toast.warn(response.data.message)
                        } else {
                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                        }
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            })

        }
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Container>
                {
                    loading ? (
                        <img src={loadingfile} style={{ background: "transparent", height: 50, width: 50 }} />
                    ) : (
                        <>

                            <h1>Contrato</h1>

                            <h2>Dados do contrato</h2>
                            <InputC text={company} title={"Nome da Empresa"} placeholder={"guruseg"} width={"100%"} height={"55px"} color="#999" executar={(text) => { setCompany(text) }} />
                            <InputC text={name} title={"Nome do Contato"} placeholder={"nome"} width={"100%"} height={"55px"} color="#999" executar={(text) => { setName(text) }} />
                            <InputTel text={contact} title={"Telefone do Contato"} placeholder={"telefone"} width={"100%"} height={"55px"} color="#999" executar={(text) => { setContact(text) }} />
                            <InputC text={consultant} title={"Consultor"} placeholder={"consultor"} width={"100%"} height={"55px"} color="#999" executar={(text) => { setConsultant(text) }} />
                            <InputTel text={phone_number} title={"Telefone"} placeholder={"telefone"} width={"100%"} height={"55px"} color="#999" executar={(text) => { setPhone_number(text) }} />
                            <InputC text={banking} title={"Dados bancarios"} placeholder={"dados bancarios"} width={"100%"} minHeight={"155px"} multiline={true} color="#999" executar={(text) => { setBanking(text) }} />

                            <h2>Serviços de Elaboração e Gestão em segurança do Trabalho</h2>
                            <h3>Grau de Risco</h3>
                            <select
                                value={risco}
                                style={{
                                    width: "100%",
                                    backgroundColor: "#FFF",
                                    borderWidth: 1,
                                    borderColor: "#ccc",
                                    borderStyle: "solid",
                                    color: "#444",
                                    padding: "10px",
                                    fontSize: 14,
                                    borderRadius: 5,
                                    fontWeight: "400",
                                    paddingLeft: 8,
                                }}
                                onChange={(text) => {
                                    setRisco(text.target.value)
                                    getValueToLife(vidas)
                                }}
                            >
                                <option value={0}>Seleciona uma opção</option>
                                <option value={1}>Grau 1</option>
                                <option value={2}>Grau 2</option>
                                <option value={3}>Grau 3</option>
                                <option value={4}>Grau 4</option>
                            </select>
                            {
                                risco != 0 && (
                                    <>
                                        <h2 style={{ display: "flex", alignItems: "center", marginTop: 15 }}>Valor por vida:
                                            <InputNumberC style={{ height: 35, marginLeft: 10 }} text={valueToLife} width={"150px"} height={"35px"} title={""} executar={(text) => {
                                                setValueToLife(text)
                                            }} /></h2>
                                        <InputC text={vidas} type="number" title={"Vidas"} placeholder={"Vidas"} width={"100%"} height={"55px"} color="#999" executar={(text) => {
                                            setVidas(text)
                                            getValueToLife(parseFloat(text))
                                        }} />
                                        <h2 style={{ display: "flex", alignItems: "center", marginTop: 5 }}>Valor de Entrada:
                                            <InputNumberC style={{ height: 35, marginLeft: 10 }} text={initialValue} width={"150px"} height={"35px"} title={""} executar={(text) => {
                                                setInitialValue(text)
                                            }} /></h2>
                                        {
                                            vidas > 0 && (
                                                <>
                                                    <h3>Valores</h3>
                                                    <h2 style={{ marginTop: 5 }}>Entrada:  {(initialValue ? initialValue : 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                        {" "}-  Mensal:  {(57 + (vidas ? vidas : 0) * (valueToLife ? valueToLife : 0)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                        {" "}- Anual:  {((initialValue ? initialValue : 0) + ((57 + (vidas ? vidas : 0) * (valueToLife ? valueToLife : 0))) * 12).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</h2>

                                                </>
                                            )
                                        }
                                        <h2 style={{ display: "flex", alignItems: "center", marginTop: 15 }}>Serviços Inclusos<button
                                            onClick={() => {
                                                setVisibleModalServiceGM(true)
                                            }}
                                            style={{ width: 30, height: 30, marginLeft: 8, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#f2760a", borderRadius: 6 }}>
                                            <MdAdd color="#fff" size={24} />
                                        </button>
                                        </h2>
                                        <InputC text={descriptionGM} type="text" title={""} placeholder={"Descreva os serviços inclusos na Gestão Mensal"} width={"100%"} multiline
                                            minHeight={"150px"} height={"55px"} color="#999"
                                            style={{ textAlign: "left" }}
                                            executar={(text) => {
                                                setDescriptionGM(text)
                                            }} />
                                    </>
                                )
                            }
                            <h2 style={{ display: "flex", alignItems: "center", marginTop: 15 }}>Serviços Avulsos<button
                                onClick={() => {
                                    setVisibleModalService(true)
                                }}
                                style={{ width: 30, height: 30, marginLeft: 8, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#f2760a", borderRadius: 6 }}>
                                <MdAdd color="#fff" size={24} />
                            </button>
                            </h2>
                            <Services>
                                {
                                    servicesContract.length == 0 && (
                                        <h2 style={{ margin: "25px 0", color: "#000", textAlign: "center" }}>Adicione algum serviço</h2>
                                    )
                                }
                                {
                                    servicesContract.map((item, index) => {
                                        return (
                                            <div>
                                                <div>
                                                    <button onClick={() => {
                                                        let servicesContractA = [...servicesContract]
                                                        servicesContractA[index].amount++
                                                        setServicesContract(servicesContractA)
                                                    }}>+</button>
                                                    <span>{item.amount}</span>
                                                    <button onClick={() => {
                                                        let servicesContractA = [...servicesContract]
                                                        if (servicesContractA[index].amount > 0) {
                                                            servicesContractA[index].amount--
                                                            setServicesContract(servicesContractA)
                                                        }

                                                    }}>-</button>
                                                </div>
                                                <p style={{ flex: 1, minWidth: 100 }}>{item.name}</p>
                                                {item.description && (<button onClick={() => {
                                                    setDescription(item.description)
                                                    setNameModal(item.name)
                                                    setValue(item.value)
                                                    setVisibleModal(true)
                                                }}>detalhes</button>)}
                                                <p style={{ flex: 1, margin: "8px 0", maxWidth: 85, textAlign: "right" }}>{item.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>

                                            </div>)
                                    })}
                            </Services>
                            <InputC text={nameExtra} title={"Serviço Extra"} placeholder={"nome"} width={"100%"} height={"55px"} color="#999" executar={(text) => { setNameExtra(text) }} />

                            <InputNumberC text={valueExtra} type="number" title={"Valor Extra"} placeholder={"valor"} width={"100%"} height={"55px"} color="#999" executar={(text) => { setValueExtra(text) }} />
                            <InputC text={descriptionExtra} title={"Descrição do Serviço Extra"} placeholder={"descrição"} width={"100%"} minHeight={"155px"} multiline={true} color="#999" executar={(text) => { setDescriptionExtra(text) }} />

                            <h2>Total:  {(totalValue() + (parseFloat(valueExtra ? valueExtra : 0))).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</h2>
                            <InputNumberC text={desconto} type="number" title={"Desconto"} placeholder={"desconto"} width={"100%"} height={"55px"} color="#999" executar={(text) => { setDesconto(text) }} />

                            <h2>Total com Desconto:  {(totalValue() + (parseFloat(valueExtra ? valueExtra : 0)) - (parseFloat(desconto ? desconto : 0))).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</h2>

                            <div style={{ display: "flex", width: "100%", justifyContent: "space-between", margin: "35px 0" }}>
                                <ButtonC text={""} title={"Voltar"}
                                    width={"40%"} executar={() => {
                                        navigate(-1)
                                    }} />
                                <ButtonC text={""} title={"Salvar"}
                                    width={"55%"} executar={() => {
                                        createContract()
                                    }} />
                            </div>

                        </>
                    )
                }

            </Container>
            <ModalC visible={visibleModal} executar={() => { setVisibleModal(false) }}>
                <div style={{ paddingTop: 0, width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <h2 style={{ textAlign: "center", color: "#fff" }}>{nameModal}</h2>
                    <p style={{ textAlign: "center", color: "#fff", margin: "15px 0", fontSize: 16 }}>{description}</p>
                    <h3 style={{ textAlign: "center", color: "#fff" }}>{value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</h3>
                    <div style={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                        justifyContent: "space-between"
                    }}>
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: "center", marginTop: 25
                        }}>



                            <ButtonC text={""} title={"Fechar"} background={"#888"}
                                width={"50%"} executar={() => {
                                    setVisibleModal(false)
                                }} />
                        </div>
                    </div>
                </div>
            </ModalC>
            <ModalC visible={visibleModalService} executar={() => { setVisibleModalService(false) }}>
                <div style={{
                    display: "flex",
                    flexWrap: "wrap",
                    width: "100%",
                    justifyContent: "space-between"
                }}>
                    <InputLabelMUI>
                        Nome do serviço</InputLabelMUI>
                    <input value={nameService} placeholder={"serviço"} list="services"
                        style={{
                            width: "100%",
                            background: "#FFF",
                            borderRadius: 5,
                            height: 45,
                            fontSize: 16,
                            borderWidth: 1,
                            borderColor: "#a2a2a2",
                            borderStyle: "solid",
                            borderRadius: 5,
                            fontWeight: "400",
                            paddingLeft: 12,
                        }}
                        onChange={(text) => {
                            setNameService(text.target.value)
                            if (servicesObj[text.target.value]) {
                                setValueService(servicesObj[text.target.value].value)
                                setDescriptionService(servicesObj[text.target.value].description)
                            }
                        }} />
                    <datalist id="services" >
                        {services.map((item) => {
                            return (
                                <option>{item.name}</option>
                            )
                        })}
                    </datalist>
                    <InputNumberC text={valueService} title={"Valor"} placeholder={"0,00"} width={"100%"} height={"55px"} executar={(text) => { setValueService(text) }} />
                    <InputC text={amountService} type="number" title={"Quantidade"} placeholder={"0"} width={"100%"} height={"55px"} executar={(text) => { setAmountService(text) }} />
                    <InputC text={descriptionService} title={"Descrição"} placeholder={"Descrição detalhada sobre o Serviço"} width={"100%"} height={120} multiline={true} executar={(text) => { setDescriptionService(text) }} />
                    <div style={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                        justifyContent: "center", marginTop: 15
                    }}>

                        <ButtonC text={""} title={"Adicionar"}
                            width={"60%"} executar={() => {
                                if (!nameService || !valueService) {
                                    toast.warning("Preencha pelo menos o nome e o valor")
                                } else {
                                    let servicesC = [...servicesContract]
                                    servicesC.push({
                                        name: nameService,
                                        description: descriptionService,
                                        value: valueService,
                                        amount: amountService,
                                    })
                                    setServicesContract(servicesC)
                                    setNameService("")
                                    setDescriptionService("")
                                    setValueService(0)
                                    setAmountService(0)
                                    toast.success("Serviço adicionado com sucesso")
                                }

                            }} />
                    </div>
                </div>

            </ModalC>
            <ModalC visible={visibleModalServiceGM} executar={() => { setVisibleModalServiceGM(false) }}>
                <div style={{
                    display: "flex",
                    flexWrap: "wrap",
                    width: "100%",
                    justifyContent: "space-between"
                }}>
                    <InputLabelMUI style={{
                        width: "100%",
                    }}>
                        Nome do serviço</InputLabelMUI>
                    <input value={nameService} placeholder={"serviço"} list="services"
                        style={{
                            flex: 1,
                            background: "#FFF",
                            borderRadius: 5,
                            height: 45,
                            fontSize: 16,
                            borderWidth: 1,
                            borderColor: "#c2c2c2",
                            borderStyle: "solid",
                            borderRadius: 5,
                            fontWeight: "400",
                            paddingLeft: 12,
                        }}
                        onChange={(text) => {
                            setNameService(text.target.value)
                        }} />
                    <datalist id="services" >
                        {services.map((item) => {
                            return (
                                <option>{item.name}</option>
                            )
                        })}
                    </datalist>
                    <button
                        onClick={() => {
                            if (servicesObj[nameService]) {
                                setDescriptionGM(prev => prev + "- " + servicesObj[nameService].name + "\n")
                            } else {
                                setDescriptionGM(prev => prev + "- " + nameService + "\n")
                            }
                            setNameService("")
                        }}
                        style={{ width: 45, height: 45, marginLeft: 12, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#f2760a", borderRadius: 6 }}>
                        <MdAdd color="#fff" size={24} />
                    </button>
                    <InputC text={descriptionGM} type="text" title={""} placeholder={"Descreva os serviços inclusos na Gestão Mensal"} width={"100%"} multiline
                        minHeight={"150px"} height={"55px"} color="#999"
                        style={{ textAlign: "left" }}
                        executar={(text) => {
                            setDescriptionGM(text)
                        }} />
                    <div style={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                        justifyContent: "center", marginTop: 15
                    }}>

                        <ButtonC text={""} title={"Fechar"}
                            width={"60%"} executar={() => {
                                setVisibleModalServiceGM(false)
                            }} />
                    </div>
                </div>

            </ModalC>
        </div>
    )
} 