import styled from "styled-components";

export const Container = styled.div`
  width: 100%; 
  align-items: center;
  min-height: 100vh;
  display: flex;
  padding: 40px 0;
  flex-direction: column;
  align-items: center;
  h1{
    text-align: left;
    color: #f2760a;
  }


  h3{
    text-align: center;
    margin-bottom: 25px;
  }

  a{
    background-color: #f2760a;
    color: #fff;
    width: 150px;
    margin-bottom: 15px;
    padding: 15px 20px;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
  }
  
  @media (max-width: 750px) {
  padding: 30px 0;
  width: 95%;
  h1{
    text-align: center;
    font-size: 22px;
    margin-bottom: 15px;
  }
  }

`;

export const Franqueado = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 35px 0;
  align-items: center;
  justify-content: center;

  img{
      max-height: 450px;
      border-radius: 10px;
      object-fit: cover;
  }

  div{
  background-color: #f2760a;
  padding: 10px;
  width: 100%;
  max-width: 450px;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  margin: 10px;


    img{
      width: 80px;
      height: 80px;
      margin-right: 10px;
      border-radius: 10px;
    }
    div{
      flex-direction: column;
      margin: 0px;
      padding: 0px;
      p{
        color: #fff;
      }
      button{
        margin-top: 15px;
        padding: 8px 15px;
        border-radius: 5px;
        background-color: #fff;
        color: #363636;
        max-width: 150px;
        align-items: center;
        justify-content: center;
      }
    }



  }
  @media (max-width: 800px) {
  margin: 5px 0 15px 0;
    img{
      width: 95%;
  }
  

  }
`;
