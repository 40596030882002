import styled from "styled-components";

export const Container = styled.div`
  width: 100%; 
  align-items: center;
  min-height: 100vh;
  display: flex;
  padding: 40px 0;
  flex-direction: column;
  align-items: center;

  img{
    width:150px ;
    height:150px ;
    border-radius:150px;
    margin-bottom: 20px;
  }

  p{
    text-align: center;
    font-size: 20px;
    margin: 5px 0;
  }

  h1{
    text-align: center;
    margin: 20px 0;
    font-size: 25px;
  }

  div{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 5px 0;
    justify-content: center;
    align-items: center;
    button{
      padding: 12px 25px;
      border-radius: 5px;
      margin: 0 10px;
      background-color: #dd0000;
      color: #fff;
      font-size: 16px;
      max-width: 150px;
      align-items: center;
      justify-content: center;
    }

  }
  @media (max-width: 750px) {
  padding: 30px 0;
  width: 95%;
  h1{
    text-align: center;
    font-size: 22px;
    margin-bottom: 15px;
  }
  }
`;
