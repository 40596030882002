import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 5px;
  width:100vw ;
  align-items: center;
  background-color: #AAC8AA;

  img{
    height: 90px;
  }

  div{
    display: flex;
    padding: 0 25px;
    align-items: center;
    justify-content: center;
    div{
      padding: 8px 0px;
      align-items: center;
      border-radius: 8px;
      p{
      margin: 0px;
      }
      img{
        margin-left: 10px;
        width: 50px;
        height: 50px;
        border-radius: 50px;
      }
    }
  }
  a{
    background-color: #fff;
    color: #363636;
    margin: 0 25px;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button{
    padding: 10px 25px;
    border-width: 0px;
    margin: 10px 25px;
    border-radius: 5px;
    background-color: #B93030;
    color: #fff;
    align-items: center;
    justify-content: center;
  }

  .icon{
    display: none;
  } 
  .perfil{
    display: none;
  }

  @media (max-width: 850px) {
    justify-content: center;

    a{
    margin: 8px;
    }

    img{
        height: 50px;
    }
    .icon{
        display:none;
    }

   .icon{
    display: flex;
    position: absolute;
    left: 15px;
    height: 40px;
    width: 40px;
    padding: 0;
  }
  .perfil{
    display: flex;
    position: absolute;
    right: 15px;
    height: 40px;
    width: 40px;
    padding: 0;
    img{
        border-radius: 40px;
        height: 40px;
        width: 40px;
    }
  }
 
  div{
    display: none;
    width: 100%;
    margin: 10px 0;
    flex-wrap: wrap;
    background-color: #AAC8AA;
    div{
        display: none;
     }
    }
  
   ${({ active }) =>
    active &&
    css`
        div{
          display: flex;
        }
        .icon{
            top: 5px;
        }
        .perfil{
            top: 5px;
        }
    `
  }
  }
`;