import React, { useEffect, useState } from 'react';
import { Container, List, Saldo } from './styles';
import { useNavigate, useParams } from "react-router-dom";
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { addDays, addYears, format } from 'date-fns';
import Status from '../../../components/status';
import { TbUrgent } from 'react-icons/tb';

export default function AllOSsAdmin({ type }) {

    const navigate = useNavigate();
    const { id } = useParams()
    const [user, setUser] = useState({})
    const [orders, setOrders] = useState([])
    const [totalServices, setTotalServices] = useState(0)


    useEffect(() => {
        loadingUser()
    }, [])

    async function loadingUser() {
        await api
            .get(`/${type}/${id}`).then((response) => {
                setUser(response.data)
                loadingOSs(response.data)
            }).catch(({ response }) => {
                if (response) {
                    if (response.data) {
                        if (response.data.message) {
                            toast.warn(response.data.message)
                        } else {
                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                        }
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
                navigate(-1)
            })
    }

    async function loadingOSs(user) {
        user.type = type == "user" ? "cliente" : "tecnico"
        await api
            .get(`/orders/${user.type}/${id}?startDate=${addYears(new Date(), -10)}&endDate=${new Date()}`).then((response) => {
                let totalS = 0;
                let totalV = 0;
                response.data.map((item) => {
                    item.totalValue = 0
                    item.totalServices = 0

                    if (user.type == "cliente") {
                        item.items.map((data) => {
                            if (item.status == "finalizado") {
                                totalS += data.amount
                                totalV += data.amount * data.value
                            }
                            item.totalServices += data.amount
                            item.totalValue += data.amount * data.value
                        })
                    } else {
                        item.items.map((data) => {
                            if (item.status == "finalizado") {
                                totalS += data.amount
                                totalV += data.amount * data.commission
                            }
                            item.totalServices += data.amount
                            item.totalValue += data.amount * data.commission
                        })
                    }
                    if (item.urgent) {
                        item.totalValue = item.totalValue * 1.1
                        totalV = totalV * 1.1
                    }
                })
                setTotalServices(totalS)
                setOrders(response.data)
            }).catch(({ response }) => {
                if (response) {
                    if (response.data) {
                        if (response.data.message) {
                            toast.warn(response.data.message)
                        } else {
                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                        }
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            })
    }

    return (
        <Container>

            <h1>Todas OSs de {user.name}</h1>
            <Saldo>
                <p>
                    Foram solicitados {totalServices} serviços ao total
                </p>
            </Saldo >

            <List>
                {orders.map((item) => {
                    return (
                        <div>
                            <div>
                                <p style={{ display: "flex", alignItems: "center" }}><a
                                    style={{ cursor: "pointer", margin: 0, marginRight: 5 }}
                                    onClick={() => {
                                        navigate("/admin/os/" + item.id)
                                    }}>{item.name ? item.name + " - " + ("0000" + item.id).slice(-4) : ("0000" + item.id).slice(-4)}</a> - {item.totalServices} serviços solicitados {item.urgent && (<div style={{ marginLeft: 5, backgroundColor: "#eb4034", height: 25, width: 25, padding: 2, borderRadius: 5 }}><TbUrgent color="#FFF" size={22} /></div>)}</p>

                                <Status status={item.status} urgent={item.urgent} />
                                <p style={{ display: "flex", alignItems: "center", marginTop: 5, fontSize: 12 }}>Última atualização em {format(new Date(item.update_at), "dd/MM/yyyy HH:mm")}</p>
                            </div>
                            <h1>{(item.totalValue).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</h1>
                        </div>
                    )
                })}
            </List>
        </Container>
    )
} 