import React, { useEffect, useState } from 'react';
import { Container, List, InputFilter, Boxs } from './styles';
import { useNavigate, useParams } from "react-router-dom";
import loadingPhoto from "./../../../images/loading.gif"
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { MdVisibility, MdAdd, MdDelete } from 'react-icons/md';
import { useAuth } from '../../../hooks/AuthContext';
import { format } from 'date-fns';
import HeaderAdmin from '../../../components/headerAdmin';
import ModalC from '../../../components/modal';
import { ButtonC } from '../../../components/simple';

const colorStatus = {
    "aguardando": "#4287f5AA",
    "assinado": "#21b82eAA",
    "recusado": "#d65b2bAA",
    "expirado": "#5e6361AA",
}

export default function ContractsAdmin() {

    const { user } = useAuth();

    const { user_id } = useParams()

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true)
    const [visibleModalDelete, setVisibleModalDelete] = useState(false)
    const [contracts, setContracts] = useState([])
    const [contract, setContract] = useState({})
    const [contractsC, setContractsC] = useState([])
    const [filtername, setFilterName] = useState("")


    useEffect(() => {
        if (user.type != "admin") {
            navigate(-1)
        } else {
            listContracts()
        }
    }, [])

    async function listContracts() {
        await api.get(`/contracts/${user_id}`).then((response) => {
            setContracts(response.data)
            setContractsC(response.data)
        }).catch(({ response }) => {
            if (response) {
                if (response.data) {
                    if (response.data.message) {
                        toast.warn(response.data.message)
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            } else {
                toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
        })
        setLoading(false)
    }

    function filterContracts(value) {
        let contractsCopy = contractsC
        let contracts = []
        contractsCopy.map((item) => {
            if (String(item.name)
                .toUpperCase()
                .indexOf(value.toUpperCase()) != -1) {
                contracts.push(item)
            }
        })
        setContracts(contracts)
    }

    async function deleteContract(id) {
        await api.delete(`/contract/${id}`)
            .then(() => {
                toast.success("Contrato excluido com sucesso")
                listContracts()
                setVisibleModalDelete(false)
            }).catch(({ response }) => {
                if (response) {
                    if (response.data) {
                        if (response.data.message) {
                            toast.warn(response.data.message)
                        } else {
                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                        }
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            })
    }

    return (
        <Container>
            <div style={{ padding: "40px 0", width: "90%", flexDirection: "column", display: "flex", alignItems: "center" }}>
                {
                    loading ? (
                        <img src={loadingPhoto} style={{ background: "transparent", height: 50, width: 50 }} />
                    ) : (
                        <>
                            <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 15 }}>
                                <h1 style={{ margin: 0 }}>{contracts.length} Contratos</h1>
                            </div>

                            <Boxs>
                                <div style={{ background: "#4287f5" }}>
                                    <h3>Aguardando</h3>
                                    <p>{contracts.filter((item) => { return item.status == "aguardando" }).length}</p>
                                </div>
                                <div style={{ background: "#21b82e" }}>
                                    <h3>Assinados</h3>
                                    <p>{contracts.filter((item) => { return item.status == "assinado" }).length}</p>
                                </div>
                                <div style={{ background: "#d65b2b" }}>
                                    <h3>Recusados</h3>
                                    <p>{contracts.filter((item) => { return item.status == "recusado" }).length}</p>
                                </div>
                                <div style={{ background: "#5e6361" }}>
                                    <h3>Expirados</h3>
                                    <p>{contracts.filter((item) => { return item.status == "expirado" }).length}</p>
                                </div>
                            </Boxs>
                            <InputFilter value={filtername} onChange={(text) => {
                                setFilterName(text.target.value)
                                filterContracts(text.target.value)
                            }} placeholder='Procurar por nome' />


                            <List>
                                {contracts.length == 0 && (
                                    <h4 style={{ marginTop: 50, fontWeight: 500, width: "80%", textAlign: "center" }}>Nenhum contrato encontrado</h4>
                                )}
                                {contracts.map((item) => {
                                    return (
                                        <div style={{ backgroundColor: colorStatus[item.status] }}>
                                            <p>{item.name}</p>
                                            <span>{item.status != "aguardando" ? "Atualizado" : "Criado"} em {format(new Date(item.update_at), "dd/MM/yyyy HH:mm")}</span>
                                            <p style={{ textTransform: "capitalize" }}>{item.status}</p>
                                            <button onClick={() => {
                                                navigate(`/contract/${item.id}`)
                                            }}>
                                                <MdVisibility color="#000" size={20} />
                                            </button>
                                            {
                                                item.status != "assinado" && (
                                                    <button style={{top: 50}} onClick={() => {
                                                        setContract(item)
                                                        setVisibleModalDelete(true)
                                                }}>
                                                    <MdDelete color="#000" size={20} />
                                                </button>
                                                )}
                                        </div>
                                    )
                                })}
                            </List>
                        </>
                    )
                }
            </div>
            <ModalC visible={visibleModalDelete} executar={() => { setVisibleModalDelete(false) }}>
                <div style={{ paddingTop: 0, width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <h3 style={{ textAlign: "center", color: "#fff" }}>Você deseja o contrato de {contract.name}?</h3>
                    <div style={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                        justifyContent: "space-between"
                    }}>
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: "center", marginTop: 25
                        }}>

                            <ButtonC text={""} title={"Confirmar"} background={"#bb4444"}
                                width={"70%"} executar={() => {
                                    deleteContract(contract.id)
                                }} />
                        </div>
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: "center", marginTop: 15
                        }}>

                            <ButtonC text={""} title={"Cancelar"} background={"#888"}
                                width={"70%"} executar={() => {
                                    setVisibleModalDelete(false)
                                }} />
                        </div>
                    </div>
                </div>

            </ModalC>
        </Container>
    )
} 