import styled from "styled-components";

export const Container = styled.div`
  width: 100%; 
  align-items: center;
  min-height: 100%;
  display: flex;
  padding: 40px 0;
  flex-direction: column;
  align-items: center;

  h1{
    text-align: center;
    margin-bottom: 10px;
  }
  h3{
    text-align: center;
    margin: 15px 0;
  }
  button{
        margin: 25px 0;
        padding: 16px 25px;
        border-radius: 5px;
        background-color: #f2760a;
        color: #fff;
        height: 50px;
        display: flex;
        font-size: 18px;
        max-width: 350px;
        align-items: center;
        justify-content: center;
      }
  @media (max-width: 850px) {
  padding: 30px 0;
  width: 95%;
  h1{
    text-align: center;
    font-size: 22px;
    margin-bottom: 5px;
  }
  }


`;

export const Contact = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  margin: 10px;


    img{
      width: 80px;
      height: 80px;
      border-radius: 80px;
      margin-right: 20px;
    }
    div{
     display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0px;
      padding: 0px;

      button{
        margin: 15px 0 0 0;
        padding: 8px 15px;
        border-radius: 5px;
        background-color: #25D366;
        color: #fff;
        display: flex;
        font-size: 14px;
        max-width: 150px;
        align-items: center;
        justify-content: center;
      }
    
  }
  
`;

export const Observation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 80%;
  padding: 25px 50px;
  border-radius: 10px;
        background-color: #f0f0f0;

    p{
      color: #000;
    }
    @media (max-width: 850px) {
    max-width: 100%;
  
    }
`;

export const Services = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;

  div{
    display: flex;
    padding: 10px;
    margin-bottom: 15px;
    flex-wrap: wrap;
    flex-direction: row;
    border-bottom: 2px solid #f2760a;
    p{
      color: #000;
    }
  }

  @media (max-width: 850px) {
  width: 100%;
 
  }
`;

export const Docs = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 80%;
  justify-content: center;
  div{
    display: flex;
  flex-direction: column;
    padding: 10px;
    width: 120px;
    min-height: 200px;
    margin: 10px;
    background-color:  #f0f0f0;
    margin-bottom: 15px;
    border-radius: 10px;
  align-items: center;
  justify-content: center;
    p{
      color: #000;
      text-align: center;
      width: 95%;
  word-break: break-all;
      margin-bottom: 15px;
    }
  }

  @media (max-width: 850px) {
  width: 100%;
  
  }
`;


export const Resume = styled.div`
  display: flex;
  justify-content: center;
    display: flex;
  flex-direction: column;
    padding: 25px;
    min-width: 350px;
    margin: 25px 0;
    background-color:  #f2760a;
    margin-bottom: 15px;
    border-radius: 10px;
  align-items: center;
  justify-content: center;
    p{
      color: #fff;
      text-align: center;
      font-size: 20px;
    }
  

  @media (max-width: 850px) {
  width: 100%;
  }
`;



export const Actions = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
    width: 100%;
    margin: 15px 0;
    flex-wrap: wrap;
  align-items: center;
  justify-content: center;
    margin-bottom: 50px;
    button{
        display: flex;
        padding: 15px;
        border-radius: 5px;
        margin:  0 15px;
        align-items: center;
        justify-content: center;
      background-color: #f2760a;
      font-size: 16px;
        color: #fff;
      }
  

  @media (max-width: 850px) {
    width: 100%;
    border-radius: 0PX;
    button{
        margin: 15px 15px 0 15px;
      }
  }
`;

export const Urgente = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  padding: 10px 30px 10px 25px;
  border-radius: 10px;
  align-items: center;
  background-color: #eb4034;
  border: none;
  color: #fff;
  font-size: 18px;
  align-items: center;
  font-weight: bold;
`;

