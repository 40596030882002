import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  align-items: center;
  min-height: 100vh;
  display: flex;
  background-color: #fff;
  flex-direction: column;

  div{
  max-width: 1100px;
  display: flex;
  align-items: center;
  background-color: #fff;
  }

  img{
    width: 50%;
    max-width: 300px;
    margin-bottom: 25px;
  }

  h1{
    width: 100%;
    text-align: left;
  font-size: 40px;
  }

  h2{
    width: 100%;
    text-align: left;
    font-size: 30px;
  }

  h3{
    width: 100%;
    text-align: left; 
    font-size: 26px;
  } 

  h4{
    width: 100%;
    text-align: left; 
    font-size: 20px;
  }

  h5{
    width: 100%;
    text-align: left; 
    font-size: 15px;
    color: #555;
  }

  span{
    width: 100%;
    text-align: left; 
  }

  strong{
    color: #000;
    font-size: 18px;
    text-align: center;
  }
`;

export const Risks = styled.div`
  display: flex;
  max-width: 1100px;
  width: 100%;
  overflow-x: scroll;
  flex-direction: column;
  height: 100%;
  flex: 1;

  div{
    display: flex;
    width: 100%;
    flex: 1;
    height: 100%;
    flex-direction: row;
    justify-content: flex-start !important; 
    align-items: flex-start !important; 
  }

  h2{
    color: #000;
    font-size: 18px;
    width: 100%;
    text-align: left;
    margin: 10px 0;
    font-weight: bold;
  } 
`;

export const Risk = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column !important; 
  min-width: 194px;
  max-width: 194px;
  justify-content: flex-start !important; 
  align-items: flex-start !important; 
  margin: 10px;

  h2{
    color: #444;
    font-size: 15px;
    width: 100%;
    text-align: center;
    margin: 10px 0;
    font-weight: bold;
  } 

  p{
    font-size: 12px;
    margin-top: 5px;
    text-align: center;
    width: 100%;
  }

`;

export const Images = styled.div`
  display: flex;
  max-width: 1100px;
  width: 100%;
  margin-top: 15px;
  overflow-x: scroll;
  flex-direction: row;

  h2{
    color: #000;
    font-size: 18px;
    width: 100%;
    text-align: left;
    margin: 10px 0;
    font-weight: bold;
  } 
`;

export const Image = styled.div`
  display: flex;
  flex-direction: column !important; 
  min-width: 200px;
  max-width: 200px;
  min-height: 150px;
  align-items: center;
  margin: 10px;

  img{
    object-fit: contain;
    width: 200px;
    max-height: 180px;
  }
`;
