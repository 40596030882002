import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useAuth } from '../hooks/AuthContext';

const PublicRoute = ({ contract }) => {

  const user = useAuth();

  useEffect(() => {
    let type = "";
    if (user.user) {
      type = user.user.type
    }
    if (!contract) {
      if (type) {
        if (type == "tecnico") {
          window.location.assign("/tecnicos/home");
        } else {
          if (type == "cliente") {
            window.location.assign("/clientes/home");
          } else {
            window.location.assign("/admin/home");
          }
        }
      }
    }

  }, [])

  let type = "";
  if (user.user) {
    type = user.user.type
  }

  if (!contract) {
    return !type ?
      <>
        <Outlet />
      </> :
      <></>
  } else {
    return (<Outlet />)
  }

};

export default PublicRoute;