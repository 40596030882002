import React from "react";
import { Container } from "./styles";
import logo from "../../images/logo.jpeg"

export default function HeaderPublic() {

    return (
        <Container>
            <img src={logo} />
        </Container>
    )

}