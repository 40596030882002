import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  align-items: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  h1{
    text-align: center;
    margin-bottom: 15px;
    color: #f2760a;
  }
  @media (max-width: 750px) {
  h1{
    text-align: center;
    font-size: 22px;
    margin-bottom: 15px;
  }
  }
`;


export const List = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 25px 0;

  div{
  background: #f0f0f0;
  width: 100%;
  max-width: 350px;
  position: relative;
  border-radius: 5px;
  margin: 15px; 
  padding: 15px 15px 45px 15px;
  height: 150px;


        p{
          width: calc(100% - 35px);
          font-size: 17px;
          text-align: left;
          color: #FFF;
          margin-top: 8px;
          font-weight: bold;
        }
        span{
          margin: 5px 0;
          width: calc(100% - 35px);
          font-size: 14px;
          text-align: left;
          color: #FFF
        }
     

      button{
        display: flex;
        border-radius: 25px;
        background-color: #fff;
        color: #fff;
        height: 25px;
        width: 25px;
        align-items: center;
        padding: 0;
        justify-content: center;
        position: absolute;
        right: 10px;
        top: 10px;
    }
  }
`;


export const InputFilter = styled.input`
        padding: 8px;
        border-radius: 5px;
        background-color: #f0f0f0;
        color: #000c;
        height: 40px;
        width: 100%;
        max-width: 450px;
        border: none;
        font-size: 16px;
`;

export const Boxs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
    margin-bottom: 25px;

  div{
  background: #f0f0f0;
  flex: 1;
  min-width: 150px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
    border-radius: 5px;
  justify-content: center;
  margin: 15px; 
  padding: 15px; 

        p{
           width: 90%;
          font-size: 17px;
          text-align: center;
        color: #fff;
        }
        h3{
          margin: 5px 0 15px 0;
           width: 90%;
          font-size: 14px;
          text-align: center;
        color: #fff;
        }

  }
`;
