import styled from 'styled-components';

export const Container = styled.div`
  width: ${props => props.sidebar != false ? "calc(100% - 180px)" : "90%"};
  margin: 0 auto;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 80px;
  min-height: 100%;
  padding-left:  ${props => props.sidebar != false ? "180px" : "0px"}; ;
  background: #fff;

  @media(max-width: 800px){
  padding-left: 0px;
  width: 90%;
  }
`;
