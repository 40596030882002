import styled from "styled-components";

export const Container = styled.div`
  width: 90%; 
  max-width: 800px;
  align-items: center;
  min-height: 100vh;
  display: flex;
  padding: 40px 0;
  flex-direction: column;

  h1{
    text-align: center;
    margin-bottom: 35px;
    color: #f2760a;
  }
  h2{
  color: #000;
  font-size: 18px;
  width: 100%;
  text-align: left;
  margin: 10px 0;
  font-weight: bold;
}

h3{
  color: #999;
  font-size: 15px;
  width: 100%;
  text-align: left;
  margin: 0 0 5px 0;
  font-weight: bold;
}
  @media (max-width: 750px) {
  padding: 30px 0;
  width: 95%;
  h1{
    text-align: center;
    font-size: 22px;
    margin-bottom: 15px;
  }

  }
`;

export const Services = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  div{
    display: flex;
    padding: 10px;
    margin-bottom: 15px;
    flex-wrap: wrap;
      align-items: center;
    flex-direction: row;
    border-bottom: 2px solid #f2760a;
    button{
        display: flex;
        height: 20px;
        padding: 0 10px;
        border-radius: 5px;
        align-items: center;
        justify-content: center;
      background-color: #f2760a;
      font-size: 10px;
          color: #fff;
          line-height: 30px;
      }
    div{
      display: flex;
      padding: 0;
    margin: 0;
    margin-right: 15px;;
      flex-direction: row;
      border: none;

      align-items: center;
      button{
        display: flex;
        height: 30px;
        width: 30px;
        padding: 0;
        border-radius: 5px;
        align-items: center;
        justify-content: center;
      background-color: #f2760a;
      font-size: 25px;
          color: #fff;
          line-height: 30px;
      }
      span{
        margin: 0 10px;
      }
  }
    p{
      color: #000;
    }
    }
  

  @media (max-width: 850px) {
  width: 100%;
 
  }
`;
