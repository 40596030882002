import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useAuth } from '../hooks/AuthContext';
import Sidebar from '../components/sidebar';
import Main from '../components/main';
import Header from '../components/header';

const RoutePrivate = ({sidebar , header}) => {

  const { user } = useAuth();
  const [isMenuMinimized, setIsMenuMinimized] = useState(true)

  useEffect(() => {

    let type = "";
    if (user) {
      type = user.type
    }

    if (!type) {
      window.location.assign("/login");
    }

  }, [])

  let type = "";
  if (user) {
    type = user.type
  }

  return type ?
    <>
      {
        sidebar != false && (
          <Sidebar type={type} isMenuMinimized={isMenuMinimized} onChange={(e)=>{setIsMenuMinimized(e == false || !isMenuMinimized)}}/>
        )
      }
      {header != false ? (
          <Main sidebar={sidebar} >
            <Header sidebar={sidebar} type={type}  onChange={() => {
              setIsMenuMinimized(!isMenuMinimized)
            }} />
            <Outlet />
          </Main>
      ) : <Outlet />}
    </>
    : <></>
};

export default RoutePrivate;