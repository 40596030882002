import React, { useState } from "react";
import { Photo, Container, ViewLogin, Information, Input, Button, } from './styles';
import logo from "./../../../images/logo.jpeg"
import { useNavigate } from "react-router-dom";
import { useAuth } from './../../../hooks/AuthContext';

export default function LoginFranqueados() {

    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const { signIn } = useAuth();

    return (
        <Container>
            <ViewLogin>
                <h2>Login como Administrador</h2>
                <Photo src={logo} />
                <Information>
                    Seja bem-vindo Administrador, faça o login para acessar o sistema.
                </Information>
                <Input placeholder='Usuário' value={email} type="email" onChange={(text) => {
                    setEmail(text.target.value)
                }} />
                <Input placeholder='Senha' value={password} type="password" onChange={(text) => {
                    setPassword(text.target.value)
                }} />
                <Button onClick={async () => {
                    await signIn({ email, password, type: "admin" })
                }}>
                    Fazer Login
                </Button>

            </ViewLogin>
        </Container>
    )
} 