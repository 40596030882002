import React, { useEffect, useState } from 'react';
import { Container, List, InputFilter, Boxs } from './styles';
import { useNavigate } from "react-router-dom";
import loadingPhoto from "./../../../images/loading.gif"
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { MdAdd, MdDelete, MdEdit, MdCheck } from 'react-icons/md';
import { useAuth } from '../../../hooks/AuthContext';
import { differenceInCalendarDays, endOfHour, format, isAfter, isSameYear } from 'date-fns';
import ModalC from '../../../components/modal';
import { ButtonC } from '../../../components/simple';
import { Pagination } from 'antd';

const colorStatus = {
    "breve":  {color: "#4287f5AA", name: "Em breve"},
    "mes": {color: "#ebb734AA", name: "Próximos 30 dias"},
    "vencido":  {color: "#d92b2bAA", name: "Vencido"},
}

export default function CompanyTimeline() {

    const { user } = useAuth();

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true)
    const [visibleModalDate, setVisibleModalDate] = useState(false)
    const [visibleModalDelete, setVisibleModalDelete] = useState(false)
    const [timelines, setTimelines] = useState([])
    const [timelinesC, setTimelinesC] = useState([])
    const [timeline, setTimeline] = useState(null)
    const [page, setPage] = useState(0);
    const [filtername, setFilterName] = useState("")

    useEffect(() => {
        listTimelines()
    }, [])

    async function listTimelines() {
        await api.get(`/companies-timeline${user.type == "cliente" ? `?region=${user.region || null}` : ""}`).then((response) => {
            let timelines = response.data
            timelines.map((item) => {
                item.date_reminder = endOfHour(new Date(item.date_reminder))
                item.difference = differenceInCalendarDays(item.date_reminder, new Date())
                if (item.difference <= 0) {
                    item.status = "vencido"
                } else {
                    if (item.difference > 30) {
                        item.status = "breve"
                    } else {
                        item.status = "mes"
                    }
                }
            })
            setTimelines(timelines)
            setTimelinesC(timelines)
        }).catch(({ response }) => {
            if (response) {
                if (response.data) {
                    if (response.data.message) {
                        toast.warn(response.data.message)
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            } else {
                toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
        })
        setLoading(false)
    }

    async function confirmTimeline() {
        await api.put(`/timeline/${timeline.id}`).then((response) => {
            listTimelines()
            toast.success("Tarefa confirmada com sucesso")
            setVisibleModalDate(false)
        }).catch(({ response }) => {
            if (response) {
                if (response.data) {
                    if (response.data.message) {
                        toast.warn(response.data.message)
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            } else {
                toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
        })
    }

    function filterTimelines(value) {
        let timelinesCopy = timelinesC
        let timelines = []
        timelinesCopy.map((item) => {
            if (String(item.renewal.razao_social)
                .toUpperCase()
                .indexOf(value.toUpperCase()) != -1 || String(item.renewal.fantasia)
                .toUpperCase()
                .indexOf(value.toUpperCase()) != -1 || String(item.name)
                .toUpperCase()
                .indexOf(value.toUpperCase()) != -1) {
                timelines.push(item)
            }
        })
        setPage(0)
        setTimelines(timelines)
    }

    async function deletetTimeline() {
        await api.delete(`/company-timeline/${timeline.id}`)
            .then(() => {
                toast.success("Tarefa excluida com sucesso")
                listTimelines()
                setVisibleModalDelete(false)
            }).catch(({ response }) => {
                if (response) {
                    if (response.data) {
                        if (response.data.message) {
                            toast.warn(response.data.message)
                        } else {
                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                        }
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            })
    }

    return (
        <Container>
            <div style={{ padding: "40px 0", width: "90%", flexDirection: "column", display: "flex", alignItems: "center" }}>
                {
                    loading ? (
                        <img src={loadingPhoto} style={{ background: "transparent", height: 50, width: 50 }} />
                    ) : (
                        <>
                            <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 15 }}>
                                <h1 style={{ margin: 0 }}>{timelines.length} Tarefas</h1>
                                <button
                                    onClick={() => {
                                        navigate(`/${user.type}/timeline/new`)
                                    }}
                                    style={{ width: 38, height: 38, marginLeft: 15, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#f2760a", borderRadius: 10 }}>
                                    <MdAdd color="#fff" size={29} />
                                </button>
                            </div>

                            <Boxs>
                                <div style={{ background: "#d92b2b" }}>
                                    <h3>Vencidos</h3>
                                    <p>{timelines.filter((item) => { return item.status == "vencido" }).length}</p>
                                </div>
                                <div style={{ background: "#ebb734" }}>
                                    <h3>Próximos 30 dias</h3>
                                    <p>{timelines.filter((item) => { return item.status == "mes" }).length}</p>
                                </div>
                                <div style={{ background: "#4287f5" }}>
                                    <h3>Em breve</h3>
                                    <p>{timelines.filter((item) => { return item.status == "breve" }).length}</p>
                                </div>
                            </Boxs>
                            <InputFilter value={filtername} onChange={(text) => {
                                setFilterName(text.target.value)
                                filterTimelines(text.target.value)
                            }} placeholder='Procurar por nome' />


                            <List>
                                {timelines.length == 0 && (
                                    <h4 style={{ marginTop: 50, fontWeight: 500, width: "80%", textAlign: "center" }}>Nenhuma tarefa encontrada</h4>
                                )}
                                {timelines.map((item) => {
                                    return (
                                        <div style={{ backgroundColor: colorStatus[item.status].color }}>
                                            <span>Data da Tafera: {format(new Date(item.date_reminder), "dd/MM/yyyy")}</span>
                                            <p>Tarefa: {item.name}</p>
                                            <p>Empresa: {item.renewal.razao_social}</p>
                                            <p style={{ position: "absolute", bottom: 15, fontSize: 15 }}>{item.status == "vencido" ? item.difference == 0 ? "Vence hoje" : `Venceu a ${(item.difference * -1)} dias` : differenceInCalendarDays(new Date(item.date_reminder), new Date()) == 1 ? "Tarefa para amanhã" : `Tarefa em ${item.difference} dias`}</p>
                                            <button style={{ top: 10 }} onClick={() => {
                                                navigate(`/${user.type}/timeline/${item.id}`)
                                            }}>
                                                <MdEdit color="#000" size={15} />
                                            </button>
                                            <button style={{ top: 44 }} onClick={() => {
                                                setTimeline(item)
                                                setVisibleModalDate(true)
                                            }}>
                                                <MdCheck color="#000" size={15} />
                                            </button>
                                            <button style={{top: 78}} onClick={() => {
                                                setTimeline(item)
                                                setVisibleModalDelete(true)
                                            }}>
                                                <MdDelete color="#000" size={15} />
                                            </button>
                                        </div>
                                    )
                                })}
                            </List>
                            <center style={{margin: "25px 0"}}>
                            <Pagination simple current={page + 1} onChange={(page) => {
                                setPage(page - 1)
                            }} total={timelines.length} pageSize={50} 
                            showTotal={(total) => `${timelines.length} tarefas`}/>
                            </center>
                        </>
                    )
                }
            </div>
            {
                timeline && (
                    <>
                        <ModalC visible={visibleModalDate} executar={() => { setVisibleModalDate(false) }}>
                            <div style={{ paddingTop: 0, width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <h3 style={{ textAlign: "center", color: "#fff" }}>Você confirma a realização da tarefa {timeline.name} para a empresa {timeline.renewal.razao_social}?</h3>
                                <div style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    width: "100%",
                                    justifyContent: "space-between"
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        width: "100%",
                                        justifyContent: "center", marginTop: 25
                                    }}>

                                        <ButtonC text={""} title={"Confirmar"}
                                            width={"70%"} executar={() => {
                                                confirmTimeline()
                                            }} />
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        width: "100%",
                                        justifyContent: "center", marginTop: 15
                                    }}>

                                        <ButtonC text={""} title={"Cancelar"} background={"#888"}
                                            width={"70%"} executar={() => {
                                                setVisibleModalDate(false)
                                            }} />
                                    </div>
                                </div>
                            </div>
                        </ModalC>
                        <ModalC visible={visibleModalDelete} executar={() => { setVisibleModalDelete(false) }}>
                            <div style={{ paddingTop: 0, width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <h3 style={{ textAlign: "center", color: "#fff" }}>Você confirma a exclusão da tarefa {timeline.name} para a empresa {timeline.renewal.razao_social}?</h3>
                                <div style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    width: "100%",
                                    justifyContent: "space-between"
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        width: "100%",
                                        justifyContent: "center", marginTop: 25
                                    }}>

                                        <ButtonC text={""} title={"Confirmar"} background={"#bb4444"}
                                            width={"70%"} executar={() => {
                                                deletetTimeline()
                                            }} />
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        width: "100%",
                                        justifyContent: "center", marginTop: 15
                                    }}>

                                        <ButtonC text={""} title={"Cancelar"} background={"#888"}
                                            width={"70%"} executar={() => {
                                                setVisibleModalDelete(false)
                                            }} />
                                    </div>
                                </div>
                            </div>

                        </ModalC>
                    </>
                )
            }
            
        </Container>
    )
} 