import styled from "styled-components";

export const Container = styled.div`
  width: 100%; 
  align-items: center;
  min-height: 100vh;
  display: flex;
  padding: 40px 0;
  flex-direction: column;
  align-items: center;

  p{
   text-align: center;
  }

  h1{
    text-align: center;
    margin-bottom: 15px;
    color: #f2760a;
  }

  a{
    text-align: center;
    margin: 25px 0;
    font-size: 14px;
    color: #f2760a;
    text-decoration: underline;
  }

  @media (max-width: 750px) {
  padding: 30px 0;
  width: 95%;
  h1{
    text-align: center;
    font-size: 22px;
    margin-bottom: 5px;
  }
  }
`;

export const Saldo = styled.div`

  
  width: 100%;
  min-width: 100px;
  max-width: 300px;
  min-height: 80px;
  margin: 25px 0;
  padding: 8px 15px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f2760a;

  p{
      font-size: 16px;
      color: #FFF;
      text-align: center;
  }

  h2{
      font-size: 20px;
      color: #FFF;
      text-align: center;
      margin-top: 8px;
  }

  @media (max-width: 850px) {
    p{
      font-size: 14px;
  }
  h2{
      font-size: 17px;
  }


  
  }

`;



export const List = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: transparent;

  div{
    border: none;
    border-bottom: 2px solid #f2760a;
    padding: 10px 0;
    width: 100%;
    background: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 10px; 

    h1{
          margin: 0px;
          margin-right: 10px;
          font-size: 19px;
          text-align: center;
          color:  #f2760a;
        }

      div{
        border: none;
        flex-direction: column;
        justify-content: center;
        padding: 0;
        flex: 1;
        align-items: flex-start;
        background-color: #fff;
        margin: 0;

        p{
          margin: 0px;
          font-size: 14px;
          text-align: left;
          color: #000;
        }
          div{
          border: none;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          padding: 0;
          margin: 0;
            margin-top: 5px;
          h1{
            margin: 0px;
            margin-top: 10px;
            font-size: 16px;
            text-align: left;
            color: #000;
          }
        }

  }
  }
`;
