import React, { useEffect, useState } from 'react';
import { Container, Contact, Services, Docs, Observation, Urgente, Actions } from './styles';
import { useNavigate, useParams } from "react-router-dom";
import loadingPhoto from "./../../../images/loading.gif"
import loadingBranco from "./../../../images/loadingBranco.gif"
import { MdDelete, MdDownload, MdUpload } from "react-icons/md"
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { useAuth } from '../../../hooks/AuthContext';
import Status from '../../../components/status';
import { TbUrgent } from "react-icons/tb"
import ModalC from '../../../components/modal';
import { ButtonC, InputC } from '../../../components/simple';
import { format } from 'date-fns';

export default function OSDetails() {
    const navigate = useNavigate();
    const { id } = useParams()
    const { user } = useAuth()
    const [loading, setLoading] = useState(true)
    const [order, setOrder] = useState({
        items: []
    })
    const [files, setFiles] = useState([])
    const [filesUp, setFilesUp] = useState([])
    const [message, setMessage] = useState("")
    const [isLoading, setIsLoading] = useState("")
    const [visibleModal, setVisibleModal] = useState(false)
    const [visibleModalView, setVisibleModalView] = useState(false)

    useEffect(() => {
        loadingOS()
    }, [id])

    async function loadingOS() {
        await api.get(`/order/${id}`).then((response) => {
            let totalS = 0
            let totalV = 0
            response.data.items.map((item) => {
                totalS += item.amount
                totalV += item.amount * item.value
            })
            if (response.data.message && (response.data.status == "alteracao" || response.data.status == "andamento")) {
                setMessage(response.data.message)
                setVisibleModalView(true)
            }
            setFiles(response.data.docs)
            setOrder(response.data)
        }).catch(({ response }) => {
            if (response) {
                if (response.data) {
                    if (response.data.message) {
                        toast.warn(response.data.message)
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            } else {
                toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
        })
        setLoading(false)
    }

    async function uploadDocs(filesUpload) {
        filesUpload.map(async (item) => {
            const data = new FormData();
            data.append('type', user.type);
            data.append('file', item, item.name);
            await api.post(`/doc/${order.id}`, data).then(async (response) => {
                setFilesUp(prevFiles => {
                    const newState = [...prevFiles]
                    newState.shift()
                    return newState
                })
                setFiles(prevFiles => [...prevFiles, response.data])
                toast.success(`O documento ${item.name} foi enviado com sucesso`)
            }).catch(({ response }) => {
                if (response) {
                    if (response.data) {
                        if (response.data.message) {
                            toast.warn(response.data.message)
                        } else {
                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                        }
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            })
        })
    }

    async function deleteDoc(fileDelete) {
        await api.delete(`/doc/${fileDelete.id}?type=${user.type}`).then(async (response) => {
            setFiles(prevFiles => {
                const newFiles = [...prevFiles]
                const idx = newFiles.indexOf(fileDelete);
                if (idx > -1) {
                    newFiles.splice(idx, 1);
                }
                return newFiles
            })
            toast.success(`O documento ${String(fileDelete.file).substr(33)} foi deletado com sucesso`)
        }).catch(({ response }) => {
            if (response) {
                if (response.data) {
                    if (response.data.message) {
                        toast.warn(response.data.message)
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            } else {
                toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
        })
    }

    return (
        <Container>
            {
                loading ? (<img src={loadingPhoto} style={{ background: "transparent", height: 50, width: 50 }} />

                ) : (
                    <>
                        {order.urgent && (<Urgente>
                            <TbUrgent color="#FFF" size={30} style={{ marginRight: 10 }} />
                            <p>Urgente</p>
                        </Urgente>)}
                        <h1 style={{ marginBottom: 0 }}>Ordem de Serviço</h1>
                        <p style={{ fontSize: 12, marginBottom: 5 }}>Aberta em {format(new Date(order.create_at), "dd/MM/yyyy")}</p>
                        <Status status={order.status} />
                        {
                            order.user && (
                                <>
                                    <h3 style={{ marginTop: 25 }}>Franqueado</h3>
                                    <Contact>
                                        <img src={order.user.photo ? order.user.photo_url : "https://png.pngtree.com/png-vector/20190710/ourlarge/pngtree-user-vector-avatar-png-image_1541962.jpg"} />
                                        <div>
                                            <p>{order.user.name}</p>
                                        </div>
                                    </Contact>
                                </>
                            )
                        }
                        {
                            order.order_linked_id && (
                                <>
                                    <h3>{!order.order_doc_recused ? "Visualizar OS de revisão" : "Visualizar OS que foi solicitada a revisão"}</h3>
                                    <button onClick={() => {
                                        navigate(`/tecnicos/os/${order.order_linked_id}`)
                                    }} style={{ margin: "10px 0 15px 0", height: 40 }}>{`Visualizar OS: ${order.order_linked_id}`}</button>
                                </>
                            )
                        }
                        {
                            order.observation && (
                                <>
                                    <h3>Observação do franqueado</h3>
                                    <Observation>
                                        <p>{order.observation}</p>
                                    </Observation>
                                </>
                            )
                        }
                        {
                            order.company_id && (
                                <>
                                    <h3>Formulário da Empresa</h3>
                                    <button onClick={() => {
                                        navigate(`/view-company/${order.company_id}`)
                                    }} style={{margin: "10px 0 15px 0"}}>Visualizar</button>
                                </>
                            )
                        }
                        
                        <h3>{order.sector}</h3>
                        <Services>
                            {
                                order.items.map((item) => {
                                    return (
                                        <div>
                                            <p style={{ flex: 1 }}>{item.name}</p>
                                            <p>{item.amount}x</p>
                                        </div>
                                    )
                                })
                            }
                        </Services>
                        <h3>Documentos do Franqueado</h3>

                        <Docs>
                            {
                                files.map((item) => {
                                    if (item.type == "cliente") {
                                        return (
                                            <div key={item.file}>
                                                <p>{String(item.file).substr(33)}</p>
                                                <div style={{ display: "flex", flexDirection: "row", minHeight: 20, padding: 0, margin: 0 }}>
                                                    <MdDownload onClick={() => {
                                                        window.open("https://guruseg-data.s3.sa-east-1.amazonaws.com/" + item.file)
                                                    }} style={{ cursor: "pointer" }} size={35} color="#f2760a" />
                                                    {order.status == "pendente" && (<MdDelete onClick={() => {
                                                        deleteDoc(item)
                                                    }} style={{ cursor: "pointer" }} size={30} color="#f2760a" />)}
                                                </div>

                                            </div>
                                        )
                                    }
                                })
                            }
                        </Docs>

                        {(order.status == "andamento" || order.status == "alteracao") && (
                            <>
                                <h3>Documentos do Técnico</h3>
                                <Docs>
                                    {
                                        files.map((item) => {
                                            if (item.type == "tecnico") {
                                                return (
                                                    <div key={item.file}>
                                                        <p>{String(item.file).substr(33)}</p>
                                                        <div style={{ display: "flex", flexDirection: "row", minHeight: 20, padding: 0, margin: 0 }}>
                                                            <MdDownload onClick={() => {
                                                                window.open("https://guruseg-data.s3.sa-east-1.amazonaws.com/" + item.file)
                                                            }} style={{ cursor: "pointer" }} size={35} color="#f2760a" />
                                                            {(order.status == "andamento" || order.status == "alteracao") && (<MdDelete onClick={() => {
                                                                deleteDoc(item)
                                                            }} style={{ cursor: "pointer" }} size={30} color="#f2760a" />)}
                                                        </div>

                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                    {
                                        filesUp.map((item) => {
                                            if (item.type == "tecnico") {
                                                return (
                                                    <div key={item.name}>
                                                        <p>{item.name}</p>
                                                        <img src={loadingPhoto} style={{ width: 60, height: 40 }} />
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                    {(order.status == "andamento" || order.status == "alteracao") && (
                                        <div for="avatar" style={{ backgroundColor: "#f2760a", position: "relative" }}>
                                            <p style={{ color: "#fff" }}>Enviar Documentos</p>
                                            <MdUpload size={35} color="#fff" />
                                            <input id="avatar" style={{ cursor: "pointer", position: "absolute", width: "100%", height: "100%", opacity: 0 }} multiple
                                                type="file" onChange={(file) => {
                                                    let filesUp = Object.values(file.target.files)
                                                    filesUp.map((item) => {
                                                        item.file_url = URL.createObjectURL(item)
                                                    })
                                                    setFilesUp(prevFiles => [...prevFiles, ...filesUp])
                                                    uploadDocs(filesUp)
                                                }} />
                                        </div>
                                    )}
                                </Docs>
                            </>
                        )}
                        <Actions>
                            {(order.status == "andamento" || order.status == "alteracao") && (
                                <button onClick={async () => {
                                    setMessage("")
                                    setVisibleModal(true)
                                }}>
                                    Solicitar Documentos
                                </button>
                            )}
                            {(order.status == "andamento" || order.status == "alteracao") && (
                                <button
                                    disabled={isLoading == "finalizar"} onClick={async () => {
                                        setIsLoading("finalizar")
                                        let filesIdx = 0
                                        files.map((item) => {
                                            if (item.type == "tecnico") {
                                                filesIdx++
                                            }
                                        })
                                        if (filesIdx > 0) {
                                            await api.put(`/status/${order.id}`, { status: "validacao" }).then((response) => {
                                                toast.success("Ordem de Serviço aguardando aprovação do franqueado")
                                                loadingOS()
                                            }).catch(({ response }) => {
                                                if (response) {
                                                    if (response.data) {
                                                        if (response.data.message) {
                                                            toast.warn(response.data.message)
                                                        } else {
                                                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                                                        }
                                                    } else {
                                                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                                                    }
                                                } else {
                                                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                                                }
                                            })
                                        } else {
                                            toast.error("Envie pelo menos um documento de resposta para finalizar a solicitação")
                                        }
                                        setIsLoading("")
                                    }}>
                                    {
                                        isLoading == "finalizar" ? (<img src={loadingBranco} style={{ background: "transparent", height: 50, width: 50, margin: "0 40px" }} />) : "Finalizar Serviço"
                                    }

                                </button>)}
                            {order.status == "aberto" && (
                                <button
                                    disabled={isLoading == "accept"}
                                    onClick={async () => {
                                        setIsLoading("accept")
                                        await api.put(`/accept-order/${order.id}`).then((response) => {
                                            toast.success("Ordem de Serviço aceita com sucesso")
                                            loadingOS()
                                        }).catch(({ response }) => {
                                            if (response) {
                                                if (response.data) {
                                                    if (response.data.message) {
                                                        toast.warn(response.data.message)
                                                    } else {
                                                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                                                    }
                                                } else {
                                                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                                                }
                                            } else {
                                                toast.error("Erro Interno. verifique sua conexão e tente novamente")
                                            }
                                        })
                                        setIsLoading("")
                                    }}>
                                    {
                                        isLoading == "accept" ? (
                                            <img src={loadingBranco} style={{ background: "transparent", height: 50, width: 50, margin: "0 40px" }} />
                                        ) : "Aceitar OS"
                                    }
                                </button>
                            )}

                            {order.status == "aberto" && (
                                <button
                                    onClick={async () => {
                                        setMessage("")
                                        setVisibleModal(true)
                                    }}>
                                    Recusar OS
                                </button>)}
                        </Actions>
                    </>
                )
            }

            <ModalC visible={visibleModal} executar={() => { setVisibleModal(false) }}>
                <div style={{ paddingTop: 0, width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div style={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                        justifyContent: "space-between"
                    }}>
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: "center", marginTop: 0
                        }}>
                            <InputC text={message} multiline title={"Mensagem para o franqueado"} placeholder={"descreva o que precisa para o franqueado"} width={"100%"} minHeight={"55px"} executar={(text) => { setMessage(text) }} />

                            <ButtonC disabled={isLoading == "modal"} style={{ height: 50 }} title={isLoading == "modal" ? (
                                <img src={loadingBranco} style={{ background: "transparent", height: 50, width: 50, margin: "0 40px" }} />
                            ) : order.status == "aberto" ? "Recusar OS" : "Solicitar Documentos"} background={"#bb4444"}
                                width={"100%"} executar={async () => {
                                    setIsLoading("modal")
                                    if (order.status == "aberto") {
                                        await api.put(`/recused-order/${order.id}`, { message: message }).then((response) => {
                                            toast.success("Ordem de Serviço recusada com sucesso")
                                            setVisibleModal(false)
                                            loadingOS()
                                        }).catch(({ response }) => {
                                            if (response) {
                                                if (response.data) {
                                                    if (response.data.message) {
                                                        toast.warn(response.data.message)
                                                    } else {
                                                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                                                    }
                                                } else {
                                                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                                                }
                                            } else {
                                                toast.error("Erro Interno. verifique sua conexão e tente novamente")
                                            }
                                        })
                                    } else {
                                        await api.put(`/status/${order.id}`, { status: "pendente", message: message }).then((response) => {
                                            toast.success("Solicitação de documentos efetuada com sucesso")
                                            setVisibleModal(false)
                                            loadingOS()
                                        }).catch(({ response }) => {
                                            if (response) {
                                                if (response.data) {
                                                    if (response.data.message) {
                                                        toast.warn(response.data.message)
                                                    } else {
                                                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                                                    }
                                                } else {
                                                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                                                }
                                            } else {
                                                toast.error("Erro Interno. verifique sua conexão e tente novamente")
                                            }
                                        })
                                    }
                                    setIsLoading("")
                                }
                                } />
                        </div>
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: "center", marginTop: 15
                        }}>

                            <ButtonC text={""} title={"Cancelar"} background={"#888"}
                                width={"100%"} executar={() => {
                                    setVisibleModal(false)
                                }} />
                        </div>
                    </div>
                </div>

            </ModalC>
            <ModalC visible={visibleModalView} executar={() => { setVisibleModalView(false) }}>
                <div style={{ paddingTop: 0, width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div style={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                        justifyContent: "space-between"
                    }}>
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: "center", marginTop: 10
                        }}>
                            <h1 style={{ marginBottom: 15, color: "#FFF", fontSize: 18 }}>Mensagem</h1>
                            <p style={{ marginBottom: 15, color: "#FFF", fontSize: 18, width: "90%", textAlign: "center" }}>{message}</p>
                            <div style={{
                                display: "flex",
                                flexWrap: "wrap",
                                width: "100%",
                                justifyContent: "center", marginTop: 15
                            }}>

                                <ButtonC text={""} title={"Fechar"} background={"#888"}
                                    width={"100%"} executar={() => {
                                        setVisibleModalView(false)
                                    }} />
                            </div>
                        </div>
                    </div>
                </div>

            </ModalC>
        </Container >
    )
} 