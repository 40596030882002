import React, { useState } from 'react';
import { Container } from './styles';
import { useParams, useNavigate } from "react-router-dom";
import { useAuth } from './../../../hooks/AuthContext';

export default function PerfilAdmin({ type }) {

    const navigate = useNavigate();
    const { id } = useParams()
    const [loading, setLoading] = useState(false)
    const { signOut, user } = useAuth();


    return (
        <Container>
            <img src={"https://png.pngtree.com/png-vector/20190710/ourlarge/pngtree-user-vector-avatar-png-image_1541962.jpg"} />
            <p>{user.name}</p>
            <p>{user.email}</p>
            <h1>Administrador</h1>
            <div>

                <button onClick={() => {
                    signOut()
                    navigate("/admin/login")
                }}>
                    Sair
                </button>
            </div>
        </Container >
    )
} 