import styled from "styled-components";

export const Container = styled.div`
  width: 90%; 
  max-width: 1100px;
  min-height: 100vh;
  display: flex;
  padding: 40px 0;
  flex-direction: row;
  flex-wrap: wrap;

  hr{
    height: 2px;
    width: 100%;
    margin: 10px 0 15px;
  }
  h1{
    text-align: center;
    margin-bottom: 35px;
    color: #f2760a;
    width: 100%;
  }
  h2{
    color: #000;
    font-size: 18px;
    width: 100%;
    text-align: left;
    margin: 10px 0;
    font-weight: bold;
  } 
  h3{
    color: #555;
    font-size: 15px;
    width: 100%;
    text-align: left;
    margin: 0 0 10px 0;
    font-weight: bold;
  }
  h4{
    color: #000;
    width: 100%;
    margin: 0 0 5px 0;
  }

  @media (max-width: 750px) {
    padding: 30px 0;
    width: 95%;
  h1{
    text-align: center;
    font-size: 22px;
    margin-bottom: 15px;
  }

  }
`;

export const Setor = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: #f2760a;
  padding: 25px 0;
`;

export const Risks = styled.div`
  display: flex;
  max-width: 1100px;
  width: 100%;
  overflow-x: scroll;
  flex-direction: column;

  div{
    display: flex;
    width: 100%;
    flex-direction: row;
  }

  h2{
    color: #000;
    font-size: 18px;
    width: 100%;
    text-align: left;
    margin: 10px 0;
    font-weight: bold;
  } 
`;

export const Risk = styled.div`
  display: flex;
  flex-direction: column !important; 
  min-width: 196px;
  max-width: 196px;
  min-height: 150px;
  align-items: center;
  margin: 10px;

  h2{
    color: #444;
    font-size: 15px;
    width: 100%;
    text-align: center;
    margin: 10px 0;
    font-weight: bold;
  } 

  p{
    font-size: 12px;
    margin-top: 5px;
    width: 100%;
  }

  input{
    margin: 5px 0;
    padding: 4px;
    width: 100%;
  }
`;
export const Images = styled.div`
  display: flex;
  max-width: 1100px;
  width: 100%;
  margin-top: 15px;
  overflow-x: scroll;
  flex-direction: row;

  h2{
    color: #000;
    font-size: 18px;
    width: 100%;
    text-align: left;
    margin: 10px 0;
    font-weight: bold;
  } 
`;

export const Image = styled.div`
  display: flex;
  flex-direction: column !important; 
  min-width: 200px;
  max-width: 200px;
  min-height: 150px;
  align-items: center;
  margin: 10px;
  border-width: 1px;
  border-color: #000;
  border-style: solid;

  img{
    object-fit: contain;
    width: 200px;
    max-height: 180px;
  }

  h2{
    color: #444;
    font-size: 15px;
    width: 100%;
    text-align: center;
    margin: 10px 0;
    font-weight: bold;
  } 

  p{
    font-size: 12px;
    margin-top: 5px;
    width: 100%;
  }

  input{
    margin: 5px 0;
    padding: 4px;
    width: 100%;
  }
`;
