import React, { useEffect, useState } from 'react';
import { Container, List, Header } from './styles';
import { useNavigate, useParams } from "react-router-dom";
import loadingfile from "./../../../images/loading.gif"
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { InputC, ButtonC } from "../../../components/simple";
import ModalC from '../../../components/modal';
import { MdDelete, MdEdit } from 'react-icons/md';


export default function LessonsAdmin() {

    const { course_id } = useParams()
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true)
    const [visibleModal, setVisibleModal] = useState(false)
    const [visibleModalDelete, setVisibleModalDelete] = useState(false)
    const [lessons, setLessons] = useState([])
    const [lessonsC, setLessonsC] = useState([])
    const [filtername, setFilterName] = useState("")
    const [file, setFile] = useState("")
    const [video, setVideo] = useState("")
    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [id, setId] = useState("")

    useEffect(() => {
        listlLessons()
    }, [])

    async function listlLessons() {
        await api.get(`/lessons/${course_id}`).then((response) => {
            setLessons(response.data)
            setLessonsC(response.data)
        }).catch(({ response }) => {
            if (response) {
                if (response.data) {
                    if (response.data.message) {
                        toast.warn(response.data.message)
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            } else {
                toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
        })
        setLoading(false)
    }

    async function createAndEditLesson() {
        if (!name || !file || !description) {
            toast.warn('Preencha name, descrição e envia uma imagem para criar novo Aula');
        } else {
            const data = new FormData();
            data.append('name', name);
            data.append('description', description);
            if (file.name) {
                data.append('file', file, file.name);
            }
            if (id) {
                await api.put(`/lesson/${id}`, data).then(async (ok) => {
                    toast.success("Aula editada com sucesso")
                    listlLessons()
                    setVisibleModal(false)
                }).catch((response) => {
                    if (response) {
                        if (response.data) {
                            if (response.data.message) {
                                toast.warn(response.data.message)
                            } else {
                                toast.error("Erro Interno. verifique sua conexão e tente novamente")
                            }
                        } else {
                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                        }
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                })
            } else {
                await api.post(`/lesson`, data).then(async (ok) => {
                    toast.success("Aula criada com sucesso")
                    listlLessons()
                    setVisibleModal(false)
                }).catch((response) => {
                    if (response) {
                        if (response.data) {
                            if (response.data.message) {
                                toast.warn(response.data.message)
                            } else {
                                toast.error("Erro Interno. verifique sua conexão e tente novamente")
                            }
                        } else {
                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                        }
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                })
            }
        }
    }

    function filterLessons(value) {
        let lessonsCopy = lessonsC
        let lessons = []
        lessonsCopy.map((item) => {
            if (String(item.name)
                .toUpperCase()
                .indexOf(value.toUpperCase()) != -1) {
                lessons.push(item)
            }
        })
        setLessons(lessons)
    }

    async function deleteLesson() {
        await api.delete(`/lesson/${id}`)
            .then(() => {
                toast.success("Aula excluida com sucesso")
                listlLessons()
                setVisibleModalDelete(false)
            }).catch(({ response }) => {
                if (response) {
                    if (response.data) {
                        if (response.data.message) {
                            toast.warn(response.data.message)
                        } else {
                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                        }
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            })
    }

    return (
        <Container>
            {
                loading ? (<img src={loadingfile} style={{ background: "transparent", height: 50, width: 50 }} />

                ) : (
                    <>

                        <h1>{lessons.length} Aulas</h1>
                        <Header>
                            <input value={filtername} onChange={(text) => {
                                setFilterName(text.target.value)
                                filterLessons(text.target.value)
                            }} placeholder='Procurar por nome' />
                            <button onClick={() => {
                                navigate(`/admin/lesson/${course_id}/new`)
                            }}>
                                Novo Aula
                            </button>
                        </Header>
                        <List>
                            {lessons.length == 0 && (
                                <h4 style={{ marginTop: 50, fontWeight: 500, width: "80%", textAlign: "center" }}>Nenhum aula encontrado</h4>
                            )}
                            {lessons.map((item) => {
                                return (
                                    <div>
                                        <div>
                                            <p>{item.order} - {item.name}</p>
                                            <p style={{ color: "#0008", marginTop: 5, fontSize: 12 }}>{item.description.substr(0, 60)}...</p>
                                        </div>

                                        <button onClick={() => {
                                            navigate(`/course/${course_id}?id=${item.id}`)
                                        }}>
                                            Visualizar
                                        </button>
                                        <button onClick={() => {
                                            navigate(`/admin/lesson/${course_id}/${item.id}`)
                                        }}>
                                            <MdEdit color="#FFF" size={20} />
                                        </button>
                                        <button onClick={() => {
                                            setId(item.id)
                                            setName(item.name)
                                            setVisibleModalDelete(true)
                                        }}>
                                            <MdDelete color="#FFF" size={20} />
                                        </button>
                                    </div>
                                )
                            })}
                        </List>
                    </>
                )
            }

            <ModalC visible={visibleModalDelete} executar={() => { setVisibleModalDelete(false) }}>
                <div style={{ paddingTop: 0, width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <h3 style={{ textAlign: "center", color: "#fff" }}>Você deseja excluir a Aula?</h3>
                    <p style={{
                        width: "90%",
                        fontSize: 20,
                        margin: "25px 0 5px 0",
                        fontWeight: "bold",
                        textAlign: "center", color: "#fff"
                    }}>{name}</p>
                    <div style={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                        justifyContent: "space-between"
                    }}>
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: "center", marginTop: 25
                        }}>

                            <ButtonC text={""} title={"Confirmar"} background={"#bb4444"}
                                width={"70%"} executar={() => {
                                    deleteLesson()
                                }} />
                        </div>
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: "center", marginTop: 15
                        }}>

                            <ButtonC text={""} title={"Cancelar"} background={"#888"}
                                width={"70%"} executar={() => {
                                    setVisibleModalDelete(false)
                                }} />
                        </div>
                    </div>
                </div>

            </ModalC>
        </Container>
    )
} 