import React, { useEffect, useState } from 'react';
import { Container, List, Header } from './styles';
import { useNavigate } from "react-router-dom";
import loadingPhoto from "./../../../images/loading.gif"
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { InputC, ButtonC, SwitchC, InputTel } from "../../../components/simple";
import { MdAddPhotoAlternate, MdDelete, MdEdit } from 'react-icons/md';
import { IconButton } from '@mui/material';
import ModalC from '../../../components/modal';
import { useAuth } from '../../../hooks/AuthContext';
import { Select } from 'antd';


export default function FranqueadosAdmin({type, nameType, nameTypes}) {

    const { user } = useAuth()

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true)
    const [visibleModalDelete, setVisibleModalDelete] = useState(false)
    const [franqueados, setFranqueados] = useState([])
    const [franqueadosC, setFranqueadosC] = useState([])
    const [filtername, setFilterName] = useState("")
    const [name, setName] = useState("")
    const [id, setId] = useState("")

    useEffect(() => {
        setLoading(true)
        listFranqueados()
    }, [type])

    async function listFranqueados() {
        setLoading(true)
        await api.get(`/users`).then((response) => {
            setFranqueados(response.data.filter((item)=>{return item.category == type}))
            setFranqueadosC(response.data.filter((item)=>{return item.category == type}))
        }).catch(({ response }) => {
            if (response) {
                if (response.data) {
                    if (response.data.message) {
                        toast.warn(response.data.message)
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            } else {
                toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
        })
        setFilterName("")
        setLoading(false)
    }

    async function deleteTecnico() {
        await api.delete(`/user/${id}`)
            .then(() => {
                toast.success(`${nameType} excluido com sucesso`)
                listFranqueados()
                setVisibleModalDelete(false)
            }).catch(({ response }) => {
                if (response) {
                    if (response.data) {
                        if (response.data.message) {
                            toast.warn(response.data.message)
                        } else {
                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                        }
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            })
    }

    function filterfranqueados(value) {
        let franqueadosCopy = franqueadosC
        let franqueados = []
        franqueadosCopy.map((item) => {
            if (String(item.name)
                .toUpperCase()
                .indexOf(value.toUpperCase()) != -1) {
                franqueados.push(item)
            }
        })
        setFranqueados(franqueados)
    }

    function phoneFormat(phone) {
        phone = phone.replace(/[^\d]/g, "");

        if (phone.length >= 11) {
            return phone.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
        }
        if (phone.length >= 10) {
            return phone.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
        }

        return phone;
    }

    return (
        <Container>
            {
                loading ? (<img src={loadingPhoto} style={{ background: "transparent", height: 50, width: 50 }} />

                ) : (
                    <>
                        <h1>{franqueados.length} {franqueados.length > 1 ? nameTypes : nameType}</h1>
                        <Header>
                            <input value={filtername} onChange={(text) => {
                                setFilterName(text.target.value)
                                filterfranqueados(text.target.value)
                            }} placeholder='Procurar por nome' />
                            <button onClick={() => {
                                navigate("/admin/franqueado/new")
                            }}>
                                Novo {nameType}
                            </button>
                        </Header>
                        <List>
                            {franqueados.length == 0 && (
                                <h4 style={{ marginTop: 50, fontWeight: 500, width: "80%", textAlign: "center" }}>Nenhum {nameType} encontrado</h4>
                            )}
                            {franqueados.map((item) => {
                                return (
                                    <div>
                                        <img src={item.photo ? item.photo_url : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__480.png"} />
                                        <div>
                                            <p>{item.name} - {phoneFormat(item.phone_number)}</p>
                                            <p style={{ color: "#0008", marginTop: 5 }}>{item.email}</p>
                                        </div>
                                        <button onClick={() => {
                                            navigate(`/contracts/${item.id}`)
                                        }}>
                                            Contratos
                                        </button>
                                        <button onClick={() => {
                                            navigate(`/admin/allos-cliente/${item.id}`)
                                        }}>
                                            OSs
                                        </button>
                                        <button onClick={() => {
                                            navigate(`/admin/financeiro-cliente/${item.id}`)
                                        }}>
                                            Financeiro
                                        </button>
                                        <button onClick={() => {
                                            navigate(`/admin/services-os/${item.id}`)
                                        }}>
                                            Serviço OSs
                                        </button>
                                        <button onClick={() => {
                                            navigate(`/admin/franqueado/${item.id}`)
                                        }}>
                                            <MdEdit color="#FFF" size={20} />
                                        </button>
                                        <button onClick={() => {
                                            setId(item.id)
                                            setName(item.name)
                                            setVisibleModalDelete(true)
                                        }}>
                                            <MdDelete color="#FFF" size={20} />
                                        </button>
                                    </div>
                                )
                            })}
                        </List>
                    </>
                )
            }

            <ModalC visible={visibleModalDelete} executar={() => { setVisibleModalDelete(false) }}>
                <div style={{ paddingTop: 0, width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <h3 style={{ textAlign: "center", color: "#fff" }}>Você deseja excluir {nameType} {name}?</h3>
                    <div style={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                        justifyContent: "space-between"
                    }}>
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: "center", marginTop: 25
                        }}>

                            <ButtonC text={""} title={"Confirmar"} background={"#bb4444"}
                                width={"70%"} executar={() => {
                                    deleteTecnico()
                                }} />
                        </div>
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: "center", marginTop: 15
                        }}>

                            <ButtonC text={""} title={"Cancelar"} background={"#888"}
                                width={"70%"} executar={() => {
                                    setVisibleModalDelete(false)
                                }} />
                        </div>
                    </div>
                </div>

            </ModalC>
        </Container>
    )
} 