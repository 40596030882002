import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../components/header';
import { useAuth } from '../hooks/AuthContext';
import Sidebar from '../components/sidebar';
import Main from '../components/main';

const RouteFranqueados = () => {

  const user = useAuth();
  const [isMenuMinimized, setIsMenuMinimized] = useState(true)

  useEffect(() => {
    let type = "";
    if (user.user) {
      type = user.user.type
    }

    if (!type) {
      window.location.assign("/login");
    } else {
      if (type != "cliente") {
        if (type == "tecnico") {
          window.location.assign("/tecnicos/home");
        } else {
          window.location.assign("/admin/home");
        }
      }
    }
  }, [])

  let type = "";
  if (user.user) {
    type = user.user.type
  }


  return type == "cliente" ?
    <>
      <Sidebar type={type} isMenuMinimized={isMenuMinimized} onChange={(e)=>{setIsMenuMinimized(e == false || !isMenuMinimized)}}/>
        <Main>
          <Header type={type} onChange={() => {
            setIsMenuMinimized(!isMenuMinimized)
          }} />
        <Outlet />
      </Main>
    </>
    : <></>
};

export default RouteFranqueados;