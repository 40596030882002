import React, { useEffect, useState } from 'react';
import { Container, List, InputFilter, Boxs } from './styles';
import { useNavigate } from "react-router-dom";
import loadingPhoto from "./../../../images/loading.gif"
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { MdVisibility, MdAdd, MdDelete, MdUpdate, MdLink, MdEdit } from 'react-icons/md';
import { useAuth } from '../../../hooks/AuthContext';
import { format } from 'date-fns';
import copy from 'copy-to-clipboard';
import ModalC from '../../../components/modal';
import { ButtonC, InputC } from '../../../components/simple';

const colorStatus = {
    "confirmado": "#21b82eAA",
    "aguardando": "#4287f5AA",
    "alteracao": "#ebb734AA",
    "expirado": "#5e6361AA",
}

export default function Companies() {

    const { user } = useAuth();

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true)
    const [visibleModal, setVisibleModal] = useState(false)
    const [visibleModalDelete, setVisibleModalDelete] = useState(false)
    const [visibleModalHandler, setVisibleModalHandler] = useState(false)
    const [companies, setCompanies] = useState([])
    const [company, setCompany] = useState({})
    const [companiesC, setCompaniesC] = useState([])
    const [filtername, setFilterName] = useState("")
    const [razao_social, setRazao_social] = useState("")
    const [observation, setObservation] = useState("")


    useEffect(() => {
        if (user.type != "cliente") {
            navigate(-1)
        } else {
            if (user.course_restricted) {
                toast.warn("Área restrita para franqueados")
                navigate(-1)
            } else {
                listCompanies()                
            }
        }
    }, [])

    async function listCompanies() {
        await api.get(`/companies`).then((response) => {
            setCompanies(response.data)
            setCompaniesC(response.data)
        }).catch(({ response }) => {
            if (response) {
                if (response.data) {
                    if (response.data.message) {
                        toast.warn(response.data.message)
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            } else {
                toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
        })
        setLoading(false)
    }

    function filterCompanies(value) {
        let CompaniesCopy = companiesC
        let Companies = []
        CompaniesCopy.map((item) => {
            if (String(item.name)
                .toUpperCase()
                .indexOf(value.toUpperCase()) != -1) {
                Companies.push(item)
            }
        })
        setCompanies(Companies)
    }

    async function createCompany() {
        if (!razao_social) {
            toast.warn("Preencha a razão social")
        } else {
            await api.post(`/company`, {
                razao_social: razao_social,
                observation: observation
            }).then((response) => {
                listCompanies()
                copy(`https://app.gurusegead.com.br/company/${response.data.id}`)
                toast.success("Formulário criado e copiado com sucesso, envie seu cliente completar o cadastro")
                navigate(`/company/${response.data.id}`)
                setVisibleModal(false)
            }).catch(({ response }) => {
                if (response) {
                    if (response.data) {
                        if (response.data.message) {
                            toast.warn(response.data.message)
                        } else {
                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                        }
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            })
        }
    }

    async function deleteCompany() {
        await api.delete(`/company/${company.id}`)
            .then(() => {
                toast.success("Formulário excluido com sucesso")
                listCompanies()
                setVisibleModalDelete(false)
            }).catch(({ response }) => {
                if (response) {
                    if (response.data) {
                        if (response.data.message) {
                            toast.warn(response.data.message)
                        } else {
                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                        }
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            })
    }

    async function handlerCompany() {
        if (observation) {
            await api.put(`/company-handler/${company.id}`, {
                observation: observation
            }).then(() => {
                    toast.success("Alteração solicitada com sucesso")
                    listCompanies()
                    setVisibleModalHandler(false)
            }).catch(({ response }) => {
                if (response) {
                    if (response.data) {
                        if (response.data.message) {
                            toast.warn(response.data.message)
                        } else {
                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                        }
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            })
        } else {
            toast.warn("Deixe uma observação para o cliente")
        }
    }
    return (
        <Container>
            <div style={{ padding: "40px 0", width: "90%", flexDirection: "column", display: "flex", alignItems: "center" }}>
                {
                    loading ? (
                        <img src={loadingPhoto} style={{ background: "transparent", height: 50, width: 50 }} />
                    ) : (
                        <>
                            <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 15 }}>
                                <h1 style={{ margin: 0 }}>{companies.length} Formulários</h1>
                                <button
                                    onClick={() => {
                                        setObservation("")
                                        setRazao_social("")
                                        setVisibleModal(true)
                                    }}
                                    style={{ width: 38, height: 38, marginLeft: 15, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#f2760a", borderRadius: 10 }}>
                                    <MdAdd color="#fff" size={29} />
                                </button>
                            </div>

                            <Boxs>
                                <div style={{ background: "#21b82e" }}>
                                    <h3>Confirmados</h3>
                                    <p>{companies.filter((item) => { return item.status == "confirmado" }).length}</p>
                                </div>
                                <div style={{ background: "#4287f5" }}>
                                    <h3>Aguardando</h3>
                                    <p>{companies.filter((item) => { return item.status == "aguardando" }).length}</p>
                                </div>
                                <div style={{ background: "#ebb734" }}>
                                    <h3>Em alterção</h3>
                                    <p>{companies.filter((item) => { return item.status == "alteracao" }).length}</p>
                                </div>
                                <div style={{ background: "#5e6361" }}>
                                    <h3>Expirados</h3>
                                    <p>{companies.filter((item) => { return item.status == "expirado" }).length}</p>
                                </div>
                            </Boxs>
                            <InputFilter value={filtername} onChange={(text) => {
                                setFilterName(text.target.value)
                                filterCompanies(text.target.value)
                            }} placeholder='Procurar por nome' />


                            <List>
                                {companies.length == 0 && (
                                    <h4 style={{ marginTop: 50, fontWeight: 500, width: "80%", textAlign: "center" }}>Nenhuma Formulário encontrado</h4>
                                )}
                                {companies.map((item) => {
                                    return (
                                        <div style={{ backgroundColor: colorStatus[item.status] }}>
                                            <span>{item.status != "aguardando" ? "Confirmado" : "Criado"} em {format(new Date(item.update_at), "dd/MM/yyyy HH:mm")}</span>
                                            <p>Razão Social: {item.razao_social}</p>
                                            {!!item.order_id && (<p style={{ cursor: "pointer", width: "100px"}} onClick={() => {
                                                navigate(`/clientes/os/${item.order_id}`)
                                            }}>OS: {item.order_id}</p>)}
                                            <p style={{ textTransform: "capitalize", position: "absolute", bottom: 15 }}>{item.status}</p>
                                            <button onClick={() => {
                                                navigate(`/view-company/${item.id}`)
                                            }}>
                                                <MdVisibility color="#000" size={15} />
                                            </button>  
                                            {
                                                (item.status == "aguardando" || item.status == "alteracao") && (
                                                    <button style={{ top: 10, right: 44 }} onClick={() => {
                                                        navigate(`/company/${item.id}`)
                                            }}>
                                                <MdEdit color="#000" size={15} />
                                            </button>
                                                )
                                            }
                                            {
                                                (item.status == "aguardando" || item.status == "alteracao") && (
                                                    <button style={{ top: 44 }} onClick={() => {
                                                        copy(`https://app.gurusegead.com.br/company/${item.id}`)
                                                        toast.success("Link do formulário copiado com sucesso")
                                            }}>
                                                <MdLink color="#000" size={15} />
                                            </button>
                                                )
                                            }
                                                    <button style={{top: 78}} onClick={() => {
                                                        setCompany(item)
                                                        setVisibleModalDelete(true)
                                            }}>
                                                <MdDelete color="#000" size={15} />
                                            </button>
                                            {
                                                item.status == "confirmado" && (
                                                    <button style={{top: 44}} onClick={() => {
                                                        setCompany(item)
                                                        setObservation("")
                                                        setVisibleModalHandler(true)
                                            }}>
                                                <MdUpdate color="#000" size={15} />
                                            </button>
                                                )
                                            }
                                           
                                        </div>
                                    )
                                })}
                            </List>
                        </>
                    )
                }
            </div>
            <ModalC visible={visibleModal} executar={() => { setVisibleModal(false) }}>
                <div style={{ paddingTop: 0, width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <InputC text={razao_social} title={"Razão Social"} placeholder={"nome"}
                        styleContent={{ margin: "0 10px 20px 10px" }} width={"100%"}
                        height={"55px"} color="#fff" executar={(text) => { setRazao_social(text) }} />
                    <InputC text={observation} title={"Observação"} placeholder={"observação"}
                        styleContent={{ margin: "0 10px 20px 10px" }} width={"100%"} minHeight={"120px"}
                        multiline={true} color="#fff" executar={(text) => { setObservation(text) }} />
                    <div style={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                        justifyContent: "space-between"
                    }}>
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: "center", marginTop: 25
                        }}>

                            <ButtonC text={""} title={"Confirmar"} background={"#f2760a"}
                                width={"100%"} executar={() => {
                                    createCompany(company.id)
                                }} />
                        </div>
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: "center", marginTop: 15
                        }}>

                            <ButtonC text={""} title={"Cancelar"} background={"#888"}
                                width={"100%"} executar={() => {
                                    setVisibleModal(false)
                                }} />
                        </div>
                    </div>
                </div>

            </ModalC>
            <ModalC visible={visibleModalHandler} executar={() => { setVisibleModalHandler(false) }}>
                <div style={{ paddingTop: 0, width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <h3 style={{ textAlign: "center", color: "#fff" }}>Digite o que você deseja solicitar de correção no Formulário</h3>
                    <InputC text={observation} title={"Observação"} placeholder={"observação"}
                        styleContent={{ margin: "0 10px 20px 10px" }} width={"100%"} minHeight={"120px"}
                        multiline={true} color="#fff" executar={(text) => { setObservation(text) }} />
                    <div style={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                        justifyContent: "space-between"
                    }}>
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: "center", marginTop: 25
                        }}>

                            <ButtonC text={""} title={"Confirmar"} background={"#bb4444"}
                                width={"70%"} executar={() => {
                                    handlerCompany()
                                }} />
                        </div>
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: "center", marginTop: 15
                        }}>

                            <ButtonC text={""} title={"Cancelar"} background={"#888"}
                                width={"70%"} executar={() => {
                                    setVisibleModalHandler(false)
                                }} />
                        </div>
                    </div>
                </div>

            </ModalC>
            <ModalC visible={visibleModalDelete} executar={() => { setVisibleModalDelete(false) }}>
                <div style={{ paddingTop: 0, width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <h3 style={{ textAlign: "center", color: "#fff" }}>Você deseja excluir a Formulário de {company.razao_social}?</h3>
                    <div style={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                        justifyContent: "space-between"
                    }}>
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: "center", marginTop: 25
                        }}>

                            <ButtonC text={""} title={"Confirmar"} background={"#bb4444"}
                                width={"70%"} executar={() => {
                                    deleteCompany(company.id)
                                }} />
                        </div>
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: "center", marginTop: 15
                        }}>

                            <ButtonC text={""} title={"Cancelar"} background={"#888"}
                                width={"70%"} executar={() => {
                                    setVisibleModalDelete(false)
                                }} />
                        </div>
                    </div>
                </div>

            </ModalC>
        </Container>
    )
} 