import React, { useEffect, useState } from 'react';
import { Container, Contact, Services, Docs, Observation, Resume, Actions, Urgente } from './styles';
import { useNavigate, useParams } from "react-router-dom";
import loadingPhoto from "./../../../images/loading.gif"
import { MdDelete, MdDownload, MdUpload } from "react-icons/md"
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { useAuth } from '../../../hooks/AuthContext';
import Status from '../../../components/status';
import { TbUrgent } from "react-icons/tb"
import ModalC from '../../../components/modal';
import { ButtonC, InputC } from '../../../components/simple';
import loadingBranco from "./../../../images/loadingBranco.gif"
import { format } from 'date-fns';

export default function OSDetails() {
    const navigate = useNavigate();
    const { id } = useParams()
    const { user } = useAuth()
    const [loading, setLoading] = useState(true)
    const [isLoading, setIsLoading] = useState("")
    const [order, setOrder] = useState({
        items: []
    })
    const [message, setMessage] = useState("")
    const [visibleModal, setVisibleModal] = useState(false)
    const [visibleModalView, setVisibleModalView] = useState(false)
    const [visibleModalRecused, setVisibleModalRecused] = useState(false)
    const [files, setFiles] = useState([])
    const [filesUp, setFilesUp] = useState([])
    const [totalServices, setTotalServices] = useState(0)
    const [totalValue, setTotalValue] = useState(0)

    useEffect(() => {
        loadingOS()
    }, [id])

    async function loadingOS() {
        await api.get(`/order/${id}`).then((response) => {
            let totalS = 0
            let totalV = 0
            response.data.items.map((item) => {
                totalS += item.amount
                totalV += item.amount * item.value
            })
            if (response.data.message && (response.data.status == "recusado" || response.data.status == "pendente")) {
                setMessage(response.data.message)
                setVisibleModalView(true)
            }
            setTotalServices(totalS)
            setTotalValue(totalV)
            setFiles(response.data.docs)
            setOrder(response.data)
        }).catch(({ response }) => {
            if (response) {
                if (response.data) {
                    if (response.data.message) {
                        toast.warn(response.data.message)
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            } else {
                toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
        })
        setLoading(false)
    }

    async function uploadDocs(filesUpload) {
        filesUpload.map(async (item) => {
            const data = new FormData();
            data.append('type', user.type);
            data.append('file', item, item.name);
            await api.post(`/doc/${order.id}`, data).then(async (response) => {
                setFilesUp(prevFiles => {
                    const newState = [...prevFiles]
                    newState.shift()
                    return newState
                })
                setFiles(prevFiles => [...prevFiles, response.data])
                toast.success(`O documento ${item.name} foi enviado com sucesso`)
            }).catch(({ response }) => {
                if (response) {
                    if (response.data) {
                        if (response.data.message) {
                            toast.warn(response.data.message)
                        } else {
                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                        }
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            })
        })
    }

    async function deleteDoc(fileDelete) {
        await api.delete(`/doc/${fileDelete.id}?type=${user.type}`).then(async (response) => {
            setFiles(prevFiles => {
                const newFiles = [...prevFiles]
                const idx = newFiles.indexOf(fileDelete);
                if (idx > -1) {
                    newFiles.splice(idx, 1);
                }
                return newFiles
            })
            toast.success(`O documento ${String(fileDelete.file).substr(33)} foi deletado com sucesso`)
        }).catch(({ response }) => {
            if (response) {
                if (response.data) {
                    if (response.data.message) {
                        toast.warn(response.data.message)
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            } else {
                toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
        })
    }

    return (
        <Container>
            {
                loading ? (<img src={loadingPhoto} style={{ background: "transparent", height: 50, width: 50 }} />

                ) : (
                    <>
                        {order.urgent && (<Urgente>
                            <TbUrgent color="#FFF" size={30} style={{ marginRight: 10 }} />
                            <p>Urgente</p>
                        </Urgente>)}
                        <h1 style={{ marginBottom: 0 }}>Ordem de Serviço</h1>
                        <p style={{ fontSize: 12, marginBottom: 5 }}>Aberta em {format(new Date(order.create_at), "dd/MM/yyyy")}</p>
                        <Status status={order.status} />
                            {
                                order.collaborator && (
                                    <>
                                        <h3 style={{ marginTop: 25 }}>Técnico</h3>
                                        <Contact>
                                            <img src={order.collaborator.photo ? order.collaborator.photo_url : "https://png.pngtree.com/png-vector/20190710/ourlarge/pngtree-user-vector-avatar-png-image_1541962.jpg"} />
                                            <div>
                                                <p>{order.collaborator.name}</p>
                                            </div>
                                        </Contact>
                                    </>
                                )
                            } 
                            {
                                order.order_doc_recused && (
                                    <>
                                        <h3>Visualizar OS que foi solicitada a revisão</h3>
                                        <button onClick={() => {
                                            navigate(`/clientes/os/${order.order_linked_id}`)
                                        }} style={{ margin: "10px 0 15px 0", height: 40 }}>{`Visualizar OS: ${order.order_linked_id}`}</button>
                                    </>
                                )
                            }
                            {
                                (order.status == "finalizado" && !order.order_doc_recused) && (
                                    <>
                                        <h3>{order.order_linked_id ? "Visualizar OS de revisão" : "Solicitar OS caso Documentação seja negada"}</h3>
                                        <button onClick={() => {
                                            if(order.order_linked_id) {
                                                navigate(`/clientes/os/${order.order_linked_id}`)
                                            } else {
                                                setVisibleModalRecused(true)
                                            }
                                        }} style={{ margin: "10px 0 15px 0", height: 40 }}>{order.order_linked_id ? `Visualizar OS: ${order.order_linked_id}` : "Solicitar" }</button>
                                    </>
                                )
                            }
                            {
                            order.observation && (
                                <>
                                    <h3>Observação</h3>
                                    <Observation>
                                        <p>{order.observation}</p>
                                    </Observation>
                                </>
                            )
                            }   
                            {
                                order.company_id && (
                                    <>
                                        <h3>Formulário da Empresa</h3>
                                        <button onClick={() => {
                                            navigate(`/view-company/${order.company_id}`)
                                        }} style={{margin: "10px 0 15px 0", height: 40}}>Visualizar</button>
                                    </>
                                )
                            }
                            <h3>{order.sector}</h3>
                            {(order.status == "aberto" || order.status == "pendente"|| order.status == "alteracao") && (
                                <button onClick={async () => {
                                    navigate(`/clientes/alteracao-os/${order.id}`)
                                }}>
                                    Adicionar Novos Serviços
                                </button>)}
                        <Services>
                            {
                                order.items.map((item) => {
                                    return (
                                        <div>
                                            <p style={{ flex: 1 }}>{item.name}</p>
                                            <p>{item.amount}x</p>
                                        </div>
                                    )
                                })
                            }
                        </Services>
                        <h3>Documentos do Franqueado</h3>

                        <Docs>
                            {
                                files.map((item) => {
                                    if (item.type == "cliente") {
                                        return (
                                            <div key={item.file}>
                                                <p>{String(item.file).substr(33)}</p>
                                                <div style={{ display: "flex", flexDirection: "row", minHeight: 20, padding: 0, margin: 0 }}>
                                                    <MdDownload onClick={() => {
                                                        window.open("https://guruseg-data.s3.sa-east-1.amazonaws.com/" + item.file)
                                                    }} style={{ cursor: "pointer" }} size={35} color="#f2760a" />
                                                    {order.status == "pendente" && (<MdDelete onClick={() => {
                                                        deleteDoc(item)
                                                    }} style={{ cursor: "pointer" }} size={30} color="#f2760a" />)}
                                                </div>

                                            </div>
                                        )
                                    }
                                })
                            }
                            {
                                filesUp.map((item) => {
                                    return (
                                        <div key={item.name}>
                                            <p>{item.name}</p>
                                            <img src={loadingPhoto} style={{ width: 60, height: 40 }} />
                                        </div>
                                    )
                                })
                            }
                            {order.status == "pendente" && (
                                <div for="avatar" style={{ backgroundColor: "#f2760a", position: "relative" }}>
                                    <p style={{ color: "#fff" }}>Enviar Documentoss</p>
                                    <MdUpload size={35} color="#fff" />
                                    <input id="avatar" style={{ cursor: "pointer", position: "absolute", width: "100%", height: "100%", opacity: 0 }} multiple type="file" accept="image/" onChange={(file) => {
                                        let filesUp = Object.values(file.target.files)
                                        filesUp.map((item) => {
                                            item.file_url = URL.createObjectURL(item)
                                        })
                                        setFilesUp(prevFiles => [...prevFiles, ...filesUp])
                                        uploadDocs(filesUp)
                                    }} />
                                </div>)}
                        </Docs>

                        {(order.status == "finalizado" || order.status == "validacao") && (
                            <>
                                <h3>Documentos do Técnico</h3>
                                <Docs>
                                    {
                                        files.map((item) => {
                                            if (item.type == "tecnico") {
                                                return (
                                                    <div key={item.file}>
                                                        <p>{String(item.file).substr(33)}</p>
                                                        <div style={{ display: "flex", flexDirection: "row", minHeight: 20, padding: 0, margin: 0 }}>
                                                            <MdDownload onClick={() => {
                                                                window.open("https://guruseg-data.s3.sa-east-1.amazonaws.com/" + item.file)
                                                            }} style={{ cursor: "pointer" }} size={35} color="#f2760a" />
                                                            {order.status == "pendente" && (<MdDelete onClick={() => {
                                                                deleteDoc(item)
                                                            }} style={{ cursor: "pointer" }} size={30} color="#f2760a" />)}
                                                        </div>

                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </Docs>
                            </>
                        )}
                        <Resume>
                            <p style={{ marginBottom: 15 }}>{totalServices} serviço{totalServices > 1 ? "s" : ""}</p>
                            {
                                order.urgent ? (
                                    <>
                                        <p style={{ fontSize: 16, marginTop: 0, marginBottom: 5 }}>Subtotal: {totalValue.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} </p>
                                        <p style={{ fontSize: 16, marginTop: 0, marginBottom: 10 }}>10% da urgência: {(totalValue * 0.1).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                                        <p style={{ fontWeight: "bold" }}>Total: {(totalValue * 1.1).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} </p>
                                    </>
                                ) : (
                                    <p style={{ fontWeight: "bold" }}>Total: {totalValue.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} </p>
                                )
                            }
                        </Resume>

                        <Actions>

                            {order.status == "validacao" && (
                                <button onClick={async () => {
                                    setMessage("")
                                    setVisibleModal(true)
                                }}>
                                    Solicitar Alteração
                                </button>)}

                            {order.status == "validacao" && (
                                <button
                                    disabled={isLoading == "valid"}
                                    onClick={async () => {
                                        setIsLoading("valid")
                                        await api.put(`/status/${order.id}`, { status: "finalizado" }).then((response) => {
                                            toast.success("Ordem de Serviço finalizada com sucesso")
                                            loadingOS()
                                        }).catch(({ response }) => {
                                            if (response) {
                                                if (response.data) {
                                                    if (response.data.message) {
                                                        toast.warn(response.data.message)
                                                    } else {
                                                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                                                    }
                                                } else {
                                                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                                                }
                                            } else {
                                                toast.error("Erro Interno. verifique sua conexão e tente novamente")
                                            }
                                        })
                                        setIsLoading("")
                                    }}>
                                    {
                                        isLoading == "valid" ? (
                                            <img src={loadingBranco} style={{ background: "transparent", height: 50, width: 50, margin: "0 40px" }} />
                                        ) : "Finalizar Serviço"
                                    }
                                </button>)}
                            {order.status == "pendente" && (
                                <button onClick={async () => {
                                    setMessage("")
                                    setVisibleModal(true)
                                }}>
                                    Confirmar Documentos
                                    </button>)}
                                    
                            {(order.status == "aberto" || order.status == "pendente") && (
                                <button onClick={async () => {
                                    await api.put(`/order-cancel/${order.id}`, { status: "andamento" }).then((response) => {
                                        toast.success("Ordem de serviço cancelada com sucesso")
                                        loadingOS()
                                    })
                                }}>
                                    Cancelar Solicitação
                                </button>)}
                        </Actions>
                    </>
                )
            }
            <ModalC visible={visibleModalRecused} executar={() => {
                setVisibleModalRecused(false)
            }}>
                <div style={{ paddingTop: 0, width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div style={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                        justifyContent: "space-between"
                    }}>
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: "center", marginTop: 10
                        }}>
                            <InputC text={message} multiline title={"Deseja deixar uma mensagem para o técnico?"} placeholder={"descreva o que precisa"} width={"100%"} minHeight={"100px"} executar={(text) => { setMessage(text) }} />

                            <ButtonC disabled={isLoading == "accept"} style={{ height: 40 }} title={
                                isLoading == "accept" ? (
                                    <img src={loadingBranco} style={{ background: "transparent", height: 40, width: 40, margin: "0 40px" }} />
                                ) : "Confirmar"
                            } background={"#bb4444"}
                                width={"100%"} executar={async () => {
                                    setIsLoading("accept")
                                        await api.put(`/doc-recused-order/${order.id}`, { observation: message }).then((response) => {
                                            toast.success("Solicitação de revisão do documento da OS realizada com sucesso")
                                            navigate(`/clientes/os/${response.data.id}`)
                                        }).catch(({ response }) => {
                                            if (response) {
                                                if (response.data) {
                                                    if (response.data.message) {
                                                        toast.warn(response.data.message)
                                                    } else {
                                                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                                                    }
                                                } else {
                                                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                                                }
                                            } else {
                                                toast.error("Erro Interno. verifique sua conexão e tente novamente")
                                            }
                                        })
                                    setIsLoading("")
                                    setVisibleModalRecused(false)
                                }} />
                        </div>
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: "center", marginTop: 15
                        }}>

                            <ButtonC text={""} disabled={isLoading == "accept"}  title={"Cancelar"} background={"#888"}
                                width={"100%"} executar={() => {
                                    setVisibleModalRecused(false)
                                }} />
                        </div>
                    </div>
                </div>

            </ModalC>
            <ModalC visible={visibleModal} executar={() => {
                if (isLoading != "accept") {
                    setVisibleModal(false)
                }
            }}>
                <div style={{ paddingTop: 0, width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div style={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                        justifyContent: "space-between"
                    }}>
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: "center", marginTop: 10
                        }}>
                            <InputC text={message} multiline title={"Deseja deixar uma mensagem para o técnico?"} placeholder={"descreva o que precisa"} width={"100%"} minHeight={"100px"} executar={(text) => { setMessage(text) }} />

                            <ButtonC disabled={isLoading == "accept"} style={{ height: 40 }} title={
                                isLoading == "accept" ? (
                                    <img src={loadingBranco} style={{ background: "transparent", height: 40, width: 40, margin: "0 40px" }} />
                                ) : "Confirmar"
                            } background={"#bb4444"}
                                width={"100%"} executar={async () => {
                                    setIsLoading("accept")
                                    if (order.status == "validacao") {
                                        await api.put(`/status/${order.id}`, { status: "alteracao", message }).then((response) => {
                                            toast.success("Solicitação de alteração da Ordem de Serviço realizada com sucesso")
                                            loadingOS()
                                        }).catch(({ response }) => {
                                            if (response) {
                                                if (response.data) {
                                                    if (response.data.message) {
                                                        toast.warn(response.data.message)
                                                    } else {
                                                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                                                    }
                                                } else {
                                                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                                                }
                                            } else {
                                                toast.error("Erro Interno. verifique sua conexão e tente novamente")
                                            }
                                        })
                                    } else {
                                        if (order.collaborator_id) {
                                            await api.put(`/status/${order.id}`, { status: "andamento", message }).then((response) => {
                                                toast.success("Documentos confirmados com sucesso")
                                                loadingOS()
                                            })
                                        } else {
                                            await api.put(`/confirm-order/${order.id}`, { message }).then((response) => {
                                                if (response.data.collaborator_id) {
                                                    toast.success("Documentos confirmados com sucesso e um técnico já foi vinculado a sua OS")
                                                } else {
                                                    toast.success("Documentos confirmados com sucesso")
                                                }
                                                loadingOS()
                                            })
                                        }
                                    }
                                    setIsLoading("")
                                    setVisibleModal(false)
                                }} />
                        </div>
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: "center", marginTop: 15
                        }}>

                            <ButtonC text={""} disabled={isLoading == "accept"}  title={"Cancelar"} background={"#888"}
                                width={"100%"} executar={() => {
                                    setVisibleModal(false)
                                }} />
                        </div>
                    </div>
                </div>

            </ModalC>
            <ModalC visible={visibleModalView} executar={() => { setVisibleModalView(false) }}>
                <div style={{ paddingTop: 0, width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div style={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                        justifyContent: "space-between"
                    }}>
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: "center", marginTop: 10
                        }}>
                            <h1 style={{ marginBottom: 15, color: "#FFF", fontSize: 18 }}>Mensagem</h1>
                            <p style={{ marginBottom: 15, color: "#FFF", fontSize: 18, width: "90%", textAlign: "center" }}>{message}</p>
                            <div style={{
                                display: "flex",
                                flexWrap: "wrap",
                                width: "100%",
                                justifyContent: "center", marginTop: 15
                            }}>

                                <ButtonC text={""} title={"Fechar"} background={"#888"}
                                    width={"100%"} executar={() => {
                                        setVisibleModalView(false)
                                    }} />
                            </div>
                        </div>
                    </div>
                </div>

            </ModalC>
        </Container >
    )
} 