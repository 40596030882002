import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../components/header';
import { useAuth } from '../hooks/AuthContext';
import Sidebar from '../components/sidebar';
import Main from '../components/main';



const RouteTecnicos = () => {

  const user = useAuth();
  
  const [isMenuMinimized, setIsMenuMinimized] = useState(true)

  useEffect(() => {
    let type = "";
    if (user.user) {
      type = user.user.type
    }

    if (!type) {
      window.location.assign("/tecnicos/login");
    } else {
      if (type != "tecnico") {
        if (type == "tecnico") {
          window.location.assign("/clientes/home");
        } else {
          window.location.assign("/admin/home");
        }
      }
    }
  }, [])

  let type = "";
  if (user.user) {
    type = user.user.type
  }

  return type == "tecnico" ?
    <>
      <Sidebar type={type} isMenuMinimized={isMenuMinimized} onChange={(e)=>{setIsMenuMinimized(e == false || !isMenuMinimized)}}/>
        <Main>
          <Header type={type} onChange={() => {
            setIsMenuMinimized(!isMenuMinimized)
          }} />
        <Outlet />
      </Main>
    </>
  : <></>
};
export default RouteTecnicos;