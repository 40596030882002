import React, { useEffect, useState } from 'react';
import { Container, Images, Risk, Risks, Image } from './styles';
import { useParams } from "react-router-dom";
import loadingPhoto from "./../../../images/loading.gif"
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { useMediaQuery } from 'react-responsive';
import logoBranca from "./../../../images/logoBranca.jpeg";
import { format } from 'date-fns';

export default function Company() {

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1200px)'
    })

    const { company_id } = useParams()

    const [loading, setLoading] = useState(true)
    const [total, setTotal] = useState(0)
    const [totalM, setTotalM] = useState(0)
    const [company, setCompany] = useState([])
    const [error, setError] = useState("")

    useEffect(() => {
        loadingCompany()
    }, [])

    async function loadingCompany() {
        await api.get(`/company/${company_id}`).then((response) => {
            setCompany(response.data)
            let total = 0
            let totalM = 0
            response.data.companySector.map((item) => {
                item.companyEmployees.map(data => {
                    total += parseInt(data.quantidade_colaboradores) || 0
                    totalM += parseInt(data.quantidade_colaboradores_m) || 0
                })
            })
            setTotal(total)
            setTotalM(totalM)
        }).catch(({ response }) => {
            if (response) {
                if (response.data) {
                    if (response.data.message) {
                        setError(response.data.message)
                        toast.warn(response.data.message)
                    } else {
                        setError("Erro Interno. verifique sua conexão e tente novamente")
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    setError("Erro Interno. verifique sua conexão e tente novamente")
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            } else {
                setError("Erro Interno. verifique sua conexão e tente novamente")
                toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
        })
        setLoading(false)
    }

    return (
        <Container>
            {
                loading ? (<img src={loadingPhoto} style={{ background: "transparent", height: 50, width: 50 }} />
                ) : (
                    <>
                        {
                            error ? (
                                <h3 style={{ marginTop: 100, textAlign: "center" }}>{error}</h3>
                            ) : (
                                <div style={isDesktopOrLaptop ? { padding: "20px", flexDirection: "column" } : { maxWidth: "92%", width: "92%", flexDirection: "column" }}>
                                    <img src={logoBranca} />
                                    <h3>Formulário EMPRESA</h3>
                                    <h4 style={{ margin: "20px 0 0 0" }}>DADOS DA EMPRESA</h4>
                                    <span style={{ margin: "20px 0 10px 0" }}>Razão Social: <strong>{company.razao_social}</strong></span>
                                    <span style={{ margin: "5px 0 10px 0" }}>Nome Fantasia: <strong>{company.nome_fantasia}</strong></span>
                                    <span style={{ margin: "5px 0 10px 0" }}>CNPJ / CAEPF: <strong>{company.cnpj}</strong></span>
                                    <span style={{ margin: "5px 0 10px 0" }}>Ramo de Atividade: <strong>{company.ramo_atividade}</strong></span>
                                    <span style={{ margin: "5px 0 10px 0" }}>CEP: <strong>{company.cep}</strong></span>
                                    <span style={{ margin: "5px 0 10px 0" }}>Endereço: <strong>{company.endereco}</strong></span>
                                    <h4 style={{ margin: "20px 0 15px 0" }}>RESPONSÁVEL</h4>
                                    <span style={{ margin: "5px 0 10px 0" }}>Nome do Responsável Assinante: <strong>{company.nome_responsavel}</strong></span>
                                    <span style={{ margin: "5px 0 10px 0" }}>Número do CPF do Representante: <strong>{company.cpf_responsavel}</strong></span>
                                    <span style={{ margin: "5px 0 10px 0" }}>Contato do Representante: <strong>{company.contato_responsavel}</strong></span>
                                    {
                                        company.companySector.map((item, index)=>{
                                            return (
                                                <>
                                                    <h4 style={{ margin: "20px 0 10px 0" }}>SETOR {index+1}</h4>
                                                    <span style={{ margin: "5px 0 0px 0" }}>Nome: <strong>{item.name}</strong></span>
                                                    <span style={{ margin: "5px 0 10px 0" }}>Descrição: <strong>{item.description}</strong></span>
                                                    {
                                                        item.companyEmployees.map((data, index)=>{
                                                            return (
                                                                <>
                                                                    <h5 style={{ margin: "20px 0 10px 0" }}>FUNÇÃO {index+1}</h5>
                                                                    <span style={{ margin: "5px 0 0px 0" }}>Nome: <strong>{data.name}</strong></span>
                                                                    <span style={{ margin: "5px 0 0px 0" }}>N° CBO: <strong>{data.cbo}</strong></span>
                                                                    <span style={{ margin: "5px 0 0px 0" }}>Descrição das Atividades: <strong>{data.description}</strong></span>
                                                                    <span style={{ margin: "5px 0 0px 0" }}>EPIS usados na função: <strong>{data.epis}</strong></span>
                                                                    <span style={{ margin: "5px 0 0px 0" }}>Quantidade colaboradores: <strong>{data.quantidade_colaboradores}</strong></span>
                                                                    <span style={{ margin: "5px 0 0px 0" }}>Quantidade colaboradores masculinos: <strong>{data.quantidade_colaboradores_m}</strong></span>
                                                                    <span style={{ margin: "5px 0 0px 0" }}>Quantidade colaboradores femininos: <strong>{data.quantidade_colaboradores_f}</strong></span>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                    <Risks>
                                                        <h5 style={{ margin: "20px 0 10px 0" }}>RISCOS DO SETOR</h5>
                                                        <div>
                                                            <Risk>
                                                                <h2>Fisico</h2>
                                                                {
                                                                    item.companyScratchs.filter((item) => item.type == "fisico").length == 0 && (
                                                                        <p style={{ fontWeight: "bold" }}>Nenhum risco</p>
                                                                    )
                                                                }
                                                                {
                                                                    item.companyScratchs.filter((item) => item.type == "fisico").map((risk) => {
                                                                        {
                                                                            return (
                                                                                <>
                                                                                <p style={{fontWeight: "bold"}}>{risk.name}</p>
                                                                                {risk.description && (<p>Causa: {risk.description}</p>)}
                                                                                </>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                            </Risk>
                                                            <Risk>
                                                                <h2>Quimico</h2>
                                                                {
                                                                    item.companyScratchs.filter((item) => item.type == "quimico").length == 0 && (
                                                                        <p style={{ fontWeight: "bold" }}>Nenhum risco</p>
                                                                    )
                                                                }
                                                                {
                                                                    item.companyScratchs.filter((item) => item.type == "quimico").map((risk) => {
                                                                        {
                                                                            return (
                                                                                <>
                                                                                <p style={{fontWeight: "bold"}}>{risk.name}</p>
                                                                                {risk.description && (<p>Causa: {risk.description}</p>)}
                                                                                </>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                            </Risk>
                                                            <Risk>
                                                                <h2>Acidente</h2>
                                                                {
                                                                    item.companyScratchs.filter((item) => item.type == "acidente").length == 0 && (
                                                                        <p style={{ fontWeight: "bold" }}>Nenhum risco</p>
                                                                    )
                                                                }
                                                                {
                                                                    item.companyScratchs.filter((item) => item.type == "acidente").map((risk) => {
                                                                        {
                                                                            return (
                                                                                <>
                                                                                <p style={{fontWeight: "bold"}}>{risk.name}</p>
                                                                                {risk.description && (<p>Causa: {risk.description}</p>)}
                                                                                </>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                            </Risk>
                                                            <Risk>
                                                                <h2>Ergonomico</h2>
                                                                {
                                                                    item.companyScratchs.filter((item) => item.type == "ergonomico").length == 0 && (
                                                                        <p style={{ fontWeight: "bold" }}>Nenhum risco</p>
                                                                    )
                                                                }
                                                                {
                                                                    item.companyScratchs.filter((item) => item.type == "ergonomico").map((risk) => {
                                                                        {
                                                                            return (
                                                                                <>
                                                                                <p style={{fontWeight: "bold"}}>{risk.name}</p>
                                                                                {risk.description && (<p>Causa: {risk.description}</p>)}
                                                                                </>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                            </Risk>
                                                            <Risk>
                                                                <h2>Biologico</h2>
                                                                {
                                                                    item.companyScratchs.filter((item) => item.type == "biologico").length == 0 && (
                                                                        <p style={{ fontWeight: "bold" }}>Nenhum risco</p>
                                                                    )
                                                                }
                                                                {
                                                                    item.companyScratchs.filter((item) => item.type == "biologico").map((risk) => {
                                                                        {
                                                                            return (
                                                                                <>
                                                                                <p style={{fontWeight: "bold"}}>{risk.name}</p>
                                                                                {risk.description && (<p>Causa: {risk.description}</p>)}
                                                                                </>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                            </Risk> 
                                                        </div>
                                                    </Risks>
                                                    {
                                                        !!company.companyImages[index].length && (
                                                            <>
                                                            <h5 style={{ margin: "20px 0 0 0" }}>IMAGENS DO SETOR</h5>
                                                                <Images style={{justifyContent: "flex-start"}}>
                                                                    {company.companyImages[index].map((item) => {
                                                                        return (
                                                                            <Image>
                                                                                <a href={item.photo_url} target='_blank'>
                                                                                   <img src={item.photo_url} /> 
                                                                                </a>
                                                                            </Image>
                                                                        )}
                                                                    )}
                                                                </Images>
                                                            </>
                                                        )
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                    
                                    <h4 style={{ margin: "35px 0 0px 0" }}>Total de Funionários: {total}</h4>
                                    <h4 style={{ margin: "5px 0" }}>Total de Funionários Femininos: {total - totalM || 0}</h4>
                                    <h4 style={{ margin: "0px 0 35px 0" }}>Total de Funionários Masculinos: {totalM}</h4>
                                            
                                    <h4 style={{ textAlign: "center", margin: "35px 0 5px 0" }}>Guruseg Gestão e Treinamentos em Segurança do Trabalho:</h4>
                                    <a>www.guruseg.com.br</a>
                                    <h4 style={{ textAlign: "center", marginTop: 35 }}>Apresentação:</h4>
                                    <span style={{ textAlign: "center", margin: "35px 0" }}>
                                        Somos uma empresa especializada em Segurança do Trabalho formada por Médicos especializados e uma equipe multidisciplinar (Médicos, Engenheiros e Técnicos), focada na qualidade agilidade dos serviços prestados aos nossos clientes, somos referência em todo Brasil. Atuando na área de Gestão ao E-social, elaboração e documentos, Treinamentos e muito mais, contamos com unidades Franqueadas em todos os estados Brasileiro.
                                    </span>
                                    
                                    {
                                        company.signature && (
                                            <h4 style={{ color: "#000", textAlign: "center", maxWidth: "90%", margin: "25px 0 50px 0" }}>
                                                Formulário foi {company.signature} no dia {format(new Date(company.signature_date), "dd/MM/yyyy HH:mm")}
                                            </h4>
                                        )
                                    }
                                    
                                </div>
                            )
                        }
                    </>

                )
            }
        </Container >
    )
} 