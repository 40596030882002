import React, { useEffect, useState } from 'react';
import { Container, List, Header } from './styles';
import loadingPhoto from "./../../../images/loading.gif"
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { InputC, ButtonC } from "./../../../components/simple";
import { MdAddPhotoAlternate, MdDelete, MdEdit } from 'react-icons/md';
import { IconButton } from '@mui/material';
import ModalC from './../../../components/modal';


export default function BannersAdmin() {

    const [loading, setLoading] = useState(true)
    const [visibleModal, setVisibleModal] = useState(false)
    const [visibleModalDelete, setVisibleModalDelete] = useState(false)
    const [banners, setBanners] = useState([])
    const [photo, setPhoto] = useState("")
    const [url, setUrl] = useState("")
    const [id, setId] = useState("")
    const [types, setTypes] = useState("")

    useEffect(() => {
        listbanners()
    }, [])

    async function listbanners() {
        await api.get(`/banners`).then((response) => {
            setBanners(response.data)
        }).catch(({ response }) => {
            if (response) {
                if (response.data) {
                    if (response.data.message) {
                        toast.warn(response.data.message)
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            } else {
                toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
        })
        setLoading(false)
    }

    async function createAndEditService() {
        if (!url || !photo || !types) {
            toast.warn('Preencha url, visualizadores e imagem para criar novo banner');
        } else {
            const data = new FormData();
            data.append('url', url);
            data.append('types', types);
            if (photo.name) {
                data.append('file', photo, photo.name);
            }
            if (id) {
                await api.put(`/banner/${id}`, data).then(async (ok) => {
                    toast.success("Banner editado com sucesso")
                    listbanners()
                    setVisibleModal(false)
                }).catch(({ response }) => {
                    if (response) {
                        if (response.data) {
                            if (response.data.message) {
                                toast.warn(response.data.message)
                            } else {
                                toast.error("Erro Interno. verifique sua conexão e tente novamente")
                            }
                        } else {
                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                        }
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                })
            } else {
                await api.post(`/banner`, data).then(async (ok) => {
                    toast.success("Banner criado com sucesso")
                    listbanners()
                    setVisibleModal(false)
                }).catch(({ response }) => {
                    if (response) {
                        if (response.data) {
                            if (response.data.message) {
                                toast.warn(response.data.message)
                            } else {
                                toast.error("Erro Interno. verifique sua conexão e tente novamente")
                            }
                        } else {
                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                        }
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                })
            }
        }
    }


    async function deleteService() {
        await api.delete(`/banner/${id}`)
            .then(() => {
                toast.success("Banner excluido com sucesso")
                listbanners()
                setVisibleModalDelete(false)
            }).catch(({ response }) => {
                if (response) {
                    if (response.data) {
                        if (response.data.message) {
                            toast.warn(response.data.message)
                        } else {
                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                        }
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            })
    }

    return (
        <Container>
            {
                loading ? (<img src={loadingPhoto} style={{ background: "transparent", height: 50, width: 50 }} />

                ) : (
                    <>
                        <Header>
                            <button onClick={() => {
                                setId("")
                                setUrl("")
                                setPhoto("")
                                setTypes("admin;")
                                setVisibleModal(true)
                            }}>
                                Novo Banner
                            </button>
                        </Header>
                        <List>
                            {banners.length == 0 && (
                                <h4 style={{ marginTop: 50, fontWeight: 500, width: "80%", textAlign: "center" }}>Nenhum banner encontrado</h4>
                            )}
                            {banners.map((item) => {
                                return (
                                    <div>

                                        <img src={item.photo_url} />

                                        <div>
                                            <button onClick={() => {
                                                setId(item.id)
                                                setUrl(item.url)
                                                setTypes(item.types)
                                                setPhoto({ photo_url: item.photo_url })
                                                setVisibleModal(true)
                                            }}>
                                                <MdEdit color="#FFF" size={20} />
                                            </button>
                                            <button onClick={() => {
                                                setId(item.id)
                                                setUrl(item.url)
                                                setTypes(item.types)
                                                setPhoto({ photo_url: item.photo_url })
                                                setVisibleModalDelete(true)
                                            }}>
                                                <MdDelete color="#FFF" size={20} />
                                            </button>
                                        </div>
                                    </div>
                                )
                            })}
                        </List>
                    </>
                )
            }

            <ModalC visible={visibleModal} executar={() => { setVisibleModal(false) }}>

                <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <h3 style={{ color: "#fff", marginBottom: 5, textAlign: "left", width: "100%", fontSize: 15 }}>Imagem (21x7 recomendado)</h3>
                    <div style={{
                        marginTop: 0, width: "100%", height: 160, borderRadius: 10, marginBottom: 12, justifyContent: "center"
                    }}>
                        <img style={{ width: "100%", height: 160, borderRadius: 10, background: "#fff", }} src={photo.photo_url ? photo.photo_url : "https://img.freepik.com/vetores-gratis/vetor-de-banner-moderno-mosaico-geometrico-cinza-e-branco_1055-10918.jpg?w=2000"} />
                        <IconButton style={{ width: 50, height: 50, bottom: 10, left: "90%" }}>
                            <label for="avatar" style={{ display: "flex", width: 50, height: 50, justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                                <MdAddPhotoAlternate color="#FFF" />
                                <input id="avatar" style={{ display: "none" }} type="file" accept="image/" onChange={(file) => {
                                    let photo = file.target.files[0]
                                    photo.photo_url = URL.createObjectURL(file.target.files[0])
                                    setPhoto(photo)
                                }
                                }
                                />
                            </label>
                        </IconButton>
                    </div>
                    <InputC text={url} title={"URL"} placeholder={"https://redirecionamento.com.br"} width={"100%"} height={"55px"} executar={(text) => { setUrl(text) }} />

                    <h3 style={{ color: "#fff", marginBottom: 5, textAlign: "left", width: "100%", fontSize: 15, marginTop: 15 }}>Visivel para: </h3>
                    <div style={{ display: "flex", alignItems: "flex-start", width: "100%" }}>

                        <div style={{ marginRight: 10 }}>
                            <input style={{ marginRight: 5 }} type="checkbox" id="topping" name="topping" value="franqueado;" checked={String(types)
                                .indexOf("franqueado;") != -1}
                                onChange={(e) => {
                                    let typesC = types
                                    if (String(types)
                                        .indexOf("franqueado;") != -1) {
                                        typesC = typesC.replace("franqueado;", "")
                                    } else {
                                        typesC = typesC + "franqueado;"
                                    }
                                    setTypes(typesC)
                                }} />Franqueado
                        </div>
                        <div style={{ marginRight: 10 }}>
                            <input style={{ marginRight: 5 }} type="checkbox" id="topping" name="topping" value="admin;" checked={String(types)
                                .indexOf("admin;") != -1}
                                onChange={(e) => {
                                    let typesC = types
                                    if (String(types)
                                        .indexOf("admin;") != -1) {
                                        typesC = typesC.replace("admin;", "")
                                    } else {
                                        typesC = typesC + "admin;"
                                    }
                                    setTypes(typesC)
                                }} />Admin
                        </div>
                        <div style={{ marginRight: 10 }}>
                            <input style={{ marginRight: 5 }} type="checkbox" id="topping" name="topping" value="credenciado;" checked={String(types)
                                .indexOf("credenciado;") != -1}
                                onChange={(e) => {
                                    let typesC = types
                                    if (String(types)
                                        .indexOf("credenciado;") != -1) {
                                        typesC = typesC.replace("credenciado;", "")
                                    } else {
                                        typesC = typesC + "credenciado;"
                                    }
                                    setTypes(typesC)
                                }} />Credenciado
                        </div>
                        <div style={{ marginRight: 10 }}>
                            <input style={{ marginRight: 5 }} type="checkbox" id="topping" name="topping" value="tecnico;" checked={String(types)
                                .indexOf("tecnico;") != -1}
                                onChange={(e) => {
                                    let typesC = types
                                    if (String(types)
                                        .indexOf("tecnico;") != -1) {
                                        typesC = typesC.replace("tecnico;", "")
                                    } else {
                                        typesC = typesC + "tecnico;"
                                    }
                                    setTypes(typesC)
                                }} />Técnico
                        </div>
                    </div>

                    <div style={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                        justifyContent: "center",
                        marginTop: 25
                    }}>

                        <ButtonC text={""} title={"Salvar"}
                            width={"60%"} executar={() => {
                                createAndEditService()
                            }} />
                    </div>
                </div>

            </ModalC>
            <ModalC visible={visibleModalDelete} executar={() => { setVisibleModalDelete(false) }}>
                <div style={{ paddingTop: 0, width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <img style={{ width: "100%", height: 160, borderRadius: 10, background: "#f0f0f0", marginBottom: 15 }} src={photo.photo_url ? photo.photo_url : "https://img.freepik.com/vetores-gratis/vetor-de-banner-moderno-mosaico-geometrico-cinza-e-branco_1055-10918.jpg?w=2000"} />
                    <h3 style={{ textAlign: "center", color: "#fff" }}>Você deseja excluir o banner?</h3>
                    <div style={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                        justifyContent: "space-between"
                    }}>
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: "center", marginTop: 25
                        }}>

                            <ButtonC text={""} title={"Confirmar"} background={"#bb4444"}
                                width={"70%"} executar={() => {
                                    deleteService()
                                }} />
                        </div>
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: "center", marginTop: 15
                        }}>

                            <ButtonC text={""} title={"Cancelar"} background={"#888"}
                                width={"70%"} executar={() => {
                                    setVisibleModalDelete(false)
                                }} />
                        </div>
                    </div>
                </div>

            </ModalC>
        </Container>
    )
} 