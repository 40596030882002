import styled from "styled-components";

export const Container = styled.div`
  width: 100%; 
  align-items: center;
  min-height: 100vh;
  display: flex;
  padding: 40px 0;
  flex-direction: column;
  align-items: center;

  h1{
    text-align: center;
    margin-bottom: 35px;
    color: #f2760a;
  }
  h3{
    text-align: center;
    margin: 15px 0;
  }
  @media (max-width: 850px) {
  padding: 30px 0;
  width: 95%;
  h1{
    text-align: center;
    font-size: 22px;
    margin-bottom: 15px;
  }
  }
`;


export const Services = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;

  div{

    span{
      font-size: 12px;
      text-align: center;
      margin-right: 15px;
    }
    display: flex;
    padding: 10px;
    margin-bottom: 15px;
    flex-wrap: wrap;
      align-items: center;
    flex-direction: row;
    border-bottom: 2px solid #f2760a;
    button{
        display: flex;
        height: 20px;
        padding: 0 10px;
        border-radius: 5px;
        align-items: center;
        justify-content: center;
      background-color: #f2760a;
      font-size: 10px;
          color: #fff;
          line-height: 30px;
      }
    div{
      display: flex;
      padding: 0;
    margin: 0;
    margin-right: 15px;;
      flex-direction: row;
      border: none;

      align-items: center;
      button{
        display: flex;
        height: 30px;
        width: 30px;
        padding: 0;
        border-radius: 5px;
        align-items: center;
        justify-content: center;
      background-color: #f2760a;
      font-size: 25px;
          color: #fff;
          line-height: 30px;
      }
      span{
        margin: 0 10px;
      }
  }
    p{
      color: #000;
    }
    }
  

  @media (max-width: 850px) {
  width: 100%;
 
  }
`;


export const Resume = styled.div`
  display: flex;
  justify-content: center;
    display: flex;
  flex-direction: column;
    padding: 25px;
    min-width: 350px;
    margin: 10px 15px;
    background-color:  #f2760a;
    margin-bottom: 15px;
    border-radius: 10px;
  align-items: center;
  justify-content: center;
    p{
      color: #fff;
      text-align: center;
      margin-bottom: 15px;
      font-size: 20px;
    }
    button{
        display: flex;
        padding: 10px;
        width: 100%;
        border-radius: 5px;
        align-items: center;
        justify-content: center;
      background-color: #fff;
      font-size: 18px;
        color: #000;
      }
  

  @media (max-width: 850px) {
  width: 90%;
    padding-bottom: 50px;
    border-radius: 10PX;
  }
`;

export const Button = styled.button`
  margin: 25px 0;
  padding: 16px 25px;
  border-radius: 5px;
  background-color: #f2760a;
  color: #fff;
  height: 50px;
  display: flex;
  font-size: 18px;
  max-width: 350px;
  align-items: center;
  justify-content: center;
`;
