import React, { useEffect, useState } from 'react';
import { Container, List, InputFilter } from './styles';
import { useNavigate } from "react-router-dom";
import loadingPhoto from "./../../../images/loading.gif"
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { InputC, ButtonC } from "./../../../components/simple";
import { MdAddPhotoAlternate } from 'react-icons/md';
import { MdLock } from 'react-icons/md';
import { IconButton } from '@mui/material';
import ModalC from './../../../components/modal';
import HeaderCourse from '../../../components/headerCourse';
import { useAuth } from '../../../hooks/AuthContext';


export default function CoursesPublic() {

    const { user } = useAuth();

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true)
    const [visibleModal, setVisibleModal] = useState(false)
    const [visibleModalDelete, setVisibleModalDelete] = useState(false)
    const [courses, setCourses] = useState([])
    const [coursesC, setCoursesC] = useState([])
    const [filtername, setFilterName] = useState("")
    const [photo, setPhoto] = useState("")
    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [id, setId] = useState("")


    useEffect(() => {
        redirect()
    }, [])

    function redirect() {
        if (user.type == "admin") {
            listCourses()
        } else {
            if (user.type == "cliente" && user.course) {
                listCourses()
            } else {
                navigate(-1)
            }
        }
    }


    async function listCourses() {
        await api.get(`/courses-user`).then((response) => {
            let courses = []
            response.data.map((item) => {
                if (item.lessons.length != 0) {
                    courses.push(item)
                }
            })
            if (user.course_restricted) {
                let coursesEdit = courses.filter((item) => { return item.restricted == true })
                coursesEdit = [...coursesEdit, ...courses.filter((item) => { return item.restricted == false })]
                setCourses(coursesEdit)
                setCoursesC(coursesEdit)
            } else {
                setCourses(courses)
                setCoursesC(courses)
            }
        }).catch(({ response }) => {
            if (response) {
                if (response.data) {
                    if (response.data.message) {
                        toast.warn(response.data.message)
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            } else {
                toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
        })
        setLoading(false)
    }

    async function createAndEditCourse() {
        if (!name || !photo || !description) {
            toast.warn('Preencha name, descrição e envia uma imagem para criar novo curso');
        } else {
            const data = new FormData();
            data.append('name', name);
            data.append('description', description);
            if (photo.name) {
                data.append('file', photo, photo.name);
            }
            if (id) {
                await api.put(`/course/${id}`, data).then(async (ok) => {
                    toast.success("Curso editado com sucesso")
                    listCourses()
                    setVisibleModal(false)
                }).catch((response) => {
                    if (response) {
                        if (response.data) {
                            if (response.data.message) {
                                toast.warn(response.data.message)
                            } else {
                                toast.error("Erro Interno. verifique sua conexão e tente novamente")
                            }
                        } else {
                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                        }
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                })
            } else {
                await api.post(`/course`, data).then(async (ok) => {
                    toast.success("Curso criado com sucesso")
                    listCourses()
                    setVisibleModal(false)
                }).catch((response) => {
                    if (response) {
                        if (response.data) {
                            if (response.data.message) {
                                toast.warn(response.data.message)
                            } else {
                                toast.error("Erro Interno. verifique sua conexão e tente novamente")
                            }
                        } else {
                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                        }
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                })
            }
        }
    }

    function filterCourses(value) {
        let coursesCopy = coursesC
        let courses = []
        coursesCopy.map((item) => {
            if (String(item.name)
                .toUpperCase()
                .indexOf(value.toUpperCase()) != -1) {
                courses.push(item)
            }
        })
        setCourses(courses)
    }

    async function deleteCourse() {
        await api.delete(`/banner/${id}`)
            .then(() => {
                toast.success("Banner excluido com sucesso")
                listCourses()
                setVisibleModalDelete(false)
            }).catch(({ response }) => {
                if (response) {
                    if (response.data) {
                        if (response.data.message) {
                            toast.warn(response.data.message)
                        } else {
                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                        }
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            })
    }

    return (
        <Container>
            <div style={{ padding: "40px 0", width: "90%", flexDirection: "column", display: "flex", alignItems: "center" }}>
                {
                    loading ? (<img src={loadingPhoto} style={{ background: "transparent", height: 50, width: 50 }} />

                    ) : (
                        <>

                            <h1>{courses.length} Cursos</h1>
                            <InputFilter value={filtername} onChange={(text) => {
                                setFilterName(text.target.value)
                                filterCourses(text.target.value)
                            }} placeholder='Procurar por nome' />
                            <List>
                                {courses.length == 0 && (
                                    <h4 style={{ marginTop: 50, fontWeight: 500, width: "80%", textAlign: "center" }}>Nenhum curso encontrado</h4>
                                )}
                                {courses.map((item) => {
                                    return (
                                        <div style={{opacity: user.course_restricted ? !item.restricted ? 0.75 : 1: 1}}>
                                            <img src={item.photo_url} />
                                            <p>{item.name}</p>
                                            <span>{item.description}</span>
                                            <div>

                                                <button onClick={() => {
                                                    if (user.course_restricted && !item.restricted) {
                                                        toast.warn("Curso restrito para franqueados")
                                                    } else {
                                                        navigate(`/course/${item.id}`)
                                                    }
                                                }}>
                                                    {(user.course_restricted && !item.restricted) && <MdLock color="#FFF" />} {(user.course_restricted && !item.restricted) ? "Aulas bloquadas" : "Ver aulas"}                                               </button>
                                            </div>
                                        </div>
                                    )
                                })}
                            </List>
                        </>
                    )
                }


            </div>
            <ModalC visible={visibleModal} executar={() => { setVisibleModal(false) }}>

                <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <h3 style={{ color: "#fff", marginBottom: 5, textAlign: "left", width: "100%", fontSize: 15 }}>Banner do curso (21 x 7 recomentado)</h3>
                    <div style={{
                        marginTop: 0, width: "100%", height: 160, borderRadius: 10, marginBottom: 12, justifyContent: "center"
                    }}>
                        <img style={{ width: "100%", height: 160, borderRadius: 10, background: "#fff", }} src={photo.photo_url ? photo.photo_url : "https://img.freepik.com/vetores-gratis/vetor-de-banner-moderno-mosaico-geometrico-cinza-e-branco_1055-10918.jpg?w=2000"} />
                        <IconButton style={{ width: 50, height: 50, bottom: 10, left: "90%" }}>
                            <label for="avatar" style={{ display: "flex", width: 50, height: 50, justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                                <MdAddPhotoAlternate color="#FFF" />
                                <input id="avatar" style={{ display: "none" }} type="file" accept="image/" onChange={(file) => {
                                    let photo = file.target.files[0]
                                    photo.photo_url = URL.createObjectURL(file.target.files[0])
                                    setPhoto(photo)
                                }
                                }
                                />
                            </label>
                        </IconButton>
                    </div>
                    <InputC text={name} title={"Nome do curso"} placeholder={"Aprendendo.."} width={"100%"} height={"55px"} executar={(text) => { setName(text) }} />
                    <InputC text={description} title={"Descrição do curso"} placeholder={"Aprendendo.."} width={"100%"} height={"155px"} multiline={true} executar={(text) => { setDescription(text) }} />
                    <div style={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                        justifyContent: "center",
                        marginTop: 25
                    }}>

                        <ButtonC text={""} title={"Salvar"}
                            width={"60%"} executar={() => {
                                createAndEditCourse()
                            }} />
                    </div>
                </div>

            </ModalC>
            <ModalC visible={visibleModalDelete} executar={() => { setVisibleModalDelete(false) }}>
                <div style={{ paddingTop: 0, width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <img style={{ width: "100%", height: 160, borderRadius: 10, background: "#f0f0f0", marginBottom: 15 }} src={photo.photo_url ? photo.photo_url : "https://img.freepik.com/vetores-gratis/vetor-de-banner-moderno-mosaico-geometrico-cinza-e-branco_1055-10918.jpg?w=2000"} />
                    <p style={{
                        width: "90%",
                        fontSize: 17,
                        textAlign: "center", color: "#fff"
                    }}>{name}</p>
                    <span style={{
                        width: "95%",
                        fontSize: 14,
                        margin: "5px 0 15px 0",
                        textAlign: "center",
                        color: "#fffd"
                    }}>{description}</span>
                    <h3 style={{ textAlign: "center", color: "#fff" }}>Você deseja excluir o curso?</h3>
                    <div style={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                        justifyContent: "space-between"
                    }}>
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: "center", marginTop: 25
                        }}>

                            <ButtonC text={""} title={"Confirmar"} background={"#bb4444"}
                                width={"70%"} executar={() => {
                                    deleteCourse()
                                }} />
                        </div>
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: "center", marginTop: 15
                        }}>

                            <ButtonC text={""} title={"Cancelar"} background={"#888"}
                                width={"70%"} executar={() => {
                                    setVisibleModalDelete(false)
                                }} />
                        </div>
                    </div>
                </div>

            </ModalC>
        </Container>
    )
} 