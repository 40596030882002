import React, { useEffect, useState } from 'react';
import { Container, Contact, Services, Docs, Observation, Resume, Urgente } from './styles';
import { useNavigate, useParams } from "react-router-dom";
import loadingPhoto from "./../../../images/loading.gif"
import { MdDownload } from "react-icons/md"
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { useAuth } from '../../../hooks/AuthContext';
import Status from '../../../components/status';
import { TbUrgent } from "react-icons/tb"
import { format } from 'date-fns';

export default function OSDetailsAdmin() {
    const navigate = useNavigate();
    const { id } = useParams()
    const [loading, setLoading] = useState(true)
    const [order, setOrder] = useState({
        items: []
    })
    const [files, setFiles] = useState([])
    const [filesUp, setFilesUp] = useState([])
    const [totalServices, setTotalServices] = useState(0)
    const [totalValue, setTotalValue] = useState(0)

    useEffect(() => {
        loadingOS()
    }, [id])

    async function loadingOS() {
        await api.get(`/order/${id}`).then((response) => {
            let totalS = 0
            let totalV = 0
            response.data.items.map((item) => {
                totalS += item.amount
                totalV += item.amount * item.value
            })
            setTotalServices(totalS)
            setTotalValue(totalV)
            setFiles(response.data.docs)
            setOrder(response.data)
        }).catch(({ response }) => {
            if (response) {
                if (response.data) {
                    if (response.data.message) {
                        toast.warn(response.data.message)
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            } else {
                toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
        })
        setLoading(false)
    }


    return (
        <Container>
            {
                loading ? (<img src={loadingPhoto} style={{ background: "transparent", height: 50, width: 50 }} />

                ) : (
                    <>
                        {order.urgent && (<Urgente>
                            <TbUrgent color="#FFF" size={30} style={{ marginRight: 10 }} />
                                <p>Urgente</p>
                            </Urgente>
                        )}
                        <h1 style={{ marginBottom: 0 }}>Ordem de Serviço</h1>
                        <p style={{ fontSize: 12, marginBottom: 5 }}>Aberta em {format(new Date(order.create_at), "dd/MM/yyyy")}</p>
                        <Status status={order.status} />
                        {
                            order.user && (
                                <>
                                    <h3 style={{ marginTop: 25 }}>Franqueado</h3>
                                    <Contact>
                                        <img src={order.user.photo ? order.user.photo_url : "https://png.pngtree.com/png-vector/20190710/ourlarge/pngtree-user-vector-avatar-png-image_1541962.jpg"} />
                                        <div>
                                            <p>{order.user.name}</p>
                                        </div>
                                    </Contact>
                                </>
                            )
                        }
                        {
                            order.collaborator && (
                                <>
                                    <h3 style={{ marginTop: 25 }}>Técnico</h3>
                                    <Contact>
                                        <img src={order.collaborator.photo ? order.collaborator.photo_url : "https://png.pngtree.com/png-vector/20190710/ourlarge/pngtree-user-vector-avatar-png-image_1541962.jpg"} />
                                        <div>
                                            <p>{order.collaborator.name}</p>
                                        </div>
                                    </Contact>
                                </>
                            )
                        }
                        {
                            order.order_linked_id && (
                                <>
                                    <h3>{!order.order_doc_recused ? "Visualizar OS de revisão" : "Visualizar OS que foi solicitada a revisão"}</h3>
                                    <button onClick={() => {
                                        navigate(`/admin/os/${order.order_linked_id}`)
                                    }} style={{ margin: "10px 0 15px 0", height: 40 }}>{`Visualizar OS: ${order.order_linked_id}`}</button>
                                </>
                            )
                        }
                        {
                            order.observation && (
                                <>
                                    <h3>Mensagem do franqueado</h3>
                                    <Observation>
                                        <p>{order.observation}</p>
                                    </Observation>
                                </>
                            )
                        }
                        {
                            order.company_id && (
                                <>
                                    <h3>Formulário da Empresa</h3>
                                    <button onClick={() => {
                                        navigate(`/view-company/${order.company_id}`)
                                    }} style={{margin: "10px 0 15px 0"}}>Visualizar</button>
                                </>
                            )
                        }
                        
                        <h3>{order.sector}</h3>
                        <Services>
                            {
                                order.items.map((item) => {
                                    return (
                                        <div>
                                            <p style={{ flex: 1 }}>{item.name}</p>
                                            <p>{item.amount}x</p>
                                        </div>
                                    )
                                })
                            }
                        </Services>
                        <h3>Documentos do Franqueado</h3>

                        <Docs>
                            {
                                files.map((item) => {
                                    if (item.type == "cliente") {
                                        return (
                                            <div key={item.file}>
                                                <p>{String(item.file).substr(33)}</p>
                                                <div style={{ display: "flex", flexDirection: "row", minHeight: 20, padding: 0, margin: 0 }}>
                                                    <MdDownload onClick={() => {
                                                        window.open("https://guruseg-data.s3.sa-east-1.amazonaws.com/" + item.file)
                                                    }} style={{ cursor: "pointer" }} size={35} color="#f2760a" />

                                                </div>

                                            </div>
                                        )
                                    }
                                })
                            }


                        </Docs>

                        {order.status == "finalizado" && (
                            <>
                                <h3>Documentos do Técnico</h3>
                                <Docs>
                                    {
                                        files.map((item) => {
                                            if (item.type == "tecnico") {
                                                return (
                                                    <div key={item.file}>
                                                        <p>{String(item.file).substr(33)}</p>
                                                        <div style={{ display: "flex", flexDirection: "row", minHeight: 20, padding: 0, margin: 0 }}>
                                                            <MdDownload onClick={() => {
                                                                window.open("https://guruseg-data.s3.sa-east-1.amazonaws.com/" + item.file)
                                                            }} style={{ cursor: "pointer" }} size={35} color="#f2760a" />

                                                        </div>

                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </Docs>
                            </>
                        )}
                        <Resume>
                            <p style={{ marginBottom: 15 }}>{totalServices} serviço{totalServices > 1 ? "s" : ""}</p>
                            {
                                order.urgent ? (
                                    <>
                                        <p style={{ fontSize: 16, marginTop: 0, marginBottom: 5 }}>Subtotal: {totalValue.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} </p>
                                        <p style={{ fontSize: 16, marginTop: 0, marginBottom: 10 }}>10% da urgência: {(totalValue * 0.1).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                                        <p style={{ fontWeight: "bold" }}>Total: {(totalValue * 1.1).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} </p>
                                    </>
                                ) : (
                                    <p style={{ fontWeight: "bold" }}>Total: {totalValue.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} </p>
                                )
                            }
                        </Resume>



                    </>
                )
            }
        </Container >
    )
} 