import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '../components/sidebar';
import Main from '../components/main';
import Header from '../components/header';
import { useAuth } from '../hooks/AuthContext';

const HybridRoute = ({ sidebar, header }) => {

  const user = useAuth();
  const [isMenuMinimized, setIsMenuMinimized] = useState(true)


  let type = "";
  if (user.user) {
    type = user.user.type
  }

  return type ?
    <>
      {
        sidebar != false && (
          <Sidebar type={type} isMenuMinimized={isMenuMinimized} onChange={(e)=>{setIsMenuMinimized(e == false || !isMenuMinimized)}}/>
        )
      }
        {header != false ? (
          <Main sidebar={sidebar} >
            <Header sidebar={sidebar} type={type}  onChange={() => {
              setIsMenuMinimized(!isMenuMinimized)
            }} />
            <Outlet />
          </Main>
        ) : <Outlet />}
    </>
    : <Outlet />
};

export default HybridRoute;