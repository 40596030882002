import React, { useEffect, useState } from 'react';
import { Container, } from './styles';
import { useNavigate, useParams } from "react-router-dom";
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { format, addDays } from 'date-fns';

export default function ServicesOS() {

    const { id } = useParams()
    const navigate = useNavigate();
    const [serviceSelect, setServiceSelect] = useState({
        name: "",
        value: 0
    })
    const [serviceOS, setServiceOS] = useState(0)
    const [services, setServices] = useState([])
    const [service, setService] = useState("")
    const [startDate, setStartDate] = useState(addDays(new Date(),-7))
    const [endDate, setEndDate] = useState(new Date())

    useEffect(() => {
        loadingServices()
    }, [])

    useEffect(() => {
        loadingServiceOS()
    }, [startDate, endDate, serviceSelect])

    async function loadingServices() {
        await api.get(`/services`).then((response) => {
            setServices(response.data)
            let name = response.data.filter((item)=>{
                return item.name == "Cadastro de Colaboradores E-social"
            })
            if (name.length) {
                setService(name[0].id)
                setServiceSelect(name[0])
            } else {
                setService(response.data[0].id)
                setServiceSelect(response.data[0])
            }
        }).catch(({ response }) => {
            if (response) {
                if (response.data) {
                    if (response.data.message) {
                        toast.warn(response.data.message)
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            } else {
                toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
        })
    }


    async function loadingServiceOS() {
        await api.post(`/service-os/${id}`, {
            service: serviceSelect.name,
            start_date: startDate,
            end_date: endDate
        }).then((response) => {
            setServiceOS(response.data)
        }).catch(( response ) => {
            if (response) {
                if (response.data) {
                    if (response.data.message) {
                        toast.warn(response.data.message)
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            } else {
                toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
        })
    }

    return (
        <Container>
            <h1>Total de Solicitação por Serviço</h1>
                <p style={{ margin: "20px 0 10px 0" }}>
                    Selecione o serviço
                </p>
                            <select
                                value={service}
                                style={{
                                    width: "90%",
                                    maxWidth: 350,
                                    backgroundColor: "#FFF",
                                    borderWidth: 1,
                                    borderColor: "#ccc",
                                    borderStyle: "solid",
                                    color: "#444",
                                    padding: "10px",
                                    fontSize: 14,
                                    borderRadius: 5,
                                    fontWeight: "400",
                                    paddingLeft: 8,
                                }}
                                onChange={(text) => {
                                    setService(text.target.value)
                                    setServiceSelect(services.filter((item) => item.id == text.target.value)[0])
                                }}
                            >
                                {services.map((item) => {
                                    return (
                                        <option value={item.id} label={item.name}>{item.name}</option>
                                    )}
                                )}
                            </select> 
                <p style={{ margin: "20px 0 10px 0" }}>
                    Altere  o periodo
                </p>
            <div style={{ width: 300, display: "flex", justifyContent: "space-between" }}>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                    de 
                    <input type="date" value={format(startDate, "yyyy-MM-dd")} onChange={(date) => {
                    let dateE = date.target.value.split("-")
                    setStartDate(new Date(dateE[0], dateE[1]-1, dateE[2]))
                }} /> 
                </div>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                    até
                <input type="date" min={format(startDate, "yyyy-MM-dd")} max={format(new Date(), "yyyy-MM-dd")} value={format(endDate, "yyyy-MM-dd")} onChange={(date) => {
                    let dateE = date.target.value.split("-")
                    setEndDate(new Date(dateE[0], dateE[1]-1, dateE[2]))
                }} />
                </div>
            </div>
            <div style={{ width: "100%", display: "flex", justifyContent: "center", margin: "15px 0" }}>
                <button onClick={() => {
                    setEndDate(new Date())
                    setStartDate(addDays(new Date(), -7))
                }}style={{background: "#f2760a", color: "#fff", padding: "5px 12px", margin: "0 8px", borderRadius: 4 }}>
                    Últimos 7 dias
                </button>
                <button onClick={()=>{
                    setEndDate(new Date())
                    setStartDate(addDays(new Date(), -15))
                }}style={{background: "#f2760a", color: "#fff", padding: "5px 12px", margin: "0 8px", borderRadius: 4 }}>
                    Últimos 15 dias
                </button>
                <button onClick={()=>{
                    setEndDate(new Date())
                    setStartDate(addDays(new Date(), -30))
                }}style={{background: "#f2760a", color: "#fff", padding: "5px 12px", margin: "0 8px", borderRadius: 4 }}>
                    Últimos 30 dias
                </button>
            </div>
            <p style={{margin: "15px 0 25px 0", width: "90%", textAlign: "center", maxWidth: 350}}>
                O serviço <strong>{serviceSelect.name}</strong> de <strong>{serviceSelect.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong> teve <strong>{serviceOS}x</strong> solicitações 
                {" "}concluidas no periodo selecionado, custo total de <strong>{(serviceSelect.value*serviceOS).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong>
            </p>
        </Container >
    )
} 