export const companiesEx = [
 {
  "Column1": 66,
  "Tipo": "PJ",
  "Razão Social/Nome": "CEREAIS VALE DO JAVAES AGROINDUSTRIAL S/A",
  "Nome Fantasia/Apelido": "CEREAIS VALE DO JAVAES AGROINDUSTRIAL S/A",
  "CNPJ": " 00.355.888/0001-85",
  "CNAE Principal": " 0111-3/01",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "05/02/2022",
  "Estado": "TO",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 2,
  "Tipo": "PJ",
  "Razão Social/Nome": "MARIA DO ESPIRITO SANTO MOTA XAVIER",
  "Nome Fantasia/Apelido": "TETO - TELHAS TOCANTINS",
  "CNPJ": " 02.839.777/0001-70",
  "CNAE Principal": "2342-7/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "28/10/2021",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 8,
  "Tipo": "PJ",
  "Razão Social/Nome": "L. DE S. FERREIRA",
  "Nome Fantasia/Apelido": "FOCUS - GESTAO & TREINAMENTOS EM SEGURANCA DO TRABALHO",
  "CNPJ": "29.558.727/0001-70",
  "CNAE Principal": "8299-7/99",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "01/02/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 9,
  "Tipo": "PJ",
  "Razão Social/Nome": "CONSTRUVICCO LTDA",
  "Nome Fantasia/Apelido": "CONTRUVICCO LTDA",
  "CNPJ": "22.382.712/0001-55",
  "CNAE Principal": "4399-1/03",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "SP",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 11,
  "Tipo": "PJ",
  "Razão Social/Nome": "CLAUDIO ROBERTO RODRIGUES 11936707802",
  "Nome Fantasia/Apelido": "ROBERTO RODRIGUES",
  "CNPJ": "35.957.757/0001-52",
  "CNAE Principal": "3313-9/01",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "13/01/2022",
  "Estado": "SP",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 12,
  "Tipo": "PJ",
  "Razão Social/Nome": "AGROPECUARIA SEMENTES TALISMA LTDA - BAHIA",
  "Nome Fantasia/Apelido": "SEMENTES TALISMA",
  "CNPJ": "37.637.139/0004-01",
  "CNAE Principal": " 0141-5/01",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "BA",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 14,
  "Tipo": "PF",
  "Razão Social/Nome": " MARCELO JOSE DE SA SCATAMBULO ",
  "Nome Fantasia/Apelido": "FAZENDA MORRO DOIS IRMAOS ",
  "CPF": " 023.409.891-08",
  "CNAE Principal": 161003,
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "24/01/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 17,
  "Tipo": "PJ",
  "Razão Social/Nome": "J S PADARIA E CONFEITARIA LTDA",
  "Nome Fantasia/Apelido": "JS SALGADOS",
  "CNPJ": " 07.391.260/0001-84",
  "CNAE Principal": "4712-1/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "28/01/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 18,
  "Tipo": "PJ",
  "Razão Social/Nome": "ACOUGUE MODELO LTDA",
  "Nome Fantasia/Apelido": "ACOUGUE MODELO",
  "CNPJ": "27.435.354/0001-15",
  "CNAE Principal": "4722-9/01",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "28/01/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 20,
  "Tipo": "PJ",
  "Razão Social/Nome": "VIVAR ODONTOLOGIA ESPECIALIZADA",
  "Nome Fantasia/Apelido": "VIVAR ODONTOLOGIA ESPECIALIZADA",
  "CNPJ": "31.230.575/0001-24",
  "CNAE Principal": "8630-5/04",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "28/01/2022",
  "Estado": "MT",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 21,
  "Tipo": "PJ",
  "Razão Social/Nome": "SMART MOVE LOCACAO E TRANSPORTE LTDA",
  "Nome Fantasia/Apelido": "SMART MOVE LOCACAO E TRANSPORTE",
  "CNPJ": "24.778.131/0001-62",
  "CNAE Principal": "4929-9/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "30/01/2022",
  "Estado": "GO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 22,
  "Tipo": "PJ",
  "Razão Social/Nome": "CENTRO DE ENDOSCOPIA DE OEIRAS S/C",
  "Nome Fantasia/Apelido": "CENTRO DE ENDOSCOPIA DE OEIRA",
  "CNPJ": " 00.132.975/0001-73",
  "CNAE Principal": "8630-5/02",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "PI",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 23,
  "Tipo": "PJ",
  "Razão Social/Nome": "AUTO POSTO CASTELO COMERCIO DE COMBUSTIVEIS E DERIVADOS DE PETROLEO LTDA",
  "Nome Fantasia/Apelido": "AUTO POSTO CASTELO",
  "CNPJ": "29.267.139/0001-88",
  "CNAE Principal": "4731-8/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "11/02/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 28,
  "Tipo": "PJ",
  "Razão Social/Nome": "CLECIO DE GODOY MOREIRA",
  "Nome Fantasia/Apelido": "CASA DE CARNE GODOY",
  "CNPJ": "19.538.649/0001-99",
  "CNAE Principal": "4722-9/01",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "01/02/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 29,
  "Tipo": "PJ",
  "Razão Social/Nome": "RESTAURANTE E LANCHONETE IPANEMA EIRELI",
  "Nome Fantasia/Apelido": "RESTAURANTE E LANCHONETE IPANEMA",
  "CNPJ": "25.045.015/0001-05",
  "CNAE Principal": "5611-2/01",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "01/02/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 30,
  "Tipo": "PJ",
  "Razão Social/Nome": "JOSE DA CRUZ DA SILVA LOPES - ENTAGRI ADMINISTRAÇÃO E SERVIÇOS",
  "Nome Fantasia/Apelido": "ENTAGRI",
  "CNPJ": "10.209.098/0001-45",
  "CNAE Principal": "5212-5-00",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 31,
  "Tipo": "PJ",
  "Razão Social/Nome": "IPANEMA DERIVADOS DE PETROLEO LTDA",
  "Nome Fantasia/Apelido": "POSTO IPANEMA",
  "CNPJ": " 00.798.642/0001-88",
  "CNAE Principal": "4731-8/00",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 35,
  "Tipo": "PJ",
  "Razão Social/Nome": "ELI MARTINS COUTO ME",
  "Nome Fantasia/Apelido": "ELI CENTRO AUTOMOTIVO",
  "CNPJ": " 04.367.631/0001-02",
  "CNAE Principal": "4520-0/01",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "10/01/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 36,
  "Tipo": "PJ",
  "Razão Social/Nome": "NOVUS CAPITAL GESTORA DE RECURSOS LTDA",
  "Nome Fantasia/Apelido": "NOVUS CAPITAL GESTORA DE RECURSOS LTDA",
  "CNPJ": "15.388.425/0001-97",
  "CNAE Principal": "6630-4/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "24/02/2023",
  "Estado": "RJ",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 39,
  "Tipo": "PJ",
  "Razão Social/Nome": "PRECY ART MOVEIS INDUSTRIA E COMERCIO LTDA",
  "Nome Fantasia/Apelido": "PRECY ART MOVEIS",
  "CNPJ": " 09.192.297/0001-09",
  "CNAE Principal": "3101-2/00",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "PR",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 55,
  "Tipo": "PJ",
  "Razão Social/Nome": "CATALINE TREINAMENTOS PESSOAL E PROFISSIONAL EIRELI",
  "Nome Fantasia/Apelido": "CATALINE",
  "CNPJ": "36.900.229/0001-20",
  "CNAE Principal": "8599-6/04",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "08/02/2022",
  "Estado": "PE",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 72,
  "Tipo": "PJ",
  "Razão Social/Nome": "SEMENTES VALE DO JAVAES LTDA",
  "Nome Fantasia/Apelido": "SEMENTES VALE DO JAVAES LTDA",
  "CNPJ": "25.089.194/0001-74",
  "CNAE Principal": " 0111-3/01",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "07/02/2022",
  "Estado": "TO",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 74,
  "Tipo": "PJ",
  "Razão Social/Nome": "VALE DO JAVAES DIESEL E LUBRIFICANTES LTDA",
  "Nome Fantasia/Apelido": "TRR",
  "CNPJ": " 02.891.158/0001-24",
  "CNAE Principal": "4681-8/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "07/02/2022",
  "Estado": "TO",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 81,
  "Tipo": "PF",
  "Razão Social/Nome": "CLOVES OLIVEIRA VALADAO",
  "Nome Fantasia/Apelido": "CLOVES OLIVEIRA VALADAO",
  "CPF": "194.236.671-04",
  "CNAE Principal": "111301 ",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "07/02/2022",
  "Estado": "TO",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 93,
  "Tipo": "PJ",
  "Razão Social/Nome": "L. CHRISTINE MARELLI LTDA",
  "Nome Fantasia/Apelido": "VET'S & PET'S",
  "CNPJ": "22.319.367/0001-05",
  "CNAE Principal": "4789-0/04",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "PR",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 95,
  "Tipo": "PJ",
  "Razão Social/Nome": "SJK COMERCIO VAREJISTA DE CALCADOS E ACESSORIOS LTDA",
  "Nome Fantasia/Apelido": "SJK CALCADOS",
  "CNPJ": "41.600.327/0001-81",
  "CNAE Principal": "47.82-2-01 ",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 97,
  "Tipo": "PJ",
  "Razão Social/Nome": "MAVILI - MERCADO, LANCHONETE E BAR LTDA",
  "Nome Fantasia/Apelido": "MAVILI - MERCADO, LANCHONETE E BAR LTDA",
  "CNPJ": "39.515.200/0001-12",
  "CNAE Principal": "4712-1/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "07/02/2022",
  "Estado": "RJ",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 98,
  "Tipo": "PF",
  "Razão Social/Nome": "DAIANA TERESINHA ANTUNES",
  "Nome Fantasia/Apelido": "DAIANA TERESINHA ANTUNES",
  "CPF": "971.640.111-68",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "07/02/2022",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 99,
  "Tipo": "PJ",
  "Razão Social/Nome": "COOPERATIVA MISTA RURAL LAGOA GRANDE LTDA",
  "Nome Fantasia/Apelido": "COOPERGRAN",
  "CNPJ": " 00.090.274/0001-19",
  "CNAE Principal": "9411-1/00",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 100,
  "Tipo": "PJ",
  "Razão Social/Nome": "FORMOSO - POSTO BEIRA RIO COM. DE DERIVADOS DE PETROLEO LTDA",
  "Nome Fantasia/Apelido": "AUTO POSTO JABURU ",
  "CNPJ": " 02.819.759/0002-07",
  "CNAE Principal": "4731-8/00",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 101,
  "Tipo": "PJ",
  "Razão Social/Nome": "NORTH CORRETORA DE SEGUROS E NEGOCIOS EIRELI",
  "Nome Fantasia/Apelido": "NORTH CORRETORA DE SEGUROS E NEGOCIOS",
  "CNPJ": "27.597.282/0001-02",
  "CNAE Principal": "6622-3/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "08/02/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 102,
  "Tipo": "PJ",
  "Razão Social/Nome": "NORTH CORRETORA DE SEGUROS E NEGOCIOS LTDA",
  "Nome Fantasia/Apelido": "NORTH CORRETORA DE SEGUROS E NEGOCIOS",
  "CNPJ": "27.597.282/0002-93",
  "CNAE Principal": "66.22-3-00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "08/02/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 105,
  "Tipo": "PJ",
  "Razão Social/Nome": "POSTO CENTRAL LTDA",
  "Nome Fantasia/Apelido": "POSTO CENTRAL",
  "CNPJ": "13.207.867/0001-64",
  "CNAE Principal": "4731-8/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "08/02/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 106,
  "Tipo": "PJ",
  "Razão Social/Nome": "VASTELECOM E INFORMATICA LTDA",
  "Nome Fantasia/Apelido": "VAS INFORMATICA",
  "CNPJ": "10.171.347/0001-50",
  "CNAE Principal": "6190-6/01",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "09/02/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 107,
  "Tipo": "PJ",
  "Razão Social/Nome": "ZOIT CONSULTORIA E SERVICOS DE TECNOLOGIA DA INFORMACAO EIRELI",
  "Nome Fantasia/Apelido": "ZOIT CONSULTORIA E SERVICOS DE TECNOLOGIA DA INFORMACAO EIRELI",
  "CNPJ": " 05.925.793/0001-73",
  "CNAE Principal": "6209-1/00",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "RJ",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 108,
  "Tipo": "PJ",
  "Razão Social/Nome": "ONEL ENGENHARIA LTDA",
  "Nome Fantasia/Apelido": "ONEL ENGENHARIA",
  "CNPJ": "39.959.976/0001-21",
  "CNAE Principal": "71.12-0-00",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 109,
  "Tipo": "PJ",
  "Razão Social/Nome": "SPARTAN CONSTRUCOES EIRELI",
  "Nome Fantasia/Apelido": "SPARTAN CONSTRUCOES",
  "CNPJ": "26.087.469/0001-01",
  "CNAE Principal": "4399-1/01",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 110,
  "Tipo": "PJ",
  "Razão Social/Nome": "J M S - TELECOM LTDA.",
  "Nome Fantasia/Apelido": "J M S",
  "CNPJ": "28.841.832/0001-50",
  "CNAE Principal": "6110-8/03",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "10/02/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 112,
  "Tipo": "PJ",
  "Razão Social/Nome": "LUCAS DREHER SAGARDIA",
  "Nome Fantasia/Apelido": "INOVA CLIMATIZA",
  "CNPJ": "32.278.654/0001-78",
  "CNAE Principal": "4322-3/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "11/02/2022",
  "Estado": "MT",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 114,
  "Tipo": "PJ",
  "Razão Social/Nome": "JANE LOPES LADEIRA",
  "Nome Fantasia/Apelido": "O CASARAO EVENTOS",
  "CNPJ": " 00.807.360/0001-08",
  "CNAE Principal": "8230-0/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "11/02/2022",
  "Estado": "PA",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 115,
  "Tipo": "PJ",
  "Razão Social/Nome": "MURBACH & MUCHELIN LTDA",
  "Nome Fantasia/Apelido": "AM TURBONET",
  "CNPJ": " 08.954.264/0001-96",
  "CNAE Principal": "6110-8/03",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "11/02/2022",
  "Estado": "PR",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 116,
  "Tipo": "PJ",
  "Razão Social/Nome": "NIVALDO SANTOS & CIA LTDA",
  "Nome Fantasia/Apelido": "NIVALMAQ",
  "CNPJ": " 08.012.356/0001-57",
  "CNAE Principal": "7711-0/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "12/02/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 117,
  "Tipo": "PJ",
  "Razão Social/Nome": "ALFA CONTABILIDADE E ASSESSORIA LTDA",
  "Nome Fantasia/Apelido": "ALFA CONTABILIDADE",
  "CNPJ": "11.220.572/0001-00",
  "CNAE Principal": "6920-6/01",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "13/02/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 118,
  "Tipo": "PJ",
  "Razão Social/Nome": "CASA DE CARNE BOI GORDO - EIRELI",
  "Nome Fantasia/Apelido": "CASA DE CARNE BOI GORDO",
  "CNPJ": "19.184.539/0001-76",
  "CNAE Principal": "4722-9/01",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "13/02/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 119,
  "Tipo": "PJ",
  "Razão Social/Nome": "BARROSO & BORGES ADVOGADAS ASSOCIADAS S/S",
  "CNPJ": "21.760.621/0001-43",
  "CNAE Principal": "6911-7/01",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "13/02/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 120,
  "Tipo": "PJ",
  "Razão Social/Nome": "ASSOCIACAO PAIS AMIGOS DOS EXEPCIONAIS DE ALVORADA-APAE",
  "Nome Fantasia/Apelido": "APAE",
  "CNPJ": " 02.201.735/0001-09",
  "CNAE Principal": "9430-8/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "13/02/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 121,
  "Tipo": "PJ",
  "Razão Social/Nome": "E. A. PEREIRA DE PAULA - ME",
  "Nome Fantasia/Apelido": "VERSATIL MODAS",
  "CNPJ": "12.356.056/0001-62",
  "CNAE Principal": "4781-4/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "14/02/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 122,
  "Tipo": "PJ",
  "Razão Social/Nome": "F. F. LEITE",
  "Nome Fantasia/Apelido": "ALTERNATIVA MAQUINAS",
  "CNPJ": " 08.241.091/0001-69",
  "CNAE Principal": "4789-0/99",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "14/02/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 123,
  "Tipo": "PJ",
  "Razão Social/Nome": "ALFREDO FRANCISCO ROSA TRANSPORTES LTDA",
  "CNPJ": " 03.682.980/0001-48",
  "CNAE Principal": "4921-3/02",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 124,
  "Tipo": "PJ",
  "Razão Social/Nome": "CARDOSO & FERREIRA LTDA",
  "Nome Fantasia/Apelido": "TORRA TORRA CONFECCOES",
  "CNPJ": "19.143.369/0001-81",
  "CNAE Principal": "4781-4/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "14/02/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 125,
  "Tipo": "PJ",
  "Razão Social/Nome": "K.A SERVICOS DE AGRONOMIA E DE CONSULTORIA AGRICOLAS E PECUARIAS LTDA",
  "Nome Fantasia/Apelido": "TECNOALVO PROJETO AGROPECUARIOS",
  "CNPJ": "12.822.886/0001-38",
  "CNAE Principal": "7490-1/03",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "14/02/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 126,
  "Tipo": "PJ",
  "Razão Social/Nome": "SUPERMERCADO SAMILLA LTDA",
  "Nome Fantasia/Apelido": "SUPERMERCADO SAMILLA",
  "CNPJ": "10.484.811/0001-69",
  "CNAE Principal": "4711-3/02",
  "Região": 6,
  "Situação": "Ativa",
  "Data Cadastro": "14/02/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 128,
  "Tipo": "PJ",
  "Razão Social/Nome": "JJP LABORATORIO DE ANALISES CLINICAS LTDA",
  "Nome Fantasia/Apelido": "LABOCLIN",
  "CNPJ": "40.521.921/0001-14",
  "CNAE Principal": "86.40-2-02",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "MG",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 129,
  "Tipo": "PJ",
  "Razão Social/Nome": "LUIZ APARECIDO AZEVEDO DE LIMA",
  "Nome Fantasia/Apelido": "LUIZ APARECIDO AZEVEDO DE LIMA",
  "CNPJ": "11.917.898/0001-83",
  "CNAE Principal": "3321-0/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "14/02/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 130,
  "Tipo": "PF",
  "Razão Social/Nome": "CAROLINA DO VAL VALADAO MESSIAS",
  "Nome Fantasia/Apelido": "CAROLINA DO VAL VALADAO MESSIAS",
  "CPF": "699.018.211-34",
  "CNAE Principal": 111301,
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "14/02/2022",
  "Estado": "TO",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 131,
  "Tipo": "PJ",
  "Razão Social/Nome": "ANTONIO HENRIQUE FRANCICA LOTACAO LTDA",
  "CNPJ": " 04.697.820/0001-35",
  "CNAE Principal": "4921-3/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "15/02/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 132,
  "Tipo": "PJ",
  "Razão Social/Nome": "PANIFICADORA PAO NOSSO LTDA",
  "Nome Fantasia/Apelido": "PANIFICADORA PAO NOSSO",
  "CNPJ": "10.465.560/0001-75",
  "CNAE Principal": "1091-1/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "14/02/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 134,
  "Tipo": "PJ",
  "Razão Social/Nome": "ASSOCIACAO DAS EMPRESAS DOS OPERADORES REGIONAIS COLETIVOS AUTONOMOS DO ESTADO DE SAO PAULO",
  "Nome Fantasia/Apelido": "ASSEOESP",
  "CNPJ": "12.281.711/0001-60",
  "CNAE Principal": "9411-1/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "14/02/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 135,
  "Tipo": "PJ",
  "Razão Social/Nome": "M P DA SILVA STUDIO E REPRESENTACOES",
  "Nome Fantasia/Apelido": "STUDIO MONARA PONCE E REPRESENTACOES",
  "CNPJ": "43.673.843/0001-61",
  "CNAE Principal": "46.18-4-99",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 136,
  "Tipo": "PJ",
  "Razão Social/Nome": "BRAZ MAGALHAES TRANSPORTES LTDA",
  "CNPJ": " 04.156.317/0001-72",
  "CNAE Principal": "4921-3/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "14/02/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 137,
  "Tipo": "PJ",
  "Razão Social/Nome": "VR TECHNIQUE MONTAGEM INDUSTRIAL LTDA",
  "Nome Fantasia/Apelido": "VR TECHNIQUE MONTAGEM INDUSTRIAL",
  "CNPJ": "28.437.915/0001-88",
  "CNAE Principal": "3.21-0-00",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "SC",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 138,
  "Tipo": "PJ",
  "Razão Social/Nome": "BRIGADA 19 EQUIPAMENTOS E ENGENHARIA DE PREVENÇÃO EIRELI",
  "Nome Fantasia/Apelido": "BRIGADA 19",
  "CNPJ": " 07.901.136/0001-11",
  "CNAE Principal": "43.22-3-03",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "PR",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 139,
  "Tipo": "PJ",
  "Razão Social/Nome": "DANIEL ALVES DE SOUZA TRANSPORTES LTDA",
  "CNPJ": " 03.662.066/0001-35",
  "CNAE Principal": "4921-3/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "14/02/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 140,
  "Tipo": "PJ",
  "Razão Social/Nome": "EDSON CARDOSO DA SILVA TRANSPORTES LTDA",
  "CNPJ": " 04.310.335/0001-67",
  "CNAE Principal": "4921-3/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "14/02/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 141,
  "Tipo": "PJ",
  "Razão Social/Nome": "ELCIO ANTONIO ALMEIDA TRANSPORTES LTDA",
  "CNPJ": "62.431.051/0001-25",
  "CNAE Principal": "4921-3/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "14/02/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 142,
  "Tipo": "PJ",
  "Razão Social/Nome": "FRANCISCO FERREIRA MACHADO TRANSPORTES LTDA",
  "CNPJ": " 03.696.075/0001-47",
  "CNAE Principal": "4921-3/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "14/02/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 144,
  "Tipo": "PJ",
  "Razão Social/Nome": "POLIAUTO LAVAGEM DE VEICULOS LTDA",
  "Nome Fantasia/Apelido": "POLIAUTO",
  "CNPJ": " 08.357.094/0001-62",
  "CNAE Principal": "4520-0/05",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 145,
  "Tipo": "PJ",
  "Razão Social/Nome": "NEEMIAS PEREIRA 23005833801",
  "Nome Fantasia/Apelido": "NEEMIAS",
  "CNPJ": "38.118.781/0001-96",
  "CNAE Principal": "3321-0/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "15/02/2022",
  "Estado": "SP",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 146,
  "Tipo": "PJ",
  "Razão Social/Nome": "FRANCISCO PEREIRA DE ARAUJO TRANSPORTES LTDA",
  "CNPJ": " 03.665.622/0001-27",
  "CNAE Principal": "4921-3/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "15/02/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 147,
  "Tipo": "PJ",
  "Razão Social/Nome": "MAURO SOARES DA SILVA",
  "Nome Fantasia/Apelido": "M S S TRANSPORTES",
  "CNPJ": " 00.287.430/0001-36",
  "CNAE Principal": "45.20-0-05",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "15/02/2022",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 148,
  "Tipo": "PJ",
  "Razão Social/Nome": "GERVASIO SANTOS CARVALHO TRANSPORTES LTDA",
  "Nome Fantasia/Apelido": "DECAPOLIS TUR",
  "CNPJ": " 03.701.191/0001-07",
  "CNAE Principal": "4921-3/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "15/02/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 149,
  "Tipo": "PJ",
  "Razão Social/Nome": "HOTEL SERRA DO MAR LTDA",
  "CNPJ": "47.707.872/0001-58",
  "CNAE Principal": "5510-8/01",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "15/02/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 150,
  "Tipo": "PJ",
  "Razão Social/Nome": "JAIR GENESIO COMIN 81328966887",
  "Nome Fantasia/Apelido": "JGC",
  "CNPJ": "17.430.479/0001-07",
  "CNAE Principal": "4924-8/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "15/02/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 151,
  "Tipo": "PJ",
  "Razão Social/Nome": "ARAUJO COMERCIO DE CARRETAS LTDA",
  "Nome Fantasia/Apelido": "ARAUJO CARRETAS E ENGATES",
  "CNPJ": "26.080.209/0001-04",
  "CNAE Principal": "2930-1/03",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "15/02/2022",
  "Estado": "PA",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 152,
  "Tipo": "PJ",
  "Razão Social/Nome": "JORGE DA CONCEICAO NASCIMENTO TRANSPORTES LTDA",
  "Nome Fantasia/Apelido": "ADANZIL TRASNPORTES",
  "CNPJ": " 03.827.139/0001-00",
  "CNAE Principal": "4921-3/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "15/02/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 153,
  "Tipo": "PJ",
  "Razão Social/Nome": "JOSE BARBOSA DOS SANTOS TRANSPORTES LTDA",
  "CNPJ": " 04.671.805/0001-18",
  "CNAE Principal": "4921-3/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "15/02/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 154,
  "Tipo": "PJ",
  "Razão Social/Nome": "ALMIRANTE PEDRO PELLENZ SOBRINHO",
  "Nome Fantasia/Apelido": "AUTO SOCORRO PESADAO",
  "CNPJ": " 08.081.530/0001-13",
  "CNAE Principal": "5229-0/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "15/02/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 155,
  "Tipo": "PJ",
  "Razão Social/Nome": "JOSE DIVAL EVANGELISTA DE OLIVEIRA TRANSPORTES LTDA",
  "CNPJ": " 03.620.544/0001-44",
  "CNAE Principal": "4921-3/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "15/02/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 156,
  "Tipo": "PJ",
  "Razão Social/Nome": "CELEIRO AGRONEGOCIOS LTDA",
  "Nome Fantasia/Apelido": "CELEIRO",
  "CNPJ": "34.622.021/0001-60",
  "CNAE Principal": "4789-0/99",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 158,
  "Tipo": "PJ",
  "Razão Social/Nome": "JOSE NATAL DA CRUZ TRANSPORTES LTDA",
  "CNPJ": " 03.801.192/0001-23",
  "CNAE Principal": "4921-3/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "15/02/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 159,
  "Tipo": "PJ",
  "Razão Social/Nome": "LEOMIR GRANDO TRANSPORTES LTDA",
  "CNPJ": " 04.738.501/0001-20",
  "CNAE Principal": "4921-3/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "15/02/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 160,
  "Tipo": "PJ",
  "Razão Social/Nome": "LUIZ BATISTA DA CUNHA FILHO TRANSPORTES LTDA",
  "CNPJ": " 03.662.833/0001-06",
  "CNAE Principal": "4921-3/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "15/02/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 162,
  "Tipo": "PJ",
  "Razão Social/Nome": "NERALDO DA CONCEICAO TRANSPORTES LTDA",
  "CNPJ": " 03.624.345/0001-04",
  "CNAE Principal": "4921-3/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "15/02/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 163,
  "Tipo": "PJ",
  "Razão Social/Nome": "NILTON DE MORAES FISCHER TRANSPORTES LTDA",
  "CNPJ": " 03.674.866/0001-76",
  "CNAE Principal": "4921-3/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "15/02/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 164,
  "Tipo": "PJ",
  "Razão Social/Nome": "PRUDENCIO PAULINO GALVAO FILHO TRANSPORTES LTDA",
  "CNPJ": " 05.878.651/0001-00",
  "CNAE Principal": "4921-3/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "15/02/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 165,
  "Tipo": "PJ",
  "Razão Social/Nome": "ROBERIO BENTO LIMA LTDA",
  "CNPJ": " 03.629.655/0001-11",
  "CNAE Principal": "4921-3/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "15/02/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 166,
  "Tipo": "PJ",
  "Razão Social/Nome": "SALATIEL GONCALVES TRANSPORTES",
  "CNPJ": " 04.804.294/0001-65",
  "CNAE Principal": "4921-3/02",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 168,
  "Tipo": "PJ",
  "Razão Social/Nome": "SEVERINO GUEDES NETO TRANSPORTES LTDA",
  "CNPJ": " 03.690.330/0001-44",
  "CNAE Principal": "4921-3/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "16/02/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 170,
  "Tipo": "PF",
  "Razão Social/Nome": "CARLOS ROBERTO DANTONIO DE ASSIS ",
  "Nome Fantasia/Apelido": "Faz Pedrinha",
  "CPF": "167.155.938-09",
  "CNAE Principal": 151201,
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "16/02/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 171,
  "Tipo": "PJ",
  "Razão Social/Nome": "VALDECIR JOSE GONCALVES TRANSPORTES LTDA",
  "CNPJ": " 03.742.139/0001-07",
  "CNAE Principal": "4921-3/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "16/02/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 172,
  "Tipo": "PF",
  "Razão Social/Nome": "CELIO ROBERTO FRANCO  FAZ.SANTA MARIA",
  "Nome Fantasia/Apelido": "CELIO ROBERTO FRANCO  FAZ.SANTA MARIA",
  "CPF": "621.278.298-91",
  "CNAE Principal": 151201,
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "16/02/2022",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 173,
  "Tipo": "PJ",
  "Razão Social/Nome": "VALTER ANTONIO DE OLIVEIRA TRANSPORTES LTDA",
  "Nome Fantasia/Apelido": "VALTER",
  "CNPJ": " 03.670.970/0001-92",
  "CNAE Principal": "4921-3/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "16/02/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 174,
  "Tipo": "PF",
  "Razão Social/Nome": "JAIR ALVES FERREIRA JUNIOR ",
  "Nome Fantasia/Apelido": "JAIR ALVES FERREIRA JUNIOR - Fazenda Conquista",
  "CPF": "264.910.951-34",
  "CNAE Principal": 151201,
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "16/02/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 175,
  "Tipo": "PF",
  "Razão Social/Nome": "JOSÉ MARIO MARIANO",
  "Nome Fantasia/Apelido": "JOSÉ MARIO MARIANO Fazenda São josé",
  "CPF": " 020.635.278-65",
  "CNAE Principal": 115600,
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "16/02/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 176,
  "Tipo": "PJ",
  "Razão Social/Nome": "VANDER CERCEAU GONCALVES TRANSPORTES LTDA",
  "Nome Fantasia/Apelido": "VAM VAM TRANSPORTES - ME",
  "CNPJ": " 03.521.214/0001-00",
  "CNAE Principal": "4921-3/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "16/02/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 177,
  "Tipo": "PF",
  "Razão Social/Nome": "MANOEL MARANHÃO DE SOUZA ",
  "Nome Fantasia/Apelido": "MANOEL MARANHÃO DE SOUZA ",
  "CPF": " 088.851.501-44",
  "CNAE Principal": 151201,
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "15/03/2023",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 178,
  "Tipo": "PJ",
  "Razão Social/Nome": "X - LINE DOCUMENTACAO E RADIOLOGIA ODONTOLOGICA LTDA",
  "Nome Fantasia/Apelido": "X - LINE DOCUMENTACAO E RADIOLOGIA ODONTOLOGICA",
  "CNPJ": " 08.401.107/0001-53",
  "CNAE Principal": "8640-2/05",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "16/02/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 179,
  "Tipo": "PJ",
  "Razão Social/Nome": "WESLEI RODRIGUES MARTINS TRANSPORTES LTDA",
  "Nome Fantasia/Apelido": "WR TRANSPORTES",
  "CNPJ": " 04.605.734/0001-55",
  "CNAE Principal": "4921-3/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "16/02/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 180,
  "Tipo": "PJ",
  "Razão Social/Nome": "DANIEL ANIBAL DA COSTA LTDA",
  "CNPJ": " 03.481.080/0001-32",
  "CNAE Principal": "4921-3/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "16/02/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 181,
  "Tipo": "PF",
  "Razão Social/Nome": "WALDEMAR SECCHI ",
  "Nome Fantasia/Apelido": "WALDEMAR SECCHI ",
  "CPF": "275.974.740-91",
  "CNAE Principal": 151201,
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "16/02/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 182,
  "Tipo": "PJ",
  "Razão Social/Nome": "EDMILSON INEZ DO NASCIMENTO TRANSPORTES LTDA",
  "CNPJ": " 05.926.025/0001-34",
  "CNAE Principal": "4921-3/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "16/02/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 183,
  "Tipo": "PF",
  "Razão Social/Nome": "SUELI ALVES MARAJÓ ",
  "Nome Fantasia/Apelido": "SUELI ALVES MARAJÓ ",
  "CPF": " 023.679.726-30",
  "CNAE Principal": 151201,
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "28/02/2023",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 184,
  "Tipo": "PJ",
  "Razão Social/Nome": "SEMATTI SOLUCOES EM OBRAS E REFORMAS LTDA",
  "CNPJ": "24.389.284/0001-18",
  "CNAE Principal": "4329-1/99",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "16/02/2022",
  "Estado": "SP",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 185,
  "Tipo": "PF",
  "Razão Social/Nome": "SERGIO MURILO LAHM",
  "Nome Fantasia/Apelido": "SERGIO MURILO LAHM",
  "CPF": " 048.690.971-92",
  "CNAE Principal": 151201,
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "16/02/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 186,
  "Tipo": "PF",
  "Razão Social/Nome": "ORLANDO FERRARI ",
  "Nome Fantasia/Apelido": "Fazenda Canarinho",
  "CPF": "486.389.518-68",
  "CNAE Principal": 151201,
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 187,
  "Tipo": "PF",
  "Razão Social/Nome": "ORLANDO FERRARI FAZ.BEIJA FLOR",
  "Nome Fantasia/Apelido": "ORLANDO FERRARI FAZ.BEIJA FLOR",
  "CPF": "486.389.518-68",
  "CNAE Principal": 151201,
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "16/02/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 188,
  "Tipo": "PJ",
  "Razão Social/Nome": "AURELIANO RODRIGUES NETO TRANSPORTES LTDA",
  "CNPJ": " 03.765.193/0001-60",
  "CNAE Principal": "4921-3/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "17/02/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 189,
  "Tipo": "PJ",
  "Razão Social/Nome": "GERSON FRANCISCO ROCHA TRANSPORTES LTDA",
  "Nome Fantasia/Apelido": "GF - TRANSPORTES",
  "CNPJ": " 03.487.776/0001-76",
  "CNAE Principal": "4921-3/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "17/02/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 190,
  "Tipo": "PF",
  "Razão Social/Nome": "AMARILDO JOSE BORGES",
  "CPF": "545.711.626-34",
  "CNAE Principal": "CULTIVO DE CAFE",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "17/02/2022",
  "Estado": "MG",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 191,
  "Tipo": "PJ",
  "Razão Social/Nome": "M.F MOTORES LTDA",
  "CNPJ": "18.040.584/0001-94",
  "CNAE Principal": "9521-5/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "17/02/2022",
  "Estado": "MG",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 192,
  "Tipo": "PJ",
  "Razão Social/Nome": "MICASA INDUSTRIA CERAMICA E COMERCIO LTDA.",
  "Nome Fantasia/Apelido": "MICASA",
  "CNPJ": "25.168.543/0001-43",
  "CNAE Principal": "4744-0/99",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "17/02/2022",
  "Estado": "MG",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 193,
  "Tipo": "PJ",
  "Razão Social/Nome": "LUIZ CARLOS FERREIRA TRANSPORTE LTDA",
  "CNPJ": " 04.800.230/0001-96",
  "CNAE Principal": "4921-3/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "17/02/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 194,
  "Tipo": "PJ",
  "Razão Social/Nome": "F S ZANOLLI COMERCIO DE MEDICAMENTOS EIRELI",
  "Nome Fantasia/Apelido": "DROGARIAS ULTRA POPULAR",
  "CNPJ": "34.609.437/0001-49",
  "CNAE Principal": "4771-7/01",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "17/02/2022",
  "Estado": "PA",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 195,
  "Tipo": "PJ",
  "Razão Social/Nome": "TTH CONSULTORIA E SERVICOS EM TECNOLOGIA LTDA",
  "Nome Fantasia/Apelido": "T2H GESTAO PROFISSIONAL DE TI",
  "CNPJ": "32.506.353/0001-54",
  "CNAE Principal": "9511-8/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "18/02/2022",
  "Estado": "BA",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 196,
  "Tipo": "PJ",
  "Razão Social/Nome": "FREITAS CAR COMERCIO E SERVICOS LTDA",
  "Nome Fantasia/Apelido": "FREITAS CAR",
  "CNPJ": "26.754.163/0001-53",
  "CNAE Principal": "4520-0/01",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "18/02/2022",
  "Estado": "PA",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 197,
  "Tipo": "PJ",
  "Razão Social/Nome": "W DA SILVA FORMENTINI EIRELI",
  "Nome Fantasia/Apelido": "W DA SILVA FORMENTINI EIRELI",
  "CNPJ": "41.802.808/0001-70",
  "CNAE Principal": "47.71-7-04",
  "Região": "0",
  "Situação": "Ativa",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 198,
  "Tipo": "PJ",
  "Razão Social/Nome": "ALVES E ALVES LTDA",
  "Nome Fantasia/Apelido": "CONSTRUFE MATERIAIS PARA CONSTRUCAO",
  "CNPJ": "27.028.409/0001-72",
  "CNAE Principal": "4744-0/99",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "18/02/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 199,
  "Tipo": "PJ",
  "Razão Social/Nome": "POSTO BEIRA RIO COM. DE DERIVADOS DE PETROLEO LTDA",
  "Nome Fantasia/Apelido": "POSTO BEIRA RIO",
  "CNPJ": " 02.819.759/0001-26",
  "CNAE Principal": "4731-8/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "18/02/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 200,
  "Tipo": "PJ",
  "Razão Social/Nome": "GURUPI TERRAPLANAGEM LTDA",
  "Nome Fantasia/Apelido": "GURUPI TERRAPLANAGEM ",
  "CNPJ": "14.247.643/0001-49",
  "CNAE Principal": "7732-2/01",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "18/02/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 201,
  "Tipo": "PJ",
  "Razão Social/Nome": "MATTOS E MONTES LTDA",
  "CNPJ": "19.662.005/0001-08",
  "CNAE Principal": " 0220-9/06",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "DF",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 202,
  "Tipo": "PJ",
  "Razão Social/Nome": "O C COSTA - EIRELI",
  "CNPJ": "19.030.488/0001-28",
  "CNAE Principal": "7711-0/00",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 203,
  "Tipo": "PJ",
  "Razão Social/Nome": "COPEL EMPREITEIRA LTDA",
  "Nome Fantasia/Apelido": "COPEL EMPREENDIMENTOS",
  "CNPJ": "34.859.721/0001-73",
  "CNAE Principal": "42.13-8-00 ",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "21/02/2022",
  "Estado": "BA",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 205,
  "Tipo": "PJ",
  "Razão Social/Nome": "SINDICATO RURAL DE ARAGUACU",
  "CNPJ": " 00.260.695/0001-40",
  "CNAE Principal": "9420-1/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "21/02/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 206,
  "Tipo": "PJ",
  "Razão Social/Nome": "JS ENGENHARIA E SOLUCOES ENERGETICAS LTDA",
  "Nome Fantasia/Apelido": "RESIL ENGENHARIA E SOLUCOES ENERGETICAS",
  "CNPJ": "39.561.969/0001-77",
  "CNAE Principal": "71.12-0-00",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 207,
  "Tipo": "PJ",
  "Razão Social/Nome": "D RIBEIRO DE SOUZA LTDA",
  "Nome Fantasia/Apelido": " TÍTULO DO ESTABELECIMENTO (NOME DE FANTASIA) RIBEIRO CONSTRUTORA",
  "CNPJ": "43.850.126/0001-68",
  "CNAE Principal": "43.99-1-03",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 208,
  "Tipo": "PJ",
  "Razão Social/Nome": "AUTO POSTO INDAIA II LTDA",
  "Nome Fantasia/Apelido": "POSTO SANTA RITA",
  "CNPJ": "19.404.894/0001-03",
  "CNAE Principal": "4731-8/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "21/02/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 209,
  "Tipo": "PJ",
  "Razão Social/Nome": "AGROPECUARIA SEMENTES TALISMA LTDA - EM RECUPERACAO JUDICIAL",
  "Nome Fantasia/Apelido": "SEMENTES TALISMA",
  "CNPJ": "37.637.139/0005-84",
  "CNAE Principal": " 01.15-6-00",
  "Região": 19,
  "Situação": "Ativa",
  "Data Cadastro": "21/02/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 210,
  "Tipo": "PJ",
  "Razão Social/Nome": "ANTONIO CARLOS BERTOLDO TRANSPORTES LTDA",
  "Nome Fantasia/Apelido": "TRANSPORTES BERTOLDO",
  "CNPJ": " 03.696.971/0001-06",
  "CNAE Principal": "4921-3/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "17/02/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 211,
  "Tipo": "PJ",
  "Razão Social/Nome": "OLAVO JACINTO DE OLIVEIRA TRANSPORTES LTDA",
  "CNPJ": " 03.679.666/0001-06",
  "CNAE Principal": "4921-3/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "22/02/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 213,
  "Tipo": "PJ",
  "Razão Social/Nome": "PEDRO MILITAO DA SILVA TRANSPORTES LTDA",
  "CNPJ": " 03.457.144/0001-60",
  "CNAE Principal": "4921-3/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "22/02/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 214,
  "Tipo": "PJ",
  "Razão Social/Nome": "ELCIO CRUZ GUARULHOS LTDA",
  "CNPJ": " 04.615.804/0001-56",
  "CNAE Principal": "4921-3/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "22/02/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 215,
  "Tipo": "PJ",
  "Razão Social/Nome": "JOSE WILTON LEITE TRANSPORTES LTDA",
  "Nome Fantasia/Apelido": "JOSE WILTON LEITE",
  "CNPJ": " 04.040.585/0001-24",
  "CNAE Principal": "4921-3/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "22/02/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 216,
  "Tipo": "PJ",
  "Razão Social/Nome": "MANOEL MESSIAS DE MOURA TRANSPORTES LTDA",
  "CNPJ": " 03.638.975/0001-38",
  "CNAE Principal": "4921-3/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "22/02/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 217,
  "Tipo": "PF",
  "Razão Social/Nome": "WELLINGTON GRACIANO ZANON",
  "Nome Fantasia/Apelido": "WELLINGTON GRACIANO ZANON",
  "CPF": "324.094.648-31",
  "CNAE Principal": "115600 CULTIVO DE SOJA",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "22/02/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 218,
  "Tipo": "PJ",
  "Razão Social/Nome": "AGROPECUARIA SEMENTES TALISMA LTDA",
  "Nome Fantasia/Apelido": "AGROPECUARIA SEMENTES TALISMA LTDA",
  "CNPJ": "37.637.139/0001-50",
  "CNAE Principal": " 01.15-6-00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "22/02/2022",
  "Estado": "GO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 220,
  "Tipo": "PJ",
  "Razão Social/Nome": "VCRC CONSTRUCAO E CONSERVACAO LTDA",
  "CNPJ": "35.551.938/0001-84",
  "CNAE Principal": "8299-7/99",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 222,
  "Tipo": "PJ",
  "Razão Social/Nome": "V.M. PUERTAS TRANSPORTES LTDA",
  "CNPJ": " 03.572.263/0001-63",
  "CNAE Principal": "4921-3/02",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 223,
  "Tipo": "PJ",
  "Razão Social/Nome": "EDVALDO RAMOS DE AZEVEDO LTDA",
  "CNPJ": " 03.732.445/0001-54",
  "CNAE Principal": "4921-3/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "24/02/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 224,
  "Tipo": "PJ",
  "Razão Social/Nome": "INDAIA COMERCIO VAREJISTA DE MERCADORIAS EM LOJAS DE CONVENIENCIA LTDA",
  "Nome Fantasia/Apelido": "CONVENIENCIA INDAIA",
  "CNPJ": "36.120.449/0001-30",
  "CNAE Principal": "4729-6/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "24/02/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 225,
  "Tipo": "PJ",
  "Razão Social/Nome": "L M DE FIGUEIREDO",
  "Nome Fantasia/Apelido": "CASA DE CARNE ARAGUACU",
  "CNPJ": "15.447.547/0001-07",
  "CNAE Principal": "4722-9/01",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "24/02/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 227,
  "Tipo": "PJ",
  "Razão Social/Nome": "MILTON JOSE DE SOUZA SILVA 13788072865",
  "Nome Fantasia/Apelido": "1000TON INSTALACOES MANUTENCOES ELETRICAS E HIDRAULICAS",
  "CNPJ": "31.321.480/0001-16",
  "CNAE Principal": "4321-5/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "24/02/2022",
  "Estado": "SP",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 228,
  "Tipo": "PJ",
  "Razão Social/Nome": "L L BARROS E CIA LTDA",
  "Nome Fantasia/Apelido": "CASA NOVA - MATERIAIS PARA CONSTRUCAO",
  "CNPJ": "21.937.484/0001-70",
  "CNAE Principal": "4744-0/99",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "24/02/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 229,
  "Tipo": "PJ",
  "Razão Social/Nome": "AGSOFT AUTOMACAO LTDA",
  "Nome Fantasia/Apelido": "AGSOFT AUTOMACAO",
  "CNPJ": "24.909.671/0001-38",
  "CNAE Principal": "6204-0/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "25/02/2022",
  "Estado": "SP",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 230,
  "Tipo": "PJ",
  "Razão Social/Nome": "ARS COMERCIO E SERVICOS EM TECNOLOGIA EIRELI",
  "Nome Fantasia/Apelido": "ARS AUDIO E ACUSTICA",
  "CNPJ": " 02.763.814/0001-03",
  "CNAE Principal": "4753-9/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "25/02/2022",
  "Estado": "DF",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 231,
  "Tipo": "PJ",
  "Razão Social/Nome": "MONI SEGURANCA LTDA",
  "Nome Fantasia/Apelido": "MONI SEGURANCA",
  "CNPJ": "42.405.973/0001-50",
  "CNAE Principal": "8011-1/01",
  "Região": 6,
  "Situação": "Ativa",
  "Data Cadastro": "21/12/2022",
  "Estado": "PE",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 232,
  "Tipo": "PF",
  "Razão Social/Nome": "ELOY BARSCH",
  "Nome Fantasia/Apelido": "ELOY BARSCH",
  "CPF": "135.253.876-87",
  "CNAE Principal": 111302,
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "02/03/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 233,
  "Tipo": "PJ",
  "Razão Social/Nome": "MANOEL FERNANDES AGUIAR TRANSPORTES LTDA",
  "CNPJ": " 04.722.622/0001-84",
  "CNAE Principal": "4921-3/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "02/03/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 235,
  "Tipo": "PF",
  "Razão Social/Nome": "GRAZZIANI RODRIGO MENEZES CARVALHO ",
  "Nome Fantasia/Apelido": "FAZENDA MARANATA ",
  "CPF": "692.537.731-20",
  "CNAE Principal": 115600,
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "04/03/2022",
  "Estado": "MT",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 236,
  "Tipo": "PF",
  "Razão Social/Nome": "RAFAEL BARSCH",
  "Nome Fantasia/Apelido": "Fazenda Santa Fé",
  "CPF": "849.855.771-20",
  "CNAE Principal": 111302,
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 237,
  "Tipo": "PJ",
  "Razão Social/Nome": "LUIS APARECIDO PASSARELLI TRANSPORTES LTDA",
  "CNPJ": " 03.611.599/0001-98",
  "CNAE Principal": "4921-3/02",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 238,
  "Tipo": "PJ",
  "Razão Social/Nome": "HMDS CONSTRUCOES LTDA",
  "Nome Fantasia/Apelido": "HMDS CONSTRUCOES",
  "CNPJ": "19.584.873/0001-17",
  "CNAE Principal": "71.12-0-00",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "SP",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 239,
  "Tipo": "PJ",
  "Razão Social/Nome": "IMPERUNICA IMPERMEABILIZACAO DEFINITIVA LTDA",
  "Nome Fantasia/Apelido": "IMPERUNICA IMPERMEABILIZACAO DEFINITIVA",
  "CNPJ": "12.046.800/0001-22",
  "CNAE Principal": "4330-4/01",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "07/03/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 240,
  "Tipo": "PJ",
  "Razão Social/Nome": "PAULO ROGERIO AGUIAR TRANSPORTE LTDA",
  "CNPJ": " 04.740.656/0001-00",
  "CNAE Principal": "4921-3/02",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 241,
  "Tipo": "PJ",
  "Razão Social/Nome": "OSCAR PAROLA TRANSPORTES",
  "CNPJ": " 03.747.873/0001-50",
  "CNAE Principal": "4921-3/02",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 242,
  "Tipo": "PJ",
  "Razão Social/Nome": "EXCLUSIVE TERRAPLANAGEM LTDA",
  "CNPJ": "32.813.365/0001-21",
  "CNAE Principal": "4313-4/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "07/03/2022",
  "Estado": "RS",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 243,
  "Tipo": "PJ",
  "Razão Social/Nome": "J L NETTO",
  "Nome Fantasia/Apelido": "OBA OBA HORTIFRUTI",
  "CNPJ": "35.606.687/0001-98",
  "CNAE Principal": "4724-5/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "08/03/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 244,
  "Tipo": "PJ",
  "Razão Social/Nome": "TEMA ENGENHARIA E LOGISTICA LTDA",
  "Nome Fantasia/Apelido": "TEMA ENGENHARIA",
  "CNPJ": "26.743.742/0001-09",
  "CNAE Principal": "43.13-4-00",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 245,
  "Tipo": "PJ",
  "Razão Social/Nome": "DJM TRANSPORTES LTDA.",
  "Nome Fantasia/Apelido": "DLM TRANSPORTES",
  "CNPJ": "10.348.565/0001-18",
  "CNAE Principal": "4930-2/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "08/03/2022",
  "Estado": "PR",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 247,
  "Tipo": "PJ",
  "Razão Social/Nome": "CIA LOG NORDESTE SERVICOS DE TRANSPORTES LIMITADA",
  "Nome Fantasia/Apelido": "CIA LOG NORDESTE",
  "CNPJ": "30.877.592/0001-95",
  "CNAE Principal": "4930-2/02",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "PE",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 251,
  "Tipo": "PF",
  "Razão Social/Nome": "JOSE RAFAEL SALMAZO Faz São Cristovão",
  "Nome Fantasia/Apelido": " Faz São Cristovão",
  "CPF": " 009.534.631-76",
  "CNAE Principal": "115600 ",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "09/03/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 254,
  "Tipo": "PJ",
  "Razão Social/Nome": "RODOMAIS INDUSTRIA DE IMPLEMENTOS RODOVIARIOS LTDA",
  "Nome Fantasia/Apelido": "RODOMAIS",
  "CNPJ": "45.459.045/0001-67",
  "CNAE Principal": "29.30-1-01",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "10/03/2022",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 256,
  "Tipo": "PJ",
  "Razão Social/Nome": "MARTPISOS REVESTIMENTOS EIRELI",
  "Nome Fantasia/Apelido": "MARTPISOS",
  "CNPJ": " 01.424.782/0001-59",
  "CNAE Principal": "4744-0/02",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 257,
  "Tipo": "PF",
  "Razão Social/Nome": "JORGE BATISTA DE CASTRO",
  "Nome Fantasia/Apelido": " Fazenda Olho Dagua",
  "CPF": " 074.933.391-04",
  "CNAE Principal": "151201 ",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "10/03/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 259,
  "Tipo": "PJ",
  "Razão Social/Nome": "P. DE B. PASSOS FILHO LTDA",
  "Nome Fantasia/Apelido": "E-FAUNA",
  "CNPJ": "28.019.532/0001-90",
  "CNAE Principal": "7490-1/99",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "10/03/2022",
  "Estado": "AL",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 260,
  "Tipo": "PF",
  "Razão Social/Nome": "JORGE BATISTA DE CASTRO Faz Sao Vicente",
  "Nome Fantasia/Apelido": " Faz Sao Vicente",
  "CPF": " 074.933.391-04",
  "CNAE Principal": "151201 ",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "11/03/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 261,
  "Tipo": "PJ",
  "Razão Social/Nome": "F S ALENCAR",
  "Nome Fantasia/Apelido": "DISPET - DISTRIBUIDORA DE PRODUTOS PET",
  "CNPJ": " 06.010.468/0001-43",
  "CNAE Principal": "46.23-1-09",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "11/03/2022",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 262,
  "Tipo": "PF",
  "Razão Social/Nome": "SOLEMAR ALVES DE OLIVEIRA",
  "Nome Fantasia/Apelido": "SOLEMAR ALVES DE OLIVEIRA",
  "CPF": "526.681.001-78",
  "CNAE Principal": "151201 ",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "11/03/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 263,
  "Tipo": "PJ",
  "Razão Social/Nome": "Projetaj Empreendimentos LTDA",
  "Nome Fantasia/Apelido": "PROJETAJ ENGENHARIA",
  "CNPJ": "25.204.592/0001-94",
  "CNAE Principal": "41.20-4-00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "11/03/2022",
  "Estado": "BA",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 264,
  "Tipo": "PF",
  "Razão Social/Nome": "ROMUALDO JOSE FONSECA",
  "Nome Fantasia/Apelido": "ROMUALDO JOSE FONSECA",
  "CPF": " 027.179.188-84",
  "CNAE Principal": "151201 ",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 265,
  "Tipo": "PJ",
  "Razão Social/Nome": "V M S SERVICOS TERCEIRIZADOS EIRELI",
  "Nome Fantasia/Apelido": "V M S SERVICOS TERCEIRIZADOS",
  "CNPJ": "25.289.353/0001-84",
  "CNAE Principal": "4399-1/99",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "11/03/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 266,
  "Tipo": "PF",
  "Razão Social/Nome": "RICARDO PINTO COELHO FAZENDA CONQUISTA",
  "Nome Fantasia/Apelido": "FAZENDA CONQUISTA",
  "CPF": " 055.478.318-59",
  "CNAE Principal": "151201 ",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 267,
  "Tipo": "PF",
  "Razão Social/Nome": "RICARDO PINTO COELHO Fazenda Álamo",
  "Nome Fantasia/Apelido": " Fazenda Álamo",
  "CPF": " 055.478.318-59",
  "CNAE Principal": 151201,
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 268,
  "Tipo": "PF",
  "Razão Social/Nome": "RICARDO PINTO COELHO FAZENDA SAO JOSE II",
  "Nome Fantasia/Apelido": "FAZENDA SAO JOSE II",
  "CPF": " 055.478.318-59",
  "CNAE Principal": "151201 ",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 269,
  "Tipo": "PF",
  "Razão Social/Nome": "DARIO SERGIO BORGES   FAZ VARJAO BONITO",
  "Nome Fantasia/Apelido": "FAZ VARJAO BONITO",
  "CPF": "125.904.101-82",
  "CNAE Principal": "151201 ",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 270,
  "Tipo": "PF",
  "Razão Social/Nome": "DARIO SERGIO BORGES    FAZ FLAMBOYANT",
  "Nome Fantasia/Apelido": "FAZENDA FLAMBOYANT",
  "CPF": "125.904.101-82",
  "CNAE Principal": "115600 ",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 271,
  "Tipo": "PJ",
  "Razão Social/Nome": "SECACCI ENGENHARIA E CONSTRUCOES LIMITADA",
  "CNPJ": "68.254.143/0001-63",
  "CNAE Principal": "4120-4/00",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "SP",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 272,
  "Tipo": "PF",
  "Razão Social/Nome": "ALAOR PROCOPIO DE AVILA FILHO Faz Rincao",
  "Nome Fantasia/Apelido": "Faz Rincao",
  "CPF": "634.490.991-91",
  "CNAE Principal": "151201 ",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 273,
  "Tipo": "PJ",
  "Razão Social/Nome": "ESCRITORIO LOPES CONTABIL S/C LTDA",
  "Nome Fantasia/Apelido": "LOPES CONTABIL",
  "CNPJ": "26.639.625/0001-91",
  "CNAE Principal": "6920-6/01",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "14/03/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 274,
  "Tipo": "PJ",
  "Razão Social/Nome": "PAIS E FILHOS CONFECCOES LTDA",
  "Nome Fantasia/Apelido": "PAIS E FILHOS CONFECCOES",
  "CNPJ": "37.376.100/0001-27",
  "CNAE Principal": "4781-4/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "14/03/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 275,
  "Tipo": "PJ",
  "Razão Social/Nome": "LHBB COMERCIO E SERVICOS EIRELI",
  "Nome Fantasia/Apelido": "LHBB",
  "CNPJ": "22.555.663/0001-05",
  "CNAE Principal": "4321-5/00",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "15/03/2022",
  "Estado": "SP",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 276,
  "Tipo": "PJ",
  "Razão Social/Nome": "BRUNO FERNANDES BORZI",
  "Nome Fantasia/Apelido": "BFB INSTALACOES",
  "CNPJ": "40.522.733/0001-00",
  "CNAE Principal": "43.99-1-01",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "15/03/2022",
  "Estado": "SP",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 278,
  "Tipo": "PF",
  "Razão Social/Nome": "EDBALDO LOPES DA SILVA",
  "Nome Fantasia/Apelido": "EDBALDO LOPES DA SILVA",
  "CPF": "126.338.011-53",
  "CNAE Principal": "151201 ",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 279,
  "Tipo": "PJ",
  "Razão Social/Nome": "FABRIN & AMARO MONTAGEM E MANUTENCAO INDUSTRIAL LTDA",
  "Nome Fantasia/Apelido": "AMARO SERVICOS",
  "CNPJ": "32.331.625/0001-22",
  "CNAE Principal": "4292-8/01",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "15/03/2022",
  "Estado": "PR",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 280,
  "Tipo": "PJ",
  "Razão Social/Nome": "IGREJA EVANGELICA ASSEMBLEIA DE DEUS DE GUARAI - TO.",
  "Nome Fantasia/Apelido": "IGREJA EVANGELICA ASSEMBLEIA DE DEUS DE GUARAI",
  "CNPJ": " 02.133.395/0001-26",
  "CNAE Principal": "9491-0/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "16/03/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 281,
  "Tipo": "PJ",
  "Razão Social/Nome": "SANTACRUZ ENGENHARIA LTDA",
  "Nome Fantasia/Apelido": "SANTACRUZ ENGENHARIA",
  "CNPJ": "40.489.197/0001-99",
  "CNAE Principal": "4120-4/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "16/03/2022",
  "Estado": "BA",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 282,
  "Tipo": "PJ",
  "Razão Social/Nome": "J F DE ARAUJO",
  "Nome Fantasia/Apelido": "PRINT GRAFICA",
  "CNPJ": "10.546.659/0001-00",
  "CNAE Principal": "1813-0/99",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "16/03/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 283,
  "Tipo": "PJ",
  "Razão Social/Nome": "ORLEILSON TAVEIRA DE ANDRADE 23859539272",
  "Nome Fantasia/Apelido": "AMAZONTEC",
  "CNPJ": "32.587.748/0001-29",
  "CNAE Principal": "4329-1/03",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "09/01/2023",
  "Estado": "PA",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 284,
  "Tipo": "PJ",
  "Razão Social/Nome": "BRUNO SANTOS SILVA",
  "Nome Fantasia/Apelido": "GRUPO RENTEC - TRANSPORTE , REMOCAO TECNICA E CAPATAZIA",
  "CNPJ": "20.354.060/0001-10",
  "CNAE Principal": "4399-1/04",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "CE",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 285,
  "Tipo": "PJ",
  "Razão Social/Nome": "AGRO-E COMERCIO DE PRODUTOS AGRICOLAS LTDA",
  "CNPJ": " 03.283.176/0001-96",
  "CNAE Principal": "4683-4/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "18/03/2022",
  "Estado": "RS",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 287,
  "Tipo": "PJ",
  "Razão Social/Nome": "ADVERTLOG LOGISTICA LTDA",
  "Nome Fantasia/Apelido": "ADVERTLOG LOGISTICA",
  "CNPJ": "32.954.543/0001-34",
  "CNAE Principal": "5211-7/99",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "21/03/2022",
  "Estado": "SP",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 288,
  "Tipo": "PJ",
  "Razão Social/Nome": "ATILE SERVICOS ADMINISTRATIVOS EIRELI",
  "Nome Fantasia/Apelido": "ATILE SERVICOS ADMINISTRATIVOS",
  "CNPJ": "10.729.059/0001-79",
  "CNAE Principal": "4757-1/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "22/03/2022",
  "Estado": "PR",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 289,
  "Tipo": "PJ",
  "Razão Social/Nome": "CENTRO MEDICO IMAGEM EIRELI",
  "Nome Fantasia/Apelido": "IMAGEM CENTRO CLINICO E DIAGNOSTICO",
  "CNPJ": "42.347.736/0001-80",
  "CNAE Principal": "86.30-5-02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "22/03/2022",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 291,
  "Tipo": "PJ",
  "Razão Social/Nome": "A. C. DA SILVA TRANSPORTES",
  "CNPJ": "23.240.661/0001-90",
  "CNAE Principal": "4930-2/03",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "23/03/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 292,
  "Tipo": "PJ",
  "Razão Social/Nome": "AGROPECUARIA GOPI LTDA",
  "Nome Fantasia/Apelido": "AGROPECUARIA GOPI",
  "CNPJ": " 02.962.011/0001-88",
  "CNAE Principal": " 0111-3/01",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "25/03/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 293,
  "Tipo": "PJ",
  "Razão Social/Nome": "JG EMPREITEIRA E CONSTRUCOES LTDA",
  "CNPJ": " 03.117.558/0001-40",
  "CNAE Principal": "4399-1/03",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "25/03/2022",
  "Estado": "SP",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 294,
  "Tipo": "PJ",
  "Razão Social/Nome": "AGRICOLA ENGENHO NOVO LTDA",
  "Nome Fantasia/Apelido": "ENGENHO NOVO",
  "CNPJ": "18.011.389/0001-36",
  "CNAE Principal": " 0111-3/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "25/03/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 295,
  "Tipo": "PJ",
  "Razão Social/Nome": "LEVE SERVICOS AGRICOLAS LTDA",
  "Nome Fantasia/Apelido": "LEVE SERVICOS AGRICOLAS",
  "CNPJ": " 04.162.983/0001-13",
  "CNAE Principal": " 0161-0/03",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "25/03/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 296,
  "Tipo": "PJ",
  "Razão Social/Nome": "ONE CONSTRUCAO E SERVICOS AGRICOLAS LTDA  ",
  "Nome Fantasia/Apelido": "ONE CONSTRUCAO E SERVICOS AGRICOLAS",
  "CNPJ": "28.287.805/0001-87",
  "CNAE Principal": "4222-7/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "25/03/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 297,
  "Tipo": "PJ",
  "Razão Social/Nome": "CONSTRUTORA CANAA SALDANHA LTDA.",
  "Nome Fantasia/Apelido": "ALFA CORRIMAO",
  "CNPJ": "17.119.304/0001-75",
  "CNAE Principal": "4329-1/99",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "25/03/2022",
  "Estado": "SP",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 298,
  "Tipo": "PJ",
  "Razão Social/Nome": "RD COMERCIO E SERVICOS EIRELI",
  "Nome Fantasia/Apelido": "R.D SERVICOS",
  "CNPJ": "27.506.119/0001-97",
  "CNAE Principal": "3314-7/12",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "25/03/2022",
  "Estado": "SP",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 299,
  "Tipo": "PJ",
  "Razão Social/Nome": "CONSTRUTORA FERREIRA NETO LTDA",
  "Nome Fantasia/Apelido": "CONSTRUTORA FERREIRA NETO",
  "CNPJ": "19.717.700/0001-20",
  "CNAE Principal": "4120-4/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "25/03/2022",
  "Estado": "PB",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 300,
  "Tipo": "PF",
  "Razão Social/Nome": "RENAN NASCIMENTO VALADAO (PEIXE)",
  "Nome Fantasia/Apelido": "RENAN NASCIMENTO VALADAO (PEIXE)",
  "CPF": "708.506.741-15",
  "CNAE Principal": " 0111301",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 301,
  "Tipo": "PF",
  "Razão Social/Nome": "RENAN NASCIMENTO VALADAO (FORMOSO)",
  "Nome Fantasia/Apelido": "RENAN NASCIMENTO VALADAO (FORMOSO)",
  "CPF": "708.506.741-15",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 302,
  "Tipo": "PF",
  "Razão Social/Nome": "RENAN NASCIMENTO VALADAO (BREJINHO)",
  "Nome Fantasia/Apelido": "RENAN NASCIMENTO VALADAO BREJINHO",
  "CPF": "708.506.741-15",
  "CNAE Principal": " 0111301 ",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 303,
  "Tipo": "PF",
  "Razão Social/Nome": "CARLOS FREDERICO NASCIMENTO VALADAO",
  "Nome Fantasia/Apelido": "CARLOS FREDERICO NASCIMENTO VALADAO",
  "CPF": "825.434.531-72",
  "CNAE Principal": " 0111301 ",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 304,
  "Tipo": "PF",
  "Razão Social/Nome": "CARLOS FREDERICO N VALADAO FAZ SOLOS ",
  "Nome Fantasia/Apelido": "CARLOS FREDERICO N VALADAO FAZ SOLOS",
  "CPF": "825.434.531-72",
  "CNAE Principal": " 0151201 ",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 305,
  "Tipo": "PF",
  "Razão Social/Nome": "CARLOS FREDERICO N VALADAO",
  "Nome Fantasia/Apelido": "CARLOS FREDERICO N VALADAO",
  "CPF": "825.434.531-72",
  "CNAE Principal": " 0111301 ",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "07/03/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 306,
  "Tipo": "PJ",
  "Razão Social/Nome": "NORTE EMPREENDIMENTOS, LOCACOES E SERVICOS LTDA",
  "Nome Fantasia/Apelido": "NORTE EMPREENDIMENTOS, LOCACOES E SERVICOS",
  "CNPJ": "13.723.721/0001-71",
  "CNAE Principal": "77.19-5-99",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "28/03/2022",
  "Estado": "BA",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 307,
  "Tipo": "PJ",
  "Razão Social/Nome": "USE CASE SISTEMAS DE INFORMACAO LTDA",
  "CNPJ": " 09.635.690/0001-20",
  "CNAE Principal": "6201-5/01",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "MG",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 308,
  "Tipo": "PJ",
  "Razão Social/Nome": "LOS TIAOS ALIMENTOS LTDA",
  "Nome Fantasia/Apelido": "LOS TIAOS",
  "CNPJ": "31.374.640/0001-95",
  "CNAE Principal": "5620-1/01",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "MG",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 309,
  "Tipo": "PJ",
  "Razão Social/Nome": "MARCOS C. DE MAGALHAES LTDA",
  "Nome Fantasia/Apelido": "VECTOR GERADORES E INSTALACOES ELETRICAS",
  "CNPJ": "21.266.579/0001-09",
  "CNAE Principal": "3313-9/99",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "29/03/2022",
  "Estado": "MT",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 311,
  "Tipo": "PF",
  "Razão Social/Nome": "VITOR N VALADAO FAZENDA VALE VERDE",
  "Nome Fantasia/Apelido": "VITOR N VALADAO FAZENDA VALE VERDE",
  "CPF": "894.184.681-15",
  "CNAE Principal": " 0111301",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 312,
  "Tipo": "PJ",
  "Razão Social/Nome": "TERATEST DO BRASIL LTDA",
  "CNPJ": "23.786.074/0001-09",
  "CNAE Principal": "4669-9/99",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "30/03/2022",
  "Estado": "SP",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 313,
  "Tipo": "PJ",
  "Razão Social/Nome": "CLENILSON SILVA DE MATOS 05221370328",
  "Nome Fantasia/Apelido": "AUTO MECANICA POLIVALENTE",
  "CNPJ": "13.477.696/0001-93",
  "CNAE Principal": "4520-0/01",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "PA",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 314,
  "Tipo": "PF",
  "Razão Social/Nome": "VITOR N. VALADÃO FAZ VALE VERDE",
  "Nome Fantasia/Apelido": "VITOR N. VALADÃO FAZ VALE VERDE",
  "CPF": "894.184.681-15",
  "CNAE Principal": " 0151201",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "30/03/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 315,
  "Tipo": "PJ",
  "Razão Social/Nome": "SS SERVICOS, CONSTRUCOES E REFORMAS LTDA",
  "Nome Fantasia/Apelido": "SS SERVICOS",
  "CNPJ": "10.858.081/0001-19",
  "CNAE Principal": "4120-4/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "01/04/2022",
  "Estado": "GO",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 316,
  "Tipo": "PJ",
  "Razão Social/Nome": "FISIOKLINIK - SERVICOS DE FISIOTERAPIA LTDA",
  "Nome Fantasia/Apelido": "C M O",
  "CNPJ": " 02.247.890/0001-66",
  "CNAE Principal": "8650-0/04",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "01/04/2022",
  "Estado": "BA",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 317,
  "Tipo": "PJ",
  "Razão Social/Nome": "TRANSFORMES SOLUTIONS COMERCIO E MANUTENCAO DE MAQUINAS E ACESSORIOS INDUSTRIAIS LTDA",
  "Nome Fantasia/Apelido": "TRANSFORMES SOLUTIONS PECAS ESPECIAIS",
  "CNPJ": "22.634.889/0001-00",
  "CNAE Principal": "3314-7/05",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "SP",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 318,
  "Tipo": "PJ",
  "Razão Social/Nome": "R O  CONTRUCOES LTDA",
  "CNPJ": "27.326.124/0001-18",
  "CNAE Principal": "4399-1/03",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "04/04/2022",
  "Estado": "SP",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 319,
  "Tipo": "PF",
  "Razão Social/Nome": "VITOR NASCIMENTO VALADÃO FAZ SÃO GERALDO",
  "CPF": "894.184.681-15",
  "CNAE Principal": " 0151201",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "04/04/2022",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 320,
  "Tipo": "PJ",
  "Razão Social/Nome": "FELIPE MORAIS SANTANA 11229884602",
  "Nome Fantasia/Apelido": "TECHSEL SOLUCOES INOVADORAS",
  "CNPJ": "16.366.849/0001-13",
  "CNAE Principal": "4321-5/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "04/04/2022",
  "Estado": "MG",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 321,
  "Tipo": "PJ",
  "Razão Social/Nome": "MEGA ENGENHARIA EIRELI",
  "Nome Fantasia/Apelido": "MEGA ENGENHARIA",
  "CNPJ": "24.932.478/0001-18",
  "CNAE Principal": "41.20-4-00",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "GO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 322,
  "Tipo": "PJ",
  "Razão Social/Nome": "MIRIAM JOY MORELAND ANDREANI",
  "Nome Fantasia/Apelido": "MORELAND'S ENGLISH SCHOOL",
  "CNPJ": "37.241.841/0001-09",
  "CNAE Principal": "8593-7/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "05/04/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 323,
  "Tipo": "PJ",
  "Razão Social/Nome": "KWE MONTAGENS INDUSTRIAIS LTDA",
  "Nome Fantasia/Apelido": "KWE COMERCIAL E CONSTRUCAO CIVIL",
  "CNPJ": "32.099.443/0001-78",
  "CNAE Principal": "4321-5/00",
  "Região": 2,
  "Situação": "Ativa",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 324,
  "Tipo": "PJ",
  "Razão Social/Nome": "CONDOMINIO MAGISTER",
  "CNPJ": " 00.719.401/0001-04",
  "CNAE Principal": "8112-5/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "06/04/2022",
  "Estado": "DF",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 325,
  "Tipo": "PJ",
  "Razão Social/Nome": "RDS PROJETOS APLICADOS E CONSTRUÇÕES LTDA",
  "Nome Fantasia/Apelido": "EIXO ENGENHARIA",
  "CNPJ": "31.550.702/0001-72",
  "CNAE Principal": "41.20-4-00 ",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "06/04/2022",
  "Estado": "DF",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 326,
  "Tipo": "PJ",
  "Razão Social/Nome": "WILLIAM SANTORO DA COSTA ",
  "Nome Fantasia/Apelido": "IGOM SOLUCOES EM VENTILADORES E EXAUSTORES INDUSTRIAIS",
  "CNPJ": "28.956.762/0001-85",
  "CNAE Principal": "4744-0/03",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 327,
  "Tipo": "PJ",
  "Razão Social/Nome": "TBM BRASIL COMERCIO DE MAQUINAS, EQUIPAMENTOS E IMPORTACAO EIRELI",
  "Nome Fantasia/Apelido": "JBR BRASIL",
  "CNPJ": "18.413.175/0001-96",
  "CNAE Principal": "4669-9/99",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "08/04/2022",
  "Estado": "SP",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 328,
  "Tipo": "PF",
  "Razão Social/Nome": "VITOR N VALADÃO E OUTROS - CVR",
  "Nome Fantasia/Apelido": "VITOR N VALADÃO E OUTROS - CVR",
  "CPF": "894.184.681-15",
  "CNAE Principal": " 0111301",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "07/03/2022",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 329,
  "Tipo": "PJ",
  "Razão Social/Nome": "DIGITALPRINTER COMERCIO DE INFORMATICA E INSUMOS LTDA ME",
  "Nome Fantasia/Apelido": "DIGITALPRINTER",
  "CNPJ": " 00.122.460/0001-92",
  "CNAE Principal": "4751-2/01",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "MT",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 330,
  "Tipo": "PJ",
  "Razão Social/Nome": "UNO TELECOM LTDA",
  "Nome Fantasia/Apelido": "UNO TELECOM",
  "CNPJ": "12.152.867/0001-41",
  "CNAE Principal": "61.10-8-03",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "RN",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 331,
  "Tipo": "PJ",
  "Razão Social/Nome": "VALMAQ-MAQUINAS E IMPLEMENTOS AGRICOLAS LTDA",
  "CNPJ": "25.070.392/0001-96",
  "CNAE Principal": "4789-0/99",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "11/04/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 332,
  "Tipo": "PJ",
  "Razão Social/Nome": "F B PEREIRA ODONTOLOGIA",
  "Nome Fantasia/Apelido": "F B ODONTOLOGIA",
  "CNPJ": "33.192.404/0001-83",
  "CNAE Principal": "8630-5/04",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "13/04/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 334,
  "Tipo": "PJ",
  "Razão Social/Nome": "SOLOS SERVICOS AGRICOLAS LTDA",
  "Nome Fantasia/Apelido": "SOLOS SERVICOS AGRICOLAS",
  "CNPJ": "15.091.017/0001-79",
  "CNAE Principal": "6822-6/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "11/04/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 336,
  "Tipo": "PJ",
  "Razão Social/Nome": "HOSPITAL NOSSA SENHORA DAS VITORIAS LTDA",
  "Nome Fantasia/Apelido": "HOSPITAL NOSSA SENHORA DAS VITORIAS",
  "CNPJ": "97.351.266/0001-10",
  "CNAE Principal": "8650-0/99",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "PI",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 337,
  "Tipo": "PF",
  "Razão Social/Nome": "VITOR NASCIMENTO VALADAO CHACARA DO ENGENHO",
  "Nome Fantasia/Apelido": "VITOR NASCIMENTO VALADAO CHACARA DO ENGENHO",
  "CPF": "894.184.681-15",
  "CNAE Principal": " 0111301",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "13/04/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 338,
  "Tipo": "PF",
  "Razão Social/Nome": "LUIZ DELEVATTI",
  "Nome Fantasia/Apelido": "Fazenda SOLEDADE",
  "CPF": " 015.808.509-44",
  "CNAE Principal": " 0151-2/02",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 339,
  "Tipo": "PJ",
  "Razão Social/Nome": "MIRA ENGENHARIA LTDA",
  "Nome Fantasia/Apelido": "MIRA ENGENHARIA",
  "CNPJ": "26.079.757/0001-06",
  "CNAE Principal": "7112-0/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "13/04/2022",
  "Estado": "RJ",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 340,
  "Tipo": "PJ",
  "Razão Social/Nome": "LOJAS DENY ELETRO MOVEIS LTDA",
  "Nome Fantasia/Apelido": "LOJAS DENY",
  "CNPJ": " 00.793.091/0001-60",
  "CNAE Principal": "4754-7/01",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "13/04/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 341,
  "Tipo": "PJ",
  "Razão Social/Nome": "AGRI-SERV AGRICULTURA DE PRECISAO E SERVICOS DE APOIO ADMINISTRATIVO LTDA",
  "CNPJ": "26.232.718/0001-05",
  "CNAE Principal": "8211-3/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "18/04/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 342,
  "Tipo": "PJ",
  "Razão Social/Nome": "AVENSI CONSTRUTORA LTDA",
  "Nome Fantasia/Apelido": "AVENSI ENGENHARIA LTDA",
  "CNPJ": " 08.983.536/0001-86",
  "CNAE Principal": "42.11-1-01",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "RS",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 343,
  "Tipo": "PJ",
  "Razão Social/Nome": "LOJAS DENY ELETRO MOVEIS LTDA FILIAL",
  "Nome Fantasia/Apelido": "LOJAS DENY ELETRO MOVEIS LTDA FILIAL",
  "CNPJ": " 00.793.091/0004-02",
  "CNAE Principal": "4754-7/01",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "19/04/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 344,
  "Tipo": "PJ",
  "Razão Social/Nome": "DANIELA DOS SANTOS ",
  "Nome Fantasia/Apelido": "IMPACTO MANUTENCAO",
  "CNPJ": "25.460.839/0001-33",
  "CNAE Principal": "33.13-9-01",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "SP",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 345,
  "Tipo": "PJ",
  "Razão Social/Nome": "A M RAMOS MOREIRA E CIA LTDA",
  "Nome Fantasia/Apelido": "MONTREAL FORROS E DIVISORIAS",
  "CNPJ": "40.164.424/0001-06",
  "CNAE Principal": "47.89-0-99",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "19/04/2022",
  "Estado": "BA",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 346,
  "Tipo": "PJ",
  "Razão Social/Nome": "COOPERATIVA DE TRABALHO AGRICOLA, ASSISTENCIA TECNICA E SERVICOS - COOATES",
  "Nome Fantasia/Apelido": "COOATES",
  "CNPJ": " 03.997.641/0001-50",
  "CNAE Principal": "7490-1/03",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "19/04/2022",
  "Estado": "PE",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 347,
  "Tipo": "PJ",
  "Razão Social/Nome": "CLIMATIZAR ALUGUEL DE EQUIPAMENTOS INDUSTRIAIS LTDA",
  "Nome Fantasia/Apelido": "CLIMATIZAR",
  "CNPJ": "10.493.376/0001-39",
  "CNAE Principal": "77.39-0-99",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "19/04/2022",
  "Estado": "DF",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 348,
  "Tipo": "PJ",
  "Razão Social/Nome": "REZMED COMERCIO DE PRODUTOS HOSPITALARES EIRELI",
  "Nome Fantasia/Apelido": "REZMED",
  "CNPJ": " 07.089.698/0001-02",
  "CNAE Principal": "4644-3/01",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "19/04/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 349,
  "Tipo": "PJ",
  "Razão Social/Nome": "AGROFARM - PRODUTOS AGROQUIMICOS LTDA",
  "Nome Fantasia/Apelido": "AGROFARM",
  "CNPJ": " 05.787.644/0002-76",
  "CNAE Principal": "1066-0/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "19/04/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 350,
  "Tipo": "PJ",
  "Razão Social/Nome": "VANESSA MARTINS LOURES 00422083607",
  "Nome Fantasia/Apelido": "FLOURES JARDIM E PAISAGISMO",
  "CNPJ": "41.316.855/0001-03",
  "CNAE Principal": "4789-0/02",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "AL",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 351,
  "Tipo": "PJ",
  "Razão Social/Nome": "ANTONIO RODRIGUES FEITOSA 22056709805",
  "Nome Fantasia/Apelido": "CEARA LANCHES",
  "CNPJ": "37.445.997/0001-01",
  "CNAE Principal": "5611-2/03",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 352,
  "Tipo": "PJ",
  "Razão Social/Nome": "S.M. ASSESSORIA E INSPECAO - EIRELI",
  "CNPJ": "71.542.765/0001-00",
  "CNAE Principal": "7120-1/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "26/04/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 353,
  "Tipo": "PJ",
  "Razão Social/Nome": "ALEXANDRE PEREIRA CARDOSO",
  "Nome Fantasia/Apelido": "INFOTEC COMPUTADORES",
  "CNPJ": " 08.012.926/0001-09",
  "CNAE Principal": "4751-2/01",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "26/04/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 354,
  "Tipo": "PJ",
  "Razão Social/Nome": "MOTAVI DEMOLICOES E TERRAPLENAGEM LTDA",
  "Nome Fantasia/Apelido": "MOTAVI LTDA",
  "CNPJ": " 06.374.397/0001-68",
  "CNAE Principal": "43.13-4-00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "02/03/2023",
  "Estado": "MG",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 355,
  "Tipo": "PJ",
  "Razão Social/Nome": "TS CRUZ PREST SERVICE LIMITADA",
  "Nome Fantasia/Apelido": "TS CRUZ PREST SERVICE LIMITADA",
  "CNPJ": "37.606.485/0001-71",
  "CNAE Principal": "8211-3/00",
  "Região": 2,
  "Situação": "Ativa",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 356,
  "Tipo": "PJ",
  "Razão Social/Nome": "ONEL OUTSOURCING APOIO ADMINISTRATIVO EIRELI",
  "Nome Fantasia/Apelido": "ONEL",
  "CNPJ": "11.939.225/0001-24",
  "CNAE Principal": "8219-9/99",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "28/04/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 357,
  "Tipo": "PJ",
  "Razão Social/Nome": "CONSERPAV CONSTRUCOES SERVICOS E PAVIMENTACAO EIRELI",
  "Nome Fantasia/Apelido": "CONSERPAV",
  "CNPJ": "10.895.537/0001-10",
  "CNAE Principal": "41.20-4-00 ",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "MA",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 358,
  "Tipo": "PJ",
  "Razão Social/Nome": " DMINAS MINERACAO & LOGISTICA LTDA.",
  "Nome Fantasia/Apelido": "DMINAS MINERACAO & LOGISTICA",
  "CNPJ": "21.898.125/0002-31",
  "CNAE Principal": "74.90-1-04 ",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 361,
  "Tipo": "PJ",
  "Razão Social/Nome": "COMERCIO E ENGARRAFAMENTO DE AGUA MINERAL SARA EIRELI",
  "Nome Fantasia/Apelido": "AGUA SARA",
  "CNPJ": " 04.752.475/0001-95",
  "CNAE Principal": "1121-6/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "02/05/2022",
  "Estado": "GO",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 362,
  "Tipo": "PJ",
  "Razão Social/Nome": "LGOM SERVICOS EIRELI",
  "Nome Fantasia/Apelido": "LGOM SERVICOS",
  "CNPJ": "33.825.804/0001-89",
  "CNAE Principal": "5611-2/01",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "PA",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 363,
  "Tipo": "PJ",
  "Razão Social/Nome": "DELTA PORTARIA E ORGANIZACOES DE EVENTOS LTDA",
  "Nome Fantasia/Apelido": "DELTA PORTARIA E ORGANIZACOES DE EVENTOS LTDA",
  "CNPJ": "18.626.491/0001-46",
  "CNAE Principal": "8111-7/00",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 364,
  "Tipo": "PJ",
  "Razão Social/Nome": "ERICA DAWANY ABREU FELTRIN",
  "Nome Fantasia/Apelido": "JETECH ELEVADORES MULTIMARCAS",
  "CNPJ": "43.549.145/0001-59",
  "CNAE Principal": "43.29-1-03 ",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "SP",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 365,
  "Tipo": "PJ",
  "Razão Social/Nome": "TERRA FORTE COM. DE PRODUTOS AGRICOLAS LTDA",
  "CNPJ": "33.174.133/0001-33",
  "CNAE Principal": "4683-4/00",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 366,
  "Tipo": "PJ",
  "Razão Social/Nome": "ANTONIO ANDRE JEGGLI PATSCHE",
  "Nome Fantasia/Apelido": "DISK RESOLVE CONSTRUCOES E MANUTENCOES",
  "CNPJ": "44.897.144/0001-68",
  "CNAE Principal": "41.20-4-00",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "RS",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 367,
  "Tipo": "PF",
  "Razão Social/Nome": "VILSON SILVA NOGUEIRA",
  "Nome Fantasia/Apelido": "FAZENDA PROJETO RIO FORMOSO II ",
  "CPF": "226.018.051-53",
  "CNAE Principal": " 0111301",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 368,
  "Tipo": "PJ",
  "Razão Social/Nome": "VANESSA CRISTINE FREDERICO",
  "Nome Fantasia/Apelido": "SO MAQUINAS ASSISTENCIA TECNICA , VENDAS E ACESSORIOS",
  "CNPJ": "13.290.084/0001-97",
  "CNAE Principal": "3313-9/01",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 369,
  "Tipo": "PJ",
  "Razão Social/Nome": "V. S. NOGUEIRA SERVICOS AGRICOLAS",
  "Nome Fantasia/Apelido": "NOGUEIRA SERVICOS AGRICOLAS",
  "CNPJ": "40.727.403/0001-51",
  "CNAE Principal": " 01.61-0-99",
  "Região": "0",
  "Situação": "Ativa",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 370,
  "Tipo": "PJ",
  "Razão Social/Nome": "MORAIS SERVICOS DE AGRIMENSURA LTDA",
  "CNPJ": " 02.141.655/0001-05",
  "CNAE Principal": "7119-7/01",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "SP",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 371,
  "Tipo": "PJ",
  "Razão Social/Nome": "FERNANDO MIRANDA DOS SANTOS 03875617657",
  "Nome Fantasia/Apelido": "FUXU CONSULTORIA ELETRICA",
  "CNPJ": "43.102.729/0001-81",
  "CNAE Principal": "43.21-5-00",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "MG",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 374,
  "Tipo": "PJ",
  "Razão Social/Nome": "REDE ARBOREO ENGENHARIA E SERVICOS AMBIENTAIS LTDA",
  "Nome Fantasia/Apelido": "ARBOREO.NET",
  "CNPJ": "26.507.294/0001-36",
  "CNAE Principal": "71.12-0-00",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "SP",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 377,
  "Tipo": "PJ",
  "Razão Social/Nome": "A. TANABE & CIA LTDA",
  "CNPJ": "59.119.339/0001-54",
  "CNAE Principal": "4783-1/01",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 379,
  "Tipo": "PJ",
  "Razão Social/Nome": "EMPRESA TESTE",
  "CNPJ": " 00.000.000/0000-00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "12/05/2022",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 380,
  "Tipo": "PJ",
  "Razão Social/Nome": "ALFA TELECOM COMERCIO E SERVICO DE TECNOLOGIA EM REDE LTDA",
  "Nome Fantasia/Apelido": "ALFA TELECOM COMERCIO E SERVICO DE TECNOLOGIA EM REDE",
  "CNPJ": "31.837.899/0001-25",
  "CNAE Principal": "43.21-5-00 ",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "DF",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 381,
  "Tipo": "PJ",
  "Razão Social/Nome": "CALL SERVICES MANUTENCAO E INSTALACOES ELETRONICA EIRELI",
  "Nome Fantasia/Apelido": "CALLSERVICOS MANUTENCAO E INSTALACOES ELETRONICAS",
  "CNPJ": "26.800.364/0001-40",
  "CNAE Principal": "9512-6/00",
  "Região": 2,
  "Situação": "Ativa",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 382,
  "Tipo": "PJ",
  "Razão Social/Nome": "TRATPISO COMERCIO E SERVICOS LTDA",
  "CNPJ": " 00.416.914/0001-38",
  "CNAE Principal": "4789-0/05",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "MG",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 385,
  "Tipo": "PJ",
  "Razão Social/Nome": "CONSELHO INDIGENISTA MISSIONARIO CIMI ",
  "Nome Fantasia/Apelido": "CENTRO DE FORMACAO VICENTE CANAS",
  "CNPJ": " 00.479.105/0013-09",
  "CNAE Principal": "9491-0/00",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "GO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 386,
  "Tipo": "PJ",
  "Razão Social/Nome": "OPERANDO ENGENHARIA LTDA",
  "Nome Fantasia/Apelido": "OPERANDO ENGENHARIA",
  "CNPJ": "59.070.706/0001-72",
  "CNAE Principal": "4399-1/99",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 387,
  "Tipo": "PJ",
  "Razão Social/Nome": "GUSTAVO VERA SOLUCOES TI",
  "Nome Fantasia/Apelido": "GV SOLUCOES TI",
  "CNPJ": "20.380.618/0001-31",
  "CNAE Principal": "9511-8/00",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 388,
  "Tipo": "PJ",
  "Razão Social/Nome": "NORTE SUN ENERGIA E SUSTENTABILIDADE LTDA",
  "Nome Fantasia/Apelido": "NORTE SUN ENERGIA E SUSTENTABILIDADE",
  "CNPJ": "15.863.696/0001-57",
  "CNAE Principal": "4322-3/01",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "RJ",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 390,
  "Tipo": "PJ",
  "Razão Social/Nome": "S DA C SILVINO - AR CONDICIONADO - ME",
  "Nome Fantasia/Apelido": "ARTEM - AR CONDICIONADO",
  "CNPJ": "24.313.909/0001-68",
  "CNAE Principal": "4753-9/00",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 391,
  "Tipo": "PJ",
  "Razão Social/Nome": "X-LEVEL CONSULTING TECH LTDA",
  "Nome Fantasia/Apelido": "X-LEVEL",
  "CNPJ": "45.675.325/0001-02",
  "CNAE Principal": "71.12-0-00",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "MG",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 392,
  "Tipo": "PJ",
  "Razão Social/Nome": "SERV MAIS SERVICOS DE LIMPEZA EIRELI",
  "Nome Fantasia/Apelido": "SERV MAIS ",
  "CNPJ": " 01.438.073/0001-22",
  "CNAE Principal": "81.21-4-00 ",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "PE",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 393,
  "Tipo": "PJ",
  "Razão Social/Nome": "LEANDRO BARBOSA DA SILVA DE ANDORINHA",
  "Nome Fantasia/Apelido": "AREAL BARBOSA",
  "CNPJ": "35.749.797/0001-09",
  "CNAE Principal": " 0810-0/06",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "BA",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 394,
  "Tipo": "PJ",
  "Razão Social/Nome": "CONTRATTO PERICIAS, LAUDOS E PROJETOS LTDA",
  "Nome Fantasia/Apelido": "CONTRATTO PERICIAS, LAUDOS E PROJETOS LTDA",
  "CNPJ": "11.061.768/0001-91",
  "CNAE Principal": "7112-0/00",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 395,
  "Tipo": "PJ",
  "Razão Social/Nome": "A2A CONSTRUCAO E LOCACAO EIRELI",
  "Nome Fantasia/Apelido": "SD FOLHADOS E SEMI JOIAS",
  "CNPJ": "30.589.612/0001-22",
  "CNAE Principal": "46.47-8-01",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "SE",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 396,
  "Tipo": "PJ",
  "Razão Social/Nome": "PAREX ENGENHARIA S.A.",
  "Nome Fantasia/Apelido": "PAREX ENGENHARIA S.A.",
  "CNPJ": " 00.532.740/0001-79",
  "CNAE Principal": "42.92-8-02",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 397,
  "Tipo": "PJ",
  "Razão Social/Nome": "THIAGO PINHO DOS SANTOS",
  "Nome Fantasia/Apelido": "LABORATORIO UMBRELLA",
  "CNPJ": "19.948.012/0001-70",
  "CNAE Principal": "8640-2/02",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "MT",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 398,
  "Tipo": "PJ",
  "Razão Social/Nome": "ENGEGOLD MINERACAO LTDA",
  "Nome Fantasia/Apelido": "ENGEGOLD MINERACAO",
  "CNPJ": "19.078.333/0002-42",
  "CNAE Principal": " 0724-3/01",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 399,
  "Tipo": "PJ",
  "Razão Social/Nome": "SOCIEDADE BENEFICENTE SAO JUDAS TADEU",
  "Nome Fantasia/Apelido": "SOCIEDADE BENEFICENTE SAO JUDAS TADEU",
  "CNPJ": "40.995.874/0001-40",
  "CNAE Principal": "94.99-5-00",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "MS",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 400,
  "Tipo": "PJ",
  "Razão Social/Nome": "TRANSLUT - TRANSPORTES E AGENCIAMENTO DE CARGAS LTDA",
  "Nome Fantasia/Apelido": "TRANSLUT - TRANSPORTES E AGENCIAMENTO DE CARGAS LTDA",
  "CNPJ": "28.315.591/0001-05",
  "CNAE Principal": "4930-2/02",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "MG",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 401,
  "Tipo": "PJ",
  "Razão Social/Nome": "LAR DO SOSSEGO CASA DE REPOUSO LTDA",
  "Nome Fantasia/Apelido": "LAR DO SOSSEGO CASA DE REPOUSO",
  "CNPJ": "23.327.998/0001-39",
  "CNAE Principal": "8711-5/02",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "MG",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 402,
  "Tipo": "PJ",
  "Razão Social/Nome": "FITCORP ASSESSORIA ESPORTIVA LTDA",
  "Nome Fantasia/Apelido": "FITCORP SAUDE CORPORATIVA",
  "CNPJ": " 07.773.783/0001-95",
  "CNAE Principal": "9319-1/01",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "SP",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 403,
  "Tipo": "PJ",
  "Razão Social/Nome": "GENIOS STEEL COMERCIO DE EQUIPAMENTOS EM ACO INOXIDAVEL LTDA",
  "Nome Fantasia/Apelido": "G STEEL EQUIPAMENTOS INDUSTRIAIS",
  "CNPJ": "42.955.259/0001-36",
  "CNAE Principal": "46.63-0-00 ",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 404,
  "Tipo": "PJ",
  "Razão Social/Nome": "EVALDO DA SILVA CONSTRUCOES EIRELI",
  "Nome Fantasia/Apelido": "ESPERANCA CONSTRUCOES",
  "CNPJ": "27.904.818/0001-95",
  "CNAE Principal": "4120-4/00",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "MA",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 405,
  "Tipo": "PJ",
  "Razão Social/Nome": "LEROMA TRANSPORTES LTDA",
  "CNPJ": "32.023.969/0001-74",
  "CNAE Principal": "49.30-2-02 ",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 406,
  "Tipo": "PJ",
  "Razão Social/Nome": "LUIS FELIPE BOFILL CHUY",
  "Nome Fantasia/Apelido": "LUIS FELIPE BOFILL CHUY",
  "CNPJ": "29.790.476/0001-55",
  "CNAE Principal": "6203-1/00",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "RS",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 407,
  "Tipo": "PJ",
  "Razão Social/Nome": "APECOL ENGENHARIA E CONSTRUCOES LTDA",
  "CNPJ": "59.928.937/0001-74",
  "CNAE Principal": "4120-4/00",
  "Região": 2,
  "Situação": "Ativa",
  "Estado": "SP",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 408,
  "Tipo": "PJ",
  "Razão Social/Nome": "AGROPECUARIA  COLASSIOL  LTDA",
  "Nome Fantasia/Apelido": "AGROPECUARIA  COLASSIOL  LTDA",
  "CNPJ": "28.812.023/0003-81",
  "CNAE Principal": " 0151-2/01",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 409,
  "Tipo": "PJ",
  "Razão Social/Nome": "G J DE MIRANDA PONTES CONSTRUÇÕES",
  "Nome Fantasia/Apelido": "ENGENHARIA DELTA",
  "CNPJ": "97.529.802/0001-25",
  "CNAE Principal": "33.21-0-00 ",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 410,
  "Tipo": "PJ",
  "Razão Social/Nome": "G J DE MIRANDA PONTES CONSTRUCOES",
  "Nome Fantasia/Apelido": "ENGENHARIA DELTA",
  "CNPJ": "97.529.802/0001-25",
  "CNAE Principal": "33.21-0-00",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 411,
  "Tipo": "PJ",
  "Razão Social/Nome": "LG EMPREENDIMENTOS EIRELI",
  "Nome Fantasia/Apelido": "LG EMPREENDIMENTO",
  "CNPJ": "29.289.024/0001-94",
  "CNAE Principal": "49.24-8-00 ",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "BA",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 412,
  "Tipo": "PJ",
  "Razão Social/Nome": "PREVIBRAS SOLUCOES INDUSTRIAIS EIRELI",
  "Nome Fantasia/Apelido": "PREVENCAO BRASIL",
  "CNPJ": "12.947.501/0001-69",
  "CNAE Principal": "4322-3/03",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "RS",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 413,
  "Tipo": "PJ",
  "Razão Social/Nome": "ANA IRIS SOUZA RODRIGUES 04408553140",
  "Nome Fantasia/Apelido": "ELETRON",
  "CNPJ": "42.656.592/0001-44",
  "CNAE Principal": "43.21-5-00 ",
  "Região": 28,
  "Situação": "Ativa",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 414,
  "Tipo": "PJ",
  "Razão Social/Nome": "LUIZ A. ARCE XIMENES EIRELI",
  "Nome Fantasia/Apelido": "CONSTRUTORA XIMENES",
  "CNPJ": "34.374.052/0001-40",
  "CNAE Principal": "4120-4/00",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "MT",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 415,
  "Tipo": "PJ",
  "Razão Social/Nome": "OSMIKI - TRANSPORTE, IND. E COM. DE RECICLAVEIS LTDA",
  "Nome Fantasia/Apelido": "OSMIKI TRANSPORTES",
  "CNPJ": " 08.883.353/0001-99",
  "CNAE Principal": "4930-2/01",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 416,
  "Tipo": "PJ",
  "Razão Social/Nome": "HOSPMED COMERCIO EIRELI",
  "Nome Fantasia/Apelido": "HOSPMED BH",
  "CNPJ": "18.224.182/0001-40",
  "CNAE Principal": "4664-8/00",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "MG",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 417,
  "Tipo": "PJ",
  "Razão Social/Nome": "OSMIK REPRESENTACOES DE MOVEIS LTDA",
  "Nome Fantasia/Apelido": "OSMIK REPRESENTACOES",
  "CNPJ": " 00.867.433/0001-49",
  "CNAE Principal": "4615-0/00",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 418,
  "Tipo": "PJ",
  "Razão Social/Nome": "M&K INSTALACOES E PROJETOS EIRELI",
  "Nome Fantasia/Apelido": "M&K INSTALACOES E PROJETOS",
  "CNPJ": "23.359.552/0001-96",
  "CNAE Principal": "4321-5/00",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "MG",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 419,
  "Tipo": "PJ",
  "Razão Social/Nome": "OSMIKI TRANSPORTES LTDA",
  "Nome Fantasia/Apelido": "OSMIKI TRANSPORTES",
  "CNPJ": "11.488.036/0001-82",
  "CNAE Principal": "4930-2/02",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "GO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 420,
  "Tipo": "PJ",
  "Razão Social/Nome": "KLIMAT SISTEMAS DE CLIMATIZACAO EIRELI",
  "CNPJ": "33.074.190/0001-40",
  "CNAE Principal": "4322-3/02",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "PR",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 421,
  "Tipo": "PJ",
  "Razão Social/Nome": "MAURICIO FAUSTO PASQUINI ZANI - ME",
  "Nome Fantasia/Apelido": "MZANI DO BRASIL",
  "CNPJ": "28.358.378/0001-80",
  "CNAE Principal": "3312-1/02",
  "Região": 6,
  "Situação": "Ativa",
  "Estado": "SP",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 422,
  "Tipo": "PJ",
  "Razão Social/Nome": "RIOMOR COMERCIO DE MAQUINAS E EQUIPAMENTOS LTDA",
  "Nome Fantasia/Apelido": "RIOMOR NAVAL",
  "CNPJ": "34.534.648/0001-60",
  "CNAE Principal": "47.89-0-99 ",
  "Região": "0",
  "Situação": "Ativa",
  "Estado": "AM",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 423,
  "Tipo": "PJ",
  "Razão Social/Nome": "FURLAN CONTROL ELETRICA E SERVICOS LTDA",
  "CNPJ": " 05.790.553/0001-09",
  "CNAE Principal": "4744-0/03",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "10/06/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 424,
  "Tipo": "PJ",
  "Razão Social/Nome": "LP FRASTON LOGISTICA LTDA",
  "Nome Fantasia/Apelido": "OSMIKI TRANSPORTES",
  "CNPJ": "42.801.857/0001-50",
  "CNAE Principal": "49.30-2-02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "13/06/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 425,
  "Tipo": "PF",
  "Razão Social/Nome": "CLÍNICA ODONTOLÓGICA VITALLE",
  "Nome Fantasia/Apelido": "CLÍNICA ODONTOLÓGICA VITALLE",
  "CPF": " 016.732.991-05",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "15/06/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 426,
  "Tipo": "PJ",
  "Razão Social/Nome": "HAMMES PAPELARIA E INFORMATICA LTDA",
  "Nome Fantasia/Apelido": "HAMMAS LOCACOES",
  "CNPJ": "10.248.224/0001-70",
  "CNAE Principal": "4761-0/03",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "15/06/2022",
  "Estado": "SC",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 427,
  "Tipo": "PJ",
  "Razão Social/Nome": "FROES CONSTRUCOES E MANUTENCAO EIRELI",
  "Nome Fantasia/Apelido": "FROES CONSTRUCOES E MANUTENCAO EIRELI",
  "CNPJ": "34.479.074/0001-74",
  "CNAE Principal": "4221-9/04",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "17/06/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 428,
  "Tipo": "PF",
  "Razão Social/Nome": "EDUARDO FERNANDEZ BERNI",
  "Nome Fantasia/Apelido": "EDUARDO FERNANDEZ BERNI",
  "CPF": "533.581.631-72",
  "CNAE Principal": 115600,
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "20/06/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 429,
  "Tipo": "PF",
  "Razão Social/Nome": "CLAUDIO FERNANDEZ BERNI",
  "Nome Fantasia/Apelido": "CLAUDIO FERNANDEZ BERNI",
  "CPF": "508.742.331-00",
  "CNAE Principal": 115600,
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "21/06/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 430,
  "Tipo": "PJ",
  "Razão Social/Nome": "MULTSERVICE LTDA",
  "Nome Fantasia/Apelido": "MULT SERVICE",
  "CNPJ": "42.452.160/0001-11",
  "CNAE Principal": "43.21-5-00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "21/06/2022",
  "Estado": "PI",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 431,
  "Tipo": "PJ",
  "Razão Social/Nome": "F B E SA",
  "Nome Fantasia/Apelido": "F B E SA",
  "CNPJ": "45.698.616/0001-16",
  "CNAE Principal": "41.20-4-00 ",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "22/06/2022",
  "Estado": "CE",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 432,
  "Tipo": "PJ",
  "Razão Social/Nome": "JASON SERVICOS MARTITIMOS LTDA",
  "Nome Fantasia/Apelido": "JASON SERVICOS MARITIMOS",
  "CNPJ": "44.334.955/0001-50",
  "CNAE Principal": " 09.10-6-00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "22/06/2022",
  "Estado": "RJ",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 433,
  "Tipo": "PJ",
  "Razão Social/Nome": "JOAO PAULO FREITAS LOPES",
  "Nome Fantasia/Apelido": "JP AUTOMATION",
  "CNPJ": "40.987.668/0001-99",
  "CNAE Principal": "43.21-5-00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "22/06/2022",
  "Estado": "CE",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 434,
  "Tipo": "PJ",
  "Razão Social/Nome": "AXXIS ARQUITETURA E DESIGN LTDA",
  "Nome Fantasia/Apelido": "AXXIS ARQUITETURA",
  "CNPJ": "36.456.198/0001-60",
  "CNAE Principal": "7111-1/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "23/06/2022",
  "Estado": "RS",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 435,
  "Tipo": "PJ",
  "Razão Social/Nome": "LIDER ACABAMENTO EM MADEIRAS LTDA",
  "Nome Fantasia/Apelido": "EMPRESA LIDER",
  "CNPJ": " 05.808.567/0001-02",
  "CNAE Principal": "4330-4/05",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "24/06/2022",
  "Estado": "RS",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 436,
  "Tipo": "PJ",
  "Razão Social/Nome": "RESTAURANTE E POUSADA ROSA DE FOGO LTDA",
  "Nome Fantasia/Apelido": "RESTAURANTE ROSA DE FOGO (PEIXE)",
  "CNPJ": " 05.400.449/0001-60",
  "CNAE Principal": "5611-2/01",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "26/06/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 437,
  "Tipo": "PJ",
  "Razão Social/Nome": "ENGMAG ENGENHARIA ELETRICA E MANUTENCOES LTDA",
  "Nome Fantasia/Apelido": "ENGMAG ENGENHARIA ELETRICA E MANUTENCOES",
  "CNPJ": "21.901.099/0001-72",
  "CNAE Principal": "3321-0/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "27/06/2022",
  "Estado": "PR",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 438,
  "Tipo": "PJ",
  "Razão Social/Nome": "ST PRESTADORA DE SERVICOS LTDA",
  "Nome Fantasia/Apelido": "ST PRESTADORA DE SERVICOS LTDA",
  "CNPJ": "41.050.070/0001-31",
  "CNAE Principal": "81.11-7-00",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "27/06/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 439,
  "Tipo": "PJ",
  "Razão Social/Nome": "INOVA MAIS PRESTACOES DE SERVICOS EIRELI",
  "Nome Fantasia/Apelido": "INOVA MAIS",
  "CNPJ": "30.964.675/0001-11",
  "CNAE Principal": "8121-4/00",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "27/06/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 440,
  "Tipo": "PJ",
  "Razão Social/Nome": "BMC MAQUINAS E EQUIPAMENTOS PESADOS S.A",
  "Nome Fantasia/Apelido": "BMC HYUNDAI S.A",
  "CNPJ": "14.168.536/0008-00",
  "CNAE Principal": "4789-0/99",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "28/06/2022",
  "Estado": "GO",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 441,
  "Tipo": "PJ",
  "Razão Social/Nome": "ZAC SERVICOS DE CONSTRUCOES E MONTAGEM DE ANDAIME LTDA",
  "CNPJ": "20.551.460/0001-15",
  "CNAE Principal": "25.39-0-01",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "28/06/2022",
  "Estado": "RJ",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 442,
  "Tipo": "PJ",
  "Razão Social/Nome": "A R SILVA SOLUCOES EM ENERGIA LTDA",
  "Nome Fantasia/Apelido": "A R SILVA SOLUCOES EM ENERGIA",
  "CNPJ": "19.348.871/0001-29",
  "CNAE Principal": "4321-5/00",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "28/06/2022",
  "Estado": "MT",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 443,
  "Tipo": "PJ",
  "Razão Social/Nome": "P.MELO DA SILVA LTDA",
  "Nome Fantasia/Apelido": "DOUTOR FARMA",
  "CNPJ": "45.531.558/0001-31",
  "CNAE Principal": "47.71-7-01 ",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "28/06/2022",
  "Estado": "PA",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 444,
  "Tipo": "PJ",
  "Razão Social/Nome": "VERTICAL SERVICOS METROLOGICOS LTDA",
  "Nome Fantasia/Apelido": "VERTICAL METROLOGIA",
  "CNPJ": "33.099.269/0001-26",
  "CNAE Principal": "3312-1/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "28/06/2022",
  "Estado": "BA",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 446,
  "Tipo": "PJ",
  "Razão Social/Nome": "ISABELLE FRANCINE FRADE DINIZ 13483255699",
  "Nome Fantasia/Apelido": "ISABELLE FRANCINE FRADE DINIZ",
  "CNPJ": "46.556.507/0001-27",
  "CNAE Principal": "58.19-1-00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "29/06/2022",
  "Estado": "MG",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 447,
  "Tipo": "PJ",
  "Razão Social/Nome": "RC DE ARAUJO SERVICOS DE TECNOLOGIA LTDA",
  "Nome Fantasia/Apelido": "FOCCO TECNOLOGIA",
  "CNPJ": "37.713.929/0001-78",
  "CNAE Principal": "4321-5/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "29/06/2022",
  "Estado": "SP",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 448,
  "Tipo": "PJ",
  "Razão Social/Nome": "JM BRASIL TRANSPORTES LTDA",
  "Nome Fantasia/Apelido": "JM BRASIL TRANSPORTES LTDA",
  "CNPJ": "42.107.277/0001-68",
  "CNAE Principal": "82.11-3-00 ",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "29/06/2022",
  "Estado": "MG",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 449,
  "Tipo": "PJ",
  "Razão Social/Nome": "ANNE REBECA WENZEL VALLADARES SOMENSI 41074012860",
  "Nome Fantasia/Apelido": "VISITA.ONLINE",
  "CNPJ": "37.238.910/0001-17",
  "CNAE Principal": "71.12-0-00 ",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "29/06/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 450,
  "Tipo": "PJ",
  "Razão Social/Nome": "GERCAL SISTEMAS CONSTRUTIVOS EIRELI",
  "Nome Fantasia/Apelido": "GERCAL SISTEMAS CONSTRUTIVOS",
  "CNPJ": "24.773.522/0001-94",
  "CNAE Principal": "4329-1/99",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "30/06/2022",
  "Estado": "SP",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 451,
  "Tipo": "PJ",
  "Razão Social/Nome": "ROSSANA LIMA DO NASCIMENTO 03362577407",
  "Nome Fantasia/Apelido": "JD METALURGICA E COMUNICACAO VISUAL",
  "CNPJ": "46.613.850/0001-66",
  "CNAE Principal": "18.13-0-01 ",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "30/06/2022",
  "Estado": "PB",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 455,
  "Tipo": "PJ",
  "Razão Social/Nome": "PREFERENCIAL ENGENHARIA LTDA",
  "Nome Fantasia/Apelido": "PREFERENCIAL CONSTRUCOES E REFORMAS",
  "CNPJ": "14.382.195/0001-96",
  "CNAE Principal": "71.12-0-00 ",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "30/06/2022",
  "Estado": "MG",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 456,
  "Tipo": "PJ",
  "Razão Social/Nome": "A. FAURE CLINICA",
  "Nome Fantasia/Apelido": "BRO - BOICUCANGA RADIOLOGIA ODONTOLOGICA",
  "CNPJ": "28.820.232/0001-05",
  "CNAE Principal": "8640-2/05",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "30/06/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 457,
  "Tipo": "PJ",
  "Razão Social/Nome": "SAMARA HOLANDA ARAUJO",
  "Nome Fantasia/Apelido": "N S MONTAGENS",
  "CNPJ": "41.846.994/0001-49",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "30/06/2022",
  "Estado": "PE",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 458,
  "Tipo": "PJ",
  "Razão Social/Nome": "D A DE OLIVEIRA SOLUCOES SUSTENTAVEIS",
  "Nome Fantasia/Apelido": "ECO GREEN HOME",
  "CNPJ": "35.737.372/0001-80",
  "CNAE Principal": "4742-3/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "01/07/2022",
  "Estado": "SP",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 459,
  "Tipo": "PJ",
  "Razão Social/Nome": "LEONARDO DA SILVA PESUTTI 40343349833",
  "Nome Fantasia/Apelido": "M.B.L ENHENHARIA",
  "CNPJ": "44.491.972/0001-00",
  "CNAE Principal": "43.22-3-02 ",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "01/07/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 461,
  "Tipo": "PJ",
  "Razão Social/Nome": "MCL TRANSPORTES LTDA",
  "Nome Fantasia/Apelido": "MCL TRANSPORTES",
  "CNPJ": "39.781.830/0001-39",
  "CNAE Principal": "5240-1/99",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "04/07/2022",
  "Estado": "GO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 462,
  "Tipo": "PJ",
  "Razão Social/Nome": "THIAGO NASCIMENTO LINO LEAO 06300044637",
  "Nome Fantasia/Apelido": "THYS SERVICOS",
  "CNPJ": "13.575.739/0001-73",
  "CNAE Principal": "4742-3/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "04/07/2022",
  "Estado": "MG",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 463,
  "Tipo": "PJ",
  "Razão Social/Nome": "C GALATI LTDA",
  "Nome Fantasia/Apelido": "CHIP TECNOLOGIA",
  "CNPJ": " 06.556.008/0001-15",
  "CNAE Principal": "62.09-1-00 ",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "04/07/2022",
  "Estado": "AM",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 464,
  "Tipo": "PJ",
  "Razão Social/Nome": "E. M. DARIVA ENGENHARIA E TERRAPLENAGEM LTDA",
  "Nome Fantasia/Apelido": "ARENORTE TERRAPLENAGEM E CONSTRUCAO",
  "CNPJ": " 09.159.629/0001-53",
  "CNAE Principal": "7112-0/00",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "05/07/2022",
  "Estado": "PA",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 465,
  "Tipo": "PJ",
  "Razão Social/Nome": "DROGARIASWFARMA COMERCIO VAREJISTA DE MEDICAMENTOS LIMITADA",
  "Nome Fantasia/Apelido": "DROGARIA BIFARMA",
  "CNPJ": "40.591.131/0001-05",
  "CNAE Principal": "47.71-7",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "05/07/2022",
  "Estado": "SP",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 466,
  "Tipo": "PJ",
  "Razão Social/Nome": "M & N PRODUTOS FARMACEUTICOS LTDA",
  "Nome Fantasia/Apelido": "M & N PRODUTOS FARMACEUTICOS LTDA",
  "CNPJ": "46.235.084/0001-43",
  "CNAE Principal": "47.71-7-01",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "05/07/2022",
  "Estado": "SP",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 467,
  "Tipo": "PJ",
  "Razão Social/Nome": "SWFARMA II COMERCIO DE PRODUTOS FARMACEUTICOS LTDA",
  "Nome Fantasia/Apelido": "SWFARMA II COMERCIO DE PRODUTOS FARMACEUTICOS LTDA",
  "CNPJ": "45.970.219/0001-51",
  "CNAE Principal": "47.71-7-01",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "05/07/2022",
  "Estado": "SP",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 468,
  "Tipo": "PJ",
  "Razão Social/Nome": "RPVAL EQUIPAMENTOS E MANUTENCOES INDUSTRIAIS EIRELI",
  "Nome Fantasia/Apelido": "RPVAL EQUIPAMENTOS E MANUTENCOES",
  "CNPJ": "38.152.316/0001-71",
  "CNAE Principal": "33.14-7-03",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "05/07/2022",
  "Estado": "RJ",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 469,
  "Tipo": "PJ",
  "Razão Social/Nome": "ESTRUTURA CONSTRUCOES E EMPREENDIMENTOS EIRELI",
  "Nome Fantasia/Apelido": "LAVINIA CONSTRUCOES E EMPREENDIMENTOS",
  "CNPJ": "15.246.908/0001-57",
  "CNAE Principal": "4120-4/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "05/07/2022",
  "Estado": "BA",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 470,
  "Tipo": "PJ",
  "Razão Social/Nome": "CANAL 3 SERVICOS DE ELETRICA LTDA",
  "Nome Fantasia/Apelido": "CANAL 3 SERVICOS DE ELETRICA LTDA - ME",
  "CNPJ": "15.599.234/0001-74",
  "CNAE Principal": "4321-5/00",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "06/07/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 471,
  "Tipo": "PJ",
  "Razão Social/Nome": "ZELLO - PAISAGISMO E AJARDINAMENTO LTDA",
  "Nome Fantasia/Apelido": "ZELLO - PAISAGISMO & AJARDINAMENTO",
  "CNPJ": "35.903.587/0001-23",
  "CNAE Principal": "81.11-7-00",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "06/07/2022",
  "Estado": "PR",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 472,
  "Tipo": "PJ",
  "Razão Social/Nome": "STROHMEIER & NOVOA SERVICOS MEDICOS S/S",
  "CNPJ": "19.079.543/0001-74",
  "CNAE Principal": "8630-5/99",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "07/07/2022",
  "Estado": "SP",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 473,
  "Tipo": "PJ",
  "Razão Social/Nome": "CENTRAL DOS CATALISADORES LTDA",
  "Nome Fantasia/Apelido": "CENTRAL DOS CATALISADORES",
  "CNPJ": "18.998.684/0001-28",
  "CNAE Principal": "38.31-9-99 ",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "07/07/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 474,
  "Tipo": "PJ",
  "Razão Social/Nome": "VRC CONSTRUCOES LTDA",
  "CNPJ": "40.812.161/0001-02",
  "CNAE Principal": "41.20-4-00",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "07/07/2022",
  "Estado": "SP",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 475,
  "Tipo": "PJ",
  "Razão Social/Nome": "CONSTRUPAR CONSTRUCOES LTDA",
  "Nome Fantasia/Apelido": "ESTACAO GARDEN PAISAGISMO E AJARDINAMENTO",
  "CNPJ": " 03.096.398/0001-08",
  "CNAE Principal": "41.20-4-00 ",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "07/07/2022",
  "Estado": "PR",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 476,
  "Tipo": "PJ",
  "Razão Social/Nome": "VMTECH SEGURANCA & TECNOLOGIA LTDA",
  "Nome Fantasia/Apelido": "VMTECH SEGURANCA ",
  "CNPJ": "12.372.604/0001-48",
  "CNAE Principal": "80.20-0-01 ",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "07/07/2022",
  "Estado": "CE",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 477,
  "Tipo": "PJ",
  "Razão Social/Nome": "E. CELUPPI CONSTRUTORA E INCORPORADORA LTDA",
  "Nome Fantasia/Apelido": "THOPO CONSTRUTORA E INCORPORADORA DE IMOVEIS LTDA",
  "CNPJ": "38.541.268/0001-03",
  "CNAE Principal": "4110-7/00",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "08/07/2022",
  "Estado": "PR",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 478,
  "Tipo": "PJ",
  "Razão Social/Nome": "PHYTO - CONSULTORIA EM ENGENHARIA E MEIO AMBIENTE LTDA",
  "Nome Fantasia/Apelido": "PHYTO ENGENHARIA",
  "CNPJ": "11.861.373/0001-73",
  "CNAE Principal": "7112-0/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "08/07/2022",
  "Estado": "AL",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 479,
  "Tipo": "PJ",
  "Razão Social/Nome": "MACHINE ELEVATOR LTDA",
  "Nome Fantasia/Apelido": "MACHINE ELEVATOR",
  "CNPJ": "28.030.792/0001-66",
  "CNAE Principal": "3321-0/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "08/07/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 480,
  "Tipo": "PJ",
  "Razão Social/Nome": "RAFAEL MOCELIN",
  "Nome Fantasia/Apelido": "RAFAEL MOCELIN",
  "CNPJ": "24.399.431/0001-30",
  "CNAE Principal": "4120-4/00",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "08/07/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 481,
  "Tipo": "PJ",
  "Razão Social/Nome": "VELOEX LOGISTICA E TRANSPORTES DE CARGAS LTDA",
  "Nome Fantasia/Apelido": "VELOEX LOGISTICA E TRANSPORTES DE CARGAS LTDA",
  "CNPJ": "18.485.555/0007-21",
  "CNAE Principal": "49.30-2-01 -",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "09/07/2022",
  "Estado": "PR",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 482,
  "Tipo": "PJ",
  "Razão Social/Nome": "BRUNO D ALMEIDA CASTRO LTDA",
  "Nome Fantasia/Apelido": "ENGEBRAL SISTEMAS INTEGRADOS",
  "CNPJ": "41.262.026/0001-95",
  "CNAE Principal": "71.12-0-00 ",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "12/07/2022",
  "Estado": "SE",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 483,
  "Tipo": "PJ",
  "Razão Social/Nome": "FRANCISCO DE ASSIS DE SANTANA",
  "Nome Fantasia/Apelido": "MOLAS KELLY",
  "CNPJ": " 03.895.401/0001-45",
  "CNAE Principal": "4520-0/01",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "15/12/2022",
  "Estado": "PA",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 484,
  "Tipo": "PJ",
  "Razão Social/Nome": "CARTORIO DO SEGUNDO OFICIO TABELIONATO DE NOTAS DO JUIZO DE VILA VELHA",
  "Nome Fantasia/Apelido": "CARTORIO DO SEGUNDO OFICIO",
  "CNPJ": "13.691.396/0001-02",
  "CNAE Principal": "6912-5/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "12/07/2022",
  "Estado": "ES",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 485,
  "Tipo": "PJ",
  "Razão Social/Nome": "MARCOS GROSS DE OLIVEIRA",
  "Nome Fantasia/Apelido": "CONSET INFORMATICA",
  "CNPJ": "30.134.857/0001-65",
  "CNAE Principal": "9511-8/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "13/07/2022",
  "Estado": "RS",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 486,
  "Tipo": "PJ",
  "Razão Social/Nome": "TIAGO SANTOS MIRANDA 00063527235",
  "Nome Fantasia/Apelido": "TM ELEVADORES",
  "CNPJ": "41.856.863/0001-42",
  "CNAE Principal": "43.29-1-03",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "13/07/2022",
  "Estado": "PA",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 487,
  "Tipo": "PJ",
  "Razão Social/Nome": "DP ESTRUTURAS METALICAS EIRELI",
  "Nome Fantasia/Apelido": "DP ESTRUTURAS METALICAS EIRELI",
  "CNPJ": " 08.621.601/0001-23",
  "CNAE Principal": "25.12-8-00 ",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "13/07/2022",
  "Estado": "PR",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 488,
  "Tipo": "PJ",
  "Razão Social/Nome": "ADAIRTON CANDIDO BONFIM ALARMES",
  "Nome Fantasia/Apelido": "ON FIRE ENGENHARIA",
  "CNPJ": "30.999.169/0001-68",
  "CNAE Principal": "4322-3/03",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "18/07/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 489,
  "Tipo": "PJ",
  "Razão Social/Nome": "CONSTRUTORA E INSTALADORA PEDRA ANGULAR LTDA",
  "Nome Fantasia/Apelido": "CONSTRUTORA E INSTALADORA PEDRA ANGULAR",
  "CNPJ": "10.807.299/0001-44",
  "CNAE Principal": "4322-3/01",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "18/07/2022",
  "Estado": "TO",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 490,
  "Tipo": "PJ",
  "Razão Social/Nome": "ALPEC INDUSTRIA COMERCIO IMPORTACAO E EXPORTACAO DE ARTEFATOS EM ACO LTDA",
  "Nome Fantasia/Apelido": "ALPEC INOXIDAVEL",
  "CNPJ": "31.406.285/0001-99",
  "CNAE Principal": "7490-1/04",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "27/03/2023",
  "Estado": "MG",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 491,
  "Tipo": "PJ",
  "Razão Social/Nome": "YURI MORESCO DE OLIVEIRA SERVICOS MEDICOS LTDA",
  "Nome Fantasia/Apelido": "CLINICA MORESCO - CIRURGIA PLASTICA",
  "CNPJ": "40.662.028/0001-09",
  "CNAE Principal": "86.30-5-03 ",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "19/07/2022",
  "Estado": "PR",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 492,
  "Tipo": "PJ",
  "Razão Social/Nome": "GILSIMAR RIBEIRO DA COSTA",
  "Nome Fantasia/Apelido": "SOLAR BRASIL",
  "CNPJ": "12.448.537/0001-06",
  "CNAE Principal": "47.42-3-00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "19/07/2022",
  "Estado": "GO",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 493,
  "Tipo": "PJ",
  "Razão Social/Nome": "JOSE RICARDO FROTA VASCONCELOS 68564147491",
  "Nome Fantasia/Apelido": "NEW LIFE - SOLUCOES PARA PISOS",
  "CNPJ": "15.411.851/0001-02",
  "CNAE Principal": "2391-5/03",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "19/07/2022",
  "Estado": "PE",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 494,
  "Tipo": "PJ",
  "Razão Social/Nome": "CROSSFIRE PROTECTION - SISTEMA DE PREVENCAO CONTRA INCENDIO EIRELI",
  "Nome Fantasia/Apelido": "CROSSFIRE PROTECTION",
  "CNPJ": "22.130.107/0001-97",
  "CNAE Principal": "4322-3/03",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "19/07/2022",
  "Estado": "PR",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 495,
  "Tipo": "PJ",
  "Razão Social/Nome": "E R RAMOS",
  "Nome Fantasia/Apelido": "PONTO FRIO REFRIGERACAO",
  "CNPJ": "17.113.163/0001-83",
  "CNAE Principal": "43.22-3-02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "19/07/2022",
  "Estado": "TO",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 496,
  "Tipo": "PJ",
  "Razão Social/Nome": "J & A OBRA DE MONTAGEM INDUSTRIAL LTDA",
  "Nome Fantasia/Apelido": "J & A",
  "CNPJ": "13.956.268/0001-43",
  "CNAE Principal": "4292-8/02",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "20/07/2022",
  "Estado": "MG",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 497,
  "Tipo": "PJ",
  "Razão Social/Nome": "FABIO HENRIQUE DA SILVA 39224106854",
  "Nome Fantasia/Apelido": "FSCLIM",
  "CNPJ": "35.559.568/0001-21",
  "CNAE Principal": "4322-3/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "20/07/2022",
  "Estado": "SP",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 498,
  "Tipo": "PJ",
  "Razão Social/Nome": "J.  S. J. PERFURACAO SONDAGENS E ESTAQUIAMENTO LTDA",
  "Nome Fantasia/Apelido": "JS SONDAGEM SOLOS E TOPOGRAFIA",
  "CNPJ": "35.359.622/0001-95",
  "CNAE Principal": "4312-6/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "21/07/2022",
  "Estado": "RJ",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 499,
  "Tipo": "PJ",
  "Razão Social/Nome": "D. N. CAPOBIANCO REVESTIMENTO",
  "Nome Fantasia/Apelido": "TEMARK",
  "CNPJ": " 04.428.105/0001-05",
  "CNAE Principal": "4330-4/01",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "24/07/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 500,
  "Tipo": "PJ",
  "Razão Social/Nome": "GILCLEYDSON XAVIER DE SOUZA - COMUNICACAO VISUAL",
  "Nome Fantasia/Apelido": "GE ARTES ADESIVOS DECORATIVOS",
  "CNPJ": "26.920.598/0001-20",
  "CNAE Principal": "4329-1/01",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "25/07/2022",
  "Estado": "PR",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 501,
  "Tipo": "PJ",
  "Razão Social/Nome": "EM ENGENHARIA LOGISTICA E TRANSPORTES LTDA",
  "Nome Fantasia/Apelido": "EM ENGENHARIA LOGISTICA E TRANSPORTES",
  "CNPJ": "30.620.073/0001-47",
  "CNAE Principal": "7112-0/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "25/07/2022",
  "Estado": "BA",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 502,
  "Tipo": "PJ",
  "Razão Social/Nome": "CPR CONSTRUTORA E SERVICO - EIRELI",
  "Nome Fantasia/Apelido": "CPR CONSTRUCOES",
  "CNPJ": "31.546.910/0001-06",
  "CNAE Principal": "4399-1/03",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "25/07/2022",
  "Estado": "MS",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 503,
  "Tipo": "PJ",
  "Razão Social/Nome": "GERMANO AVALIACOES E INSPECOES INDUSTRIAIS LTDA",
  "Nome Fantasia/Apelido": "GOGERB AVALIACOES E INSPECOES INDUSTRIAIS",
  "CNPJ": "10.525.180/0001-89",
  "CNAE Principal": "8599-6/99",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "25/07/2022",
  "Estado": "RS",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 504,
  "Tipo": "PJ",
  "Razão Social/Nome": "METSON ENGENHARIA E CONSULTORIA EIRELI",
  "Nome Fantasia/Apelido": "METSON ENGENHARIA",
  "CNPJ": "24.331.974/0001-16",
  "CNAE Principal": "7112-0/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "26/07/2022",
  "Estado": "GO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 505,
  "Tipo": "PJ",
  "Razão Social/Nome": "W. A. DO NASCIMENTO",
  "Nome Fantasia/Apelido": "BETINHO MOTOS",
  "CNPJ": "14.136.596/0001-66",
  "CNAE Principal": "4541-2/06",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "27/07/2022",
  "Estado": "MG",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 506,
  "Tipo": "PJ",
  "Razão Social/Nome": "MASSI & MASSI COMERCIO E SERVICOS DE MAQUINAS EIRELI",
  "CNPJ": "12.928.463/0001-05",
  "CNAE Principal": "4789-0/99",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "27/07/2022",
  "Estado": "SP",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 507,
  "Tipo": "PJ",
  "Razão Social/Nome": "PREVENIR - PROTECAO DE AMBIENTES LTDA",
  "CNPJ": "15.706.793/0001-36",
  "CNAE Principal": "4330-4/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "27/07/2022",
  "Estado": "PR",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 508,
  "Tipo": "PJ",
  "Razão Social/Nome": "CLODOALDO TOBIAS DA SILVA & CIA LTDA",
  "Nome Fantasia/Apelido": "CLODOALDO TOBIAS DA SILVA & CIA LTDA",
  "CNPJ": "23.284.360/0001-68",
  "CNAE Principal": "7119-7/01",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "28/07/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 509,
  "Tipo": "PJ",
  "Razão Social/Nome": "LEMKE-RECIFE CONSTRUCOES EIRELI",
  "Nome Fantasia/Apelido": "LEMKE -RECIFE",
  "CNPJ": " 08.888.259/0001-22",
  "CNAE Principal": "4120-4/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "28/07/2022",
  "Estado": "PE",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 510,
  "Tipo": "PJ",
  "Razão Social/Nome": "PROTESA ENGENHARIA LTDA",
  "Nome Fantasia/Apelido": "PROTESA ENGENHARIA",
  "CNPJ": "40.670.110/0001-85",
  "CNAE Principal": "7112-0/00",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "29/07/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 512,
  "Tipo": "PJ",
  "Razão Social/Nome": "RODRIGO CARLOS FRANCO MINOZZI",
  "Nome Fantasia/Apelido": "PREVENÇÃO GERENCIAMENTO DE RESÍDUOS DE SAÚDE",
  "CNPJ": "12.911.712/0001-41",
  "CNAE Principal": "3812-2/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "01/08/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 513,
  "Tipo": "PJ",
  "Razão Social/Nome": "BRASCORTE COMERCIAL DE CORREIAS LTDA.",
  "CNPJ": " 04.763.709/0001-08",
  "CNAE Principal": "4661-3/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "01/08/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 514,
  "Tipo": "PJ",
  "Razão Social/Nome": "AGLOK CONSTRUCOES E LOCACAO DE EQUIPAMENTOS LTDA",
  "Nome Fantasia/Apelido": "AGLOK COMERCIO SERVICOS E LOCACAO",
  "CNPJ": "20.299.942/0001-20",
  "CNAE Principal": "7732-2/01",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "02/08/2022",
  "Estado": "BA",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 515,
  "Tipo": "PJ",
  "Razão Social/Nome": "LOG  RIO TRANSPORTE E TURISMO EIRELI",
  "Nome Fantasia/Apelido": "LOG RIO",
  "CNPJ": "26.198.828/0001-90",
  "CNAE Principal": "4929-9/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "02/08/2022",
  "Estado": "RJ",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 516,
  "Tipo": "PJ",
  "Razão Social/Nome": "ALC - CONSTRUCOES E ARQUITETURA LTDA",
  "Nome Fantasia/Apelido": "ALC CONSTRUCOES E ARQUITETURA",
  "CNPJ": " 09.456.098/0001-60",
  "CNAE Principal": "4120-4/00",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "02/08/2022",
  "Estado": "PA",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 517,
  "Tipo": "PJ",
  "Razão Social/Nome": "HURSA CONSTRUCOES E EDIFICACOES LTDA",
  "Nome Fantasia/Apelido": "CRIARTE ENGENHARIA",
  "CNPJ": "37.447.056/0001-07",
  "CNAE Principal": "41.20-4-00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "05/08/2022",
  "Estado": "MG",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 518,
  "Tipo": "PJ",
  "Razão Social/Nome": "D SORRISO CURUCA - CLINICA ODONTOLOGICA LTDA",
  "CNPJ": "39.832.853/0001-25",
  "CNAE Principal": "8630-5/04",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "05/08/2022",
  "Estado": "SP",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 519,
  "Tipo": "PJ",
  "Razão Social/Nome": "CONSELHO INDIGENISTA MISSIONARIO CIMI",
  "Nome Fantasia/Apelido": "Conselho Indigenista Missionário Regional Goiás/Tocantins",
  "CNPJ": " 00.479.105/0012-28",
  "CNAE Principal": "9491-0/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "05/08/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 520,
  "Tipo": "PJ",
  "Razão Social/Nome": "CONSELHO INDIGENISTA MISSIONARIO CIMI ",
  "Nome Fantasia/Apelido": "CONSELHO INDIGENISTA MISSIONARIO CIMI ",
  "CNPJ": " 00.479.105/0004-18",
  "CNAE Principal": "9491-0/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "05/08/2022",
  "Estado": "MG",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 521,
  "Tipo": "PJ",
  "Razão Social/Nome": "CONSELHO INDIGENISTA MISSIONARIO CIMI ",
  "Nome Fantasia/Apelido": "CONSELHO INDIGENISTA MISSIONARIO CIMI ",
  "CNPJ": " 00.479.105/0006-80",
  "CNAE Principal": "9491-0/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "05/08/2022",
  "Estado": "MA",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 522,
  "Tipo": "PJ",
  "Razão Social/Nome": "CONSELHO INDIGENISTA MISSIONARIO CIMI ",
  "Nome Fantasia/Apelido": "CONSELHO INDIGENISTA MISSIONARIO CIMI ",
  "CNPJ": " 00.479.105/0002-56",
  "CNAE Principal": "9491-0/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "05/08/2022",
  "Estado": "MS",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 523,
  "Tipo": "PJ",
  "Razão Social/Nome": "CONSELHO INDIGENISTA MISSIONARIO CIMI ",
  "Nome Fantasia/Apelido": "CONSELHO INDIGENISTA MISSIONARIO CIMI  ",
  "CNPJ": " 00.479.105/0003-37",
  "CNAE Principal": "9491-0/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "05/08/2022",
  "Estado": "MT",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 524,
  "Tipo": "PJ",
  "Razão Social/Nome": "CONSELHO INDIGENISTA MISSIONARIO CIMI ",
  "Nome Fantasia/Apelido": "CONSELHO INDIGENISTA MISSIONARIO CIMI",
  "CNPJ": " 00.479.105/0007-60",
  "CNAE Principal": "9491-0/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "05/08/2022",
  "Estado": "PE",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 525,
  "Tipo": "PJ",
  "Razão Social/Nome": "CONSELHO INDIGENISTA MISSIONARIO CIMI ",
  "Nome Fantasia/Apelido": "CONSELHO INDIGENISTA MISSIONARIO CIMI ",
  "CNPJ": " 00.479.105/0008-41",
  "CNAE Principal": "9491-0/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "05/08/2022",
  "Estado": "PA",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 526,
  "Tipo": "PJ",
  "Razão Social/Nome": "CONSELHO INDIGENISTA MISSIONARIO CIMI ",
  "Nome Fantasia/Apelido": "CONSELHO INDIGENISTA MISSIONARIO CIMI ",
  "CNPJ": " 00.479.105/0001-75",
  "CNAE Principal": "9491-0/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "05/08/2022",
  "Estado": "DF",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 527,
  "Tipo": "PJ",
  "Razão Social/Nome": "R N F ARAUJO MONTAGEM INDUSTRIAL EIRELI",
  "Nome Fantasia/Apelido": "J A MONTAGEM INDUSTRIAL E FABRICACAO",
  "CNPJ": "22.507.080/0001-09",
  "CNAE Principal": "2511-0/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "08/08/2022",
  "Estado": "PA",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 528,
  "Tipo": "PJ",
  "Razão Social/Nome": "WESLEI APARECIDO DA SILVA",
  "Nome Fantasia/Apelido": "MORATOFARMA",
  "CNPJ": "36.641.928/0001-00",
  "CNAE Principal": "4771-7/01",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "08/08/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 529,
  "Tipo": "PJ",
  "Razão Social/Nome": "GURUSEG FRANCHISING EM SEGURANCA DO TRABALHO LTDA",
  "Nome Fantasia/Apelido": "GURUSEG FRANCHISING",
  "CNPJ": "46.316.746/0001-00",
  "CNAE Principal": "7740-3/00",
  "Região": 5,
  "Situação": "Ativa",
  "Data Cadastro": "09/08/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 530,
  "Tipo": "PJ",
  "Razão Social/Nome": "SUPER FRESH AR CONDICIONADO E CLIMATIZACAO EIRELI",
  "Nome Fantasia/Apelido": "SUPER FRESH AR CONDICIONADO",
  "CNPJ": "28.206.454/0001-32",
  "CNAE Principal": "4322-3/02",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "09/08/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 531,
  "Tipo": "PJ",
  "Razão Social/Nome": "SOMA EXPRESS SERVICOS DE ENTREGAS DE MALOTE LTDA",
  "Nome Fantasia/Apelido": "SOMA EXPRESS",
  "CNPJ": " 06.369.717/0001-91",
  "CNAE Principal": "5320-2/02",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "09/08/2022",
  "Estado": "BA",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 532,
  "Tipo": "PJ",
  "Razão Social/Nome": "STEELENGE CONSTRUCOES ESPECIALIZADAS E FACILITIES EIRELI",
  "Nome Fantasia/Apelido": "STEELENGE EIRELI",
  "CNPJ": "32.419.175/0001-24",
  "CNAE Principal": "4120-4/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "09/08/2022",
  "Estado": "PR",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 533,
  "Tipo": "PJ",
  "Razão Social/Nome": "M. DE ABREU INSTALACOES LTDA",
  "Nome Fantasia/Apelido": "ABREU AMBIENTAL",
  "CNPJ": "18.458.759/0001-88",
  "CNAE Principal": "8129-0/00",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "10/08/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 534,
  "Tipo": "PJ",
  "Razão Social/Nome": "CONNECT INTERNET BANDA LARGA LTDA",
  "Nome Fantasia/Apelido": "CONNECTA",
  "CNPJ": "26.843.237/0001-28",
  "CNAE Principal": "6110-8/03",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "10/08/2022",
  "Estado": "BA",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 535,
  "Tipo": "PJ",
  "Razão Social/Nome": "ZAHDI E PIQUES LTDA",
  "Nome Fantasia/Apelido": "ZAHDI E PIQUES LTDA",
  "CNPJ": " 00.096.572/0001-16",
  "CNAE Principal": "4753-9/00",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "10/08/2022",
  "Estado": "PR",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 536,
  "Tipo": "PJ",
  "Razão Social/Nome": "IZVQ FARM FOODS LTDA",
  "Nome Fantasia/Apelido": "GRUPO IZVQ",
  "CNPJ": "45.017.632/0004-49",
  "CNAE Principal": " 0151-2/01",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "10/08/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 537,
  "Tipo": "PF",
  "Razão Social/Nome": "EDUARDO SCANNAVINO DE QUEIROZ 12",
  "Nome Fantasia/Apelido": "Wanderlândia 12",
  "CPF": "218.987.978-70",
  "CNAE Principal": 151201,
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "10/08/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 538,
  "Tipo": "PJ",
  "Razão Social/Nome": "GOES DE FARIAS FARMACIA DE MANIPULACAO FLORAIS E PRODUTOS NATURAIS LTDA",
  "Nome Fantasia/Apelido": "PINHEIROS BOTICA FARMACIA DE MANIPULACAO FLORAIS",
  "CNPJ": "45.566.017/0001-49",
  "CNAE Principal": "4771-7/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "10/08/2022",
  "Estado": "PA",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 539,
  "Tipo": "PF",
  "Razão Social/Nome": "EDUARDO SCANNAVINO DE QUEIROZ 64",
  "Nome Fantasia/Apelido": "Wanderlândia 64",
  "CPF": "218.987.978-70",
  "CNAE Principal": 151201,
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "10/08/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 542,
  "Tipo": "PF",
  "Razão Social/Nome": "EDUARDO SCANNAVINO DE QUEIROZ 79",
  "Nome Fantasia/Apelido": "Sandolandia 79",
  "CPF": "218.987.978-70",
  "CNAE Principal": 151201,
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "10/08/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 543,
  "Tipo": "PF",
  "Razão Social/Nome": "EDUARDO SCANNAVINO DE QUEIROZ 41",
  "Nome Fantasia/Apelido": "ANANAS 41",
  "CPF": "218.987.978-70",
  "CNAE Principal": 151201,
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "10/08/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 544,
  "Tipo": "PJ",
  "Razão Social/Nome": "CONSELHO INDIGENISTA MISSIONARIO CIMI I",
  "Nome Fantasia/Apelido": "CONSELHO INDIGENISTA MISSIONARIO CIMI I",
  "CNPJ": " 00.479.105/0010-66",
  "CNAE Principal": "9491-0/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "11/08/2022",
  "Estado": "RO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 545,
  "Tipo": "PJ",
  "Razão Social/Nome": "COOPERATIVA DE TRABALHO E SERVICOS AKIRA EVENTOS",
  "Nome Fantasia/Apelido": "COOPERATIVA AKIRA EVENTOS",
  "CNPJ": "45.521.469/0001-04",
  "CNAE Principal": "5620-1/02",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "11/08/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 546,
  "Tipo": "PJ",
  "Razão Social/Nome": "CONSELHO INDIGENISTA MISSIONARIO CIMI",
  "Nome Fantasia/Apelido": "CONSELHO INDIGENISTA MISSIONARIO CIMI",
  "CNPJ": " 00.479.105/0009-22",
  "CNAE Principal": "9491-0/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "11/08/2022",
  "Estado": "AM",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 547,
  "Tipo": "PJ",
  "Razão Social/Nome": "HCR ENGENHARIA LTDA",
  "Nome Fantasia/Apelido": "SAFRA ENGENHARIA",
  "CNPJ": "36.839.597/0001-00",
  "CNAE Principal": "4120-4/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "11/08/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 548,
  "Tipo": "PJ",
  "Razão Social/Nome": "VERTICAL ENGENHARIA CRICIUMA LTDA",
  "Nome Fantasia/Apelido": "VERTICAL ENGENHARIA CRICIUMA",
  "CNPJ": "37.755.151/0001-60",
  "CNAE Principal": "7112-0/00",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "12/08/2022",
  "Estado": "SC",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 549,
  "Tipo": "PJ",
  "Razão Social/Nome": "L C BARBOSA LTDA",
  "Nome Fantasia/Apelido": "BARBOSA TRANSPORTES",
  "CNPJ": "46.704.751/0001-90",
  "CNAE Principal": "49.29-9-01 ",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "12/08/2022",
  "Estado": "MS",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 550,
  "Tipo": "PJ",
  "Razão Social/Nome": "CANTO & ROHDE TRANSPORTES LTDA",
  "Nome Fantasia/Apelido": "CR TRANSPORTES",
  "CNPJ": "13.394.065/0001-00",
  "CNAE Principal": "4930-2/01",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "12/08/2022",
  "Estado": "SC",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 551,
  "Tipo": "PJ",
  "Razão Social/Nome": "ANA CATHARINA CAMARGO BARRETO",
  "Nome Fantasia/Apelido": "HM COMUNICACAO",
  "CNPJ": "32.553.301/0001-39",
  "CNAE Principal": "4399-1/99",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "12/08/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 552,
  "Tipo": "PJ",
  "Razão Social/Nome": "AFLINE INSTALACAO E MANUTENCAO ELETRICA LTDA",
  "Nome Fantasia/Apelido": "AFLINE",
  "CNPJ": "11.446.324/0011-46",
  "CNAE Principal": "6209-1/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "15/08/2024",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 553,
  "Tipo": "PJ",
  "Razão Social/Nome": "M & D CONSERTOS E REF DE MOVEIS DE ESCRITORIOS LTDA",
  "Nome Fantasia/Apelido": "M & D",
  "CNPJ": " 00.459.808/0001-31",
  "CNAE Principal": "9529-1/05",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "16/08/2022",
  "Estado": "RJ",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 554,
  "Tipo": "PJ",
  "Razão Social/Nome": "ROSA DE FOGO - RESTAURANTE E POUSADA LTDA",
  "Nome Fantasia/Apelido": "ROSA DE FOGO - RESTAURANTE E POUSADA LTDA ALMAS",
  "CNPJ": "47.367.338/0001-40",
  "CNAE Principal": "5611-2/01",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "16/08/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 555,
  "Tipo": "PJ",
  "Razão Social/Nome": "DIVERSITA - CONSULTORIA EM ESTUDOS AMBIENTAIS LTDA.",
  "Nome Fantasia/Apelido": "DIVERSITA",
  "CNPJ": "97.519.516/0001-89",
  "CNAE Principal": "7490-1/99",
  "Região": 6,
  "Situação": "Ativa",
  "Data Cadastro": "05/02/2023",
  "Estado": "GO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 556,
  "Tipo": "PJ",
  "Razão Social/Nome": "R L PAIVA TRANSPORTES",
  "Nome Fantasia/Apelido": "TRANSPAIVA",
  "CNPJ": "36.307.730/0002-68",
  "CNAE Principal": "4930-2/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "17/08/2022",
  "Estado": "MG",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 557,
  "Tipo": "PJ",
  "Razão Social/Nome": "NEWTEC SOLUCOES LTDA",
  "Nome Fantasia/Apelido": "NEWTEC",
  "CNPJ": "34.443.600/0001-46",
  "CNAE Principal": "4313-4/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "17/08/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 558,
  "Tipo": "PJ",
  "Razão Social/Nome": "VAGNER L S FERREIRA",
  "Nome Fantasia/Apelido": "JSJ ENGENHARIA CIVIL E ELETRICA",
  "CNPJ": "29.845.604/0001-10",
  "CNAE Principal": "7112-0/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "17/08/2022",
  "Estado": "BA",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 559,
  "Tipo": "PJ",
  "Razão Social/Nome": "ADALBERTO OLIVEIRA DA SILVA 14702509268",
  "Nome Fantasia/Apelido": "O.A.S SERVICE ELEVADORES E ELETRICA",
  "CNPJ": "13.906.252/0001-26",
  "CNAE Principal": "4329-1/03",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "17/08/2022",
  "Estado": "PA",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 560,
  "Tipo": "PJ",
  "Razão Social/Nome": "S E DA SILVA SERVICOS AMBIENTAIS",
  "Nome Fantasia/Apelido": "FAUNALITICA - MONITORAMENTO AMBIENTAL",
  "CNPJ": "31.599.875/0001-85",
  "CNAE Principal": "7490-1/99",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "18/08/2022",
  "Estado": "MT",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 561,
  "Tipo": "PJ",
  "Razão Social/Nome": "ELDORADO REFEICOES LTDA.",
  "Nome Fantasia/Apelido": "ELDORADO REFEICOES",
  "CNPJ": " 02.416.118/0016-07",
  "CNAE Principal": "5620-1/01",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "18/08/2022",
  "Estado": "GO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 562,
  "Tipo": "PJ",
  "Razão Social/Nome": "RB QUALITY PARKING ESTACIONAMENTOS LTDA",
  "Nome Fantasia/Apelido": "RB QUALITY PARKING",
  "CNPJ": "13.755.438/0001-21",
  "CNAE Principal": "5223-1/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "18/08/2022",
  "Estado": "SP",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 563,
  "Tipo": "PJ",
  "Razão Social/Nome": "BMMI TELECOMUNICACOES E SERVICOS LTDA",
  "Nome Fantasia/Apelido": "BMMI TELECOMUNICACOES E SERVICOS",
  "CNPJ": " 05.536.840/0001-97",
  "CNAE Principal": "4221-9/04",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "19/08/2022",
  "Estado": "SE",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 564,
  "Tipo": "PJ",
  "Razão Social/Nome": "JOAO CESAR MACEDO RODRIGUES 05034210142",
  "Nome Fantasia/Apelido": "CBR TRANSPORTE",
  "CNPJ": "46.638.806/0001-00",
  "CNAE Principal": "49.29-9-02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "19/08/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 565,
  "Tipo": "PJ",
  "Razão Social/Nome": "LFJP ALIMENTOS LTDA",
  "Nome Fantasia/Apelido": "O BURGUES ITAIPU",
  "CNPJ": "43.330.837/0001-01",
  "CNAE Principal": "5611-2/03",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "27/09/2024",
  "Estado": "RJ",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 566,
  "Tipo": "PJ",
  "Razão Social/Nome": "SAD PRESTACAO DE SERVICOS NA SAUDE LTDA",
  "Nome Fantasia/Apelido": "PREMIER HOME CARE",
  "CNPJ": "36.255.236/0001-16",
  "CNAE Principal": "8712-3/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "22/08/2022",
  "Estado": "PR",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 567,
  "Tipo": "PJ",
  "Razão Social/Nome": "INEZ QUEIROZ BARBOSA 03927766690",
  "Nome Fantasia/Apelido": "SYSTECH AUTOMACAO",
  "CNPJ": "37.156.202/0001-37",
  "CNAE Principal": "4321-5/00",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "22/08/2022",
  "Estado": "MG",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 568,
  "Tipo": "PJ",
  "Razão Social/Nome": "CIVIL- OBRAS E PROJETOS EIRELI",
  "Nome Fantasia/Apelido": "CIVIL- OBRAS E PROJETOS EIRELI",
  "CNPJ": "40.140.021/0001-27",
  "CNAE Principal": "4120-4/00",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "22/08/2022",
  "Estado": "PA",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 569,
  "Tipo": "PJ",
  "Razão Social/Nome": "ELOMAR EMERSON STEIN",
  "Nome Fantasia/Apelido": "EES MANUTENCAO DE EQUIPAMENTOS",
  "CNPJ": "31.949.507/0001-10",
  "CNAE Principal": "3321-0/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "23/08/2022",
  "Estado": "RS",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 570,
  "Tipo": "PJ",
  "Razão Social/Nome": "R. F. GONZALEZ",
  "Nome Fantasia/Apelido": "MILARIS TELECOM",
  "CNPJ": "21.670.055/0001-89",
  "CNAE Principal": "6190-6/01",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "23/08/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 571,
  "Tipo": "PJ",
  "Razão Social/Nome": "LUIS FERNANDO PACHECO 65189426068",
  "Nome Fantasia/Apelido": "LF PACHECO SERVICOS TECNICOS",
  "CNPJ": "33.067.820/0001-50",
  "CNAE Principal": "3321-0/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "23/08/2022",
  "Estado": "RS",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 572,
  "Tipo": "PJ",
  "Razão Social/Nome": "FENIX SERVICOS INDUSTRIAIS LTDA",
  "Nome Fantasia/Apelido": "FENIX SERVICOS",
  "CNPJ": "28.140.831/0001-88",
  "CNAE Principal": "3312-1/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "23/08/2022",
  "Estado": "MG",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 573,
  "Tipo": "PJ",
  "Razão Social/Nome": "LG ALPHA SERVICOS LTDA",
  "CNPJ": " 02.271.137/0001-06",
  "CNAE Principal": "4322-3/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "24/08/2022",
  "Estado": "RJ",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 574,
  "Tipo": "PJ",
  "Razão Social/Nome": "INFRATEC MANUTENCOES E SERVICOS LTDA",
  "Nome Fantasia/Apelido": "INFRATEC REFRIGERACAO E ELETRICA",
  "CNPJ": "30.730.589/0001-44",
  "CNAE Principal": "4322-3/02",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "24/08/2022",
  "Estado": "ES",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 575,
  "Tipo": "PJ",
  "Razão Social/Nome": "VEIGA & LOPES SEGURANCA ELETRONICA E TELECOMUNICACOES LTDA",
  "Nome Fantasia/Apelido": "GRUPO VEIGA & LOPES",
  "CNPJ": "43.495.800/0001-33",
  "CNAE Principal": "4321-5/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "24/08/2022",
  "Estado": "MG",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 576,
  "Tipo": "PJ",
  "Razão Social/Nome": "E. R. CONSTRUCOES COMERCIO E PRESTACOES DE SERVICOS LTDA",
  "Nome Fantasia/Apelido": "MONTE DAS OLIVEIRAS",
  "CNPJ": " 08.237.470/0001-85",
  "CNAE Principal": "4722-9/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "25/08/2022",
  "Estado": "PA",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 577,
  "Tipo": "PJ",
  "Razão Social/Nome": "JB ESTRUTURA METALURGICA LTDA",
  "Nome Fantasia/Apelido": "JB ESTRUTURA METALURGICA",
  "CNPJ": "45.644.429/0001-50",
  "CNAE Principal": "4292-8/01",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "25/08/2022",
  "Estado": "RJ",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 578,
  "Tipo": "PJ",
  "Razão Social/Nome": "WS VANS SERVICOS DE TRANSPORTE EIRELI",
  "Nome Fantasia/Apelido": "JDL TRANSPORTES",
  "CNPJ": "35.679.061/0001-01",
  "CNAE Principal": "4929-9/02",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "26/08/2022",
  "Estado": "MG",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 579,
  "Tipo": "PJ",
  "Razão Social/Nome": "CLINICA SANTO ANTONIO ODONTOLOGIA INTEGRADA LTDA",
  "Nome Fantasia/Apelido": "CLINICA SANTO ANTONIO ODONTOLOGIA",
  "CNPJ": "43.539.297/0001-70",
  "CNAE Principal": "8630-5/04",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "29/08/2022",
  "Estado": "PA",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 580,
  "Tipo": "PJ",
  "Razão Social/Nome": "TELMO JUNIOR MACHADO RODRIGUES",
  "Nome Fantasia/Apelido": "INSTALART",
  "CNPJ": "13.752.591/0001-03",
  "CNAE Principal": "4321-5/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "29/08/2022",
  "Estado": "RS",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 582,
  "Tipo": "PJ",
  "Razão Social/Nome": "DISTRIBUIDORA DE PRODUTOS FARMACEUTICOS VALLE MED LTDA",
  "Nome Fantasia/Apelido": "VALLE MED",
  "CNPJ": "19.805.900/0001-34",
  "CNAE Principal": "4644-3/01",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "29/08/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 583,
  "Tipo": "PJ",
  "Razão Social/Nome": "SALMERON ENERGIA RENOVAVEL E PROTECAO AO CLIMA NORDESTE LTDA",
  "CNPJ": "43.359.008/0001-51",
  "CNAE Principal": "1629-3/01",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "30/08/2022",
  "Estado": "PE",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 585,
  "Tipo": "PJ",
  "Razão Social/Nome": "DELTA FIBRA SERVICOS DE COMUNICACAO E SUPORTE TECNICO LTDA",
  "CNPJ": "42.098.436/0001-05",
  "CNAE Principal": "6110-8/03",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "30/08/2022",
  "Estado": "GO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 586,
  "Tipo": "PF",
  "Razão Social/Nome": "ANÍSIO APARECIDO DA SILVA  ",
  "Nome Fantasia/Apelido": "Fazenda Nossa Senhora Aparecida ",
  "CPF": "936.945.391-15",
  "CNAE Principal": 151203,
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "30/08/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 587,
  "Tipo": "PJ",
  "Razão Social/Nome": "J.S.C. VASCONCELOS",
  "Nome Fantasia/Apelido": "JV ENGENHARIA & SERVICOS",
  "CNPJ": "26.779.485/0001-57",
  "CNAE Principal": "7112-0/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "31/08/2022",
  "Estado": "RO",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 588,
  "Tipo": "PJ",
  "Razão Social/Nome": "CPA DOS SANTOS",
  "Nome Fantasia/Apelido": "RENOVAR",
  "CNPJ": "13.377.501/0001-33",
  "CNAE Principal": "4322-3/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "31/08/2022",
  "Estado": "GO",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 589,
  "Tipo": "PJ",
  "Razão Social/Nome": "EVERTON GREGORIO EVANGELISTA EUCASUL",
  "Nome Fantasia/Apelido": "EUCASUL MADEIRAS",
  "CNPJ": "47.489.389/0001-44",
  "CNAE Principal": " 01.61-0-02 ",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "31/08/2022",
  "Estado": "PR",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 590,
  "Tipo": "PJ",
  "Razão Social/Nome": "NL SERVICOS TOPOGRAFICOS LTDA",
  "Nome Fantasia/Apelido": "SERTO S SERVICOS TOPOGRAFICOS",
  "CNPJ": " 05.651.535/0001-46",
  "CNAE Principal": "7119-7/01",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "31/08/2022",
  "Estado": "BA",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 591,
  "Tipo": "PJ",
  "Razão Social/Nome": "CHARLES ALAN DA SILVA GUILHERME 04190178950",
  "Nome Fantasia/Apelido": "C&A INSTALACOES ELETRICA",
  "CNPJ": "34.528.867/0001-36",
  "CNAE Principal": "4321-5/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "31/08/2022",
  "Estado": "PR",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 592,
  "Tipo": "PJ",
  "Razão Social/Nome": "REGINA MARIA CASTRO GREIN",
  "Nome Fantasia/Apelido": "MANAGEMENT ENGENHARIA RCG",
  "CNPJ": "37.760.855/0001-20",
  "CNAE Principal": "7112-0/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "31/08/2022",
  "Estado": "PR",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 593,
  "Tipo": "PJ",
  "Razão Social/Nome": "JONAS N DA SILVA",
  "Nome Fantasia/Apelido": "SAR - SERVICOS DE APLICACAO REFRATARIA",
  "CNPJ": "32.626.719/0001-29",
  "CNAE Principal": "7020-4/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "01/09/2022",
  "Estado": "MG",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 594,
  "Tipo": "PJ",
  "Razão Social/Nome": "NICANOR SILVA MATOS 31679389866",
  "Nome Fantasia/Apelido": "NICANOR",
  "CNPJ": "27.412.981/0001-30",
  "CNAE Principal": "3319-8/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "01/09/2022",
  "Estado": "SP",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 595,
  "Tipo": "PJ",
  "Razão Social/Nome": "MARCOS AUAD CONSULTORIA LTDA",
  "CNPJ": "10.355.510/0001-35",
  "CNAE Principal": "7490-1/99",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "01/09/2022",
  "Estado": "MG",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 596,
  "Tipo": "PJ",
  "Razão Social/Nome": "FOX CONSTRUCOES LTDA",
  "Nome Fantasia/Apelido": "FOX ENGENHARIA",
  "CNPJ": "14.361.373/0001-00",
  "CNAE Principal": "7112-0/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "01/09/2022",
  "Estado": "GO",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 597,
  "Tipo": "PJ",
  "Razão Social/Nome": "RAFAEL LAZARINE DA SILVA 33251241869",
  "Nome Fantasia/Apelido": "LAZARINE SERVICOS TECNICOS",
  "CNPJ": "47.798.769/0001-60",
  "CNAE Principal": "33.14-7-19 ",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "01/09/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 598,
  "Tipo": "PJ",
  "Razão Social/Nome": "AIRTRONICS AUTOMATION LTDA",
  "Nome Fantasia/Apelido": "AIRTRONICS AUTOMATION",
  "CNPJ": "23.314.543/0001-89",
  "CNAE Principal": "7112-0/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "02/09/2022",
  "Estado": "SP",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 599,
  "Tipo": "PJ",
  "Razão Social/Nome": "B. RIOS - ATIVIDADES DE LIMPEZA NAO ESPECIFICADAS ANTERIORMENTE",
  "Nome Fantasia/Apelido": "VAP CLEAN SERVICOS DE LIMPEZA",
  "CNPJ": "25.528.794/0001-91",
  "CNAE Principal": "8129-0/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "02/09/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 600,
  "Tipo": "PJ",
  "Razão Social/Nome": "KELLITON EDNO DA ROCHA SOLUCOES EM ENGENHARIA",
  "Nome Fantasia/Apelido": "KER ENGENHARIA",
  "CNPJ": "39.492.858/0001-56",
  "CNAE Principal": "7112-0/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "02/09/2022",
  "Estado": "MT",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 601,
  "Tipo": "PJ",
  "Razão Social/Nome": "ECO SPLIT AR CONDICIONADO LTDA",
  "Nome Fantasia/Apelido": "ECO SPLIT",
  "CNPJ": "46.560.699/0001-45",
  "CNAE Principal": "43.22-3-02 ",
  "Região": 2,
  "Situação": "Ativa",
  "Data Cadastro": "02/09/2022",
  "Estado": "PR",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 602,
  "Tipo": "PJ",
  "Razão Social/Nome": "ALEXSANDRA SILVA NASCIMENTO 07372669537",
  "Nome Fantasia/Apelido": "AL TRANSPORTE",
  "CNPJ": "46.414.628/0001-34",
  "CNAE Principal": "49.29-9-02 ",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "05/09/2022",
  "Estado": "BA",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 603,
  "Tipo": "PJ",
  "Razão Social/Nome": "MARCOS VITOR ARAUJO VASCONCELOS 86652490528",
  "Nome Fantasia/Apelido": "MASTER ENGENHARIA",
  "CNPJ": "47.080.727/0001-90",
  "CNAE Principal": "43.30-4-04 ",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "05/09/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 604,
  "Tipo": "PJ",
  "Razão Social/Nome": "DBEM CO LTDA",
  "Nome Fantasia/Apelido": "DBEM COSMETICOS",
  "CNPJ": "40.151.477/0001-92",
  "CNAE Principal": "4772-5/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "05/09/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 605,
  "Tipo": "PJ",
  "Razão Social/Nome": "DIEGO CASTRO GONCALVES 01610500008",
  "Nome Fantasia/Apelido": "CONSTRUD&L",
  "CNPJ": "23.508.316/0001-94",
  "CNAE Principal": "4399-1/03",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "06/09/2022",
  "Estado": "RS",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 606,
  "Tipo": "PJ",
  "Razão Social/Nome": "MARIA ELINETE LINS PEIXOTO",
  "Nome Fantasia/Apelido": "LABORATORIO DE ANALISES CLINICAS PROF EURICO LITTON",
  "CNPJ": " 01.795.814/0001-22",
  "CNAE Principal": "8640-2/02",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "06/09/2022",
  "Estado": "TO",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 607,
  "Tipo": "PJ",
  "Razão Social/Nome": "MARMORARIA DIAMANTINA EIRELI",
  "Nome Fantasia/Apelido": "MARMORARIA DIAMANTINA",
  "CNPJ": " 01.028.013/0001-31",
  "CNAE Principal": "4744-0/99",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "06/09/2022",
  "Estado": "GO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 608,
  "Tipo": "PJ",
  "Razão Social/Nome": "OLIVEIRA & MONCAO LTDA",
  "Nome Fantasia/Apelido": "OLIVEIRA WTP CONSULTORIA",
  "CNPJ": "28.852.664/0001-06",
  "CNAE Principal": "7112-0/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "06/09/2022",
  "Estado": "MA",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 609,
  "Tipo": "PJ",
  "Razão Social/Nome": "CLAUDIO ROBERTO DA SILVA SANTOS",
  "CNPJ": "19.870.789/0001-60",
  "CNAE Principal": "4530-7/03",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "07/09/2022",
  "Estado": "MS",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 610,
  "Tipo": "PJ",
  "Razão Social/Nome": "MEM EMPORIO LTDA",
  "Nome Fantasia/Apelido": "BETTINA RESTAURANTE",
  "CNPJ": "46.573.054/0001-47",
  "CNAE Principal": "56.11-2-01",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "09/09/2022",
  "Estado": "RJ",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 611,
  "Tipo": "PJ",
  "Razão Social/Nome": "BETTINA CAFE LTDA",
  "Nome Fantasia/Apelido": "BETTINA CAFE",
  "CNPJ": "45.258.610/0001-28",
  "CNAE Principal": "5611-2/03",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "09/09/2022",
  "Estado": "RJ",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 612,
  "Tipo": "PJ",
  "Razão Social/Nome": "GUSTAVO MAZZARDO LTDA",
  "Nome Fantasia/Apelido": "GUSTAVO MAZZARDO - PRESTACAO DE SERVICOS MEDICOS",
  "CNPJ": "47.679.591/0001-39",
  "CNAE Principal": "86.30-5-03 ",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "09/09/2022",
  "Estado": "SC",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 613,
  "Tipo": "PJ",
  "Razão Social/Nome": "CLINICA GIANNETTO MORAES RADIOLOGIA LTDA",
  "Nome Fantasia/Apelido": "DENTAL IMAGENS RADIOLOGIA ODONTOLOGICA",
  "CNPJ": "29.310.485/0001-00",
  "CNAE Principal": "8640-2/05",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "09/09/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 614,
  "Tipo": "PJ",
  "Razão Social/Nome": "SAINT-GOBAIN DO BRASIL PRODUTOS INDUSTRIAIS E PARA CONSTRUCAO LTDA",
  "Nome Fantasia/Apelido": "SAINT-GOBAIN DO BRASIL - DIVISAO CERAMICAS & PLASTICOS",
  "CNPJ": "61.064.838/0042-01",
  "CNAE Principal": "2341-9/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "09/09/2022",
  "Estado": "SP",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 615,
  "Tipo": "PJ",
  "Razão Social/Nome": "DROGARIA CANAVEZZI  LTDA",
  "Nome Fantasia/Apelido": "DROGARIA DO POVO",
  "CNPJ": "26.790.078/0001-40",
  "CNAE Principal": "4771-7/01",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "12/09/2022",
  "Estado": "SP",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 616,
  "Tipo": "PJ",
  "Razão Social/Nome": "AUTOMAQ COMERCIO, INSTALACAO E SERVICOS ELETRICOS LTDA",
  "CNPJ": "22.675.685/0001-09",
  "CNAE Principal": "4742-3/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "12/09/2022",
  "Estado": "SP",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 617,
  "Tipo": "PJ",
  "Razão Social/Nome": "LUIZ GONZAGA DA SILVA",
  "Nome Fantasia/Apelido": "MARMEC",
  "CNPJ": " 00.087.636/0001-12",
  "CNAE Principal": "3314-7/06",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "12/09/2022",
  "Estado": "MG",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 618,
  "Tipo": "PJ",
  "Razão Social/Nome": "MORETI PINTURA INDUSTRIAL E PREDIAL LTDA",
  "Nome Fantasia/Apelido": "MORETI PINTURA",
  "CNPJ": "43.356.232/0001-90",
  "CNAE Principal": "4330-4/04",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "12/09/2022",
  "Estado": "SC",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 619,
  "Tipo": "PJ",
  "Razão Social/Nome": "GM ENGENHARIA-EMPREENDIMENTOS LTDA",
  "CNPJ": " 04.503.910/0001-48",
  "CNAE Principal": "4120-4/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "12/09/2022",
  "Estado": "PA",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 620,
  "Tipo": "PJ",
  "Razão Social/Nome": "LOCCAMAQ LOCACAO DE CAMINHOES E MAQUINAS EIRELI",
  "Nome Fantasia/Apelido": "LOCCAMAQ - SOLUCOES AMBIENTAIS",
  "CNPJ": "19.017.473/0001-20",
  "CNAE Principal": "7732-2/01",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "12/09/2022",
  "Estado": "MG",
  "Situação ESocial": "Nao Habilitada",
  "Convocação de exames?": "Sim"
 },
 {
  "Column1": 621,
  "Tipo": "PJ",
  "Razão Social/Nome": "MOURA & MIRANDA LTDA",
  "Nome Fantasia/Apelido": "AUTO POSTO MOURA",
  "CNPJ": "14.824.430/0001-32",
  "CNAE Principal": "4731-8/00",
  "Região": "0",
  "Situação": "Ativa",
  "Data Cadastro": "13/09/2022",
  "Estado": "TO",
  "Situação ESocial": "Habilitada",
  "Convocação de exames?": "Sim"
 },
    {
     "Column1": 622,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CONSELHO INDIGENISTA MISSIONARIO CIMI ",
     "Nome Fantasia\/Apelido": "CONSELHO INDIGENISTA MISSIONARIO CIMI ",
     "CNPJ": " 00.479.105\/0011-47",
     "CNAE Principal": "9491-0\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "13\/09\/2022",
     "Estado": "AC",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 623,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CONSORCIO KAPA INOVE E VIA OESTE",
     "Nome Fantasia\/Apelido": "CONSORCIO KAPA INOVE E VIA OESTE",
     "CNPJ": "46.251.157\/0001-90",
     "CNAE Principal": "4213-8\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "14\/09\/2022",
     "Estado": "PA",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 624,
     "Tipo": "PJ",
     "Razão Social\/Nome": "NSS SERVICOS ELETRICOS E COMERCIO LTDA",
     "CNPJ": "24.462.717\/0001-13",
     "CNAE Principal": "4321-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "01\/09\/2022",
     "Estado": "MG",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 625,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CARLOS ARAUJO MARCELINO 09376722469",
     "Nome Fantasia\/Apelido": "CLIMAX",
     "CNPJ": "24.855.195\/0001-10",
     "CNAE Principal": "3314-7\/07",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "14\/09\/2022",
     "Estado": "RN",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 626,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DANIEL LUCIO RODRIGUES",
     "Nome Fantasia\/Apelido": "MINAS MANUTENCAO E MONTAGEM INDUSTRIAL",
     "CNPJ": "25.128.821\/0001-39",
     "CNAE Principal": "4292-8\/02",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "15\/09\/2022",
     "Estado": "MG",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 628,
     "Tipo": "PJ",
     "Razão Social\/Nome": "AGRO INDUSTRIAL DE CEREAIS VERDES CAMPOS S\/A",
     "CNPJ": " 00.082.253\/0001-51",
     "CNAE Principal": "1061-9\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "15\/09\/2022",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 629,
     "Tipo": "PJ",
     "Razão Social\/Nome": "AGRO INDUSTRIAL DE CEREAIS VERDES CAMPOS S\/A",
     "CNPJ": " 00.082.253\/0002-32",
     "CNAE Principal": "4632-0\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "15\/09\/2022",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 630,
     "Tipo": "PJ",
     "Razão Social\/Nome": "J.S.R.C GNV SEGURANCA CONTRA INCENDIO EIRELI",
     "Nome Fantasia\/Apelido": "J. S. QUALITY",
     "CNPJ": " 07.692.685\/0001-23",
     "CNAE Principal": "4520-0\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "16\/09\/2022",
     "Estado": "RJ",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 631,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CONSTRUTORA LOCAR EIRELI",
     "Nome Fantasia\/Apelido": "CONSTRUTORA LOCAR",
     "CNPJ": "29.619.312\/0001-60",
     "CNAE Principal": "4120-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "16\/09\/2022",
     "Estado": "PI",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 632,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CARLOS ALMEIDA DE SOUZA",
     "Nome Fantasia\/Apelido": "SOUZA EMPREITEIRA",
     "CNPJ": "34.827.411\/0001-77",
     "CNAE Principal": " 0161-0\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "19\/09\/2022",
     "Estado": "MT",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 633,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CARTORIO DO UNICO OFICIO DA COMARCA DE CURIONOPOLIS - PA",
     "Nome Fantasia\/Apelido": "CARTORIO DO UNICO OFICIO DA COMARCA DE CURIONOPOLIS-PA",
     "CNPJ": "36.739.070\/0001-03",
     "CNAE Principal": "6912-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "19\/09\/2022",
     "Estado": "PA",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 634,
     "Tipo": "PJ",
     "Razão Social\/Nome": "NAYARA TRANSPORTE LTDA",
     "Nome Fantasia\/Apelido": "PLANETA AGUA",
     "CNPJ": "47.861.548\/0001-90",
     "CNAE Principal": "77.31-4-00 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "19\/09\/2022",
     "Estado": "TO",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 635,
     "Tipo": "PJ",
     "Razão Social\/Nome": "L. DE MOURA DA SILVA CHAGAS - REFRIGERACAO MC",
     "Nome Fantasia\/Apelido": "REFRIGERACAO MC",
     "CNPJ": "18.846.811\/0001-73",
     "CNAE Principal": "4322-3\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "19\/09\/2022",
     "Estado": "GO",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 636,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SILVA & PASSOS LTDA",
     "Nome Fantasia\/Apelido": "ENGEMAIS",
     "CNPJ": "33.888.053\/0001-40",
     "CNAE Principal": "4322-3\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "20\/09\/2022",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 637,
     "Tipo": "PJ",
     "Razão Social\/Nome": "TORQUE ENGENHARIA E SERVICOS LTDA",
     "Nome Fantasia\/Apelido": "TORQUE ENGENHARIA E SERVICOS",
     "CNPJ": "42.745.739\/0001-72",
     "CNAE Principal": "4120-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "20\/09\/2022",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 638,
     "Tipo": "PJ",
     "Razão Social\/Nome": "PAVNORTE CONSTRUTORA LTDA.",
     "Nome Fantasia\/Apelido": "PAVNORTE CONSTRUTORA",
     "CNPJ": "20.216.786\/0001-96",
     "CNAE Principal": "2399-1\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "20\/09\/2022",
     "Estado": "BA",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 639,
     "Tipo": "PJ",
     "Razão Social\/Nome": "T4S TECNOLOGIA S.A.",
     "CNPJ": "23.858.704\/0001-03",
     "CNAE Principal": "8020-0\/01",
     "Região": 6,
     "Situação": "Ativa",
     "Data Cadastro": "20\/09\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 640,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SEBESTYEN CONSTRUTORA LTDA",
     "Nome Fantasia\/Apelido": "SEBESTYEN CONSTRUTORA",
     "CNPJ": "40.649.199\/0001-06",
     "CNAE Principal": "4120-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "21\/09\/2022",
     "Estado": "AM",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 641,
     "Tipo": "PJ",
     "Razão Social\/Nome": "NR SERRALHERIA LTDA.",
     "Nome Fantasia\/Apelido": "NR SERRALHERIA",
     "CNPJ": "44.609.569\/0001-24",
     "CNAE Principal": "2542-0\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "22\/09\/2022",
     "Estado": "MS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 642,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ALIANCA COMERCIO DE EXTINTORES LTDA",
     "Nome Fantasia\/Apelido": "ALIANCA EXTINTORES",
     "CNPJ": "21.432.368\/0001-07",
     "CNAE Principal": "4530-7\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "22\/09\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 643,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SAP SOLUCOES AUDIO VISUAIS E PRODUCAO LTDA",
     "Nome Fantasia\/Apelido": "SAP SOLUCOES AUDIO VISUAIS E PRODUCAO",
     "CNPJ": " 07.907.190\/0001-74",
     "CNAE Principal": "9001-9\/06",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "23\/09\/2022",
     "Estado": "MG",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 644,
     "Tipo": "PJ",
     "Razão Social\/Nome": "BOXLOC - ESTRUTURAS DE ALUMINIO LTDA",
     "Nome Fantasia\/Apelido": "BOXLOC",
     "CNPJ": "12.515.951\/0001-82",
     "CNAE Principal": "7739-0\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "23\/09\/2022",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 645,
     "Tipo": "PJ",
     "Razão Social\/Nome": "RGS PERICIAS TECNICAS LTDA",
     "Nome Fantasia\/Apelido": "RGS SERVICOS",
     "CNPJ": "19.218.647\/0001-12",
     "CNAE Principal": "8599-6\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "26\/09\/2022",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 646,
     "Tipo": "PJ",
     "Razão Social\/Nome": "GELUX - ILUMINACAO E SOLUCOES ELETRICAS LTDA",
     "Nome Fantasia\/Apelido": "GELUX - ILUMINACAO E SOLUCOES ELETRICAS",
     "CNPJ": "27.183.511\/0001-42",
     "CNAE Principal": "7112-0\/00",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "26\/09\/2022",
     "Estado": "SC",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 647,
     "Tipo": "PJ",
     "Razão Social\/Nome": "EFFORT ENGINEERING-PROJETOS E GERENCIAMENTO LTDA",
     "Nome Fantasia\/Apelido": "EFFORT ENGINEERING",
     "CNPJ": "17.498.567\/0001-32",
     "CNAE Principal": "7112-0\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "26\/09\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 648,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ATITUDE SERVICOS DE  ASSEIO, LOCACOES, PRODUCAO E EVENTOS EIRELI",
     "Nome Fantasia\/Apelido": "ATITUDE SERVICOS DE ASSEIO",
     "CNPJ": "27.596.490\/0001-97",
     "CNAE Principal": "8299-7\/99",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "27\/09\/2022",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 649,
     "Tipo": "PJ",
     "Razão Social\/Nome": "IND. E COMERCIO DE PARA-CHOQUES FERNANDES LTDA",
     "Nome Fantasia\/Apelido": "RODOESTE CARRETAS",
     "CNPJ": "10.014.501\/0001-80",
     "CNAE Principal": "2930-1\/01",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "27\/09\/2024",
     "Estado": "PR",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 651,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ENGELEV ENGENHARIA LTDA",
     "Nome Fantasia\/Apelido": "ENGELEV ENGENHARIA",
     "CNPJ": "21.928.906\/0001-40",
     "CNAE Principal": "7112-0\/00",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "28\/09\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 652,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ESPARTANO SILVA DOS SANTOS LTDA",
     "Nome Fantasia\/Apelido": "NATRAPSE REFORMAS E CONSTRUCAO",
     "CNPJ": "31.489.591\/0001-36",
     "CNAE Principal": "7112-0\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "28\/09\/2022",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 653,
     "Tipo": "PJ",
     "Razão Social\/Nome": "FBR ENERGIA RENOVAVEL LTDA",
     "Nome Fantasia\/Apelido": "Freelight energia solar",
     "CNPJ": "29.513.466\/0001-72",
     "CNAE Principal": "4321-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "29\/09\/2022",
     "Estado": "ES",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 655,
     "Tipo": "PJ",
     "Razão Social\/Nome": "J.B. MONTEIRO ALIMENTOS LTDA",
     "Nome Fantasia\/Apelido": "J.B. ALIMENTOS",
     "CNPJ": "18.659.426\/0001-17",
     "CNAE Principal": "5620-1\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "20\/06\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 656,
     "Tipo": "PJ",
     "Razão Social\/Nome": "JEAN JOSE DA SILVA 52704823120",
     "Nome Fantasia\/Apelido": "LIMPA FOSSA FORMOSO",
     "CNPJ": "47.723.018\/0001-85",
     "CNAE Principal": "37.02-9-00 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "30\/09\/2022",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 657,
     "Tipo": "PF",
     "Razão Social\/Nome": "LAUREMICIO DE CASTRO AMORIM GOMES",
     "Nome Fantasia\/Apelido": "Fazenda Vitória",
     "CPF": "883.873.724-04",
     "CNAE Principal": 119999,
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "03\/10\/2022",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 658,
     "Tipo": "PJ",
     "Razão Social\/Nome": "KVA ILUMINACAO E SANEAMENTO AMBIENTAL EIRELI",
     "Nome Fantasia\/Apelido": "KVA ILUMINACAO E SANEAMENTO AMBIENTAL",
     "CNPJ": "39.566.141\/0001-01",
     "CNAE Principal": "4329-1\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "03\/10\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 659,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ROBERTO DONISETE MARCHIORI 14144547833",
     "Nome Fantasia\/Apelido": "R. MARCHIORI MANUTENCAO ELETRICA",
     "CNPJ": "23.294.664\/0001-06",
     "CNAE Principal": "4321-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "04\/10\/2022",
     "Estado": "SC",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 660,
     "Tipo": "PJ",
     "Razão Social\/Nome": "PAULO RICARDO DE ANDRADE YAMACAKE 02115933540",
     "Nome Fantasia\/Apelido": "ORIGEM TEMPEROS MILENARES",
     "CNPJ": "17.051.672\/0001-29",
     "CNAE Principal": "4729-6\/99",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "05\/10\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 661,
     "Tipo": "PJ",
     "Razão Social\/Nome": "BETA ENERGY LTDA",
     "Nome Fantasia\/Apelido": "BETA ENERGY ",
     "CNPJ": " 07.032.273\/0001-67",
     "CNAE Principal": "4120-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "05\/10\/2022",
     "Estado": "GO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 662,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ARTHUR PREGO AMORIM 04676139314",
     "Nome Fantasia\/Apelido": "ATH.CONSTRUCOES REFORMAS E PINTURAS",
     "CNPJ": "34.841.435\/0001-80",
     "CNAE Principal": "4399-1\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "05\/10\/2022",
     "Estado": "SC",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 663,
     "Tipo": "PJ",
     "Razão Social\/Nome": "EZX ENGENHARIA EIRELI",
     "Nome Fantasia\/Apelido": "EZX ENGENHARIA",
     "CNPJ": "27.475.744\/0001-19",
     "CNAE Principal": "4399-1\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "05\/10\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 664,
     "Tipo": "PJ",
     "Razão Social\/Nome": "RAFAEL MOREIRA LTDA",
     "Nome Fantasia\/Apelido": "RMS PLANEJMAMENTO E CONTROLE",
     "CNPJ": "47.846.862\/0001-01",
     "CNAE Principal": "82.11-3-00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "06\/10\/2022",
     "Estado": "BA",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 665,
     "Tipo": "PJ",
     "Razão Social\/Nome": "FERNANDO DE ARRUDA FERRAMENTAS E INSTALACOES",
     "Nome Fantasia\/Apelido": "ITA CALHAS ITAPEVI COMERCIO DE CALHAS EM GERAL",
     "CNPJ": "18.542.459\/0001-82",
     "CNAE Principal": "4744-0\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "06\/10\/2022",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 666,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CONSULTORIO ODONTOLOGICO EDER A ROCHA EIRELI",
     "CNPJ": "41.578.447\/0001-20",
     "CNAE Principal": "8630-5\/04",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "06\/10\/2022",
     "Estado": "MG",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 670,
     "Tipo": "PJ",
     "Razão Social\/Nome": "AGV TRANSPORTES LTDA",
     "Nome Fantasia\/Apelido": "AGV TUR",
     "CNPJ": "11.540.026\/0001-49",
     "CNAE Principal": "4929-9\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "07\/10\/2022",
     "Estado": "MT",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 671,
     "Tipo": "PJ",
     "Razão Social\/Nome": "R.A. SERVICOS E INSTALACAO DE MAQUINAS TEXTEIS LTDA",
     "Nome Fantasia\/Apelido": "R.A. SERVICE",
     "CNPJ": "47.771.403\/0001-06",
     "CNAE Principal": "33.21-0-00 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "10\/10\/2022",
     "Estado": "MG",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 673,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ARIEL RODRIGUES DOS SANTOS",
     "Nome Fantasia\/Apelido": "RW SERRALHERIA",
     "CNPJ": "42.904.363\/0001-00",
     "CNAE Principal": "4744-0\/01",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "10\/10\/2022",
     "Estado": "RS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 674,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CRISTATA EMPREENDIMENTOS EM ENGENHARIA EIRELI",
     "Nome Fantasia\/Apelido": "CRISTATA EMPREENDIMENTOS",
     "CNPJ": "40.099.227\/0001-50",
     "CNAE Principal": "4120-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "10\/10\/2022",
     "Estado": "BA",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 675,
     "Tipo": "PJ",
     "Razão Social\/Nome": "THERMO EL DUTOS DE AR CONDICIONADO",
     "Nome Fantasia\/Apelido": "THERMO EL DUTOS DE AR CONDICIONADO",
     "CNPJ": "48.231.623\/0001-00",
     "CNAE Principal": "43.22-3-02 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "11\/10\/2022",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 676,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MARCELO TELES DA FONSECA",
     "Nome Fantasia\/Apelido": "MT CONSTRUCOES E PINTURAS",
     "CNPJ": "41.623.005\/0001-58",
     "CNAE Principal": "4399-1\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "11\/10\/2022",
     "Estado": "MG",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 677,
     "Tipo": "PJ",
     "Razão Social\/Nome": "BRASFREIGHT LOGISTICA E SERVICOS LTDA",
     "Nome Fantasia\/Apelido": "BRASFREIGHT",
     "CNPJ": "12.601.552\/0001-34",
     "CNAE Principal": "5232-0\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "13\/10\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 678,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CLAUDIA DE MELO RODRIGUES 33209303894",
     "Nome Fantasia\/Apelido": "THERMO EL DUTOS DE AR CONDICIONADO ME",
     "CNPJ": "46.763.590\/0001-05",
     "CNAE Principal": "33.21-0-00 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "13\/10\/2022",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 679,
     "Tipo": "PJ",
     "Razão Social\/Nome": "OSVALDO CARDOSO MOURA",
     "Nome Fantasia\/Apelido": "OSVALDINHO IMPERMEABILIZACAO",
     "CNPJ": "14.887.620\/0001-07",
     "CNAE Principal": "4330-4\/01",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "13\/10\/2022",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 680,
     "Tipo": "PJ",
     "Razão Social\/Nome": "PAULO NUNES GESSO",
     "Nome Fantasia\/Apelido": "NUNES GESSO",
     "CNPJ": "25.084.157\/0001-73",
     "CNAE Principal": "4330-4\/03",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "13\/10\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 681,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CARTORIO DO 6 TABELIONATO DE NOTAS DA COMARCA DE MANAUS",
     "Nome Fantasia\/Apelido": "CARTORIO ENCONTRO DAS AGUAS",
     "CNPJ": "39.909.584\/0001-58",
     "CNAE Principal": "6912-5\/00",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "14\/10\/2022",
     "Estado": "AM",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 682,
     "Tipo": "PJ",
     "Razão Social\/Nome": "TSM COMERCIO DE EQUIPAMENTOS E SISTEMAS ELETRONICOS LTDA",
     "Nome Fantasia\/Apelido": "TOTALSEG",
     "CNPJ": "19.795.717\/0001-03",
     "CNAE Principal": "4757-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "14\/10\/2022",
     "Estado": "AM",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 683,
     "Tipo": "PJ",
     "Razão Social\/Nome": "2 OFICIO DE MANACAPURU",
     "Nome Fantasia\/Apelido": "2º TABELIONATO E REGISTROS PÚBLICOS DE MANACAPURU",
     "CNPJ": "41.957.251\/0001-46",
     "CNAE Principal": "6912-5\/00",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "14\/10\/2022",
     "Estado": "AM",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 685,
     "Tipo": "PJ",
     "Razão Social\/Nome": "VOLT STEEL ENGENHARIA ELETRICA LTDA",
     "Nome Fantasia\/Apelido": "VOLT STEEL ENGENHARIA ELETRICA ",
     "CNPJ": "47.851.784\/0001-25",
     "CNAE Principal": "71.12-0-00 ",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "15\/10\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 686,
     "Tipo": "PJ",
     "Razão Social\/Nome": "PAX SERVICOS SOMATOCONSERVACAO LTDA",
     "Nome Fantasia\/Apelido": "TANATOPRAXIA PAX",
     "CNPJ": "42.277.872\/0001-41",
     "CNAE Principal": "9603-3\/05",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "17\/10\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 689,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CLAYTON CESAR DA CRUZ LOPES 40550365826",
     "Nome Fantasia\/Apelido": "CPLA INOVACAO EM SERVICOS ELETRICOS",
     "CNPJ": "29.076.837\/0001-04",
     "CNAE Principal": "4321-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "18\/10\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 690,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MARLI DOS ANJOS GONCALVES FERREIRA",
     "Nome Fantasia\/Apelido": "PISOS & CIA LAPIDADORA",
     "CNPJ": "24.538.901\/0001-08",
     "CNAE Principal": "4329-1\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "18\/10\/2022",
     "Estado": "BA",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 692,
     "Tipo": "PJ",
     "Razão Social\/Nome": "D TULIO GOURMET RESTAURANTE EIRELI",
     "Nome Fantasia\/Apelido": "D TULIO GOUMERT",
     "CNPJ": "26.450.499\/0001-22",
     "CNAE Principal": "5611-2\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "18\/10\/2022",
     "Estado": "MG",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 693,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LUCIANO PORTILHO DA SILVA  LTDA",
     "Nome Fantasia\/Apelido": "L.P.S. TRATORES E CAMINHOES",
     "CNPJ": "27.880.346\/0001-88",
     "CNAE Principal": "3314-7\/12",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "19\/10\/2022",
     "Estado": "PR",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 694,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MARCIO CASCAES JUNIOR 96810866000",
     "Nome Fantasia\/Apelido": "SUL PELICULAS",
     "CNPJ": "25.996.742\/0001-40",
     "CNAE Principal": "4520-0\/07",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "19\/10\/2022",
     "Estado": "RS",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 696,
     "Tipo": "PJ",
     "Razão Social\/Nome": "R & R VELOZO SERVICOS EM TELECOMUNICACOES LTDA",
     "Nome Fantasia\/Apelido": "VELOZ TELECOM",
     "CNPJ": "37.975.811\/0001-18",
     "CNAE Principal": "42.21-9-04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "19\/10\/2022",
     "Estado": "DF",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 697,
     "Tipo": "PJ",
     "Razão Social\/Nome": "HP OBRAS & HIDRAULICA LTDA",
     "CNPJ": "33.736.337\/0001-10",
     "CNAE Principal": "4322-3\/01",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "19\/10\/2022",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 698,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ENGESYS LTDA",
     "CNPJ": "40.706.206\/0001-56",
     "CNAE Principal": "6110-8\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "19\/10\/2022",
     "Estado": "MG",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 700,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ZALOG OPERADORA LOGISTICA LTDA",
     "Nome Fantasia\/Apelido": "ZALOG LOGISTICA",
     "CNPJ": "14.062.933\/0003-80",
     "CNAE Principal": "4930-2\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "19\/10\/2022",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 701,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MD REFORMAS CONSTRUCOES E REPRESENTACOES LTDA",
     "Nome Fantasia\/Apelido": "MD REFORMAS",
     "CNPJ": "35.637.535\/0001-52",
     "CNAE Principal": "4399-1\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "19\/10\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 702,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SV ODONTOLOGIA INTEGRADA LTDA",
     "Nome Fantasia\/Apelido": "SV ODONTOLOGIA INTEGRADA",
     "CNPJ": "39.926.289\/0001-00",
     "CNAE Principal": "8630-5\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "20\/10\/2022",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 704,
     "Tipo": "PJ",
     "Razão Social\/Nome": "JULIANA MITIKO YAMAMOTO COMERCIO DE MOTORES",
     "Nome Fantasia\/Apelido": "YAMAMOTO MOTOR BOATS",
     "CNPJ": "35.136.930\/0001-51",
     "CNAE Principal": "4763-6\/05",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "20\/10\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 705,
     "Tipo": "PJ",
     "Razão Social\/Nome": "M & B REFRIGERACAO LTDA.",
     "Nome Fantasia\/Apelido": "M & B REFRIGERACAO",
     "CNPJ": "14.144.518\/0001-03",
     "CNAE Principal": "4322-3\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "20\/10\/2022",
     "Estado": "CE",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 706,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SOUZA SOLUCOES TECNOLOGICAS DE ENGENHARIA E IMPERMEABILIZACAO EM OBRAS DE ENGENHARIA CIVIL LTDA",
     "Nome Fantasia\/Apelido": "RS ENGENHARIA INVESTIGATIVA",
     "CNPJ": "35.864.148\/0001-59",
     "CNAE Principal": "7112-0\/00",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "21\/10\/2022",
     "Estado": "TO",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 707,
     "Tipo": "PJ",
     "Razão Social\/Nome": "RILDO DE OLIVEIRA CRESCENCIO",
     "CNPJ": "16.560.036\/0001-60",
     "CNAE Principal": "2542-0\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "21\/10\/2022",
     "Estado": "BA",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 708,
     "Tipo": "PJ",
     "Razão Social\/Nome": "GRUPO ALVES SEGURANCA E SERVICOS LTDA",
     "Nome Fantasia\/Apelido": "GRUPO ALVES SEGURANCA",
     "CNPJ": "46.448.598\/0001-87",
     "CNAE Principal": "81.11-7-00 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "21\/10\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 709,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CESAR TEATIN COCCO",
     "Nome Fantasia\/Apelido": "AERO INSPEC",
     "CNPJ": "22.079.148\/0001-04",
     "CNAE Principal": "7420-0\/01",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "21\/10\/2022",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 710,
     "Tipo": "PJ",
     "Razão Social\/Nome": "NATTUSFOOD ALIMENTACAO SAUDAVEL LTDA",
     "Nome Fantasia\/Apelido": "NATTUSFOOD",
     "CNPJ": "27.503.496\/0001-72",
     "CNAE Principal": "5620-1\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "23\/10\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 711,
     "Tipo": "PJ",
     "Razão Social\/Nome": "TAGA MOTORS LTDA",
     "Nome Fantasia\/Apelido": "TAGA MOTORS",
     "CNPJ": "41.543.454\/0001-96",
     "CNAE Principal": "4520-0\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "24\/10\/2022",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 712,
     "Tipo": "PJ",
     "Razão Social\/Nome": "BOLZONI SOUTH AMERICA ACESSORIOS PARA EMPILHADEIRAS LTDA",
     "CNPJ": "32.634.779\/0001-93",
     "CNAE Principal": "4669-9\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "24\/10\/2022",
     "Estado": "ES",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 713,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ACQUA REDUZ SOLUCAO EM ECONOMIA DE AGUA LTDA",
     "CNPJ": "21.399.815\/0001-65",
     "CNAE Principal": "7020-4\/00",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "24\/10\/2022",
     "Estado": "SC",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 714,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ASSOCIACAO DOS APOSENTADOS E PENSIONISTAS DE A DOS REIS",
     "CNPJ": "39.161.278\/0001-86",
     "CNAE Principal": "9430-8\/00",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "25\/10\/2022",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 715,
     "Tipo": "PJ",
     "Razão Social\/Nome": "W S MARQUES SERVICOS LTDA",
     "Nome Fantasia\/Apelido": "CONSTRUTORA W S MARQUES",
     "CNPJ": "41.502.712\/0001-96",
     "CNAE Principal": "4120-4\/00",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "25\/10\/2022",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 718,
     "Tipo": "PF",
     "Razão Social\/Nome": "ANTONIO CARLOS APOLINARIO DE SOUZA CARDOSO",
     "Nome Fantasia\/Apelido": "ANTONIO CARLOS APOLINARIO DE SOUZA CARDOSO",
     "CPF": "904.013.952-00",
     "CNAE Principal": 6912500,
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "27\/10\/2022",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 719,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ENERGY CONTROL SISTEMAS DE AUTOMACAO, CONTROLE E TELEMETRIA LTDA",
     "Nome Fantasia\/Apelido": "ENERGY CONTROL",
     "CNPJ": "11.261.598\/0001-99",
     "CNAE Principal": "4789-0\/99",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "27\/10\/2022",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 720,
     "Tipo": "PJ",
     "Razão Social\/Nome": "INNOVAR TECNOLOGIA EM CLIMATIZACAO LTDA",
     "Nome Fantasia\/Apelido": "INNOVAR AR CONDICIONADO E ELETRICA",
     "CNPJ": "38.028.136\/0001-82",
     "CNAE Principal": "4322-3\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "27\/10\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 721,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SORTECH - CONSULTORIA E DESENVOLVIMENTO DE SOLUCOES TECNOLOGICAS LTDA",
     "Nome Fantasia\/Apelido": "SORTECH - CONSULTORIA E DESENVOLVIMENTO DE SOLUCOES TEC",
     "CNPJ": "20.727.821\/0001-31",
     "CNAE Principal": "6202-3\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "27\/10\/2022",
     "Estado": "DF",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 722,
     "Tipo": "PJ",
     "Razão Social\/Nome": "VANDERLEI BARBOSA DE SOUZA",
     "Nome Fantasia\/Apelido": "MIWA MOTOS",
     "CNPJ": "74.554.460\/0001-06",
     "CNAE Principal": "4541-2\/06",
     "Região": 7,
     "Situação": "Ativa",
     "Data Cadastro": "27\/10\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 724,
     "Tipo": "PJ",
     "Razão Social\/Nome": "RSB CONSTRUCAO E REFORMAS LTDA.",
     "CNPJ": " 07.705.509\/0001-89",
     "CNAE Principal": "4120-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "30\/10\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 726,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ALVES TRANSPORTES LTDA",
     "Nome Fantasia\/Apelido": "ALVES TRANSPORTES",
     "CNPJ": "74.466.624\/0001-35",
     "CNAE Principal": "4930-2\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "03\/11\/2022",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 728,
     "Tipo": "PJ",
     "Razão Social\/Nome": "OXXYGENIUM CONSTRUCAO E OBRAS EIRELI",
     "Nome Fantasia\/Apelido": "OXXYGENIUM CONSTRUCOES E PROJETOS",
     "CNPJ": " 04.261.965\/0001-99",
     "CNAE Principal": "4120-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "04\/11\/2022",
     "Estado": "BA",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 729,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MACRON SOLUCOES EM ENGENHARIA E TECNOLOGIA LTDA",
     "Nome Fantasia\/Apelido": "MACRON SOLUCOES EM ENGENHARIA E TECNOLOGIA",
     "CNPJ": "39.435.469\/0001-99",
     "CNAE Principal": "4322-3\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "04\/11\/2022",
     "Estado": "ES",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 730,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DONNA CONCEPT, BEM-ESTAR, BELEZA E MODA LTDA",
     "Nome Fantasia\/Apelido": "DONNA CONCEPT, BEM-ESTAR, BELEZA E MODA",
     "CNPJ": "43.200.020\/0001-19",
     "CNAE Principal": "9602-5\/02",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "04\/11\/2022",
     "Estado": "MA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 732,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ATENAS ARQUIVOS LTDA",
     "Nome Fantasia\/Apelido": "ATENAS ARQUIVOS",
     "CNPJ": "43.892.915\/0001-61",
     "CNAE Principal": "4754-7\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "07\/11\/2022",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 735,
     "Tipo": "PJ",
     "Razão Social\/Nome": "QUALITY VIDROS LTDA",
     "CNPJ": "46.907.789\/0001-60",
     "CNAE Principal": "47.43-1-00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "07\/11\/2022",
     "Estado": "RS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 741,
     "Tipo": "PJ",
     "Razão Social\/Nome": "NRL NASCIMENTO REPRESENTACOES LTDA",
     "Nome Fantasia\/Apelido": "NRL REPRESENTACOES",
     "CNPJ": "17.957.326\/0001-04",
     "CNAE Principal": "4613-3\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "09\/11\/2022",
     "Estado": "GO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 742,
     "Tipo": "PJ",
     "Razão Social\/Nome": "JULIO CESAR CERIALI 32800346809",
     "Nome Fantasia\/Apelido": "CERIALI MANUTENCOES E INSTALACOES ELETR",
     "CNPJ": "34.851.151\/0001-75",
     "CNAE Principal": "4321-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "09\/11\/2022",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 743,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MINERACAO Z DANTAS - COM. TRANSP. E AGROP. LTDA",
     "Nome Fantasia\/Apelido": "MINERAO Z DANTAS",
     "CNPJ": " 06.208.500\/0001-08",
     "CNAE Principal": " 0810-0\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "10\/11\/2022",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 744,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CONSULTEC PROJETOS DE ENGENHARIA LTDA",
     "Nome Fantasia\/Apelido": "CONSULTEC",
     "CNPJ": "43.509.993\/0001-34",
     "CNAE Principal": "42.21-9-04 -",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "10\/11\/2022",
     "Estado": "SC",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 745,
     "Tipo": "PJ",
     "Razão Social\/Nome": "A E ARAUJO DA SILVA LTDA",
     "Nome Fantasia\/Apelido": "CENTER ACESSORIOS E VARIEDADES",
     "CNPJ": "31.092.913\/0001-09",
     "CNAE Principal": "4752-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "12\/11\/2022",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 746,
     "Tipo": "PJ",
     "Razão Social\/Nome": "V CAMPELO GOMES LTDA",
     "Nome Fantasia\/Apelido": "V GOMES AUTOPECAS",
     "CNPJ": "31.031.308\/0001-28",
     "CNAE Principal": "4530-7\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "12\/11\/2022",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 747,
     "Tipo": "PJ",
     "Razão Social\/Nome": "4PEOPLE PROMOCOES, EVENTOS E CONSULTORIA LTDA",
     "Nome Fantasia\/Apelido": "4PEOPLE PROMOCOES, EVENTOS E CONSULTORIA",
     "CNPJ": "27.342.786\/0001-81",
     "CNAE Principal": "8230-0\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "11\/11\/2022",
     "Estado": "PE",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 748,
     "Tipo": "PJ",
     "Razão Social\/Nome": "VALDEIR PAULO ALONSO 25873322813",
     "Nome Fantasia\/Apelido": "MASTER AUTOMACAO",
     "CNPJ": "31.649.160\/0001-90",
     "CNAE Principal": "4321-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "11\/11\/2022",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 749,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SERGIO VELOZO REFORMAS E MANUTENCOES",
     "Nome Fantasia\/Apelido": "CONTRACTOR SERVICE",
     "CNPJ": " 03.879.189\/0001-22",
     "CNAE Principal": "4330-4\/04",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "16\/11\/2022",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 754,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DESIDERATUM ASSESSORIA ESTRATEGICA EM INFORMATICA LTDA",
     "Nome Fantasia\/Apelido": "GV2C CONSULTING & SOLUTIONS",
     "CNPJ": " 08.950.711\/0001-39",
     "CNAE Principal": "6201-5\/01",
     "Região": 6,
     "Situação": "Ativa",
     "Data Cadastro": "17\/11\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 759,
     "Tipo": "PJ",
     "Razão Social\/Nome": "VINICIUS SEVERINO DE JESUS",
     "Nome Fantasia\/Apelido": "VSJ ELETROTECNICA",
     "CNPJ": "16.507.134\/0001-33",
     "CNAE Principal": "4321-5\/00",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "17\/11\/2022",
     "Estado": "DF",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 760,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ALINE MENDONCA SILVA ALBUQUERQUE LTDA",
     "Nome Fantasia\/Apelido": "AM ODONTOLOGIA",
     "CNPJ": "41.965.706\/0001-75",
     "CNAE Principal": "8630-5\/04",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "17\/11\/2022",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 761,
     "Tipo": "PJ",
     "Razão Social\/Nome": "FABRICIO RODRIGUES DE OLIVEIRA SILVA 37200800805",
     "Nome Fantasia\/Apelido": "JAPA MANUTENCOES",
     "CNPJ": "46.025.465\/0001-06",
     "CNAE Principal": "3321-0\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "17\/11\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 762,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MAFFENG ENGENHARIA E MANUTENCAO EIRELI",
     "Nome Fantasia\/Apelido": "MAFFENG ENGENHARIA E MANUTENCAO",
     "CNPJ": "33.624.704\/0001-94",
     "CNAE Principal": "4120-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "17\/11\/2022",
     "Estado": "GO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 763,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LEMA LABORATORIO DE ANALISES CLINICA EIRELI",
     "Nome Fantasia\/Apelido": "LEMA LABORATORIO DE ANALISES CLINICA",
     "CNPJ": "21.302.618\/0001-86",
     "CNAE Principal": "8640-2\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "18\/11\/2022",
     "Estado": "TO",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 764,
     "Tipo": "PJ",
     "Razão Social\/Nome": "PEDRO PAULO LISBOA 74465163991",
     "Nome Fantasia\/Apelido": "BOM CLIMA MANUTENCAO E INSTALACAO",
     "CNPJ": "21.857.686\/0001-01",
     "CNAE Principal": "4322-3\/02",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "21\/11\/2022",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 765,
     "Tipo": "PJ",
     "Razão Social\/Nome": "F.C. MOURA OFICINA MECANICA EIRELI",
     "Nome Fantasia\/Apelido": "MOURA OFICINA MECNICA",
     "CNPJ": "29.117.613\/0001-95",
     "CNAE Principal": "4520-0\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "21\/11\/2022",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 766,
     "Tipo": "PJ",
     "Razão Social\/Nome": "R F BARBOSA EIRELI",
     "Nome Fantasia\/Apelido": "MAGAZINE SANTA LUCIA",
     "CNPJ": "83.313.189\/0001-08",
     "CNAE Principal": "4781-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "21\/11\/2022",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 767,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CODIGO EVENTOS LTDA",
     "Nome Fantasia\/Apelido": "CODIGO EVENTOS",
     "CNPJ": "35.609.083\/0001-03",
     "CNAE Principal": "7729-2\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "22\/11\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 768,
     "Tipo": "PJ",
     "Razão Social\/Nome": "FLOR BRANCA COMERCIO DE VESTUARIO LTDA",
     "Nome Fantasia\/Apelido": "MAGAZINE SANTA LUCIA",
     "CNPJ": " 09.102.911\/0001-02",
     "CNAE Principal": "4781-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "22\/11\/2022",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 769,
     "Tipo": "PJ",
     "Razão Social\/Nome": "L L C BARBOSA EIRELI",
     "Nome Fantasia\/Apelido": "MAGAZINE SANTA LUCIA",
     "CNPJ": " 05.279.894\/0001-14",
     "CNAE Principal": "4781-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "22\/11\/2022",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 770,
     "Tipo": "PJ",
     "Razão Social\/Nome": "BALANCO SOLUCOES EM ENGENHARIA LTDA",
     "Nome Fantasia\/Apelido": "BALANCO SOLUCOES EM ENGENHARIA LTDA",
     "CNPJ": "33.003.684\/0001-34",
     "CNAE Principal": "7112-0\/00",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "22\/11\/2022",
     "Estado": "RS",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 771,
     "Tipo": "PJ",
     "Razão Social\/Nome": "YASMIM FASHION COMERCIO DE CONFECCOES LTDA",
     "Nome Fantasia\/Apelido": "MAGAZINE SANTA LUCIA",
     "CNPJ": " 09.441.073\/0001-93",
     "CNAE Principal": "4781-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "22\/11\/2022",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 773,
     "Tipo": "PJ",
     "Razão Social\/Nome": "NATHANNA ALVES DE CASTRO",
     "Nome Fantasia\/Apelido": "JOHN MODA",
     "CNPJ": "12.357.321\/0001-27",
     "CNAE Principal": "4755-5\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "22\/11\/2022",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 774,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CARBON METAIS E SERVICOS LTDA",
     "Nome Fantasia\/Apelido": "CARBON",
     "CNPJ": "43.941.235\/0001-90",
     "CNAE Principal": "4292-8\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "23\/11\/2022",
     "Estado": "ES",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 775,
     "Tipo": "PJ",
     "Razão Social\/Nome": "EMR MANUTENCAO E INSTALACAO ELETRICA LTDA",
     "Nome Fantasia\/Apelido": "E. S. DA SILVA MANUTENCOES",
     "CNPJ": "32.552.194\/0001-24",
     "CNAE Principal": "4321-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "23\/11\/2022",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 777,
     "Tipo": "PF",
     "Razão Social\/Nome": "RUBENS ANTONIO DE AZEVEDO",
     "Nome Fantasia\/Apelido": "FAZENDA ALVORADA ",
     "CPF": " 051.756.581-15",
     "CNAE Principal": " 0151201 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "23\/11\/2022",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 779,
     "Tipo": "PJ",
     "Razão Social\/Nome": "AILTON JOSE TRENS ",
     "Nome Fantasia\/Apelido": "PODASUL",
     "CNPJ": "41.199.800\/0001-60",
     "CNAE Principal": " 0161-0\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "23\/11\/2022",
     "Estado": "RS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 780,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MARKET SIZING BRASIL LTDA",
     "Nome Fantasia\/Apelido": "M-SIZING",
     "CNPJ": "43.528.916\/0001-21",
     "CNAE Principal": "6204-0\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "24\/11\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 781,
     "Tipo": "PJ",
     "Razão Social\/Nome": "EDUARDO LOURENCO DA COSTA NETO 07375572658",
     "Nome Fantasia\/Apelido": "LIMPA FOSSA COMBINADO",
     "CNPJ": "20.310.412\/0001-35",
     "CNAE Principal": "4782-2\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "24\/11\/2022",
     "Estado": "MG",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 783,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MAD MINERACAO LTDA",
     "Nome Fantasia\/Apelido": "MAD AMBIENTAL",
     "CNPJ": "11.480.455\/0002-59",
     "CNAE Principal": "3811-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "25\/11\/2022",
     "Estado": "MG",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 784,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SILVA & SILVA SERVICO DE VIGILANCIA LTDA",
     "Nome Fantasia\/Apelido": "G. O. P. SERVICOS",
     "CNPJ": "36.714.298\/0001-49",
     "CNAE Principal": "8011-1\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "21\/03\/2023",
     "Estado": "PE",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 785,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MIYAZAKI MATERIAIS PARA CONSTRUCOES E FERRAGENS LTDA",
     "Nome Fantasia\/Apelido": "MIYAZAKI",
     "CNPJ": "55.286.850\/0001-90",
     "CNAE Principal": "4744-0\/99",
     "Região": 7,
     "Situação": "Ativa",
     "Data Cadastro": "25\/11\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 787,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CONSULTORIO MEDICO ELIANE CABRAL RODRIGUES DE ARAUJO LTDA",
     "Nome Fantasia\/Apelido": "CONSULTORIO MEDICO ELIANE CABRAL RODRIGUES DE ARAUJO LTDA",
     "CNPJ": "37.307.137\/0001-01",
     "CNAE Principal": "8630-5\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "27\/11\/2022",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 788,
     "Tipo": "PJ",
     "Razão Social\/Nome": "JOSEPH SOUZA BATISTA",
     "Nome Fantasia\/Apelido": "ELETROTECNICA JOSE DE SOUZA",
     "CNPJ": "17.785.993\/0001-57",
     "CNAE Principal": "3313-9\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "27\/11\/2022",
     "Estado": "MA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 789,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ASSOCIACAO CULTURAL E ASSISTENCIAL NOVA EVANGELIZACAO DE PRESIDENTE EPITACIO",
     "CNPJ": " 01.610.990\/0001-42",
     "CNAE Principal": "9430-8\/00",
     "Região": 7,
     "Situação": "Ativa",
     "Data Cadastro": "29\/11\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 790,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ELETROLUZ HOME CENTER MATERIAIS ELETRICOS E HIDRAULICOS LTDA",
     "Nome Fantasia\/Apelido": "ELETROLUZ",
     "CNPJ": " 02.493.027\/0001-99",
     "CNAE Principal": "4742-3\/00",
     "Região": 7,
     "Situação": "Ativa",
     "Data Cadastro": "29\/11\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 791,
     "Tipo": "PJ",
     "Razão Social\/Nome": "YANAGUIDA & YANAGUIDA LTDA",
     "Nome Fantasia\/Apelido": "POUSADA DO SOSSEGO",
     "CNPJ": "10.360.786\/0001-01",
     "CNAE Principal": "5510-8\/01",
     "Região": 7,
     "Situação": "Ativa",
     "Data Cadastro": "29\/11\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 792,
     "Tipo": "PJ",
     "Razão Social\/Nome": "PREMIER COMERCIO E SERVICOS LTDA",
     "Nome Fantasia\/Apelido": "POINT DAS BOMBAS",
     "CNPJ": "16.501.549\/0001-08",
     "CNAE Principal": "9609-2\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "29\/11\/2022",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 793,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MASSA PURA PANIFICADORA E CONFEITARIA LTDA",
     "Nome Fantasia\/Apelido": "MASSA PURA PANIFICADORA E CONFEITARIA",
     "CNPJ": "28.114.413\/0001-16",
     "CNAE Principal": "4721-1\/02",
     "Região": 7,
     "Situação": "Ativa",
     "Data Cadastro": "29\/11\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 795,
     "Tipo": "PJ",
     "Razão Social\/Nome": "B W B VELOSO NETTO EIRELI",
     "Nome Fantasia\/Apelido": "AUTOESCOLA VIGIA",
     "CNPJ": "38.280.211\/0001-06",
     "CNAE Principal": "8599-6\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "29\/11\/2022",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 797,
     "Tipo": "PJ",
     "Razão Social\/Nome": "TECNOVOLT INSTALACOES ELETRICAS LTDA",
     "CNPJ": "43.723.466\/0001-28",
     "CNAE Principal": "3321-0\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "30\/11\/2022",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 798,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CARAPICUIBA COMERCIO DE GAS LTDA",
     "Nome Fantasia\/Apelido": "NASA GAS",
     "CNPJ": " 06.078.981\/0001-76",
     "CNAE Principal": "4784-9\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "30\/11\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 799,
     "Tipo": "PF",
     "Razão Social\/Nome": "IVANI SANTOS MONFORT ",
     "Nome Fantasia\/Apelido": "FAZENDA SANTA FÉ",
     "CPF": "264.004.508-31",
     "CNAE Principal": " 0151201",
     "Região": 7,
     "Situação": "Ativa",
     "Data Cadastro": "30\/11\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 800,
     "Tipo": "PJ",
     "Razão Social\/Nome": "NASA GAS  LTDA EPP",
     "Nome Fantasia\/Apelido": "NASA GAS",
     "CNPJ": "25.276.558\/0001-25",
     "CNAE Principal": "4784-9\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "30\/11\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 801,
     "Tipo": "PF",
     "Razão Social\/Nome": "BRUNO FERREIRA DE ARAUJO ",
     "Nome Fantasia\/Apelido": "FAZENDA B.B ARAUJO",
     "CPF": "889.626.011-68",
     "CNAE Principal": " 01.15-6",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "30\/11\/2022",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 802,
     "Tipo": "PJ",
     "Razão Social\/Nome": "TEREZA BUENO GONCALVES",
     "Nome Fantasia\/Apelido": "SUPER SACOLAO ARISTON",
     "CNPJ": " 01.846.076\/0001-03",
     "CNAE Principal": "4712-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "30\/11\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 803,
     "Tipo": "PJ",
     "Razão Social\/Nome": "OPCAO COMERCIO DE GLP LTDA",
     "Nome Fantasia\/Apelido": "OPCAO COMERCIO DE GAS",
     "CNPJ": "36.686.207\/0001-09",
     "CNAE Principal": "4784-9\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "30\/11\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 805,
     "Tipo": "PJ",
     "Razão Social\/Nome": "REBOCAR INDUSTRIA E COMERCIO DE REBOQUES LTDA",
     "Nome Fantasia\/Apelido": "REBOCAR",
     "CNPJ": " 08.056.291\/0001-41",
     "CNAE Principal": "2930-1\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "01\/12\/2022",
     "Estado": "PR",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 806,
     "Tipo": "PJ",
     "Razão Social\/Nome": "J. E. T. DO NASCIMENTO FILHO DROGARIA",
     "Nome Fantasia\/Apelido": "VAL DROGARIA",
     "CNPJ": "28.625.981\/0001-81",
     "CNAE Principal": "4771-7\/01",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "01\/12\/2022",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 807,
     "Tipo": "PJ",
     "Razão Social\/Nome": "L. P. L. COELHO COM. DE ARTIGOS OPTICOS",
     "Nome Fantasia\/Apelido": "OTICA BOA VISTA",
     "CNPJ": "21.423.809\/0001-04",
     "CNAE Principal": "4774-1\/00",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "01\/12\/2022",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 808,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ISAQUY DE SOUSA OLIVEIRA 06005576429",
     "Nome Fantasia\/Apelido": "MARIA DECOR",
     "CNPJ": "35.270.945\/0001-08",
     "CNAE Principal": "3102-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "03\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 809,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CONFITECH ASSISTENCIA TECNICA HOSPITALAR LTDA",
     "Nome Fantasia\/Apelido": "CONFITECH",
     "CNPJ": "12.419.502\/0001-30",
     "CNAE Principal": "4773-3\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "04\/12\/2022",
     "Estado": "DF",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 810,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DAMARO & MARTINS LTDA",
     "Nome Fantasia\/Apelido": "POUSADA CURURU",
     "CNPJ": " 04.261.622\/0001-24",
     "CNAE Principal": "5590-6\/99",
     "Região": 7,
     "Situação": "Ativa",
     "Data Cadastro": "05\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 811,
     "Tipo": "PJ",
     "Razão Social\/Nome": "BETTUNI TERCEIRIZACAO DE SERVICOS DE LIMPEZA E PORTARIA LTDA",
     "Nome Fantasia\/Apelido": "BETTUNI FACILITIES",
     "CNPJ": "41.007.646\/0001-88",
     "CNAE Principal": "8121-4\/00",
     "Região": 1,
     "Situação": "Ativa",
     "Data Cadastro": "05\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 812,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MARCENARIA ANDRADE EIRELI",
     "Nome Fantasia\/Apelido": "MARCENARIA ANDRADE",
     "CNPJ": "23.764.547\/0001-69",
     "CNAE Principal": "3329-5\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "05\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 813,
     "Tipo": "PJ",
     "Razão Social\/Nome": "NEVES E ANDRADE ATIVIDADES DE CONDICIONAMENTO FISICO E LUTAS LTDA",
     "Nome Fantasia\/Apelido": "FA ATIVIDADE FISICA E SAUDE",
     "CNPJ": "38.625.692\/0001-36",
     "CNAE Principal": "9313-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "06\/12\/2022",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 814,
     "Tipo": "PJ",
     "Razão Social\/Nome": "M.S TRANSPORTES DE CARGAS RODOVIARIAS LTDA",
     "Nome Fantasia\/Apelido": "MS TRANSPORTES",
     "CNPJ": "41.654.325\/0001-75",
     "CNAE Principal": "4930-2\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "07\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 816,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ALMEIDA INDUSTRIA E COMERCIO DE COSMETICOS EIRELI",
     "Nome Fantasia\/Apelido": "MAXIMUS HAIR",
     "CNPJ": "12.075.402\/0001-34",
     "CNAE Principal": "4772-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "07\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 818,
     "Tipo": "PJ",
     "Razão Social\/Nome": "NATIONAL ENGENHARIA E ARQUITETURA LTDA",
     "CNPJ": "10.571.968\/0001-21",
     "CNAE Principal": "7112-0\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "07\/12\/2022",
     "Estado": "PR",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 819,
     "Tipo": "PJ",
     "Razão Social\/Nome": "V. R -  SERVICOS  DE TELECOMUNICACOES LTDA",
     "Nome Fantasia\/Apelido": "V R TELECOM",
     "CNPJ": "37.391.735\/0001-01",
     "CNAE Principal": "9512-6\/00",
     "Região": 1,
     "Situação": "Ativa",
     "Data Cadastro": "07\/12\/2022",
     "Estado": "SC",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 820,
     "Tipo": "PJ",
     "Razão Social\/Nome": "OMEGA PROVEDOR E SERVICOS DE INTERNET EIRELI",
     "Nome Fantasia\/Apelido": "OMEGA TELECOM",
     "CNPJ": "21.901.257\/0001-94",
     "CNAE Principal": "6190-6\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "07\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 821,
     "Tipo": "PJ",
     "Razão Social\/Nome": "PAULA SEVERINO SOCIEDADE INDIVIDUAL DE ADVOCACIA",
     "CNPJ": "26.657.355\/0001-41",
     "CNAE Principal": "6911-7\/01",
     "Região": 7,
     "Situação": "Ativa",
     "Data Cadastro": "08\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 822,
     "Tipo": "PJ",
     "Razão Social\/Nome": "TOI SERVICE LTDA",
     "CNPJ": " 04.183.326\/0001-52",
     "CNAE Principal": "8211-3\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "08\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 823,
     "Tipo": "PJ",
     "Razão Social\/Nome": "RURAL PLAN - PLANEJAMENTOS E CONSULTORIA AGROPECUARIA LTDA",
     "Nome Fantasia\/Apelido": "RURAL PLAN",
     "CNPJ": "19.692.608\/0001-52",
     "CNAE Principal": "7490-1\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "08\/12\/2022",
     "Estado": "GO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 824,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MAFC GINECOLOGIA E OBSTETRICIA S\/S EIRELI",
     "Nome Fantasia\/Apelido": "PEROLA MATER GINECOLOGIA E OBSTETRICIA",
     "CNPJ": "24.063.544\/0001-60",
     "CNAE Principal": "8630-5\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "08\/12\/2022",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 825,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MARIZA LOCACOES E SERVICOS LTDA",
     "Nome Fantasia\/Apelido": "MARIZA LOCACOES E SERVICOS",
     "CNPJ": "46.164.068\/0001-07",
     "CNAE Principal": "7732-2\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "09\/12\/2022",
     "Estado": "BA",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 826,
     "Tipo": "PF",
     "Razão Social\/Nome": "DOMINGOS PEREIRA COELHO ",
     "Nome Fantasia\/Apelido": "FAZENDA LAGO RICO ",
     "CPF": " 017.767.701-53",
     "CNAE Principal": " 0141-4\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "09\/12\/2022",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 828,
     "Tipo": "PJ",
     "Razão Social\/Nome": "VERGINIA DOS SANTOS SOUZA LOPES",
     "Nome Fantasia\/Apelido": "DELTA SEG SEGURANCA E AUTOMACAO",
     "CNPJ": "33.222.380\/0001-68",
     "CNAE Principal": "4753-9\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "11\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 829,
     "Tipo": "PJ",
     "Razão Social\/Nome": "JOSE ALEXANDRE LOUZEIRO DE CARVALHO 86314432120",
     "Nome Fantasia\/Apelido": "SUAVE",
     "CNPJ": "36.031.572\/0001-85",
     "CNAE Principal": "4781-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "11\/12\/2022",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 830,
     "Tipo": "PJ",
     "Razão Social\/Nome": "USEGAS COMERCIO DE GLP LTDA",
     "Nome Fantasia\/Apelido": "USEGAS",
     "CNPJ": "12.226.782\/0001-60",
     "CNAE Principal": "4784-9\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "12\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 831,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MARIA MABIA F DO NASCIMENTO & CIA LTDA",
     "Nome Fantasia\/Apelido": "COMERCIAL MAJU",
     "CNPJ": "13.231.874\/0001-00",
     "CNAE Principal": "4639-7\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "12\/12\/2022",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 832,
     "Tipo": "PJ",
     "Razão Social\/Nome": "AUTO POSTO DEZ LTDA",
     "CNPJ": "23.376.671\/0001-57",
     "CNAE Principal": "4731-8\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "12\/12\/2022",
     "Estado": "ES",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 833,
     "Tipo": "PJ",
     "Razão Social\/Nome": "AZEVEDO & FOGLIATTO LTDA",
     "Nome Fantasia\/Apelido": "FOGLIATTO ESPECIALIDADES",
     "CNPJ": "40.413.647\/0001-60",
     "CNAE Principal": "8630-5\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "12\/12\/2022",
     "Estado": "TO",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 834,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CARHEGOM CARVALHO SERVICOS LTDA",
     "Nome Fantasia\/Apelido": "CARHEGOM CARVALHO ENGENHARIA E GER. DE PROJETOS INDUSTR",
     "CNPJ": "41.708.353\/0001-28",
     "CNAE Principal": "7112-0\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "12\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 835,
     "Tipo": "PJ",
     "Razão Social\/Nome": "D&C REFRIGERACAO LTDA",
     "Nome Fantasia\/Apelido": "FRIO D+ SERVICOS EM REFRIGERACAO",
     "CNPJ": "22.701.679\/0001-89",
     "CNAE Principal": "4322-3\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "12\/12\/2022",
     "Estado": "BA",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 836,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DOUTOR LUIZ GUEDES SERVICOS LTDA",
     "Nome Fantasia\/Apelido": "CLINICA DR. LUIZ GUEDES",
     "CNPJ": "15.264.505\/0001-30",
     "CNAE Principal": "8630-5\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "12\/12\/2022",
     "Estado": "RJ",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 837,
     "Tipo": "PJ",
     "Razão Social\/Nome": "FOCO COMERCIO E SERVICOS LTDA",
     "CNPJ": "23.077.813\/0001-85",
     "CNAE Principal": "4751-2\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "12\/12\/2022",
     "Estado": "PR",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 838,
     "Tipo": "PJ",
     "Razão Social\/Nome": "FRANCINE PAZ DE ARAUJO - MONTAGENS DE ESTRUTURAS METALICAS",
     "Nome Fantasia\/Apelido": "FPA CONSTRUCOES METALICAS",
     "CNPJ": " 09.454.889\/0001-51",
     "CNAE Principal": "4292-8\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "12\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 839,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DIEGO D. PEREIRA",
     "Nome Fantasia\/Apelido": "GEOCON CONTROLE TECNOLOGICO",
     "CNPJ": "37.860.406\/0001-54",
     "CNAE Principal": "7112-0\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "12\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 840,
     "Tipo": "PJ",
     "Razão Social\/Nome": "GAMARRA E GAMARRA ENGENHARIA LTDA",
     "Nome Fantasia\/Apelido": "GAMARRA ENGENHARIA",
     "CNPJ": "25.043.669\/0001-91",
     "CNAE Principal": "4330-4\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "12\/12\/2022",
     "Estado": "MT",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 841,
     "Tipo": "PJ",
     "Razão Social\/Nome": "GAUTIER NORIEGA ALCANTARA 36534683881",
     "Nome Fantasia\/Apelido": "GNA AUTOMACAO",
     "CNPJ": "34.552.608\/0001-40",
     "CNAE Principal": "3321-0\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "12\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 842,
     "Tipo": "PJ",
     "Razão Social\/Nome": "GLOBAL BELT SOLUCOES INDUSTRIAIS LTDA",
     "Nome Fantasia\/Apelido": "GLOBAL BELT SOLUCOES INDUSTRIAIS",
     "CNPJ": "26.887.584\/0001-52",
     "CNAE Principal": "4663-0\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "12\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 843,
     "Tipo": "PJ",
     "Razão Social\/Nome": "JOSIELMA ALEXANDRE SIQUEIRA",
     "Nome Fantasia\/Apelido": "REBOQUES JOSIELMA",
     "CNPJ": " 03.483.373\/0001-59",
     "CNAE Principal": "2930-1\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "12\/12\/2022",
     "Estado": "BA",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 844,
     "Tipo": "PJ",
     "Razão Social\/Nome": "JULIANE FERREIRA SANTOS",
     "Nome Fantasia\/Apelido": "BLAST - SERVICOS TERCEIRIZADOS",
     "CNPJ": "30.040.797\/0001-11",
     "CNAE Principal": "2532-2\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "12\/12\/2022",
     "Estado": "PB",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 845,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ARPROS SERVICOS ESPECILIZADOS LTDA",
     "Nome Fantasia\/Apelido": "RMP ENGENHARIA",
     "CNPJ": "42.081.341\/0001-89",
     "CNAE Principal": "7112-0\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "12\/12\/2022",
     "Estado": "RS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 846,
     "Tipo": "PJ",
     "Razão Social\/Nome": "RICARDO DE JESUS DIAS 08678541628",
     "Nome Fantasia\/Apelido": "RJ DIAS ENGENHARIA",
     "CNPJ": "24.887.581\/0001-93",
     "CNAE Principal": "4321-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "13\/12\/2022",
     "Estado": "MG",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 847,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MAFRAN AMBIENTAL CONSULTORIA & SERVICOS LTDA",
     "Nome Fantasia\/Apelido": "MAFRAN AMBIENTAL CONSULTORIA & SERVICOS",
     "CNPJ": "18.163.395\/0001-09",
     "CNAE Principal": "7490-1\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "13\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 848,
     "Tipo": "PJ",
     "Razão Social\/Nome": "PAULO PEREIRA DE BRITO 10245311807",
     "Nome Fantasia\/Apelido": "Paulo Brito",
     "CNPJ": "34.103.801\/0001-02",
     "CNAE Principal": "3313-9\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "13\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 849,
     "Tipo": "PJ",
     "Razão Social\/Nome": "RESIDENCIAL 60+ LTDA.",
     "Nome Fantasia\/Apelido": "RESIDENCIAL SENIOR 60+ PARQUE DO IBIRAPUERA",
     "CNPJ": "33.346.286\/0001-10",
     "CNAE Principal": "8711-5\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "13\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 850,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SIDNEI DE OLIVEIRA DA SILVA 30354817841",
     "Nome Fantasia\/Apelido": "SOS REFRIGERACAO E AR CONDICIONADO",
     "CNPJ": "30.151.556\/0001-40",
     "CNAE Principal": "4322-3\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "13\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 851,
     "Tipo": "PJ",
     "Razão Social\/Nome": "RESTAURANTE TEMPEROS DA MA LTDA",
     "Nome Fantasia\/Apelido": "TEMPEROS DA MA",
     "CNPJ": "33.878.531\/0001-30",
     "CNAE Principal": "5620-1\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "13\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 852,
     "Tipo": "PJ",
     "Razão Social\/Nome": "3A CONSTRUTORA E TERRAPLENAGEM LTDA",
     "Nome Fantasia\/Apelido": "3A",
     "CNPJ": "13.164.208\/0001-98",
     "CNAE Principal": "4313-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "13\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 853,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ALEXSANDRO COSTA DOS SANTOS",
     "Nome Fantasia\/Apelido": "DROGARIA BIFARMA",
     "CNPJ": "40.523.285\/0001-60",
     "CNAE Principal": "4771-7\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "13\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 854,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ENGERMAQ ENGENHARIA E SOLUCOES LTDA",
     "Nome Fantasia\/Apelido": "ENGERMAQ",
     "CNPJ": "40.004.881\/0001-33",
     "CNAE Principal": "7112-0\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "13\/12\/2022",
     "Estado": "RS",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 855,
     "Tipo": "PJ",
     "Razão Social\/Nome": "GPR I BARES E RESTAURANTES LTDA",
     "Nome Fantasia\/Apelido": "GPR",
     "CNPJ": "34.699.909\/0001-00",
     "CNAE Principal": "4723-7\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "13\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 856,
     "Tipo": "PJ",
     "Razão Social\/Nome": "IMOBILIARIA E INCORPORADORA PETROPOLIS LTDA",
     "Nome Fantasia\/Apelido": "IMOBILIARIA PETROPOLIS",
     "CNPJ": " 09.021.897\/0001-04",
     "CNAE Principal": "4110-7\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "13\/12\/2022",
     "Estado": "TO",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 857,
     "Tipo": "PJ",
     "Razão Social\/Nome": "INTERNACIONAL TELECOM E INFORMATICA LTDA",
     "CNPJ": "13.734.153\/0001-04",
     "CNAE Principal": "9511-8\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "13\/12\/2022",
     "Estado": "RJ",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 858,
     "Tipo": "PJ",
     "Razão Social\/Nome": "JOSE ANTONIO DE ALMEIDA MANBINI",
     "Nome Fantasia\/Apelido": "JJ MANGINI ELETRICA",
     "CNPJ": "34.410.859\/0001-90",
     "CNAE Principal": "4321-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "13\/12\/2022",
     "Estado": "PR",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 859,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LARISSA RIBEIRO MAZAIA 45952722865",
     "Nome Fantasia\/Apelido": "DA HORA PETISCARIA",
     "CNPJ": "25.143.344\/0001-80",
     "CNAE Principal": "5611-2\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "13\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 860,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MFS TRANSPORTES, COMERCIO E SERVICOS EIRELI",
     "Nome Fantasia\/Apelido": "MFS TRANSPORTES, COMERCIO E SERVICOS",
     "CNPJ": "22.224.744\/0001-22",
     "CNAE Principal": "4930-2\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "13\/12\/2022",
     "Estado": "GO",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 861,
     "Tipo": "PJ",
     "Razão Social\/Nome": "PETROGAS  COMERCIO  E  TRANSPORTE  DE  GLP LTDA",
     "Nome Fantasia\/Apelido": "PETROGAS",
     "CNPJ": "23.862.510\/0001-73",
     "CNAE Principal": "4784-9\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "13\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 862,
     "Tipo": "PJ",
     "Razão Social\/Nome": "PLENO GAS E AGUA LTDA",
     "Nome Fantasia\/Apelido": "PLENO GAS E AGUA",
     "CNPJ": "25.048.742\/0001-18",
     "CNAE Principal": "4784-9\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "13\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 863,
     "Tipo": "PJ",
     "Razão Social\/Nome": "P. S. ANGERAMI TRATAMENTO DE DADOS LTDA",
     "Nome Fantasia\/Apelido": "ZETA DADOS",
     "CNPJ": "34.057.504\/0001-60",
     "CNAE Principal": "6311-9\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "13\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 864,
     "Tipo": "PJ",
     "Razão Social\/Nome": "THRAMACE SERVICOS E INFORMATICA LTDA",
     "Nome Fantasia\/Apelido": "THRAMACE",
     "CNPJ": " 08.845.543\/0001-11",
     "CNAE Principal": "6201-5\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "13\/12\/2022",
     "Estado": "RJ",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 866,
     "Tipo": "PJ",
     "Razão Social\/Nome": "KTEL SOLUCOES EM FACHADAS LTDA",
     "Nome Fantasia\/Apelido": "KTEL SOLUCOES EM FACHADAS",
     "CNPJ": "43.077.420\/0001-89",
     "CNAE Principal": "4329-1\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "13\/12\/2022",
     "Estado": "RS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 867,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LABRAS LABORATORIO DE PATOLOGIA CLINICA LTDA",
     "Nome Fantasia\/Apelido": "LABRAS",
     "CNPJ": "35210176\/0001-52",
     "CNAE Principal": "8640-2\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "14\/12\/2022",
     "Estado": "MG",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 868,
     "Tipo": "PJ",
     "Razão Social\/Nome": "NILSON OLIVEIRA NONATO",
     "CNPJ": "11.534.215\/0001-09",
     "CNAE Principal": "1622-6\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "14\/12\/2022",
     "Estado": "MT",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 869,
     "Tipo": "PJ",
     "Razão Social\/Nome": "C.M.N. SUPERVISAO E MONTAGEM ELETRICA LTDA",
     "Nome Fantasia\/Apelido": "C.M.N. SUPERVISAO E MONTAGEM ELETRICA",
     "CNPJ": "39.912.781\/0001-26",
     "CNAE Principal": "4321-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "14\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 870,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MARCELO LOPES DE OLIVEIRA 08006859892",
     "Nome Fantasia\/Apelido": "VERTICAL INDOOR ESCALADA ESPORTIVA",
     "CNPJ": "32.759.118\/0001-94",
     "CNAE Principal": "4763-6\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "14\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 871,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MGSEG - SAUDE E SEGURANCA DO TRABALHO - LTDA",
     "Nome Fantasia\/Apelido": "MGSEG - SAUDE E SEGURANCA DO TRABALHO",
     "CNPJ": "40.680.291\/0001-20",
     "CNAE Principal": "7490-1\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "14\/12\/2022",
     "Estado": "PR",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 872,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MACHADO MANUTENCAO INDUSTRIAL LTDA",
     "Nome Fantasia\/Apelido": "MACHADO MANUTENCAO INDUSTRIAL",
     "CNPJ": "32.156.677\/0001-00",
     "CNAE Principal": "2539-0\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "14\/12\/2022",
     "Estado": "SC",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 873,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SANTANA E MATERAGIA LTDA",
     "Nome Fantasia\/Apelido": "ROBERTO PINTURAS",
     "CNPJ": "15.069.234\/0001-62",
     "CNAE Principal": "4330-4\/04",
     "Região": 7,
     "Situação": "Ativa",
     "Data Cadastro": "06\/01\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 874,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ACADEMIA ABADAI FITNESS LTDA",
     "CNPJ": "22.730.984\/0001-07",
     "CNAE Principal": "8650-0\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "14\/12\/2022",
     "Estado": "RJ",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 875,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DAMASCENO ARF SERVICOS PETSHOP E VETERINARIOS LTDA",
     "Nome Fantasia\/Apelido": "CIANIMAL",
     "CNPJ": "36.020.007\/0001-12",
     "CNAE Principal": "9609-2\/08",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "14\/12\/2022",
     "Estado": "PA",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 876,
     "Tipo": "PJ",
     "Razão Social\/Nome": "EXPURGOS MAQUINAS E EQUIPAMENTOS AGRICOLA LTDA",
     "CNPJ": "16.698.309\/0001-37",
     "CNAE Principal": "4661-3\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "14\/12\/2022",
     "Estado": "PR",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 877,
     "Tipo": "PJ",
     "Razão Social\/Nome": "JULIO CESAR DA PIEVE",
     "Nome Fantasia\/Apelido": "RENOVACAO SERVICOS",
     "CNPJ": "17.671.904\/0001-41",
     "CNAE Principal": "8121-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "14\/12\/2022",
     "Estado": "RS",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 878,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DS DISTRIBUIDORA E COMERCIO DE ARTIGOS HOSPITALARES E TECNOLOGICOS LTDA",
     "Nome Fantasia\/Apelido": "DS DISTRIBUIDORA",
     "CNPJ": "37.245.599\/0001-33",
     "CNAE Principal": "4785-7\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "14\/12\/2022",
     "Estado": "MG",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 882,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MARROLIVER SUPRIMENTOS DE INFORMATICA LTDA",
     "CNPJ": " 05.360.453\/0001-42",
     "CNAE Principal": "4751-2\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "14\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 883,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ACESSOSHOP COMERCIO DE PRODUTOS PARA ESCRITORIO E RESIDENCIA LTDA",
     "Nome Fantasia\/Apelido": "FASHION CONFECCOES",
     "CNPJ": " 01.809.833\/0001-60",
     "CNAE Principal": "4751-2\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "14\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 884,
     "Tipo": "PJ",
     "Razão Social\/Nome": "WALDIR MAURICIO GUERRA 00810488825",
     "Nome Fantasia\/Apelido": "C7 PARK ESTACIONAMENTO",
     "CNPJ": "26.701.179\/0001-06",
     "CNAE Principal": "5223-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "14\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 885,
     "Tipo": "PJ",
     "Razão Social\/Nome": "C3A GROUP DESENVOLVIMENTO DE SOFTWARES, AUTOMACAO E LOCACAO LTDA",
     "CNPJ": "23.962.219\/0001-77",
     "CNAE Principal": "3321-0\/00",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "15\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 886,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DANYLLO PEREIRA DA SILVA",
     "Nome Fantasia\/Apelido": "CASA DE CARNE SILVA",
     "CNPJ": "42.947.607\/0001-23",
     "CNAE Principal": "4722-9\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "15\/12\/2022",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 890,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ANTONIO VALDENI DE SOUZA NASCIMENTO",
     "Nome Fantasia\/Apelido": "MARMORARIA NASCIMENTO",
     "CNPJ": "27.095.912\/0001-40",
     "CNAE Principal": "2391-5\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "15\/12\/2022",
     "Estado": "PA",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 891,
     "Tipo": "PJ",
     "Razão Social\/Nome": "INSPECTECH CONTROLE DE QUALIDADE LTDA",
     "Nome Fantasia\/Apelido": "INSPECTECH",
     "CNPJ": "13.182.784\/0001-68",
     "CNAE Principal": "4789-0\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "15\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 892,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DANIEL SILVA DOS REIS 26239250899",
     "Nome Fantasia\/Apelido": "DR DESIGNER COMUNICACAO VISUAL",
     "CNPJ": "23.003.475\/0001-37",
     "CNAE Principal": "4329-1\/01",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "15\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 893,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CASA DO NORTE LUIZAO LTDA",
     "Nome Fantasia\/Apelido": "CASA DO NORTE LUIZAO LTDA ME",
     "CNPJ": " 03.770.684\/0001-07",
     "CNAE Principal": "4721-1\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "15\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 894,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MIGUEL ALEXANDRELI JUNIOR",
     "CNPJ": "20.275.139\/0001-55",
     "CNAE Principal": "5611-2\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "16\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 898,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CIESO - CLINICA INTEGRADA DE ESTETICA E SAUDE ODONTOLOGICA LTDA",
     "Nome Fantasia\/Apelido": "CIESO - CLINICA INTEGRADA DE ESTETICA E SAUDE ODONTOLOG",
     "CNPJ": "11.087.694\/0001-62",
     "CNAE Principal": "8630-5\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "22\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 905,
     "Tipo": "PJ",
     "Razão Social\/Nome": "VIDA REVESTIMENTOS LTDA",
     "CNPJ": " 01.463.395\/0001-21",
     "CNAE Principal": "4679-6\/99",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "19\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 906,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MJM SERVICOS E MANUTENCAO LTDA",
     "Nome Fantasia\/Apelido": "MJM MANUTENCOES",
     "CNPJ": "19.338.152\/0001-27",
     "CNAE Principal": "4321-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "19\/12\/2022",
     "Estado": "PE",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 907,
     "Tipo": "PF",
     "Razão Social\/Nome": "RUBIA NICACIA ROCHA ",
     "Nome Fantasia\/Apelido": "ODONTOPREV",
     "CPF": "800.460.021-20",
     "CNAE Principal": "8513-8\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "19\/12\/2022",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 908,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MARLI REGINA DA SILVA QUEIROZ LTDA",
     "Nome Fantasia\/Apelido": "MEGA LABORATORIO DE CONTROLE TECNOLOGICO DO CONCRETO",
     "CNPJ": "42.356.863\/0001-46",
     "CNAE Principal": "7112-0\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "19\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 909,
     "Tipo": "PJ",
     "Razão Social\/Nome": "R. R. GAMA EIRELI",
     "Nome Fantasia\/Apelido": "SUPERMERCADO ECONOMICO",
     "CNPJ": "29.801.140\/0001-40",
     "CNAE Principal": "4712-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "19\/12\/2022",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 910,
     "Tipo": "PJ",
     "Razão Social\/Nome": "COMERCIO E INDUSTRIA COMBRATEL 2000 LTDA",
     "CNPJ": " 00.645.310\/0001-63",
     "CNAE Principal": "4789-0\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "19\/12\/2022",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 911,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CONSULTORIO ODONTOLOGICO LETICIA SOARES BRESSAN EIRELI",
     "CNPJ": "28.256.748\/0001-79",
     "CNAE Principal": "8630-5\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "19\/12\/2022",
     "Estado": "MG",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 913,
     "Tipo": "PJ",
     "Razão Social\/Nome": "A M COMERCIO DE EMBALAGENS DE PRES EPITACIO LTDA",
     "Nome Fantasia\/Apelido": "CASA DAS EMBALAGENS",
     "CNPJ": "57.327.074\/0001-54",
     "CNAE Principal": "4789-0\/99",
     "Região": 7,
     "Situação": "Ativa",
     "Data Cadastro": "20\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 914,
     "Tipo": "PJ",
     "Razão Social\/Nome": "VANESSA SANCHES DE SOUZA RIBAS OLIVEIRA",
     "Nome Fantasia\/Apelido": "KARA MELADA",
     "CNPJ": " 04.988.452\/0001-84",
     "CNAE Principal": "4781-4\/00",
     "Região": 7,
     "Situação": "Ativa",
     "Data Cadastro": "20\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 915,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DEPOSITO DE MATERIAIS PARA CONSTRUCAO G.M.B. LTDA",
     "Nome Fantasia\/Apelido": "DEPOSITO FIMBOM",
     "CNPJ": "10.397.509\/0001-73",
     "CNAE Principal": "4744-0\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "20\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 916,
     "Tipo": "PJ",
     "Razão Social\/Nome": "FFB OBRAS E PAVIMENTACAO EIRELI",
     "CNPJ": "40.881.238\/0001-98",
     "CNAE Principal": "4211-1\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "20\/12\/2022",
     "Estado": "SC",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 917,
     "Tipo": "PJ",
     "Razão Social\/Nome": "RAIO SOLAR INSTALACAO E MANUTENCAO ELETRICA - EIRELI",
     "Nome Fantasia\/Apelido": "RAIO SOLAR ENERGIA",
     "CNPJ": " 07.489.399\/0001-65",
     "CNAE Principal": "4321-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "20\/12\/2022",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 918,
     "Tipo": "PJ",
     "Razão Social\/Nome": "VET CENTER DE PRESIDENTE EPITACIO LTDA",
     "Nome Fantasia\/Apelido": "VET CENTER",
     "CNPJ": "29.696.511\/0001-71",
     "CNAE Principal": "7500-1\/00",
     "Região": 7,
     "Situação": "Ativa",
     "Data Cadastro": "21\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 919,
     "Tipo": "PJ",
     "Razão Social\/Nome": "FIGUEIREDO DA SILVA & FIGUEIREDO DA SILVA LTDA",
     "Nome Fantasia\/Apelido": "MOTOCAR",
     "CNPJ": "10.292.643\/0001-00",
     "CNAE Principal": "4541-2\/06",
     "Região": 7,
     "Situação": "Ativa",
     "Data Cadastro": "21\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 920,
     "Tipo": "PJ",
     "Razão Social\/Nome": "JOSEMAR RODRIGUES COSTA",
     "Nome Fantasia\/Apelido": "AUTO POSTO CRISTAL",
     "CNPJ": "17.617.662\/0001-08",
     "CNAE Principal": "4731-8\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "21\/12\/2022",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 921,
     "Tipo": "PJ",
     "Razão Social\/Nome": "IVAN LAZARO LOPES",
     "Nome Fantasia\/Apelido": "FERRAGISTA IRMAOS LAZARO",
     "CNPJ": "12.049.721\/0001-75",
     "CNAE Principal": "4712-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "21\/12\/2022",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 922,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CLAREL SOLUCOES EM TECNOLOGIA E MARKETING LTDA",
     "Nome Fantasia\/Apelido": "CLAREL SOLUCOES EM MARKETING",
     "CNPJ": " 01.141.914\/0001-35",
     "CNAE Principal": "7319-0\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "21\/12\/2022",
     "Estado": "RS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 923,
     "Tipo": "PJ",
     "Razão Social\/Nome": "AUTO  POSTO FALCAO LTDA",
     "Nome Fantasia\/Apelido": "POSTO FALCAO II",
     "CNPJ": " 02.357.386\/0002-08",
     "CNAE Principal": "4731-8\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "21\/12\/2022",
     "Estado": "PI",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 924,
     "Tipo": "PJ",
     "Razão Social\/Nome": "M F L L DA SILVA EIRELI",
     "Nome Fantasia\/Apelido": "PARAISO IMPERIAL",
     "CNPJ": "10.835.704\/0001-38",
     "CNAE Principal": "4754-7\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "21\/12\/2022",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 925,
     "Tipo": "PJ",
     "Razão Social\/Nome": "AUTO  POSTO FALCAO LTDA.",
     "Nome Fantasia\/Apelido": "POSTO FALCAO",
     "CNPJ": " 02.357.386\/0001-19",
     "CNAE Principal": "4731-8\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "21\/12\/2022",
     "Estado": "PI",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 926,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CAPITAO POÇO TECIDOS LTDA",
     "Nome Fantasia\/Apelido": "R&V IMPERIAL CENTER",
     "CNPJ": "83.904.193\/0001-32",
     "CNAE Principal": "4754-7\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "21\/12\/2022",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 927,
     "Tipo": "PJ",
     "Razão Social\/Nome": "R P DA SILVA - EIRELI",
     "Nome Fantasia\/Apelido": "R&V IMPERIAL CENTER",
     "CNPJ": "20.798.579\/0002-78",
     "CNAE Principal": "4753-9\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "21\/12\/2022",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 928,
     "Tipo": "PJ",
     "Razão Social\/Nome": "PROFIVEST CURSOS PREPARATORIOS EIRELI",
     "Nome Fantasia\/Apelido": "CURSO PROFITEC",
     "CNPJ": "30.689.310\/0001-26",
     "CNAE Principal": "8599-6\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "21\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 929,
     "Tipo": "PJ",
     "Razão Social\/Nome": "R P DA SILVA - LTDA",
     "Nome Fantasia\/Apelido": "R&V IMPERIAL CENTER",
     "CNPJ": "20.798.579\/0001-97",
     "CNAE Principal": "4753-9\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "21\/12\/2022",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 930,
     "Tipo": "PJ",
     "Razão Social\/Nome": "R P DA SILVA - EIRELI FILIAL",
     "Nome Fantasia\/Apelido": "R&V IMPERIAL CENTER",
     "CNPJ": "20.798.579\/0003-59",
     "CNAE Principal": "4753-9\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "21\/12\/2022",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 931,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CALDAS TECIDOS LTDA",
     "Nome Fantasia\/Apelido": "R&M IMPERIAL CENTER",
     "CNPJ": "86.951.241\/0001-31",
     "CNAE Principal": "4753-9\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "21\/12\/2022",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 932,
     "Tipo": "PJ",
     "Razão Social\/Nome": "FLAVIO VIEIRA DE OLIVEIRA",
     "Nome Fantasia\/Apelido": "MONTE CARLO SERVICOS",
     "CNPJ": "18.656.403\/0001-59",
     "CNAE Principal": "4930-2\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "21\/12\/2022",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 933,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LABLAR ARTIGOS E EQUIPAMENTOS PARA LABORATORIO LTDA",
     "CNPJ": "65.636.268\/0001-60",
     "CNAE Principal": "4789-0\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "21\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 934,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ROSILEI COLPANI 01551227975",
     "Nome Fantasia\/Apelido": "VIDACREDI PROMOTORA DE VENDAS",
     "CNPJ": "29.423.992\/0001-41",
     "CNAE Principal": "8291-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "21\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 935,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ACADEMIA DE LETRAS E NUMEROS EIRELI",
     "Nome Fantasia\/Apelido": "ACADEMIA DE LETRAS E NUMEROS",
     "CNPJ": " 03.344.864\/0001-19",
     "CNAE Principal": "8599-6\/05",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "21\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 936,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CONDOMINIO EDIFICIO GENEVE BLOCO A",
     "CNPJ": "72.381.718\/0001-86",
     "CNAE Principal": "8112-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "21\/12\/2022",
     "Estado": "CE",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 937,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LEG SPA SERVICOS TERAPEUTICOS LTDA.",
     "Nome Fantasia\/Apelido": "LEG SPA SERVICOS TERAPEUTICOS LTDA.",
     "CNPJ": "45.450.516\/0001-76",
     "CNAE Principal": "9602-5\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "21\/12\/2022",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 938,
     "Tipo": "PJ",
     "Razão Social\/Nome": "NGU,OBRAS,INSTALACOES, MONTAGENS E SERVICOS ESPECIALIZADOS PARA A CONSTRUCAO EIRELI",
     "CNPJ": "34.939.717\/0001-15",
     "CNAE Principal": "4329-1\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "22\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 940,
     "Tipo": "PJ",
     "Razão Social\/Nome": "PEDRASSA SUPERMERCADO EIRELI",
     "Nome Fantasia\/Apelido": "PEG PAG GIRASSOL",
     "CNPJ": "14.920.999\/0001-00",
     "CNAE Principal": "4711-3\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "22\/12\/2022",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 941,
     "Tipo": "PJ",
     "Razão Social\/Nome": "A. B. TEIXEIRA PUBLICIDADE",
     "Nome Fantasia\/Apelido": "EXCLUSIVA",
     "CNPJ": " 09.008.488\/0001-78",
     "CNAE Principal": "1813-0\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "22\/12\/2022",
     "Estado": "PI",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 942,
     "Tipo": "PJ",
     "Razão Social\/Nome": "WILLIANS APARECIDO BERNARDES FERREIRA 32970461803",
     "Nome Fantasia\/Apelido": "LLIANS VIDRO",
     "CNPJ": "24.667.344\/0001-17",
     "CNAE Principal": "4330-4\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "22\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 943,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DISTRIBUIDORA DE ALIMENTOS JP EIRELI",
     "Nome Fantasia\/Apelido": "DISTRIBUIDORA DE ALIMENTOS GIRASSOL",
     "CNPJ": "21.877.927\/0001-84",
     "CNAE Principal": "4724-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "22\/12\/2022",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 944,
     "Tipo": "PF",
     "Razão Social\/Nome": "RONILDO PEREIRA DA SILVA ",
     "Nome Fantasia\/Apelido": "SITIO IMPERIAL",
     "CPF": "485.069.901-49",
     "CNAE Principal": " 0121101",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "22\/12\/2022",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 945,
     "Tipo": "PJ",
     "Razão Social\/Nome": "A A DE LIMA TECNOLOGIA E COMPUTADORES",
     "Nome Fantasia\/Apelido": "EURO TECNOLOGIA",
     "CNPJ": "21.382.297\/0001-77",
     "CNAE Principal": "4752-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "22\/12\/2022",
     "Estado": "MT",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 946,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MIRANDA S FOOD LTDA",
     "Nome Fantasia\/Apelido": "PLANET PIZZA",
     "CNPJ": "39.480.693\/0001-00",
     "CNAE Principal": "4729-6\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "22\/12\/2022",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 947,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CENTRO EDUCACIONAL GREENLEAF LTDA.",
     "Nome Fantasia\/Apelido": "GREENLEAF",
     "CNPJ": "26.662.459\/0001-44",
     "CNAE Principal": "8219-9\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "22\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 948,
     "Tipo": "PJ",
     "Razão Social\/Nome": "KENTEX COMERCIAL LTDA",
     "CNPJ": " 00.029.704\/0001-97",
     "CNAE Principal": "4721-1\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "22\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 949,
     "Tipo": "PJ",
     "Razão Social\/Nome": "TAYNARA SOUZA SILVA 01777036283",
     "Nome Fantasia\/Apelido": "DOCE TAY",
     "CNPJ": "46.909.281\/0001-09",
     "CNAE Principal": "56.11-2-03 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "23\/12\/2022",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 952,
     "Tipo": "PJ",
     "Razão Social\/Nome": "WRL CONSTRUTORA LTDA",
     "Nome Fantasia\/Apelido": "WRL CONSTRUTORA",
     "CNPJ": "42.704.964\/0001-60",
     "CNAE Principal": "4120-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "23\/12\/2022",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 953,
     "Tipo": "PJ",
     "Razão Social\/Nome": "G DE S DA SILVA TOPCOM",
     "Nome Fantasia\/Apelido": "TOPCOM",
     "CNPJ": "36.577.535\/0001-77",
     "CNAE Principal": "4752-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "23\/12\/2022",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 954,
     "Tipo": "PJ",
     "Razão Social\/Nome": "E S DA SILVA TOPCOM",
     "Nome Fantasia\/Apelido": "TOPCOM CELULARES E ACESSORIOS",
     "CNPJ": "41.850.969\/0001-39",
     "CNAE Principal": "4752-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "23\/12\/2022",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 955,
     "Tipo": "PJ",
     "Razão Social\/Nome": "G DE S DA SILVA TOPCOM",
     "Nome Fantasia\/Apelido": "USA FONE",
     "CNPJ": "36.577.535\/0002-58",
     "CNAE Principal": "47.52-1-00 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "23\/12\/2022",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 956,
     "Tipo": "PJ",
     "Razão Social\/Nome": "I. PAULA DA SILVA COMERCIO",
     "Nome Fantasia\/Apelido": "MAKE ATELIE",
     "CNPJ": "47.914.662\/0001-30",
     "CNAE Principal": "47.72-5-00 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "23\/12\/2022",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 957,
     "Tipo": "PJ",
     "Razão Social\/Nome": "REIS & JAEHN SONDAGENS & FUNDACOES LTDA",
     "Nome Fantasia\/Apelido": "VER SOLO",
     "CNPJ": "40.831.672\/0001-63",
     "CNAE Principal": "4312-6\/00",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "23\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 958,
     "Tipo": "PJ",
     "Razão Social\/Nome": "KENKO CLINICA MEDICA LTDA",
     "CNPJ": "32.634.813\/0001-20",
     "CNAE Principal": "8630-5\/02",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "23\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 959,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ALUMINIUM SOLUTION ESQUADRIAS DE ALUMINIO E FACHADA LTDA",
     "Nome Fantasia\/Apelido": "ALUMINIUM SOLUTION",
     "CNPJ": "27.819.489\/0001-84",
     "CNAE Principal": "2512-8\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "24\/12\/2022",
     "Estado": "MT",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 960,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MARCIO APARECIDO DE SA - TRANSPORTES",
     "Nome Fantasia\/Apelido": "CARDOSO TRANSPORTES",
     "CNPJ": "12.137.292\/0001-98",
     "CNAE Principal": "7719-5\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "24\/12\/2022",
     "Estado": "GO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 962,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MEDCLIN SERVICOS DE SAUDE LTDA.",
     "Nome Fantasia\/Apelido": "MEDCLIN",
     "CNPJ": "22.834.988\/0001-27",
     "CNAE Principal": "8630-5\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "26\/12\/2022",
     "Estado": "MA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 963,
     "Tipo": "PJ",
     "Razão Social\/Nome": "M.A DA SILVA NOLETO EIRELI",
     "Nome Fantasia\/Apelido": "LOJAS REZENDE",
     "CNPJ": "27.411.394\/0002-08",
     "CNAE Principal": "4781-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "26\/12\/2022",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 964,
     "Tipo": "PJ",
     "Razão Social\/Nome": "F F COMERCIO VAREJISTA DE ARTIGOS DO VESTUARIO LTDA",
     "Nome Fantasia\/Apelido": "LOJAS REZENDE",
     "CNPJ": "42.233.458\/0004-83",
     "CNAE Principal": "47.81-4-00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "26\/12\/2022",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 965,
     "Tipo": "PF",
     "Razão Social\/Nome": "JOÃO JEREZ ORTIZ ESTÂNCIA ALVORADA",
     "Nome Fantasia\/Apelido": "ESTÂNCIA ALVORADA",
     "CPF": "779.576.878-49",
     "CNAE Principal": " 0151201",
     "Região": 7,
     "Situação": "Ativa",
     "Data Cadastro": "26\/12\/2022",
     "Estado": "MS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 966,
     "Tipo": "PJ",
     "Razão Social\/Nome": "EMBATEXTIL SERVICOS DE TRANSPORTES LTDA",
     "CNPJ": "63.063.853\/0001-92",
     "CNAE Principal": "4687-7\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "28\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 967,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LOJAS REZENDE COMERCIO DE CONFECCOES LTDA",
     "Nome Fantasia\/Apelido": "LOJAS REZENDE",
     "CNPJ": "35.500.681\/0002-12",
     "CNAE Principal": "4781-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "26\/12\/2022",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 968,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LOJAS REZENDE COMERCIO DE CONFECCOES LTDA.",
     "Nome Fantasia\/Apelido": "LOJAS REZENDE",
     "CNPJ": "35.500.681\/0001-31",
     "CNAE Principal": "4781-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "26\/12\/2022",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 969,
     "Tipo": "PJ",
     "Razão Social\/Nome": "FERREIRA & PAES LTDA",
     "Nome Fantasia\/Apelido": "CADIKIN",
     "CNPJ": "45.808.450\/0001-43",
     "CNAE Principal": "4712-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "26\/12\/2022",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 970,
     "Tipo": "PF",
     "Razão Social\/Nome": "MARCIO DE CARVALHO LEITAO FAZENDA TAQUARI DA SANTA MARIA ",
     "Nome Fantasia\/Apelido": "FAZENDA TAQUARI DA SANTA MARIA",
     "CPF": " 080.272.488-41",
     "CNAE Principal": " 0151201",
     "Região": 7,
     "Situação": "Ativa",
     "Data Cadastro": "26\/12\/2022",
     "Estado": "MS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 971,
     "Tipo": "PJ",
     "Razão Social\/Nome": "D.S. DA COSTA COMERCIO DE PLANTAS ORNAMENTAIS",
     "Nome Fantasia\/Apelido": "ORQUIDARIO IMPERIAL",
     "CNPJ": "13.404.325\/0001-81",
     "CNAE Principal": "4789-0\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "26\/12\/2022",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 972,
     "Tipo": "PJ",
     "Razão Social\/Nome": "C.H.R. DE OLIVEIRA FITNESS",
     "Nome Fantasia\/Apelido": "SPORT FITNESS GESTAO ESPORTIVA",
     "CNPJ": "13.254.553\/0001-12",
     "CNAE Principal": "9313-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "26\/12\/2022",
     "Estado": "MT",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 973,
     "Tipo": "PJ",
     "Razão Social\/Nome": "COMERCIO VAREJISTA DE ARTIGOS DO VESTUARIO E ACESSORIOS W N LTDA",
     "Nome Fantasia\/Apelido": "MODA W N",
     "CNPJ": "42.233.458\/0001-30",
     "CNAE Principal": "4781-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "27\/12\/2022",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 976,
     "Tipo": "PJ",
     "Razão Social\/Nome": "REI DAS CAPAS PECAS E ACESSORIOS PARA VEICULOS LTDA",
     "Nome Fantasia\/Apelido": "REI DAS CAPAS",
     "CNPJ": "26.266.977\/0001-49",
     "CNAE Principal": "4530-7\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "27\/12\/2022",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 977,
     "Tipo": "PJ",
     "Razão Social\/Nome": "NILTON CARLOS DE OLIVEIRA FILHO LTDA",
     "Nome Fantasia\/Apelido": "CATI TECNOLOGIA E AUTOMACAO",
     "CNPJ": " 07.547.162\/0001-93",
     "CNAE Principal": "9511-8\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "27\/12\/2022",
     "Estado": "GO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 979,
     "Tipo": "PJ",
     "Razão Social\/Nome": "TECFIT RIBEIRAO ACADEMIA E SERVICOS ESPORTIVOS LTDA",
     "Nome Fantasia\/Apelido": "TECFIT RIBEIRAO",
     "CNPJ": "33.682.109\/0001-05",
     "CNAE Principal": "9313-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "27\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 980,
     "Tipo": "PJ",
     "Razão Social\/Nome": "FLASH COMERCIO DE PNEUS DE PRES. EPITACIO LTDA",
     "Nome Fantasia\/Apelido": "FLASH PNEUS",
     "CNPJ": " 02.569.350\/0001-07",
     "CNAE Principal": "4530-7\/05",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "27\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 981,
     "Tipo": "PJ",
     "Razão Social\/Nome": "VILLA CASA LTDA",
     "CNPJ": "19.389.918\/0001-00",
     "CNAE Principal": "4754-7\/01",
     "Região": 7,
     "Situação": "Ativa",
     "Data Cadastro": "27\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 982,
     "Tipo": "PJ",
     "Razão Social\/Nome": "TECFIT MARISTA ACADEMIA E SERVICOS ESPORTIVOS LTDA",
     "Nome Fantasia\/Apelido": "TECFIT MARISTA",
     "CNPJ": "30.724.735\/0001-29",
     "CNAE Principal": "9313-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "27\/12\/2022",
     "Estado": "GO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 983,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DANIEL R DE SOUZA",
     "Nome Fantasia\/Apelido": "DCONTABIL - CONTABILIDADE & SERVICOS",
     "CNPJ": " 04.697.641\/0001-06",
     "CNAE Principal": "6920-6\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "27\/12\/2022",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 984,
     "Tipo": "PJ",
     "Razão Social\/Nome": "F F COMERCIO VAREJISTA DE ARTIGOS DO VESTUARIO LTDA.",
     "Nome Fantasia\/Apelido": "LOJAS REZENDE",
     "CNPJ": "42.233.458\/0003-00",
     "CNAE Principal": "47.81-4-00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "27\/12\/2022",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 985,
     "Tipo": "PF",
     "Razão Social\/Nome": "HELTON DE OLIVEIRA AGUIAR",
     "Nome Fantasia\/Apelido": "FAZENDA CANÃA",
     "CPF": " 002.518.281-15",
     "CNAE Principal": 151201,
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "27\/12\/2022",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Não"
    },
    {
     "Column1": 986,
     "Tipo": "PF",
     "Razão Social\/Nome": "HELTON DE OLIVEIRA AGUIAR",
     "Nome Fantasia\/Apelido": "FAZENDA AROEIRA",
     "CPF": " 002.518.281-15",
     "CNAE Principal": 151201,
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "27\/12\/2022",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 987,
     "Tipo": "PF",
     "Razão Social\/Nome": "GERUSA CORTELETTI RONCONI",
     "Nome Fantasia\/Apelido": " CARTORIO DO SEGUNDO OFICIO.",
     "CPF": "881.183.477-53",
     "CNAE Principal": "69.12-5-00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "28\/12\/2022",
     "Estado": "ES",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 988,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SL LOPES JUNIOR",
     "Nome Fantasia\/Apelido": "DERMANNE",
     "CNPJ": "35.963.608\/0001-04",
     "CNAE Principal": "8630-5\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "28\/12\/2022",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 989,
     "Tipo": "PF",
     "Razão Social\/Nome": "SHINJI TAKAMURA",
     "Nome Fantasia\/Apelido": "SITIO TAKAMURA",
     "CPF": "110.762.498-33",
     "CNAE Principal": " 0151201 ",
     "Região": 7,
     "Situação": "Ativa",
     "Data Cadastro": "28\/12\/2022",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 990,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CLEIDE BALIZA BARROS",
     "Nome Fantasia\/Apelido": "ZITO SAT",
     "CNPJ": " 01.047.813\/0001-08",
     "CNAE Principal": "4751-2\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "28\/12\/2022",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 992,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MAXIPROD INFORMATICA INDUSTRIAL LTDA",
     "CNPJ": "91.090.522\/0001-87",
     "CNAE Principal": "6202-3\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "28\/12\/2022",
     "Estado": "RS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 994,
     "Tipo": "PF",
     "Razão Social\/Nome": "MARCELO RIBAS DE OLIVEIRA",
     "Nome Fantasia\/Apelido": "FAZENDA SÃO JUDAS TADEU",
     "CPF": "117.166.618-70",
     "CNAE Principal": " 0151201",
     "Região": 7,
     "Situação": "Ativa",
     "Data Cadastro": "29\/12\/2022",
     "Estado": "MS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 995,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CENTRO MEDICO VETERINARIO SILVET LTDA",
     "Nome Fantasia\/Apelido": "SILVET",
     "CNPJ": "42.991.667\/0001-43",
     "CNAE Principal": "7500-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "29\/12\/2022",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 996,
     "Tipo": "PF",
     "Razão Social\/Nome": "MARCO ANTONIO RIBAS DE OLIVEIRA",
     "Nome Fantasia\/Apelido": "FAZENDA CANAÃ",
     "CPF": " 093.200.358-30",
     "CNAE Principal": " 0151201",
     "Região": 7,
     "Situação": "Ativa",
     "Data Cadastro": "29\/12\/2022",
     "Estado": "MS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 997,
     "Tipo": "PJ",
     "Razão Social\/Nome": "F F COMERCIO VAREJISTA DE ARTIGOS DO VESTUARIO LTDA,",
     "Nome Fantasia\/Apelido": "LOJAS REZENDE",
     "CNPJ": "42.233.458\/0005-64",
     "CNAE Principal": "47.81-4-00 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "29\/12\/2022",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 998,
     "Tipo": "PJ",
     "Razão Social\/Nome": "M.A DA SILVA NOLETO EIRELI.",
     "Nome Fantasia\/Apelido": "LOJAS REZENDE",
     "CNPJ": "27.411.394\/0003-99",
     "CNAE Principal": "4781-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "30\/12\/2022",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 999,
     "Tipo": "PF",
     "Razão Social\/Nome": "CARLOS WILSON DE OLIVEIRA ",
     "Nome Fantasia\/Apelido": "FAZENDA BOM JESUS",
     "CPF": "779.881.248-20",
     "CNAE Principal": " 0151201",
     "Região": 7,
     "Situação": "Ativa",
     "Data Cadastro": "30\/12\/2022",
     "Estado": "MS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1000,
     "Tipo": "PF",
     "Razão Social\/Nome": "CARLOS WILSON DE OLIVEIRA FAZ BOA VISTA",
     "Nome Fantasia\/Apelido": "FAZENDA BOA VISTA",
     "CPF": "779.881.248-20",
     "CNAE Principal": " 0151201",
     "Região": 7,
     "Situação": "Ativa",
     "Data Cadastro": "30\/12\/2022",
     "Estado": "MS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1001,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LOJAS REZENDE COMERCIO DE CONFECCOES LTDA..",
     "Nome Fantasia\/Apelido": "LOJAS REZENDE",
     "CNPJ": "35.500.681\/0004-84",
     "CNAE Principal": "47.81-4-00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "30\/12\/2022",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1002,
     "Tipo": "PJ",
     "Razão Social\/Nome": "M.A DA SILVA NOLETO EIRELI,",
     "Nome Fantasia\/Apelido": "LOJAS REZENDE",
     "CNPJ": "27.411.394\/0001-27",
     "CNAE Principal": "4781-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "30\/12\/2022",
     "Estado": "GO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1003,
     "Tipo": "PJ",
     "Razão Social\/Nome": "F F COMERCIO VAREJISTA DE ARTIGOS DO VESTUARIO LTDA PEDRO AFONSO",
     "Nome Fantasia\/Apelido": "LOJAS REZENDE",
     "CNPJ": "42.233.458\/0002-11",
     "CNAE Principal": "47.81-4-00 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "30\/12\/2022",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1004,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LOJAS REZENDE COMERCIO DE CONFECCOES LTDA,",
     "Nome Fantasia\/Apelido": "LOJAS REZENDE",
     "CNPJ": "35.500.681\/0003-01",
     "CNAE Principal": "4781-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "30\/12\/2022",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1005,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LOJAS REZENDE COMERCIO DE CONFECCOES LTDA\/",
     "Nome Fantasia\/Apelido": "LOJAS REZENDE",
     "CNPJ": "32.458.076\/0003-13",
     "CNAE Principal": "47.81-4-00 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "30\/12\/2022",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1006,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LOJAS REZENDE COMERCIO DE CONFECCOES LTDA -",
     "Nome Fantasia\/Apelido": "LOJAS REZENDE",
     "CNPJ": "35.500.681\/0005-65",
     "CNAE Principal": "47.81-4-00 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "30\/12\/2022",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1007,
     "Tipo": "PJ",
     "Razão Social\/Nome": "M.A DA SILVA NOLETO EIRELI\/",
     "Nome Fantasia\/Apelido": "LOJAS REZENDE",
     "CNPJ": "27.411.394\/0005-50",
     "CNAE Principal": "4781-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "30\/12\/2022",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1008,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ROGERIO P DA SILVA",
     "Nome Fantasia\/Apelido": "LABOR MEDICAL",
     "CNPJ": "11.794.588\/0001-19",
     "CNAE Principal": "3312-1\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "02\/01\/2023",
     "Estado": "AP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1009,
     "Tipo": "PJ",
     "Razão Social\/Nome": "C&C COMERCIO DE MATERIAIS DE CONSTRUCAO LTDA",
     "Nome Fantasia\/Apelido": "CASA & CIA MATERIAIS DE CONSTRUCAO",
     "CNPJ": "33.155.686\/0001-49",
     "CNAE Principal": "4744-0\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "02\/01\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1010,
     "Tipo": "PF",
     "Razão Social\/Nome": "Eliana Montelo de Souza",
     "Nome Fantasia\/Apelido": "Eliana Montelo de Souza",
     "CPF": "696.825.776-91",
     "CNAE Principal": "6912-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "03\/01\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1011,
     "Tipo": "PF",
     "Razão Social\/Nome": "Benvindo De Souza Neto (Faz. Chaparral)",
     "Nome Fantasia\/Apelido": "Fazenda Chaparral  ",
     "CPF": " 002.734.651-04",
     "CNAE Principal": " 0151-2\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "03\/01\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1012,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ADAO COELHO DA SILVA",
     "Nome Fantasia\/Apelido": "DUPE CALCADOS",
     "CNPJ": "36.996.726\/0001-73",
     "CNAE Principal": "4782-2\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "03\/01\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1015,
     "Tipo": "PJ",
     "Razão Social\/Nome": "EZEQUIEL ROBERTO STELA",
     "CNPJ": "30.221.462\/0001-08",
     "CNAE Principal": "2391-5\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "03\/01\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1016,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CARLENE ALVES PEREIRA SILVA",
     "Nome Fantasia\/Apelido": "A CRIATIVA",
     "CNPJ": " 04.073.162\/0001-00",
     "CNAE Principal": "4713-0\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "03\/01\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1017,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MEU CREPE ALIMENTOS LTDA",
     "CNPJ": "38.542.564\/0003-90",
     "CNAE Principal": "5611-2\/03",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "04\/01\/2023",
     "Estado": "MA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1018,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MEU CREPE ALIMENTOS LTDA.",
     "Nome Fantasia\/Apelido": "MEU CREPE",
     "CNPJ": "38.542.564\/0002-00",
     "CNAE Principal": "5611-2\/03",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "04\/01\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1020,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CSG SERVICOS AUTOMOTIVOS LTDA",
     "Nome Fantasia\/Apelido": "CSG AUTO",
     "CNPJ": "48.623.067\/0001-09",
     "CNAE Principal": "45.20-0-02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "04\/01\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1022,
     "Tipo": "PJ",
     "Razão Social\/Nome": "STORM TELECOM EIRELI",
     "Nome Fantasia\/Apelido": "CASA DO WIRELESS",
     "CNPJ": "29.640.002\/0001-27",
     "CNAE Principal": "4751-2\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "05\/01\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1023,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ELAINE REIS BATISTA",
     "Nome Fantasia\/Apelido": "CONNECTION AMERICAN ENGLISH INSTITUTE",
     "CNPJ": "27.015.423\/0001-31",
     "CNAE Principal": "8593-7\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "05\/01\/2023",
     "Estado": "MG",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1024,
     "Tipo": "PJ",
     "Razão Social\/Nome": "IT WIGS TAVARES COMERCIO IMPORTACAO LTDA.",
     "CNPJ": "46.379.494\/0001-68",
     "CNAE Principal": "4772-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "05\/01\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1025,
     "Tipo": "PJ",
     "Razão Social\/Nome": "BA SERVICOS TECNICOS EIRELI",
     "Nome Fantasia\/Apelido": "BA SERVICOS TECNICOS",
     "CNPJ": " 05.790.939\/0001-10",
     "CNAE Principal": "3321-0\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "05\/01\/2023",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1027,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DUMILA LANCHES E SUCOS LTDA",
     "Nome Fantasia\/Apelido": "Karibune",
     "CNPJ": " 00.805.468\/0001-53",
     "CNAE Principal": "5611-2\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "05\/01\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1028,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DROGARIA SAO GABRIEL LTDA",
     "Nome Fantasia\/Apelido": "DROGARIA SAO GABRIEL",
     "CNPJ": " 01.625.925\/0001-90",
     "CNAE Principal": "4771-7\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "05\/01\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1029,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SOUZA E REIS COMERCIO DE VIDROS LTDA",
     "Nome Fantasia\/Apelido": "VIDRACARIA ANA LAURA",
     "CNPJ": "21.558.744\/0001-04",
     "CNAE Principal": "4743-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "05\/01\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1031,
     "Tipo": "PJ",
     "Razão Social\/Nome": "AGILIZE CONTABILIDADE LTDA",
     "Nome Fantasia\/Apelido": "ESCRITORIO AGILIZE",
     "CNPJ": "46.874.940\/0001-00",
     "CNAE Principal": "69.20-6-01 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "06\/01\/2023",
     "Estado": "MT",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1033,
     "Tipo": "PJ",
     "Razão Social\/Nome": "UIRISLAN ALMEIDA DE SOUZA",
     "Nome Fantasia\/Apelido": "METAL SUL- INDUSTRIA E COMERCIO DE ESTRUTURAS METALICAS",
     "CNPJ": "16.762.976\/0001-31",
     "CNAE Principal": "2542-0\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "06\/01\/2023",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1034,
     "Tipo": "PF",
     "Razão Social\/Nome": "IARA RUCINSKI\t",
     "CPF": "752.280.259-87",
     "CNAE Principal": 139399,
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "06\/01\/2023",
     "Estado": "PR",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1035,
     "Tipo": "PF",
     "Razão Social\/Nome": "AYRIS LUCIO PARREIRA ",
     "Nome Fantasia\/Apelido": "Fazenda Varão   ",
     "CPF": " 058.955.701-78",
     "CNAE Principal": " 0151-2\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "06\/01\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1036,
     "Tipo": "PJ",
     "Razão Social\/Nome": "J A XIMENES DE LIMA FILHO LTDA",
     "Nome Fantasia\/Apelido": "BARBEARIA CAP VIP",
     "CNPJ": "34.779.233\/0001-56",
     "CNAE Principal": "9602-5\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "06\/01\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1037,
     "Tipo": "PJ",
     "Razão Social\/Nome": "R SAT RASTREAMENTO E LOGISTICA EIRELI",
     "Nome Fantasia\/Apelido": "R SAT RASTREAMENTO E LOGISTICA",
     "CNPJ": "17.276.026\/0001-60",
     "CNAE Principal": "8020-0\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "06\/01\/2023",
     "Estado": "GO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1038,
     "Tipo": "PJ",
     "Razão Social\/Nome": "J. C. DE FARIAS COMERCIO DE MOVEIS E ELETRODOMESTICOS EIRELI",
     "Nome Fantasia\/Apelido": "DANTAS MOVEIS E ELETRODOMESTICOS",
     "CNPJ": "23.782.193\/0001-85",
     "CNAE Principal": "4754-7\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "06\/01\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1039,
     "Tipo": "PJ",
     "Razão Social\/Nome": "M. L. DE NAZARE FARIAS",
     "Nome Fantasia\/Apelido": "VIA LU",
     "CNPJ": " 04.749.182\/0001-59",
     "CNAE Principal": "4781-4\/00",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "06\/01\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1040,
     "Tipo": "PJ",
     "Razão Social\/Nome": "FAUSTINA MONTELO SOUZA & CIA LTDA",
     "Nome Fantasia\/Apelido": "O BOTICARIO",
     "CNPJ": "37.322.336\/0001-80",
     "CNAE Principal": "4772-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "06\/01\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1041,
     "Tipo": "PF",
     "Razão Social\/Nome": "MARCIO YASSUO KOBAYASHI ",
     "Nome Fantasia\/Apelido": "FAZENDA NOSSA SENHORA APARECIDA",
     "CPF": " 014.050.231-98",
     "CNAE Principal": " 0151-2\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "06\/01\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1042,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MAGAZINE DANTAS LTDA",
     "Nome Fantasia\/Apelido": "MAGAZINE DANTAS",
     "CNPJ": "40.011.155\/0001-48",
     "CNAE Principal": "4753-9\/00",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "06\/01\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1043,
     "Tipo": "PJ",
     "Razão Social\/Nome": "STARADUB E ROMANIW LTDA",
     "Nome Fantasia\/Apelido": "POWER HOUSE ACADEMIA",
     "CNPJ": "34.940.520\/0001-04",
     "CNAE Principal": "9313-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "06\/01\/2023",
     "Estado": "PR",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1044,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LAIS CRISTINA OLIVEIRA INACIO & CIA LTDA",
     "CNPJ": "33.136.591\/0001-88",
     "CNAE Principal": "7311-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "06\/01\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1045,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MEU CREPE ALIMENTOS LTDA,",
     "Nome Fantasia\/Apelido": "MEU CREPE",
     "CNPJ": "38.542.564\/0001-29",
     "CNAE Principal": "5611-2\/03",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "08\/01\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1046,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MEU CREPE ALIMENTOS LTDA;",
     "Nome Fantasia\/Apelido": "MEU CREPE - RUA 14",
     "CNPJ": "38.542.564\/0005-52",
     "CNAE Principal": "56.11-2-03",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "08\/01\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1047,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ORTOMEDIKA COMERCIO E SERVICO DE ARTIGOS MEDICOS E ORTOPEDICOS LTDA",
     "Nome Fantasia\/Apelido": "ORTOMEDIKA",
     "CNPJ": "10.654.943\/0001-91",
     "CNAE Principal": "4773-3\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "08\/01\/2023",
     "Estado": "RO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1048,
     "Tipo": "PJ",
     "Razão Social\/Nome": "WELLINGTON SILVA MACEDO",
     "Nome Fantasia\/Apelido": "MULT CLIMATIZADOR E AR CONDICIONADO",
     "CNPJ": "12.116.536\/0001-56",
     "CNAE Principal": "3314-7\/07",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "08\/01\/2023",
     "Estado": "MG",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1049,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ABC TELECOM COMERCIO VAREJISTA DE EQUIPAMENTOS DE INFORMATICA LTDA",
     "Nome Fantasia\/Apelido": "ABC INFORMÁTICA",
     "CNPJ": "30.130.533\/0001-59",
     "CNAE Principal": "4751-2\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "09\/01\/2023",
     "Estado": "PE",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1050,
     "Tipo": "PJ",
     "Razão Social\/Nome": "FREITAS CAIRES REVESTIMENTOS LTDA",
     "Nome Fantasia\/Apelido": "IDEAL REVESTIMENTOS",
     "CNPJ": "28.276.528\/0001-07",
     "CNAE Principal": "4330-4\/05",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "09\/01\/2023",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1051,
     "Tipo": "PJ",
     "Razão Social\/Nome": "GREEN FOREST ECOSYSTEMS AMBIENTAL LTDA",
     "Nome Fantasia\/Apelido": "GREEN FOREST ECOSYSTEMS",
     "CNPJ": "22.433.463\/0001-80",
     "CNAE Principal": " 0230-6\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "09\/01\/2023",
     "Estado": "BA",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1052,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ATLANTICO ENGENHARIA LTDA",
     "Nome Fantasia\/Apelido": "ATLANTICO ENGENHARIA",
     "CNPJ": " 05.883.859\/0001-00",
     "CNAE Principal": "4211-1\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "10\/01\/2023",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1053,
     "Tipo": "PF",
     "Razão Social\/Nome": "ANTONIO DOS REIS ELIAS TEIXEIRA",
     "Nome Fantasia\/Apelido": "FAZENDA RENASCER",
     "CPF": " 030.995.711-72",
     "CNAE Principal": "151201 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "10\/01\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1054,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SCHULER E GOMES - ADVOGADOS ASSOCIADOS",
     "Nome Fantasia\/Apelido": "SCHULER E GOMES - ADVOGADOS ASSOCIADOS.",
     "CNPJ": " 05.003.068\/0001-48",
     "CNAE Principal": "6911-7\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "11\/01\/2023",
     "Estado": "PE",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1055,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ESPACO K BRONZEAMENTO E ESTETICA",
     "Nome Fantasia\/Apelido": "ESPACO K BRONZE",
     "CNPJ": "42.412.807\/0001-81",
     "CNAE Principal": "9602-5\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "11\/01\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1056,
     "Tipo": "PJ",
     "Razão Social\/Nome": "AGRIGEO AGRIMENSURA E AMBIENTAL LTDA",
     "Nome Fantasia\/Apelido": "AGRIGEO",
     "CNPJ": "28.224.429\/0001-81",
     "CNAE Principal": "7119-7\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "11\/01\/2023",
     "Estado": "MG",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1057,
     "Tipo": "PJ",
     "Razão Social\/Nome": "HR SERVICOS AGRICOLAS LTDA",
     "Nome Fantasia\/Apelido": "HR SERVICOS AGRICOLAS",
     "CNPJ": " 09.654.777\/0001-44",
     "CNAE Principal": " 0161-0\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "11\/01\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1058,
     "Tipo": "PJ",
     "Razão Social\/Nome": "AIRTON PEREIRA DA SILVA",
     "Nome Fantasia\/Apelido": "ALOJAMENTO MACAPA",
     "CNPJ": "34.841.460\/0001-64",
     "CNAE Principal": "5590-6\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "11\/01\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1059,
     "Tipo": "PJ",
     "Razão Social\/Nome": "VALOR PRESTADORA DE SERVICOS LTDA",
     "CNPJ": "44.329.622\/0001-33",
     "CNAE Principal": "7112-0\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "11\/01\/2023",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1060,
     "Tipo": "PJ",
     "Razão Social\/Nome": "APECOL ENGENHARIA E CONSTRUCOES EIRELI",
     "CNPJ": "59.928.937\/0001-74",
     "CNAE Principal": "4120-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "11\/01\/2023",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1061,
     "Tipo": "PJ",
     "Razão Social\/Nome": "A S J SERVICOS MAQUINAS E IMPLEMENTOS EIRELI",
     "Nome Fantasia\/Apelido": "NETO MAQUINAS",
     "CNPJ": "21.279.676\/0001-36",
     "CNAE Principal": "3314-7\/12",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "12\/01\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1062,
     "Tipo": "PJ",
     "Razão Social\/Nome": "GEBRIM CONSULTORIA E SERVICOS EIRELI",
     "Nome Fantasia\/Apelido": "GEBRIM CONSULTORIA E SERVICOS",
     "CNPJ": "22.083.263\/0001-44",
     "CNAE Principal": "8111-7\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "12\/01\/2023",
     "Estado": "DF",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1063,
     "Tipo": "PJ",
     "Razão Social\/Nome": "BRASNET WEB INFORMATICA LTDA",
     "Nome Fantasia\/Apelido": "CASA DO WIRELESS",
     "CNPJ": " 09.552.061\/0004-89",
     "CNAE Principal": "4651-6\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "13\/01\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1064,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DANIELA ANDRADE ALCASSA",
     "CNPJ": "14.192.135\/0001-00",
     "CNAE Principal": "1091-1\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "13\/01\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1065,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SANTOS E BERTELONI LTDA",
     "Nome Fantasia\/Apelido": "SO SUPORTE",
     "CNPJ": "11.556.437\/0001-22",
     "CNAE Principal": "4751-2\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "13\/01\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1066,
     "Tipo": "PJ",
     "Razão Social\/Nome": "L. C. GONCALVES VIDROS E ESPELHOS",
     "Nome Fantasia\/Apelido": "ESPELHOS FENIX",
     "CNPJ": "14.234.170\/0001-45",
     "CNAE Principal": "4743-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "14\/01\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1067,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CRISTIANO ELIAS REFATTI TRANSPORTES",
     "Nome Fantasia\/Apelido": "CRISTIANO REFATTI TRANSPORTES",
     "CNPJ": "21.887.853\/0001-67",
     "CNAE Principal": "49.30-2-02 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "15\/01\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1068,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CHARLES ANDREW J. BLACHFORD SERVICOS MARITIMOS",
     "Nome Fantasia\/Apelido": "BLACKJACK SERVICOS MARITIMOS",
     "CNPJ": "43.941.893\/0001-82",
     "CNAE Principal": "3317-1\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "16\/01\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1069,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ADR CURSOS PREPARATORIOS LTDA",
     "Nome Fantasia\/Apelido": "ADR CURSOS PREPARATORIOS",
     "CNPJ": "43.248.366\/0001-97",
     "CNAE Principal": "8599-6\/05",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "16\/01\/2023",
     "Estado": "MG",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1070,
     "Tipo": "PJ",
     "Razão Social\/Nome": "R & C ELETRICA LTDA",
     "Nome Fantasia\/Apelido": "INFRA R& C",
     "CNPJ": "38.714.717\/0001-78",
     "CNAE Principal": "4321-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "16\/01\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1071,
     "Tipo": "PF",
     "Razão Social\/Nome": "PAULO LOBO DE ARAÚJO JUNIOR ",
     "CPF": "500.271.661-04",
     "CNAE Principal": 111301,
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "17\/01\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1072,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ELETROMOVEIS E BRINQUEDOS LTDA",
     "CNPJ": " 09.573.950\/0001-80",
     "CNAE Principal": "4754-7\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "17\/01\/2023",
     "Estado": "PE",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1073,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ASSOCIACAO DOS GESTORES DA CAIXA ECONOMICA FEDERAL DO RIO GRANDE DO SUL AGECEF\/RS",
     "Nome Fantasia\/Apelido": "AGECEF\/RS",
     "CNPJ": "97.259.949\/0001-42",
     "CNAE Principal": "9430-8\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "17\/01\/2023",
     "Estado": "RS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1074,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MAGAZINE DANTAS LTDA",
     "Nome Fantasia\/Apelido": "MAGAZINE DANTAS",
     "CNPJ": "23.605.347\/0001-63",
     "CNAE Principal": "4753-9\/00",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "17\/01\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1075,
     "Tipo": "PJ",
     "Razão Social\/Nome": "F GOMES PIRES HORTIFRUTI",
     "Nome Fantasia\/Apelido": "HORTIFRUTI 2 IRMAOS",
     "CNPJ": "42.416.270\/0001-28",
     "CNAE Principal": "4724-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "17\/01\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1076,
     "Tipo": "PJ",
     "Razão Social\/Nome": "JOSE L. GONZAGA FILHO",
     "Nome Fantasia\/Apelido": "GONZAGA SERVICOS",
     "CNPJ": " 08.823.238\/0001-29",
     "CNAE Principal": "3314-7\/18",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "17\/01\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1077,
     "Tipo": "PJ",
     "Razão Social\/Nome": "REZENDE TRANSPORTES E LOCACOES LTDA",
     "Nome Fantasia\/Apelido": "REZENDE TRANSPORTES",
     "CNPJ": "20.601.557\/0001-95",
     "CNAE Principal": "4930-2\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "17\/01\/2023",
     "Estado": "GO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1078,
     "Tipo": "PJ",
     "Razão Social\/Nome": "PERAZZA FERRES E FERRES LTDA",
     "Nome Fantasia\/Apelido": "LABORCLIN - LABORATORIO CLINICO",
     "CNPJ": " 06.934.116\/0001-84",
     "CNAE Principal": "8640-2\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "18\/01\/2023",
     "Estado": "MT",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1079,
     "Tipo": "PF",
     "Razão Social\/Nome": "RICARDO REHDER GARCIA DE FIGUEIREDO ",
     "Nome Fantasia\/Apelido": "SITIO SÃO JORGE  ",
     "CPF": "137.529.748-18",
     "CNAE Principal": " 0151-2\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "18\/01\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1083,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ETELVINA DE LOURDES DA SILVA",
     "Nome Fantasia\/Apelido": "STUDIO TRAINNER",
     "CNPJ": "12.118.777\/0001-34",
     "CNAE Principal": "9313-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "18\/01\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1084,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ALCIDES THOMAZINI CALBENTE",
     "Nome Fantasia\/Apelido": "ELETRO ELETRONICA SHOCK",
     "CNPJ": "51.386.530\/0001-50",
     "CNAE Principal": "4753-9\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "18\/01\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1085,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CARLOS ROBERTO COSTA DE OLIVEIRA",
     "Nome Fantasia\/Apelido": "CR LOCACOES E SERVICOS",
     "CNPJ": "25.534.444\/0001-38",
     "CNAE Principal": "4744-0\/01",
     "Região": 28,
     "Situação": "Ativa",
     "Data Cadastro": "18\/01\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1089,
     "Tipo": "PF",
     "Razão Social\/Nome": "MARCELO DE SOUZA JARDIM",
     "Nome Fantasia\/Apelido": "MARCELO DE SOUZA JARDIM",
     "CPF": "597.537.651-34",
     "CNAE Principal": " 0111-3\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "18\/01\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1093,
     "Tipo": "PJ",
     "Razão Social\/Nome": "P.M.A. FORTES",
     "Nome Fantasia\/Apelido": "FORTES SERVICOS AGRICOLAS",
     "CNPJ": "41.281.529\/0001-08",
     "CNAE Principal": " 0161-0\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "18\/01\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1094,
     "Tipo": "PF",
     "Razão Social\/Nome": "Altair Wilson Teixeira De Lisboa  ",
     "Nome Fantasia\/Apelido": "Agropecuária Alto Alegre   ",
     "CPF": "970.889.785-04",
     "CNAE Principal": " 01.51-2\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "19\/01\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1095,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LUIS LIMA ODONTOLOGIA LTDA",
     "Nome Fantasia\/Apelido": "ODONTO LIMA",
     "CNPJ": "47.502.365\/0001-88",
     "CNAE Principal": "86.30-5-04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "19\/01\/2023",
     "Estado": "DF",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1096,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ADELCLEI ULIANOPOLIS - DISTRIBUIDORA E COMERCIO LTDA",
     "Nome Fantasia\/Apelido": "ADELCLEI ULIANOPOLIS",
     "CNPJ": "14.341.876\/0001-06",
     "CNAE Principal": "4784-9\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "19\/01\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1097,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CLINICA ALIANCA LTDA",
     "Nome Fantasia\/Apelido": "CLINICA ALIANCA",
     "CNPJ": "27.115.662\/0001-63",
     "CNAE Principal": "8630-5\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "19\/01\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1098,
     "Tipo": "PF",
     "Razão Social\/Nome": "FRANCISCO GOMES DE LIMA",
     "CPF": "688.403.302-91",
     "CNAE Principal": 151201,
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "20\/01\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1099,
     "Tipo": "PJ",
     "Razão Social\/Nome": "A GOMES DE LIMA COMERCIO E SERVICO EIRELI",
     "Nome Fantasia\/Apelido": "LIMA CITROS",
     "CNPJ": "33.371.342\/0001-77",
     "CNAE Principal": " 0163-6\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "20\/01\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1100,
     "Tipo": "PJ",
     "Razão Social\/Nome": "A GOMES DE LIMA & CIA LTDA",
     "Nome Fantasia\/Apelido": "CASA LIMA",
     "CNPJ": " 07.986.548\/0001-00",
     "CNAE Principal": "4789-0\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "20\/01\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1101,
     "Tipo": "PJ",
     "Razão Social\/Nome": "A OLIVEIRA DE LIMA CASA DE CARNES LTDA",
     "Nome Fantasia\/Apelido": "CASA DE CARNES LIMA",
     "CNPJ": "21.157.898\/0001-86",
     "CNAE Principal": "4722-9\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "20\/01\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1102,
     "Tipo": "PJ",
     "Razão Social\/Nome": "PROJETO DE PEDRA COMERCIO E SERVICOS EIRELI",
     "CNPJ": "21.867.622\/0001-91",
     "CNAE Principal": "2391-5\/02",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "20\/01\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1103,
     "Tipo": "PF",
     "Razão Social\/Nome": "JOÃO GOMES DE LIMA",
     "CPF": "423.850.752-53",
     "CNAE Principal": 151201,
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "20\/01\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1104,
     "Tipo": "PF",
     "Razão Social\/Nome": "RAIMUNDO GOMES DE LIMA",
     "CPF": "630.530.042-91",
     "CNAE Principal": 154700,
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "20\/01\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1105,
     "Tipo": "PJ",
     "Razão Social\/Nome": "VALE DO JAVAES AGROPECUARIA LTDA",
     "Nome Fantasia\/Apelido": "VALE DO JAVAES AGROPECUARIA",
     "CNPJ": "45.806.555\/0001-63",
     "CNAE Principal": " 01.51-2-01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "21\/01\/2023",
     "Estado": "TO",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1106,
     "Tipo": "PJ",
     "Razão Social\/Nome": "F. A. SERVICOS EM CONSTRUCAO CIVIL LTDA",
     "Nome Fantasia\/Apelido": "GRUPO PRIME",
     "CNPJ": "37.824.767\/0001-45",
     "CNAE Principal": "7112-0\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "21\/01\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1107,
     "Tipo": "PJ",
     "Razão Social\/Nome": "H G F DE ALENCAR EIRELI",
     "Nome Fantasia\/Apelido": "POSTO PARAIBA",
     "CNPJ": "21.959.210\/0001-81",
     "CNAE Principal": "4731-8\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "23\/01\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1108,
     "Tipo": "PF",
     "Razão Social\/Nome": "HUMBERTO GLAUDSON FERNANDES DE ALENCAR ",
     "Nome Fantasia\/Apelido": "FAZENDA PARAIBA ",
     "CPF": "839.006.162-72",
     "CNAE Principal": 151201,
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "23\/01\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1109,
     "Tipo": "PJ",
     "Razão Social\/Nome": "A. JEAN MOTA DE LIMA CONSTRUTORA",
     "Nome Fantasia\/Apelido": "LIMA FERRO & ACO",
     "CNPJ": "10.640.646\/0001-97",
     "CNAE Principal": "7732-2\/01",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "23\/01\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1110,
     "Tipo": "PF",
     "Razão Social\/Nome": "JOSE DE SOUZA MACIEL  ",
     "Nome Fantasia\/Apelido": "FAZENDA LAGOA VERDE ",
     "CPF": " 017.473.121-34",
     "CNAE Principal": " 0151-2\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "23\/01\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1111,
     "Tipo": "PJ",
     "Razão Social\/Nome": "AUTO PECAS E SERVICOS LIMA LTDA",
     "Nome Fantasia\/Apelido": "AUTO PECAS LIMA",
     "CNPJ": "23.429.342\/0002-08",
     "CNAE Principal": "4530-7\/03",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "23\/01\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1112,
     "Tipo": "PJ",
     "Razão Social\/Nome": "I9MED REPRESENTACOES E COMERCIO DE PRODUTOS HOSPITALARES EIRELI",
     "CNPJ": "22.871.465\/0001-50",
     "CNAE Principal": "4619-2\/00",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "23\/01\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1113,
     "Tipo": "PJ",
     "Razão Social\/Nome": "AUTO PECAS E SERVICOS LIMA LTDA.",
     "Nome Fantasia\/Apelido": "AUTO PECAS LIMA",
     "CNPJ": "23.429.342\/0001-27",
     "CNAE Principal": "4530-7\/03",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "23\/01\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1114,
     "Tipo": "PJ",
     "Razão Social\/Nome": "A OLIVEIRA DE LIMA CASA DE CARNES LTDA.",
     "Nome Fantasia\/Apelido": "CASA DE CARNES LIMA",
     "CNPJ": "21.157.898\/0003-48",
     "CNAE Principal": "4722-9\/01",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "23\/01\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1115,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LIMA COUNTRY COMERCIAL LTDA",
     "Nome Fantasia\/Apelido": "LIMA COUNTRY",
     "CNPJ": "11.935.537\/0001-60",
     "CNAE Principal": "4744-0\/05",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "23\/01\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1116,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LUDMILLA C DA SILVA",
     "Nome Fantasia\/Apelido": "TORNEBRAS SERVICOS",
     "CNPJ": "32.711.882\/0001-90",
     "CNAE Principal": " 0161-0\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "23\/01\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1117,
     "Tipo": "PF",
     "Razão Social\/Nome": "Edenilson Zellmer Poeschike ",
     "Nome Fantasia\/Apelido": "Edenilson Zellmer Poeschike ",
     "CPF": "382.954.581-91",
     "CNAE Principal": 111301,
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "24\/01\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1118,
     "Tipo": "PJ",
     "Razão Social\/Nome": "M.L. PINTO EIRELI",
     "Nome Fantasia\/Apelido": "LIMA INFORMATICA",
     "CNPJ": "20.873.317\/0001-40",
     "CNAE Principal": "4751-2\/01",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "24\/01\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1119,
     "Tipo": "PJ",
     "Razão Social\/Nome": "XINGU CARGO LTDA",
     "Nome Fantasia\/Apelido": "XINGU CARGO",
     "CNPJ": "10.625.216\/0001-04",
     "CNAE Principal": "4930-2\/02",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "24\/01\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1120,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ATUAL CONTABILIDADE E SERVICOS LTDA",
     "Nome Fantasia\/Apelido": "ATUAL CONTABILIDADE",
     "CNPJ": "10.391.185\/0001-66",
     "CNAE Principal": "6920-6\/01",
     "Região": 28,
     "Situação": "Ativa",
     "Data Cadastro": "24\/01\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1121,
     "Tipo": "PJ",
     "Razão Social\/Nome": "RODRIGUES & AZEVEDO ACADEMIA DE GINASTICA E MUSCULACAO LTDA",
     "Nome Fantasia\/Apelido": "RUNNER FITNESS",
     "CNPJ": "24.287.788\/0001-27",
     "CNAE Principal": "93.13-1-00 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "24\/01\/2023",
     "Estado": "RN",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1122,
     "Tipo": "PJ",
     "Razão Social\/Nome": "RAIMUNDO LOPES DE ALMEIDA FILHO 84180692104",
     "Nome Fantasia\/Apelido": "REI DO ACAI",
     "CNPJ": "22.561.607\/0001-83",
     "CNAE Principal": "4729-6\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "24\/01\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1123,
     "Tipo": "PJ",
     "Razão Social\/Nome": "P.S.R DE OLIVEIRA",
     "Nome Fantasia\/Apelido": "COMETA LOG ALTERNATIVO",
     "CNPJ": "24.055.595\/0001-40",
     "CNAE Principal": "7911-2\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "24\/01\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1124,
     "Tipo": "PJ",
     "Razão Social\/Nome": "F DAS CHAGAS RODRIGUES DE OLIVEIRA DA SILVA",
     "Nome Fantasia\/Apelido": "COMETA LOG",
     "CNPJ": "12.697.101\/0001-42",
     "CNAE Principal": "7911-2\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "24\/01\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1126,
     "Tipo": "PF",
     "Razão Social\/Nome": "Antônio Eustáquio Lopes  ",
     "Nome Fantasia\/Apelido": "Fazenda Taturuba ",
     "CPF": "186.646.366-72",
     "CNAE Principal": " 01.51-2",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "24\/01\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1127,
     "Tipo": "PJ",
     "Razão Social\/Nome": "FALCAO E CARVALHO LTDA",
     "CNPJ": "11.095.375\/0001-07",
     "CNAE Principal": "4781-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "24\/01\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1129,
     "Tipo": "PJ",
     "Razão Social\/Nome": "G MENEZES DE SOUSA CONFECCOES EIRELI",
     "Nome Fantasia\/Apelido": "VALDECI MODAS",
     "CNPJ": "20.281.008\/0001-80",
     "CNAE Principal": "4781-4\/00",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "25\/01\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1131,
     "Tipo": "PJ",
     "Razão Social\/Nome": "VALIDAC VALIDACAO E MANUTENCAO LTDA",
     "Nome Fantasia\/Apelido": "VALIDAC",
     "CNPJ": "39.798.817\/0001-92",
     "CNAE Principal": "9511-8\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "25\/01\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1132,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ALTANEIRA REFRIGERACAO LTDA",
     "CNPJ": " 05.052.598\/0001-86",
     "CNAE Principal": "4322-3\/02",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "25\/01\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1133,
     "Tipo": "PJ",
     "Razão Social\/Nome": "V MESSIAS DE SOUSA CONFECCOES EIRELI",
     "Nome Fantasia\/Apelido": "LOJA SANTO ANTONIO",
     "CNPJ": " 05.132.714\/0001-77",
     "CNAE Principal": "4781-4\/00",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "25\/01\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1134,
     "Tipo": "PJ",
     "Razão Social\/Nome": "A.P. FORMENTINI FERREIRA",
     "Nome Fantasia\/Apelido": "IDEAL CONTABILIDADE",
     "CNPJ": "12.935.908\/0001-76",
     "CNAE Principal": "6920-6\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "25\/01\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1135,
     "Tipo": "PF",
     "Razão Social\/Nome": "ELIO COSTA MARTINS ",
     "Nome Fantasia\/Apelido": "FAZENDA VERTENTES  ",
     "CPF": "879.836.641-68",
     "CNAE Principal": " 0151-2\/01 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "25\/01\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1136,
     "Tipo": "PJ",
     "Razão Social\/Nome": "APROV SERVICOS E REPRESENTACOES LTDA",
     "Nome Fantasia\/Apelido": "AF REPRESENTACOES",
     "CNPJ": "46.053.171\/0001-80",
     "CNAE Principal": "4619-2\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "26\/01\/2023",
     "Estado": "SC",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1137,
     "Tipo": "PJ",
     "Razão Social\/Nome": "HERILANIA BATISTA ALVES",
     "CNPJ": " 01.396.226\/0001-16",
     "CNAE Principal": "4755-5\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "26\/01\/2023",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1138,
     "Tipo": "PF",
     "Razão Social\/Nome": "Joaquim Almeida de Carvalho ",
     "Nome Fantasia\/Apelido": "Fazenda São Nicolau 2",
     "CPF": " 038.953.988-02",
     "CNAE Principal": " 0151-2\/01 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "26\/01\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1139,
     "Tipo": "PF",
     "Razão Social\/Nome": "LEOMAR FERNANDES CARDOSO ",
     "Nome Fantasia\/Apelido": "FAZENDA BOA ESPERANÇA ",
     "CPF": "574.928.321-68",
     "CNAE Principal": " 0151-2\/01 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "26\/01\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1140,
     "Tipo": "PJ",
     "Razão Social\/Nome": "HUGO LUIS PACHECO SOARES 08962107406",
     "Nome Fantasia\/Apelido": "COIFA CLEAN TEC",
     "CNPJ": "38.481.546\/0001-84",
     "CNAE Principal": "4789-0\/05",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "26\/01\/2023",
     "Estado": "PE",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1141,
     "Tipo": "PJ",
     "Razão Social\/Nome": "M. L. SPAGLIARI & CIA LTDA.",
     "CNPJ": "10.789.292\/0001-47",
     "CNAE Principal": "4221-9\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "26\/01\/2023",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1143,
     "Tipo": "PJ",
     "Razão Social\/Nome": "GENIVAL NAZARE DE OLIVEIRA",
     "Nome Fantasia\/Apelido": "AGROTERRA",
     "CNPJ": " 00.354.487\/0001-00",
     "CNAE Principal": "4692-3\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "27\/01\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1144,
     "Tipo": "PJ",
     "Razão Social\/Nome": "VICENTE BONETI",
     "Nome Fantasia\/Apelido": "BONETI EMPREENDIMENTOS RURAIS",
     "CNPJ": "43.249.565\/0001-10",
     "CNAE Principal": "7731-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "28\/01\/2023",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1145,
     "Tipo": "PF",
     "Razão Social\/Nome": "FAZENDA LIMA ",
     "Nome Fantasia\/Apelido": "FAZENDA LIMA ",
     "CPF": "372.579.602-59",
     "CNAE Principal": "16.10-9\/9",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "28\/01\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1146,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ELIKSON ELIEVANIO CUNHA DE QUEIROZ",
     "Nome Fantasia\/Apelido": "SUPERMERCADO BOA OPCAO",
     "CNPJ": "21.424.929\/0001-18",
     "CNAE Principal": "4711-3\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "28\/01\/2023",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1147,
     "Tipo": "PJ",
     "Razão Social\/Nome": "VILMA MARIA DOS SANTOS SOUZA",
     "Nome Fantasia\/Apelido": "MERCADINHO COQUENSE",
     "CNPJ": "11.513.966\/0001-49",
     "CNAE Principal": "4712-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "28\/01\/2023",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1148,
     "Tipo": "PJ",
     "Razão Social\/Nome": "JURANDIR CARLOS ATAIDE LOPES",
     "Nome Fantasia\/Apelido": "SERRALHERIA JANUARENSE",
     "CNPJ": " 05.357.193\/0001-56",
     "CNAE Principal": "2512-8\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "28\/01\/2023",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1149,
     "Tipo": "PF",
     "Razão Social\/Nome": "MARCIA HELENA SOUZA DE OLIVEIRA",
     "Nome Fantasia\/Apelido": "FAZENDA CAJAMUNUM",
     "CPF": "121.663.166-20",
     "CNAE Principal": "1156-00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "28\/01\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1150,
     "Tipo": "PF",
     "Razão Social\/Nome": "MARCIA HELENA SOUZA DE OLIVEIRA",
     "Nome Fantasia\/Apelido": "FAZENDA ESPERANCA",
     "CPF": "121.663.166-20",
     "CNAE Principal": "1156-00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "28\/01\/2023",
     "Estado": "PR",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1151,
     "Tipo": "PJ",
     "Razão Social\/Nome": "S G XAVIER SOUZA",
     "Nome Fantasia\/Apelido": "JHS REFRIGERACAO AUTOMOTIVA",
     "CNPJ": "42.056.636\/0001-03",
     "CNAE Principal": "4530-7\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "30\/01\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1152,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CORACY DE SA ALMEIDA BRITO EIRELI",
     "Nome Fantasia\/Apelido": "EQUILIBRIO STORE",
     "CNPJ": "23.661.683\/0001-23",
     "CNAE Principal": "4781-4\/00",
     "Região": 28,
     "Situação": "Ativa",
     "Data Cadastro": "30\/01\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1153,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LEANDRO MATERIAIS PARA CONSTRUCAO LTDA",
     "Nome Fantasia\/Apelido": "LEANDRO FERRAGENS MATERIAIS PARA CONSTRUCAO",
     "CNPJ": "27.490.577\/0001-85",
     "CNAE Principal": "4744-0\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "30\/01\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1154,
     "Tipo": "PJ",
     "Razão Social\/Nome": "PSJ CONSTRUCOES  EIRELI",
     "Nome Fantasia\/Apelido": "PSJ ENGENHARIA",
     "CNPJ": "37.147.685\/0001-03",
     "CNAE Principal": "4120-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "30\/01\/2023",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1155,
     "Tipo": "PJ",
     "Razão Social\/Nome": "POERSCHKE & ALVES LTDA",
     "Nome Fantasia\/Apelido": "ARMAZENS GERAIS PANTANAL",
     "CNPJ": "22.965.992\/0001-24",
     "CNAE Principal": "5211-7\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "30\/01\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1156,
     "Tipo": "PJ",
     "Razão Social\/Nome": "NOVA AGRO SERVICOS RURAIS LTDA",
     "Nome Fantasia\/Apelido": "NOVA AGRO",
     "CNPJ": "41.418.711\/0001-68",
     "CNAE Principal": " 0161-0\/99",
     "Região": 19,
     "Situação": "Ativa",
     "Data Cadastro": "30\/01\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1157,
     "Tipo": "PJ",
     "Razão Social\/Nome": "AUGUSTO CESAR PEREIRA FERNANDES 79378617204",
     "Nome Fantasia\/Apelido": "Elevadores fernandes & Fernandes",
     "CNPJ": "48.324.028\/0001-00",
     "CNAE Principal": "43.29-1-03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "31\/01\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1158,
     "Tipo": "PF",
     "Razão Social\/Nome": "ANDERSON PRIEB",
     "Nome Fantasia\/Apelido": "FAZENDA NHU PORA",
     "CPF": "857.647.601-00",
     "CNAE Principal": 111301,
     "Região": 28,
     "Situação": "Ativa",
     "Data Cadastro": "31\/01\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1159,
     "Tipo": "PJ",
     "Razão Social\/Nome": "J P SIMAO COMERCIAL LTDA",
     "Nome Fantasia\/Apelido": "KIBARATO",
     "CNPJ": "19.815.815\/0001-57",
     "CNAE Principal": "4711-3\/02",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "31\/01\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1160,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CENTRO DE ESTUDOS APLICADOS AS CIENCIAS E TECNOLOGIA DO ENSINO FUNDAMENTAL E MEDIO EIRELI",
     "Nome Fantasia\/Apelido": "METROPOLES",
     "CNPJ": "28.849.116\/0001-19",
     "CNAE Principal": "8513-9\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "31\/01\/2023",
     "Estado": "SE",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1161,
     "Tipo": "PF",
     "Razão Social\/Nome": "ELIZANGELA PEREIRA BARROS SANTOS",
     "Nome Fantasia\/Apelido": "FAZENDA NOVO HORIZONTE",
     "CPF": "805.632.361-91",
     "CNAE Principal": 151201,
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "31\/01\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1162,
     "Tipo": "PF",
     "Razão Social\/Nome": "VALTER LOPES DE BRITO",
     "Nome Fantasia\/Apelido": "VALTER LOPES DE BRITO",
     "CPF": " 015.752.898-73",
     "CNAE Principal": " 0151-2\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "31\/01\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1163,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CARLOS ANTONIO CASSIANO DE PONTES",
     "Nome Fantasia\/Apelido": "MARANATA ENXOVAIS",
     "CNPJ": "51.003.176\/0001-38",
     "CNAE Principal": "4755-5\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "31\/01\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1164,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MOACIR COIFFEUR STYLE CABELEIREIROS LTDA",
     "Nome Fantasia\/Apelido": "MOACIR",
     "CNPJ": "12.976.192\/0001-55",
     "CNAE Principal": "9602-5\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "31\/01\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1165,
     "Tipo": "PF",
     "Razão Social\/Nome": "FULVIO DOMINGOS MARZULLO APRATO E OUTROS",
     "Nome Fantasia\/Apelido": "FAZENDA SÃO LOURENÇO",
     "CPF": "230.626.570-49",
     "CNAE Principal": 1251201,
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "31\/01\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1166,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ENSIMED SERVICOS MEDICOS",
     "Nome Fantasia\/Apelido": "ENSIMED",
     "CNPJ": "18.337.324\/0001-85",
     "CNAE Principal": "8630-5\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "31\/01\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1167,
     "Tipo": "PJ",
     "Razão Social\/Nome": "FORMENTINI E MOTA SERVICOS DE CONSTRUCAO E ENGENHARIA LTDA",
     "Nome Fantasia\/Apelido": "G&S ENGENHARIA",
     "CNPJ": "14.254.641\/0001-87",
     "CNAE Principal": "7112-0\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "01\/02\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1168,
     "Tipo": "PF",
     "Razão Social\/Nome": "RAFAEL ALVES POESCHEKE",
     "Nome Fantasia\/Apelido": "RAFAEL ALVES  ",
     "CPF": " 003.348.111-39",
     "CNAE Principal": 111301,
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "01\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1169,
     "Tipo": "PF",
     "Razão Social\/Nome": "LUCIENE FERREIRA ALVES POERSCHKE",
     "Nome Fantasia\/Apelido": "FAZENDA AGROVILA",
     "CPF": "704.622.591-53",
     "CNAE Principal": 111301,
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "01\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1170,
     "Tipo": "PJ",
     "Razão Social\/Nome": "AREAL DO PARANA MATERIAIS DE CONSTRUCOES LTDA",
     "Nome Fantasia\/Apelido": "AREAL DO PARANA",
     "CNPJ": "37.728.420\/0001-07",
     "CNAE Principal": "4744-0\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "01\/02\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1171,
     "Tipo": "PJ",
     "Razão Social\/Nome": "PANIFICADORA E CONFEITARIA DOCE VANILA EIRELI",
     "CNPJ": "34.799.204\/0001-56",
     "CNAE Principal": "4721-1\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "01\/02\/2023",
     "Estado": "PR",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1172,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MARIA LUCILENE TAVARES LEAO",
     "Nome Fantasia\/Apelido": "SUPER VO SUPERMERCADO",
     "CNPJ": "37.862.315\/0001-58",
     "CNAE Principal": "4712-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "01\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1173,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ALESSANDRA DA S GOMES",
     "Nome Fantasia\/Apelido": "SANTA MARIA MATERIAIS PARA CONSTRUCAO",
     "CNPJ": "43.955.421\/0001-89",
     "CNAE Principal": "4744-0\/99",
     "Região": 28,
     "Situação": "Ativa",
     "Data Cadastro": "01\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1174,
     "Tipo": "PJ",
     "Razão Social\/Nome": "EDITORA TRIBUNA DE SAO JOSE LTDA",
     "CNPJ": "75.235.358\/0001-00",
     "CNAE Principal": "5822-1\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "01\/02\/2023",
     "Estado": "PR",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1175,
     "Tipo": "PJ",
     "Razão Social\/Nome": "NOGUEIRA E AMORIM ENGENHARIA E SERVICOS LTDA",
     "Nome Fantasia\/Apelido": "NOGUEIRA & AMORIM ENGENHARIA",
     "CNPJ": "17.467.453\/0001-25",
     "CNAE Principal": "4120-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "01\/02\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1176,
     "Tipo": "PJ",
     "Razão Social\/Nome": "RADIO E TV NATIVA LTDA",
     "Nome Fantasia\/Apelido": "RADIO E TV NATIVA",
     "CNPJ": "22.103.304\/0001-17",
     "CNAE Principal": "6010-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "01\/02\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1177,
     "Tipo": "PJ",
     "Razão Social\/Nome": "R M G DE LIMA & CIA LTDA",
     "Nome Fantasia\/Apelido": "R & C SHOPPING",
     "CNPJ": " 04.062.289\/0001-24",
     "CNAE Principal": "4781-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "01\/02\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1178,
     "Tipo": "PJ",
     "Razão Social\/Nome": "A GOMES DE LIMA COMERCIO E SERVICO LTDA",
     "Nome Fantasia\/Apelido": "LIMA CITRUS",
     "CNPJ": "33.371.342\/0002-58",
     "CNAE Principal": "4724-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "01\/02\/2023",
     "Estado": "MA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1179,
     "Tipo": "PJ",
     "Razão Social\/Nome": "JS TELECOM E CONSTRUCAO LTDA",
     "Nome Fantasia\/Apelido": "GEOVANE",
     "CNPJ": "39.528.937\/0001-70",
     "CNAE Principal": "42.21-9-04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "02\/02\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1180,
     "Tipo": "PJ",
     "Razão Social\/Nome": "A. R. COSTA",
     "Nome Fantasia\/Apelido": "RODRIGUES MOTO PECAS",
     "CNPJ": "11.945.081\/0001-19",
     "CNAE Principal": "4541-2\/06",
     "Região": 28,
     "Situação": "Ativa",
     "Data Cadastro": "02\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1181,
     "Tipo": "PJ",
     "Razão Social\/Nome": "K M R TRANSPORTES LTDA",
     "CNPJ": "46.024.069\/0001-56",
     "CNAE Principal": "4930-2\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "02\/02\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1182,
     "Tipo": "PJ",
     "Razão Social\/Nome": "STILO PINTURAS E REFORMAS LTDA",
     "Nome Fantasia\/Apelido": "STILO PINTURAS E REFORMAS",
     "CNPJ": "25.529.958\/0001-03",
     "CNAE Principal": "4329-1\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "02\/02\/2023",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1183,
     "Tipo": "PJ",
     "Razão Social\/Nome": "GET CLEAN HIGIENIZACAO EIRELI",
     "Nome Fantasia\/Apelido": "GET CLEAN",
     "CNPJ": "15.394.685\/0001-75",
     "CNAE Principal": "9529-1\/05",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "02\/02\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1184,
     "Tipo": "PJ",
     "Razão Social\/Nome": "RA WASH EMBELEZAMENTO AUTOMOTIVO LTDA",
     "CNPJ": "33.270.462\/0001-88",
     "CNAE Principal": "4520-0\/05",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "02\/02\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1185,
     "Tipo": "PJ",
     "Razão Social\/Nome": "F C BATISTA COMERCIO VAREJISTA EM GERAL EIRELI",
     "Nome Fantasia\/Apelido": "GC3",
     "CNPJ": "28.689.011\/0001-40",
     "CNAE Principal": "4712-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "02\/02\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1186,
     "Tipo": "PJ",
     "Razão Social\/Nome": "M. A. SIMIONI",
     "Nome Fantasia\/Apelido": "ALEXANDRE MARMORES",
     "CNPJ": "23.374.805\/0001-09",
     "CNAE Principal": "2391-5\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "02\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1187,
     "Tipo": "PJ",
     "Razão Social\/Nome": "PAULO CESAR A DE SOUSA",
     "Nome Fantasia\/Apelido": "JP CENTRO AUTOMOTIVO",
     "CNPJ": "21.344.557\/0001-10",
     "CNAE Principal": "4520-0\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "02\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1188,
     "Tipo": "PF",
     "Razão Social\/Nome": "Iradi Letrari ",
     "Nome Fantasia\/Apelido": "Fazenda Maranata ",
     "CPF": "225.820.610-34",
     "CNAE Principal": " 0111-3\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "02\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1189,
     "Tipo": "PJ",
     "Razão Social\/Nome": "COELHO E MIRANDA LTDA",
     "Nome Fantasia\/Apelido": "ESPACO RURAL",
     "CNPJ": "22.471.088\/0001-62",
     "CNAE Principal": "4771-7\/04",
     "Região": 28,
     "Situação": "Ativa",
     "Data Cadastro": "02\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1192,
     "Tipo": "PJ",
     "Razão Social\/Nome": "HIGASHIAMA & HIGASHIAMA LTDA",
     "CNPJ": " 00.649.774\/0001-48",
     "CNAE Principal": "4222-7\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "02\/02\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1193,
     "Tipo": "PF",
     "Razão Social\/Nome": "Cartório de Registro Civil de Pessoas Naturais ",
     "Nome Fantasia\/Apelido": "Cartório de Registro Civil de Pessoas Naturais",
     "CPF": "575.119.341-53",
     "CNAE Principal": "6912-5\/00 ",
     "Região": 28,
     "Situação": "Ativa",
     "Data Cadastro": "03\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1194,
     "Tipo": "PJ",
     "Razão Social\/Nome": "A L BARRETOS",
     "Nome Fantasia\/Apelido": "SUPERMERCADO ADRIANO",
     "CNPJ": "11.363.575\/0001-95",
     "CNAE Principal": "4712-1\/00",
     "Região": 28,
     "Situação": "Ativa",
     "Data Cadastro": "03\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1195,
     "Tipo": "PJ",
     "Razão Social\/Nome": "A. MARTINS MACIEL VERAS",
     "Nome Fantasia\/Apelido": "LOJA AGUIA",
     "CNPJ": "25.140.422\/0001-93",
     "CNAE Principal": "4541-2\/06",
     "Região": 28,
     "Situação": "Ativa",
     "Data Cadastro": "03\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1196,
     "Tipo": "PJ",
     "Razão Social\/Nome": "HOTEL SERRA DOURADA LTDA",
     "Nome Fantasia\/Apelido": "HOTEL SERRA DOURADA",
     "CNPJ": "10.937.527\/0001-09",
     "CNAE Principal": "5510-8\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "03\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1197,
     "Tipo": "PJ",
     "Razão Social\/Nome": "HC AUTOPECAS LTDA",
     "Nome Fantasia\/Apelido": "CARLAO AUTOPECAS",
     "CNPJ": "18.740.861\/0001-71",
     "CNAE Principal": "4530-7\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "03\/02\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1198,
     "Tipo": "PJ",
     "Razão Social\/Nome": "AMPAR AGROPECUARIA LTDA",
     "Nome Fantasia\/Apelido": "AMPAR",
     "CNPJ": "63.915.326\/0001-69",
     "CNAE Principal": " 0151-2\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "03\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1199,
     "Tipo": "PJ",
     "Razão Social\/Nome": "E. BARBOSA AGUIAR LTDA",
     "Nome Fantasia\/Apelido": "D ESSENCIA",
     "CNPJ": "32.540.784\/0001-37",
     "CNAE Principal": "4781-4\/00",
     "Região": 28,
     "Situação": "Ativa",
     "Data Cadastro": "05\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1200,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MV CENTRO DE BELEZA & ESTETICA LTDA",
     "Nome Fantasia\/Apelido": "FRANQUIA NAILS2YOU SALVADOR",
     "CNPJ": "37.468.424\/0001-95",
     "CNAE Principal": "9602-5\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "05\/02\/2023",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1201,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DESSENCIA COMERCIO VAREJISTA DE ROUPAS LTDA",
     "Nome Fantasia\/Apelido": "DESSENCIA",
     "CNPJ": " 09.643.837\/0001-23",
     "CNAE Principal": "4781-4\/00",
     "Região": 28,
     "Situação": "Ativa",
     "Data Cadastro": "05\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1202,
     "Tipo": "PJ",
     "Razão Social\/Nome": "E S BARBOSA COMERCIO",
     "Nome Fantasia\/Apelido": "CASA SIMOA",
     "CNPJ": " 05.348.002\/0001-90",
     "CNAE Principal": "4712-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "05\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1203,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MARESSA FERREIRA FONSECA 04630344144",
     "Nome Fantasia\/Apelido": "LOVE MAKEUP",
     "CNPJ": "38.322.101\/0001-51",
     "CNAE Principal": "4772-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "05\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1204,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LAVOR & OLIVEIRA TERRAPLANAGEM, TRANSPORTE E LOCACAO LTDA",
     "Nome Fantasia\/Apelido": "ENTULHO EXPRESS",
     "CNPJ": "18.994.857\/0001-30",
     "CNAE Principal": "3811-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "05\/02\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1205,
     "Tipo": "PJ",
     "Razão Social\/Nome": "FABIO LEONEL DE BRITO FILHO",
     "Nome Fantasia\/Apelido": "CASA BELLA DISTRIBUIDORA",
     "CNPJ": "17.960.300\/0001-15",
     "CNAE Principal": "4674-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "05\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1206,
     "Tipo": "PJ",
     "Razão Social\/Nome": "L F M DE LIMA ODONTO",
     "Nome Fantasia\/Apelido": "ODONTO LIMA CLINICA ODONTOLOGICA EM GERAL",
     "CNPJ": "30.393.236\/0001-04",
     "CNAE Principal": "8630-5\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "05\/02\/2023",
     "Estado": "DF",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1207,
     "Tipo": "PF",
     "Razão Social\/Nome": "FABIO LEONEL DE BRITO FILHO",
     "Nome Fantasia\/Apelido": "FABIO LEONEL DE BRITO FILHO ",
     "CPF": "897.383.301-49",
     "CNAE Principal": 151201,
     "Região": 19,
     "Situação": "Ativa",
     "Data Cadastro": "05\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1208,
     "Tipo": "PJ",
     "Razão Social\/Nome": "AGUIAR MOVEIS E ELETRO LTDA",
     "Nome Fantasia\/Apelido": "AGUIAR MOVEIS",
     "CNPJ": "24.415.437\/0002-35",
     "CNAE Principal": "4754-7\/01",
     "Região": 28,
     "Situação": "Ativa",
     "Data Cadastro": "05\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1209,
     "Tipo": "PJ",
     "Razão Social\/Nome": "BARROS E TAVARES LTDA",
     "Nome Fantasia\/Apelido": "HOTEL GIRASSOL",
     "CNPJ": " 08.316.608\/0001-31",
     "CNAE Principal": "5510-8\/01",
     "Região": 28,
     "Situação": "Ativa",
     "Data Cadastro": "05\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1210,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CESAR FERREIRA DE OLIVEIRA",
     "Nome Fantasia\/Apelido": "KRC RADIADORES BORRACHARIA E MOLAS NORDESTE",
     "CNPJ": "10.899.541\/0001-57",
     "CNAE Principal": "4530-7\/03",
     "Região": 28,
     "Situação": "Ativa",
     "Data Cadastro": "05\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1211,
     "Tipo": "PJ",
     "Razão Social\/Nome": "L. S. MACIEL",
     "Nome Fantasia\/Apelido": "MEGAVOLTS",
     "CNPJ": "22.941.721\/0001-39",
     "CNAE Principal": "4742-3\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "05\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1213,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LIMA FERRO & ACO INDUSTRIAL LTDA",
     "Nome Fantasia\/Apelido": "LIMA FERRO & ACO",
     "CNPJ": "18.891.118\/0001-12",
     "CNAE Principal": "4120-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "06\/02\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1214,
     "Tipo": "PJ",
     "Razão Social\/Nome": "VENETE COMERCIO DE ROUPAS LTDA",
     "Nome Fantasia\/Apelido": "MODAMANIA",
     "CNPJ": "15.380.460\/0001-60",
     "CNAE Principal": "4781-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "06\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1215,
     "Tipo": "PJ",
     "Razão Social\/Nome": "E ANTONIO OLIVEIRA",
     "Nome Fantasia\/Apelido": "CLIMED",
     "CNPJ": "11.959.241\/0001-89",
     "CNAE Principal": "8630-5\/03",
     "Região": 28,
     "Situação": "Ativa",
     "Data Cadastro": "06\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1216,
     "Tipo": "PJ",
     "Razão Social\/Nome": "AGUIAR MOVEIS E ELETRO LTDA",
     "Nome Fantasia\/Apelido": "AGUIAR MOVEIS",
     "CNPJ": "24.415.437\/0001-54",
     "CNAE Principal": "4754-7\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "06\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    }
   ,
    {
     "Column1": 1217,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CLINICA MEDICA DR PEDRO ZANINA LTDA",
     "Nome Fantasia\/Apelido": "CLINICA MEDICA DR PEDRO ZANINA",
     "CNPJ": " 09.228.056\/0001-72",
     "CNAE Principal": "8630-5\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "06\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1218,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SCR-SINALIZACAO E CONSERVACAO DE RODOVIAS LTDA.",
     "CNPJ": " 02.873.295\/0005-60",
     "CNAE Principal": "4222-7\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "07\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1219,
     "Tipo": "PF",
     "Razão Social\/Nome": "BRUNNA TAVARES CASTRO",
     "Nome Fantasia\/Apelido": "BRUNA TAVARES ",
     "CPF": " 018.795.391-01",
     "CNAE Principal": " 0151-201 ",
     "Região": 28,
     "Situação": "Ativa",
     "Data Cadastro": "07\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1220,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DRAW SYSTEM LTDA",
     "CNPJ": " 04.432.028\/0001-59",
     "CNAE Principal": "6209-1\/00",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "07\/02\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1221,
     "Tipo": "PJ",
     "Razão Social\/Nome": "JSANTOS ENGENHARIA PROJETOS E INSTALACOES LTDA",
     "Nome Fantasia\/Apelido": "JSANTOS ENGENHARIA",
     "CNPJ": "36.037.865\/0001-70",
     "CNAE Principal": "7112-0\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "07\/02\/2023",
     "Estado": "GO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1222,
     "Tipo": "PF",
     "Razão Social\/Nome": "AMADEU LUIZ DA SILVA",
     "CPF": "360.145.591-91",
     "CNAE Principal": 151201,
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "08\/02\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1223,
     "Tipo": "PJ",
     "Razão Social\/Nome": "GOMES E LEONEL LTDA",
     "Nome Fantasia\/Apelido": "CASA BELLA MATERIAIS DE CONSTRUCAO",
     "CNPJ": " 09.373.849\/0001-85",
     "CNAE Principal": "4744-0\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "08\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1224,
     "Tipo": "PJ",
     "Razão Social\/Nome": "RIBEIRO E AGUIAR MOVEIS E ELETRO LTDA",
     "Nome Fantasia\/Apelido": "AGUIAR MOVEIS",
     "CNPJ": "19.898.788\/0001-23",
     "CNAE Principal": "4754-7\/01",
     "Região": 28,
     "Situação": "Ativa",
     "Data Cadastro": "08\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1225,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ARAGUAIA COMERCIO DE TECIDOS LTDA",
     "Nome Fantasia\/Apelido": "ARAGUAIA TECIDOS",
     "CNPJ": " 02.159.550\/0001-83",
     "CNAE Principal": "4754-7\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "08\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1228,
     "Tipo": "PF",
     "Razão Social\/Nome": "RODNEY DEAN SCHURING",
     "Nome Fantasia\/Apelido": "RODNEY DEAN SCHURING",
     "CPF": "133.429.401-15",
     "CNAE Principal": " 0151-2\/01 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "08\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1229,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ALIANCA-EXAMES LABORATORIAIS E DIAGNOSTICO POR IMAGEM LTDA",
     "Nome Fantasia\/Apelido": "LABORATORIO ALIANCA",
     "CNPJ": "17.951.236\/0001-06",
     "CNAE Principal": "8640-2\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "09\/02\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1230,
     "Tipo": "PJ",
     "Razão Social\/Nome": "C A DA SILVA AIRES",
     "Nome Fantasia\/Apelido": "MOVEIS VITORIA",
     "CNPJ": "31.305.240\/0001-28",
     "CNAE Principal": "4754-7\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "09\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1231,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ANA DOS SANTOS G DE LIMA AUTO PECAS",
     "Nome Fantasia\/Apelido": "CARIOCA AUTO PECAS",
     "CNPJ": "10.505.762\/0001-01",
     "CNAE Principal": "4530-7\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "09\/02\/2023",
     "Estado": "PE",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1232,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ANDRADE & ALVES LTDA",
     "Nome Fantasia\/Apelido": "ELETRO POPULAR",
     "CNPJ": "12.165.229\/0001-65",
     "CNAE Principal": "4754-7\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "09\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1233,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SOUSA ATACADISTA DE PRODUTOS ALIMENTICIOS LTDA",
     "Nome Fantasia\/Apelido": "SOUSA ATACADISTA",
     "CNPJ": " 02.988.211\/0001-00",
     "CNAE Principal": "4632-0\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "09\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1235,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MAG JOIAS LTDA",
     "Nome Fantasia\/Apelido": "GIL JOIAS",
     "CNPJ": "28.802.807\/0001-67",
     "CNAE Principal": "4783-1\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "09\/02\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1236,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CBW COMERCIO LTDA",
     "Nome Fantasia\/Apelido": "BIBI RR",
     "CNPJ": "47.674.009\/0001-41",
     "CNAE Principal": "47.82-2-01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "09\/02\/2023",
     "Estado": "RR",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1237,
     "Tipo": "PF",
     "Razão Social\/Nome": "ADÉLIA MOURA DANTAS",
     "Nome Fantasia\/Apelido": "CARTÓRIO DO OFICIO ÚNICO DE ELISEU MARTINS",
     "CPF": " 025.330.763-50",
     "CNAE Principal": 6912500,
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "09\/02\/2023",
     "Estado": "PI",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1238,
     "Tipo": "PJ",
     "Razão Social\/Nome": "BAKOS & MARRA ASSOCIADOS LTDA",
     "Nome Fantasia\/Apelido": "BAKOS & MARRA ASSOCIADOS",
     "CNPJ": "40.001.663\/0001-45",
     "CNAE Principal": "6822-6\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "09\/02\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1239,
     "Tipo": "PJ",
     "Razão Social\/Nome": "FELIPE AUGUSTO GAYA DE CARVALHO",
     "Nome Fantasia\/Apelido": "CACADOR DE CARROS",
     "CNPJ": "23.338.394\/0001-98",
     "CNAE Principal": "7490-1\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "10\/02\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1240,
     "Tipo": "PF",
     "Razão Social\/Nome": "ADÉLIA MOURA DANTAS.",
     "Nome Fantasia\/Apelido": "ADÉLIA MOURA DANTAS",
     "CPF": " 025.330.763-50",
     "CNAE Principal": "69.12\/5",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "10\/02\/2023",
     "Estado": "PI",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1241,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ANDERSON DE SOUZA TORQUATO LTDA",
     "Nome Fantasia\/Apelido": "TOR4 BEER",
     "CNPJ": "26.831.863\/0001-02",
     "CNAE Principal": "4723-7\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "10\/02\/2023",
     "Estado": "MS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1242,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ILHA DA MODA & ACESSORIOS LTDA",
     "Nome Fantasia\/Apelido": "ILHA DA MODA E ACESSORIOS",
     "CNPJ": "19.496.037\/0001-80",
     "CNAE Principal": "4781-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "10\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1243,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SPEEDTEC DRYWALL LTDA",
     "CNPJ": "45.958.779\/0001-90",
     "CNAE Principal": "47.44-0-05",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "10\/02\/2023",
     "Estado": "PR",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1245,
     "Tipo": "PJ",
     "Razão Social\/Nome": "L. DE AMORIM SOARES COMERCIO DE GAS",
     "Nome Fantasia\/Apelido": "TOP GAS",
     "CNPJ": "33.746.620\/0001-23",
     "CNAE Principal": "4784-9\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "10\/02\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1246,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CLAUDIA MARRA BAKOS  - SOCIEDADE INDIVIDUAL DE ADVOCACIA",
     "CNPJ": "30.566.513\/0001-25",
     "CNAE Principal": "6911-7\/01",
     "Região": 11,
     "Situação": "Ativa",
     "Data Cadastro": "10\/02\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1247,
     "Tipo": "PJ",
     "Razão Social\/Nome": "BAKOS & MARRA ASSOCIADOS LTDA.",
     "Nome Fantasia\/Apelido": "BAKOS & MARRA ASSOCIADOS",
     "CNPJ": "40.001.663\/0001-45",
     "CNAE Principal": "6822-6\/00",
     "Região": 11,
     "Situação": "Ativa",
     "Data Cadastro": "11\/02\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1248,
     "Tipo": "PJ",
     "Razão Social\/Nome": "W LANCHONETES LTDA",
     "Nome Fantasia\/Apelido": "PATRONI",
     "CNPJ": "26.519.721\/0001-04",
     "CNAE Principal": "5611-2\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "12\/02\/2023",
     "Estado": "RR",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1249,
     "Tipo": "PJ",
     "Razão Social\/Nome": "BINDS TECNOLOGIA DA INFORMACAO LTDA",
     "CNPJ": "23.621.217\/0001-14",
     "CNAE Principal": "6203-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "13\/02\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1250,
     "Tipo": "PJ",
     "Razão Social\/Nome": "R. B. DA SILVA AUTOMACOES",
     "Nome Fantasia\/Apelido": "RBS AUTOMACOES",
     "CNPJ": "30.084.576\/0001-45",
     "CNAE Principal": "4321-5\/00",
     "Região": 11,
     "Situação": "Ativa",
     "Data Cadastro": "13\/02\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1251,
     "Tipo": "PJ",
     "Razão Social\/Nome": "R. B. DA SILVA AUTOMACOES.",
     "Nome Fantasia\/Apelido": "RBS AUTOMACOES",
     "CNPJ": "30.084.576\/0002-26",
     "CNAE Principal": "47.57\/1-00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "13\/02\/2023",
     "Estado": "PE",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1252,
     "Tipo": "PJ",
     "Razão Social\/Nome": "JSN LOGISTICA EIRELI",
     "CNPJ": "33.075.783\/0001-21",
     "CNAE Principal": "4930-2\/01",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "13\/02\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1253,
     "Tipo": "PJ",
     "Razão Social\/Nome": "A OLIVEIRA DE LIMA CASA DE CARNES LTDA",
     "Nome Fantasia\/Apelido": "PIZZARIA E CHOPPERIA LIMA",
     "CNPJ": "21.157.898\/0002-67",
     "CNAE Principal": "4722-9\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "13\/02\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1254,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MARCOS SANTOS MENDES 08230554501",
     "Nome Fantasia\/Apelido": "MENDES ACESSORIA TECNICA DE EMPREENDIMENTOS RURAIS",
     "CNPJ": "44.024.000\/0001-05",
     "CNAE Principal": " 0161-0\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "13\/02\/2023",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1255,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ALANA ALVES DE OLIVEIRA",
     "Nome Fantasia\/Apelido": "OLIVEIRA'S CONSULTORIA E PROJETOS",
     "CNPJ": "38.352.368\/0001-91",
     "CNAE Principal": "7490-1\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "13\/02\/2023",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1256,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SAO JORGE GUERREIRO - AGRICULTURA E PECUARIA LTDA",
     "Nome Fantasia\/Apelido": "FAZENDA SAO JORGE GUERREIRO - AGRICULTURA E PECUARIA",
     "CNPJ": "41.861.100-0001-90",
     "CNAE Principal": " 0151-2\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "14\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1258,
     "Tipo": "PJ",
     "Razão Social\/Nome": "NOFUSO DISTRIBUIDORA DE MATERIAIS ELETRICOS LTDA",
     "CNPJ": "10.494.955\/0001-04",
     "CNAE Principal": "4672-9\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "14\/02\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1259,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ROBERTO MIRANDA FILHO 07203086666",
     "Nome Fantasia\/Apelido": "ODIN MICROWAVE",
     "CNPJ": "18.972.137\/0001-73",
     "CNAE Principal": "6190-6\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "14\/02\/2023",
     "Estado": "MG",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1260,
     "Tipo": "PJ",
     "Razão Social\/Nome": "TAMBORYL INDUSTRIA E COMERCIO DE AGUAS E BEBIDAS ENVASADAS LTDA",
     "Nome Fantasia\/Apelido": "AGUA TAMBORYL",
     "CNPJ": "26.349.799\/0001-10",
     "CNAE Principal": "11.21-6-00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "15\/02\/2023",
     "Estado": "PI",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1261,
     "Tipo": "PF",
     "Razão Social\/Nome": "RODRIGO FONSECA CÉZAR",
     "Nome Fantasia\/Apelido": "RODRIGO FONSECA CÉZAR",
     "CPF": "145.237.988-26",
     "CNAE Principal": "86305-04 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "15\/02\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1262,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CONSORCIO INTERMUNICIPAL TRES RIOS",
     "Nome Fantasia\/Apelido": "COINTRI",
     "CNPJ": " 04.611.637\/0001-75",
     "CNAE Principal": "8411-6\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "15\/02\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1263,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ARTERRA LOGISTICA LTDA",
     "CNPJ": " 09.458.890\/0001-54",
     "CNAE Principal": "3329-5\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "15\/02\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1264,
     "Tipo": "PJ",
     "Razão Social\/Nome": "NEWCAR COMERCIO ATACADISTA DE EQUIPAMENTOS PARA MOVIMENTACAO E ARMAZENAMENTO LTDA",
     "Nome Fantasia\/Apelido": "NEWCAR",
     "CNPJ": "45.700.422\/0001-08",
     "CNAE Principal": "3099-7\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "15\/02\/2023",
     "Estado": "PE",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1265,
     "Tipo": "PJ",
     "Razão Social\/Nome": "IRRIGAR CERTO LTDA",
     "Nome Fantasia\/Apelido": "IRRIGAR CERTO",
     "CNPJ": "35.466.475\/0001-52",
     "CNAE Principal": "3321-0\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "15\/02\/2023",
     "Estado": "AC",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1266,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LEVI BELAVER",
     "Nome Fantasia\/Apelido": "BELAVER",
     "CNPJ": "29.465.280\/0001-95",
     "CNAE Principal": " 0210-1\/07",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "15\/02\/2023",
     "Estado": "PR",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1268,
     "Tipo": "PJ",
     "Razão Social\/Nome": "NOCAUTE AVIACAO AGRICOLA LTDA.",
     "Nome Fantasia\/Apelido": "SOLDEIRA AVIACAO AGRICOLA",
     "CNPJ": "39.761.228\/0001-30",
     "CNAE Principal": " 0161-0\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "15\/02\/2023",
     "Estado": "PR",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1269,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CASA DE REPOUSO VALDEMIRO DAREM SANTA FELICIDADE LTDA",
     "Nome Fantasia\/Apelido": "CASA DE REPOUSO VALDEMIRO DAREM SANTA FELICIDADE",
     "CNPJ": "43.711.310\/0001-27",
     "CNAE Principal": "8711-5\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "15\/02\/2023",
     "Estado": "PR",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1270,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MARIA CELIA CORREIA DA COSTA",
     "Nome Fantasia\/Apelido": "MASTERCAIXAS",
     "CNPJ": "29.322.028\/0001-27",
     "CNAE Principal": "4686-9\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "15\/02\/2023",
     "Estado": "PE",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1271,
     "Tipo": "PJ",
     "Razão Social\/Nome": "GELADAO BEBIDAS LTDA",
     "Nome Fantasia\/Apelido": "GELADAO BEBIDAS",
     "CNPJ": "15.717.692\/0001-60",
     "CNAE Principal": "4723-7\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "15\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1274,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LEIDIVANIA MACHADO DA SILVA",
     "Nome Fantasia\/Apelido": "DISTRIBUIDORA CENTRO NORTE",
     "CNPJ": " 09.309.040\/0001-94",
     "CNAE Principal": "4674-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "15\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1275,
     "Tipo": "PJ",
     "Razão Social\/Nome": "G F DA COSTA",
     "Nome Fantasia\/Apelido": "CONSTRIL MATERIAIS DE CONSTRUCAO",
     "CNPJ": " 01.706.273\/0001-19",
     "CNAE Principal": "4744-0\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "15\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1276,
     "Tipo": "PF",
     "Razão Social\/Nome": "HUMBERTO FERREIRA MAIA",
     "Nome Fantasia\/Apelido": "HUMBERTO FERREIRA MAIA",
     "CPF": "332.490.161-87",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "15\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1277,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SCR-SINALIZACAO E CONSERVACAO DE RODOVIAS LTDA.",
     "CNPJ": " 02.873.295\/0011-08",
     "CNAE Principal": "4222-7\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "15\/02\/2023",
     "Estado": "GO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1278,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DEIVITI RODRIGO DE MELLO ZINN 00625384032",
     "Nome Fantasia\/Apelido": "FK ESTAQUEAMENTO",
     "CNPJ": "41.872.420\/0001-45",
     "CNAE Principal": "7732-2\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "15\/02\/2023",
     "Estado": "RS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1279,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DF AGRO FLORESTA EIRELI",
     "Nome Fantasia\/Apelido": "DF AGRO FLORESTAL",
     "CNPJ": "34.845.562\/0001-58",
     "CNAE Principal": "46.71-1-00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "16\/02\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1280,
     "Tipo": "PJ",
     "Razão Social\/Nome": "BRANDAO DISTRIBUIDORA DE FERRAGEM LTDA",
     "Nome Fantasia\/Apelido": "BRANDAO FERRAGENS PARA VIDRO TEMPERADO",
     "CNPJ": "40.901.218\/0001-31",
     "CNAE Principal": "4672-9\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "16\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1281,
     "Tipo": "PJ",
     "Razão Social\/Nome": "INSTITUTO EUFRASIO CONSULTORIA E ODONTOLOGIA LTDA",
     "Nome Fantasia\/Apelido": "INSTITUTO EUFRASIO",
     "CNPJ": "46.685.903\/0001-54",
     "CNAE Principal": "86.30-5-04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "16\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1282,
     "Tipo": "PJ",
     "Razão Social\/Nome": "FAZENDA OURO BRANCO LTDA",
     "Nome Fantasia\/Apelido": "FAZENDA OURO BRANCO",
     "CNPJ": "39.890.288\/0001-52",
     "CNAE Principal": " 0151-2\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "16\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1283,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SCR-SINALIZACAO E CONSERVACAO DE RODOVIAS LTDA.",
     "CNPJ": " 02.873.295\/0006-40",
     "CNAE Principal": "4222-7\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "16\/02\/2023",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1284,
     "Tipo": "PJ",
     "Razão Social\/Nome": "TALES GOMES SOARES 14538039632",
     "Nome Fantasia\/Apelido": "FUNERARIA GOMES",
     "CNPJ": "46.159.396\/0001-15",
     "CNAE Principal": "9603-3\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "16\/02\/2023",
     "Estado": "MG",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1285,
     "Tipo": "PJ",
     "Razão Social\/Nome": "M. DE N. SILVA DA LUZ",
     "Nome Fantasia\/Apelido": "MERCADINHO E ACOUGUE M. D.",
     "CNPJ": "13.476.913\/0001-20",
     "CNAE Principal": "4712-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "16\/02\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1286,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LUIZ MATIAS 34443140867",
     "CNPJ": "27.157.073\/0001-48",
     "CNAE Principal": "4330-4\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "16\/02\/2023",
     "Estado": "PR",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1287,
     "Tipo": "PJ",
     "Razão Social\/Nome": "IURY Q. FERNANDES",
     "Nome Fantasia\/Apelido": "AGUIA SERVICOS AGRICOLAS",
     "CNPJ": "15.147.729\/0001-62",
     "CNAE Principal": " 0161-0\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "16\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1288,
     "Tipo": "PJ",
     "Razão Social\/Nome": "EDMILSON CANTANHEDE COSTA",
     "Nome Fantasia\/Apelido": "RETIFICA DE MOTORES AGINDO DEUS",
     "CNPJ": "13.904.518\/0001-00",
     "CNAE Principal": "2950-6\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "16\/02\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1289,
     "Tipo": "PJ",
     "Razão Social\/Nome": "AMS COMERCIO VAREJISTA DE ROUPAS LTDA",
     "Nome Fantasia\/Apelido": "CASA PEREIRA",
     "CNPJ": "11.410.485\/0001-08",
     "CNAE Principal": "47.81-4-00 ",
     "Região": 28,
     "Situação": "Ativa",
     "Data Cadastro": "16\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1290,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SALMERON ENERGIA RENOVAVEL E PROTECAO AO CLIMA SUL LTDA",
     "CNPJ": "46.358.488\/0002-05",
     "CNAE Principal": "35.30-1-00 ",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "16\/02\/2023",
     "Estado": "RS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1291,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DENIS DE GOES CAMARGO LTDA",
     "Nome Fantasia\/Apelido": "DGSTARK TECNOLOGIA",
     "CNPJ": "22.956.851\/0001-45",
     "CNAE Principal": "2651-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "16\/02\/2023",
     "Estado": "MT",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1292,
     "Tipo": "PJ",
     "Razão Social\/Nome": "J A PROJECT & ENGENHARIA LTDA",
     "Nome Fantasia\/Apelido": "J & A ENGENHARIA",
     "CNPJ": " 05.325.864\/0001-05",
     "CNAE Principal": "4120-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "17\/02\/2023",
     "Estado": "SE",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1293,
     "Tipo": "PJ",
     "Razão Social\/Nome": "TECFIT BOA VIAGEM ACADEMIA LTDA",
     "Nome Fantasia\/Apelido": "TECFIT BOA VIAGEM",
     "CNPJ": "33.693.247\/0001-90",
     "CNAE Principal": "9313-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "17\/02\/2023",
     "Estado": "PE",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1294,
     "Tipo": "PJ",
     "Razão Social\/Nome": "AGROFRUT POLPAS LTDA",
     "Nome Fantasia\/Apelido": "AGROFRUT POLPAS",
     "CNPJ": "27.642.417\/0001-04",
     "CNAE Principal": "1033-3\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "17\/02\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1295,
     "Tipo": "PJ",
     "Razão Social\/Nome": "D M PAIXAO COMERCIO DE GLP LTDA",
     "Nome Fantasia\/Apelido": "THAYANI",
     "CNPJ": " 07.075.334\/0001-73",
     "CNAE Principal": "4784-9\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "17\/02\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1296,
     "Tipo": "PJ",
     "Razão Social\/Nome": "M I C LIMA & CIA LTDA",
     "Nome Fantasia\/Apelido": "A NACIONAL",
     "CNPJ": " 04.083.148\/0001-98",
     "CNAE Principal": "4781-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "18\/02\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1297,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SUPERMERCADO NACIONAL LTDA",
     "Nome Fantasia\/Apelido": "SUPERMERCADO NACIONAL",
     "CNPJ": "26.915.740\/0001-41",
     "CNAE Principal": "4711-3\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "18\/02\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1299,
     "Tipo": "PJ",
     "Razão Social\/Nome": "J C M CAMILO & L R SILVA LTDA",
     "Nome Fantasia\/Apelido": "DROGARIA SANTA LUZIA",
     "CNPJ": " 04.154.707\/0002-94",
     "CNAE Principal": "4771-7\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "20\/02\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1300,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SUELLEN PATRICIA GAMA DA SILVA 82482861268",
     "Nome Fantasia\/Apelido": "R&S CURSOS E TREINAMENTOS",
     "CNPJ": "37.917.419\/0001-12",
     "CNAE Principal": "8599-6\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "20\/02\/2023",
     "Estado": "RR",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1301,
     "Tipo": "PJ",
     "Razão Social\/Nome": "RAIMUNDO JOSE SILVA DOS SANTOS",
     "Nome Fantasia\/Apelido": "RC TURISMO AGENCIA DE VIAGENS",
     "CNPJ": "17.334.729\/0001-05",
     "CNAE Principal": "7911-2\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "20\/02\/2023",
     "Estado": "RR",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1302,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ROGERIO MAURO DE SIQUEIRA",
     "CNPJ": "53.869.400\/0001-02",
     "CNAE Principal": "5611-2\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "20\/02\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1303,
     "Tipo": "PJ",
     "Razão Social\/Nome": "HIDROSHOW BOMBAS E EQUIPAMENTOS LIMITADA",
     "CNPJ": "40.399.263\/0001-30",
     "CNAE Principal": "4789-0\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "20\/02\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1305,
     "Tipo": "PJ",
     "Razão Social\/Nome": "KLEUSON K M DA SILVA SERVICOS EIRELI",
     "Nome Fantasia\/Apelido": "KMS - SISTEMAS & SERVICOS",
     "CNPJ": " 07.706.128\/0001-14",
     "CNAE Principal": "3600-6\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "20\/02\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1306,
     "Tipo": "PF",
     "Razão Social\/Nome": "CLEIDE MARIA SILVA ALMEIDA",
     "Nome Fantasia\/Apelido": "CLEIDE MARIA SILVA ALMEIDA",
     "CPF": "802.274.141-87",
     "CNAE Principal": "6912-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "20\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1307,
     "Tipo": "PJ",
     "Razão Social\/Nome": "J.N. SIQUEIRA & CIA LTDA",
     "CNPJ": "22.608.637\/0001-06",
     "CNAE Principal": "5611-2\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "20\/02\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1308,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ELITANIA LINS DA SILVA",
     "Nome Fantasia\/Apelido": "PADARIA GEOVANNA",
     "CNPJ": " 08.687.322\/0001-62",
     "CNAE Principal": "4721-1\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "20\/02\/2023",
     "Estado": "PE",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1309,
     "Tipo": "PJ",
     "Razão Social\/Nome": "J. C. F. AMORIM",
     "Nome Fantasia\/Apelido": "AMORIM PECAS",
     "CNPJ": "11.892.399\/0001-89",
     "CNAE Principal": "4541-2\/06",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "20\/02\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1310,
     "Tipo": "PJ",
     "Razão Social\/Nome": "REVIVA LTDA ME",
     "Nome Fantasia\/Apelido": "REVIVA",
     "CNPJ": "11.485.219\/0001-44",
     "CNAE Principal": "4781-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "20\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1311,
     "Tipo": "PJ",
     "Razão Social\/Nome": "BLUEX LOGISTICA LTDA",
     "CNPJ": "42.932.057\/0002-50",
     "CNAE Principal": "4930-2\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "21\/02\/2023",
     "Estado": "PR",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1312,
     "Tipo": "PJ",
     "Razão Social\/Nome": "BLUEX LOGISTICA LTDA",
     "CNPJ": "42.932.057\/0001-79",
     "CNAE Principal": "4930-2\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "21\/02\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1313,
     "Tipo": "PJ",
     "Razão Social\/Nome": "PALAVRAS PROJETOS EDITORIAIS LTDA",
     "CNPJ": " 05.606.794\/0001-55",
     "CNAE Principal": "5811-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "22\/02\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1314,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DINDA COMERCIO DE CONFECCOES EIRELI ME",
     "Nome Fantasia\/Apelido": "BELLA MAKE UP",
     "CNPJ": "13.560.739\/0001-08",
     "CNAE Principal": "4781-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "02\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1315,
     "Tipo": "PJ",
     "Razão Social\/Nome": "FABIANA COELHO DE JESUS 01881173186",
     "Nome Fantasia\/Apelido": "RD AUTO ELETRICA",
     "CNPJ": "47.618.677\/0001-51",
     "CNAE Principal": "45.20-0-03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "22\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1316,
     "Tipo": "PF",
     "Razão Social\/Nome": "CEJANA COELHO BANDEIRA GAMA ",
     "Nome Fantasia\/Apelido": "FAZENDA SÃO FRANCISCO ",
     "CPF": "335.727.201-68",
     "CNAE Principal": "412-0 ",
     "Região": 28,
     "Situação": "Ativa",
     "Data Cadastro": "22\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1318,
     "Tipo": "PF",
     "Razão Social\/Nome": "ANGILBERTO MATEUS DOS SANTOS ",
     "Nome Fantasia\/Apelido": "ANGILBERTO MATEUS DOS SANTOS ",
     "CPF": "383.346.150-00",
     "CNAE Principal": "151201 ",
     "Região": 28,
     "Situação": "Ativa",
     "Data Cadastro": "22\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1319,
     "Tipo": "PJ",
     "Razão Social\/Nome": "M DE N SANTOS GUIMARAES",
     "Nome Fantasia\/Apelido": "DPEROLA MAGAZINE",
     "CNPJ": "18.922.994\/0001-69",
     "CNAE Principal": "4781-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "22\/02\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1321,
     "Tipo": "PJ",
     "Razão Social\/Nome": "STAR-BEK AR CONDICIONADO LTDA",
     "Nome Fantasia\/Apelido": "STAR-BEK",
     "CNPJ": " 00.239.175\/0001-56",
     "CNAE Principal": "9521-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "22\/02\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1322,
     "Tipo": "PJ",
     "Razão Social\/Nome": "PITITI COMERCIO DE PRODUTOS ALIMENTICIOS LTDA",
     "Nome Fantasia\/Apelido": "FARRO",
     "CNPJ": " 00.480.496\/0001-48",
     "CNAE Principal": "5611-2\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "22\/02\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1323,
     "Tipo": "PJ",
     "Razão Social\/Nome": "J H M SANTANA COMERCIAL DE ALIMENTOS HAILTON LTDA",
     "Nome Fantasia\/Apelido": "MERCADO MATOS",
     "CNPJ": " 01.843.522\/0001-18",
     "CNAE Principal": "4729-6\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "22\/02\/2023",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1324,
     "Tipo": "PJ",
     "Razão Social\/Nome": "TECFIT MUNDO PLAZA ACADEMIA E SERVICOS ESPORTIVOS LTDA",
     "Nome Fantasia\/Apelido": "TECFIT",
     "CNPJ": "34.194.778\/0001-09",
     "CNAE Principal": "9313-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "22\/02\/2023",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1325,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DENISON COMERCIO E SERVICOS LTDA",
     "Nome Fantasia\/Apelido": "S.O.S. LAR",
     "CNPJ": "68.586.122\/0001-45",
     "CNAE Principal": "4757-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "22\/02\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1326,
     "Tipo": "PJ",
     "Razão Social\/Nome": "TAPIRI POUSADA DE CHARME LTDA",
     "Nome Fantasia\/Apelido": "POUSADA TAPIRI",
     "CNPJ": "43.244.983\/0001-14",
     "CNAE Principal": "5510-8\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "22\/02\/2023",
     "Estado": "AL",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1327,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CDR COMERCIO DE MATERIAIS PARA CONSTRUCAO LTDA",
     "Nome Fantasia\/Apelido": "CDR DESIGN",
     "CNPJ": "42.701.920\/0001-87",
     "CNAE Principal": "4744-0\/05",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "23\/02\/2023",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1328,
     "Tipo": "PJ",
     "Razão Social\/Nome": "A SANTOS QUEIROZ",
     "Nome Fantasia\/Apelido": "AUTORIZADO JAN-PRO",
     "CNPJ": "27.048.083\/0001-45",
     "CNAE Principal": "8121-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "23\/02\/2023",
     "Estado": "AM",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1329,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SILVA NETO ELETRICIDADES LTDA",
     "Nome Fantasia\/Apelido": "G.P.N. ELETRICIDADES",
     "CNPJ": " 09.189.446\/0001-80",
     "CNAE Principal": "4321-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "23\/02\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1330,
     "Tipo": "PJ",
     "Razão Social\/Nome": "FUNERARIA GLOBOPAX LTDA",
     "Nome Fantasia\/Apelido": "FUNERARIA GLOBOPAX",
     "CNPJ": " 02.213.802\/0004-58",
     "CNAE Principal": "9603-3\/04",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "23\/02\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1331,
     "Tipo": "PF",
     "Razão Social\/Nome": "MARCELO DE SOUSA JARDIM",
     "CPF": "597.537.651-34",
     "CNAE Principal": 151201,
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "23\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1332,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SALMERON ENERGIA RENOVAVEL E PROTECAO AO CLIMA SP LTDA",
     "CNPJ": "46.022.197\/0003-22",
     "CNAE Principal": "35.30-1-00",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "24\/02\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1333,
     "Tipo": "PF",
     "Razão Social\/Nome": "HELIO GARCIA DA COSTA JUNIOR",
     "Nome Fantasia\/Apelido": "Fazenda Vale perdido ",
     "CPF": "175.424.328-26",
     "CNAE Principal": 151201,
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "24\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1334,
     "Tipo": "PF",
     "Razão Social\/Nome": "JULIANA BARRETO MONTEIRO",
     "Nome Fantasia\/Apelido": "TABELIÃ DE NOTAS E DE PROTESTO DE LETRAS E TITULOS DA COMARCA DE PRESIDENTE EPITÁCIO",
     "CPF": " 097.353.897-03",
     "CNAE Principal": "69.125-00 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "24\/02\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1335,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ISLAM C. LEITE GOMES LTDA",
     "Nome Fantasia\/Apelido": "CASA DE CARNES BG",
     "CNPJ": "28.387.328\/0001-21",
     "CNAE Principal": "4722-9\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "24\/02\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1336,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ACADEMIA R4 FITNESS LTDA",
     "Nome Fantasia\/Apelido": "R4 FITNESS",
     "CNPJ": " 02.557.259\/0001-63",
     "CNAE Principal": "9313-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "24\/02\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1337,
     "Tipo": "PJ",
     "Razão Social\/Nome": "VERDENET PROVEDOR DE INTERNET LTDA",
     "Nome Fantasia\/Apelido": "VERDENET FIBRA OPTICA",
     "CNPJ": " 07.601.386\/0001-36",
     "CNAE Principal": "6110-8\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "24\/02\/2023",
     "Estado": "AM",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1338,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ROMEL CUNHA BRITO 23626089134",
     "CNPJ": "17.162.037\/0001-19",
     "CNAE Principal": "1092-9\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "25\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1339,
     "Tipo": "PJ",
     "Razão Social\/Nome": "WILSON JOSE DA SILVA",
     "Nome Fantasia\/Apelido": "CONEXAO DO GELO",
     "CNPJ": " 03.497.747\/0001-95",
     "CNAE Principal": "4712-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "25\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1340,
     "Tipo": "PJ",
     "Razão Social\/Nome": "OLIVEIRA E SILVA LTDA",
     "Nome Fantasia\/Apelido": "ELETRO MAGAZINE",
     "CNPJ": "25.344.776\/0001-50",
     "CNAE Principal": "4754-7\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "25\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1341,
     "Tipo": "PJ",
     "Razão Social\/Nome": "PAULO CESAR BORIN FILHO",
     "Nome Fantasia\/Apelido": "SERVIAGRO",
     "CNPJ": " 09.654.788\/0001-24",
     "CNAE Principal": " 0161-0\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "25\/02\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1342,
     "Tipo": "PJ",
     "Razão Social\/Nome": "PLENA ASSESSORIA E CONSULTORIA EMPRESARIAL LTDA",
     "Nome Fantasia\/Apelido": "GOLD CONTABILIDADE",
     "CNPJ": "22.794.984\/0001-62",
     "CNAE Principal": "6920-6\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "25\/02\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1343,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ASFEC CONTABILIDADE LTDA",
     "Nome Fantasia\/Apelido": "ASFEC - ASSISTENCIA FISICA E EMPRESARIAL CARVALHO",
     "CNPJ": "26.896.812\/0001-50",
     "CNAE Principal": "6920-6\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "25\/02\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1344,
     "Tipo": "PJ",
     "Razão Social\/Nome": "COMERCIAL SENHOR DO BONFIM LTDA",
     "Nome Fantasia\/Apelido": "COMERCIAL SENHOR DO BONFIM",
     "CNPJ": "12.378.731\/0001-54",
     "CNAE Principal": "4755-5\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "26\/02\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1345,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LIFE ODONTO LTDA",
     "Nome Fantasia\/Apelido": "ODONTOCOMPANY",
     "CNPJ": "23.263.594\/0005-52",
     "CNAE Principal": "8630-5\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "26\/02\/2023",
     "Estado": "MA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1346,
     "Tipo": "PJ",
     "Razão Social\/Nome": "RICARDO B. B. COSTA & CIA LTDA",
     "Nome Fantasia\/Apelido": "ODONTOCOMPANY",
     "CNPJ": "24.950.228\/0001-00",
     "CNAE Principal": "8630-5\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "26\/02\/2023",
     "Estado": "MA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1347,
     "Tipo": "PJ",
     "Razão Social\/Nome": "BOUERES ODONTOLOGIA LTDA",
     "Nome Fantasia\/Apelido": "ODONTOCOMPANY",
     "CNPJ": "28.189.704\/0002-54",
     "CNAE Principal": "8630-5\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "26\/02\/2023",
     "Estado": "MA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1348,
     "Tipo": "PJ",
     "Razão Social\/Nome": "BOUERES E COSTA ODONTOLOGIA LTDA",
     "Nome Fantasia\/Apelido": "ORAL SIN",
     "CNPJ": "39.351.299\/0001-64",
     "CNAE Principal": "8630-5\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "26\/02\/2023",
     "Estado": "MA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1349,
     "Tipo": "PJ",
     "Razão Social\/Nome": "BOUERES E BOUERES LTDA",
     "Nome Fantasia\/Apelido": "ODONTOCOMPANY",
     "CNPJ": "26.541.025\/0001-96",
     "CNAE Principal": "8630-5\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "26\/02\/2023",
     "Estado": "MA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1350,
     "Tipo": "PJ",
     "Razão Social\/Nome": "BRUNA B. C. OLIVEIRA & CIA LTDA",
     "Nome Fantasia\/Apelido": "ODONTOCOMPANY",
     "CNPJ": "26.626.030\/0002-82",
     "CNAE Principal": "8630-5\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "26\/02\/2023",
     "Estado": "MA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1351,
     "Tipo": "PJ",
     "Razão Social\/Nome": "J S DE SOUSA & CIA LTDA",
     "Nome Fantasia\/Apelido": "ODONTOCOMPANY",
     "CNPJ": "35.640.882\/0001-34",
     "CNAE Principal": "8630-5\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "26\/02\/2023",
     "Estado": "MA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1352,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ODONTO BARREIRINHAS LTDA",
     "Nome Fantasia\/Apelido": "ODONTOCOMPANY",
     "CNPJ": "47.008.169\/0001-51",
     "CNAE Principal": "86.30-5-04 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "26\/02\/2023",
     "Estado": "MA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1353,
     "Tipo": "PJ",
     "Razão Social\/Nome": "IZABEL DA C. SOUZA",
     "Nome Fantasia\/Apelido": "BEL MODAS",
     "CNPJ": "18.015.015\/0001-99",
     "CNAE Principal": "4781-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "26\/02\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1354,
     "Tipo": "PJ",
     "Razão Social\/Nome": "N. QUEIROZ DA S. LTDA",
     "Nome Fantasia\/Apelido": "BALANTEC",
     "CNPJ": "30.411.225\/0001-00",
     "CNAE Principal": "3312-1\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "26\/02\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1355,
     "Tipo": "PJ",
     "Razão Social\/Nome": "F. E. DE LIMA BORGES",
     "Nome Fantasia\/Apelido": "BOUTIQUE DESEJO DE MULHER",
     "CNPJ": "13.467.644\/0001-36",
     "CNAE Principal": "4781-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "26\/02\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1356,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ACC & AC REVESTIMENTOS E DIVISORIAS LTDA",
     "CNPJ": " 09.603.627\/0001-01",
     "CNAE Principal": "4649-4\/05",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "26\/02\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1357,
     "Tipo": "PJ",
     "Razão Social\/Nome": "RADIOCLIN - SERVICOS DE RADIOLOGIA LTDA",
     "Nome Fantasia\/Apelido": "RADIOCLIN",
     "CNPJ": "13.453.641\/0001-43",
     "CNAE Principal": "8640-2\/05",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "27\/02\/2023",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1358,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CAMPAGNOLLO MAGAZINE DO PISO LTDA",
     "CNPJ": "13.390.102\/0001-02",
     "CNAE Principal": "4744-0\/05",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "27\/02\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1359,
     "Tipo": "PF",
     "Razão Social\/Nome": "LUIZ EDUARDO DE HOLANDA LOPES",
     "CPF": "533.903.424-00",
     "CNAE Principal": " 01.21-1",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "27\/02\/2023",
     "Estado": "PE",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1360,
     "Tipo": "PJ",
     "Razão Social\/Nome": "BOUERES ODONTOLOGIA LTDA.",
     "Nome Fantasia\/Apelido": "ODONTOCOMPANY",
     "CNPJ": "28.189.704\/0001-73",
     "CNAE Principal": "8630-5\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "27\/02\/2023",
     "Estado": "MA",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1361,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SALAMANCA ALIMENTOS LTDA",
     "Nome Fantasia\/Apelido": "LA BOCA PIZZARIA ANANINDEUA",
     "CNPJ": "47.339.227\/0001-20",
     "CNAE Principal": "56.11-2-01 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "27\/02\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1362,
     "Tipo": "PF",
     "Razão Social\/Nome": "REGISTRO CIVIL DE PESSOAS NATURAIS, TABELIONATO DE NOTAS, PROTESTOS DE TIT MERC E PESSOAS JURID DA COMARCA DE NOBRES\/MT 2 OFICIO",
     "Nome Fantasia\/Apelido": "2 OFICIO DE NOBRES - MT",
     "CPF": " 025.252.305-90",
     "CNAE Principal": "6912-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "27\/02\/2023",
     "Estado": "MT",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1363,
     "Tipo": "PF",
     "Razão Social\/Nome": "JOSE FELIX DA SILVA NETO",
     "CPF": "306.807.272-72",
     "CNAE Principal": "155501 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "27\/02\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1364,
     "Tipo": "PJ",
     "Razão Social\/Nome": "NELSON DONIZETE ACETI PRESTACAO DE SERVICOS",
     "Nome Fantasia\/Apelido": "ACETI",
     "CNPJ": "39.434.905\/0001-05",
     "CNAE Principal": " 0161-0\/99",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "27\/02\/2023",
     "Estado": "PR",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1365,
     "Tipo": "PJ",
     "Razão Social\/Nome": "EU IMPLANTES SERVICOS ODONTOLOGICOS LTDA",
     "Nome Fantasia\/Apelido": "EU IMPLANTES",
     "CNPJ": "30.789.750\/0001-55",
     "CNAE Principal": "8630-5\/04",
     "Região": 11,
     "Situação": "Ativa",
     "Data Cadastro": "27\/02\/2023",
     "Estado": "PE",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1366,
     "Tipo": "PJ",
     "Razão Social\/Nome": "R. R. O. MOURA",
     "Nome Fantasia\/Apelido": "STUDIO HULK",
     "CNPJ": "13.096.814\/0001-13",
     "CNAE Principal": "9313-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "27\/02\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1368,
     "Tipo": "PJ",
     "Razão Social\/Nome": "RICARDO B. B. COSTA & CIA LTDA,",
     "Nome Fantasia\/Apelido": "ODONTOCOMPANY",
     "CNPJ": "24.950.228\/0002-91",
     "CNAE Principal": "8630-5\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "28\/02\/2023",
     "Estado": "PA",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1369,
     "Tipo": "PJ",
     "Razão Social\/Nome": "BRUNA B. C. OLIVEIRA & CIA LTDA.",
     "Nome Fantasia\/Apelido": "ODONTOCOMPANY",
     "CNPJ": "26.626.030\/0001-00",
     "CNAE Principal": "8630-5\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "28\/02\/2023",
     "Estado": "MA",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1370,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LEAL & SOARES IND. E COM. DE TIJOLOS LTDA",
     "Nome Fantasia\/Apelido": "CERAMICA LEAL",
     "CNPJ": "48.371.788\/0001-79",
     "CNAE Principal": "23.42-7-02 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "28\/02\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1371,
     "Tipo": "PJ",
     "Razão Social\/Nome": "BARRA SPACE DENTAL ODONTOLOGIA LTDA",
     "CNPJ": "11.178.082\/0001-85",
     "CNAE Principal": "8630-5\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "28\/02\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1372,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ECOPEL COMERCIO DE RESIDUOS LTDA",
     "Nome Fantasia\/Apelido": "ECOPEL",
     "CNPJ": "10.456.906\/0001-79",
     "CNAE Principal": "4687-7\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "28\/02\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1373,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ERNANI ZAT",
     "Nome Fantasia\/Apelido": "ALVORADA MATERIAIS DE CONSTRUCAO",
     "CNPJ": "14.223.106\/0001-69",
     "CNAE Principal": "4744-0\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "28\/02\/2023",
     "Estado": "GO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1374,
     "Tipo": "PJ",
     "Razão Social\/Nome": "R B ODONTOLOGIA LTDA",
     "Nome Fantasia\/Apelido": "ODONTOCOMPANY",
     "CNPJ": "45.157.225\/0001-94",
     "CNAE Principal": "8630-5\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "01\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1375,
     "Tipo": "PF",
     "Razão Social\/Nome": "BRUNO FERREIRA PENA",
     "Nome Fantasia\/Apelido": "FAZENDA CAMAPUÃ",
     "CPF": " 014.052.116-01",
     "CNAE Principal": 151201,
     "Região": 28,
     "Situação": "Ativa",
     "Data Cadastro": "01\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1377,
     "Tipo": "PJ",
     "Razão Social\/Nome": "BOUERES COSTA ODONTOLOGIA LTDA",
     "Nome Fantasia\/Apelido": "ODONTOCOMPANY",
     "CNPJ": "28.420.639\/0001-45",
     "CNAE Principal": "8630-5\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "01\/03\/2023",
     "Estado": "MA",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1378,
     "Tipo": "PJ",
     "Razão Social\/Nome": "BOUERES ODONTOLOGIA LTDA,",
     "CNPJ": "28.189.704\/0003-35",
     "CNAE Principal": "8630-5\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "01\/03\/2023",
     "Estado": "MA",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1379,
     "Tipo": "PJ",
     "Razão Social\/Nome": "RICARDO B B COSTA ODONTOLOGIA,",
     "Nome Fantasia\/Apelido": "ODONTOCOMPANY",
     "CNPJ": "39.229.520\/0001-06",
     "CNAE Principal": "8630-5\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "01\/03\/2023",
     "Estado": "MA",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1380,
     "Tipo": "PJ",
     "Razão Social\/Nome": "NORTE BRASIL TRANSPORTE E COMERCIO DE CEREAIS LTDA",
     "Nome Fantasia\/Apelido": "NORTE BRASIL & CIA LTDA",
     "CNPJ": "17.949.226\/0001-36",
     "CNAE Principal": "4632-0\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "01\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1381,
     "Tipo": "PJ",
     "Razão Social\/Nome": "PANAMERICANA MATERIAIS DE CONSTRUCAO LTDA",
     "Nome Fantasia\/Apelido": "PANAMERICANA MATERIAL DE CONSTRUCAO",
     "CNPJ": "11.461.042\/0001-46",
     "CNAE Principal": "4744-0\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "01\/03\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1382,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SAIDA SUPERMERCADO",
     "Nome Fantasia\/Apelido": "SAIDA SUPERMERCADO",
     "CNPJ": "23.248.269\/0001-97",
     "CNAE Principal": "4711-3\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "01\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1383,
     "Tipo": "PJ",
     "Razão Social\/Nome": "TUGURIO DA SORTE LOTERIAS LTDA",
     "CNPJ": "13.646.325\/0001-98",
     "CNAE Principal": "8299-7\/06",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "01\/03\/2023",
     "Estado": "MG",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1384,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LE VIVRE CAFETERIA LTDA  ",
     "Nome Fantasia\/Apelido": "CAFE LE VIVRE",
     "CNPJ": "46.953.580\/0001-32",
     "CNAE Principal": "56.11-2-03 -",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "01\/03\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1385,
     "Tipo": "PJ",
     "Razão Social\/Nome": "R S COMERCIO DE PRODUTOS ALIMENTICIOS LTDA",
     "Nome Fantasia\/Apelido": "FARRO",
     "CNPJ": "13.518.070\/0001-88",
     "CNAE Principal": "5611-2\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "02\/03\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1386,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MARSELHA ALIMENTOS LTDA",
     "Nome Fantasia\/Apelido": "LA BOCA PIZZARIA BELEM",
     "CNPJ": "44.117.434\/0001-41",
     "CNAE Principal": "5611-2\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "02\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1387,
     "Tipo": "PJ",
     "Razão Social\/Nome": "NH ASSESSORIA E CONSULTORIA DE SEGUROS LTDA",
     "Nome Fantasia\/Apelido": "NH ASSESSORIA",
     "CNPJ": "24.940.089\/0001-34",
     "CNAE Principal": "6629-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "02\/03\/2023",
     "Estado": "RS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1388,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CATALUNHA ALIMENTOS COMERCIO DE PRODUTOS ALIMENTICIOS EIRELI",
     "Nome Fantasia\/Apelido": "LA BOCA PIZZARIA",
     "CNPJ": "36.357.631\/0001-00",
     "CNAE Principal": "5611-2\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "02\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1389,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DEYSE DHULLE SOBREIRA ARAUJO EIRELI",
     "Nome Fantasia\/Apelido": "D BARROS MUNCK AUTOPECAS",
     "CNPJ": "27.897.625\/0001-54",
     "CNAE Principal": "2833-0\/00",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "02\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1390,
     "Tipo": "PJ",
     "Razão Social\/Nome": "B. COELHO PARRIAO",
     "Nome Fantasia\/Apelido": "AGRO HOMEOPATIA NORTE",
     "CNPJ": "35.469.258\/0001-16",
     "CNAE Principal": " 0161-0\/03",
     "Região": 28,
     "Situação": "Ativa",
     "Data Cadastro": "02\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1391,
     "Tipo": "PJ",
     "Razão Social\/Nome": "NINA SAUDE VITORIA LTDA",
     "Nome Fantasia\/Apelido": "NINA SAUDE",
     "CNPJ": "42.193.915\/0001-00",
     "CNAE Principal": "8630-5\/06",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "02\/03\/2023",
     "Estado": "ES",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1392,
     "Tipo": "PJ",
     "Razão Social\/Nome": "EMPORIUM CORRETORA E ADMINISTRADORA DE SEGUROS EIRELI",
     "CNPJ": "28.786.287\/0001-46",
     "CNAE Principal": "6622-3\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "02\/03\/2023",
     "Estado": "RS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1393,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SILVA COM DE GENEROS ALIMENTICIOS LTDA",
     "Nome Fantasia\/Apelido": "MERCANTIL SAO FRANCISCO",
     "CNPJ": "14.111.154\/0001-65",
     "CNAE Principal": "4712-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "02\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1394,
     "Tipo": "PJ",
     "Razão Social\/Nome": "JOSE DIAS DA SILVA - O FORMOSO",
     "Nome Fantasia\/Apelido": "CASA DIAS",
     "CNPJ": "11.997.923\/0001-86",
     "CNAE Principal": "47.81-4-00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "02\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1395,
     "Tipo": "PJ",
     "Razão Social\/Nome": "FBV CONSTRUTORA E INCORPORADORA LTDA",
     "CNPJ": "34.682.657\/0001-06",
     "CNAE Principal": "4120-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "02\/03\/2023",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1397,
     "Tipo": "PJ",
     "Razão Social\/Nome": "26.078.707 PAMELA THAIS GOMES DA SILVA",
     "Nome Fantasia\/Apelido": "PAMELA BIJU",
     "CNPJ": "26.078.707\/0001-04",
     "CNAE Principal": "4789-0\/01",
     "Região": 7,
     "Situação": "Ativa",
     "Data Cadastro": "02\/03\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1398,
     "Tipo": "PJ",
     "Razão Social\/Nome": "EMPREENDIMENTOS IMOBILIARIOS J.L. LTDA",
     "Nome Fantasia\/Apelido": "C IMOBILIARIA CASTELLO",
     "CNPJ": " 01.464.226\/0001-06",
     "CNAE Principal": "6821-8\/02",
     "Região": 7,
     "Situação": "Ativa",
     "Data Cadastro": "02\/03\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1399,
     "Tipo": "PJ",
     "Razão Social\/Nome": "AMS COMERCIO VAREJISTA DE ROUPAS LTDA ",
     "Nome Fantasia\/Apelido": "CASA PEREIRA TECIDOS E CONFECCOES",
     "CNPJ": "11.410.485\/0002-99",
     "CNAE Principal": "4781-4\/00",
     "Região": 28,
     "Situação": "Ativa",
     "Data Cadastro": "03\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1400,
     "Tipo": "PJ",
     "Razão Social\/Nome": "R. BOTELHO DA S. LTDA",
     "Nome Fantasia\/Apelido": "BR ALIMENTOS",
     "CNPJ": "28.549.635\/0001-61",
     "CNAE Principal": "4632-0\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "03\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1401,
     "Tipo": "PJ",
     "Razão Social\/Nome": "L ROCHA MACEDO",
     "Nome Fantasia\/Apelido": "DG SPORT",
     "CNPJ": "29.956.077\/0001-11",
     "CNAE Principal": "4763-6\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "03\/03\/2023",
     "Estado": "MA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1402,
     "Tipo": "PJ",
     "Razão Social\/Nome": "INCENTIVO - SISTEMA DE EDUCACAO BASICA LTDA",
     "Nome Fantasia\/Apelido": "SEI - SISTEMA DE ENSINO INCENTIVO",
     "CNPJ": " 07.869.021\/0001-97",
     "CNAE Principal": "8520-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "03\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1403,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ORGANIMINAS PRODUCAO DE ADUBOS ORGANICOS LTDA",
     "Nome Fantasia\/Apelido": "ORGANEE",
     "CNPJ": "40.614.762\/0001-00",
     "CNAE Principal": "3839-4\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "03\/03\/2023",
     "Estado": "MG",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1404,
     "Tipo": "PJ",
     "Razão Social\/Nome": "PATRICIA AYOROA RAMOS",
     "Nome Fantasia\/Apelido": "PS TRANSPORTE",
     "CNPJ": "12.627.552\/0001-03",
     "CNAE Principal": "4930-2\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "03\/03\/2023",
     "Estado": "MS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1405,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CONSTRUTORA FRAGOSO LTDA",
     "Nome Fantasia\/Apelido": "CONSTRUTORA FRAGOSO",
     "CNPJ": "45.223.966\/0001-26",
     "CNAE Principal": " 0161-0\/03",
     "Região": 28,
     "Situação": "Ativa",
     "Data Cadastro": "03\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1406,
     "Tipo": "PF",
     "Razão Social\/Nome": "MARIO FRANCESCHI NETTO",
     "Nome Fantasia\/Apelido": "MARIO FRANCESCHI NETTO",
     "CPF": "298.139.708-71",
     "CNAE Principal": 151201,
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "03\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1407,
     "Tipo": "PJ",
     "Razão Social\/Nome": "26 FIT LUZO ACADEMIA DE MUSCULACAO LTDA",
     "Nome Fantasia\/Apelido": "26 FIT",
     "CNPJ": "13.396.172\/0001-78",
     "CNAE Principal": "9313-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "04\/03\/2023",
     "Estado": "RS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1409,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ECOPEL COMERCIO DE RESIDUOS LTDA",
     "Nome Fantasia\/Apelido": "ECOPEL CARTONAGEM",
     "CNPJ": "10.456.906\/0002-50",
     "CNAE Principal": "1733-8\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "04\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1410,
     "Tipo": "PJ",
     "Razão Social\/Nome": "26 FIT LUZO ACADEMIA DE MUSCULACAO LTDA.",
     "CNPJ": "13.396.172\/0008-44",
     "CNAE Principal": "93.13-1-00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "04\/03\/2023",
     "Estado": "RS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1411,
     "Tipo": "PJ",
     "Razão Social\/Nome": "26 FIT LUZO ACADEMIA DE MUSCULACAO LTDA",
     "Nome Fantasia\/Apelido": "26 FIT ",
     "CNPJ": "13.396.172\/0009-25",
     "CNAE Principal": "93.13-1\/00",
     "Região": 10,
     "Situação": "Ativa",
     "Data Cadastro": "04\/03\/2023",
     "Estado": "RS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1412,
     "Tipo": "PJ",
     "Razão Social\/Nome": "26 FIT LUZO ACADEMIA DE MUSCULACAO LTDA",
     "Nome Fantasia\/Apelido": "26 FIT ",
     "CNPJ": "13.396.172\/0010-69",
     "CNAE Principal": "93.13-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "04\/03\/2023",
     "Estado": "RS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1413,
     "Tipo": "PJ",
     "Razão Social\/Nome": "26 FIT LUZO ACADEMIA DE MUSCULACAO LTDA",
     "CNPJ": "13.396.172\/0007-63",
     "CNAE Principal": "93.13-1-00",
     "Região": 10,
     "Situação": "Ativa",
     "Data Cadastro": "04\/03\/2023",
     "Estado": "RS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1414,
     "Tipo": "PJ",
     "Razão Social\/Nome": "26 FIT PRO ACADEMIA DE MUSCULACAO LTDA",
     "Nome Fantasia\/Apelido": "26 FIT",
     "CNPJ": "19.852.108\/0001-30",
     "CNAE Principal": "9313-1\/00",
     "Região": 10,
     "Situação": "Ativa",
     "Data Cadastro": "04\/03\/2023",
     "Estado": "RS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1415,
     "Tipo": "PJ",
     "Razão Social\/Nome": "26 FIT LUZO ACADEMIA DE MUSCULACAO LTDA",
     "CNPJ": "13.396.172\/0006-82",
     "CNAE Principal": "93.13-1-00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "04\/03\/2023",
     "Estado": "RS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1416,
     "Tipo": "PJ",
     "Razão Social\/Nome": "26 FIT LUZO ACADEMIA DE MUSCULACAO LTDA",
     "CNPJ": "13.396.172\/0005-00",
     "CNAE Principal": "93.13-1-00",
     "Região": 10,
     "Situação": "Ativa",
     "Data Cadastro": "04\/03\/2023",
     "Estado": "RS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1417,
     "Tipo": "PJ",
     "Razão Social\/Nome": "26 FIT LUZO ACADEMIA DE MUSCULACAO LTDA",
     "CNPJ": "13.396.172\/0003-30",
     "CNAE Principal": "93.13-1-00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "04\/03\/2023",
     "Estado": "RS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1418,
     "Tipo": "PJ",
     "Razão Social\/Nome": "26 FIT LUZO ACADEMIA DE MUSCULACAO LTDA",
     "CNPJ": "13.396.172\/0002-59",
     "CNAE Principal": "93.13-1-00",
     "Região": 10,
     "Situação": "Ativa",
     "Data Cadastro": "05\/03\/2023",
     "Estado": "RS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1420,
     "Tipo": "PJ",
     "Razão Social\/Nome": "26 FIT PRO ACADEMIA DE MUSCULACAO LTDA",
     "CNPJ": "19.852.108\/0005-64",
     "CNAE Principal": "9313-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "05\/03\/2023",
     "Estado": "RS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1421,
     "Tipo": "PJ",
     "Razão Social\/Nome": "26 FIT PRO ACADEMIA DE MUSCULACAO LTDA",
     "Nome Fantasia\/Apelido": "26 FIT ",
     "CNPJ": "19.852.108\/0007-26",
     "CNAE Principal": "9313-1\/00",
     "Região": 10,
     "Situação": "Ativa",
     "Data Cadastro": "05\/03\/2023",
     "Estado": "RS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1422,
     "Tipo": "PJ",
     "Razão Social\/Nome": "26 FIT PRO ACADEMIA DE MUSCULACAO LTDA",
     "Nome Fantasia\/Apelido": "26 FIT ",
     "CNPJ": "19.852.108\/0008-07",
     "CNAE Principal": "9313-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "05\/03\/2023",
     "Estado": "RS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1423,
     "Tipo": "PJ",
     "Razão Social\/Nome": "26 FIT PRO ACADEMIA DE MUSCULACAO LTDA",
     "Nome Fantasia\/Apelido": "26 FIT",
     "CNPJ": "19.852.108\/0009-98",
     "CNAE Principal": "93.13-1\/00",
     "Região": 10,
     "Situação": "Ativa",
     "Data Cadastro": "05\/03\/2023",
     "Estado": "SC",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1424,
     "Tipo": "PJ",
     "Razão Social\/Nome": "COMERCIO AGRO M M EIRELI",
     "Nome Fantasia\/Apelido": "M M AGROPECUARIA",
     "CNPJ": "39.154.538\/0001-96",
     "CNAE Principal": "4771-7\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "05\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1425,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MOTO PECAS LIMA LTDA",
     "Nome Fantasia\/Apelido": "MOTO PECAS LIMA",
     "CNPJ": " 09.107.253\/0001-33",
     "CNAE Principal": "4541-2\/06",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "05\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1426,
     "Tipo": "PJ",
     "Razão Social\/Nome": "A L COSTA BARBOSA LTDA",
     "Nome Fantasia\/Apelido": "CONSULTORIO ODONTOLOGICO +SORRISO",
     "CNPJ": "31.056.257\/0001-99",
     "CNAE Principal": "8630-5\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "05\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1427,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DENTISTA DA FAMILIA ODONTOLOGIA LTDA",
     "Nome Fantasia\/Apelido": "DENTISTA DA FAMILIA",
     "CNPJ": "44.533.002\/0001-11",
     "CNAE Principal": "8630-5\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "05\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1428,
     "Tipo": "PJ",
     "Razão Social\/Nome": "AGIL GAS LTDA",
     "Nome Fantasia\/Apelido": "AGIL GAS",
     "CNPJ": "32.416.466\/0001-69",
     "CNAE Principal": "4784-9\/00",
     "Região": 28,
     "Situação": "Ativa",
     "Data Cadastro": "05\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1429,
     "Tipo": "PJ",
     "Razão Social\/Nome": "AGROPECUARIA FORMOSO LTDA",
     "CNPJ": "36.986.354\/0001-02",
     "CNAE Principal": "4789-0\/99",
     "Região": 28,
     "Situação": "Ativa",
     "Data Cadastro": "05\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1430,
     "Tipo": "PF",
     "Razão Social\/Nome": "PAULO VICENTE DA MOTA",
     "Nome Fantasia\/Apelido": "FAZENDA CURITIBA",
     "CPF": "232.488.231-00",
     "CNAE Principal": "1512\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "05\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1431,
     "Tipo": "PJ",
     "Razão Social\/Nome": "Monte Carlos Estruturas LTDA",
     "Nome Fantasia\/Apelido": "MONTE CARLOS ESTRUTURAS",
     "CNPJ": "35.736.692\/0001-15",
     "CNAE Principal": "2542-0\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "05\/03\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1432,
     "Tipo": "PJ",
     "Razão Social\/Nome": "I L FERREIRA COMERCIO DE FORROS LTDA",
     "Nome Fantasia\/Apelido": "PARA FORROS",
     "CNPJ": "19.197.117\/0001-35",
     "CNAE Principal": "4744-0\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "06\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1433,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ASSOCIACAO ATLETICA BANCO DO BRASIL-GUARAI GOIAS",
     "Nome Fantasia\/Apelido": "AABB",
     "CNPJ": " 01.785.930\/0001-60",
     "CNAE Principal": "9312-3\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "06\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1434,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SANTOS FREIRE CONSTRUCOES LTDA",
     "Nome Fantasia\/Apelido": "SANTOS FREIRE CONSTRUCOES",
     "CNPJ": " 09.147.934\/0001-25",
     "CNAE Principal": "4120-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "06\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1435,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CRECHE ESCOLA PRIMEIROS PASSOS LTDA",
     "Nome Fantasia\/Apelido": "CRECHE ESCOLA PRIMEIROS PASSOS",
     "CNPJ": "21.508.810\/0001-23",
     "CNAE Principal": "8511-2\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "06\/03\/2023",
     "Estado": "MA",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1436,
     "Tipo": "PJ",
     "Razão Social\/Nome": "FORMOSO COMERCIO DE ELETRODOMESTICOS EIRELI",
     "Nome Fantasia\/Apelido": "FORMOSO ELETROS",
     "CNPJ": "17.509.730\/0001-15",
     "CNAE Principal": "4754-7\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "06\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1438,
     "Tipo": "PJ",
     "Razão Social\/Nome": "RMEC ASSESSORIA EMPRESARIAL E EDUCACIONAL LTDA",
     "CNPJ": "13.796.191\/0001-91",
     "CNAE Principal": "8532-5\/00",
     "Região": 11,
     "Situação": "Ativa",
     "Data Cadastro": "06\/03\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1439,
     "Tipo": "PJ",
     "Razão Social\/Nome": "TOPOWILD ENGENHARIA E TOPOGRAFIA LTDA",
     "Nome Fantasia\/Apelido": "TOPOWILD ENGENHARIA E TOPOGRAFIA",
     "CNPJ": "28.613.782\/0001-53",
     "CNAE Principal": "7119-7\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "06\/03\/2023",
     "Estado": "MG",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1440,
     "Tipo": "PJ",
     "Razão Social\/Nome": "OUROSFOS PRODUTOS VETERINARIOS EIRELI",
     "Nome Fantasia\/Apelido": "OUROFOS",
     "CNPJ": " 01.673.009\/0001-26",
     "CNAE Principal": "4771-7\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "07\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1441,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MOREIRA, SANTOS E CIA LTDA",
     "Nome Fantasia\/Apelido": "RENOVA ELETRO",
     "CNPJ": "22.397.180\/0001-20",
     "CNAE Principal": "4754-7\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "07\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1442,
     "Tipo": "PJ",
     "Razão Social\/Nome": "GRANDE MS SERVICOS ADMINISTRATIVOS LTDA",
     "Nome Fantasia\/Apelido": "GRUPO GRANDE MS",
     "CNPJ": "15.269.396\/0001-44",
     "CNAE Principal": "4930-2\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "07\/03\/2023",
     "Estado": "MS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1443,
     "Tipo": "PJ",
     "Razão Social\/Nome": "A. C. PEREIRA & CIA. LTDA.",
     "Nome Fantasia\/Apelido": "MERCADINHO BOM PRECO",
     "CNPJ": "10.475.349\/0001-33",
     "CNAE Principal": "4712-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "07\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1444,
     "Tipo": "PJ",
     "Razão Social\/Nome": "OLIVEIRA DOS SANTOS COMERCIAL LTDA",
     "Nome Fantasia\/Apelido": "MERCANTIL E CASA IDEAL",
     "CNPJ": "10.892.480\/0001-04",
     "CNAE Principal": "4744-0\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "07\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1445,
     "Tipo": "PJ",
     "Razão Social\/Nome": "J C M CAMILO & L R SILVA LTDA",
     "Nome Fantasia\/Apelido": "DROGARIA POPULAR",
     "CNPJ": " 04.154.707\/0001-03",
     "CNAE Principal": "4771-7\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "07\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1446,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CERAMICA FORMOSO LTDA",
     "Nome Fantasia\/Apelido": "CERAMICA FORMOSO",
     "CNPJ": "41.592.615\/0001-31",
     "CNAE Principal": "4744-0\/04",
     "Região": 28,
     "Situação": "Ativa",
     "Data Cadastro": "07\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1447,
     "Tipo": "PJ",
     "Razão Social\/Nome": "TENORIO PRODUTOS AGROPECUARIOS LTDA",
     "Nome Fantasia\/Apelido": "TENORIO PRODUTOS AGROPECUARIOS",
     "CNPJ": "10.971.785\/0001-01",
     "CNAE Principal": "4771-7\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "07\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1448,
     "Tipo": "PJ",
     "Razão Social\/Nome": "J B A DA SILVA",
     "Nome Fantasia\/Apelido": "MERCADO ECONOMIA DO POVO",
     "CNPJ": "21.516.595\/0001-02",
     "CNAE Principal": "4712-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "07\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1449,
     "Tipo": "PF",
     "Razão Social\/Nome": "ADEVANIR DE LIMA ",
     "Nome Fantasia\/Apelido": "FAZENDA MATÃO",
     "CPF": "595.244.058-49",
     "CNAE Principal": "1113-99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "07\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1450,
     "Tipo": "PJ",
     "Razão Social\/Nome": "FUNERARIA GLOBOPAX LTDA",
     "Nome Fantasia\/Apelido": "FUNERARIA GLOBOPAX",
     "CNPJ": " 02.213.802\/0001-05",
     "CNAE Principal": "9603-3\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "07\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1451,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ADAILTON AMORIM VIANA REFRIGERACAO",
     "Nome Fantasia\/Apelido": "DUDU REFRIGERACAO",
     "CNPJ": " 07.556.052\/0001-98",
     "CNAE Principal": "4322-3\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "07\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1452,
     "Tipo": "PJ",
     "Razão Social\/Nome": "COMERCIAL MARTINS ALMEIDA LTDA",
     "CNPJ": " 00.812.171\/0001-15",
     "CNAE Principal": "4754-7\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "07\/03\/2023",
     "Estado": "MG",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1453,
     "Tipo": "PJ",
     "Razão Social\/Nome": "EXPLOAR DEMOLICOES E TERRAPLENAGEM LTDA",
     "Nome Fantasia\/Apelido": "EXPLOAR DEMOLICOES E TERRAPLENAGEM",
     "CNPJ": "33.375.038\/0001-06",
     "CNAE Principal": "4311-8\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "08\/03\/2023",
     "Estado": "DF",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1454,
     "Tipo": "PJ",
     "Razão Social\/Nome": "JOSE CARLOS DOS SANTOS 19224052568",
     "Nome Fantasia\/Apelido": "LIDER SERRALHERIA MANUTENCAO E MONTAGEM",
     "CNPJ": "36.715.259\/0001-66",
     "CNAE Principal": "2542-0\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "08\/03\/2023",
     "Estado": "BA",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1455,
     "Tipo": "PJ",
     "Razão Social\/Nome": "K. C. SCHMID LTDA",
     "Nome Fantasia\/Apelido": "TIGRE AGROMAQUINAS",
     "CNPJ": " 06.198.546\/0001-85",
     "CNAE Principal": "4744-0\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "08\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1456,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SILVA E ALVES LTDA",
     "Nome Fantasia\/Apelido": "VOLCAR PECAS",
     "CNPJ": "13.672.614\/0001-61",
     "CNAE Principal": "4530-7\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "08\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1457,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SOFTMAKERINFO COMERCIO DE COMPUTADORES LTDA",
     "Nome Fantasia\/Apelido": "SOFTMAKERINFO - COMPUTADORES E SERVICOS",
     "CNPJ": " 09.226.810\/0001-35",
     "CNAE Principal": "4751-2\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "08\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1458,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ROBSON DIAS SILVA 92879071100",
     "Nome Fantasia\/Apelido": "RD AUTO ELETRICA",
     "CNPJ": "24.020.765\/0001-51",
     "CNAE Principal": "4520-0\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "08\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1459,
     "Tipo": "PF",
     "Razão Social\/Nome": "JOAO CARLOS PRADO MORANDINI",
     "Nome Fantasia\/Apelido": "FAZENDA SANTA MONICA",
     "CPF": " 063.898.358-41",
     "CNAE Principal": " 01.51-2\/01 ",
     "Região": 7,
     "Situação": "Ativa",
     "Data Cadastro": "08\/03\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1460,
     "Tipo": "PF",
     "Razão Social\/Nome": "SEBASTIAO SIMON CAMELO JUNIOR ",
     "Nome Fantasia\/Apelido": "FAZENDA TOCA DA RITINHA ",
     "CPF": "132.590.081-87",
     "CNAE Principal": " 0151-2\/02 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "08\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1461,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SALE ODONTOLOGIA LTDA",
     "Nome Fantasia\/Apelido": "ODONTOCLINIC",
     "CNPJ": "41.381.960\/0001-26",
     "CNAE Principal": "8630-5\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "09\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1462,
     "Tipo": "PF",
     "Razão Social\/Nome": "PAULO VICENTE DA MOTA.",
     "Nome Fantasia\/Apelido": "FAZENDA ESTAÇÃO",
     "CPF": "232.488.231-00",
     "CNAE Principal": "1512\/01  ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "09\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1463,
     "Tipo": "PF",
     "Razão Social\/Nome": "PAULO VICENTE DA MOTA",
     "Nome Fantasia\/Apelido": "FAZENDA MODELO",
     "CPF": "232.488.231-00",
     "CNAE Principal": "1512\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "09\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1464,
     "Tipo": "PJ",
     "Razão Social\/Nome": "R. O. DA SILVA",
     "Nome Fantasia\/Apelido": "RADICAL MOTOS PECAS E ACESSORIOS",
     "CNPJ": "12.364.062\/0001-61",
     "CNAE Principal": "4541-2\/06",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "09\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1465,
     "Tipo": "PJ",
     "Razão Social\/Nome": "B. COELHO DE SOUZA",
     "Nome Fantasia\/Apelido": "CONCRETO",
     "CNPJ": "30.652.652\/0001-71",
     "CNAE Principal": "4399-1\/03",
     "Região": 28,
     "Situação": "Ativa",
     "Data Cadastro": "09\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1466,
     "Tipo": "PJ",
     "Razão Social\/Nome": "J E A AGRONEGOCIOS LTDA",
     "Nome Fantasia\/Apelido": "RANCHO AGRO",
     "CNPJ": "40.161.736\/0001-66",
     "CNAE Principal": "4692-3\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "09\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1467,
     "Tipo": "PJ",
     "Razão Social\/Nome": "FUNERARIA GLOBOPAX LTDA.",
     "Nome Fantasia\/Apelido": "FUNERARIA GLOBOPAX S\/C LTDA",
     "CNPJ": " 02.213.802\/0003-77",
     "CNAE Principal": "9603-3\/04",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "09\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1470,
     "Tipo": "PJ",
     "Razão Social\/Nome": "S B R MENDES COMERCIO E SERVICOS",
     "Nome Fantasia\/Apelido": "BENOLIEL STORE COMERCIO E SERVICOS",
     "CNPJ": "32.959.813\/0001-08",
     "CNAE Principal": "43.29-1-03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "10\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1471,
     "Tipo": "PJ",
     "Razão Social\/Nome": "A L ALVES RODRIGUES",
     "Nome Fantasia\/Apelido": "MIX TINTAS AUTOMOTIVAS",
     "CNPJ": "24.058.238\/0001-36",
     "CNAE Principal": "4741-5\/00",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "10\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1472,
     "Tipo": "PJ",
     "Razão Social\/Nome": "FERREIRA E FERRO TRANSPORTES LTDA",
     "CNPJ": "11.761.008\/0001-97",
     "CNAE Principal": "49.30-2-02",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "10\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1473,
     "Tipo": "PF",
     "Razão Social\/Nome": "FAZENDA UNIÃO",
     "CPF": "405.595.485-91",
     "CNAE Principal": 151201,
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "11\/03\/2023",
     "Estado": "BA",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1474,
     "Tipo": "PJ",
     "Razão Social\/Nome": "A L BARRETOS",
     "Nome Fantasia\/Apelido": "ADRIANO VEICULOS",
     "CNPJ": "11.363.575\/0002-76",
     "CNAE Principal": "4512-9\/02",
     "Região": 28,
     "Situação": "Ativa",
     "Data Cadastro": "11\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1475,
     "Tipo": "PJ",
     "Razão Social\/Nome": "AMANDA VERAS PARRIAO VALENTE 03636644165",
     "Nome Fantasia\/Apelido": "VARIEDADES DO LAR",
     "CNPJ": "14.511.145\/0001-61",
     "CNAE Principal": "4713-0\/02",
     "Região": 28,
     "Situação": "Ativa",
     "Data Cadastro": "11\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1476,
     "Tipo": "PJ",
     "Razão Social\/Nome": "BRASIL ECOCLEAN LTDA",
     "Nome Fantasia\/Apelido": "MARCIO PECA A PECA",
     "CNPJ": "19.782.020\/0001-90",
     "CNAE Principal": "3811-4\/00",
     "Região": 28,
     "Situação": "Ativa",
     "Data Cadastro": "11\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1477,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MULTICOM ALUGUEL DE MAQUINAS E CONSTRUCOES EIRELI",
     "Nome Fantasia\/Apelido": "MULTICOM SERVICOS",
     "CNPJ": "10.173.171\/0001-76",
     "CNAE Principal": "4120-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "11\/03\/2023",
     "Estado": "BA",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1478,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DI CASA INDUSTRIA, COMERCIO E DISTRIBUIDORA DE MOVEIS LTDA",
     "Nome Fantasia\/Apelido": "DI CASA",
     "CNPJ": "24.005.267\/0001-30",
     "CNAE Principal": "4754-7\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "12\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1479,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DI CASA INDUSTRIA, COMERCIO E DISTRIBUIDORA DE MOVEIS LTDA",
     "Nome Fantasia\/Apelido": "DI CASA",
     "CNPJ": "24.005.267\/0002-10",
     "CNAE Principal": "4754-7\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "12\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1480,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SALMERON ENERGIA RENOVAVEL E PROTECAO AO CLIMA NORDESTE LTDA",
     "CNPJ": "43.359.008\/0002-32",
     "CNAE Principal": "16.29-3-01",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "13\/03\/2023",
     "Estado": "PE",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1483,
     "Tipo": "PJ",
     "Razão Social\/Nome": "VALDEMIR DE SANTANA ROLIM",
     "Nome Fantasia\/Apelido": "ELETROTEL",
     "CNPJ": " 08.035.655\/0001-07",
     "CNAE Principal": "6190-6\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "13\/03\/2023",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1484,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ANA PAULA SOUZA BARRIOS",
     "Nome Fantasia\/Apelido": "IMPERIO RACOES",
     "CNPJ": "42.094.824\/0001-18",
     "CNAE Principal": "4789-0\/04",
     "Região": 28,
     "Situação": "Ativa",
     "Data Cadastro": "13\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1485,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SALMERON ENERGIA RENOVAVEL E PROTECAO AO CLIMA SP LTDA;",
     "CNPJ": "46.022.197\/0002-41",
     "CNAE Principal": "35.30-1-00",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "13\/03\/2023",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1486,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LOEBENS E OLIVEIRA COMERCIO LTDA",
     "Nome Fantasia\/Apelido": "CHURRASCARIA E PIZZARIA RENASCER",
     "CNPJ": "21.869.950\/0001-27",
     "CNAE Principal": "5611-2\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "13\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1487,
     "Tipo": "PJ",
     "Razão Social\/Nome": "L&F AGRONEGOCIOS LTDA",
     "Nome Fantasia\/Apelido": "L&F AGRONEGOCIOS",
     "CNPJ": "43.199.183\/0001-29",
     "CNAE Principal": " 0161-0\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "13\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1489,
     "Tipo": "PJ",
     "Razão Social\/Nome": "Y K R TRANPORTE RODIVIARIO DE PASSAGEIROS LTDA",
     "Nome Fantasia\/Apelido": "TRANSCAP",
     "CNPJ": " 09.386.066\/0001-36",
     "CNAE Principal": "4921-3\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "13\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1490,
     "Tipo": "PJ",
     "Razão Social\/Nome": "C H CARVALHO OLIVEIRA",
     "Nome Fantasia\/Apelido": "LIDER DISTRIBUIDORA",
     "CNPJ": "39.394.381\/0001-76",
     "CNAE Principal": "5611-2\/05",
     "Região": 28,
     "Situação": "Ativa",
     "Data Cadastro": "13\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1491,
     "Tipo": "PJ",
     "Razão Social\/Nome": "GOURMET DELIVERY LTDA",
     "Nome Fantasia\/Apelido": "GOURMET REFEICOES DELIVERY",
     "CNPJ": "97.255.434\/0001-74",
     "CNAE Principal": "1096-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "13\/03\/2023",
     "Estado": "RS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1492,
     "Tipo": "PJ",
     "Razão Social\/Nome": "COOPERATIVA DE TRABALHO EDUCACIONAL DE FORMOSO DO ARAGUAIA - COOPEFA",
     "Nome Fantasia\/Apelido": "COOPEFA",
     "CNPJ": " 09.309.300\/0001-21",
     "CNAE Principal": "85.12-1-00",
     "Região": 28,
     "Situação": "Ativa",
     "Data Cadastro": "13\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1494,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ALBERTO A. RODRIGUES",
     "Nome Fantasia\/Apelido": "BETO COLHEITAS D PRECISAO",
     "CNPJ": "26.101.328\/0001-98",
     "CNAE Principal": " 0161-0\/03",
     "Região": 28,
     "Situação": "Ativa",
     "Data Cadastro": "14\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1495,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LEONARDO DE CASTRO",
     "Nome Fantasia\/Apelido": "DA VO MATERIAIS PARA CONSTRUCAO",
     "CNPJ": "10.985.238\/0001-77",
     "CNAE Principal": "4744-0\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "14\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1496,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MENESES E CAMPELO LTDA",
     "Nome Fantasia\/Apelido": "AUTO ELETRICA TOCANTINS",
     "CNPJ": "10.883.821\/0001-77",
     "CNAE Principal": "4530-7\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "14\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1497,
     "Tipo": "PJ",
     "Razão Social\/Nome": "GREENTEC MANUTENCAO INDUSTRIAL LTDA",
     "Nome Fantasia\/Apelido": "GREENTEC",
     "CNPJ": "15.842.805\/0001-50",
     "CNAE Principal": "8599-6\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "14\/03\/2023",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1498,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CLINICA E LABORATORIO ALIANCA LTDA",
     "Nome Fantasia\/Apelido": "CLINICA ALIANCA",
     "CNPJ": "39.806.424\/0001-83",
     "CNAE Principal": "8630-5\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "15\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1499,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SUSTENTA SERVICOS E CONSTRUCOES LTDA",
     "Nome Fantasia\/Apelido": "SUSTENTA SERVICOS E CONSTRUCOES",
     "CNPJ": "48.426.254\/0001-00",
     "CNAE Principal": "81.21-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "16\/03\/2023",
     "Estado": "MG",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1500,
     "Tipo": "PF",
     "Razão Social\/Nome": "KEILA ADELAYDE RITA ALVES DE ANDRADE ",
     "Nome Fantasia\/Apelido": "FAZENDA RECANTO ",
     "CPF": " 045.099.441-44",
     "CNAE Principal": 151201,
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "17\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1501,
     "Tipo": "PJ",
     "Razão Social\/Nome": "R.P.C. AGROPECUARIA E PARTICIPACOES LTDA",
     "CNPJ": "48.422.526\/0002-77",
     "CNAE Principal": " 01.13-0-00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "17\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1502,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MERCADO SUSIN EIRELI",
     "Nome Fantasia\/Apelido": "SUPERMECADO COLIBRI",
     "CNPJ": "28.352.727\/0001-57",
     "CNAE Principal": "4712-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "17\/03\/2023",
     "Estado": "PR",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1503,
     "Tipo": "PJ",
     "Razão Social\/Nome": "GADIOLI SOCIEDADE INDIVIDUAL DE ADVOCACIA",
     "CNPJ": "37.337.875\/0001-93",
     "CNAE Principal": "6911-7\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "18\/03\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1504,
     "Tipo": "PF",
     "Razão Social\/Nome": "FABIANA MORAES MIRANDA",
     "Nome Fantasia\/Apelido": "Fazenda Boa Esperança",
     "CPF": " 039.862.526-30",
     "CNAE Principal": 111301,
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "18\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1505,
     "Tipo": "PF",
     "Razão Social\/Nome": "FABIANA MORAES MIRANDA",
     "Nome Fantasia\/Apelido": "Fazenda Boa Esperança",
     "CPF": " 039.862.526-30",
     "CNAE Principal": 111301,
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "18\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1506,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CRISTIANE SILVA RIBEIRO 03960752156",
     "Nome Fantasia\/Apelido": "NETO MARTELINHO DE OURO",
     "CNPJ": "27.007.820\/0001-61",
     "CNAE Principal": "4520-0\/02",
     "Região": 28,
     "Situação": "Ativa",
     "Data Cadastro": "18\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1507,
     "Tipo": "PF",
     "Razão Social\/Nome": "LUCELIO GONCALVES RODRIGUES",
     "CPF": "883.345.031-72",
     "CNAE Principal": "151201 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "18\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1508,
     "Tipo": "PJ",
     "Razão Social\/Nome": "K.A.C.COUTINHO COMERCIO",
     "Nome Fantasia\/Apelido": "CASA DAS EMBALAGENS",
     "CNPJ": "17.739.664\/0001-70",
     "CNAE Principal": "4686-9\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "18\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1509,
     "Tipo": "PJ",
     "Razão Social\/Nome": "K.A.C.COUTINHO COMERCIO",
     "Nome Fantasia\/Apelido": "CASA DAS EMBALAGENS E FESTAS",
     "CNPJ": "17.739.664\/0002-50",
     "CNAE Principal": "4686-9\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "18\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1510,
     "Tipo": "PF",
     "Razão Social\/Nome": "GILBERTO FERNANDES CORMINEIRO",
     "Nome Fantasia\/Apelido": "FAZENDA GROTÃO",
     "CPF": " 094.828.811-68",
     "CNAE Principal": 151202,
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "18\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1511,
     "Tipo": "PF",
     "Razão Social\/Nome": "ERNANI ZAT",
     "Nome Fantasia\/Apelido": "RANCHO VELHO",
     "CPF": "999.214.211-15",
     "CNAE Principal": "151201 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "19\/03\/2023",
     "Estado": "GO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1512,
     "Tipo": "PJ",
     "Razão Social\/Nome": "G. DA SILVA LACERDA COMERCIO DE ROUPAS",
     "Nome Fantasia\/Apelido": "LOJA DEVASSA",
     "CNPJ": "14.841.246\/0001-09",
     "CNAE Principal": "4781-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "19\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1515,
     "Tipo": "PJ",
     "Razão Social\/Nome": "WAGNER FIGUEIRAS 06188584825",
     "Nome Fantasia\/Apelido": "AGROPET & PESCA BOER",
     "CNPJ": "31.621.582\/0001-57",
     "CNAE Principal": "4789-0\/04",
     "Região": 10,
     "Situação": "Ativa",
     "Data Cadastro": "19\/03\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1516,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CASA DO PVC - COMERCIO DE FORROS LTDA",
     "Nome Fantasia\/Apelido": "CASA DO PVC",
     "CNPJ": "48.955.220\/0001-03",
     "CNAE Principal": "47.44-0-99 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "19\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1517,
     "Tipo": "PJ",
     "Razão Social\/Nome": "D. L. CARDOSO DOS SANTOS",
     "Nome Fantasia\/Apelido": "AGRO CAMPO FORMOSO",
     "CNPJ": "30.132.628\/0001-01",
     "CNAE Principal": "4729-6\/99",
     "Região": 28,
     "Situação": "Ativa",
     "Data Cadastro": "19\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1518,
     "Tipo": "PF",
     "Razão Social\/Nome": "JOSÉ PAULO LIMA COSTA",
     "CPF": "538.333.921-34",
     "CNAE Principal": 151201,
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "19\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1519,
     "Tipo": "PJ",
     "Razão Social\/Nome": "E O JARDIM",
     "Nome Fantasia\/Apelido": "INJETORA TAILANDIA",
     "CNPJ": " 00.692.539\/0001-59",
     "CNAE Principal": "4744-0\/05",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "19\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1520,
     "Tipo": "PJ",
     "Razão Social\/Nome": "PROBOI COMERCIO ATACADISTA DE BOVINOS LTDA",
     "Nome Fantasia\/Apelido": "PROBOI",
     "CNPJ": "47.085.985\/0001-69",
     "CNAE Principal": "46.23-1-01 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "19\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1521,
     "Tipo": "PJ",
     "Razão Social\/Nome": "JOEL JOSE ALVES TRANSPORTES - EIRELI",
     "CNPJ": " 03.628.274\/0001-18",
     "CNAE Principal": "4921-3\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "19\/03\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1522,
     "Tipo": "PJ",
     "Razão Social\/Nome": "A. F. VIEIRA COMERCIO DE VARIEDADES",
     "Nome Fantasia\/Apelido": "GABY VARIEDADES",
     "CNPJ": "15.048.194\/0001-72",
     "CNAE Principal": "4713-0\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "19\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1523,
     "Tipo": "PJ",
     "Razão Social\/Nome": "G. L. DA SILVA MUSCULACAO E FITNESS EIRELI",
     "Nome Fantasia\/Apelido": "PERFORMANCE ACADEMIA",
     "CNPJ": "24.881.861\/0001-94",
     "CNAE Principal": "8599-6\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "19\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1524,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CAVIRE LTDA",
     "CNPJ": "47.378.769\/0002-92",
     "CNAE Principal": " 01.11-3-99 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "19\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1525,
     "Tipo": "PJ",
     "Razão Social\/Nome": "A. DOS SANTOS S.SIQUEIRA",
     "Nome Fantasia\/Apelido": "AMAROK CENTRO AUTO MOTIVO",
     "CNPJ": "22.424.871\/0001-75",
     "CNAE Principal": "4520-0\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "19\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1526,
     "Tipo": "PF",
     "Razão Social\/Nome": "SINTIA PINHEIRO GOMES TAVARES",
     "Nome Fantasia\/Apelido": "FAZENDA SITIO NOVO ",
     "CPF": "794.611.401-00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "19\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1527,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LELIA MAGNA SILVA DOS ANJOS PRADO LTDA",
     "Nome Fantasia\/Apelido": "CACAU SHOW CONTAINER",
     "CNPJ": "45.612.976\/0001-53",
     "CNAE Principal": "4721-1\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "19\/03\/2023",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1528,
     "Tipo": "PJ",
     "Razão Social\/Nome": "POWER LIGHT ILUMINACAO LTDA",
     "CNPJ": " 07.361.026\/0001-04",
     "CNAE Principal": "7739-0\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "20\/03\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1529,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LELIA MAGNA SILVA DOS ANJOS PRADO",
     "Nome Fantasia\/Apelido": "CACAU SHOW",
     "CNPJ": "18.707.044\/0001-11",
     "CNAE Principal": "4721-1\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "20\/03\/2023",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1530,
     "Tipo": "PJ",
     "Razão Social\/Nome": "FAM ENERGIA SOLAR E MANUTENCAO ELETRICA EIRELI",
     "Nome Fantasia\/Apelido": "FAM SOLAR",
     "CNPJ": "14.992.584\/0001-33",
     "CNAE Principal": "4754-7\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "20\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1531,
     "Tipo": "PJ",
     "Razão Social\/Nome": "JM SMART SERVICES LTDA",
     "Nome Fantasia\/Apelido": "JM SMART SERVICES",
     "CNPJ": "48.844.497\/0001-50",
     "CNAE Principal": "42.21-9-05",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "20\/03\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1532,
     "Tipo": "PJ",
     "Razão Social\/Nome": "J&C ENGENHARIA EIRELI",
     "Nome Fantasia\/Apelido": "J&C ENGENHARIA",
     "CNPJ": "32.264.908\/0001-07",
     "CNAE Principal": "7112-0\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "20\/03\/2023",
     "Estado": "SE",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1533,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DF SOLUCOES TECNOLOGICAS LTDA",
     "Nome Fantasia\/Apelido": "DF SOLUCOES TECNOLOGICAS",
     "CNPJ": "39.956.686\/0001-24",
     "CNAE Principal": "3313-9\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "20\/03\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1534,
     "Tipo": "PJ",
     "Razão Social\/Nome": "STAR INDUSTRIA DE CONFECCAO LTDA",
     "Nome Fantasia\/Apelido": "STAR INDUSTRIA DE CONFECCAO LTDA",
     "CNPJ": "19.275.719\/0001-63",
     "CNAE Principal": "1412-6\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "20\/03\/2023",
     "Estado": "SC",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1535,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SUN COMERCIO VAREJISTA DE VESTUARIO E ACESSORIOS LTDA",
     "Nome Fantasia\/Apelido": "SOMEDAY",
     "CNPJ": "45.504.728\/0001-99",
     "CNAE Principal": "4781-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "20\/03\/2023",
     "Estado": "SC",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1536,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SKY COMERCIO VAREJISTA DE VESTUARIO E ACESSORIOS LTDA.",
     "Nome Fantasia\/Apelido": "SOMEDAY",
     "CNPJ": "42.592.522\/0001-70",
     "CNAE Principal": "4781-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "20\/03\/2023",
     "Estado": "SC",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1537,
     "Tipo": "PJ",
     "Razão Social\/Nome": "L. ALVES COMERCIO VAREJISTA DE VESTUARIO E ACESSORIOS LTDA",
     "Nome Fantasia\/Apelido": "SOMEDAY",
     "CNPJ": "40.914.748\/0001-14",
     "CNAE Principal": "4781-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "20\/03\/2023",
     "Estado": "SC",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1539,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CAMARA DE DIRIGENTES LOJISTAS DE DOM ELISEU",
     "Nome Fantasia\/Apelido": "CDL - D ELISEU",
     "CNPJ": " 01.872.931\/0001-42",
     "CNAE Principal": "9430-8\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "21\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1540,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LAYSON MESSIAS DOS SANTOS 07401715937",
     "Nome Fantasia\/Apelido": "LMS - MANUTENCOES CURITIBA",
     "CNPJ": "23.418.511\/0001-23",
     "CNAE Principal": "4322-3\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "21\/03\/2023",
     "Estado": "PR",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1541,
     "Tipo": "PJ",
     "Razão Social\/Nome": "M. EVANGELISTA DE SOUZA",
     "Nome Fantasia\/Apelido": "LAVA JATO IPANEMA ALVORADA-TO",
     "CNPJ": "37.645.774\/0001-80",
     "CNAE Principal": "4520-0\/05",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "01\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1542,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DENIS DAVID SARAIVA NICOLOSO 02230788086",
     "Nome Fantasia\/Apelido": "DSN SISTEMAS DE SEGURANCA",
     "CNPJ": "27.108.795\/0001-02",
     "CNAE Principal": "4321-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "22\/03\/2023",
     "Estado": "RS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1543,
     "Tipo": "PJ",
     "Razão Social\/Nome": "J.L.S SERVICOS AGRICOLAS LTDA",
     "Nome Fantasia\/Apelido": "J.L.S SERVICOS AGRICOLAS",
     "CNPJ": "47.239.746\/0001-16",
     "CNAE Principal": " 01.63-6-00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "22\/03\/2023",
     "Estado": "MS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1544,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DIEGO ARTHUR CANDIDO DE SA FOTOGRAFIAS E FILMAGENS",
     "Nome Fantasia\/Apelido": "ARMAZEM IMAGENS",
     "CNPJ": "18.559.480\/0001-90",
     "CNAE Principal": "7420-0\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "22\/03\/2023",
     "Estado": "MG",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1545,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SUPERMERCADO NOVO LAR LTDA",
     "Nome Fantasia\/Apelido": "SUPERMERCADO NOVO LAR",
     "CNPJ": "10.633.699\/0001-80",
     "CNAE Principal": "4711-3\/02",
     "Região": 4,
     "Situação": "Ativa",
     "Data Cadastro": "22\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1546,
     "Tipo": "PJ",
     "Razão Social\/Nome": "W A BATISTA PANIFICADORA",
     "Nome Fantasia\/Apelido": "PANIFICADORA CAFE COM PAO",
     "CNPJ": "40.825.686\/0001-74",
     "CNAE Principal": "4721-1\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "22\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1547,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LRS ENGENHARIA E ENERGIA LTDA",
     "Nome Fantasia\/Apelido": "EVOLUZ ENGENHARIA & ENERGIA",
     "CNPJ": "44.231.409\/0001-94",
     "CNAE Principal": "4321-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "22\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1548,
     "Tipo": "PJ",
     "Razão Social\/Nome": "INSTITUTO MVA SERVICOS MEDICOS LTDA",
     "CNPJ": "45.478.012\/0001-64",
     "CNAE Principal": "8630-5\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "22\/03\/2023",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1549,
     "Tipo": "PJ",
     "Razão Social\/Nome": "JUNIOR SERVICE COMERCIO DE BEBEDOUROS LTDA",
     "CNPJ": "24.565.171\/0001-26",
     "CNAE Principal": "3314-7\/07",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "22\/03\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1550,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ANDRE BENICIO PEREIRA DE SOUZA 01638354111",
     "Nome Fantasia\/Apelido": "FORRADORA PVC ALVORADA",
     "CNPJ": "20.986.483\/0001-52",
     "CNAE Principal": "4744-0\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "23\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1551,
     "Tipo": "PF",
     "Razão Social\/Nome": "DANIELA SOUZA ZIMMERMANN",
     "CPF": " 070.202.178-42",
     "CNAE Principal": 151201,
     "Região": 28,
     "Situação": "Ativa",
     "Data Cadastro": "23\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1552,
     "Tipo": "PJ",
     "Razão Social\/Nome": "TATIANA SOARES VOTTO",
     "CNPJ": "22.783.324\/0002-67",
     "CNAE Principal": "6209-1\/00",
     "Região": 14,
     "Situação": "Ativa",
     "Data Cadastro": "23\/03\/2023",
     "Estado": "RS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1553,
     "Tipo": "PJ",
     "Razão Social\/Nome": "GAS IRACEMA LTDA",
     "CNPJ": "58.310.103\/0001-38",
     "CNAE Principal": "4784-9\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "23\/03\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1554,
     "Tipo": "PJ",
     "Razão Social\/Nome": "K L X - TECNOLOGIA LTDA",
     "Nome Fantasia\/Apelido": "AJUNET",
     "CNPJ": "10.624.750\/0001-98",
     "CNAE Principal": "6110-8\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "24\/03\/2023",
     "Estado": "SE",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1555,
     "Tipo": "PJ",
     "Razão Social\/Nome": "S ELTON PEREIRA DE SOUSA LTDA",
     "Nome Fantasia\/Apelido": "EMPREITEIRA SOUSA",
     "CNPJ": "50.027.096\/0001-50",
     "CNAE Principal": "41.20-4-00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "24\/03\/2023",
     "Estado": "GO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1556,
     "Tipo": "PF",
     "Razão Social\/Nome": "BENONE FELIX PESSOA NETO",
     "CPF": " 070.821.569-60",
     "CNAE Principal": "161003 ",
     "Região": 28,
     "Situação": "Ativa",
     "Data Cadastro": "25\/03\/2023",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1557,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SOLARIZE SERVICOS AGRICOLAS LTDA",
     "Nome Fantasia\/Apelido": "SOLARIZE",
     "CNPJ": "16.369.840\/0001-66",
     "CNAE Principal": " 0161-0\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "25\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1558,
     "Tipo": "PJ",
     "Razão Social\/Nome": "R. ALVES BEZERRA",
     "Nome Fantasia\/Apelido": "TRATOR REI",
     "CNPJ": "21.671.308\/0001-39",
     "CNAE Principal": "3314-7\/12",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "25\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1559,
     "Tipo": "PJ",
     "Razão Social\/Nome": "AGROTERRA NUTRICAO ANIMAL LTDA",
     "Nome Fantasia\/Apelido": "OUROFOS",
     "CNPJ": " 03.241.773\/0001-58",
     "CNAE Principal": "1066-0\/00",
     "Região": 28,
     "Situação": "Ativa",
     "Data Cadastro": "25\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1560,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SALMERON ENERGIA RENOVAVEL E PROTECAO AO CLIMA NORDESTE II LTDA",
     "Nome Fantasia\/Apelido": "SALMERON ENERGIA NORDESTE II",
     "CNPJ": "50.039.996\/0001-17",
     "CNAE Principal": "16.29-3\/01",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "27\/03\/2023",
     "Estado": "MA",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1561,
     "Tipo": "PJ",
     "Razão Social\/Nome": "GISLAINE MENDES DE OLIVEIRA ",
     "Nome Fantasia\/Apelido": "GISLAINE MENDES CABELOS E COSMETICOS",
     "CNPJ": "14.293.305\/0001-43",
     "CNAE Principal": "96.02-5-02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "27\/03\/2023",
     "Estado": "GO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1562,
     "Tipo": "PJ",
     "Razão Social\/Nome": "VANGUARD SECURITY ASSISTENCIA EM SEGURANCA LTDA.",
     "Nome Fantasia\/Apelido": "VANGUARD SECURITY",
     "CNPJ": " 07.754.224\/0001-38",
     "CNAE Principal": "8020-0\/01",
     "Região": 17,
     "Situação": "Ativa",
     "Data Cadastro": "27\/03\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1563,
     "Tipo": "PJ",
     "Razão Social\/Nome": "TECH CONTROLL COMERCIO E REPRESENTACOES LTDA",
     "Nome Fantasia\/Apelido": "TECH CONTROLL",
     "CNPJ": "24.797.869\/0001-77",
     "CNAE Principal": "81.22-2-00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "27\/03\/2023",
     "Estado": "GO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1564,
     "Tipo": "PF",
     "Razão Social\/Nome": "JORGE ELIAS CATARCIONE",
     "CPF": "269.048.967-87",
     "CNAE Principal": 8630504,
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "27\/03\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1565,
     "Tipo": "PF",
     "Razão Social\/Nome": "MAURICIO FONSECA FELIX DE SOUSA",
     "Nome Fantasia\/Apelido": "MAURICIO FONSECA FELIX DE SOUSA",
     "CPF": "951.183.731-15",
     "CNAE Principal": 115600,
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "27\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1566,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MIYAGAWA E MIYAGAWA LTDA",
     "Nome Fantasia\/Apelido": "POSTO MIYAGAWA",
     "CNPJ": " 04.108.088\/0001-10",
     "CNAE Principal": "4731-8\/00",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "28\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1567,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DROGARIA SANTO REMEDIO LTDA",
     "Nome Fantasia\/Apelido": "DROGARIA SANTO REMEDIO",
     "CNPJ": "32.706.105\/0001-57",
     "CNAE Principal": "4771-7\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "28\/03\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1569,
     "Tipo": "PJ",
     "Razão Social\/Nome": "AUTO PECAS DUVALE LTDA",
     "Nome Fantasia\/Apelido": "AUTO PECAS DUVALE",
     "CNPJ": "21.263.996\/0001-06",
     "CNAE Principal": "4530-7\/03",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "29\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1570,
     "Tipo": "PJ",
     "Razão Social\/Nome": "E. C. SAMPAIO COMERCIO DE PECAS E TRANSPORTES LTDA",
     "Nome Fantasia\/Apelido": "EDMAIS PECAS ACESSORIOS",
     "CNPJ": " 08.975.105\/0001-78",
     "CNAE Principal": "4541-2\/06",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "29\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1571,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ELETROMINAS COMERCIO DE MATERIAL ELETRICO LTDA",
     "Nome Fantasia\/Apelido": "ELETROMINAS",
     "CNPJ": "35.223.295\/0001-40",
     "CNAE Principal": "4742-3\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "29\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1573,
     "Tipo": "PJ",
     "Razão Social\/Nome": "JL AUTO CENTER LTDA",
     "Nome Fantasia\/Apelido": "J. L. AUTO CENTER",
     "CNPJ": "14.915.010\/0001-61",
     "CNAE Principal": "4530-7\/03",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "29\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1575,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MF LOCACOES DE MAQUINAS LTDA",
     "CNPJ": "45.075.547\/0001-94",
     "CNAE Principal": "7732-2\/01",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "29\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1576,
     "Tipo": "PJ",
     "Razão Social\/Nome": "PIA COMERCIO DE OVOS E FRIOS LTDA",
     "Nome Fantasia\/Apelido": "PIA OVOS E FRIOS",
     "CNPJ": "34.249.130\/0001-84",
     "CNAE Principal": "4712-1\/00",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "29\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1578,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SUPERMERCADO RADAR SUCESSO LTDA",
     "Nome Fantasia\/Apelido": "SUPERMERCADO RADAR SUCESSO",
     "CNPJ": "44.293.271\/0001-58",
     "CNAE Principal": "4712-1\/00",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "29\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1579,
     "Tipo": "PJ",
     "Razão Social\/Nome": "VIVEIRO MUDA FORTE LTDA",
     "Nome Fantasia\/Apelido": "VIVEIRO MUDA FORTE",
     "CNPJ": "21.932.536\/0001-15",
     "CNAE Principal": " 0142-3\/00",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "29\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1580,
     "Tipo": "PJ",
     "Razão Social\/Nome": "G. A. DE SOUZA TRANSPORTES",
     "Nome Fantasia\/Apelido": "GAS TRANSPORTES",
     "CNPJ": "17.332.360\/0001-93",
     "CNAE Principal": "4924-8\/00",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "29\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1581,
     "Tipo": "PF",
     "Razão Social\/Nome": "GERALDO CIRINO TAVARES",
     "CPF": " 082.854.891-91",
     "CNAE Principal": "151201 ",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "29\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1582,
     "Tipo": "PF",
     "Razão Social\/Nome": "AVELINO DONIZETI TONDIN",
     "Nome Fantasia\/Apelido": "FAZENDA VITORIA",
     "CPF": " 035.875.158-67",
     "CNAE Principal": "115600 ",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "29\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1583,
     "Tipo": "PF",
     "Razão Social\/Nome": "AVELINO DONIZETI TONDIN",
     "Nome Fantasia\/Apelido": "FAZ SAO JORGE",
     "CPF": " 035.875.158-67",
     "CNAE Principal": "115600 - ",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "29\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1584,
     "Tipo": "PF",
     "Razão Social\/Nome": "AVELINO DONIZETI TONDIN",
     "Nome Fantasia\/Apelido": "FAZ ESPERANÇA",
     "CPF": " 035.875.158-67",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "29\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1585,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LUIZA CAROLINA DA PAIXAO SANTOS VALCACIO 03366779527",
     "Nome Fantasia\/Apelido": "NDT TREINAMENTOS E SERVICOS DE INSPECOES",
     "CNPJ": "36.675.378\/0001-32",
     "CNAE Principal": "7739-0\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "29\/03\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1586,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ALACI C DE B PEREIRA SERVICOS",
     "Nome Fantasia\/Apelido": "BELAR INSTACOES E SERVICOS",
     "CNPJ": "34.671.009\/0001-46",
     "CNAE Principal": "43.22-3-02 -",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "29\/03\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1587,
     "Tipo": "PJ",
     "Razão Social\/Nome": "AILTON DA SILVA BRAGA JUNIOR 54121914600",
     "Nome Fantasia\/Apelido": "S&B ELETROMECANICA",
     "CNPJ": "15.473.870\/0001-55",
     "CNAE Principal": "3321-0\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "29\/03\/2023",
     "Estado": "MG",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1588,
     "Tipo": "PJ",
     "Razão Social\/Nome": "TECNOGAS EXPRESS LTDA",
     "Nome Fantasia\/Apelido": "TECNOGAS EXPRESS",
     "CNPJ": "21.423.540\/0001-58",
     "CNAE Principal": "9529-1\/99",
     "Região": 10,
     "Situação": "Ativa",
     "Data Cadastro": "30\/03\/2023",
     "Estado": "PR",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1589,
     "Tipo": "PJ",
     "Razão Social\/Nome": "IAN SEGURANCA ELETRONICA EIRELI",
     "Nome Fantasia\/Apelido": "IAN SEG",
     "CNPJ": "20.952.623\/0001-71",
     "CNAE Principal": "4321-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "30\/03\/2023",
     "Estado": "MG",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1590,
     "Tipo": "PJ",
     "Razão Social\/Nome": "FL MED DISTRIBUIDORA DE MEDICAMENTOS LTDA",
     "Nome Fantasia\/Apelido": "FL MED",
     "CNPJ": "42.537.261\/0001-95",
     "CNAE Principal": "4644-3\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "30\/03\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1591,
     "Tipo": "PJ",
     "Razão Social\/Nome": "AUGUSTO NAVARRO DIAS LTDA",
     "CNPJ": " 03.716.850\/0001-89",
     "CNAE Principal": "4921-3\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "30\/03\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1592,
     "Tipo": "PJ",
     "Razão Social\/Nome": "HIDROHOUSE AQUECEDORES LTDA",
     "CNPJ": "43.945.061\/0001-34",
     "CNAE Principal": "9521-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "30\/03\/2023",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1593,
     "Tipo": "PJ",
     "Razão Social\/Nome": "JANDERSON SILVA DE SOUSA 46172419811",
     "Nome Fantasia\/Apelido": "WTJ CLIMA FRIO",
     "CNPJ": "39.901.430\/0001-10",
     "CNAE Principal": "4322-3\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "31\/03\/2023",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1594,
     "Tipo": "PJ",
     "Razão Social\/Nome": "WEGG ENGINEERING CONSTRUCOES LTDA",
     "Nome Fantasia\/Apelido": "MARE3S",
     "CNPJ": "28.367.867\/0001-07",
     "CNAE Principal": "4120-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "03\/04\/2023",
     "Estado": "MS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1595,
     "Tipo": "PJ",
     "Razão Social\/Nome": "J S LOPES TRANSPORTES LTDA",
     "CNPJ": " 03.690.726\/0001-91",
     "CNAE Principal": "4921-3\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "03\/04\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1596,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SIRLEI DE LIMA",
     "Nome Fantasia\/Apelido": "MARMOARIA SAO CHICO",
     "CNPJ": "29.642.192\/0001-11",
     "CNAE Principal": "4330-4\/05",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "04\/04\/2023",
     "Estado": "RS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1597,
     "Tipo": "PJ",
     "Razão Social\/Nome": "E MAQUI LOCACOES E SERVICOS LTDA",
     "Nome Fantasia\/Apelido": "E MAQUI LOCACOES E SERVICOS",
     "CNPJ": "17.837.693\/0001-74",
     "CNAE Principal": "7732-2\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "04\/04\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1598,
     "Tipo": "PJ",
     "Razão Social\/Nome": "VFR PLANEJAMENTO EMPRESARIAL LTDA",
     "Nome Fantasia\/Apelido": "VFR CONSULTORIA EMPRESARIAL",
     "CNPJ": " 08.984.823\/0001-00",
     "CNAE Principal": "8211-3\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "06\/04\/2023",
     "Estado": "MT",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1599,
     "Tipo": "PJ",
     "Razão Social\/Nome": "49.709.927 NEOMAR SANTOS DE MATOS",
     "Nome Fantasia\/Apelido": "GP CONSTRUCOES",
     "CNPJ": "49.709.927\/0001-94",
     "CNAE Principal": "43.99-1-03 -",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "06\/04\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1600,
     "Tipo": "PJ",
     "Razão Social\/Nome": "R S VAZ TRANSPORTES EIRELI",
     "Nome Fantasia\/Apelido": "DUDA E ISA TRANSPORTES",
     "CNPJ": "37.914.392\/0001-04",
     "CNAE Principal": "4929-9\/02",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "10\/04\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1601,
     "Tipo": "PJ",
     "Razão Social\/Nome": "A & R SAUDE LTDA",
     "Nome Fantasia\/Apelido": "MEDICMAIS",
     "CNPJ": "40.113.207\/0001-97",
     "CNAE Principal": "8630-5\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "12\/04\/2023",
     "Estado": "SE",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1602,
     "Tipo": "PJ",
     "Razão Social\/Nome": "JM SANTOS CENTRO DE FORMACAO DE CONDUTORES LTDA",
     "Nome Fantasia\/Apelido": "AUTO ESCOLA JORGE",
     "CNPJ": "10.379.511\/0001-10",
     "CNAE Principal": "8599-6\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "12\/04\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1603,
     "Tipo": "PJ",
     "Razão Social\/Nome": "JD STANDS & DESING LTDA",
     "Nome Fantasia\/Apelido": "JD STANDS & DESING",
     "CNPJ": "43.068.684\/0001-76",
     "CNAE Principal": "8230-0\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "12\/04\/2023",
     "Estado": "CE",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1604,
     "Tipo": "PJ",
     "Razão Social\/Nome": "S L DE SENA COLCHOES LTDA",
     "Nome Fantasia\/Apelido": "SUPER SIL",
     "CNPJ": "49.461.103\/0001-48",
     "CNAE Principal": "47.54-7-02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "12\/04\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1605,
     "Tipo": "PJ",
     "Razão Social\/Nome": "PAULO A P JUNIOR ADEGA",
     "Nome Fantasia\/Apelido": "ARMAZEM IMPERIAL",
     "CNPJ": "44.892.184\/0001-17",
     "CNAE Principal": "4723-7\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "12\/04\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1606,
     "Tipo": "PJ",
     "Razão Social\/Nome": "JOSE GUIOMAR DE AGUIAR SIRQUEIRA 46341196172",
     "Nome Fantasia\/Apelido": "PETIT PEU MODA NFANTO JUVENIL E ACESSOR",
     "CNPJ": "36.444.913\/0001-44",
     "CNAE Principal": "4781-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "13\/04\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1607,
     "Tipo": "PJ",
     "Razão Social\/Nome": "APPI DERIVADOS DE PETROLEO LTDA",
     "Nome Fantasia\/Apelido": "POSTO APPI",
     "CNPJ": "31.994.765\/0001-18",
     "CNAE Principal": "4731-8\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "13\/04\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1608,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CONTEINERS LOCACOES LTDA",
     "Nome Fantasia\/Apelido": "CONTEINERS",
     "CNPJ": " 05.266.344\/0001-60",
     "CNAE Principal": "7739-0\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "14\/04\/2023",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1609,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MARIA IZABEL GOURMET LTDA",
     "Nome Fantasia\/Apelido": "MARIA IZABEL GOURMET",
     "CNPJ": "47.428.414\/0001-80",
     "CNAE Principal": "56.11-2-01 -",
     "Região": 4,
     "Situação": "Ativa",
     "Data Cadastro": "16\/04\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1610,
     "Tipo": "PJ",
     "Razão Social\/Nome": "JOAO MARCOS JACOBSON LTDA",
     "CNPJ": " 03.636.466\/0001-76",
     "CNAE Principal": "4921-3\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "17\/04\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1611,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SALMAZO & TOSTA LTDA",
     "Nome Fantasia\/Apelido": "SALMAZO & TOSTA ODONTOLOGIA",
     "CNPJ": "16.542.543\/0001-70",
     "CNAE Principal": "8630-5\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "18\/04\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1612,
     "Tipo": "PJ",
     "Razão Social\/Nome": "BRENDA PAULA SILVA",
     "Nome Fantasia\/Apelido": "VIVACE ODONTOLOGIA ESPECIALIZADA",
     "CNPJ": "26.917.508\/0001-42",
     "CNAE Principal": "8630-5\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "18\/04\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1613,
     "Tipo": "PJ",
     "Razão Social\/Nome": "VALDIR DOS SANTOS SILVA CALHAS",
     "Nome Fantasia\/Apelido": "CALHAS FENIX",
     "CNPJ": "24.166.727\/0001-01",
     "CNAE Principal": "4744-0\/05",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "20\/04\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1614,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SUMMER QUALIDADE EM SERVICOS LTDA",
     "Nome Fantasia\/Apelido": "SUMMER QUALIDADE EM SERVICOS",
     "CNPJ": " 07.291.335\/0001-55",
     "CNAE Principal": "5620-1\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "20\/04\/2023",
     "Estado": "MT",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1615,
     "Tipo": "PJ",
     "Razão Social\/Nome": "RONIVON DINALDO ALVES PEREIRA",
     "Nome Fantasia\/Apelido": "DROGARIA ALTO DA CRUZ",
     "CNPJ": " 01.845.535\/0001-26",
     "CNAE Principal": "4771-7\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "20\/04\/2023",
     "Estado": "MG",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1616,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DROGARIA SANTA ANA LTDA",
     "Nome Fantasia\/Apelido": "DROGARIA SANTA ANA",
     "CNPJ": " 06.000.893\/0001-51",
     "CNAE Principal": "4771-7\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "20\/04\/2023",
     "Estado": "MG",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1617,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CONVENIENCIA AVENIDA LTDA",
     "Nome Fantasia\/Apelido": "AVENIDA CONVENIENCIA",
     "CNPJ": "38.488.716\/0001-52",
     "CNAE Principal": "4729-6\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "22\/04\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1618,
     "Tipo": "PJ",
     "Razão Social\/Nome": "AVENIDA AUTO POSTO COMERCIAL DE COMBUSTIVEIS LTDA",
     "Nome Fantasia\/Apelido": "AVENIDA AUTO POSTO",
     "CNPJ": "31.631.878\/0001-59",
     "CNAE Principal": "4731-8\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "22\/04\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1619,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ELIZABETE DE O BRITO PIRES  LTDA",
     "Nome Fantasia\/Apelido": "LANCHONETE 3 IRMAOS",
     "CNPJ": "45.152.193\/0001-34",
     "CNAE Principal": "5611-2\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "04\/04\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1620,
     "Tipo": "PF",
     "Razão Social\/Nome": "IVONETE DIAS PINHEIRO",
     "CPF": "247.407.561-53",
     "CNAE Principal": "151201 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "24\/04\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1621,
     "Tipo": "PJ",
     "Razão Social\/Nome": "WILLIAN LEANDRO GOMES DA SILVA 44024292854",
     "CNPJ": "46.605.210\/0001-04",
     "CNAE Principal": "47.44-0-99 ",
     "Região": 7,
     "Situação": "Ativa",
     "Data Cadastro": "25\/04\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1622,
     "Tipo": "PJ",
     "Razão Social\/Nome": "POSTO DE MOLAS PARA LTDA",
     "Nome Fantasia\/Apelido": "POSTO DE MOLAS PARA",
     "CNPJ": "44.285.141\/0001-73",
     "CNAE Principal": "4530-7\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "25\/04\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1623,
     "Tipo": "PJ",
     "Razão Social\/Nome": "EDSON DE BRITO BANDEIRA",
     "Nome Fantasia\/Apelido": "CABECOTES GLOBO",
     "CNPJ": "17.708.357\/0001-21",
     "CNAE Principal": "4520-0\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "25\/04\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1624,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MILTON B CAVALCANTE",
     "Nome Fantasia\/Apelido": "M.B.C.TRANSPORTES E SERVICOS",
     "CNPJ": "35.549.148\/0001-64",
     "CNAE Principal": "4930-2\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "26\/04\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1625,
     "Tipo": "PF",
     "Razão Social\/Nome": "ARNALDO PEIXOTO",
     "CPF": " 097.744.161-04",
     "CNAE Principal": "151201 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "26\/04\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1626,
     "Tipo": "PF",
     "Razão Social\/Nome": "JOÃO DA CRUZ",
     "Nome Fantasia\/Apelido": "FAZENDA J3R",
     "CPF": "100.346.921-34",
     "CNAE Principal": "151201 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "26\/04\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1627,
     "Tipo": "PF",
     "Razão Social\/Nome": "SADIR SCHMID",
     "CPF": "431.287.779-34",
     "CNAE Principal": 151201,
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "26\/04\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1628,
     "Tipo": "PJ",
     "Razão Social\/Nome": "O TRADICIONAL RESTAURANTE LTDA",
     "CNPJ": "73.718.637\/0001-91",
     "CNAE Principal": "5611-2\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "26\/04\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1629,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SABIDU EDUCACAO INFANTIL LTDA",
     "Nome Fantasia\/Apelido": "ESCOLA SABIDO",
     "CNPJ": "22.498.493\/0001-74",
     "CNAE Principal": "8512-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "26\/04\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1630,
     "Tipo": "PJ",
     "Razão Social\/Nome": "J. ABREU DOS SANTOS COMERCIO DE ADUBO E FERTILIZANTES LTDA",
     "CNPJ": "19.527.105\/0001-21",
     "CNAE Principal": "4683-4\/00",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "27\/04\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1631,
     "Tipo": "PF",
     "Razão Social\/Nome": "JOLITON ABREU DOS SANTOS",
     "CPF": "743.258.332-34",
     "CNAE Principal": 161099,
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "27\/04\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1632,
     "Tipo": "PF",
     "Razão Social\/Nome": "FRANCISCO CARLOS PEREIRA RIZZUTO",
     "Nome Fantasia\/Apelido": "Faz Martinez",
     "CPF": "388.195.848-75",
     "CNAE Principal": "115600 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "27\/04\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1633,
     "Tipo": "PJ",
     "Razão Social\/Nome": "GEOFORTS ENGENHARIA LTDA",
     "Nome Fantasia\/Apelido": "GEOFORTS ENHENHARIA",
     "CNPJ": "40.825.631\/0001-64",
     "CNAE Principal": "7112-0\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "27\/04\/2023",
     "Estado": "MT",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1634,
     "Tipo": "PJ",
     "Razão Social\/Nome": "RAMOS & COSTA LTDA",
     "Nome Fantasia\/Apelido": "CENTRO AUTOMOTIVO MODELO",
     "CNPJ": "18.093.323\/0001-32",
     "CNAE Principal": "4520-0\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "27\/04\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1635,
     "Tipo": "PJ",
     "Razão Social\/Nome": "FARMAVIDA GUARAI LTDA",
     "Nome Fantasia\/Apelido": "FARMAVIDA",
     "CNPJ": "15.626.267\/0001-66",
     "CNAE Principal": "4771-7\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "27\/04\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1636,
     "Tipo": "PJ",
     "Razão Social\/Nome": "EDGAR TAFAREL ARAUJO 39201020880",
     "CNPJ": "33.039.370\/0001-91",
     "CNAE Principal": "3314-7\/07",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "27\/04\/2023",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1637,
     "Tipo": "PF",
     "Razão Social\/Nome": "VITOR NASCIMENTO VALADÃO E OUTROS",
     "Nome Fantasia\/Apelido": "VITOR NASCIMENTO VALADÃO E OUTROS",
     "CPF": "894.184.681-15",
     "CNAE Principal": 111301,
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "02\/05\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1638,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DE MOURA SOLUCOES EM LOGISTICA E TRANSPORTE LTDA",
     "Nome Fantasia\/Apelido": "DE MOURA TRANSPORTE",
     "CNPJ": "50.264.663\/0001-91",
     "CNAE Principal": "52.50-8-05",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "02\/05\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1639,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CLAUDOMIRO ANTONIO MARIANO",
     "CNPJ": "19.668.239\/0001-62",
     "CNAE Principal": "4530-7\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "03\/05\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1640,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SAULO AUGUSTO MOREIRA LTDA",
     "Nome Fantasia\/Apelido": "AS2 AUTOMACAO E SISTEMAS",
     "CNPJ": "44.998.619\/0001-02",
     "CNAE Principal": "71.12-0-00 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "03\/05\/2023",
     "Estado": "PR",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1641,
     "Tipo": "PJ",
     "Razão Social\/Nome": "PRIVAG SERVICOS DE VIGILANCIA PATRIMONIAL LTDA",
     "CNPJ": " 08.504.727\/0001-18",
     "CNAE Principal": "8011-1\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "03\/05\/2023",
     "Estado": "BA",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1642,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DOMINGOS ALEXANDRE DO NASCIMENTO BISNETO",
     "Nome Fantasia\/Apelido": "ITABUNA MOTORES RETIFICA",
     "CNPJ": " 02.873.475\/0001-18",
     "CNAE Principal": "2950-6\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "03\/05\/2023",
     "Estado": "BA",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1643,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ADEMIR APARECIDO CAMILLI",
     "Nome Fantasia\/Apelido": "SANTA CLARA AGROPECUARIA E SERVICOS AGRICOLAS",
     "CNPJ": "44.759.013\/0001-14",
     "CNAE Principal": " 0161-0\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "03\/05\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1644,
     "Tipo": "PJ",
     "Razão Social\/Nome": "JOAB BRITO NUNES SERVICOS TECNICOS ELETRICOS LTDA",
     "Nome Fantasia\/Apelido": "NCOM TECNICA",
     "CNPJ": "34.834.348\/0001-04",
     "CNAE Principal": "4321-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "05\/05\/2023",
     "Estado": "PB",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1645,
     "Tipo": "PJ",
     "Razão Social\/Nome": "TELECOM PONTONET LTDA",
     "Nome Fantasia\/Apelido": "PONTONET",
     "CNPJ": "13.185.367\/0001-79",
     "CNAE Principal": "6190-6\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "05\/05\/2023",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1646,
     "Tipo": "PJ",
     "Razão Social\/Nome": "QUANTA CONSULTORIA, PROJETOS E EDITORA LTDA",
     "Nome Fantasia\/Apelido": "QCP",
     "CNPJ": " 05.775.513\/0001-98",
     "CNAE Principal": "6201-5\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "05\/05\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1647,
     "Tipo": "PJ",
     "Razão Social\/Nome": "GERSON ARES",
     "Nome Fantasia\/Apelido": "ALTO PADRAO SOLUCOES ELETRICAS",
     "CNPJ": "22.953.071\/0001-41",
     "CNAE Principal": "4742-3\/00",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "05\/05\/2023",
     "Estado": "SC",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1648,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LEOFE CONFECCOES LTDA",
     "Nome Fantasia\/Apelido": "LEOFE CONFECCOES",
     "CNPJ": "48.844.684\/0001-34",
     "CNAE Principal": "14.12-6-01 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "06\/05\/2023",
     "Estado": "SC",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1649,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LANCA ENGENHARIA E CONSULTORIA EIRELI",
     "Nome Fantasia\/Apelido": "LANCA ENGENHARIA",
     "CNPJ": "39.456.431\/0001-00",
     "CNAE Principal": "7112-0\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "08\/05\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1650,
     "Tipo": "PF",
     "Razão Social\/Nome": "LUCIANA ALVES MIELE",
     "CPF": " 003.930.731-06",
     "CNAE Principal": "4299599 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "08\/05\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1651,
     "Tipo": "PJ",
     "Razão Social\/Nome": "GISELLE CALLADO SEMIJOIAS LTDA",
     "Nome Fantasia\/Apelido": "CALLADO JOIAS",
     "CNPJ": "40.567.301\/0001-16",
     "CNAE Principal": "4781-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "08\/05\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1652,
     "Tipo": "PJ",
     "Razão Social\/Nome": "HOLZ E SILVA LTDA",
     "Nome Fantasia\/Apelido": "JALAPAO POCOS ARTESIANOS",
     "CNPJ": "45.933.102\/0001-06",
     "CNAE Principal": "4399-1\/05",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "08\/05\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1653,
     "Tipo": "PJ",
     "Razão Social\/Nome": "BW TECNOLOGIA EM MANUTENCAO LTDA",
     "Nome Fantasia\/Apelido": "BW TECNOLOGIA EM MANUTENCAO",
     "CNPJ": "15.083.236\/0001-06",
     "CNAE Principal": "7020-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "08\/05\/2023",
     "Estado": "GO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1654,
     "Tipo": "PJ",
     "Razão Social\/Nome": "GZK PLANEJAMENTO E SERVICOS LTDA",
     "Nome Fantasia\/Apelido": "GZK PLANEJAMENTO E SERVICOS",
     "CNPJ": "30.041.139\/0001-44",
     "CNAE Principal": "8211-3\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "09\/05\/2023",
     "Estado": "ES",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1655,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SERVICO DE SANEAMENTO AMBIENTAL DE MARABA",
     "Nome Fantasia\/Apelido": "AMBIENTAL SANEAMENTO",
     "CNPJ": " 05.555.362\/0001-62",
     "CNAE Principal": "8411-6\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "09\/05\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1656,
     "Tipo": "PF",
     "Razão Social\/Nome": " BRUNO JOSÉ FURLAN ",
     "Nome Fantasia\/Apelido": "SITIO 22",
     "CPF": "353.539.118-06",
     "CNAE Principal": " 0151201",
     "Região": 7,
     "Situação": "Ativa",
     "Data Cadastro": "10\/05\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1657,
     "Tipo": "PJ",
     "Razão Social\/Nome": "STRAIN GAGE ASSESSORIA EM EXTENSOMETRIA LTDA.",
     "CNPJ": "61.777.439\/0001-10",
     "CNAE Principal": "8299-7\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "10\/05\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1658,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ADEMILSON MARTINS TRANSPORTES LTDA",
     "CNPJ": " 03.626.366\/0001-69",
     "CNAE Principal": "4921-3\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "10\/05\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1659,
     "Tipo": "PJ",
     "Razão Social\/Nome": "RPB ENGENHARIA E CONSULTORIA EIRELI",
     "CNPJ": "27.694.331\/0001-25",
     "CNAE Principal": "7120-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "10\/05\/2023",
     "Estado": "MG",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1660,
     "Tipo": "PJ",
     "Razão Social\/Nome": "JOSE ROBERTO ALBUQUERQUE RODRIGUES DA SILVA LTDA",
     "CNPJ": " 03.580.062\/0001-08",
     "CNAE Principal": "4921-3\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "10\/05\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1661,
     "Tipo": "PJ",
     "Razão Social\/Nome": "GABRIEL LUIZ DE MOURA OLIVEIRA 01472619617",
     "Nome Fantasia\/Apelido": "DIMOURA CONSULTORIA E SERVICOS",
     "CNPJ": "30.345.779\/0001-48",
     "CNAE Principal": "4329-1\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "10\/05\/2023",
     "Estado": "MG",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1662,
     "Tipo": "PJ",
     "Razão Social\/Nome": "BENEDITO TRISTAO TRANSPORTES LTDA",
     "CNPJ": " 03.485.362\/0001-08",
     "CNAE Principal": "4921-3\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "11\/05\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1664,
     "Tipo": "PF",
     "Razão Social\/Nome": "ELINALDO DE ALBUQUERQUE VASCONCELOS",
     "Nome Fantasia\/Apelido": "FAZENDA SAO SEBASTIAO",
     "CPF": "491.446.004-10",
     "CNAE Principal": "151201 ",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "11\/05\/2023",
     "Estado": "PB",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1665,
     "Tipo": "PJ",
     "Razão Social\/Nome": "EXATA SERVICOS DE CONSULTORIA E ASSESSORIA EMPRESARIAL LTDA",
     "Nome Fantasia\/Apelido": "EXATA CONTABIL",
     "CNPJ": "31.486.799\/0001-00",
     "CNAE Principal": "6920-6\/02",
     "Região": 4,
     "Situação": "Ativa",
     "Data Cadastro": "12\/05\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1666,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ATELIER DE ROTEIROS AGENCIA DE VIAGENS E TURISMO LTDA",
     "CNPJ": "21.735.038\/0001-82",
     "CNAE Principal": "7911-2\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "12\/05\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1667,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CEADI CENTRO DE ESTUDOS E APOIO AO DESENVOLVIMENTO INTEGRADO EM FONOAUDIOLOGIA E PSICOPEDAGOGIA - SANTA TEREZINHA LTDA",
     "CNPJ": " 04.153.779\/0001-36",
     "CNAE Principal": "8650-0\/06",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "12\/05\/2023",
     "Estado": "PR",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1668,
     "Tipo": "PF",
     "Razão Social\/Nome": "WICTOR HUGO OLIVEIRA LELES ",
     "Nome Fantasia\/Apelido": "FAZENDA MASSARANDUBA ",
     "CPF": "110.156.746-58",
     "CNAE Principal": 151201,
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "12\/05\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1669,
     "Tipo": "PJ",
     "Razão Social\/Nome": "AGROFARM - PRODUTOS AGROQUIMICOS LTDA",
     "Nome Fantasia\/Apelido": "AGROFARM ( Açailândia)",
     "CNPJ": " 05.787.644\/0013-29",
     "CNAE Principal": " 0163-6\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "15\/05\/2023",
     "Estado": "MA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1670,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SINDICATO RURAL DE FORMOSO DO ARAGUAIA",
     "CNPJ": " 02.065.530\/0001-43",
     "CNAE Principal": "9420-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "15\/05\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1671,
     "Tipo": "PJ",
     "Razão Social\/Nome": "RESTAURANTE E RANCHO DO GRID LTDA",
     "Nome Fantasia\/Apelido": "RANCHO DO GRID",
     "CNPJ": "48.899.088\/0001-51",
     "CNAE Principal": "56.11-2-01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "15\/05\/2023",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1672,
     "Tipo": "PJ",
     "Razão Social\/Nome": "OZELPHARM FARMACIA DE MANIPULACAO LTDA",
     "CNPJ": " 02.445.968\/0001-57",
     "CNAE Principal": "47.71-7-02 ",
     "Região": 10,
     "Situação": "Ativa",
     "Data Cadastro": "16\/05\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1673,
     "Tipo": "PJ",
     "Razão Social\/Nome": "OK COMERCIO DE MEDICAMENTOS LTDA",
     "Nome Fantasia\/Apelido": "REDE DROGA MARY",
     "CNPJ": "13.335.649\/0001-05",
     "CNAE Principal": "4771-7\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "16\/05\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1674,
     "Tipo": "PF",
     "Razão Social\/Nome": "JORCEMAR DE JESUS SILVA",
     "Nome Fantasia\/Apelido": "FAZENDA SETE COPAS",
     "CPF": "839.476.201-87",
     "CNAE Principal": " 01.51-20",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "17\/05\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1675,
     "Tipo": "PF",
     "Razão Social\/Nome": "THIAGO ALBACETE CASTRO",
     "CPF": "226.981.028-70",
     "CNAE Principal": "8630504 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "18\/05\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1676,
     "Tipo": "PJ",
     "Razão Social\/Nome": "NM AUTO PECAS LTDA",
     "Nome Fantasia\/Apelido": "NM AUTO PECAS",
     "CNPJ": "13.446.877\/0002-33",
     "CNAE Principal": "4530-7\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "18\/05\/2023",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1677,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CE MA LU COMERCIO DE MEDICAMENTOS LTDA",
     "Nome Fantasia\/Apelido": "REDE DROGA MARY",
     "CNPJ": "14.992.529\/0001-43",
     "CNAE Principal": "4771-7\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "19\/05\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1678,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CAPUAVA COMERCIO DE RESIDUOS, RECICLAGEM, LOCACOES E SERVICOS LTDA",
     "CNPJ": " 09.532.406\/0002-70",
     "CNAE Principal": "4671-1\/00",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "19\/05\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1680,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SCHMIDT ENGENHARIA E SEGURANCA DO TRABALHO LTDA",
     "Nome Fantasia\/Apelido": "SCHMIDT ENGENHARIA ",
     "CNPJ": "47.296.190\/0001-08",
     "CNAE Principal": "41.20-4-00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "19\/05\/2023",
     "Estado": "RS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1681,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SALMERON ENERGIA RENOVAVEL E PROTECAO AO CLIMA MG LTDA",
     "CNPJ": "43.167.240\/0001-98",
     "CNAE Principal": "4671-1\/00",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "19\/05\/2023",
     "Estado": "MG",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1682,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CANCUN CAFE LTDA",
     "CNPJ": "47.210.872\/0001-48",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "19\/05\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1683,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SAT MASTER TELECOMUNICACOES E SEGURANCA LTDA",
     "CNPJ": "36.291.182\/0001-44",
     "CNAE Principal": "6190-6\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "19\/05\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1684,
     "Tipo": "PJ",
     "Razão Social\/Nome": "49.251.828 ELIMAR BUBLITZ",
     "Nome Fantasia\/Apelido": "ELIMAR BUBLITZ",
     "CNPJ": "49.251.828\/0001-01",
     "CNAE Principal": "33.11-2-00 ",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "21\/05\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1685,
     "Tipo": "PJ",
     "Razão Social\/Nome": "GERSON PEREZ LOPES 10601977823",
     "CNPJ": "39.470.300\/0001-70",
     "CNAE Principal": "3311-2\/00",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "21\/05\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1686,
     "Tipo": "PJ",
     "Razão Social\/Nome": "K.S. ESTRUTURAS METALICAS SOROCABA LTDA",
     "CNPJ": "30.227.352\/0001-45",
     "CNAE Principal": "4330-4\/04",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "21\/05\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1687,
     "Tipo": "PJ",
     "Razão Social\/Nome": "RICARDO MOREIRA DE CASTILHO 28418889837",
     "CNPJ": "45.893.125\/0001-26",
     "CNAE Principal": "3311-2\/00",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "22\/05\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1688,
     "Tipo": "PJ",
     "Razão Social\/Nome": "RV ENGENHARIA LTDA",
     "Nome Fantasia\/Apelido": "RV ENGENHARIA",
     "CNPJ": "26.095.426\/0001-60",
     "CNAE Principal": "4330-4\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "22\/05\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1689,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MENDES LIMA PRODUTOS FARMACEUTICOS LTDA",
     "Nome Fantasia\/Apelido": "REDE DROGA MARY",
     "CNPJ": "23.004.333\/0001-94",
     "CNAE Principal": "4771-7\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "22\/05\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1690,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CIM COMERCIAL LTDA",
     "Nome Fantasia\/Apelido": "KIMAGEM",
     "CNPJ": " 02.915.850\/0001-45",
     "CNAE Principal": "4761-0\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "23\/05\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1691,
     "Tipo": "PF",
     "Razão Social\/Nome": "IVALDO BARBOSA JORGE",
     "Nome Fantasia\/Apelido": "FAZENDA SANTO ANTÔNIO ",
     "CPF": "588.891.081-34",
     "CNAE Principal": "161099 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "23\/05\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1692,
     "Tipo": "PF",
     "Razão Social\/Nome": "DANIEL ANGELOZZI ",
     "Nome Fantasia\/Apelido": "ESTANCIA CANAÃ",
     "CPF": "725.825.108-15",
     "CNAE Principal": " 0151201 ",
     "Região": 7,
     "Situação": "Ativa",
     "Data Cadastro": "23\/05\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1693,
     "Tipo": "PJ",
     "Razão Social\/Nome": "R A DO N PEREIRA",
     "Nome Fantasia\/Apelido": "MARMORARIA GOIAS",
     "CNPJ": " 09.528.796\/0001-24",
     "CNAE Principal": "4744-0\/06",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "23\/05\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1694,
     "Tipo": "PF",
     "Razão Social\/Nome": "MIRIAM HELENA PICCOLOTTO GALIB E OUTROS ",
     "Nome Fantasia\/Apelido": "SITIO BELA VISTA",
     "CPF": " 025.117.888-96",
     "CNAE Principal": " 0151201 ",
     "Região": 7,
     "Situação": "Ativa",
     "Data Cadastro": "23\/05\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1695,
     "Tipo": "PF",
     "Razão Social\/Nome": "MIRIAM HELENA PICCOLOTTO GALIB E OUTROS ",
     "Nome Fantasia\/Apelido": "FAZENDA REPRESA II",
     "CPF": " 025.117.888-96",
     "CNAE Principal": "151201 ",
     "Região": 7,
     "Situação": "Ativa",
     "Data Cadastro": "23\/05\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1696,
     "Tipo": "PF",
     "Razão Social\/Nome": "MIRIAM HELENA PICCOLOTTO GALIB E OUTROS ",
     "Nome Fantasia\/Apelido": "FAZENDA REPRESA I",
     "CPF": " 025.117.888-96",
     "CNAE Principal": 151201,
     "Região": 7,
     "Situação": "Ativa",
     "Data Cadastro": "23\/05\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1697,
     "Tipo": "PJ",
     "Razão Social\/Nome": "H A S QUEIROZ EIRELI",
     "Nome Fantasia\/Apelido": "QUEIROZ HORTIFRUTI",
     "CNPJ": "35.050.724\/0001-24",
     "CNAE Principal": "4724-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "24\/05\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1698,
     "Tipo": "PJ",
     "Razão Social\/Nome": "FARMACIA SANTA RITA DE PRESIDENTE EPITACIO LTDA",
     "Nome Fantasia\/Apelido": "FARMAIS",
     "CNPJ": "72.855.430\/0001-04",
     "CNAE Principal": "4771-7\/02",
     "Região": 7,
     "Situação": "Ativa",
     "Data Cadastro": "24\/05\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1699,
     "Tipo": "PF",
     "Razão Social\/Nome": "DEUSDETH COSTA PARRIAO",
     "Nome Fantasia\/Apelido": " FAZENDA BELA VISTA",
     "CPF": "457.152.461-72",
     "CNAE Principal": "151201 ",
     "Região": 4,
     "Situação": "Ativa",
     "Data Cadastro": "24\/05\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1700,
     "Tipo": "PJ",
     "Razão Social\/Nome": "KARINA DE FREITAS SILVA MARIANO LTDA",
     "Nome Fantasia\/Apelido": "MIRO TRANSMISSOES",
     "CNPJ": "23.476.863\/0001-35",
     "CNAE Principal": "4530-7\/03",
     "Região": 10,
     "Situação": "Ativa",
     "Data Cadastro": "24\/05\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1701,
     "Tipo": "PJ",
     "Razão Social\/Nome": "37.262.358 SANDRO SIMOES SANTOS",
     "Nome Fantasia\/Apelido": "DISK ENTULHO 3S",
     "CNPJ": "37.262.358\/0001-00",
     "CNAE Principal": "3811-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "25\/05\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1702,
     "Tipo": "PJ",
     "Razão Social\/Nome": "L. DE S. FERREIRA",
     "Nome Fantasia\/Apelido": "FOCUS - GESTAO & TREINAMENTOS EM SEGURANCA DO TRABALHO",
     "CNPJ": "29.558.727\/0001-70",
     "CNAE Principal": "8299-7\/99",
     "Região": 19,
     "Situação": "Ativa",
     "Data Cadastro": "25\/05\/2023",
     "Estado": "TO",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1703,
     "Tipo": "PJ",
     "Razão Social\/Nome": "EMPRESA TESTE",
     "Nome Fantasia\/Apelido": "FOCUS - GESTAO & TREINAMENTOS EM SEGURANCA DO TRABALHO",
     "CNPJ": "29.558.727\/0001-70",
     "CNAE Principal": "8299-7\/99",
     "Região": 19,
     "Situação": "Ativa",
     "Data Cadastro": "25\/05\/2023",
     "Estado": "TO",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1704,
     "Tipo": "PF",
     "Razão Social\/Nome": "RENAN NASCIMENTO VALADÃO (LAGOA DA CONFUSÃO)",
     "Nome Fantasia\/Apelido": "LAGOA DA CONFUSÃO",
     "CPF": "708.506.741-15",
     "CNAE Principal": 111301,
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "25\/05\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1705,
     "Tipo": "PF",
     "Razão Social\/Nome": "SILVIA PINHEIRO GOMES",
     "Nome Fantasia\/Apelido": "FAZENDA MARANATA",
     "CPF": " 029.969.711-83",
     "CNAE Principal": "151201 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "26\/05\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1706,
     "Tipo": "PF",
     "Razão Social\/Nome": "RICARDO TEIXEIRA FELIPE",
     "Nome Fantasia\/Apelido": "FAZENDA RENASCER II",
     "CPF": "287.752.951-72",
     "CNAE Principal": " 0151-2\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "26\/05\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1707,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CML COMERCIO DE COMBUSTIVEIS LTDA",
     "Nome Fantasia\/Apelido": "AUTO POSTO CEMAR",
     "CNPJ": "17.222.418\/0001-46",
     "CNAE Principal": "4731-8\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "26\/05\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1708,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ONEL OUTSOURCING APOIO ADMINISTRATIVO LTDA",
     "Nome Fantasia\/Apelido": "ONEL",
     "CNPJ": "11.939.225\/0001-24",
     "CNAE Principal": "8219-9\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "26\/05\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1709,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DONIZETE APARECIDO DE FREITAS TRANSPORTES LTDA",
     "CNPJ": " 03.725.289\/0001-02",
     "CNAE Principal": "4921-3\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "03\/05\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1710,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CENTRAL SHOPING COMERCIO DE EQUIPAMENTOS ELETRONICOS E DOMESTICOS LTDA",
     "Nome Fantasia\/Apelido": "CENTRAL SHOPPING ELETRONICOS",
     "CNPJ": "40.369.113\/0001-83",
     "CNAE Principal": "4757-1\/00",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "26\/05\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1711,
     "Tipo": "PJ",
     "Razão Social\/Nome": "FELIPE RODRIGUES DA SILVEIRA 14179127741",
     "Nome Fantasia\/Apelido": "ELECTROHIDRO",
     "CNPJ": "37.521.277\/0001-70",
     "CNAE Principal": "4321-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "29\/05\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1713,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MAURO MELLO DO NASCIMENTO LTDA",
     "CNPJ": " 03.603.208\/0001-93",
     "CNAE Principal": "4921-3\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "29\/05\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1714,
     "Tipo": "PJ",
     "Razão Social\/Nome": "HIDRAUTIG MAQUINAS E IMPLEMENTOS LTDA",
     "Nome Fantasia\/Apelido": "HIDRAUTIG",
     "CNPJ": "46.319.152\/0001-52",
     "CNAE Principal": "4520-0\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "30\/05\/2023",
     "Estado": "PE",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1716,
     "Tipo": "PF",
     "Razão Social\/Nome": "MARCELO DE SOUSA JARDIM E OUTROS",
     "CPF": "597.537.651-34",
     "Região": 19,
     "Situação": "Ativa",
     "Data Cadastro": "30\/05\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1717,
     "Tipo": "PJ",
     "Razão Social\/Nome": "VIELE COMERCIO DE MATERIAL OFFSHORE E SERVICOS DE LOCACAO DE EQUIPAMENTOS LTDA",
     "Nome Fantasia\/Apelido": "ALL SUPPLIES COMERCIO OFFSHORE",
     "CNPJ": "24.002.186\/0001-86",
     "CNAE Principal": "4744-0\/03",
     "Região": 17,
     "Situação": "Ativa",
     "Data Cadastro": "30\/05\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1718,
     "Tipo": "PF",
     "Razão Social\/Nome": "JOSE RANGEL DA SILVA",
     "Nome Fantasia\/Apelido": "SITIO MAIKEL",
     "CPF": "240.381.729-53",
     "CNAE Principal": "161099 ",
     "Região": 18,
     "Situação": "Ativa",
     "Data Cadastro": "30\/05\/2023",
     "Estado": "PR",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1719,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MARTIN TORRES ENGENHARIA LTDA",
     "Nome Fantasia\/Apelido": "MARTIN TORRES ENGENHARIA",
     "CNPJ": "47.140.666\/0001-09",
     "CNAE Principal": "71.12-0-00 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "31\/05\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1720,
     "Tipo": "PJ",
     "Razão Social\/Nome": "FIRMINO MARMORES E GRANITOS LTDA",
     "Nome Fantasia\/Apelido": "FIRMINO MARMORES E GRANITOS",
     "CNPJ": "39.578.218\/0001-63",
     "CNAE Principal": "4744-0\/06",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "31\/05\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1722,
     "Tipo": "PJ",
     "Razão Social\/Nome": "HUBI COMERCIO DE CONFECCOES LTDA",
     "CNPJ": " 00.795.786\/0001-80",
     "CNAE Principal": "4781-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "31\/05\/2023",
     "Estado": "PR",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1723,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LIRAN - COMERCIO DE CONFECCOES LTDA",
     "Nome Fantasia\/Apelido": "JOYHUB",
     "CNPJ": "73.321.390\/0001-75",
     "CNAE Principal": "4781-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "31\/05\/2023",
     "Estado": "PR",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1724,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CACAL COMERCIO DE PRODUTOS ALIMENTICIOS LTDA",
     "CNPJ": "31.865.357\/0001-66",
     "CNAE Principal": "4721-1\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "02\/06\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1727,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LUCENA INFRAESTRUTURA",
     "CNPJ": " 03.992.929\/0001-32",
     "Região": 19,
     "Situação": "Ativa",
     "Data Cadastro": "05\/06\/2023",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1728,
     "Tipo": "PJ",
     "Razão Social\/Nome": "PLAY PARTY DECORACOES DE EVENTOS LTDA",
     "Nome Fantasia\/Apelido": "PLAY PARTY EVENTOS",
     "CNPJ": " 07.566.379\/0001-40",
     "CNAE Principal": "8230-0\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "05\/06\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1729,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SOLOS TEC AGRICOLA LTDA",
     "CNPJ": "29.935.781\/0001-98",
     "Região": 19,
     "Situação": "Ativa",
     "Data Cadastro": "05\/06\/2023",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1730,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DOIS IRMAOS COM. DE PECAS LTDA",
     "Nome Fantasia\/Apelido": "TAPAJOS AUTO CENTER",
     "CNPJ": "34.856.377\/0001-69",
     "CNAE Principal": "4530-7\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "05\/06\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1731,
     "Tipo": "PF",
     "Razão Social\/Nome": "KARLOS EDUARDO DO NASCIMENTO",
     "CPF": "650.143.242-15",
     "CNAE Principal": "119999 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "05\/06\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1732,
     "Tipo": "PF",
     "Razão Social\/Nome": "MATEUS GAMA BERTAZZO",
     "CPF": " 032.245.901-06",
     "Região": 19,
     "Situação": "Ativa",
     "Data Cadastro": "05\/06\/2023",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1733,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SUPERMERCADO NOVO LAR LTDA",
     "CNPJ": "10.633.699\/0001-80",
     "Região": 19,
     "Situação": "Ativa",
     "Data Cadastro": "05\/06\/2023",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1734,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MILHOMEM SERVICOS ADMINISTRATIVOS LTDA",
     "CNPJ": "35.830.141\/0001-16",
     "Região": 19,
     "Situação": "Ativa",
     "Data Cadastro": "05\/06\/2023",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1735,
     "Tipo": "PF",
     "Razão Social\/Nome": "CARLOS ROBERTO DANTONIO DE ASSIS ",
     "Nome Fantasia\/Apelido": "Faz Pedrinha II",
     "CPF": "167.155.938-09",
     "CNAE Principal": 151201,
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "05\/06\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1736,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DROGARIA CASA BRANCA LTDA",
     "CNPJ": "19.615.446\/0001-59",
     "CNAE Principal": "4771-7\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "05\/06\/2023",
     "Estado": "MG",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1737,
     "Tipo": "PF",
     "Razão Social\/Nome": "EDSON ANTONIO BALLONI",
     "CPF": "849.559.298-34",
     "Região": 19,
     "Situação": "Ativa",
     "Data Cadastro": "06\/06\/2023",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1738,
     "Tipo": "PJ",
     "Razão Social\/Nome": "N C DE ARAUJO",
     "Nome Fantasia\/Apelido": "PROJECAL ENGENHARIA",
     "CNPJ": "43.448.027\/0001-54",
     "CNAE Principal": "4322-3\/01",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "06\/06\/2023",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1739,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LIBBY SOLUCOES LTDA",
     "Nome Fantasia\/Apelido": "LIBBY SOLAR",
     "CNPJ": "28.400.817\/0001-76",
     "CNAE Principal": "6209-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "06\/06\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1740,
     "Tipo": "PJ",
     "Razão Social\/Nome": "WILLIAN ROBERTO DELFINO JUNIOR",
     "CNPJ": "13.010.425\/0004-76",
     "Região": 19,
     "Situação": "Ativa",
     "Data Cadastro": "07\/06\/2023",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1741,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MARCOS ROGERIO XAVIER 28465325839",
     "Nome Fantasia\/Apelido": "MRC",
     "CNPJ": "36.547.100\/0001-80",
     "CNAE Principal": "2542-0\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "07\/06\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1742,
     "Tipo": "PJ",
     "Razão Social\/Nome": "VIP ONLINE TELECOM LTDA",
     "CNPJ": " 03.481.331\/0001-89",
     "Região": 19,
     "Situação": "Ativa",
     "Data Cadastro": "07\/06\/2023",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1743,
     "Tipo": "PF",
     "Razão Social\/Nome": "CARLOS AURELIO DOMPIERI FILHO ",
     "Nome Fantasia\/Apelido": "CARLOS AURELIO DOMPIERI FILHO Faz Santa Angela",
     "CPF": " 021.576.448-06",
     "CNAE Principal": "1512-01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "10\/01\/2022",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1744,
     "Tipo": "PF",
     "Razão Social\/Nome": "JOSE MAURO SIMIONATTO ",
     "Nome Fantasia\/Apelido": "JOSE MAURO SIMIONATTO Faz Cachoeirinha",
     "CPF": " 062.840.511-15",
     "CNAE Principal": "15-120-1",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "21\/01\/2022",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1745,
     "Tipo": "PJ",
     "Razão Social\/Nome": "KARINA DE FREITAS SILVA MARIANO",
     "CNPJ": "30.517.651\/0001-14",
     "CNAE Principal": "4520-0\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "11\/06\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1746,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MECAN DIESEL COMERCIO DE PECAS E SERVICOS LTDA",
     "Nome Fantasia\/Apelido": "MECAN DIESEL",
     "CNPJ": "33.088.765\/0001-84",
     "CNAE Principal": "4530-7\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "11\/06\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1747,
     "Tipo": "PJ",
     "Razão Social\/Nome": "KI-DELICIA INDUSTRIA E COMERCIO DE CEREAIS LTDA",
     "Nome Fantasia\/Apelido": "ALIMENTOS KI-DELICIA",
     "CNPJ": "33.545.884\/0001-19",
     "CNAE Principal": "4639-7\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "11\/06\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1748,
     "Tipo": "PJ",
     "Razão Social\/Nome": "32.338.526 MARCOS DOUGLAS DE ARAUJO",
     "Nome Fantasia\/Apelido": "MUNDO LIVRE CICLOTURISMO E AVENTURAS",
     "CNPJ": "32.338.526\/0001-72",
     "CNAE Principal": "4763-6\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "13\/06\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1749,
     "Tipo": "PF",
     "Razão Social\/Nome": "CASSIO RODRIGO GREGIO",
     "CPF": " 022.702.249-14",
     "Região": 19,
     "Situação": "Ativa",
     "Data Cadastro": "13\/06\/2023",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1751,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CEREALISTA CAMBARA LTDA",
     "Nome Fantasia\/Apelido": "KI-DELICIA",
     "CNPJ": "26.633.767\/0001-41",
     "CNAE Principal": "1061-9\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "13\/06\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1752,
     "Tipo": "PJ",
     "Razão Social\/Nome": "KAREN CRISTINE B SALVADOR LTDA",
     "Nome Fantasia\/Apelido": "ENGEMARGO ENGENHARIA E CONSULTORIA",
     "CNPJ": "50.163.759\/0001-63",
     "CNAE Principal": "7112-0\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "14\/06\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1753,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CLINICA ESTETICA MARCELA GOUVEIA LTDA",
     "Nome Fantasia\/Apelido": "DRA. MARCELA GOUVEIA",
     "CNPJ": "31.498.498\/0001-98",
     "CNAE Principal": "9602-5\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "14\/06\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1754,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MARCIO ALEXANDRE GALDINO MORAES 62819020259",
     "Nome Fantasia\/Apelido": "GALDINOS ENGENHARIA",
     "CNPJ": "38.461.624\/0001-89",
     "CNAE Principal": "4321-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "14\/06\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1755,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CONDOMNIO RESIDENCIAL CITIZEN PARK",
     "CNPJ": " 03.674.935\/0001-41",
     "CNAE Principal": "8112-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "15\/06\/2023",
     "Estado": "PR",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    }
   ,
    {
     "Column1": 1756,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DROGARIA ALERRO LIMITADA",
     "Nome Fantasia\/Apelido": "BIFARMA",
     "CNPJ": "48.415.275\/0001-12",
     "CNAE Principal": "4771-7\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "15\/06\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1757,
     "Tipo": "PJ",
     "Razão Social\/Nome": "RCS2 COMERCIO DE PRODUTOS ALIMENTICIOS LTDA",
     "Nome Fantasia\/Apelido": "RCS2 NOVO OSASCO",
     "CNPJ": "48.137.569\/0001-20",
     "CNAE Principal": "4721-1\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "16\/06\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1758,
     "Tipo": "PJ",
     "Razão Social\/Nome": "RCS COMERCIO DE PRODUTOS ALIMENTICIOS LTDA",
     "Nome Fantasia\/Apelido": "RCS",
     "CNPJ": "47.324.451\/0001-48",
     "CNAE Principal": "4721-1\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "16\/06\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1759,
     "Tipo": "PJ",
     "Razão Social\/Nome": "NKS PARCERIA E COMERCIO DE PRODUTOS ALIMENTICIOS LTDA",
     "Nome Fantasia\/Apelido": "NKS PARCERIA E COMERCIO LTDA",
     "CNPJ": "17.481.868\/0001-53",
     "CNAE Principal": "4721-1\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "16\/06\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1760,
     "Tipo": "PJ",
     "Razão Social\/Nome": "GRUPO ADVENTUS LTDA",
     "Nome Fantasia\/Apelido": "ADVENTUS MOVEIS FINOS",
     "CNPJ": "19.898.781\/0001-01",
     "CNAE Principal": "3101-2\/00",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "16\/06\/2023",
     "Estado": "PR",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1761,
     "Tipo": "PJ",
     "Razão Social\/Nome": "TECHPROTECTION LTDA",
     "Nome Fantasia\/Apelido": "TECHPROTECTION",
     "CNPJ": "40.987.668\/0001-99",
     "CNAE Principal": "4321-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "19\/06\/2023",
     "Estado": "CE",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1762,
     "Tipo": "PJ",
     "Razão Social\/Nome": "F. T. REIS",
     "Nome Fantasia\/Apelido": "F T SERVICOS AGRICOLAS",
     "CNPJ": "22.238.727\/0001-44",
     "CNAE Principal": " 0161-0\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "20\/06\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1763,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DELPA ENGENHARIA E SEGURANCA LTDA",
     "CNPJ": " 02.084.129\/0001-50",
     "CNAE Principal": "7112-0\/00",
     "Região": 21,
     "Situação": "Ativa",
     "Data Cadastro": "20\/06\/2023",
     "Estado": "MG",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1764,
     "Tipo": "PJ",
     "Razão Social\/Nome": "EDILSON VIEIRA SA",
     "Nome Fantasia\/Apelido": "PANIFICADORA E LANCHONETE PAO DE CADA DIA",
     "CNPJ": "33.255.952\/0001-05",
     "CNAE Principal": "5611-2\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "20\/06\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1765,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MINAS FORTY COMERCIO ATACADISTA LTDA",
     "CNPJ": "30.544.802\/0001-23",
     "CNAE Principal": "1731-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "20\/06\/2023",
     "Estado": "MG",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1766,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ORGANIZACAO FUNERARIA NOSSA SENHORA DE FATIMA LTDA",
     "Nome Fantasia\/Apelido": "FUNERARIA NOSSA SENHORA APARECIDA",
     "CNPJ": " 08.865.431\/0001-22",
     "CNAE Principal": "9603-3\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "21\/06\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1767,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MARIA HELENA DA SILVA OLIVEIRA CAIUA",
     "Nome Fantasia\/Apelido": "FUNERARIA CAIUAENSE",
     "CNPJ": " 00.659.894\/0001-26",
     "CNAE Principal": "9603-3\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "22\/06\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1768,
     "Tipo": "PJ",
     "Razão Social\/Nome": "NHF AUTOMACAO INDUSTRIAL LTDA",
     "Nome Fantasia\/Apelido": "NHF ROBOTICA",
     "CNPJ": " 02.821.236\/0001-14",
     "CNAE Principal": "3321-0\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "22\/06\/2023",
     "Estado": "RS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1769,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MASTER MANUTENCOES LTDA",
     "Nome Fantasia\/Apelido": "MASTER MANUTENCOES",
     "CNPJ": "29.023.773\/0001-75",
     "CNAE Principal": "3314-7\/02",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "22\/06\/2023",
     "Estado": "GO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1770,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ISMAEL U. DA COSTA REFORMAS",
     "Nome Fantasia\/Apelido": "MI REFORMAS E CONSTRUCOES",
     "CNPJ": "16.787.761\/0001-75",
     "CNAE Principal": "4399-1\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "23\/06\/2023",
     "Estado": "ES",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1771,
     "Tipo": "PJ",
     "Razão Social\/Nome": "TARUMA CLIMATIZACAO LTDA",
     "Nome Fantasia\/Apelido": "TARUMA CLIMATIZACAO",
     "CNPJ": "49.080.965\/0001-20",
     "CNAE Principal": "4322-3\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "23\/06\/2023",
     "Estado": "RS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1772,
     "Tipo": "PF",
     "Razão Social\/Nome": "MARCELO PEREIRA ",
     "Nome Fantasia\/Apelido": "Fazenda Nossa Senhora Aparecida ",
     "CPF": " 052.245.458-54",
     "CNAE Principal": "1156-00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "21\/06\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1773,
     "Tipo": "PJ",
     "Razão Social\/Nome": "M B SANTIAGO RODRIGUES",
     "Nome Fantasia\/Apelido": "MONICA SUPERMERCADO & VERDURAO",
     "CNPJ": " 05.961.414\/0001-09",
     "CNAE Principal": "4711-3\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "25\/06\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1774,
     "Tipo": "PF",
     "Razão Social\/Nome": "FULVIO OLIVEIRA APRATO",
     "CPF": " 007.657.701-56",
     "CNAE Principal": "111301 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "25\/06\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1775,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DROGARIA VICENTE LTDA",
     "Nome Fantasia\/Apelido": "DROGARIA VICENTE",
     "CNPJ": " 08.933.522\/0001-58",
     "CNAE Principal": "47.71-7-01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "25\/06\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1776,
     "Tipo": "PJ",
     "Razão Social\/Nome": "J R M FELIX BARROSO LTDA",
     "Nome Fantasia\/Apelido": "ROBERTO ATACADO E DISTRIBUIDORA",
     "CNPJ": "40.729.060\/0001-64",
     "CNAE Principal": "4711-3\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "25\/06\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1777,
     "Tipo": "PJ",
     "Razão Social\/Nome": "KM TRANSPORTES E COMERCIO LTDA",
     "Nome Fantasia\/Apelido": "KM TRANSPORTES E COMERCIO",
     "CNPJ": "46.161.045\/0001-49",
     "CNAE Principal": "4930-2\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "25\/06\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1778,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LIRENI SILVIA DE SOUSA 07292105610",
     "CNPJ": "15.029.797\/0001-27",
     "CNAE Principal": "5611-2\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "26\/06\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1779,
     "Tipo": "PJ",
     "Razão Social\/Nome": "WCM ENGENHARIA LTDA",
     "Nome Fantasia\/Apelido": "WCM ENGENHARIA",
     "CNPJ": "33.897.627\/0001-46",
     "CNAE Principal": "3321-0\/00",
     "Região": 21,
     "Situação": "Ativa",
     "Data Cadastro": "26\/06\/2023",
     "Estado": "PE",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1780,
     "Tipo": "PJ",
     "Razão Social\/Nome": "FORGETECH TECNOLOGIA E SEGURANCA DA INFORMACAO LTDA",
     "Nome Fantasia\/Apelido": "FORGETECH",
     "CNPJ": "47.092.857\/0001-42",
     "CNAE Principal": "6209-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "26\/06\/2023",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1781,
     "Tipo": "PJ",
     "Razão Social\/Nome": "PETS ROYAL LTDA",
     "Nome Fantasia\/Apelido": "PETS ROYAL",
     "CNPJ": "45.218.923\/0001-52",
     "CNAE Principal": "4789-0\/04",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "27\/06\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1782,
     "Tipo": "PJ",
     "Razão Social\/Nome": "FARMA VIDA LTDA",
     "Nome Fantasia\/Apelido": "FARMA VIDA",
     "CNPJ": "21.893.971\/0001-88",
     "CNAE Principal": "4771-7\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "27\/06\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1783,
     "Tipo": "PJ",
     "Razão Social\/Nome": "26 FIT LUZO ACADEMIA DE MUSCULACAO LTDA",
     "CNPJ": "13.396.172\/0013-01",
     "CNAE Principal": "9313-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "27\/06\/2023",
     "Estado": "RS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1784,
     "Tipo": "PJ",
     "Razão Social\/Nome": "J E B SOARES",
     "Nome Fantasia\/Apelido": "PEDACINHO DO CEU",
     "CNPJ": "46.912.132\/0001-90",
     "CNAE Principal": "4691-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "27\/06\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1785,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ZRT INDUSTRIA DE CALDEIRAS LTDA",
     "Nome Fantasia\/Apelido": "ZRT CALDEIRAS.",
     "CNPJ": " 00.069.892\/0001-87",
     "CNAE Principal": "3311-2\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "28\/06\/2023",
     "Estado": "RS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1787,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CONSTRULAR MINI SHOPPING LTDA",
     "Nome Fantasia\/Apelido": "CONSTRULAR",
     "CNPJ": "41.186.473\/0001-02",
     "CNAE Principal": "4744-0\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "29\/06\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1788,
     "Tipo": "PJ",
     "Razão Social\/Nome": "QUIOSQUE PONTA DA PRAIA LTDA",
     "CNPJ": " 05.117.756\/0001-39",
     "CNAE Principal": "5611-2\/03",
     "Região": 21,
     "Situação": "Ativa",
     "Data Cadastro": "29\/06\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1789,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SOUSA E SOUZA SERVICOS MEDICOS LTDA",
     "Nome Fantasia\/Apelido": "ABM SAUDE",
     "CNPJ": "46.918.853\/0001-08",
     "CNAE Principal": "8712-3\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "29\/06\/2023",
     "Estado": "AP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1790,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SWFARMA III COMERCIO DE PRODUTOS FARMACEUTICOS LTDA",
     "CNPJ": "50.508.591\/0001-80",
     "CNAE Principal": "4771-7\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "29\/06\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1791,
     "Tipo": "PJ",
     "Razão Social\/Nome": "VERONEZ TERRAPLANAGEM LTDA",
     "Nome Fantasia\/Apelido": "VERONEZ TERRAPLANAGEM",
     "CNPJ": "47.588.594\/0001-67",
     "CNAE Principal": "43.13-4-00 ",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "30\/06\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1792,
     "Tipo": "PF",
     "Razão Social\/Nome": "MAYARA KATYUSCIA MIRANDA PEREIRA",
     "CPF": " 004.695.782-03",
     "CNAE Principal": 151203,
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "30\/06\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1793,
     "Tipo": "PJ",
     "Razão Social\/Nome": "L.F. FARMACIA LTDA",
     "Nome Fantasia\/Apelido": "DROGARIA VIVA BEM",
     "CNPJ": "44.475.722\/0001-78",
     "CNAE Principal": "4771-7\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "01\/07\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1794,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LUBCAR CENTRO AUTOMOTIVO LTDA",
     "Nome Fantasia\/Apelido": "LUBCAR CENTRO AUTOMOTIVO",
     "CNPJ": "27.482.003\/0001-65",
     "CNAE Principal": "4520-0\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "01\/07\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1795,
     "Tipo": "PJ",
     "Razão Social\/Nome": "BOM PRECO COMERCIO DE MATERIAIS DE CONSTRUCAO LTDA",
     "Nome Fantasia\/Apelido": "BOM PRECO MATERIAIS DE CONSTRUCAO",
     "CNPJ": " 04.362.007\/0001-04",
     "CNAE Principal": "4744-0\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "02\/07\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1796,
     "Tipo": "PJ",
     "Razão Social\/Nome": "R N M DO NASCIMENTO COMERCIO",
     "Nome Fantasia\/Apelido": "CASA PARAENSE",
     "CNPJ": "34.086.204\/0001-09",
     "CNAE Principal": "4771-7\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "02\/07\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1797,
     "Tipo": "PJ",
     "Razão Social\/Nome": "AGROLIMA COMERCIAL LTDA",
     "Nome Fantasia\/Apelido": "AGROLIMA",
     "CNPJ": " 05.864.558\/0002-10",
     "CNAE Principal": "4771-7\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "02\/07\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1798,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MANOEL PEREIRA SANTOS",
     "Nome Fantasia\/Apelido": "POSTO DE MOLAS JM",
     "CNPJ": "23.868.079\/0001-72",
     "CNAE Principal": "4520-0\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "02\/07\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1799,
     "Tipo": "PJ",
     "Razão Social\/Nome": "26 FIT LUZO ACADEMIA DE MUSCULACAO LTDA",
     "CNPJ": "13.396.172\/0012-20",
     "CNAE Principal": "9313-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "03\/07\/2023",
     "Estado": "SC",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1800,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DMUNCK GUINDASTES LTDA",
     "Nome Fantasia\/Apelido": "DMUNCK",
     "CNPJ": "26.702.716\/0001-24",
     "CNAE Principal": "4930-2\/02",
     "Região": 18,
     "Situação": "Ativa",
     "Data Cadastro": "03\/07\/2023",
     "Estado": "SC",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1801,
     "Tipo": "PJ",
     "Razão Social\/Nome": "F L SOARES E CIA LTDA",
     "Nome Fantasia\/Apelido": "F LOPES PECAS DISTRIBUIDORA",
     "CNPJ": "11.516.517\/0001-54",
     "CNAE Principal": "4541-2\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "03\/07\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1802,
     "Tipo": "PJ",
     "Razão Social\/Nome": "AGROLIMA COMERCIAL LTDA",
     "Nome Fantasia\/Apelido": "AGROLIMA",
     "CNPJ": " 05.864.558\/0001-39",
     "CNAE Principal": "4771-7\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "03\/07\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1803,
     "Tipo": "PJ",
     "Razão Social\/Nome": "JOSE DELBERSON VASCONSELOS LTDA",
     "Nome Fantasia\/Apelido": "CONSTRUTEC",
     "CNPJ": "27.628.152\/0001-90",
     "CNAE Principal": "41.20-4-00 - Construção d",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "04\/07\/2023",
     "Estado": "MT",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1804,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CREARE SOLUCOES EM ENGENHARIA LTDA",
     "Nome Fantasia\/Apelido": "ARPROS ENGENHARIA",
     "CNPJ": "45.664.288\/0001-37",
     "CNAE Principal": "7112-0\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "04\/07\/2023",
     "Estado": "RS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1805,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ENERGIAFIT COZINHA INDUSTRIAL LTDA",
     "CNPJ": "42.177.157\/0001-37",
     "CNAE Principal": "5620-1\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "04\/07\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1806,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CARMEM LUCIA M VAZ",
     "Nome Fantasia\/Apelido": "FARMACIA LIRIOS DOS VALES",
     "CNPJ": " 07.326.640\/0001-35",
     "CNAE Principal": "4771-7\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "04\/07\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1809,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MARIA VIEIRA DOS SANTOS",
     "Nome Fantasia\/Apelido": "ASTEC MARA SERVIOS CONTABEIS",
     "CNPJ": " 07.828.964\/0001-71",
     "CNAE Principal": "6920-6\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "04\/07\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1810,
     "Tipo": "PJ",
     "Razão Social\/Nome": "M. ALENCAR DE CARVALHO CHURRASCARIA",
     "Nome Fantasia\/Apelido": "FOGO SANTO CHURRASCARIA",
     "CNPJ": "46.812.839\/0001-25",
     "CNAE Principal": "5611-2\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "05\/07\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1811,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CONTEUDO URBANO PRODUTORA LTDA",
     "CNPJ": "16.098.654\/0001-30",
     "CNAE Principal": "5911-1\/99",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "05\/07\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1812,
     "Tipo": "PJ",
     "Razão Social\/Nome": "R DOS R XAVIER",
     "Nome Fantasia\/Apelido": "LARILAR",
     "CNPJ": "13.828.560\/0001-80",
     "CNAE Principal": "4754-7\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "05\/07\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1813,
     "Tipo": "PJ",
     "Razão Social\/Nome": "VALEMAR BRASIL LTDA",
     "Nome Fantasia\/Apelido": "VALEMAR BRASIL",
     "CNPJ": "15.483.053\/0001-88",
     "CNAE Principal": "8599-6\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "05\/07\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1814,
     "Tipo": "PJ",
     "Razão Social\/Nome": "XAVIER E LIMA LTDA",
     "Nome Fantasia\/Apelido": "LOJA LARILAR",
     "CNPJ": " 02.731.244\/0001-70",
     "CNAE Principal": "4753-9\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "06\/07\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1815,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CARLOS P DE CARVALHO",
     "Nome Fantasia\/Apelido": "AUTO PECAS JUNIOR MOTOS",
     "CNPJ": " 04.108.090\/0001-90",
     "CNAE Principal": "4541-2\/06",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "06\/07\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1816,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CLEANDENTE CLINICA ODONTOLOGICA LTDA",
     "Nome Fantasia\/Apelido": "CLEANDENTE",
     "CNPJ": "10.296.231\/0001-48",
     "CNAE Principal": "8630-5\/04",
     "Região": 21,
     "Situação": "Ativa",
     "Data Cadastro": "06\/07\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1817,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DELTA CONSTRUCOES",
     "Nome Fantasia\/Apelido": "DELTA CONSTRUCOES",
     "CNPJ": "43.110.687\/0001-20",
     "CNAE Principal": "43.99-1-0",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "06\/07\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1818,
     "Tipo": "PJ",
     "Razão Social\/Nome": "50.243.189 GILVANDRO SILVA SANTOS",
     "Nome Fantasia\/Apelido": "GSISTEM",
     "CNPJ": "50.243.189\/0001-11",
     "CNAE Principal": "4322-3\/03",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "06\/07\/2023",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1819,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ABL SOLUCOES INDUSTRIAIS LTDA",
     "Nome Fantasia\/Apelido": "ABL SOLUCOES INDUSTRIAIS",
     "CNPJ": "48.270.291\/0001-64",
     "CNAE Principal": "3313-9\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "07\/07\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1820,
     "Tipo": "PJ",
     "Razão Social\/Nome": "WS PIPELINE PROJETOS E SERVICOS LTDA",
     "Nome Fantasia\/Apelido": "WS PIPELINE",
     "CNPJ": "46.072.754\/0001-58",
     "CNAE Principal": "7119-7\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "07\/07\/2023",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1822,
     "Tipo": "PJ",
     "Razão Social\/Nome": "AR ARQUITETURA E ENGENHARIA LTDA",
     "Nome Fantasia\/Apelido": "AR ARQUITETURA E ENGENHARIA",
     "CNPJ": "41.578.237\/0001-31",
     "CNAE Principal": "7112-0\/00",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "07\/07\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1823,
     "Tipo": "PJ",
     "Razão Social\/Nome": "FERRAZ & LIMA CHOCOLATERIA LTDA",
     "CNPJ": "49.502.177\/0001-85",
     "CNAE Principal": "47.21-1-04 ",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "07\/07\/2023",
     "Estado": "RN",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1824,
     "Tipo": "PJ",
     "Razão Social\/Nome": "D. P DOS SANTOS LTDA",
     "Nome Fantasia\/Apelido": "BRAVO MOTOS",
     "CNPJ": "32.251.074\/0001-97",
     "CNAE Principal": "4541-2\/06",
     "Região": 4,
     "Situação": "Ativa",
     "Data Cadastro": "07\/07\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1825,
     "Tipo": "PJ",
     "Razão Social\/Nome": "COUTO & AMORIM LTDA",
     "Nome Fantasia\/Apelido": "RANCHO DO KOJAK",
     "CNPJ": "16.669.759\/0001-00",
     "CNAE Principal": "5590-6\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "07\/07\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1826,
     "Tipo": "PF",
     "Razão Social\/Nome": "ALAN BATISTA DA SIVLA",
     "CPF": "534.765.811-87",
     "CNAE Principal": "111399 ",
     "Região": 4,
     "Situação": "Ativa",
     "Data Cadastro": "07\/07\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1827,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CONTINENTAL SERVICOS E COBRANCAS LTDA",
     "CNPJ": "28.857.257\/0001-83",
     "CNAE Principal": "7711-0\/00",
     "Região": 18,
     "Situação": "Ativa",
     "Data Cadastro": "07\/07\/2023",
     "Estado": "PR",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1828,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LIGHTINGBH SERVICOS LTDA",
     "CNPJ": "16.912.038\/0001-70",
     "CNAE Principal": "7739-0\/03",
     "Região": 18,
     "Situação": "Ativa",
     "Data Cadastro": "09\/07\/2023",
     "Estado": "MG",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1829,
     "Tipo": "PJ",
     "Razão Social\/Nome": "JOSE GILSON SILVA BORGES COMERCIO",
     "Nome Fantasia\/Apelido": "G M TINTAS",
     "CNPJ": "37.490.773\/0001-03",
     "CNAE Principal": "4679-6\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "10\/07\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1830,
     "Tipo": "PJ",
     "Razão Social\/Nome": "R F LEAL COMERCIO VAREJISTA",
     "Nome Fantasia\/Apelido": "PASSOS CALCADOS",
     "CNPJ": "30.777.589\/0001-08",
     "CNAE Principal": "4782-2\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "10\/07\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1831,
     "Tipo": "PJ",
     "Razão Social\/Nome": "J RANGEL MARMORARIA LTDA",
     "Nome Fantasia\/Apelido": "TOP GRAN MARMORARIA",
     "CNPJ": "13.532.956\/0001-86",
     "CNAE Principal": "2391-5\/03",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "10\/07\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1832,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ANTONIO SANTOS NUNES NETO",
     "Nome Fantasia\/Apelido": "ANG FRUTAS",
     "CNPJ": " 07.113.676\/0001-30",
     "CNAE Principal": "4633-8\/01",
     "Região": 21,
     "Situação": "Ativa",
     "Data Cadastro": "10\/07\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1833,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ECOSERV SERVICOS E MANUTENCOES LTDA",
     "CNPJ": "26.458.960\/0001-93",
     "CNAE Principal": "3319-8\/00",
     "Região": 21,
     "Situação": "Ativa",
     "Data Cadastro": "11\/07\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1834,
     "Tipo": "PJ",
     "Razão Social\/Nome": "HAROLDO S. COSTA",
     "Nome Fantasia\/Apelido": "CASA BRASIL MATERIAL DE CONSTRUCAO",
     "CNPJ": " 05.417.815\/0001-94",
     "CNAE Principal": "4744-0\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "11\/07\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1835,
     "Tipo": "PJ",
     "Razão Social\/Nome": "POLARES TRANSPORTES MARITIMOS LTDA",
     "Nome Fantasia\/Apelido": "POLARES TRANSPORTES MARITIMOS",
     "CNPJ": " 07.269.288\/0001-43",
     "CNAE Principal": "5030-1\/01",
     "Região": 17,
     "Situação": "Ativa",
     "Data Cadastro": "11\/07\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1836,
     "Tipo": "PJ",
     "Razão Social\/Nome": "F&P SOLUTION COMERCIO E SERVICOS LTDA.",
     "Nome Fantasia\/Apelido": "F&P SOLUTION",
     "CNPJ": "40.776.862\/0001-25",
     "CNAE Principal": "4321-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "11\/07\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1837,
     "Tipo": "PJ",
     "Razão Social\/Nome": "T LEDA MELO",
     "Nome Fantasia\/Apelido": "MERCADO NOVO HORIZONTE",
     "CNPJ": "14.393.240\/0001-08",
     "CNAE Principal": "4712-1\/00",
     "Região": 4,
     "Situação": "Ativa",
     "Data Cadastro": "12\/07\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1838,
     "Tipo": "PF",
     "Razão Social\/Nome": "EDUARDO PINHEIRO AGUIAR - CLINICA",
     "Nome Fantasia\/Apelido": "ODONTOMAIS",
     "CPF": " 030.413.671-90",
     "CNAE Principal": "8513-8\/02",
     "Região": 4,
     "Situação": "Ativa",
     "Data Cadastro": "12\/07\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1839,
     "Tipo": "PJ",
     "Razão Social\/Nome": "PIZZARIA TENORIO LTDA",
     "Nome Fantasia\/Apelido": "PIZZARIA DOS AMIGOS",
     "CNPJ": "33.220.648\/0001-22",
     "CNAE Principal": "5611-2\/01",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "12\/07\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1840,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LABTRUST LABORATORIO DE ANALISES TECNICAS LTDA",
     "Nome Fantasia\/Apelido": "LABTRUST",
     "CNPJ": "38.248.143\/0001-90",
     "CNAE Principal": "7120-1\/00",
     "Região": 18,
     "Situação": "Ativa",
     "Data Cadastro": "12\/07\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1841,
     "Tipo": "PF",
     "Razão Social\/Nome": "EDUARDO PINHEIRO AGUIAR",
     "Nome Fantasia\/Apelido": "FAZENDA SANTA MARIA II,",
     "CPF": " 030.413.671-90",
     "CNAE Principal": " 0151-2\/01 ",
     "Região": 4,
     "Situação": "Ativa",
     "Data Cadastro": "12\/07\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1842,
     "Tipo": "PJ",
     "Razão Social\/Nome": "J. D. DOS SANTOS & L. J. PASSOS LTDA",
     "CNPJ": "44.555.724\/0001-77",
     "CNAE Principal": "4322-3\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "12\/07\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1843,
     "Tipo": "PJ",
     "Razão Social\/Nome": "FABIO LOPES SOARES",
     "Nome Fantasia\/Apelido": "MOTOKAO MOTOCENTER",
     "CNPJ": "26.416.623\/0001-33",
     "CNAE Principal": "4541-2\/06",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "12\/07\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1844,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SAUDI ENGENHARIA LTDA",
     "Nome Fantasia\/Apelido": "SAUDI",
     "CNPJ": "44.637.129\/0001-80",
     "CNAE Principal": "7112-0\/00",
     "Região": 18,
     "Situação": "Ativa",
     "Data Cadastro": "13\/07\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1845,
     "Tipo": "PJ",
     "Razão Social\/Nome": "J & M PAPELARIA LTDA",
     "Nome Fantasia\/Apelido": "TOOP PAPELARIA",
     "CNPJ": "10.916.111\/0001-04",
     "CNAE Principal": "4761-0\/03",
     "Região": 4,
     "Situação": "Ativa",
     "Data Cadastro": "13\/07\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1846,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LASER BRUMADO LTDA",
     "Nome Fantasia\/Apelido": "ESPACOLASER",
     "CNPJ": "47.619.745\/0001-05",
     "CNAE Principal": "96.02-5-02 - Atividades d",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "13\/07\/2023",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1847,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DROGARIA LOPES LTDA",
     "Nome Fantasia\/Apelido": "DROGARIA LOPES",
     "CNPJ": "32.255.496\/0001-30",
     "CNAE Principal": "4771-7\/01",
     "Região": 4,
     "Situação": "Ativa",
     "Data Cadastro": "14\/07\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1849,
     "Tipo": "PJ",
     "Razão Social\/Nome": "M E ASSUNCAO DE LIMA",
     "Nome Fantasia\/Apelido": "PRIMEIRA MODA",
     "CNPJ": "18.127.558\/0001-06",
     "CNAE Principal": "4781-4\/00",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "14\/07\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1850,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LED AMBIENTAL LTDA",
     "CNPJ": "31.503.903\/0001-19",
     "CNAE Principal": "7112-0\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "15\/07\/2023",
     "Estado": "SC",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1851,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ALL IN SHOES.COM LTDA",
     "Nome Fantasia\/Apelido": "ALL IN SHOES.COM",
     "CNPJ": "50.183.939\/0001-07",
     "CNAE Principal": "4782-2\/01",
     "Região": 9,
     "Situação": "Ativa",
     "Data Cadastro": "17\/07\/2023",
     "Estado": "GO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1852,
     "Tipo": "PJ",
     "Razão Social\/Nome": "M. NASCIMENTO DA SILVA SERVICOS",
     "Nome Fantasia\/Apelido": "P V SERVICOS",
     "CNPJ": "43.348.471\/0001-06",
     "CNAE Principal": "2539-0\/01",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "17\/07\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1853,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LASER BARREIRAS 2 LTDA",
     "Nome Fantasia\/Apelido": "ESPACOLASER",
     "CNPJ": "47.623.793\/0001-69",
     "CNAE Principal": "96.02-5-02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "17\/07\/2023",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1854,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SALMERON ENERGIA RENOVAVEL E PROTECAO AO CLIMA LTDA",
     "CNPJ": " 07.200.160\/0005-57",
     "CNAE Principal": "4671-1\/00",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "18\/07\/2023",
     "Estado": "MG",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1855,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CONECTADO NOVO PLANO COMERCIO DE CELULARES LTDA",
     "Nome Fantasia\/Apelido": "NOVO PLANO CONECTADO",
     "CNPJ": "36.857.194\/0001-93",
     "CNAE Principal": "4752-1\/00",
     "Região": 4,
     "Situação": "Ativa",
     "Data Cadastro": "20\/07\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1856,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ADRIANO BEZERRA DE SOUSA SILVA 38275121825",
     "Nome Fantasia\/Apelido": "AENGENHARIA",
     "CNPJ": "42.809.490\/0001-11",
     "CNAE Principal": "4322-3\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "20\/07\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1857,
     "Tipo": "PJ",
     "Razão Social\/Nome": "RGS EQUIPAMENTOS PARA GALVANOPLASTIA LTDA",
     "Nome Fantasia\/Apelido": "RGS EQUIPAMENTOS PARA GALVANOPLASTIA",
     "CNPJ": "26.609.448\/0001-09",
     "CNAE Principal": "4744-0\/01",
     "Região": 21,
     "Situação": "Ativa",
     "Data Cadastro": "20\/07\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1858,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SANTANA APOIO FLORESTAL E MEIO AMBIENTE LTDA",
     "Nome Fantasia\/Apelido": "MERCADO MENEZES",
     "CNPJ": "78.583.267\/0001-46",
     "CNAE Principal": " 0161-0\/02",
     "Região": 20,
     "Situação": "Ativa",
     "Data Cadastro": "21\/07\/2023",
     "Estado": "PR",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1859,
     "Tipo": "PJ",
     "Razão Social\/Nome": "JBS DROGARIAS LTDA",
     "Nome Fantasia\/Apelido": "DROGARIA COMPRE BEM",
     "CNPJ": "51.129.433\/0001-82",
     "CNAE Principal": "47.71-7-01",
     "Região": 18,
     "Situação": "Ativa",
     "Data Cadastro": "24\/07\/2023",
     "Estado": "SE",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1860,
     "Tipo": "PJ",
     "Razão Social\/Nome": "TOTAL TRUST CARGO ANALYTICA LTDA",
     "Nome Fantasia\/Apelido": "TOTAL TRUST",
     "CNPJ": "50.236.359\/0001-30",
     "CNAE Principal": "7020-4\/00",
     "Região": 10,
     "Situação": "Ativa",
     "Data Cadastro": "24\/07\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1861,
     "Tipo": "PJ",
     "Razão Social\/Nome": "FERREIRA, IWATA & CIA LTDA",
     "Nome Fantasia\/Apelido": "CECONVET - CENTRO ESPECIALIZADO EM CLINICA E ONCOLOGIA",
     "CNPJ": "21.264.011\/0001-59",
     "CNAE Principal": "7500-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "25\/07\/2023",
     "Estado": "MS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1862,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ITA CALHAS ITAPEVI LTDA",
     "Nome Fantasia\/Apelido": "ITA CALHAS",
     "CNPJ": "49.963.626\/0001-92",
     "CNAE Principal": "4744-0\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "26\/07\/2023",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1863,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DIONISIO WAGNO DELMONDES OLIVEIRA - ME",
     "Nome Fantasia\/Apelido": "GESSO T D.",
     "CNPJ": "10.444.809\/0001-66",
     "CNAE Principal": "4744-0\/05",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "26\/07\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1864,
     "Tipo": "PJ",
     "Razão Social\/Nome": "UNA COMERCIO & SERVICOS LTDA",
     "Nome Fantasia\/Apelido": "UNA PATRIMONIAL",
     "CNPJ": "41.820.307\/0001-16",
     "CNAE Principal": "8111-7\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "27\/07\/2023",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1865,
     "Tipo": "PJ",
     "Razão Social\/Nome": "BERTESQUAD ESQUADRIAS LTDA",
     "Nome Fantasia\/Apelido": "BERTIOGA ESQUADRIAS",
     "CNPJ": "21.237.464\/0001-96",
     "CNAE Principal": "2542-0\/00",
     "Região": 18,
     "Situação": "Ativa",
     "Data Cadastro": "27\/07\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1866,
     "Tipo": "PJ",
     "Razão Social\/Nome": "L C CORDEIRO JOSE CONFECCOES",
     "Nome Fantasia\/Apelido": "GUERRAH",
     "CNPJ": " 04.882.675\/0001-62",
     "CNAE Principal": "4781-4\/00",
     "Região": 17,
     "Situação": "Ativa",
     "Data Cadastro": "27\/07\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1867,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MARCO ANTONIO SANCHES GALIASSI 32417835886",
     "Nome Fantasia\/Apelido": "MASG SISTEMAS DE PROTECAO CONTRA INCENDIO",
     "CNPJ": "44.795.718\/0001-97",
     "CNAE Principal": "4322-3\/03",
     "Região": 21,
     "Situação": "Ativa",
     "Data Cadastro": "27\/07\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1868,
     "Tipo": "PF",
     "Razão Social\/Nome": "FERNANDO BUENO BERTAZZO",
     "CPF": "137.810.248-73",
     "CNAE Principal": "151201 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "28\/07\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1869,
     "Tipo": "PF",
     "Razão Social\/Nome": "ROBERTO RODRIGUES DE SOUZA",
     "CPF": "369.615.821-68",
     "CNAE Principal": 151201,
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "28\/07\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1870,
     "Tipo": "PJ",
     "Razão Social\/Nome": "R R LIMA JUNIOR",
     "Nome Fantasia\/Apelido": "SUPERMERCADO LAR CENTER",
     "CNPJ": "10.518.441\/0001-33",
     "CNAE Principal": "4712-1\/00",
     "Região": 4,
     "Situação": "Ativa",
     "Data Cadastro": "28\/07\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1871,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MILIELE ABREU DE OLIVEIRA",
     "Nome Fantasia\/Apelido": "AGRO RACOES",
     "CNPJ": "32.940.750\/0001-30",
     "CNAE Principal": "4771-7\/04",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "28\/07\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1872,
     "Tipo": "PF",
     "Razão Social\/Nome": "ALEXANDRINA LEANDRO PINHEIRO CORREA",
     "CPF": "774.632.001-59",
     "CNAE Principal": "115600 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "28\/07\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1873,
     "Tipo": "PJ",
     "Razão Social\/Nome": "M M AZEVEDO AUTO PECAS & ACESSORIOS LTDA",
     "Nome Fantasia\/Apelido": "AZEVEDO AUTO PECAS",
     "CNPJ": "35.150.780\/0001-30",
     "CNAE Principal": "4530-7\/03",
     "Região": 17,
     "Situação": "Ativa",
     "Data Cadastro": "28\/07\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1874,
     "Tipo": "PJ",
     "Razão Social\/Nome": "EDSON CICERO DE CARVALHO TRANSPORTES LTDA",
     "Nome Fantasia\/Apelido": "EDSON CICERO DE CARVALHO TRANSPORTES",
     "CNPJ": " 04.682.532\/0001-07",
     "CNAE Principal": "4921-3\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "03\/05\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1875,
     "Tipo": "PJ",
     "Razão Social\/Nome": "N. M. DE O. SOARES",
     "Nome Fantasia\/Apelido": "MOTOKAO",
     "CNPJ": "31.097.494\/0001-06",
     "CNAE Principal": "4541-2\/06",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "31\/07\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1876,
     "Tipo": "PJ",
     "Razão Social\/Nome": "M G DAMASCENO SOUZA",
     "Nome Fantasia\/Apelido": "SOBERAN@",
     "CNPJ": "47.176.944\/0001-88",
     "CNAE Principal": "4642-7\/01",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "31\/07\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1877,
     "Tipo": "PJ",
     "Razão Social\/Nome": "VILA BEBE COMERCIO DE ROUPAS LTDA",
     "Nome Fantasia\/Apelido": "VILA BEBE",
     "CNPJ": " 03.469.265\/0001-21",
     "CNAE Principal": "4781-4\/00",
     "Região": 17,
     "Situação": "Ativa",
     "Data Cadastro": "31\/07\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1878,
     "Tipo": "PJ",
     "Razão Social\/Nome": "C S DA COSTA",
     "Nome Fantasia\/Apelido": "REAL VIDROS",
     "CNPJ": " 05.874.619\/0001-49",
     "CNAE Principal": "4743-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "01\/08\/2023",
     "Estado": "RN",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1879,
     "Tipo": "PJ",
     "Razão Social\/Nome": "L. DE A. SILVA & CIA LTDA",
     "Nome Fantasia\/Apelido": "EYKEART",
     "CNPJ": "46.613.806\/0001-56",
     "CNAE Principal": "4120-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "01\/08\/2023",
     "Estado": "MS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1880,
     "Tipo": "PJ",
     "Razão Social\/Nome": "L DA S N DE PINHO LTDA",
     "Nome Fantasia\/Apelido": "ELETRO PINHO",
     "CNPJ": "28.408.087\/0001-50",
     "CNAE Principal": "4754-7\/01",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "02\/08\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1881,
     "Tipo": "PJ",
     "Razão Social\/Nome": "HENRIQUE WESLEY SILVA LIMA COMERCIO",
     "Nome Fantasia\/Apelido": "MERCADINHO PEIXOTO",
     "CNPJ": "34.637.649\/0001-30",
     "CNAE Principal": "4729-6\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "02\/08\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1882,
     "Tipo": "PJ",
     "Razão Social\/Nome": "FABIO HENRIQUE DE OLIVEIRA 39331493800",
     "Nome Fantasia\/Apelido": "FHARBORISTA",
     "CNPJ": "36.602.655\/0001-87",
     "CNAE Principal": "8130-3\/00",
     "Região": 18,
     "Situação": "Ativa",
     "Data Cadastro": "03\/08\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1883,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MARCOS TAVARES GOMES 93607164053",
     "Nome Fantasia\/Apelido": "REFORMAS MATOS",
     "CNPJ": "23.402.109\/0001-50",
     "CNAE Principal": "8130-3\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "04\/08\/2023",
     "Estado": "RS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1884,
     "Tipo": "PJ",
     "Razão Social\/Nome": "J. M. FREITAS & CIA LTDA",
     "Nome Fantasia\/Apelido": "TEC FIRE",
     "CNPJ": "20.165.003\/0001-92",
     "CNAE Principal": "43.22-3-03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "04\/08\/2023",
     "Estado": "PR",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1885,
     "Tipo": "PJ",
     "Razão Social\/Nome": "FABIANO R. PEREIRA",
     "Nome Fantasia\/Apelido": "PANIFICADORA & LANCHONETE IDEAL",
     "CNPJ": "13.458.092\/0001-08",
     "CNAE Principal": "1091-1\/02",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "04\/08\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1886,
     "Tipo": "PJ",
     "Razão Social\/Nome": "AUTO POSTO DE COMBUSTIVEIS ARAGUAIA LTDA",
     "Nome Fantasia\/Apelido": "POSTO ARAGUAIA",
     "CNPJ": "40.993.972\/0001-49",
     "CNAE Principal": "4731-8\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "05\/08\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1887,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LASER GUANAMBI LTDA",
     "Nome Fantasia\/Apelido": "ESPACOLASER",
     "CNPJ": "46.828.774\/0001-06",
     "CNAE Principal": "96.02-5-02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "05\/08\/2023",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1888,
     "Tipo": "PJ",
     "Razão Social\/Nome": "INFRA SERVICE UNIPESSOAL LTDA",
     "Nome Fantasia\/Apelido": "R & C SERVICE",
     "CNPJ": "51.244.773\/0001-54",
     "CNAE Principal": "43.21-5-00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "05\/08\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1889,
     "Tipo": "PJ",
     "Razão Social\/Nome": "WJR DE JESUS CONSTRUCOES E MANUTENCOES LTDA",
     "CNPJ": "32.212.012\/0001-76",
     "CNAE Principal": "4120-4\/00",
     "Região": 18,
     "Situação": "Ativa",
     "Data Cadastro": "07\/08\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1890,
     "Tipo": "PJ",
     "Razão Social\/Nome": "BHD OBRAS E REFORMAS LTDA",
     "CNPJ": "38.463.669\/0001-92",
     "CNAE Principal": "4399-1\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "06\/07\/2023",
     "Estado": "MG",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1891,
     "Tipo": "PJ",
     "Razão Social\/Nome": "G5W SOLUCOES DE SEGURANCA LTDA",
     "Nome Fantasia\/Apelido": "G5W SOLUCOES DE SEGURANCA",
     "CNPJ": "46.556.487.0001-94",
     "CNAE Principal": "4321-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "07\/08\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1892,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MCE SERVICOS GERAIS LTDA",
     "CNPJ": "34.399.531\/0001-10",
     "CNAE Principal": "4120-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "07\/08\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1893,
     "Tipo": "PF",
     "Razão Social\/Nome": "SINEIDES MILHOMEM DE SOUSA",
     "CPF": "486.007.841-15",
     "CNAE Principal": " 0151-2\/01 ",
     "Região": 4,
     "Situação": "Ativa",
     "Data Cadastro": "07\/08\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1894,
     "Tipo": "PJ",
     "Razão Social\/Nome": "WESLLEY DE OLIVEIRA MORAES - IMPERMEABILIZACOES",
     "CNPJ": "17.155.200\/0001-16",
     "CNAE Principal": "4330-4\/04",
     "Região": 20,
     "Situação": "Ativa",
     "Data Cadastro": "07\/08\/2023",
     "Estado": "PR",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1895,
     "Tipo": "PJ",
     "Razão Social\/Nome": "W M FREITAS E CIA LTDA",
     "Nome Fantasia\/Apelido": "VISUAL CURSOS TREINAMENTOS SERVICOS & LOCACAO",
     "CNPJ": "10.426.775\/0001-87",
     "CNAE Principal": "4923-0\/02",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "08\/08\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1896,
     "Tipo": "PJ",
     "Razão Social\/Nome": "REVISTA & CIA DISTRIBUIDORA LTDA",
     "Nome Fantasia\/Apelido": "REVISTA & CIA DISTRIBUIDORA",
     "CNPJ": " 07.631.477\/0001-14",
     "CNAE Principal": "4761-0\/02",
     "Região": 21,
     "Situação": "Ativa",
     "Data Cadastro": "09\/08\/2023",
     "Estado": "MG",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1897,
     "Tipo": "PJ",
     "Razão Social\/Nome": "A LASER TECNOLOGIA LTDA",
     "Nome Fantasia\/Apelido": "A LASER TECNOLOGIA EIRELI",
     "CNPJ": "33.744.311\/0001-14",
     "CNAE Principal": "7739-0\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "09\/08\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1898,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LN - CALCADOS & ACESSORIOS LTDA",
     "Nome Fantasia\/Apelido": "LN - CALCADOS & ACESSORIOS",
     "CNPJ": "50.307.739\/0001-19",
     "CNAE Principal": "4782-2\/01",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "10\/08\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1899,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ACO ESSENCIAL LTDA",
     "Nome Fantasia\/Apelido": "ACO ESSENCIAL",
     "CNPJ": "28.863.982\/0001-64",
     "CNAE Principal": "2542-0\/00",
     "Região": 20,
     "Situação": "Ativa",
     "Data Cadastro": "10\/08\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1900,
     "Tipo": "PJ",
     "Razão Social\/Nome": "N M JARDIM MURTINHO LTDA",
     "Nome Fantasia\/Apelido": "O BARATAO DA MODA",
     "CNPJ": "45.261.820\/0001.75",
     "CNAE Principal": "4781-4\/00",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "11\/08\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1901,
     "Tipo": "PJ",
     "Razão Social\/Nome": "THIAGO LEME COMERCIO DE REFEICOES LTDA",
     "Nome Fantasia\/Apelido": "THIAGO LEME REFEICOES",
     "CNPJ": "51.659.051\/0001-60",
     "CNAE Principal": "56.20-1-01 ",
     "Região": 21,
     "Situação": "Ativa",
     "Data Cadastro": "11\/08\/2023",
     "Estado": "PR",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1902,
     "Tipo": "PJ",
     "Razão Social\/Nome": "TWIIH AUTOMATION SOLUTIONS LTDA",
     "Nome Fantasia\/Apelido": "TWIIH AUTOMATION SOLUTIONS",
     "CNPJ": "43.740.697\/0001-40",
     "CNAE Principal": "3321-0\/00",
     "Região": 20,
     "Situação": "Ativa",
     "Data Cadastro": "11\/08\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1903,
     "Tipo": "PF",
     "Razão Social\/Nome": "BRENO APARECIDO SANTOS PINHEIRO",
     "CPF": "456.056.078-12",
     "CNAE Principal": "6912500 ",
     "Região": 7,
     "Situação": "Ativa",
     "Data Cadastro": "13\/08\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1904,
     "Tipo": "PJ",
     "Razão Social\/Nome": "GEDEAO COMERCIO E SERVICOS LTDA",
     "Nome Fantasia\/Apelido": "ALENCAR O BARATAO",
     "CNPJ": "18.125.044\/0001-03",
     "CNAE Principal": "4520-0\/01",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "13\/08\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1905,
     "Tipo": "PJ",
     "Razão Social\/Nome": "GRU COMPRESSORES LTDA",
     "Nome Fantasia\/Apelido": "MANUTECNICA COMERCIO E MANUTENCAO DE COMPRESSORES DE AR",
     "CNPJ": "33.149.567\/0001-83",
     "CNAE Principal": "3314-7\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "14\/08\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1906,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MAINFIX SERVICOS DE MANUTENCAO LTDA",
     "CNPJ": "30.037.191\/0001-27",
     "CNAE Principal": "4330-4\/02",
     "Região": 21,
     "Situação": "Ativa",
     "Data Cadastro": "14\/08\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1907,
     "Tipo": "PJ",
     "Razão Social\/Nome": "GEDEAO COMERCIO AGRICOLA LTDA",
     "Nome Fantasia\/Apelido": "GEDEAO AGRICOLA",
     "CNPJ": "44.577.295\/0001-39",
     "CNAE Principal": "4692-3\/00",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "15\/08\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1908,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DENILSON PINHEIRO DE JESUS",
     "Nome Fantasia\/Apelido": "RIOGUARD PISCINAS",
     "CNPJ": "39.479.289\/0001-09",
     "CNAE Principal": "9609-2\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "15\/08\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1909,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MULTI DRIVE FORTALEZA COMERCIO ELETROELETRONICO",
     "Nome Fantasia\/Apelido": "MULTI DRIVE FORTALEZA COMERCIO ELETROELETRONICO",
     "CNPJ": "45.588.976\/0001-65",
     "CNAE Principal": "4789-0\/99",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "15\/08\/2023",
     "Estado": "CE",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1910,
     "Tipo": "PJ",
     "Razão Social\/Nome": "JMC FLORESTAL LTDA",
     "CNPJ": " 02.291.477\/0001-07",
     "CNAE Principal": "4613-3\/00",
     "Região": 20,
     "Situação": "Ativa",
     "Data Cadastro": "16\/08\/2023",
     "Estado": "PR",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1911,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ENGENHO DESIGN E COMUNICACAO VISUAL STUDIO LTDA",
     "Nome Fantasia\/Apelido": "BUENO CAOS",
     "CNPJ": " 07.848.681\/0001-91",
     "CNAE Principal": "8599-6\/04",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "16\/08\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1912,
     "Tipo": "PJ",
     "Razão Social\/Nome": "W M FREITAS E CIA LTDA",
     "Nome Fantasia\/Apelido": "VISUAL TREINAMENTO, SERVICOS & LOCACAO",
     "CNPJ": "10.426.775\/0002-68",
     "CNAE Principal": "4923-0\/02",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "16\/08\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1913,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CBM CORRETORA DE SEGUROS LTDA",
     "Nome Fantasia\/Apelido": "CBM CORRETORA DE SEGUROS",
     "CNPJ": "22.634.357\/0001-64",
     "CNAE Principal": "66.22-3-00 ",
     "Região": 4,
     "Situação": "Ativa",
     "Data Cadastro": "16\/08\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1914,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CENTRAL FIELD SERVICE LTDA",
     "Nome Fantasia\/Apelido": "CENTRAL FIELD SERVICE",
     "CNPJ": "19.319.337\/0001-94",
     "CNAE Principal": "9511-8\/00",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "16\/08\/2023",
     "Estado": "MG",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1915,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DG&D ENGENHARIA LTDA",
     "Nome Fantasia\/Apelido": "DG&D ENGENHARIA",
     "CNPJ": "43.946.808\/0001-79",
     "CNAE Principal": "41.20-4-00",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "16\/08\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1916,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ENGEFIXE CONSTRUCOES E SERVICOS LTDA",
     "Nome Fantasia\/Apelido": "ENGEFIXE CONSTRUCOES E SERVICOS",
     "CNPJ": "17.390.308\/0001-93",
     "CNAE Principal": "4120-4\/00",
     "Região": 21,
     "Situação": "Ativa",
     "Data Cadastro": "17\/08\/2023",
     "Estado": "RN",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1917,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LARYSSA MOURAO RODRIGUES LTDA",
     "Nome Fantasia\/Apelido": "BOUTIQUE LARY SEMI JOIAS",
     "CNPJ": "29.777.196\/0001-07",
     "CNAE Principal": "4783-1\/01",
     "Região": 4,
     "Situação": "Ativa",
     "Data Cadastro": "17\/08\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1918,
     "Tipo": "PJ",
     "Razão Social\/Nome": "RAILSON DA SILVA 17692182892",
     "Nome Fantasia\/Apelido": "RAICLIMAT AR CONDICIONADO",
     "CNPJ": "23.215.271\/0001-60",
     "CNAE Principal": "4322-3\/02",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "17\/08\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1919,
     "Tipo": "PJ",
     "Razão Social\/Nome": "AMICIS ENGENHARIA LTDA",
     "Nome Fantasia\/Apelido": "AMICIS ENGENHARIA",
     "CNPJ": "35.754.114\/0001-01",
     "CNAE Principal": "7112-0\/00",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "17\/08\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1920,
     "Tipo": "PJ",
     "Razão Social\/Nome": "COMDOMINIO PARK RESEDA",
     "Nome Fantasia\/Apelido": "CONDOMINIO PARK RESEDA",
     "CNPJ": "50.310.014\/0001-80",
     "CNAE Principal": "8112-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "17\/08\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1921,
     "Tipo": "PJ",
     "Razão Social\/Nome": "BH FRIO LTDA",
     "CNPJ": "24.723.774\/0001-09",
     "CNAE Principal": "4759-8\/99",
     "Região": 21,
     "Situação": "Ativa",
     "Data Cadastro": "18\/08\/2023",
     "Estado": "MG",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1922,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DISTRIBUICAO E LOGISTICA FREITAS LTDA",
     "Nome Fantasia\/Apelido": "DISTRIBUICAO E LOGISTICA SANTOS & FREITAS",
     "CNPJ": "27.501.772\/0001-63",
     "CNAE Principal": "4637-1\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "20\/08\/2023",
     "Estado": "RS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1923,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SALMERON ENERGIA RENOVAVEL E PROTECAO AO CLIMA LTDA",
     "CNPJ": " 07.200.160\/0001-23",
     "CNAE Principal": "3530-1\/00",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "21\/08\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1924,
     "Tipo": "PJ",
     "Razão Social\/Nome": "F DANTAS DE FARIAS LTDA",
     "Nome Fantasia\/Apelido": "ESTACAO DANTAS",
     "CNPJ": "42.604.726\/0001-83",
     "CNAE Principal": "4753-9\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "21\/08\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1925,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ADEMIR DONIZETE FERREIRA TRANSPORTES LTDA",
     "Nome Fantasia\/Apelido": "MGA TRANSPORTES",
     "CNPJ": " 04.721.808\/0001-19",
     "CNAE Principal": "4921-3\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "20\/08\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1926,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ANTONIO DE PADUA ALVES RODRIGUES LTDA",
     "CNPJ": " 03.464.334\/0001-04",
     "CNAE Principal": "4921-3\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "09\/08\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1928,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CICERO DE SOUZA VALE TRANSPORTES LTDA",
     "Nome Fantasia\/Apelido": "CICERO DE SOUZA VALE TRANSPORTES LTDA",
     "CNPJ": " 03.472.021\/0001-06",
     "CNAE Principal": "4923-0\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "09\/08\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1930,
     "Tipo": "PJ",
     "Razão Social\/Nome": "GPPRO ENGENHARIA LTDA",
     "Nome Fantasia\/Apelido": "PROJEKTA PROJETOS DE ENGENHARIA",
     "CNPJ": "31.369.355\/0001-86",
     "CNAE Principal": "7119-7\/03",
     "Região": 20,
     "Situação": "Ativa",
     "Data Cadastro": "21\/08\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1931,
     "Tipo": "PJ",
     "Razão Social\/Nome": "AUTO PECAS ELETRICAS SUL LTDA",
     "Nome Fantasia\/Apelido": "AUTO PECAS ELETRICAS SUL",
     "CNPJ": "89.618.532\/0001-09",
     "CNAE Principal": "4530-7\/03",
     "Região": 11,
     "Situação": "Ativa",
     "Data Cadastro": "22\/08\/2023",
     "Estado": "RS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1932,
     "Tipo": "PJ",
     "Razão Social\/Nome": "GOLDEN MANUTENCAO DE ELEVADORES LTDA",
     "Nome Fantasia\/Apelido": "GOLDEN ELEVADORES",
     "CNPJ": "29.154.554\/0001-25",
     "CNAE Principal": "4329-1\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "22\/08\/2023",
     "Estado": "AM",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1933,
     "Tipo": "PF",
     "Razão Social\/Nome": "NIVALSON JOSE ALVES",
     "Nome Fantasia\/Apelido": "FAZENDA AGUA BRANCA",
     "CPF": " 071.409.671-72",
     "CNAE Principal": 151202,
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "22\/08\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1934,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MANOEL ALTOS TEIXEIRA TRANSPORTES LTDA",
     "CNPJ": " 03.525.104\/0001-08",
     "CNAE Principal": "4921-3\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "22\/08\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1935,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MANOEL EDUARDO RIBEIRO TRANSPORTES LTDA",
     "Nome Fantasia\/Apelido": "OPCAO TRANSLADOS",
     "CNPJ": " 03.638.808\/0001-97",
     "CNAE Principal": "4921-3\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "22\/03\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1936,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MILTON GERALDO MAURICIO TRANSPORTES LTDA",
     "CNPJ": " 03.698.093\/0001-68",
     "CNAE Principal": "4921-3\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "10\/04\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1937,
     "Tipo": "PJ",
     "Razão Social\/Nome": "JOACIL DE OLIVEIRA NEGRE 49400916191",
     "Nome Fantasia\/Apelido": "STOP CAR",
     "CNPJ": "32.952.275\/0001-11",
     "CNAE Principal": "4520-0\/02",
     "Região": 4,
     "Situação": "Ativa",
     "Data Cadastro": "22\/08\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1938,
     "Tipo": "PJ",
     "Razão Social\/Nome": "50.311.480 DOUGLAS LIMA DA SILVA",
     "Nome Fantasia\/Apelido": "GRUPO GERA",
     "CNPJ": "50.311.480\/0001-80",
     "CNAE Principal": "4322-3\/02",
     "Região": 21,
     "Situação": "Ativa",
     "Data Cadastro": "23\/08\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1939,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CONSTRUTORA OLIVEIRA ANDRADE LTDA ",
     "Nome Fantasia\/Apelido": "CONSTRUTORA OLIVEIRA ANDRADE GURUPI",
     "CNPJ": "21.791.639\/0001-02",
     "CNAE Principal": "4120-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "24\/08\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1940,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MASSARI MINERACAO PARTICIPACOES LTDA.",
     "CNPJ": "12.776.168\/0003-34",
     "CNAE Principal": " 0810-0\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "24\/08\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1941,
     "Tipo": "PJ",
     "Razão Social\/Nome": "TESTAR - TREINAMENTOS E SERVICOS TECNICOS LTDA",
     "Nome Fantasia\/Apelido": "TESTAR - TREINAMENTOS E SEVICOS TECNICOS",
     "CNPJ": "18.759.403\/0001-84",
     "CNAE Principal": "8599-6\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "24\/08\/2023",
     "Estado": "MG",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1942,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CAPUAVA COMERCIO DE RESIDUOS, RECICLAGEM, LOCACOES E SERVICOS LTDA",
     "CNPJ": " 09.532.406\/0001-90",
     "CNAE Principal": "4671-1\/00",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "25\/08\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1943,
     "Tipo": "PJ",
     "Razão Social\/Nome": "26.583.904 SILDOMAR DE SOUSA GLORIA",
     "Nome Fantasia\/Apelido": "AUTO MECANICA CORUJAO DIESEL",
     "CNPJ": "26.583.904\/0001-80",
     "CNAE Principal": "4520-0\/01",
     "Região": 4,
     "Situação": "Ativa",
     "Data Cadastro": "25\/08\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1944,
     "Tipo": "PF",
     "Razão Social\/Nome": "SIMONE ALBERTIN",
     "CPF": "872.495.451-91",
     "CNAE Principal": 8630501,
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "25\/08\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1945,
     "Tipo": "PJ",
     "Razão Social\/Nome": "TAGG EXCELENCIA EM GERENCIAMENTO E DESENVOLVIMENTO ORGANIZACIONAL LTDA",
     "Nome Fantasia\/Apelido": "TAGG",
     "CNPJ": "10.712.817\/0001-46",
     "CNAE Principal": "8299-7\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "29\/08\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1946,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MANEJO AGRO CONSULTORIA LTDA",
     "Nome Fantasia\/Apelido": "MANEJO AGRO CONSULTORIA",
     "CNPJ": "49.678.715\/0001-97",
     "CNAE Principal": "7490-1\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "28\/08\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1947,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LUCIA NUNES DE MOURA SANTOS",
     "CNPJ": "19.389.727\/0001-30",
     "CNAE Principal": "5510-8\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "28\/08\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1948,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ANTONIO CARLOS NEVES DA SILVA TRANSPORTES LTDA",
     "Nome Fantasia\/Apelido": "ACNS TRANSPORTES",
     "CNPJ": " 03.638.016\/0001-12",
     "CNAE Principal": "4921-3\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "28\/08\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1949,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LUCAS FRANCA DA SILVA 11703831497",
     "Nome Fantasia\/Apelido": "IUNNIQ ESQUADRIAS E VIDROS",
     "CNPJ": "39.632.304\/0001-07",
     "CNAE Principal": "4330-4\/99",
     "Região": 20,
     "Situação": "Ativa",
     "Data Cadastro": "28\/08\/2023",
     "Estado": "PE",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1950,
     "Tipo": "PJ",
     "Razão Social\/Nome": "K. A. TEIXEIRA FARMA LTDA",
     "Nome Fantasia\/Apelido": "FARMA POPULAR",
     "CNPJ": "23.807.272\/0001-01",
     "CNAE Principal": "4771-7\/01",
     "Região": 20,
     "Situação": "Ativa",
     "Data Cadastro": "28\/08\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1951,
     "Tipo": "PJ",
     "Razão Social\/Nome": "BC CONSTRUCTION LTDA",
     "Nome Fantasia\/Apelido": "BC CONSTRUCTION",
     "CNPJ": "51.586.911\/0001-83",
     "CNAE Principal": "7112-0\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "29\/08\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1952,
     "Tipo": "PJ",
     "Razão Social\/Nome": "BOURBON GESTAO DE RESIDUOS LTDA",
     "Nome Fantasia\/Apelido": "BOURBON MERCOSUL GESTAO DE RESIDUOS",
     "CNPJ": "30.883.301\/0001-71",
     "CNAE Principal": "3702-9\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "29\/08\/2023",
     "Estado": "RS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1953,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MARIA DA S SANTOS",
     "Nome Fantasia\/Apelido": "MERCADINHO E HOTEL CENTRAL",
     "CNPJ": " 09.457.278\/0001-67",
     "CNAE Principal": "4712-1\/00",
     "Região": 8,
     "Situação": "Ativa",
     "Data Cadastro": "29\/08\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1954,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ADILSON BARBOSA DA SILVA",
     "Nome Fantasia\/Apelido": "AD LIMPEZA DE PISOS E CARPETES",
     "CNPJ": "19.389.317\/0001-90",
     "CNAE Principal": "8121-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "30\/08\/2023",
     "Estado": "MG",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1955,
     "Tipo": "PJ",
     "Razão Social\/Nome": "GPL MONTAGENS E MANUTENCOES LTDA",
     "Nome Fantasia\/Apelido": "GPL MANUTENCOES",
     "CNPJ": "51.380.198\/0001-17",
     "CNAE Principal": "3311-2\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "30\/08\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1956,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CAUE ARAUJO BRAZ LTDA",
     "Nome Fantasia\/Apelido": "CLINICA MAIS MED",
     "CNPJ": "32.135.697\/0001-02",
     "CNAE Principal": "8610-1\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "30\/08\/2023",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1957,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CHAM ENGENHARIA LTDA",
     "Nome Fantasia\/Apelido": "CH SERRALHERIA",
     "CNPJ": "24.140.622\/0001-83",
     "CNAE Principal": "4120-4\/00",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "31\/08\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1958,
     "Tipo": "PJ",
     "Razão Social\/Nome": "A S COMERCIO E SERVICOS DE ESQUADRIAS E VIDROS LTDA",
     "Nome Fantasia\/Apelido": "SMART ARTEFATOS DE ALUMINIO",
     "CNPJ": " 07.315.386\/0001-70",
     "CNAE Principal": "2441-5\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "31\/08\/2023",
     "Estado": "RN",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1959,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SUL INSTALACOES HIDRAULICAS LTDA",
     "Nome Fantasia\/Apelido": "SUL INSTALACOES HIDRAULICAS",
     "CNPJ": "35.588.622\/0001-67",
     "CNAE Principal": "4322-3\/01",
     "Região": 10,
     "Situação": "Ativa",
     "Data Cadastro": "31\/08\/2023",
     "Estado": "SC",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1960,
     "Tipo": "PJ",
     "Razão Social\/Nome": "EZIQUIEL INACIO DE LIMA LTDA",
     "CNPJ": " 03.620.179\/0001-78",
     "CNAE Principal": "4921-3\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "01\/09\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1961,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ATAMAM LTDA",
     "Nome Fantasia\/Apelido": "GRUPO ATAMAM",
     "CNPJ": "42.549.947\/0001-04",
     "CNAE Principal": "3315-5\/00",
     "Região": 21,
     "Situação": "Ativa",
     "Data Cadastro": "01\/09\/2023",
     "Estado": "PR",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1962,
     "Tipo": "PJ",
     "Razão Social\/Nome": "TRUCK CENTER ALINHADOR DA BRASIL LTDA",
     "Nome Fantasia\/Apelido": "TRUCK CENTER",
     "CNPJ": "12.186.772\/0001-49",
     "CNAE Principal": "4520-0\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "01\/09\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1963,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MEDIR TOPOGRAFIA SOCIEDADE UNIPESSOAL LIMITADA",
     "Nome Fantasia\/Apelido": "MEDIR",
     "CNPJ": "30.226.576\/0001-32",
     "CNAE Principal": "7119-7\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "01\/09\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1964,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ALDENOR SOARES DA SILVA - ME",
     "Nome Fantasia\/Apelido": "ASOSIL SERVICOS",
     "CNPJ": "31.805.857\/0001-02",
     "CNAE Principal": "4399-1\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "03\/09\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1965,
     "Tipo": "PJ",
     "Razão Social\/Nome": "WILSON MAXIMO DE MELO LTDA",
     "CNPJ": " 03.655.988\/0001-15",
     "CNAE Principal": "4921-3\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "03\/02\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1966,
     "Tipo": "PJ",
     "Razão Social\/Nome": "BELARTEC CONSTRUCAO MANUTENCAO E NEGOCIOS LTDA.",
     "Nome Fantasia\/Apelido": "BELARTEC ENGENHARIA",
     "CNPJ": "33.143.669\/0001-91",
     "CNAE Principal": "4120-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "04\/09\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1967,
     "Tipo": "PJ",
     "Razão Social\/Nome": "BOS COMERCIO DE COSMETICOS LTDA",
     "Nome Fantasia\/Apelido": "AMAVIA",
     "CNPJ": "51.059.927\/0001-38",
     "CNAE Principal": "4772-5\/00",
     "Região": 21,
     "Situação": "Ativa",
     "Data Cadastro": "04\/09\/2023",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1969,
     "Tipo": "PJ",
     "Razão Social\/Nome": "JS CN GESTAO DE SAUDE LTDA",
     "Nome Fantasia\/Apelido": "D IMAGE CENTRO DE DIAGNOSTICOS",
     "CNPJ": "41.698.494\/0001-07",
     "CNAE Principal": "8660-7\/00",
     "Região": 23,
     "Situação": "Ativa",
     "Data Cadastro": "04\/09\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1970,
     "Tipo": "PF",
     "Razão Social\/Nome": "EGNALDO CAETANO DE OLIVEIRA",
     "Nome Fantasia\/Apelido": "EGNALDO CAETANO DE OLIVEIRA ",
     "CPF": "331.477.101-06",
     "CNAE Principal": 133499,
     "Região": 5,
     "Situação": "Ativa",
     "Data Cadastro": "05\/09\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1971,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CONSTRUTORA OLIVEIRA ANDRADE LTDA",
     "Nome Fantasia\/Apelido": "CONSTRUTORA OLIVEIRA ANDRADE ( LUZIMANGES)",
     "CNPJ": "21.791.639\/0001-02",
     "CNAE Principal": "4120-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "05\/09\/2023",
     "Estado": "TO",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1972,
     "Tipo": "PJ",
     "Razão Social\/Nome": "INOVE COMERCIO DE TINTAS LTDA",
     "Nome Fantasia\/Apelido": "INOVE CIMENTO QUEIMADO",
     "CNPJ": "40.705.945\/0001-23",
     "CNAE Principal": "4741-5\/00",
     "Região": 10,
     "Situação": "Ativa",
     "Data Cadastro": "11\/09\/2023",
     "Estado": "SC",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1973,
     "Tipo": "PJ",
     "Razão Social\/Nome": "VICENTE DE PAULA MOURA TRANSPORTES LTDA",
     "CNPJ": " 03.598.293\/0001-49",
     "CNAE Principal": "4921-3\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "02\/03\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1974,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ABG PLANTIOS E SOLUCOES AMBIENTAIS LTDA",
     "Nome Fantasia\/Apelido": "ABG COMERCIAL",
     "CNPJ": "39.330.398\/0001-60",
     "CNAE Principal": " 0142-3\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "08\/09\/2023",
     "Estado": "PR",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1975,
     "Tipo": "PF",
     "Razão Social\/Nome": "UMBERTO PEREIRA DA CRUZ CARDOSO",
     "Nome Fantasia\/Apelido": "FAZENDA BARRA DO DIA",
     "CPF": "117.212.861-87",
     "CNAE Principal": " 01.51-2-01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "09\/09\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1976,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DOMO AGRO SOLUTION LTDA",
     "Nome Fantasia\/Apelido": "DOMO AGRO SOLUTION",
     "CNPJ": "46.484.412\/0001-45",
     "CNAE Principal": "7319-0\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "11\/09\/2023",
     "Estado": "RS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1977,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MODRO CONSTRUCOES LTDA",
     "Nome Fantasia\/Apelido": "MODRO CONSTRUCOES",
     "CNPJ": "50.960.978\/0001-73",
     "CNAE Principal": "4330-4\/05",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "11\/09\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1978,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SOLMIF ENGENHARIA E ENERGIA SOLAR LTDA",
     "Nome Fantasia\/Apelido": "SOLMIF ENERGIA SOLAR",
     "CNPJ": "43.515.270\/0001-48",
     "CNAE Principal": "7112-0\/00",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "11\/09\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1979,
     "Tipo": "PJ",
     "Razão Social\/Nome": "AUTO PECAS COSTINHA LTDA",
     "Nome Fantasia\/Apelido": "AUTO PECAS COSTINHA",
     "CNPJ": " 00.319.468\/0001-43",
     "CNAE Principal": "4530-7\/04",
     "Região": 7,
     "Situação": "Ativa",
     "Data Cadastro": "11\/09\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1980,
     "Tipo": "PJ",
     "Razão Social\/Nome": "34.637.229 ANDERSON RODRIGO DOS SANTOS",
     "Nome Fantasia\/Apelido": "ARS MANUTENCAO E INSTALACOES INDUSTRIAIS",
     "CNPJ": "34.637.229\/0001-53",
     "CNAE Principal": "3321-0\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "11\/09\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1981,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ANTONIO RICARDO MANCINI 22211636802",
     "Nome Fantasia\/Apelido": "MANCINI SERVICE",
     "CNPJ": "38.165.433\/0001-70",
     "CNAE Principal": "4321-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "11\/09\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1982,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ADRIANO RAFAEL ODONTOLOGIA, ESTETICA E LABORATORIO DE PROTESE LTDA",
     "Nome Fantasia\/Apelido": "CLINICA ODONTOLOGICA DR ADRIANO",
     "CNPJ": "41.159.992\/0001-81",
     "CNAE Principal": "3250-7\/06",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "12\/09\/2023",
     "Estado": "MG",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1983,
     "Tipo": "PJ",
     "Razão Social\/Nome": "A M R BASTOS",
     "Nome Fantasia\/Apelido": "XEROCOPIAS",
     "CNPJ": " 03.959.151\/0001-60",
     "CNAE Principal": "8219-9\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "12\/09\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1984,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ASSOCIACAO RECREATIVA PINGUINHO DE GENTE",
     "CNPJ": "11.301.349\/0001-80",
     "CNAE Principal": "9430-8\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "08\/09\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1985,
     "Tipo": "PJ",
     "Razão Social\/Nome": "47.307.311 MARIO MOREIRA RESENDE FILHO",
     "Nome Fantasia\/Apelido": "MARTH SOLUCOES",
     "CNPJ": "47.307.311\/0001-61",
     "CNAE Principal": "3314-7\/19",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "15\/09\/2023",
     "Estado": "MG",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1986,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CONSTRUTORA OLIVEIRA ANDRADE LTDA",
     "Nome Fantasia\/Apelido": "CONSTRUTORA OLIVEIRA ANDRADE (ENVIOS)",
     "CNPJ": "21.791.639\/0001-02",
     "CNAE Principal": "4120-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "15\/09\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1987,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ADELCLEI - DISTRIBUIDORA E COMERCIO LTDA",
     "Nome Fantasia\/Apelido": "ADELCLEI",
     "CNPJ": "11.369.612\/0001-72",
     "CNAE Principal": "4784-9\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "15\/09\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1988,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MARCOS T DA SILVA COMERCIO",
     "Nome Fantasia\/Apelido": "AMERICA MODAS",
     "CNPJ": "17.948.476\/0001-51",
     "CNAE Principal": "4781-4\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "18\/09\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1989,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DESIGN COMUNICACAO VISUAL ITAPEVA LTDA",
     "Nome Fantasia\/Apelido": "DESIGN COMUNICACAO VISUAL",
     "CNPJ": "10.243.464\/0001-82",
     "CNAE Principal": "3299-0\/04",
     "Região": 18,
     "Situação": "Ativa",
     "Data Cadastro": "18\/09\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1990,
     "Tipo": "PJ",
     "Razão Social\/Nome": "JOSIRALDO ALEXANDRE SIQUEIRA",
     "Nome Fantasia\/Apelido": "FABRICA DE REBOQUES ITAPUY",
     "CNPJ": "34.361.964\/0001-87",
     "CNAE Principal": "3099-7\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "19\/09\/2023",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1991,
     "Tipo": "PJ",
     "Razão Social\/Nome": "PRECAL LTDA",
     "Nome Fantasia\/Apelido": "PRECAL ENGENHARIA",
     "CNPJ": "50.014.569\/0001-84",
     "CNAE Principal": "4330-4\/99",
     "Região": 20,
     "Situação": "Ativa",
     "Data Cadastro": "19\/09\/2023",
     "Estado": "MG",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1992,
     "Tipo": "PJ",
     "Razão Social\/Nome": "GUAIRA REAL MATERA COMERCIO LTDA",
     "Nome Fantasia\/Apelido": "GUAIRA REAL MATERA COMERCIO",
     "CNPJ": "42.274.398\/0001-02",
     "CNAE Principal": "46.42-7-02 ",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "19\/09\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1993,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ROSANA BEATRIZ OLIVEIRA CALDEIRA ANDRADE - ME",
     "Nome Fantasia\/Apelido": "CLINICA FISIO & SAUDE",
     "CNPJ": " 05.301.426\/0001-07",
     "CNAE Principal": "8650-0\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "10\/09\/2023",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1994,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ALMIR DA SILVA CRUZ 08395082479",
     "Nome Fantasia\/Apelido": "SILVA CONSTRUTORA E CONSULTORIA",
     "CNPJ": "29.548.584\/0001-16",
     "CNAE Principal": "4321-5\/00",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "21\/09\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 1995,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CONFECCOES AMERICANA COMERCIO DE ROUPAS LTDA",
     "Nome Fantasia\/Apelido": "AMERICA MODAS",
     "CNPJ": "42.005.782\/0001-00",
     "CNAE Principal": "4781-4\/00",
     "Região": 9,
     "Situação": "Ativa",
     "Data Cadastro": "22\/09\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Não"
    },
    {
     "Column1": 1996,
     "Tipo": "PJ",
     "Razão Social\/Nome": "45.425.810 TALITA ROJO MIRALDO",
     "Nome Fantasia\/Apelido": "PDVS MIX",
     "CNPJ": "45.425.810\/0001-28",
     "CNAE Principal": "47.13-0-02 -",
     "Região": 11,
     "Situação": "Ativa",
     "Data Cadastro": "22\/09\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Não"
    },
    {
     "Column1": 1997,
     "Tipo": "PJ",
     "Razão Social\/Nome": "IVO FELICIANI FERNANDES LTDA",
     "Nome Fantasia\/Apelido": "PLANTAR BEM",
     "CNPJ": "35.714.638\/0001-79",
     "CNAE Principal": " 0161-0\/03",
     "Região": 4,
     "Situação": "Ativa",
     "Data Cadastro": "25\/09\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Não"
    },
    {
     "Column1": 1998,
     "Tipo": "PJ",
     "Razão Social\/Nome": "M C P DA SILVA - SERVICOS",
     "Nome Fantasia\/Apelido": "CONEXAO GARDEN",
     "CNPJ": "33.622.625\/0001-44",
     "CNAE Principal": "8111-7\/00",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "25\/09\/2023",
     "Estado": "PR",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Não"
    },
    {
     "Column1": 1999,
     "Tipo": "PJ",
     "Razão Social\/Nome": "K.W.L TRANSPORTE DE CARGAS LTDA",
     "CNPJ": "18.082.091\/0001-17",
     "CNAE Principal": "4930-2\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "25\/09\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Não"
    },
    {
     "Column1": 2000,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ABGAIR CORREIA DOS SANTOS DIAS LTDA",
     "CNPJ": " 01.293.331\/0001-20",
     "CNAE Principal": "4744-0\/99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "25\/09\/2023",
     "Estado": "MT",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Não"
    },
    {
     "Column1": 2001,
     "Tipo": "PF",
     "Razão Social\/Nome": "EMANOEL GOMES BEZERRA JÚNIOR",
     "Nome Fantasia\/Apelido": "FAZENDA SÃO FRANCISCO",
     "CPF": "482.378.251-87",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "26\/09\/2023",
     "Estado": "MT",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Não"
    },
    {
     "Column1": 2002,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ELONGHI ENERGIA LTDA",
     "CNPJ": "50.136.346\/0001-90",
     "CNAE Principal": "4322-3\/01",
     "Região": 20,
     "Situação": "Ativa",
     "Data Cadastro": "26\/09\/2023",
     "Estado": "RS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Não"
    },
    {
     "Column1": 2003,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ONEL OUTSOURCING APOIO ADMINISTRATIVO LTDA (FILIAL)",
     "Nome Fantasia\/Apelido": "ONEL",
     "CNPJ": "11.939.225\/0002-05",
     "CNAE Principal": "82.19-9-99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "27\/09\/2023",
     "Estado": "MG",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Não"
    },
    {
     "Column1": 2004,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ONEL OUTSOURCING APOIO ADMINISTRATIVO LTDA ( FILIAL",
     "CNPJ": "11.939.225\/0003-96",
     "CNAE Principal": "82.19-9-99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "28\/09\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Não"
    },
    {
     "Column1": 2005,
     "Tipo": "PJ",
     "Razão Social\/Nome": "AUTO POSTO STELLA MARIS II LTDA",
     "CNPJ": "17.978.430\/0001-85",
     "CNAE Principal": "4731-8\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "28\/09\/2023",
     "Estado": "PR",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Não"
    },
    {
     "Column1": 2006,
     "Tipo": "PF",
     "Razão Social\/Nome": "JONATAS SOUZA DIAS",
     "CPF": "246.115.062-15",
     "CNAE Principal": 139305,
     "Região": 23,
     "Situação": "Ativa",
     "Data Cadastro": "28\/09\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Não"
    },
    {
     "Column1": 2007,
     "Tipo": "PF",
     "Razão Social\/Nome": "MARIA LUIZA DE ALVARENGA BUENO",
     "Nome Fantasia\/Apelido": "FAZENDA SANTA MARIA",
     "CPF": "246.059.358-94",
     "CNAE Principal": "151201 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "02\/10\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Não"
    },
    {
     "Column1": 2008,
     "Tipo": "PJ",
     "Razão Social\/Nome": "TAINA TERRA DE MATOS SANTOS - ME",
     "Nome Fantasia\/Apelido": "CEREALISTA SAO JOSE",
     "CNPJ": "48.212.859\/0001-90",
     "CNAE Principal": "4712-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "24\/09\/2023",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Não"
    },
    {
     "Column1": 2009,
     "Tipo": "PJ",
     "Razão Social\/Nome": "JONES ISMAEL GENZ INSTALACAO DE GERADORES FOTOVOLTAICOS",
     "Nome Fantasia\/Apelido": "JIG SOLAR",
     "CNPJ": "30.942.307\/0001-72",
     "CNAE Principal": "3321-0\/00",
     "Região": 20,
     "Situação": "Ativa",
     "Data Cadastro": "04\/10\/2023",
     "Estado": "SC",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Não"
    },
    {
     "Column1": 2010,
     "Tipo": "PJ",
     "Razão Social\/Nome": "PLANALTO LABORATORIO DE ANALISES CLINICAS LTDA",
     "Nome Fantasia\/Apelido": "PLANALTO LABORATORIO DE ANALISES CLINICAS",
     "CNPJ": "11.656.647\/0001-92",
     "CNAE Principal": "8640-2\/02",
     "Região": 6,
     "Situação": "Ativa",
     "Data Cadastro": "04\/10\/2023",
     "Estado": "SC",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Não"
    },
    {
     "Column1": 2011,
     "Tipo": "PJ",
     "Razão Social\/Nome": "PABLO HENRIQUE GONCALVES 46027473886",
     "CNPJ": "47.967.969\/0001-08",
     "CNAE Principal": "4321-5\/00",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "06\/10\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Não"
    },
    {
     "Column1": 2012,
     "Tipo": "PJ",
     "Razão Social\/Nome": "AR2 AVALIACOES E PERICIAS DE ENGENHARIA LTDA",
     "Nome Fantasia\/Apelido": "AR2 AVALIACOES",
     "CNPJ": "22.455.990\/0001-95",
     "CNAE Principal": "7112-0\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "08\/10\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Não"
    },
    {
     "Column1": 2013,
     "Tipo": "PF",
     "Razão Social\/Nome": "DAYANE MASCENO DA SILVA",
     "Nome Fantasia\/Apelido": "FAZ. ALTO BONITO",
     "CPF": " 039.449.701-52",
     "CNAE Principal": "151201 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "09\/10\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Não"
    },
    {
     "Column1": 2014,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SILVIO KSZESINSKI MAINKA",
     "Nome Fantasia\/Apelido": "HIDROBOMBAS",
     "CNPJ": " 03.327.028\/0001-26",
     "CNAE Principal": "4744-0\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "10\/10\/2023",
     "Estado": "RS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Não"
    },
    {
     "Column1": 2015,
     "Tipo": "PJ",
     "Razão Social\/Nome": "W SOTHERI",
     "Nome Fantasia\/Apelido": "SORVETINHO",
     "CNPJ": "12.522.096\/0001-37",
     "CNAE Principal": "4712-1\/00",
     "Região": 17,
     "Situação": "Ativa",
     "Data Cadastro": "10\/10\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Não"
    },
    {
     "Column1": 2016,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MAR SUL MACAE PESCADOS LTDA",
     "CNPJ": "51.553.150\/0001-63",
     "CNAE Principal": "1020-1\/02",
     "Região": 17,
     "Situação": "Ativa",
     "Data Cadastro": "10\/10\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Não"
    },
    {
     "Column1": 2017,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SERRA CAIADA CAMARA MUNICIPAL",
     "Nome Fantasia\/Apelido": "CAMARA MUNICIPAL PRESIDENTE JUSCELINO",
     "CNPJ": " 09.428.533\/0001-43",
     "CNAE Principal": "8411-6\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "11\/10\/2023",
     "Estado": "RN",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Não"
    },
    {
     "Column1": 2018,
     "Tipo": "PJ",
     "Razão Social\/Nome": "NOBELS PHG COMERCIO DE CONSUMIVEIS LTDA",
     "Nome Fantasia\/Apelido": "NOBELS",
     "CNPJ": "20.928.088\/0001-13",
     "CNAE Principal": "4713-0\/04",
     "Região": 17,
     "Situação": "Ativa",
     "Data Cadastro": "17\/10\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2019,
     "Tipo": "PJ",
     "Razão Social\/Nome": "BOA SAFRA IND E COM DE FERTILIZANTES LTDA",
     "Nome Fantasia\/Apelido": "BOA SAFRA",
     "CNPJ": "26.672.980\/0008-39",
     "CNAE Principal": "4683-4\/00",
     "Região": 6,
     "Situação": "Ativa",
     "Data Cadastro": "17\/10\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2020,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MULBAUER OLIVEIRA AMBIENTES CORPORATIVOS LTDA",
     "Nome Fantasia\/Apelido": "MBOFFICE AMBIENTES CORPORATIVOS",
     "CNPJ": "19.657.056\/0001-41",
     "CNAE Principal": "3329-5\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "12\/10\/2023",
     "Estado": "RS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2021,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SONIA REGINA SANCHES 76416240653",
     "Nome Fantasia\/Apelido": "SABOR DE CASA",
     "CNPJ": "24.458.050\/0001-85",
     "CNAE Principal": "5611-2\/03",
     "Região": 22,
     "Situação": "Ativa",
     "Data Cadastro": "12\/10\/2023",
     "Estado": "MG",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2022,
     "Tipo": "PJ",
     "Razão Social\/Nome": "EVA FRANCISCA DA SILVA - ME",
     "Nome Fantasia\/Apelido": "MERCEARIA MATOS",
     "CNPJ": " 05.844.242\/0001-85",
     "CNAE Principal": "4712-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "07\/10\/2023",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2023,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ACC DE SOUZA LTDA",
     "Nome Fantasia\/Apelido": "BIOATLAS DO BRASIL",
     "CNPJ": "31.306.492\/0001-71",
     "CNAE Principal": "4693-1\/00",
     "Região": 17,
     "Situação": "Ativa",
     "Data Cadastro": "15\/10\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2024,
     "Tipo": "PJ",
     "Razão Social\/Nome": "AREIAO JAVAE LTDA",
     "Nome Fantasia\/Apelido": "AREIAO JAVAE",
     "CNPJ": "26.209.582\/0001-04",
     "CNAE Principal": "4744-0\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "16\/10\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2025,
     "Tipo": "PJ",
     "Razão Social\/Nome": "M F PEREIRA",
     "Nome Fantasia\/Apelido": "MN SERVICOS AGRICOLAS",
     "CNPJ": "46.991.350\/0001-68",
     "CNAE Principal": " 01.61-0-99 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "16\/10\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2027,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MENEZES COMERCIO E REPRESENTACOES LTDA",
     "Nome Fantasia\/Apelido": "MENEZES COLCHOES E ESTOFADOS",
     "CNPJ": "50.252.314\/0001-50",
     "CNAE Principal": "4713-0\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "16\/10\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2028,
     "Tipo": "PF",
     "Razão Social\/Nome": "IVO FELICIANI FERNANDES",
     "Nome Fantasia\/Apelido": "FAZENDA PANTANAL DE CIMA",
     "CPF": "431.559.101-78",
     "CNAE Principal": "111301 ",
     "Região": 4,
     "Situação": "Ativa",
     "Data Cadastro": "17\/10\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2029,
     "Tipo": "PJ",
     "Razão Social\/Nome": "P S DE SOUZA CONSULTORIA & SERVICOS LTDA",
     "Nome Fantasia\/Apelido": "P S DE SOUZA",
     "CNPJ": "34.820.921\/0001-12",
     "CNAE Principal": "6920-6\/01",
     "Região": 17,
     "Situação": "Ativa",
     "Data Cadastro": "17\/10\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2030,
     "Tipo": "PJ",
     "Razão Social\/Nome": "UFTA JOSE VARIEDADES LTDA",
     "CNPJ": "19.768.814\/0001-07",
     "CNAE Principal": "4713-0\/02",
     "Região": 17,
     "Situação": "Ativa",
     "Data Cadastro": "18\/10\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2031,
     "Tipo": "PF",
     "Razão Social\/Nome": "WILTON COSTA E SILVA ",
     "Nome Fantasia\/Apelido": "FAZENDA PANTANAL DE CIMA",
     "CPF": "451.741.301-25",
     "CNAE Principal": "111301 ",
     "Região": 4,
     "Situação": "Ativa",
     "Data Cadastro": "18\/10\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2032,
     "Tipo": "PJ",
     "Razão Social\/Nome": "UFTA JOSE VARIEDADES LTDA",
     "CNPJ": "19.768.814\/0002-80",
     "CNAE Principal": "4713-0\/02",
     "Região": 17,
     "Situação": "Ativa",
     "Data Cadastro": "18\/10\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2033,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ABTECK ELETROTECNICA LTDA",
     "Nome Fantasia\/Apelido": "ABTECH",
     "CNPJ": "38.462.985\/0001-40",
     "CNAE Principal": "4321-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "18\/10\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2034,
     "Tipo": "PJ",
     "Razão Social\/Nome": "VANESSA FERNANDES CIFONI",
     "Nome Fantasia\/Apelido": "VAN GOURMET EVENTOS & GASTRONOMIA",
     "CNPJ": "20.835.539\/0001-78",
     "CNAE Principal": "8230-0\/01",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "18\/10\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2035,
     "Tipo": "PJ",
     "Razão Social\/Nome": "DMENSURE GEOTECNOLOGIA LTDA",
     "Nome Fantasia\/Apelido": "DMENSURE GEOTECNOLOGIA",
     "CNPJ": "52.399.810\/0001-66",
     "CNAE Principal": "71.19-7-01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "03\/10\/2023",
     "Estado": "MT",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2036,
     "Tipo": "PF",
     "Razão Social\/Nome": "BALTAZAR ALVES DE MACEDO",
     "Nome Fantasia\/Apelido": "FAZENDA ÁGUA  BOA ",
     "CPF": " 044.989.681-15",
     "CNAE Principal": 151201,
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "19\/10\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2037,
     "Tipo": "PJ",
     "Razão Social\/Nome": "BIOAGRO, BIOENERGIA E AGRONEGOCIO SUSTENTAVEL LTDA",
     "Nome Fantasia\/Apelido": "BIOAGRO",
     "CNPJ": "41.735.544\/0001-89",
     "CNAE Principal": "4687-7\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "19\/10\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2038,
     "Tipo": "PJ",
     "Razão Social\/Nome": "NALIANE K. ZENLY EL CHUFI CLINICA ODONTOLOGICA LTDA.",
     "Nome Fantasia\/Apelido": "DRA. NALIANE K. ZENLY - CLINICA ODONTOLOGICA",
     "CNPJ": "10.999.121.0001-42",
     "CNAE Principal": "8630-5\/04",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "19\/10\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2039,
     "Tipo": "PJ",
     "Razão Social\/Nome": "RESTAURANTE FIGUEIRAL LTDA",
     "Nome Fantasia\/Apelido": "RESTAURANTE FIGUEIRAL LTDA",
     "CNPJ": "17.336.045\/0001-34",
     "CNAE Principal": "5611-2\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "20\/10\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2040,
     "Tipo": "PJ",
     "Razão Social\/Nome": "GUIDO RAMPINI",
     "Nome Fantasia\/Apelido": "GUIDO DIVISORIAS",
     "CNPJ": " 07.875.830\/0001-01",
     "CNAE Principal": "4754-7\/01",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "23\/10\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2041,
     "Tipo": "PJ",
     "Razão Social\/Nome": "TERMIL TERMO INSTALACOES LIMITADA",
     "CNPJ": "16.714.263\/0001-00",
     "CNAE Principal": "3311-2\/00",
     "Região": 18,
     "Situação": "Ativa",
     "Data Cadastro": "23\/10\/2023",
     "Estado": "MG",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2042,
     "Tipo": "PF",
     "Razão Social\/Nome": "YAGO MIKAEL PEREIRA DOS SANTOS",
     "CPF": " 054.249.201-60",
     "CNAE Principal": 115600,
     "Região": 4,
     "Situação": "Ativa",
     "Data Cadastro": "23\/10\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2043,
     "Tipo": "PJ",
     "Razão Social\/Nome": "VARZEA CAMARA MUNICIPAL",
     "Nome Fantasia\/Apelido": "CAMARA MUNICIPAL DE VARZEA",
     "CNPJ": "40.800.625\/0001-52",
     "CNAE Principal": "8411-6\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "23\/10\/2023",
     "Estado": "RN",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2044,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ANJU EMPREENDIMENTOS E ENGENHARIA LTDA",
     "CNPJ": "43.240.442\/0001-18",
     "CNAE Principal": "7112-0\/00",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "24\/10\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2046,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MARCIO COELHO RODRIGUES",
     "Nome Fantasia\/Apelido": "AUTOMOTIVA SMART",
     "CNPJ": "26.746.219\/0001-28",
     "CNAE Principal": "4530-7\/03",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "29\/10\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2047,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MARIAS EMBALAGENS LTDA",
     "Nome Fantasia\/Apelido": "MARIAS EMBALAGENS",
     "CNPJ": "10.218.729\/0001-92",
     "CNAE Principal": "4761-0\/03",
     "Região": 6,
     "Situação": "Ativa",
     "Data Cadastro": "30\/10\/2023",
     "Estado": "DF",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2049,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ROBERTO MINOR MATSUMOTO TRANSPORTES LTDA",
     "CNPJ": " 05.230.979\/0001-08",
     "CNAE Principal": "4921-3\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "31\/10\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2050,
     "Tipo": "PJ",
     "Razão Social\/Nome": "VICENTE PEREIRA RAMOS TRANSPORTE LTDA",
     "CNPJ": " 04.758.398\/0001-80",
     "CNAE Principal": "4921-3\/02",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "31\/10\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2051,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MAGAZINE VITORLEON LTDA FILIAL",
     "CNPJ": " 05.230.786\/0002-38",
     "CNAE Principal": "4781-4\/00",
     "Região": 17,
     "Situação": "Ativa",
     "Data Cadastro": "01\/11\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2052,
     "Tipo": "PJ",
     "Razão Social\/Nome": "AGF ESPORTES E COMERCIO LTDA",
     "Nome Fantasia\/Apelido": "CENTRO HIPICO LAGO SUL",
     "CNPJ": "46.142.696\/0001-91",
     "CNAE Principal": "8591-1\/00",
     "Região": 6,
     "Situação": "Ativa",
     "Data Cadastro": "01\/11\/2023",
     "Estado": "DF",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2053,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MULUNGU CALCADOS E CONFECCOES LTDA",
     "Nome Fantasia\/Apelido": "MANDACARU CALCADOS E CONFECCOES",
     "CNPJ": "36.126.735\/0001-03",
     "CNAE Principal": "4782-2\/01",
     "Região": 4,
     "Situação": "Ativa",
     "Data Cadastro": "01\/11\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2054,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SEMPRE LAR VARIEDADES LTDA",
     "Nome Fantasia\/Apelido": "SEMPRE LAR",
     "CNPJ": " 02.552.969\/0001-09",
     "CNAE Principal": "4713-0\/02",
     "Região": 17,
     "Situação": "Ativa",
     "Data Cadastro": "01\/11\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2055,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SAPATARIA DOIS PES LTDA",
     "Nome Fantasia\/Apelido": "STOMP",
     "CNPJ": " 03.416.903\/0001-46",
     "CNAE Principal": "4763-6\/02",
     "Região": 17,
     "Situação": "Ativa",
     "Data Cadastro": "01\/11\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2056,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MAGAZINE VITORLEON LTDA",
     "Nome Fantasia\/Apelido": "RESUMO DA MODA",
     "CNPJ": " 05.230.786\/0001-57",
     "CNAE Principal": "4781-4\/00",
     "Região": 17,
     "Situação": "Ativa",
     "Data Cadastro": "01\/11\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2057,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MAURO BENEDITO GOMES 05787780841",
     "Nome Fantasia\/Apelido": "GOMES ELETRICISTA",
     "CNPJ": "33.019.770\/0001-35",
     "CNAE Principal": "4321-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "03\/11\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2058,
     "Tipo": "PJ",
     "Razão Social\/Nome": "C P F ENGENHARIA LTDA",
     "Nome Fantasia\/Apelido": "C P F ENGENHARIA",
     "CNPJ": "39.811.554\/0001-04",
     "CNAE Principal": "7112-0\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "03\/11\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2059,
     "Tipo": "PJ",
     "Razão Social\/Nome": "J P SARAIVA MIRANDA LTDA",
     "Nome Fantasia\/Apelido": "CEREALISTA DEUS E FIEL",
     "CNPJ": "37.781.041\/0001-72",
     "CNAE Principal": "4712-1\/00",
     "Região": 6,
     "Situação": "Ativa",
     "Data Cadastro": "01\/11\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2060,
     "Tipo": "PJ",
     "Razão Social\/Nome": "JOHN AXE ENGENHARIA LTDA",
     "CNPJ": "18.237.928\/0001-50",
     "CNAE Principal": "7112-0\/00",
     "Região": 22,
     "Situação": "Ativa",
     "Data Cadastro": "06\/11\/2023",
     "Estado": "RS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2061,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ED CARLOS. A. S. DE ANDRADE ENGENHARIA",
     "Nome Fantasia\/Apelido": "ELETRICAL SERVICES",
     "CNPJ": "37.154.864\/0001-78",
     "CNAE Principal": "4321-5\/00",
     "Região": 22,
     "Situação": "Ativa",
     "Data Cadastro": "06\/11\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2062,
     "Tipo": "PJ",
     "Razão Social\/Nome": "JOSE DA CUNHA PIO IRMAO LTDA",
     "Nome Fantasia\/Apelido": "J CUNHA TRANSPORTES ALTERNATIVO",
     "CNPJ": "68.933.456\/0001-48",
     "CNAE Principal": "4921-3\/02",
     "Região": 6,
     "Situação": "Ativa",
     "Data Cadastro": "06\/11\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2063,
     "Tipo": "PJ",
     "Razão Social\/Nome": "S W & M SOLUCOES ELETRICAS ENGENHARIA LTDA",
     "CNPJ": "14.846.402\/0001-16",
     "CNAE Principal": "7112-0\/00",
     "Região": 22,
     "Situação": "Ativa",
     "Data Cadastro": "07\/11\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2064,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MOREIRA SOLUCOES ADMINISTRATIVAS LTDA",
     "CNPJ": "42.615.598\/0001-73",
     "CNAE Principal": "8291-1\/00",
     "Região": 21,
     "Situação": "Ativa",
     "Data Cadastro": "08\/11\/2023",
     "Estado": "GO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2065,
     "Tipo": "PF",
     "Razão Social\/Nome": "SITIO SANTO ANTONIO",
     "CPF": "179.429.852-53",
     "CNAE Principal": "15.12.01",
     "Região": 23,
     "Situação": "Ativa",
     "Data Cadastro": "08\/11\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2066,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MARIA A. F. DOS SANTOS",
     "Nome Fantasia\/Apelido": "BIG BIG CONVENIENCIA",
     "CNPJ": "23.142.394\/0001-18",
     "CNAE Principal": "4712-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "08\/11\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2067,
     "Tipo": "PJ",
     "Razão Social\/Nome": "RRP HOLANDA & SUASSUNA LTDA",
     "Nome Fantasia\/Apelido": "EMAGRECENTRO DIONISIO TORRES",
     "CNPJ": "52.485.546\/0001-83",
     "CNAE Principal": "86.90-9-01",
     "Região": 6,
     "Situação": "Ativa",
     "Data Cadastro": "08\/11\/2023",
     "Estado": "CE",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2068,
     "Tipo": "PJ",
     "Razão Social\/Nome": "GREEN HOUSE LTDA",
     "Nome Fantasia\/Apelido": "GREEN HOUSE ",
     "CNPJ": "14.622.213\/0002-40",
     "CNAE Principal": "38.21-1-00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "09\/11\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2069,
     "Tipo": "PJ",
     "Razão Social\/Nome": "GREEN HOUSE LTDA",
     "CNPJ": "14.622.213\/0001-60",
     "CNAE Principal": "7112-0\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "09\/11\/2023",
     "Estado": "SP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2070,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SOBA CONSTRUCAO CIVIL LTDA",
     "Nome Fantasia\/Apelido": "SOBA CONSTRUCAO CIVIL",
     "CNPJ": " 02.943.858\/0001-15",
     "CNAE Principal": "4399-1\/03",
     "Região": 20,
     "Situação": "Ativa",
     "Data Cadastro": "10\/11\/2023",
     "Estado": "PR",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2071,
     "Tipo": "PJ",
     "Razão Social\/Nome": "EDUARDO GIACHIN TEDESCO 02624064036",
     "Nome Fantasia\/Apelido": "TEDESCO SOLDAS",
     "CNPJ": "28.198.361\/0001-03",
     "CNAE Principal": "2539-0\/01",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "10\/11\/2023",
     "Estado": "RS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2072,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MEP CONSTRUCOES E PROJETOS LTDA",
     "Nome Fantasia\/Apelido": "OXXYGENIUM PROJETOS",
     "CNPJ": "22.777.180\/0001-55",
     "CNAE Principal": "4120-4\/00",
     "Região": 1,
     "Situação": "Ativa",
     "Data Cadastro": "10\/11\/2023",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2073,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CUPCAKE DE MACAE COMERCIO DE ROUPAS LTDA",
     "Nome Fantasia\/Apelido": "CUPCAKE",
     "CNPJ": " 04.687.368\/0001-20",
     "CNAE Principal": "4781-4\/00",
     "Região": 17,
     "Situação": "Ativa",
     "Data Cadastro": "13\/11\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2074,
     "Tipo": "PJ",
     "Razão Social\/Nome": "RIO LOURO LOCACAO DE MAQUINAS E EQUIPAMENTOS LTDA",
     "Nome Fantasia\/Apelido": "RIO LOURO LOCACOES",
     "CNPJ": " 07.328.492\/0001-98",
     "CNAE Principal": "7739-0\/99",
     "Região": 17,
     "Situação": "Ativa",
     "Data Cadastro": "13\/11\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2075,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CLINICA DE EMAGRECIMENTO SPBC LTDA",
     "Nome Fantasia\/Apelido": "EMAGRECENTRO BARRETOS",
     "CNPJ": "46.921.547\/0001-20",
     "CNAE Principal": "8690-9\/01",
     "Região": 6,
     "Situação": "Ativa",
     "Data Cadastro": "13\/11\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2076,
     "Tipo": "PF",
     "Razão Social\/Nome": "SITIO GUIMARAES",
     "CPF": "756.734.952-34",
     "Região": 23,
     "Situação": "Ativa",
     "Data Cadastro": "13\/11\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2077,
     "Tipo": "PJ",
     "Razão Social\/Nome": "FD MONTAGEM E SERVICOS LTDA",
     "Nome Fantasia\/Apelido": "FENIX MONTAGEM E SERVICOS INDUSTRIAIS",
     "CNPJ": "51.991.276\/0001-10",
     "CNAE Principal": "42.92-8-01 ",
     "Região": 23,
     "Situação": "Ativa",
     "Data Cadastro": "13\/11\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2078,
     "Tipo": "PJ",
     "Razão Social\/Nome": "VRS TERRAPLENAGEM LTDA",
     "Nome Fantasia\/Apelido": "GL DISTRIBUIDORA DE BEBIDAS",
     "CNPJ": "50.643.127\/0001-05",
     "CNAE Principal": "43.13-4-00 -",
     "Região": 4,
     "Situação": "Ativa",
     "Data Cadastro": "16\/11\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2079,
     "Tipo": "PJ",
     "Razão Social\/Nome": "PADILHA E NASCIMENTO CAFETERIA LTDA",
     "Nome Fantasia\/Apelido": "MAIS1 CAFE",
     "CNPJ": "52.393.495.0001\/60",
     "CNAE Principal": "56.11-2-03 ",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "16\/11\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2080,
     "Tipo": "PJ",
     "Razão Social\/Nome": "BENJAMIN EDMO SANTOS CABRAL 06263544988",
     "Nome Fantasia\/Apelido": "SPIDER ACESSO POR CORDAS",
     "CNPJ": "23.003.451\/0001-88",
     "CNAE Principal": "7721-7\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "17\/11\/2023",
     "Estado": "SC",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2081,
     "Tipo": "PJ",
     "Razão Social\/Nome": "SKINA COMERCIO VAREJISTA DE GAS E FERRAGENS LTDA",
     "Nome Fantasia\/Apelido": "SKINA GAS & FERRAGENS",
     "CNPJ": " 08.279.455\/0001-08",
     "CNAE Principal": "4784-9\/00",
     "Região": 4,
     "Situação": "Ativa",
     "Data Cadastro": "20\/11\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2082,
     "Tipo": "PJ",
     "Razão Social\/Nome": "RAFAEL M. MARTINS SOARES",
     "Nome Fantasia\/Apelido": "ELITE ACADEMIA",
     "CNPJ": "36.312.983\/0001-49",
     "CNAE Principal": "9313-1\/00",
     "Região": 1,
     "Situação": "Ativa",
     "Data Cadastro": "20\/11\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2083,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CINTHIA CAROLINA RODRIGUES DA SILVA 09754886601",
     "Nome Fantasia\/Apelido": "CJ GESSO",
     "CNPJ": "36.138.633\/0001-08",
     "CNAE Principal": "4330-4\/03",
     "Região": 21,
     "Situação": "Ativa",
     "Data Cadastro": "20\/11\/2023",
     "Estado": "MG",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2084,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MANOEL DA FONSECA SANTOS 83676619153",
     "CNPJ": "19.185.181\/0001-04",
     "CNAE Principal": "4930-2\/01",
     "Região": 4,
     "Situação": "Ativa",
     "Data Cadastro": "21\/11\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2085,
     "Tipo": "PJ",
     "Razão Social\/Nome": "TELMA DE SOUZA ALVES 05020958816",
     "CNPJ": "20.431.608\/0001-88",
     "CNAE Principal": "8230-0\/01",
     "Região": 20,
     "Situação": "Ativa",
     "Data Cadastro": "21\/11\/2023",
     "Estado": "MS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2086,
     "Tipo": "PJ",
     "Razão Social\/Nome": "41.342.893 JOSE DE JESUS BRAGA MARTEL JUNIOR - ME",
     "Nome Fantasia\/Apelido": "JAT MARTEL ENGENHARIA ELETRICA",
     "CNPJ": "41.342.893\/0001-30",
     "CNAE Principal": "4321-5\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "22\/11\/2023",
     "Estado": "PR",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2087,
     "Tipo": "PJ",
     "Razão Social\/Nome": "JOAO MANUEL DE FIGUEIREDO DOS SANTOS LIMITADA",
     "Nome Fantasia\/Apelido": "JR-SYSTEMS",
     "CNPJ": "24.789.627\/0001-31",
     "CNAE Principal": "2330-3\/99",
     "Região": 21,
     "Situação": "Ativa",
     "Data Cadastro": "22\/11\/2023",
     "Estado": "GO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2088,
     "Tipo": "PJ",
     "Razão Social\/Nome": "NEW LINE AMBIENTES PLANEJADOS LTDA",
     "Nome Fantasia\/Apelido": "NEW LINE PLANEJADOS",
     "CNPJ": "49.164.804\/0001-15",
     "CNAE Principal": "4754-7\/01",
     "Região": 21,
     "Situação": "Ativa",
     "Data Cadastro": "22\/11\/2023",
     "Estado": "GO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2089,
     "Tipo": "PJ",
     "Razão Social\/Nome": "BRAS DOMINGOS DA SILVA TRANSPORTES LTDA",
     "CNPJ": " 03.643.837\/0001-47",
     "CNAE Principal": "4921-3\/02",
     "Região": 6,
     "Situação": "Ativa",
     "Data Cadastro": "14\/11\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2090,
     "Tipo": "PJ",
     "Razão Social\/Nome": "VALDIR DE OLIVEIRA NEVES - ME",
     "Nome Fantasia\/Apelido": "INGRID NACIONAL E IMPORTADOS",
     "CNPJ": "46.745.104\/0001-26",
     "CNAE Principal": "4763-6\/01",
     "Região": 6,
     "Situação": "Ativa",
     "Data Cadastro": "15\/11\/2023",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2091,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CLOVIS DA SILVA LOPES 90546466087",
     "Nome Fantasia\/Apelido": "C LOPES MANUTENCAO",
     "CNPJ": "23.231.139\/0001-41",
     "CNAE Principal": "3329-5\/01",
     "Região": 20,
     "Situação": "Ativa",
     "Data Cadastro": "23\/11\/2023",
     "Estado": "RS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2092,
     "Tipo": "PJ",
     "Razão Social\/Nome": "JIRIPOCA HOTEL LTDA",
     "Nome Fantasia\/Apelido": "JIRIPOCA HOTEL",
     "CNPJ": " 07.428.090\/0001-65",
     "CNAE Principal": "5510-8\/01",
     "Região": 4,
     "Situação": "Ativa",
     "Data Cadastro": "23\/11\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2093,
     "Tipo": "PJ",
     "Razão Social\/Nome": "52.902.779 DERLON PEREIRA DE MOURA - ME",
     "CNPJ": "52.902.779\/0001-34",
     "CNAE Principal": "33.11-2-00",
     "Região": 6,
     "Situação": "Ativa",
     "Data Cadastro": "24\/11\/2023",
     "Estado": "AL",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2094,
     "Tipo": "PJ",
     "Razão Social\/Nome": "LEL GARCIA ENGENHARIA LTDA",
     "Nome Fantasia\/Apelido": "OCEANO ENGENHARIA",
     "CNPJ": " 01.016.038\/0001-15",
     "CNAE Principal": "7112-0\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "24\/11\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2095,
     "Tipo": "PF",
     "Razão Social\/Nome": "FRANCISCO NOGUEIRA GAMA",
     "Nome Fantasia\/Apelido": "FAZENDA SÃO FRANCISCO",
     "CPF": " 017.411.871-68",
     "CNAE Principal": 151201,
     "Região": 4,
     "Situação": "Ativa",
     "Data Cadastro": "26\/11\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2096,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CLINICA DE EMAGRECIMENTO INTEGRATIVO COM APARELHOS PATRIARCA LTDA",
     "Nome Fantasia\/Apelido": "EMAGRECE PATRIARCA",
     "CNPJ": "30.379.862\/0001-38",
     "CNAE Principal": "8690-9\/01",
     "Região": 5,
     "Situação": "Ativa",
     "Data Cadastro": "27\/11\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2097,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ASSOCIACAO DE PAIS E AMIGOS DOS EXCEPCIONAIS",
     "Nome Fantasia\/Apelido": "APAE DE FORTALEZA DO TABOCAO-TO",
     "CNPJ": " 07.351.803\/0001-30",
     "CNAE Principal": "8800-6\/00",
     "Região": 1,
     "Situação": "Ativa",
     "Data Cadastro": "28\/11\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2098,
     "Tipo": "PJ",
     "Razão Social\/Nome": "EVERTON TEIXEIRA 06602208900",
     "Nome Fantasia\/Apelido": "MIDAS ENGENHARIA",
     "CNPJ": "24.928.444\/0001-50",
     "CNAE Principal": "4330-4\/04",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "29\/11\/2023",
     "Estado": "PR",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2099,
     "Tipo": "PJ",
     "Razão Social\/Nome": "NOVA PONTUAL MANUTENCOES E INSTALACOES LTDA",
     "Nome Fantasia\/Apelido": "NOVA PONTUAL MANUTENCOES E INSTALACOES",
     "CNPJ": "45.227.186\/0001-54",
     "CNAE Principal": "4330-4\/99",
     "Região": 1,
     "Situação": "Ativa",
     "Data Cadastro": "29\/11\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2100,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MEDYCOM PRODUTOS MEDICOS E HOSPITALARES LTDA",
     "Nome Fantasia\/Apelido": "MEDYCOM PRODUTOS MEDICOS E HOSPITALARES",
     "CNPJ": "21.392.644\/0001-42",
     "CNAE Principal": "4645-1\/01",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "29\/11\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2101,
     "Tipo": "PJ",
     "Razão Social\/Nome": "FABIO MOTA GOMES - CPF 03690177650",
     "Nome Fantasia\/Apelido": "ART & ACO SERRALHERIA",
     "CNPJ": "12.384.016\/0001-24",
     "CNAE Principal": "2512-8\/00",
     "Região": 1,
     "Situação": "Ativa",
     "Data Cadastro": "29\/11\/2023",
     "Estado": "MG",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2102,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CONGELADISSIMOS COM SABOR LTDA",
     "CNPJ": "18.705.825\/0004-10",
     "CNAE Principal": "47.29-6-99",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "29\/11\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2103,
     "Tipo": "PJ",
     "Razão Social\/Nome": "CONGELADISSIMOS COM SABOR LTDA",
     "Nome Fantasia\/Apelido": "CONGELADISSIMOS COM SABOR",
     "CNPJ": "18.705.825\/0001-77",
     "CNAE Principal": "1096-1\/00",
     "Região": " 0",
     "Situação": "Ativa",
     "Data Cadastro": "30\/11\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2104,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ELETROPOWER INSTALACAO E MANUTENCAO ELETRICA LTDA",
     "Nome Fantasia\/Apelido": "ELETROPOWER",
     "CNPJ": "50.307.746\/0001-10",
     "CNAE Principal": "4321-5\/00",
     "Região": 6,
     "Situação": "Ativa",
     "Data Cadastro": "01\/12\/2023",
     "Estado": "PA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2105,
     "Tipo": "PJ",
     "Razão Social\/Nome": "R J S ARAUJO SERVICOS TECNICOS",
     "Nome Fantasia\/Apelido": "ALIMAR RJ REVESTIMENTOS ESPECIAIS PINTURAS E IMPERMEABI",
     "CNPJ": "41.991.676\/0001-71",
     "CNAE Principal": "4330-4\/01",
     "Região": 1,
     "Situação": "Ativa",
     "Data Cadastro": "03\/12\/2023",
     "Estado": "RJ",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2106,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MARGARIDA CONCEICAO SANTOS",
     "Nome Fantasia\/Apelido": "OILA CONSTRUTORA",
     "CNPJ": "45.674.242\/0001-07",
     "CNAE Principal": "4299-5\/99",
     "Região": 18,
     "Situação": "Ativa",
     "Data Cadastro": "03\/12\/2023",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2107,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MR SOUZA SEGURANCA E SERVICOS LTDA",
     "Nome Fantasia\/Apelido": "MR SOUZA SEGURANCA E SERVICOS",
     "CNPJ": "53.031.679\/0001-42",
     "Região": 22,
     "Situação": "Ativa",
     "Data Cadastro": "04\/12\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2108,
     "Tipo": "PJ",
     "Razão Social\/Nome": "53.053.494 LUIS ALBERTO YANSE DIAZ",
     "CNPJ": "53.053.494\/0001-39",
     "CNAE Principal": "43.21-5-00",
     "Região": 20,
     "Situação": "Ativa",
     "Data Cadastro": "05\/12\/2023",
     "Estado": "PR",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2109,
     "Tipo": "PJ",
     "Razão Social\/Nome": "A G TRANSPORTES LTDA",
     "Nome Fantasia\/Apelido": "A G LOGISTICA E TRANSPORTE",
     "CNPJ": "40.117.523\/0001-37",
     "CNAE Principal": "4930-2\/02",
     "Região": 20,
     "Situação": "Ativa",
     "Data Cadastro": "05\/12\/2023",
     "Estado": "AM",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2110,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ESCRITORIO TIRADENTES - AUDITORIA, FINANCAS E CONTABILIDADE LTDA",
     "Nome Fantasia\/Apelido": "ESCRITORIO TIRADENTES",
     "CNPJ": "55.293.591\/0001-24",
     "CNAE Principal": "6920-6\/01",
     "Região": 7,
     "Situação": "Ativa",
     "Data Cadastro": "01\/12\/2023",
     "Estado": "SP",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2111,
     "Tipo": "PJ",
     "Razão Social\/Nome": "F C LAUERMANN LTDA",
     "Nome Fantasia\/Apelido": "COMERCIAL & HORTIFRUTI ECONOMICO",
     "CNPJ": "22.088.861\/0001-06",
     "CNAE Principal": "4711-3\/02",
     "Região": 1,
     "Situação": "Ativa",
     "Data Cadastro": "05\/12\/2023",
     "Estado": "TO",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2112,
     "Tipo": "PJ",
     "Razão Social\/Nome": "MARGOTTI E FERNANDES CENTRO DE ESTETICA LTDA",
     "Nome Fantasia\/Apelido": "EMAGRECENTRO ITAPEMA",
     "CNPJ": "35.580.608\/0001-17",
     "CNAE Principal": "9602-5\/02",
     "Região": 6,
     "Situação": "Ativa",
     "Data Cadastro": "07\/12\/2023",
     "Estado": "SC",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2113,
     "Tipo": "PJ",
     "Razão Social\/Nome": "AMMER COMERCIO DE PRODUTOS QUIMICOS DO BRASIL LTDA",
     "Nome Fantasia\/Apelido": "AMMER",
     "CNPJ": "19.876.529\/0001-00",
     "CNAE Principal": "4664-8\/00",
     "Região": 1,
     "Situação": "Ativa",
     "Data Cadastro": "07\/12\/2023",
     "Estado": "MG",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2114,
     "Tipo": "PJ",
     "Razão Social\/Nome": "GHIZONI E MARGOTTI CENTRO ESTETICO LTDA",
     "Nome Fantasia\/Apelido": "EMAGRECENTRO CAMBORIU",
     "CNPJ": "34.310.538\/0001-14",
     "CNAE Principal": "9602-5\/02",
     "Região": 6,
     "Situação": "Ativa",
     "Data Cadastro": "09\/12\/2023",
     "Estado": "SC",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2115,
     "Tipo": "PJ",
     "Razão Social\/Nome": "B.J. CADINI",
     "Nome Fantasia\/Apelido": "CADINI & CIA",
     "CNPJ": "11.819.392\/0001-31",
     "CNAE Principal": "4120-4\/00",
     "Região": 1,
     "Situação": "Ativa",
     "Data Cadastro": "11\/12\/2023",
     "Estado": "PR",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2116,
     "Tipo": "PJ",
     "Razão Social\/Nome": "R. TERHORST LTDA",
     "Nome Fantasia\/Apelido": "AGIL PRESTADORA DE SERVICOS",
     "CNPJ": "46.418.405\/0001-45",
     "CNAE Principal": "4313-4\/00",
     "Região": 2,
     "Situação": "Ativa",
     "Data Cadastro": "11\/12\/2023",
     "Estado": "RS",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2117,
     "Tipo": "PJ",
     "Razão Social\/Nome": "NILSON ANTUNES MENDES",
     "Nome Fantasia\/Apelido": "MENDES PINTURAS",
     "CNPJ": "32.252.922\/0001-82",
     "CNAE Principal": "4120-4\/00",
     "Região": 20,
     "Situação": "Ativa",
     "Data Cadastro": "15\/12\/2023",
     "Estado": "SC",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2118,
     "Tipo": "PJ",
     "Razão Social\/Nome": "VALLE TEC SOLUCOES EM CLIMATIZACAO LTDA",
     "Nome Fantasia\/Apelido": "VALLE TEC REFRIGERACAO",
     "CNPJ": "47.838.288\/0001-31",
     "CNAE Principal": "3314-7\/07",
     "Região": 24,
     "Situação": "Ativa",
     "Data Cadastro": "15\/12\/2023",
     "Estado": "BA",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2119,
     "Tipo": "PJ",
     "Razão Social\/Nome": "C. DO CARMO NASCIMENTO",
     "Nome Fantasia\/Apelido": "ORAL VITTA",
     "CNPJ": "22.939.450\/0001-87",
     "CNAE Principal": "8630-5\/04",
     "Região": 6,
     "Situação": "Ativa",
     "Data Cadastro": "18\/12\/2023",
     "Estado": "AP",
     "Situação ESocial": "Nao Habilitada",
     "Convocação de exames?": "Sim"
    },
    {
     "Column1": 2120,
     "Tipo": "PJ",
     "Razão Social\/Nome": "ALEX SANDRO TEIXEIRA - PROJETOS",
     "Nome Fantasia\/Apelido": "TOPOARQ ENGENHARIA",
     "CNPJ": "20.405.388\/0001-18",
     "CNAE Principal": "7119-7\/03",
     "Região": 20,
     "Situação": "Ativa",
     "Data Cadastro": "18\/12\/2023",
     "Estado": "PR",
     "Situação ESocial": "Habilitada",
     "Convocação de exames?": "Sim"
    }
   ]
