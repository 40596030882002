import styled from "styled-components";

export const Container = styled.div`
  width: 90%; 
  max-width: 800px;
  align-items: center;
  min-height: 100vh;
  display: flex;
  padding: 40px 0;
  flex-direction: column;

  h1{
    text-align: center;
    margin-bottom: 35px;
    color: #f2760a;
  }
  h2{
  color: #000;
  font-size: 18px;
  width: 100%;
  text-align: left;
  margin: 10px 0;
  font-weight: bold;
}

h3{
  color: #999;
  font-size: 15px;
  width: 100%;
  text-align: left;
  margin: 0 0 5px 0;
  font-weight: bold;
}
  @media (max-width: 750px) {
  padding: 30px 0;
  width: 95%;
  h1{
    text-align: center;
    font-size: 22px;
    margin-bottom: 15px;
  }

  }
`;

export const Input = styled.input`
  border: none;
  background-color: #f0f0f0;
  height: 35px;
  border-radius: 10px;
  font-size: 16px;
  width: 75%;
  margin-bottom: 15px;
  text-align: center;
`;

export const Button = styled.button`
  border: none;
  background-color: #AAC8AA;
  height: 35px;
  border-radius: 10px;
  font-size: 17px;
  padding: 0 10px;
  width: 75%;
  margin-top: 10px;
  color: #FFF;
  text-align: center;
`;