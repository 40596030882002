import React, { useEffect, useState } from 'react';
import { Container, } from './styles';
import { useNavigate } from "react-router-dom";
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { format, addDays } from 'date-fns';

export default function HomeAdmin() {

    const navigate = useNavigate();
    const [OSs, setOSs] = useState([])
    const [startDate, setStartDate] = useState(addDays(new Date(),-7))
    const [endDate, setEndDate] = useState(new Date())


    useEffect(() => {
        loadingFinancas()
    }, [startDate, endDate])

    async function loadingFinancas() {
        await api.post(`/list-orders`, {
            start_date: startDate,
            end_date: endDate
        }).then((response) => {
            let OSs = {
                "finalizado": {
                    OSs: 0,
                    name: "Finalizado",
                    total: 0
                },
                "cancelado": {
                    OSs: 0,
                    name: "Cancelado",
                    total: 0
                },
                "andamento": {
                    OSs: 0,
                    name: "Em andamento",
                    total: 0
                },
                "pendente": {
                    OSs: 0,
                    name: "Pendente",
                    total: 0
                },
                "validacao": {
                    OSs: 0,
                    name: "Em validação",
                    total: 0
                },
                "alteracao":{
                    OSs: 0,
                    name: "Em alteração",
                    total: 0
                },
                "recusado": {
                    OSs: 0,
                    name: "Recusado",
                    total: 0
                },
                "aberto": {
                    OSs: 0,
                    name: "Aberto",
                    total: 0
                },
            }
            response.data.map((item) => {
                OSs[item.status].OSs += 1
                let total = 0
                item.items.map((data) => {
                    total += data.amount * data.value
                })
                OSs[item.status].total += total
            })
            setOSs(Object.values(OSs))
        }).catch(({ response }) => {
            if (response) {
                if (response.data) {
                    if (response.data.message) {
                        toast.warn(response.data.message)
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            } else {
                toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
        })
    }

    return (
        <Container>
            <h1>Dados financeiros</h1>
                <p style={{ margin: "20px 0 10px 0" }}>
                    Altere  o periodo
                </p>
            <div style={{ width: 300, display: "flex", justifyContent: "space-between" }}>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                    de 
                    <input type="date" value={format(startDate, "yyyy-MM-dd")} onChange={(date) => {
                    let dateE = date.target.value.split("-")
                    setStartDate(new Date(dateE[0], dateE[1]-1, dateE[2]))
                }} /> 
                </div>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                    até
                <input type="date" min={format(startDate, "yyyy-MM-dd")} max={format(new Date(), "yyyy-MM-dd")} value={format(endDate, "yyyy-MM-dd")} onChange={(date) => {
                    let dateE = date.target.value.split("-")
                    setEndDate(new Date(dateE[0], dateE[1]-1, dateE[2]))
                }} />
                </div>
            </div>
            <div style={{ width: "100%", display: "flex", justifyContent: "center", margin: "15px 0" }}>
                <button onClick={() => {
                    setEndDate(new Date())
                    setStartDate(addDays(new Date(), -7))
                }}style={{background: "#f2760a", color: "#fff", padding: "5px 12px", margin: "0 8px", borderRadius: 4 }}>
                    Últimos 7 dias
                </button>
                <button onClick={()=>{
                    setEndDate(new Date())
                    setStartDate(addDays(new Date(), -15))
                }}style={{background: "#f2760a", color: "#fff", padding: "5px 12px", margin: "0 8px", borderRadius: 4 }}>
                    Últimos 15 dias
                </button>
                <button onClick={()=>{
                    setEndDate(new Date())
                    setStartDate(addDays(new Date(), -30))
                }}style={{background: "#f2760a", color: "#fff", padding: "5px 12px", margin: "0 8px", borderRadius: 4 }}>
                    Últimos 30 dias
                </button>
            </div>
            <div style={{margin: "15px 0 25px 0", width: "90%", maxWidth: 500, overflowX: "scroll"}}>
                            <div style={{width: 500, display: "flex", padding: "10px 0", borderBottomWidth: 2, borderBottomStyle: "solid", borderBottomColor: "#f2760a"}}>
                                <p style={{flex: 4, minWidth: 250}}>Status</p>
                                <p style={{ flex: 1, minWidth: 100, textAlign: "center" }}>N.º OSs</p>
                                <p style={{ flex: 2, minWidth: 150, textAlign: "end"}}>Total</p>
                            </div>
                {
                    OSs.map((item) => {
                        return (
                            <div style={{width: 500, display: "flex", padding: "10px 0", borderBottomWidth: 2, borderBottomStyle: "solid", borderBottomColor: "#f2760a"}}>
                                <p style={{flex: 4, minWidth: 250}}>{item.name}</p>
                                <p style={{ flex: 1, minWidth: 100, textAlign: "center" }}>{item.OSs}</p>
                                <p style={{ flex: 2, minWidth: 150, textAlign: "end"}}>{item.total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                            </div>
                        )
                    })
                }
            </div>
            <h1>Atalhos</h1>
            <a style={{ marginTop: 25 }} onClick={() => {
                navigate(`/admin/tecnicos`)
            }}>Técnicos</a>
            <a onClick={() => {
                navigate(`/admin/clientes`)
            }}>Franqueados</a>
            <a onClick={() => {
                navigate(`/admin/services`)
            }}>Serviços</a>
            <a onClick={() => {
                navigate(`/admin/banners`)
            }}>Banners</a>
            <a onClick={() => {
                navigate(`/admin/courses`)
            }}>Cursos</a>
            <a onClick={() => {
                navigate("/contracts")
            }}>Gerenciador de Contratos</a>
            <>
                <h3 style={{ marginTop: 25 }}>Área do conhecimento</h3>
                <a onClick={() => {
                    navigate(`/courses`)
                }}>Acessar</a>
            </>
        </Container >
    )
} 