import styled from "styled-components";

export const Container = styled.div`
  width: 100%; 
  align-items: center;
  min-height: 100vh;
  display: flex;
  padding: 40px 0;
  flex-direction: column;

  h1{
    text-align: center;
    margin-bottom: 35px;
    color: #f2760a;
  }
  @media (max-width: 750px) {
  padding: 30px 0;
  width: 95%;
  h1{
    text-align: center;
    font-size: 22px;
    margin-bottom: 15px;
  }
  }
`;

export const List = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  div{
  border-bottom: 2px solid #f2760a;
  padding: 10px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 10px; 

      div{
        border: none;
        flex-direction: column;
         justify-content: center;
        padding: 0;
        min-width: 150px;
        margin: 0;
        width: auto;
        flex: 1;
        p{
          margin: 0px;
          font-size: 14px;
          text-align: left;
        }
      }

      button{
        padding: 6px 15px;
        border-radius: 5px;
        background-color: #f2760a;
        color: #fff;
        height: 35px;
        margin: 5px 0 5px 10px;
        max-width: 250px;
        align-items: center;
        justify-content: center;
    }
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #f2760a;
  padding: 15px;
  margin: 10px 0; 

    input{
        padding: 8px;
        border-radius: 5px;
        background-color: #fff;
        color: #000c;
        height: 40px;
        width: 100%;
        max-width: 400px;
        border: none;
        font-size: 16px;
    }

      button{
        padding: 6px 15px;
        border-radius: 5px;
        background-color: #fff;
        color: #f2760a;
        height: 40px;
        margin-left: 50px;
        max-width: 250px;
        align-items: center;
        justify-content: center;
    }

    @media (max-width: 670px) {
      button{
        margin: 15px 30px 0;
      }
  }
`;