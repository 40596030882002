import React, { createContext, useCallback, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from './../services/api';

const AuthContext = createContext();


const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [data, setData] = useState(() => {
    const token = localStorage.getItem('@guruseg:token');
    const user = localStorage.getItem('@guruseg:user');

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;

      return { token, user: JSON.parse(user) };
    }

    return {};
  });

  /**
   * SignIn
   */
  const signIn = useCallback(async ({ email, password, type }) => {
    await api.post(type == "cliente" ? "user-session" : type == "admin" ? "admin-session" : "collaborator-session", { email, password })
      .then((response) => {
        const { user, token } = response.data;

        localStorage.setItem('@guruseg:token', token);
        localStorage.setItem('@guruseg:user', JSON.stringify(user));

        api.defaults.headers = {
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*"
        }
        api.defaults.headers.authorization = `Bearer ${token}`;

        setData({ token, user });

        if (type == "admin") {
          navigate(`/${type}/home`)
        } else {
          navigate(`/${type}s/home`)
        }

      }).catch(({ response }) => {
        if (response) {
          if (response.data) {
            if (response.data.message) {
              toast.warn(response.data.message)
            } else {
              toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      })

  }, []);


  const editUser = useCallback(({ user }) => {

    let token = localStorage.getItem('@guruseg:token');
    localStorage.setItem('@guruseg:user', JSON.stringify(user));

    setData({ token, user });

  }, []);
  /**
   * SignOut
   */
  const signOut = useCallback(() => {
    localStorage.removeItem('@guruseg:token');
    localStorage.removeItem('@guruseg:user');

    setData({});
  }, []);


  return (
    <AuthContext.Provider value={{ user: data.user, signIn, signOut, editUser }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const contenxt = useContext(AuthContext);
  if (!contenxt) {
    throw new Error('useAuth must be used with an AuthProvider');
  }
  return contenxt;
}

export { AuthProvider, useAuth };