import styled, { css } from "styled-components";

export const StatusDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;

  span{
    margin: 0px;
    font-size: 16px;
    text-align: center;
    margin: 5px 0 0 5px;
  }  
  `;