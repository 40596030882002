import styled from "styled-components";

export const Container = styled.div`
  width: 100%; 
  align-items: center;
  min-height: 100vh;
  display: flex;
  padding: 40px 0;
  flex-direction: column;

  h1{
    text-align: center;
    margin-bottom: 35px;
    color: #f2760a;
  }
  @media (max-width: 750px) {
  padding: 30px 0;
  width: 95%;
  h1{
    text-align: center;
    font-size: 22px;
    margin-bottom: 15px;
  }
  }
`;

export const List = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  div{
  background-color: #f2760add;
  padding: 10px;
  width: 100%;
  max-width: 450px;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  margin: 10px;


    img{
      width: 80px;
      height: 80px;
      margin-right: 10px;
      border-radius: 10px;
    }
    div{
      flex-direction: column;
      margin: 0px;
      background-color: transparent;
      padding: 0px;
      p{
        color: #fff;
      }
      button{
        margin-top: 15px;
        padding: 8px 15px;
        border-radius: 5px;
        background-color: #fff;
        color: #363636;
        max-width: 150px;
        align-items: center;
        justify-content: center;
      }
    }


  @media (max-width: 1100px) {
    max-width: 1200px;
  }
  

  }
`;


export const Urgente = styled.div`
  display: flex;
  position: absolute;
  right: 0px;
  bottom: 0px;
  flex-direction: row;
  margin-bottom: 10px;
  padding: 10px 30px 10px 25px;
  border-radius: 10px;
  align-items: center;
  background-color: #eb4034;
  border: none;
  color: #fff;
  font-size: 13px;
  height: 28px;
  justify-content: center;
  align-items: center;
  font-weight: bold;
`;
