import React, { useEffect, useState } from 'react';
import { Actions, Container, Services } from './styles';
import { useNavigate, useParams } from "react-router-dom";
import loadingPhoto from "./../../../images/loading.gif"
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { useMediaQuery } from 'react-responsive';
import logoBranca from "./../../../images/logoBranca.jpeg";
import ModalC from '../../../components/modal';
import { ButtonC, InputC } from '../../../components/simple';
import { format } from 'date-fns';

export default function Contract() {

    const navigate = useNavigate();

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1200px)'
    })

    const { contract_id } = useParams()

    const [visibleModalRecusa, setVisibleModalRecusa] = useState(false)
    const [visibleModalAceite, setVisibleModalAceite] = useState(false)
    const [loading, setLoading] = useState(true)
    const [totalValue, setTotalValue] = useState(0)
    const [contract, setContract] = useState([])
    const [name, setName] = useState("")
    const [doc, setDoc] = useState("")
    const [error, setError] = useState("")

    useEffect(() => {
        loadingContract()
    }, [])

    async function loadingContract() {
        await api.get(`/contract/${contract_id}`).then((response) => {
            if (response.data) {
                setContract(response.data)
                let totalV = 0
                response.data.services.map((item) => {
                    totalV += item.amount * item.value
                })
                if (response.data.service_value) {
                    totalV += response.data.service_value
                }
                setTotalValue(totalV)
            } else {
                toast.error("Contrato não foi encontrado")
                setError("Contrato não foi encontrado")
            }

        }).catch(({ response }) => {
            if (response) {
                if (response.data) {
                    if (response.data.message) {
                        setError(response.data.message)
                        toast.warn(response.data.message)
                    } else {
                        setError("Erro Interno. verifique sua conexão e tente novamente")
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    setError("Erro Interno. verifique sua conexão e tente novamente")
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            } else {
                setError("Erro Interno. verifique sua conexão e tente novamente")
                toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
        })
        setLoading(false)
    }

    async function signatureContract() {
        if (!name || !doc) {
            toast.warn("Preencha seu nome e documento para assinar o contrato")

        } else {
            await api.put(`/signature-contract/${contract.id}`, {
                signature: `Assinado por ${name}, documentação: ${doc}`,
            }).then(() => {
                toast.success("Contrato assinado com sucesso")
                loadingContract()
                setVisibleModalAceite(false)
            }).catch(({ response }) => {
                if (response) {
                    if (response.data) {
                        if (response.data.message) {
                            toast.warn(response.data.message)
                        } else {
                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                        }
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            })

        }
    }

    async function refusalContract() {
        await api.put(`/refusal-contract/${contract.id}`
        ).then(() => {
            toast.success("Contrato recusado com sucesso")
            loadingContract()
            setVisibleModalRecusa(false)
        }).catch(({ response }) => {
            if (response) {
                if (response.data) {
                    if (response.data.message) {
                        toast.warn(response.data.message)
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            } else {
                toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
        })

    }



    return (
        <Container>
            {
                loading ? (<img src={loadingPhoto} style={{ background: "transparent", height: 50, width: 50 }} />
                ) : (
                    <>
                        {
                            error ? (
                                <h3 style={{ marginTop: 100, textAlign: "center" }}>{error}</h3>
                            ) : (
                                <div style={isDesktopOrLaptop ? { padding: "20px", flexDirection: "column" } : { maxWidth: "92%", width: "92%", flexDirection: "column" }}>
                                    <img src={logoBranca} />
                                    <h3>PROPOSTA COMERCIAL</h3>
                                    <h3 style={{ margin: "35px 0 22px 0" }}>Á</h3>
                                    <h1>
                                        {contract.company}
                                    </h1>
                                    <span style={{ margin: "20px 0 0px 0" }}>Contato: <strong>{contract.name}</strong></span>
                                    <span style={{ margin: "5px 0 10px 0" }}>Telefone: <strong>{contract.contact}</strong></span>
                                    <h4 style={{ margin: "20px 0 0 0" }}>REF.:	Serviços de Segurança e Medicina do Trabalho</h4>
                                    <span style={{ margin: "35px 0" }}>Prezado Senhores,</span>
                                    <span style={{ marginBottom: 35 }}>Segue Proposta Comercial para prestação de serviço em Gestão de Segurança do Trabalho junto a vossa empresa.</span>
                                    <strong>(Atendemos em todo Brasil).</strong>
                                    <h4 style={{ margin: "35px 0 5px 0" }}>Atenciosamente,</h4>
                                    <span style={{ paddingLeft: 15, margin: 3 }}>Consultor {contract.consultant}</span>
                                    <span style={{ paddingLeft: 15, margin: 3 }}>Tel. {contract.phone_number}</span>
                                    <h4 style={{ textAlign: "center", margin: "35px 0 5px 0" }}>Guruseg Gestão e Treinamentos em Segurança do Trabalho:</h4>
                                    <a>www.guruseg.com.br</a>
                                    <h4 style={{ textAlign: "center", marginTop: 35 }}>Apresentação:</h4>
                                    <span style={{ textAlign: "center", margin: "35px 0" }}>
                                        Somos uma empresa especializada em Segurança do Trabalho formada por Médicos especializados e uma equipe multidisciplinar (Médicos, Engenheiros e Técnicos), focada na qualidade agilidade dos serviços prestados aos nossos clientes, somos referência em todo Brasil. Atuando na área de Gestão ao E-social, elaboração e documentos, Treinamentos e muito mais, contamos com unidades Franqueadas em todos os estados Brasileiro.
                                    </span>
                                    {
                                        (!!contract.risk && !!contract.lifes) && (
                                            <>
                                                <h4 style={{ textAlign: "center", margin: "15px 0 10px 0" }}>Serviços de Elaboração e Gestão em segurança do Trabalho</h4>
                                                {
                                                    !!contract.services_gestao && (
                                                        <>
                                                            <h4 style={{ margin: "15px 0 0 0" }}>Serviços Inclusos </h4>
                                                            <span style={{
                                                                margin: "25px 0",
                                                                whiteSpace: " pre-line",
                                                                wordBreak: "break-word"
                                                            }}>
                                                                {contract.services_gestao}
                                                            </span>
                                                        </>
                                                    )
                                                }
                                                <h4>Risco {contract.risk}</h4>
                                                <h4 style={{ margin: "10px 0 10px 0" }}>{contract.lifes} vidas</h4>
                                                <h4>Valor por vida:  {(contract.life_value ? contract.life_value : 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</h4>
                                                <h3 style={{ margin: "15px 0 10px 0" }}>
                                                    Entrada:  {(contract.initial_value ? contract.initial_value : 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                    {" "}-  Mensal:  {(57 + (contract.lifes ? contract.lifes : 0) * (contract.life_value ? contract.life_value : 0)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                    {" "}- Anual:  {((contract.initial_value ? contract.initial_value : 0) + ((57 + (contract.lifes ? contract.lifes : 0) * (contract.life_value ? contract.life_value : 0))) * 12).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                </h3>

                                                <h4 style={{ margin: " 30px 0" }}>MEIOS DE PAGAMENTO: VIA PIX, TRANSFERÊNCIA BANCARIA, BOLETO.</h4>
                                            </>
                                                )}    

                                    {contract.services.length != 0 && (
                                        <>

                                            <h4 style={{ textAlign: "center", margin: "15px 0 10px 0" }}>VALORES SST:</h4>
                                            <Services>
                                                {
                                                    contract.services.map((item) => {
                                                        return (
                                                            <div>
                                                                <p style={{ flex: 1, minWidth: 300 }}>{item.name}</p>
                                                                <p>{item.amount}x {item.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                                                                <p style={{ marginLeft: 25 }}>{(item.amount * item.value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                                                            </div>
                                                        )
                                                    })
                                                }

                                                {
                                                    (!!contract.service_value && !!contract.service_name) &&
                                                    (<div>
                                                        <p style={{ flex: 1, textTransform: "capitalize" }}>{contract.service_name}</p>
                                                        <p style={{ marginLeft: 25 }}>{(contract.service_value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                                                    </div>
                                                    )}
                                                <div>
                                                    <p style={{ flex: 1 }}>Total</p>
                                                    <p style={{ marginLeft: 25 }}>{(totalValue).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                                                </div>
                                                {!!contract.discount && (<div>
                                                    <p style={{ flex: 1 }}>Desconto</p>
                                                    <p style={{ marginLeft: 25 }}>-{(contract.discount).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                                                </div>)}
                                                <div>
                                                    <p style={{ flex: 1 }}>Valor Total</p>
                                                    <p style={{ marginLeft: 25 }}>{(contract.discount ? totalValue - contract.discount : totalValue).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                                                </div>
                                            </Services>

                                            <h4 style={{ margin: " 30px 0" }}>MEIOS DE PAGAMENTO: VIA PIX, TRANSFERÊNCIA BANCARIA, BOLETO.</h4>
                                        </>
                                    )
                                            }
                                    
                                    <h4 style={{
                                        margin: "10px 0 30px 0",
                                        whiteSpace: "pre-line",
                                        wordBreak: 'break-word'
                                    }}>{contract.banking}</h4>        
                                    <span style={{ margin: "25px 0" }}>
                                        Aprovo as condições comerciais descritas nesta proposta para dos exames e/ou documentos em segurança e medicina ocupacional.
                                    </span>
                                    <span style={{ margin: "15px 0" }}>
                                        Estou ciente que o inicio do serviço está condicionado ao aval de inicio dos serviços que é a assinatura da aprovação desta proposta.
                                    </span>

                                    {contract.status == "aguardando" && (

                                        <Actions>
                                            <button

                                                style={{ background: "#25b03a" }}
                                                onClick={async () => {

                                                    setVisibleModalAceite(true)

                                                }}>
                                                Assinar Contrato
                                            </button>

                                            <button
                                                style={{ background: "#bf3f36" }}
                                                onClick={async () => {

                                                    setVisibleModalRecusa(true)

                                                }}>
                                                Recusar Contrato
                                            </button>
                                        </Actions>
                                    )}
                                    {contract.status == "recusado" && (
                                        <h4 style={{ color: "#bf3f36", textAlign: "center", maxWidth: "90%", margin: "25px 0 50px 0" }}>
                                            Documento foi recusado no dia {format(new Date(contract.signature_date), "dd/MM/yyyy HH:mm")}
                                        </h4>
                                    )}


                                    {contract.status == "expirado" && (
                                        <h4 style={{ color: "#777", textAlign: "center", maxWidth: "90%", margin: "25px 0 50px 0" }}>
                                            Documento expirou no dia {format(new Date(contract.signature_date), "dd/MM/yyyy HH:mm")}
                                        </h4>
                                    )}

                                    {contract.status == "assinado" && (
                                        <h4 style={{ color: "#25b03a", textAlign: "center", maxWidth: "90%", margin: "25px 0 50px 0" }}>
                                            Documento foi {contract.signature} no dia {format(new Date(contract.signature_date), "dd/MM/yyyy HH:mm")}
                                        </h4>
                                    )}
                                </div>
                            )
                        }
                    </>

                )
            }


            <ModalC visible={visibleModalRecusa} executar={() => { setVisibleModalRecusa(false) }}>
                <div style={{ paddingTop: 0, width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <p style={{
                        width: "90%",
                        fontSize: 17,
                        marginTop: 15,
                        textAlign: "center", color: "#fff"
                    }}>Tem certeza que deseja recusar o contrato?</p>
                    <div style={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                        justifyContent: "space-between"
                    }}>
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: "center", marginTop: 25
                        }}>

                            <ButtonC text={""} title={"Recusa Contrato"} background={"#bb4444"}
                                width={"70%"} executar={() => {
                                    refusalContract()
                                }} />
                        </div>
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: "center", marginTop: 15
                        }}>

                            <ButtonC text={""} title={"Cancelar"} background={"#888"}
                                width={"70%"} executar={() => {
                                    setVisibleModalRecusa(false)
                                }} />
                        </div>
                    </div>
                </div>
            </ModalC>
            <ModalC visible={visibleModalAceite} executar={() => { setVisibleModalAceite(false) }}>
                <div style={{ paddingTop: 0, width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>

                    <p style={{
                        width: "90%",
                        fontSize: 17,
                        margin: "15px 0",
                        textAlign: "center", color: "#fff"
                    }}>Digite seu nome e documento para assinar o contrato o contrato</p>

                    <InputC text={name} title={""} placeholder={"nome completo"} width={"100%"} height={"55px"} color="#999" executar={(text) => { setName(text) }} />
                    <InputC text={doc} title={""} placeholder={"documento"} width={"100%"} height={"55px"} color="#999" executar={(text) => { setDoc(text) }} />
                    <div style={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                        justifyContent: "space-between"
                    }}>
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: "center", marginTop: 25
                        }}>

                            <ButtonC text={""} title={"Aceitar Contrato"} background={(name && doc) ? "#25b03a" : "#25b03a98"}
                                width={"70%"} executar={() => {
                                    signatureContract()
                                }} />
                        </div>
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: "center", marginTop: 15
                        }}>

                            <ButtonC text={""} title={"Cancelar"} background={"#888"}
                                width={"70%"} executar={() => {
                                    setVisibleModalAceite(false)
                                }} />
                        </div>
                    </div>
                </div>

            </ModalC>
        </Container >
    )
} 