import React, { useEffect } from "react";
import ReactInputMask from "react-input-mask";
import IntlCurrencyInput from "react-intl-currency-input";
import { ViewInput, InputMUI, InputLabelMUI, ButtonMUI, SwitchMUI } from './styles';
import { styled } from '@mui/material';

const currencyConfig = {
    locale: "pt-BR",
    formats: {
        number: {
            BRL: {
                style: "currency",
                currency: "BRL",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
        },
    },
};


const UrgentSwitch = styled(SwitchMUI)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: "#f2760a",
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: "#f2760a99",
    },
}));

export function InputC({ text, title, placeholder, width, executar, height, disabled, multiline, color, minHeight, type, styleInput, maxWidth, styleContent }) {
    return (
        <ViewInput style={{...styleContent || {}, width: width ? width : "100%", height: minHeight ? "auto" : height ? height : 50, minHeight: minHeight || 50, alignItems: "center", maxWidth: maxWidth || width}}>
            <InputLabelMUI style={{ color: color ? color : "#FFF", width: "100%" }}>
                {title}
            </InputLabelMUI>
            <InputMUI type={type ? type : "text"} multiline={multiline} disabled={disabled} size="small" value={text} style={styleInput ? styleInput : {}} placeholder={placeholder}
                onChange={(text) => { executar(text.target.value) }} />
        </ViewInput>
    );
}

export function InputTel({ text, title, placeholder, color, width, executar, height, maxWidth, styleContent}) {
    return (
        <ViewInput style={{...styleContent || {}, width: width ? width : "100%", height: height ? height : 50, maxWidth: maxWidth || width }}>
            <InputLabelMUI  style={{ color: color ? color : "#FFF", width: "100%" }}>
                {title}
            </InputLabelMUI>
            <ReactInputMask mask="(99) 99999-9999" value={text} placeholder={placeholder} style={{
                width: "100%",
                backgroundColor: "#FFF",
                borderWidth: 1,
                borderColor: "#ccc",
                borderStyle: "solid",
                color: "#000",
                padding: "10px",
                fontSize: 16,
                borderRadius: 5,
                fontWeight: "400",
                paddingLeft: 12,
            }}
                onChange={(text) => { executar(text.target.value.replace(/[^0-9]/g, '')) }} />
        </ViewInput>
    );
}

export function InputDate({ text, title, placeholder, width, executar, height }) {
    return (
        <ViewInput style={{ width: width ? width : "100%", height: height ? height : 50 }}>
            <InputLabelMUI>
                {title}
            </InputLabelMUI>
            <ReactInputMask mask="99/99/9999" value={text} placeholder={placeholder} style={{
                width: "100%",
                backgroundColor: "#FFF",
                borderWidth: 1,
                borderColor: "#ccc",
                borderStyle: "solid",
                color: "#000",
                padding: "10px",
                fontSize: 16,
                borderRadius: 5,
                fontWeight: "400",
                paddingLeft: 12,
            }}
                onChange={(text) => { executar(text.target.value.replace(/[^0-9]/g, '')) }} />
        </ViewInput>
    );
}

export function InputCPF({ text, title, placeholder, width, executar, height }) {
    return (
        <ViewInput style={{ width: width ? width : "100%", height: height ? height : 50 }}>
            <InputLabelMUI>
                {title}
            </InputLabelMUI>
            <ReactInputMask mask="999.999.999-99" value={text} placeholder={placeholder} style={{
                width: "100%",
                backgroundColor: "#FFF",
                borderWidth: 1,
                borderColor: "#ccc",
                borderStyle: "solid",
                color: "#000",
                padding: "10px",
                fontSize: 16,
                borderRadius: 5,
                fontWeight: "400",
                paddingLeft: 12,
            }}
                onChange={(text) => { executar(text.target.value.replace(/[^0-9]/g, '')) }} />
        </ViewInput>
    );
}

export function InputNumberC({ text, title, style, width, executar, height }) {
    return (
        <ViewInput style={style ? { ...style, width: width ? width : "100%", height: height ? height : "100%" } : { width: width ? width : "100%", height: height ? height : "100%" }}>
            <InputLabelMUI>
                {title}
            </InputLabelMUI>
            <IntlCurrencyInput
                style={{
                    width: "100%",
                    backgroundColor: "#FFF",
                    borderWidth: 0,
                    color: "#000",
                    padding: "10px",
                    fontSize: 16,
                    borderWidth: 1,
                    borderColor: "#ccc",
                    borderStyle: "solid",
                    borderRadius: 5,
                    fontWeight: "400",
                    paddingLeft: 12,
                }}
                currency="BRL"
                config={currencyConfig}
                value={text}
                onChange={(event, value) => executar(value)}
            />
        </ViewInput>
    );
}


export function SwitchC({ value, title, width, executar, height, style}) {
    return (
        <ViewInput style={{width: width ? width : "100%", height: height ? height : "100%",...style}}>
            {title && (<InputLabelMUI style={{ marginRight: 8 }}>
                {title}
            </InputLabelMUI>)}
            <UrgentSwitch size="small" checked={value}
                onChange={() => { executar(!value) }} />
        </ViewInput>
    );
}

export function ButtonC({ title, background, width, height, executar, style, disabled }) {
    return (
        <ButtonMUI disabled={disabled} onClick={() => { executar() }} style={{height: height || 40, width: width || "100%", background: background ? background : "#f2760a", borderRadius: 5, ...style }}>
            {typeof title == "string" ? (
                <p>
                    {title}
                </p>
            ) : (
                title
            )}
        </ButtonMUI>
    );
}