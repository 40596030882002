import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  align-items: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  h3{
    margin: 100px 0;
  }
`;


export const HeaderLeft = styled.div`
  width: 200px;
  max-width: 200px;
  display: flex;
  background-color: #AAC8AA;
  min-height: calc(100vh - 100px);
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px;

  button{
        padding: 6px 15px;
        background-color: #AAC8AA;
        color: #fff;
        border-bottom: solid;
        border-bottom-width: 1px;
        border-bottom-color: #AAC8AA;
        min-height: 40px;
        width: 200px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        p{
           width: 100%;
           margin-top: 5px;
          font-size: 10px;
          text-align: center;
          margin-bottom: 0px;
          color: #fff;
        }
        &:hover,
      &.active {
        background-color: #fff;
        border-bottom: solid;
        border-bottom-width: 1px;
        color: #AAC8AA;
        border-bottom-color: #AAC8AA;
        p{
        color: #AAC8AA;
      }
    }
    }
`;


export const Lesson = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  div{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

         h3{
           width: 100%;
          font-size: 25px;
          margin: 0 0 20px 0;
          text-align: center;
        }
      
        textarea{
           width: 80%;
          font-size: 15px;
          text-align: center;
          margin-bottom: 30px;
          color: #999;
          border: none;
          word-wrap: break-word;
          word-break: break-all;
        resize: none;
          overflow:hidden
        }
    @media (max-width: 1200px) {
     
    align-items: center;
  flex-direction: column;
  

  textarea{
           width: 92%;
  }
}
  
`;


export const HeaderTop = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #AAC8AA;
  padding: 5px 10px;
  margin: 0 0 25px 0; 
  button{
    width: 60px;
    height: 60px;
        background-color: transparent;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
          font-size: 10px;
          flex-direction: column;
  }
  div{
      flex: 1;
        padding: 6px 15px;
        background-color: #AAC8AA;
        color: #fff;
        border-bottom: solid;
        border-bottom-width: 1px;
        border-bottom-color: #AAC8AA;
    height: 60px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        p{
          font-size: 10px;
          text-align: center;
          margin-bottom: 0px;
          color: #fff;
        }
    
    }
`;