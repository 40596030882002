import React, { useEffect, useState } from 'react';
import { Container } from './styles';
import { useNavigate } from "react-router-dom";
import { useAuth } from '../../../hooks/AuthContext';
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { useMediaQuery } from 'react-responsive';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import loadingPhoto from "./../../../images/loading.gif"

export default function HomeTecnicos({ type }) {
    const { user, editUser } = useAuth();


    const navigate = useNavigate();
    const [banners, setBanners] = useState([])
    const [loading, setLoading] = useState(true)

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })

    useEffect(() => {
        loadingUser()
        loadingBanners()
    }, [])

    async function loadingUser() {
        await api.get(`/user`).then((response) => {
            let userEdit = user
            userEdit.name = response.data.name
            userEdit.email = response.data.email
            userEdit.phone_number = response.data.phone_number
            userEdit.course_restricted = response.data.course_restricted
            userEdit.course = response.data.course
            userEdit.resale = response.data.resale
            userEdit.region = response.data.region
            userEdit.signature = response.data.signature
            editUser({ user: userEdit })
        }).catch(({ response }) => {
            if (response) {
                if (response.data) {
                    if (response.data.message) {
                        toast.warn(response.data.message)
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            } else {
                toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
        })
        setLoading(false)
    }

    async function loadingBanners() {
        await api.get(`/banners-public?type=franqueado`).then((response) => {
            setBanners(response.data)
        }).catch(({ response }) => {
            if (response) {
                if (response.data) {
                    if (response.data.message) {
                        toast.warn(response.data.message)
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            } else {
                toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
        })
    }

    return (
        <Container>
            {
                loading ? (<img src={loadingPhoto} style={{ background: "transparent", height: 50, width: 50 }} />

                ) : (
                    <>
                        <h1>
                            Seja bem-vindo, {user.name}
                        </h1>
                        {(banners.length != 0 && !user.resale && !user.signature) && (
                            <div style={{ margin: isDesktopOrLaptop ? "25px 0" : "0px 15px", cursor: "pointer", height: isDesktopOrLaptop ? "25vw" : "33vw", width: isDesktopOrLaptop ? "75vw" : "100vw" }} >
                                <Carousel
                                    showThumbs={false}
                                    autoPlay={true}
                                    interval={5000}
                                    infiniteLoop={true}
                                    showArrows={true}
                                    showStatus={false}
                                    centerMode="100%"
                                    selectedItem={0}
                                    onClickItem={(idx) => {
                                        window.location.assign(banners[idx].url);
                                    }}
                                >
                                    {banners.map((item) => {
                                        return (
                                            <img src={item.photo_url} style={{ height: isDesktopOrLaptop ? "25vw" : "33vw", width: "100%", borderRadius: isDesktopOrLaptop ? 10 : 0, objectFit: "contain" }} />
                                        )
                                    })}
                                </Carousel>

                            </div>
                        )}
                        {
                            user.course && (
                                <>
                                    <h3 style={{ marginTop: 25 }}>Área do conhecimento</h3>
                                    <a onClick={() => {
                                        navigate(`/courses`)
                                    }}>Acessar</a>
                                </>
                            )
                        }

                        <h3 style={{ marginTop: 25 }}>Navegue pelo sistema e solicite suas OS </h3>
                        <a onClick={() => {
                            navigate(`/clientes/solicitacoes`)
                        }}>Minhas OSs</a>
                        <a onClick={() => {
                            navigate(`/clientes/financeiro`)
                        }}>Financeiro</a>
                        <a onClick={() => {
                            navigate("/solicitacao")
                        }}>Solicitar OS</a>
                        {
                            !user.resale && !user.signature && (
                                    <a onClick={() => {
                                        if (user.course_restricted) {
                                            toast.warn("Área restrita para franqueados")
                                        } else {
                                            navigate("/contracts")
                                        }
                                }}>Gerenciador de Contratos</a>
                            )
                        }

                    </>
                )}


        </Container >
    )
}