import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  align-items: center;
  min-height: 100vh;
  padding: 0 40px;
  display: flex;
  background-color: #f0f0f0;
  flex-direction: column;

  div{
  max-width: 1000px;
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  }

  img{
    width: 50%;
    max-width: 300px;
    margin-bottom: 25px;
  }

  h1{
    width: 100%;
    text-align: left;
  font-size: 40px;
  }

  h2{
    width: 100%;
    text-align: left;
font-size: 30px;
  }

  h3{
    width: 100%;
text-align: left; 
font-size: 26px;
} 
h4{
    width: 100%;
text-align: left; 
font-size: 20px;
}

  span{
    width: 100%;
text-align: left; 
}
  strong{
    color: #d00;
font-size: 18px;
    text-align: center;
  }
`;

export const Services = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  div{
  width: 100%;
  margin: 0; 
  align-items: center;
  max-width: 1000px;
    display: flex;
    padding: 5px;
    justify-content: flex-end;
    margin-bottom: 15px;
    flex-wrap: wrap;
    flex-direction: row;
    border-bottom: 2px solid #f2760a;
    p{
      color: #000;
      margin: 5px 0;
    }
  }

  @media (max-width: 850px) {
  width: 100%;
 
  }
`;


export const Actions = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
    width: 100%;
    margin: 35px 0;
    flex-wrap: wrap;
  align-items: center;
  justify-content: center;
    margin-bottom: 50px;
    button{
        display: flex;
        padding: 15px;
        border-radius: 5px;
        margin:  0 15px;
        align-items: center;
        justify-content: center;
      background-color: #f2760a;
      font-size: 16px;
        color: #fff;
      }
  

  @media (max-width: 850px) {
    width: 100%;
    border-radius: 0PX;
    button{
        margin: 15px 15px 0 15px;
      }
  }
`;