import React, { useEffect, useState } from 'react';
import { Container, Resume, Services, Observation, Name, Docs } from './styles';
import { useNavigate } from "react-router-dom";
import loadingPhoto from "./../../../images/loading.gif"
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { ButtonC } from '../../../components/simple';
import { Switch, styled } from '@mui/material';
import { MdDelete, MdDownload, MdUpload } from "react-icons/md"
import ModalC from '../../../components/modal';
import { useAuth } from '../../../hooks/AuthContext';
import { AutoComplete } from 'antd';


const UrgentSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: "#f2760a",
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: "#f2760a99",
    },
}));

export default function Solicitacao() {
    const { user } = useAuth()
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true)
    const [loadingAction, setLoadingAction] = useState(false)
    const [observation, setObservation] = useState("")
    const [visibleModal, setVisibleModal] = useState(false)
    const [etapa, setEtapa] = useState(0)
    const [urgent, setUrgent] = useState(false)
    const [files, setFiles] = useState([])
    const [filesUp, setFilesUp] = useState([])
    const [name, setName] = useState("")
    const [nameOS, setNameOS] = useState("")
    const [description, setDescription] = useState("")
    const [sector, setSector] = useState("")
    const [value, setValue] = useState(0)
    const [order, setOrder] = useState(null)
    const [company, setCompany] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [companies, setCompanies] = useState([])
    const [companiesC, setCompaniesC] = useState([])
    const [services, setServices] = useState([])

    function totalValue() {
        let value = 0
        services.map((item) => {
            value += item.amount * item.value
        })
        return value
    }

    function totalServices() {
        let nServices = 0
        services.map((item) => {
            nServices += item.amount
        })
        return nServices
    }

    useEffect(() => {
        loadingOSs()
        if (user.resale) {
            setSector("Revenda de treinamentos online")
        }
        if (user.signature) {
            setSector("Assinatura Documentos SST")
        }
    }, [])

    async function loadingOSs() {
        await api.get(`/services-client`).then((response) => {
            response.data.map((item) => {
                item.amount = 0
            })
            setServices(response.data)
        }).catch(({ response }) => {
            if (response) {
                if (response.data) {
                    if (response.data.message) {
                        toast.warn(response.data.message)
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            } else {
                toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
        })
        await api.get(`/companies-confirm`).then((response) => {
            let companies = response.data
            
            companies.map((item) => {
                item.value = item.razao_social
                item.label = item.razao_social
            })

            setCompanies(companies)
            setCompaniesC(companies)
        }).catch(({ response }) => {
            if (response) {
                if (response.data) {
                    if (response.data.message) {
                        toast.warn(response.data.message)
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            } else {
                toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
        })
        setLoading(false)
    }

    async function uploadDocs(filesUpload) {
        filesUpload.map(async (item) => {
            const data = new FormData();
            data.append('type', user.type);
            data.append('file', item, item.name);
            await api.post(`/doc/${order.id}`, data).then(async (response) => {
                setFilesUp(prevFiles => {
                    const newState = [...prevFiles]
                    newState.shift()
                    return newState
                })
                setFiles(prevFiles => [...prevFiles, response.data])
                toast.success(`O documento ${item.name} foi enviado com sucesso`)
            }).catch(({ response }) => {
                if (response) {
                    if (response.data) {
                        if (response.data.message) {
                            toast.warn(response.data.message)
                        } else {
                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                        }
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            })
        })
    }

    async function deleteDoc(fileDelete) {
        await api.delete(`/doc/${fileDelete.id}?type=${user.type}`).then(async (response) => {
            setFiles(prevFiles => {
                const newFiles = [...prevFiles]
                const idx = newFiles.indexOf(fileDelete);
                if (idx > -1) {
                    newFiles.splice(idx, 1);
                }
                return newFiles
            })
            toast.success(`O documento ${String(fileDelete.file).substr(33)} foi deletado com sucesso`)
        }).catch(({ response }) => {
            if (response) {
                if (response.data) {
                    if (response.data.message) {
                        toast.warn(response.data.message)
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            } else {
                toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
        })
    }
    
    return (
        <Container>
            {
                loading ? (<img src={loadingPhoto} style={{ background: "transparent", height: 50, width: 50 }} />
                ) : (
                    <>
                            <h1>Solicitando novos serviços</h1>
                            {etapa == 0 && (
                                <>
                                 <h3>Nome da OS</h3>
                        <Name type="text" placeholder="Razão social da empresa" onChange={(text) => {
                            setNameOS(text.target.value)
                        }} />
                        <h3>Observação</h3>
                        <Observation type="text" placeholder='Deixe aqui uma observação para o técnico.' onChange={(text) => {
                            setObservation(text.target.value)
                        }} />
                        <h3>Selecione o Setor</h3>
                        <select
                            value={sector}
                            style={{
                                width: "100%",
                                maxWidth: "350px",
                                backgroundColor: "#FFF",
                                borderWidth: 1,
                                borderColor: "#ccc",
                                borderStyle: "solid",
                                color: "#444",
                                padding: "10px",
                                fontSize: 14,
                                borderRadius: 5,
                                fontWeight: "400",
                                paddingLeft: 8,
                            }}
                            onChange={(text) => {
                                if (user.resale || user.signature) {
                                    toast.warn("Apenas para franqueados")
                                } else {
                                    let servicesC = services
                                    servicesC.map((item) => {
                                        item.amount = 0
                                    })
                                    setServices(servicesC)
                                    setSector(text.target.value)
                                }

                            }}
                        >
                            <option disabled hidden value={""}>Seleciona uma opção</option>
                            { !user.signature && (<option value={"Serviços de segurança do Trabalho"}>Serviços de segurança do Trabalho</option>)}
                            { !user.signature && (<option value={"Revenda de treinamentos online"}>Revenda de treinamentos online</option>)}
                            { !user.signature && (<option value={"Serviços Medicina ocupacional"}>Serviços Medicina ocupacional</option>)}
                            { !user.signature && (<option value={"Credenciamento SST"}>Credenciamento SST</option>)}
                            <option value={"Assinatura Documentos SST"}>Assinatura Documentos SST</option>
                        </select>
                        <h3>Vincular Formulário da Empresa</h3>
                                 <AutoComplete
                                    style={{ 
                                        width: "100%",
                                        maxWidth: "350px",
                                        fontSize: 14, 
                                        height: 40,
                                        textAlign: "left" }}
                                    options={companies}
                                    value={company || companyName}
                                    notFoundContent={(<>Nenhuma empresa encontrado</>)}
                                    onSelect={(text, client) => {
                                        setCompany(client)
                                        setCompanyName("")
                                        setCompanies(companiesC)
                                    }}
                                    onSearch={(text) => {
                                        setCompanyName(text)
                                            if (text) {
                                                let companiesGet = companiesC.filter((item) => {
                                                    return item.label.toUpperCase().indexOf(text.toUpperCase()) != -1
                                                }) 
                                                setCompanies([...companiesGet])
                                            } else {
                                                setCompanies(companiesC)
                                            }
                                          
                                    }}
                                    placeholder="procurar por nome"
                                />
                        {
                            sector && (
                                <>
                                    <h3>{sector}</h3>
                                    <Services>
                                        {

                                            services.map((item, index) => {
                                                if (item.sector == sector) {
                                                    return (
                                                        <div>
                                                            <div>
                                                                <button onClick={() => {
                                                                    let servicesA = [...services]
                                                                    servicesA[index].amount++
                                                                    setServices(servicesA)
                                                                }}>+</button>
                                                                <span>{item.amount}</span>
                                                                <button onClick={() => {
                                                                    let servicesA = [...services]
                                                                    if (servicesA[index].amount > 0) {
                                                                        servicesA[index].amount--
                                                                        setServices(servicesA)
                                                                    }

                                                                }}>-</button>
                                                            </div>
                                                            <p style={{ flex: 1, minWidth: 100 }}>{item.name}</p>
                                                            {item.description && (<button onClick={() => {
                                                                setDescription(item.description)
                                                                setName(item.name)
                                                                setValue(item.value)
                                                                setVisibleModal(true)
                                                            }}>detalhes</button>)}
                                                            <p style={{ flex: 1, maxWidth: 85, textAlign: "right" }}>{item.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>

                                                        </div>)
                                                }

                                            })}
                                    </Services>
                                </>
                            )}
                       </>
                            )
                        }
                                    {
                                        /*
 <h3>Urgência</h3>
                                    <Urgente>
                                        <UrgentSwitch color="default" checked={urgent}
                                            onChange={() => { setUrgent(!urgent) }} />
                                        <p style={{ textAlign: "center", color: "#000", marginLeft: 10, fontSize: 16 }}>Ao solicitar urgência, ela terá preferencia e entrega mais rápida, acrescendo 10% do valor total</p>
                                    </Urgente>
                                        */
                                    }
                                   
                                        {
                                            etapa == 1 && (
                                                <>
                                                 <h3>Envie seus Documentos</h3>
                                                    <Docs>
                                                        {
                                                            files.map((item) => {
                                                                    return (
                                                                        <div key={item.file}>
                                                                            <p>{String(item.file).substr(33)}</p>
                                                                            <div style={{ display: "flex", flexDirection: "row", minHeight: 20, padding: 0, margin: 0 }}>
                                                                                <MdDownload onClick={() => {
                                                                                    window.open("https://guruseg-data.s3.sa-east-1.amazonaws.com/" + item.file)
                                                                                }} style={{ cursor: "pointer" }} size={35} color="#f2760a" />
                                                                                <MdDelete onClick={() => {
                                                                                    deleteDoc(item)
                                                                                }} style={{ cursor: "pointer" }} size={30} color="#f2760a" />
                                                                            </div>

                                                                        </div>
                                                                    )
                                                            })
                                                        }
                                                        {
                                                            filesUp.map((item) => {
                                                                return (
                                                                    <div key={item.name}>
                                                                        <p>{item.name}</p>
                                                                        <img src={loadingPhoto} style={{ width: 60, height: 40 }} />
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        <div for="avatar" style={{ backgroundColor: "#f2760a", position: "relative" }}>
                                                            <p style={{ color: "#fff" }}>Enviar Documentos</p>
                                                            <MdUpload size={35} color="#fff" />
                                                            <input id="avatar" style={{ cursor: "pointer", position: "absolute", width: "100%", height: "100%", opacity: 0 }} multiple type="file" accept="image/" onChange={(file) => {
                                                                let filesUp = Object.values(file.target.files)
                                                                filesUp.map((item) => {
                                                                    item.file_url = URL.createObjectURL(item)
                                                                })
                                                                setFilesUp(prevFiles => [...prevFiles, ...filesUp])
                                                                uploadDocs(filesUp)
                                                            }} />
                                                        </div>
                                                    </Docs>
                                                </>
                                            )
                                    }

                                    <h3>Resumo</h3>
                                    <Resume>
                                        <p>{totalServices()} serviço{totalServices() > 1 ? "s" : ""}</p>
                                        {
                                            urgent ? (
                                                <>
                                                    <p style={{ fontSize: 16, marginTop: 0, marginBottom: 5 }}>Subtotal: {totalValue().toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} </p>
                                                    <p style={{ fontSize: 16, marginTop: 0, marginBottom: 10 }}>10% da urgência: {(totalValue() * 0.1).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                                                    <p style={{ fontWeight: "bold" }}>Total: {(totalValue() * 1.1).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} </p>
                                                </>
                                            ) : (
                                                <p style={{ fontWeight: "bold" }}>Total: {totalValue().toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} </p>
                                            )
                                        }

                                        <button disabled={loadingAction} onClick={async () => {
                                                setLoadingAction(true)
                                                if (etapa == 0) {
                                                    let items = []
                                                    services.map((item) => {
                                                        if (item.amount > 0) {
                                                            items.push(item)
                                                        }
                                                    })
                                                    if (items.length == 0) {
                                                        toast.warn("Selecione pelo menos um item")
                                                    } else {
                                                        await api.post("/order", {
                                                            items,
                                                            name: nameOS,
                                                            company_id: company.id,
                                                            observation,
                                                            sector: sector,
                                                            month: format(new Date(), "yyyy-MM"),
                                                            urgent: urgent
                                                        }).then((response) => {
                                                            setOrder(response.data)
                                                            setEtapa(1)
                                                        }).catch((error) => {
                                                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                                                        })
                                                    }
                                                } else {
                                                    if (files.length == 0 && !order.company_id) {
                                                        toast.warn("Envie pelo menos um documento para finalizar.")
                                                    } else {
                                                        await api.put(`/confirm-order/${order.id}`, {message: ""}).then((response) => {
                                                            navigate("/clientes/os/" + response.data.id)
                                                        }).catch((error) => {
                                                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                                                        })
                                                    }
                                                }
                                            setLoadingAction(false)
                                            }}>
                                        { loadingAction ? (
                                                <img src={loadingPhoto} style={{ background: "transparent", height: 35, width: 50, margin: "0 40px" }} />
                                            ) :
                                            etapa == 0 ? "Próxima Etapa" : "Finalizar Solicitação"
                                        }
                                        </button>
                                    </Resume>
                                


                    </>
                )
            }
            <ModalC visible={visibleModal} executar={() => { setVisibleModal(false) }}>
                <div style={{ paddingTop: 0, width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <h2 style={{ textAlign: "center", color: "#fff" }}>{name}</h2>
                    <p style={{ textAlign: "center", color: "#fff", margin: "15px 0", fontSize: 16 }}>{description}</p>
                    <h3 style={{ textAlign: "center", color: "#fff" }}>{value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</h3>
                    <div style={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                        justifyContent: "space-between"
                    }}>
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: "center", marginTop: 25
                        }}>
                            <ButtonC text={""} title={"Fechar"}background={"#888"}
                                width={"50%"} executar={() => {
                                    setVisibleModal(false)
                                }} />
                        </div>
                    </div>
                </div>

            </ModalC>
        </Container >
    )
} 