import React, { useEffect, useState } from 'react';
import { Container, List, Header } from './styles';
import { useNavigate } from "react-router-dom";
import loadingPhoto from "./../../../images/loading.gif"
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { InputC, ButtonC, SwitchC, InputNumberC } from "./../../../components/simple";
import { MdAddPhotoAlternate, MdDelete, MdEdit } from 'react-icons/md';
import { IconButton } from '@mui/material';
import ModalC from './../../../components/modal';


export default function ServicesAdmin() {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true)
    const [visibleModal, setVisibleModal] = useState(false)
    const [visibleModalDelete, setVisibleModalDelete] = useState(false)
    const [services, setServices] = useState([])
    const [servicesC, setServicesC] = useState([])
    const [filtername, setFilterName] = useState("")
    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [value, setValue] = useState(0)
    const [commission, setCommission] = useState(0)
    const [sector, setSector] = useState("")
    const [id, setId] = useState("")

    useEffect(() => {
        listServices()
    }, [])

    async function listServices() {
        await api.get(`/services`).then((response) => {
            setServices(response.data)
            setServicesC(response.data)
        }).catch(({ response }) => {
            if (response) {
                if (response.data) {
                    if (response.data.message) {
                        toast.warn(response.data.message)
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            } else {
                toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
        })
        setFilterName("")
        setLoading(false)
    }

    async function createAndEditService() {
        if (!name || !value || !commission || !sector) {
            toast.warn('Preencha todos os campos para realizar o cadastro');
        } else {
            if (id) {
                await api.put(`/service/${id}`, {
                    name, description, value, commission, sector
                }).then(async (ok) => {
                    toast.success("Serviço editado com sucesso")
                    listServices()
                    setVisibleModal(false)
                }).catch((response) => {
                    if (response) {
                        if (response.data) {
                            if (response.data.message) {
                                toast.warn(response.data.message)
                            } else {
                                toast.error("Erro Interno. verifique sua conexão e tente novamente")
                            }
                        } else {
                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                        }
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                })
            } else {
                await api.post(`/service`, {
                    name, description, value, commission, sector
                }).then(async (ok) => {
                    toast.success("Serviço criado com sucesso")
                    listServices()
                    setVisibleModal(false)
                }).catch((response) => {
                    if (response) {
                        if (response.data) {
                            if (response.data.message) {
                                toast.warn(response.data.message)
                            } else {
                                toast.error("Erro Interno. verifique sua conexão e tente novamente")
                            }
                        } else {
                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                        }
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                })
            }
        }
    }

    function filterServices(value) {
        let servicesCopy = servicesC
        let services = []
        servicesCopy.map((item) => {
            if (String(item.name)
                .toUpperCase()
                .indexOf(value.toUpperCase()) != -1) {
                services.push(item)
            }
        })
        setServices(services)
    }

    async function deleteService() {
        await api.delete(`/service/${id}`)
            .then(() => {
                toast.success("Serviço excluido com sucesso")
                listServices()
                setVisibleModalDelete(false)
            }).catch(({ response }) => {
                if (response) {
                    if (response.data) {
                        if (response.data.message) {
                            toast.warn(response.data.message)
                        } else {
                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                        }
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            })
    }

    return (
        <Container>
            {
                loading ? (<img src={loadingPhoto} style={{ background: "transparent", height: 50, width: 50 }} />

                ) : (
                    <>
                        <h1>{services.length} Serviços</h1>
                        <Header>
                            <input value={filtername} onChange={(text) => {
                                setFilterName(text.target.value)
                                filterServices(text.target.value)
                            }} placeholder='Procurar por nome' />
                            <button onClick={() => {
                                setId("")
                                setName("")
                                setDescription("")
                                setValue("")
                                setCommission("")
                                setSector("")
                                setVisibleModal(true)
                            }}>
                                Novo Serviço
                            </button>
                        </Header>
                        <List>
                            {services.length == 0 && (
                                <h4 style={{ marginTop: 50, fontWeight: 500, width: "80%", textAlign: "center" }}>Nenhum serviço encontrado</h4>
                            )}
                            {services.map((item) => {
                                return (
                                    <div>
                                        <div>
                                            <p>{item.name} - {(item.value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                                            <p style={{ color: "#000a", marginTop: 5 }}>{item.description}</p>
                                            <p style={{ color: "#0008", marginTop: 5, fontSize: 12 }}>Comissão paga por serviço: {(item.commission).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                                        </div>

                                        <button onClick={() => {
                                            setId(item.id)
                                            setName(item.name)
                                            setDescription(item.description)
                                            setValue(item.value)
                                            setSector(item.sector)
                                            setCommission(item.commission)
                                            setVisibleModal(true)
                                        }}>
                                            <MdEdit color="#FFF" size={20} />
                                        </button>
                                        <button onClick={() => {
                                            setId(item.id)
                                            setName(item.name)
                                            setVisibleModalDelete(true)
                                        }}>
                                            <MdDelete color="#FFF" size={20} />
                                        </button>
                                    </div>
                                )
                            })}
                        </List>
                    </>
                )
            }

            <ModalC visible={visibleModal} executar={() => { setVisibleModal(false) }}>
                <div style={{
                    display: "flex",
                    flexWrap: "wrap",
                    width: "100%",
                    justifyContent: "space-between"
                }}>
                    <InputC text={name} title={"Nome"} placeholder={"serviço"} width={"100%"} height={"55px"} executar={(text) => { setName(text) }} />
                    <InputNumberC text={value} title={"Valor"} placeholder={"0,00"} width={"100%"} height={"55px"} executar={(text) => { setValue(text) }} />
                    <InputNumberC text={commission} title={"Comisão do Técnico"} placeholder={"0,00"} width={"100%"} height={"55px"} executar={(text) => { setCommission(text) }} />
                    <h3 style={{
                        color: "#fff",
                        fontSize: 15,
                        width: "100%",
                        textAlign: "left",
                        margin: "10px 0 5px 0",
                        fontWeight: "bold"
                    }}>Setor</h3>
                    <select
                        value={sector}
                        style={{
                            width: "100%",
                            backgroundColor: "#FFF",
                            borderWidth: 1,
                            borderColor: "#ccc",
                            borderStyle: "solid",
                            color: "#444",
                            padding: "10px",
                            fontSize: 14,
                            borderRadius: 5,
                            fontWeight: "400",
                            paddingLeft: 8,
                        }}
                        onChange={(text) => {
                            setSector(text.target.value)
                        }}
                    >
                        <option disabled hidden value={""}>Seleciona uma opção</option>
                        <option value={"Serviços de segurança do Trabalho"}>Serviços de segurança do Trabalho</option>
                        <option value={"Revenda de treinamentos online"}>Revenda de treinamentos online</option>
                        <option value={"Serviços Medicina ocupacional"}>Serviços Medicina ocupacional</option>
                        <option value={"Credenciamento SST"}>Credenciamento SST</option>
                        <option value={"Assinatura Documentos SST"}>Assinatura Documentos SST</option>
                    </select>
                    <InputC text={description} title={"Descrição"} placeholder={"Descrição detalhada sobre o Serviço"} width={"100%"} height={120} multiline={true} executar={(text) => { setDescription(text) }} />
                    <div style={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                        justifyContent: "center", marginTop: 15
                    }}>

                        <ButtonC text={""} title={"Salvar"}
                            width={"60%"} executar={() => {
                                createAndEditService()
                            }} />
                    </div>
                </div>

            </ModalC>
            <ModalC visible={visibleModalDelete} executar={() => { setVisibleModalDelete(false) }}>
                <div style={{ paddingTop: 0, width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <h3 style={{ textAlign: "center", color: "#fff" }}>Você deseja excluir o Serviço {name}?</h3>
                    <div style={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                        justifyContent: "space-between"
                    }}>
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: "center", marginTop: 25
                        }}>

                            <ButtonC text={""} title={"Confirmar"} background={"#bb4444"}
                                width={"70%"} executar={() => {
                                    deleteService()
                                }} />
                        </div>
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: "center", marginTop: 15
                        }}>

                            <ButtonC text={""} title={"Cancelar"} background={"#888"}
                                width={"70%"} executar={() => {
                                    setVisibleModalDelete(false)
                                }} />
                        </div>
                    </div>
                </div>

            </ModalC>
        </Container>
    )
} 