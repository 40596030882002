import React, { useEffect, useState } from 'react';
import { Container, List, InputFilter, Boxs, ViewFilter } from './styles';
import { useNavigate } from "react-router-dom";
import loadingPhoto from "./../../../images/loading.gif"
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { MdAdd, MdDelete, MdEdit } from 'react-icons/md';
import ModalC from '../../../components/modal';
import { ButtonC } from '../../../components/simple';
import { useQuery } from '../../../hooks/Location';
import { Pagination } from 'antd';

export default function AdminCredentials() {

    const navigate = useNavigate();

    const query = useQuery()

    const [loading, setLoading] = useState(true)
    const [credentials, setCredentials] = useState([])
    const [page, setPage] = useState(0)
    const [credentialsTotal, setCredentialsTotal] = useState(0)
    const [filter, setFilter] = useState("")
    const [visibleModalDelete, setVisibleModalDelete] = useState(false)
    const [photo, setPhoto] = useState("")
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [id, setId] = useState("")

    useEffect(() => {
        setPage(parseInt(query.get("page")) || 0)
        setFilter(query.get("filter") || "")
        listCredentials()
    }, [window.location.href])

    async function listCredentials() {
        setLoading(true)
        await api.get(`/admin/list-credentials${window.location.href.split("/credentials")[1] || ""}`).then((response) => {
            setCredentials(response.data.credentials)
            setCredentialsTotal(response.data.total)
        }).catch(({ response }) => {
            if (response) {
                if (response.data) {
                    if (response.data.message) {
                        toast.warn(response.data.message)
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            } else {
                toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
        })
        setLoading(false)
    }

    async function deleteCredential() {
        await api.delete(`/credential/${id}`)
            .then(() => {
                toast.success("Credenciado excluido com sucesso")
                listCredentials()
                setVisibleModalDelete(false)
            }).catch(({ response }) => {
                if (response) {
                    if (response.data) {
                        if (response.data.message) {
                            toast.warn(response.data.message)
                        } else {
                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                        }
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            })
    }

    return (
        <Container>
            <div style={{ padding: "40px 0", width: "90%", flexDirection: "column", display: "flex", alignItems: "center" }}>
                            <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 20 }}>
                                <h1 style={{ margin: 0 }}>{credentialsTotal} Credenciados</h1>
                                <button
                                    onClick={() => {
                                        navigate(`/admin/new-credential`)
                                    }}
                                    style={{ width: 38, height: 38, marginLeft: 15, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#f2760a", borderRadius: 10 }}>
                                    <MdAdd color="#fff" size={29} />
                                </button>
                            </div>
                            <ViewFilter>
                            <InputFilter value={filter} onChange={(text) => {
                                setFilter(text.target.value)
                            }} placeholder='Procurar por nome, email ou cidade' />
                                <button onClick={() => {
                                        let url = `/admin/credentials`
                                        if(filter){
                                            url = url + `?filter=${filter}`
                                        }
                                        navigate(url)
                                    }}
                                >
                                    Buscar
                                </button>
                            </ViewFilter>



                {
                    loading ? (
                        <img src={loadingPhoto} style={{ background: "transparent", height: 50, width: 50, marginTop: 50 }} />
                    ) : (
                        <>
                            <List>
                                {credentials.length == 0 && (
                                    <h4 style={{ marginTop: 50, fontWeight: 500, width: "80%", textAlign: "center" }}>Nenhum credenciado encontrado</h4>
                                )}
                                {credentials.map((item) => {
                                    return (
                                        <div style={{ maxWidth: 280 }}>
                                            <img src={item.photo ? item.photo_url : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__480.png"} style={{ width: 250, height: 250 }} />
                                            <p>{item.name}</p>
                                            <p style={{ fontSize: 12, marginTop: 4 }}>{item.email}</p>
                                            {
                                                item.enabled ? (
                                                    <>
                                                        <p style={{ textTransform: "capitalize", fontSize: 12, marginTop: 4 }}>{item.visible ? "Visivel" : "Não visivel"}</p>
                                                        <p style={{ textTransform: "capitalize", fontSize: 15 }}>{item.city} - {item.state}</p>
                                                    </>
                                                ) : (
                                                    <p style={{ textTransform: "capitalize", fontSize: 15 }}>Cadastro não completo</p>
                                                )
                                            }
                                            <div style={{ padding: 0, margin: 0, marginTop: 10 }}>
                                                <button onClick={() => {
                                                    navigate(`/admin/credential/${item.id}`)
                                                }}>
                                                    <MdEdit color="#FFF" size={20} />
                                                </button>
                                                <button onClick={() => {
                                                    setId(item.id)
                                                    setName(item.name)
                                                    setEmail(item.email)
                                                    setPhoto({ photo_url: item.photo_url })
                                                    setVisibleModalDelete(true)
                                                }}>
                                                    <MdDelete color="#FFF" size={20} />
                                                </button>
                                            </div>
                                        </div>
                                    )
                                })}
                            </List>
                                <center style={{marginTop: 15}}>
                                    <Pagination simple defaultCurrent={page + 1} onChange={(page) => {
                                        let url = `/admin/credentials`
                                        if(filter){
                                            if(url != `/admin/credentials`){
                                                url = url + `&filter=${filter}`
                                            }else{
                                                url = url + `?filter=${filter}`
                                            }
                                        }
                                        if(url != `/admin/credentials`){
                                            url = url + `&page=${page - 1}`
                                        }else{
                                            url = url + `?page=${page - 1}`
                                        }
                                        navigate(url)
                                    }
                                } total={credentialsTotal} pageSize={30} showTotal={(total) => `${total} credenciados`}/>
                                </center>
                        </>
                    )
                }
            </div>

            <ModalC visible={visibleModalDelete} executar={() => { setVisibleModalDelete(false) }}>
                <div style={{ paddingTop: 0, width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <img style={{ borderRadius: 10, width: 200, height: 200, background: "#f0f0f0", marginBottom: 15 }} src={photo.photo_url ? photo.photo_url : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__480.png"} />
                    <p style={{
                        width: "90%",
                        fontSize: 17,
                        textAlign: "center", color: "#fff"
                    }}>{name}</p>
                    <span style={{
                        width: "95%",
                        fontSize: 14,
                        margin: "5px 0 15px 0",
                        textAlign: "center",
                        color: "#fffd"
                    }}>{email}</span>
                    <h3 style={{ textAlign: "center", color: "#fff" }}>Você deseja excluir o credenciado?</h3>
                    <div style={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                        justifyContent: "space-between"
                    }}>
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: "center", marginTop: 25
                        }}>

                            <ButtonC text={""} title={"Confirmar"} background={"#bb4444"}
                                width={"70%"} executar={() => {
                                    deleteCredential()
                                }} />
                        </div>
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: "center", marginTop: 15
                        }}>

                            <ButtonC text={""} title={"Cancelar"} background={"#888"}
                                width={"70%"} executar={() => {
                                    setVisibleModalDelete(false)
                                }} />
                        </div>
                    </div>
                </div>
            </ModalC>
        </Container>
    )
} 