import React, { useEffect, useState } from 'react';
import { Container, List, InputFilter, Boxs } from './styles';
import loadingPhoto from "./../../../images/loading.gif"
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { MdAdd, MdDelete, MdEdit, MdCheck } from 'react-icons/md';
import { useAuth } from '../../../hooks/AuthContext';
import { addDays, addYears, differenceInCalendarDays, format, isAfter, isSameDay, isSameYear } from 'date-fns';
import ModalC from '../../../components/modal';
import { ButtonC, InputC } from '../../../components/simple';
import {companiesEx} from "./../../../services/export"
import { Pagination } from 'antd';

const colorStatus = {
    "concluido": {color: "#21b82eAA", name: "Confirmado"},
    "breve":  {color: "#4287f5AA", name: "Em breve"},
    "mes": {color: "#ebb734AA", name: "Próximos 30 dias"},
    "vencido":  {color: "#d92b2bAA", name: "Vencido"},
}

export default function CompanyRenewal() {

    const { user } = useAuth();

    const [loading, setLoading] = useState(true)
    const [visibleModal, setVisibleModal] = useState(false)
    const [visibleModalDate, setVisibleModalDate] = useState(false)
    const [visibleModalDelete, setVisibleModalDelete] = useState(false)
    const [id, setId] = useState("")
    const [companies, setCompanies] = useState([])
    const [company, setCompany] = useState({})
    const [companiesC, setCompaniesC] = useState([])
    const [companiesAll, setCompaniesAll] = useState([])
    const [filtername, setFilterName] = useState("")
    const [fantasia, setFantasia] = useState("")
    const [cpfAndCnpj, setCpfAndCnpj] = useState("")
    const [type, setType] = useState("")
    const [phone_number, setPhone_number] = useState("")
    const [whatsapp, setWhatsapp] = useState("")
    const [region, setRegion] = useState("")
    const [page, setPage] = useState(0);
    const [date_init, setDate_init] = useState(new Date())
    const [date_init_text, setDate_init_text] = useState("")
    const [razao_social, setRazao_social] = useState("")
    const [observation, setObservation] = useState("")

    useEffect(() => {
        listCompanies()
        //exportCompanies()
    }, [])

    async function exportCompanies() {
            let error = 0
            companiesEx.map(async (item, index) => {
            if (index < 2000 && index > 1800) {
                let dateE = addDays(new Date(), 2)
            if (item["Data Cadastro"]) {
                dateE = item["Data Cadastro"].split("/")
        
                dateE = new Date(dateE[1]-1, dateE[0], dateE[2])
            }

            await api.post(`/company-renewal`, {
                fantasia: item["Nome Fantasia/Apelido"],
                type: item["Tipo"],
                region: String(item["Região"]),
                phone_number: "",
                whatsapp: "",
                date_init: dateE,
                cpf: item["Tipo"] == "PF" ? item["CPF"] : "",
                cnpj: item["Tipo"] == "PJ" ? item["CNPJ"] : "",
                razao_social: item["Razão Social/Nome"],
                observation: ""
            }).then((response) => {
                console.log("Empresa cadastrada com sucesso", index)
            }).catch(({ response }) => {
                error += 1
                console.log(error)
                if (response) {
                    if (response.data) {
                        if (response.data.message) {
                            console.log(response.data.message, index)
                        } else {
                            console.log("Erro Interno. verifique sua conexão e tente novamente")
                        }
                    } else {
                        console.log("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    console.log("Erro Interno. verifique sua conexão e tente novamente")
                }
            })
            }
        })
    }

    async function handlerDate() {
        await api.put(`/renewal/${id}`, {
            date_renewal: date_init,
            observation: observation
        }).then((response) => {
            listCompanies()
            toast.success("Confirmado a renovação e nova data atualizada com sucesso")
            setVisibleModalDate(false)
        }).catch(({ response }) => {
            if (response) {
                if (response.data) {
                    if (response.data.message) {
                        toast.warn(response.data.message)
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            } else {
                toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
        })
    }

    async function listCompanies() {
        await api.get(`/companies-renewal${user.type == "cliente" ? `?region=${user.region || null}` : ""}`).then((response) => {
            let companies = response.data
            companies.map((item) => {
                item.date_renewal = new Date(item.date_renewal)
                item.difference = differenceInCalendarDays(item.date_renewal, new Date())
                if (item.difference <= 0) {
                    if (isSameYear(new Date(), item.date_renewal)) {
                        item.status = "vencido"
                    } else {
                        item.status = "concluido"
                    }
                } else {
                    if (isSameYear(new Date(), item.date_renewal)) {
                        if (item.difference > 30) {
                            item.status = "breve"
                        } else {
                            item.status = "mes"
                        }
                    } else {
                        item.status = "concluido"
                    }
                }
            })
            setCompaniesAll(companies)
            if (user.type == "tecnico") {
                let companiesC = []
                companies.map((item, index) => {
                    if (user.id == "cc0834ec-994b-4601-b5fe-381f8739c2dc" && index % 2 == 0) {
                        companiesC.push(item)
                    } else {
                        if (user.id == "73fe590a-7d24-4122-a208-5e6c1578b0b2" && index % 2 == 1) {
                            companiesC.push(item)
                        }
                    }
                })
                companies = companiesC
            }
            setCompanies(companies)
            setCompaniesC(companies)
        }).catch(({ response }) => {
            if (response) {
                if (response.data) {
                    if (response.data.message) {
                        toast.warn(response.data.message)
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            } else {
                toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
        })
        setFilterName("")
        setLoading(false)
    }

    function filterCompanies(value) {
        let CompaniesCopy = companiesC
        if (user.type == "tecnico") {
            CompaniesCopy = companiesAll
        }
        if (!value) {
            CompaniesCopy = companiesC
        }
        let Companies = []
        CompaniesCopy.map((item) => {
            if (String(item.fantasia)
                .toUpperCase()
                .indexOf(value.toUpperCase()) != -1 || String(item.razao_social)
                .toUpperCase()
                .indexOf(value.toUpperCase()) != -1) {
                Companies.push(item)
            }
        })
        setPage(0)
        setCompanies(Companies)
    }

    async function createCompany() {
        if (!razao_social || !fantasia || !date_init || !type || !cpfAndCnpj || !region || !date_init || !phone_number) {
            toast.warn("Preencha todos os campos")
        } else {
            await api.post(`/company-renewal`, {
                fantasia: fantasia,
                type: type,
                region: region,
                phone_number: phone_number,
                whatsapp: whatsapp,
                date_init: date_init,
                date_renewal: date_init,
                cpf: type == "PF" ? cpfAndCnpj : "",
                cnpj: type == "PJ" ? cpfAndCnpj : "",
                razao_social: razao_social,
                observation: observation
            }).then((response) => {
                listCompanies()
                toast.success("Empresa cadastrada com sucesso")
                setVisibleModal(false)
            }).catch(({ response }) => {
                if (response) {
                    if (response.data) {
                        if (response.data.message) {
                            toast.warn(response.data.message)
                        } else {
                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                        }
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            })
        }
    }

    async function deleteCompany() {
        await api.delete(`/company-renewal/${company.id}`)
            .then(() => {
                toast.success("Empresa excluida com sucesso")
                listCompanies()
                setVisibleModalDelete(false)
            }).catch(({ response }) => {
                if (response) {
                    if (response.data) {
                        if (response.data.message) {
                            toast.warn(response.data.message)
                        } else {
                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                        }
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            })
    }

    async function handlerCompany() {
        if (!id || !razao_social || !fantasia || !date_init || !type || !cpfAndCnpj || !region || !date_init || !phone_number) {
            toast.warn("Preencha todos os campos")
        } else {
            await api.put(`/company-renewal/${id}`, {
                fantasia: fantasia,
                type: type,
                region: region,
                phone_number: phone_number,
                whatsapp: whatsapp,
                date_init: date_init,
                date_renewal: date_init,
                cpf: type == "PF" ? cpfAndCnpj : "",
                cnpj: type == "PJ" ? cpfAndCnpj : "",
                razao_social: razao_social,
                observation: observation
            }).then((response) => {
                listCompanies()
                toast.success("Empresa atualizada com sucesso")
                setVisibleModal(false)
            }).catch(({ response }) => {
                if (response) {
                    if (response.data) {
                        if (response.data.message) {
                            toast.warn(response.data.message)
                        } else {
                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                        }
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            })
        }
    }
    return (
        <Container>
            <div style={{ padding: "40px 0", width: "90%", flexDirection: "column", display: "flex", alignItems: "center" }}>
                {
                    loading ? (
                        <img src={loadingPhoto} style={{ background: "transparent", height: 50, width: 50 }} />
                    ) : (
                        <>
                            <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 15 }}>
                                <h1 style={{ margin: 0 }}>{companies.length} Empresas</h1>
                                <button
                                    onClick={() => {
                                        setRazao_social("")
                                        setFantasia("")
                                        setType("")
                                        setCpfAndCnpj("")
                                        setPhone_number("")
                                        setWhatsapp("")
                                        setRegion("")
                                        setObservation("")
                                        setId("")
                                        setDate_init(addYears(new Date(), 1))
                                        setDate_init_text(format(addYears(new Date(), 1), "yyyy-MM-dd"))
                                        setVisibleModal(true)
                                    }}
                                    style={{ width: 38, height: 38, marginLeft: 15, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#f2760a", borderRadius: 10 }}>
                                    <MdAdd color="#fff" size={29} />
                                </button>
                            </div>

                            <Boxs>
                                <div style={{ background: "#21b82e" }}>
                                    <h3>Concluidos</h3>
                                    <p>{companies.filter((item) => { return item.status == "concluido" }).length}</p>
                                </div>
                                <div style={{ background: "#d92b2b" }}>
                                    <h3>Vencidos</h3>
                                    <p>{companies.filter((item) => { return item.status == "vencido" }).length}</p>
                                </div>
                                <div style={{ background: "#ebb734" }}>
                                    <h3>Próximos 30 dias</h3>
                                    <p>{companies.filter((item) => { return item.status == "mes" }).length}</p>
                                </div>
                                <div style={{ background: "#4287f5" }}>
                                    <h3>Em breve</h3>
                                    <p>{companies.filter((item) => { return item.status == "breve" }).length}</p>
                                </div>
                            </Boxs>
                            <InputFilter value={filtername} onChange={(text) => {
                                setFilterName(text.target.value)
                                filterCompanies(text.target.value)
                            }} placeholder='Procurar por nome' />


                            <List>
                                {companies.length == 0 && (
                                    <h4 style={{ marginTop: 50, fontWeight: 500, width: "80%", textAlign: "center" }}>Nenhuma empresa encontrada</h4>
                                )}
                                {companies.map((item, index) => {
                                  if(index < 50*(page+1) && index >= 50*page)
                                    return (
                                        <div style={{ backgroundColor: colorStatus[item.status].color }}>
                                            <span>Data de Renovação: {format(new Date(item.date_renewal), "dd/MM/yyyy")}</span>
                                            <p>Razão Social: {item.razao_social || "Não cadastrado"}</p>
                                            <p>Fantasia: {item.fantasia || "Não cadastrado"}</p>
                                            <p>Região: {item.region}</p>
                                            {item.phone_number && (<p>Telefone: {item.phone_number}</p>)}
                                            <p>{item.type == "PF"? "CPF": "CNPJ"}: {item.cpf ? item.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4') : item.cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')}</p>
                                            <p style={{ position: "absolute", bottom: 15, fontSize: 15 }}>{ item.status == "vencido" ? item.difference == 0 ? "Vence hoje" : `Venceu a ${item.difference*-1} dias`: item.difference == 1 ?  "Renovação amanhã" : `Renovação em ${item.difference} dias`}</p>
                                            <p style={{ width: 50, textAlign: "right", position: "absolute", left: 289, bottom: 15, fontSize: 15 }}>{index+1}</p>
                                            <button style={{ top: 10 }} onClick={() => {
                                                setId(item.id)
                                                setRazao_social(item.razao_social)
                                                setFantasia(item.fantasia)
                                                setType(item.type)
                                                setCpfAndCnpj(item.cpf || item.cnpj)
                                                setPhone_number(item.phone_number)
                                                setWhatsapp(item.whatsapp)
                                                setRegion(item.region)
                                                setObservation(item.observation)
                                                setDate_init(new Date(item.date_renewal))
                                                setDate_init_text(format(new Date(item.date_renewal), "yyyy-MM-dd"))
                                                setVisibleModal(true)
                                            }}>
                                                <MdEdit color="#000" size={15} />
                                            </button>
                                            <button style={{ top: 44 }} onClick={() => {
                                                setId(item.id)
                                                setObservation(item.observation)
                                                setDate_init(addYears(new Date(item.date_renewal), 1))
                                                setDate_init_text(format(addYears(new Date(item.date_renewal), 1), "yyyy-MM-dd"))
                                                setRazao_social(item.razao_social)
                                                setVisibleModalDate(true)
                                            }}>
                                                <MdCheck color="#000" size={15} />
                                            </button>
                                            <button style={{top: 78}} onClick={() => {
                                                setCompany(item)
                                                setVisibleModalDelete(true)
                                            }}>
                                                <MdDelete color="#000" size={15} />
                                            </button>
                                        </div>
                                    )
                                })}
                            </List>
                            <center style={{margin: "25px 0"}}>
                                <Pagination simple current={page + 1} onChange={(page) => {
                                setPage(page - 1)
                            }} total={companies.length} pageSize={50} 
                            showTotal={(total) => `${companies.length} empresas`}/>
                            </center>
                        </>
                    )
                }
            </div>
            <ModalC visible={visibleModal} executar={() => { setVisibleModal(false) }}>
                <div style={{ paddingTop: 0, width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center" }}>
                    <InputC text={razao_social} title={"Razão Social"} placeholder={"razão social"}
                        styleContent={{ margin: "0 10px 20px 10px" }} width={"100%"}
                        height={"55px"} color="#fff" executar={(text) => { setRazao_social(text) }} />
                    <InputC text={fantasia} title={"Nome Fantasia"} placeholder={"nome fantasia"}
                        styleContent={{ margin: "0 10px 20px 10px" }} width={"100%"}
                        height={"55px"} color="#fff" executar={(text) => { setFantasia(text) }} />
                    <div style={{width: "calc(40% - 20px)", margin: "0 10px 0px 10px"}}>
                        <h3 style={{
                            color: "#fff",
                            fontSize: 15,
                            width: "100%",
                            textAlign: "left",
                            margin: "10px 0 5px 0",
                            fontWeight: "bold"
                        }}>Tipo de Empresa</h3>
                        <select
                            value={type}
                            style={{
                                width: "100%",
                                backgroundColor: "#FFF",
                                borderWidth: 1,
                                borderColor: "#ccc",
                                borderStyle: "solid",
                                color: "#444",
                                padding: "10px",
                                fontSize: 14,
                                borderRadius: 5,
                                fontWeight: "400",
                                paddingLeft: 8,
                            }}
                            onChange={(text) => {
                                setType(text.target.value)
                            }}
                        >
                            <option disabled hidden value={""}>Seleciona uma opção</option>
                            <option value={"PF"}>Pessoa Fisica</option>
                            <option value={"PJ"}>Pessoa Juridica</option>
                        </select>
                    </div>
                    
                    {type ? (
                        <InputC text={cpfAndCnpj} title={type == "PF" ? "CPF" : "CNPJ"} placeholder={type == "PF" ? "cpf" : "cnpj"}
                        styleContent={{ margin: "0 10px 20px 10px" }} width={"calc(60% - 20px)"}
                        height={"55px"} color="#fff" executar={(text) => { setCpfAndCnpj(text) }} />
                    ) : <div style={{width: "calc(60% - 20px)"}}></div>}
                    <InputC text={phone_number} title={"Telefone"} placeholder={"telefone"}
                        styleContent={{ margin: "0 10px 20px 10px" }} width={"calc(50% - 20px)"}
                        height={"55px"} color="#fff" executar={(text) => { setPhone_number(text) }} />
                    <InputC text={whatsapp} title={"Whatsapp"} placeholder={"whatsapp"}
                        styleContent={{ margin: "0 10px 20px 10px" }} width={"calc(50% - 20px)"}
                        height={"55px"} color="#fff" executar={(text) => { setWhatsapp(text) }} />
                    
                    <div style={{width: "calc(40% - 20px)", margin: "0 10px 0px 10px"}}>
                        <h3 style={{
                            color: "#fff",
                            fontSize: 15,
                            width: "100%",
                            textAlign: "left",
                            margin: "10px 0 5px 0",
                            fontWeight: "bold"
                        }}>Data de Renovação</h3>
                        <input
                            style={{
                                width: "100%",
                                backgroundColor: "#FFF",
                                borderWidth: 1,
                                borderColor: "#ccc",
                                borderStyle: "solid",
                                color: "#444",
                                padding: "10px",
                                fontSize: 14,
                                borderRadius: 5,
                                fontWeight: "400",
                                paddingLeft: 8,
                            }}
                            type="date" min={format(new Date(), "yyyy-MM-dd")}  value={date_init_text} onChange={(date) => {
                                if (date.target.value.length <= 10) {
                                    setDate_init_text(date.target.value)
                                    if (date.target.value.length == 10) {
                                        let dateE = date.target.value.split("-")
                                        setDate_init(new Date(dateE[0], dateE[1] - 1, dateE[2]))
                                    }
                                }
                            }} />
                    </div>
                    
                    <div style={{width: "calc(60% - 20px)", margin: "0 10px 0px 10px"}}>
                        <h3 style={{
                            color: "#fff",
                            fontSize: 15,
                            width: "100%",
                            textAlign: "left",
                            margin: "10px 0 5px 0",
                            fontWeight: "bold"
                        }}>Região</h3>
                        <select
                            value={region}
                            style={{
                                width: "100%",
                                backgroundColor: "#FFF",
                                borderWidth: 1,
                                borderColor: "#ccc",
                                borderStyle: "solid",
                                color: "#444",
                                padding: "10px",
                                fontSize: 14,
                                borderRadius: 5,
                                fontWeight: "400",
                                paddingLeft: 8,
                            }}
                            onChange={(text) => {
                                setRegion(text.target.value)
                            }}
                        >
                            <option disabled hidden value={""}>Seleciona uma opção</option>
                            <option value={"LEONARDO (GURUPI) TO"}>LEONARDO (GURUPI) TO</option>
                            <option value={"SILMÁRIO (ARAGUAINA) TO"}>SILMÁRIO (ARAGUAINA) TO</option>
                            <option value={"ERIZETE (ABC PAULISTA) SP"}>ERIZETE (ABC PAULISTA) SP</option>
                            <option value={"ALMAS - TO"}>ALMAS - TO</option>
                            <option value={"INACIO OU FABRICIO(FORMOSO) TO"}>INACIO OU FABRICIO(FORMOSO) TO</option>
                            <option value={"FRANQUEADORA GURUSEG"}>FRANQUEADORA GURUSEG</option>
                            <option value={"NILVA (GURUPI) TO"}>NILVA (GURUPI) TO</option>
                            <option value={"WELINTON (PRESIDENTE EPITACIO) SP"}>WELINTON (PRESIDENTE EPITACIO) SP</option>
                            <option value={"CAPITÃO POÇO - PA"}>CAPITÃO POÇO - PA</option>
                            <option value={"JEFERSON (SANTO ANDRE) SP"}>JEFERSON (SANTO ANDRE) SP</option>
                            <option value={"CARINA (ARAGUARI) SC"}>CARINA (ARAGUARI) SC</option>
                            <option value={"ANGELA (BARUERI) SP"}>ANGELA (BARUERI) SP</option>
                            <option value={"MARCOS (RIBEIRÃO PRETO) SP"}>MARCOS (RIBEIRÃO PRETO) SP</option>
                            <option value={"GUAIRA GABRIEL"}>GUAIRA GABRIEL</option>
                            <option value={"WAGNER (RIO DE JANEIRO) RJ"}>WAGNER (RIO DE JANEIRO) RJ</option>
                            <option value={"MOGI SP - MARCOS"}>MOGI SP - MARCOS</option>
                            <option value={"MARABA PA JACKSON"}>MARABA PA JACKSON</option>
                            <option value={"MACAE RJ PAULO"}>MACAE RJ PAULO</option>
                            <option value={"RENAN (MARINGÁ)-PR"}>RENAN (MARINGÁ)-PR</option>
                            <option value={"FORMOSO DO ARAGUAIA"}>FORMOSO DO ARAGUAIA</option>
                            <option value={"TARCISO (CHAPADA) RS"}>TARCISO (CHAPADA) RS</option>
                            <option value={"EDIANI (SÃO BERNARD) SP"}>EDIANI (SÃO BERNARD) SP</option>
                            <option value={"ENIVALDIR (VOLTA REDONDA) RJ"}>ENIVALDIR (VOLTA REDONDA) RJ</option>
                            <option value={"EUCIDEMAR (CASTANHAL) PA"}>EUCIDEMAR (CASTANHAL) PA</option>
                            <option value={"GISELI (ANÁPOLIS) GO"}>GISELI (ANÁPOLIS) GO</option>
                            <option value={"ROGER (CAXIAS DO SUL) RS"}>ROGER (CAXIAS DO SUL) RS</option>
                            <option value={"ALZENIRA(REDENÇÃO) PA"}>ALZENIRA(REDENÇÃO) PA</option>
                            <option value={"LEANDRO (RIO DE JANEIRO) RJ"}>LEANDRO (RIO DE JANEIRO) RJ</option>
                            <option value={"ELIETE (FORMOSO DO ARAGUAIA) TO"}>ELIETE (FORMOSO DO ARAGUAIA) TO</option>                    
                        </select>
                    </div>
                    <InputC text={observation} title={"Observação"} placeholder={"observação"}
                        styleContent={{ margin: "0 10px 20px 10px" }} width={"100%"} minHeight={"120px"}
                        multiline={true} color="#fff" executar={(text) => { setObservation(text) }} />
                    <div style={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                        justifyContent: "space-between"
                    }}>
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: "center", marginTop: 15
                        }}>
                            <ButtonC text={""} title={id ? "Salvar" :"Cadastrar"} background={"#f2760a"}
                                width={"100%"} executar={() => {
                                    if (id) {
                                        handlerCompany()
                                    } else {
                                        createCompany()
                                    }
                                    
                                }} />
                        </div>
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: "center", marginTop: 15
                        }}>

                            <ButtonC text={""} title={"Cancelar"} background={"#888"}
                                width={"100%"} executar={() => {
                                    setVisibleModal(false)
                                }} />
                        </div>
                    </div>
                </div>

            </ModalC>
            <ModalC visible={visibleModalDate} executar={() => { setVisibleModal(false) }}>
                <div style={{ paddingTop: 0, width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center" }}>
                    <h3 style={{ textAlign: "center", color: "#fff" }}>Você deseja confirmar a renocacao e atualizar a proxima data da empresa {razao_social}?</h3>   
                    <h3 style={{
                            color: "#fff",
                            fontSize: 15,
                            width: "100%",
                            textAlign: "left",
                            margin: "10px 0 5px 0",
                            fontWeight: "bold"
                        }}>Próxima data de Renovação</h3>
                        <input
                            style={{
                                width: "100%",
                                backgroundColor: "#FFF",
                                borderWidth: 1,
                                borderColor: "#ccc",
                                borderStyle: "solid",
                                color: "#444",
                                padding: "10px",
                                fontSize: 14,
                                borderRadius: 5,
                                fontWeight: "400",
                                paddingLeft: 8,
                            }}
                            type="date" min={format(new Date(), "yyyy-MM-dd")}  value={date_init_text} onChange={(date) => {
                                if (date.target.value.length <= 10) {
                                    setDate_init_text(date.target.value)
                                    if (date.target.value.length == 10) {
                                        let dateE = date.target.value.split("-")
                                        setDate_init(new Date(dateE[0], dateE[1]-1, dateE[2]))
                                    }
                                }
                        }} />
                    
                    <InputC text={observation} title={"Observação"} placeholder={"observação"}
                        styleContent={{ margin: "0 0 20px 0" }} width={"100%"} minHeight={"120px"}
                        multiline={true} color="#fff" executar={(text) => { setObservation(text) }} />
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: "center", marginTop: 15
                        }}>
                            <ButtonC text={""} title={id ? "Salvar" :"Cadastrar"} background={"#f2760a"}
                                width={"100%"} executar={() => {
                                    handlerDate()
                                }} />
                        </div>
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: "center", marginTop: 15
                    }}> 
                        

                            <ButtonC text={""} title={"Cancelar"} background={"#888"}
                                width={"100%"} executar={() => {
                                    setVisibleModalDate(false)
                            }} />
                    </div>
                </div>

            </ModalC>
            <ModalC visible={visibleModalDelete} executar={() => { setVisibleModalDelete(false) }}>
                <div style={{ paddingTop: 0, width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <h3 style={{ textAlign: "center", color: "#fff" }}>Você deseja excluir a empresa {company.razao_social}?</h3>
                    <div style={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                        justifyContent: "space-between"
                    }}>
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: "center", marginTop: 25
                        }}>

                            <ButtonC text={""} title={"Confirmar"} background={"#bb4444"}
                                width={"70%"} executar={() => {
                                    deleteCompany(company.id)
                                }} />
                        </div>
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: "center", marginTop: 15
                        }}>

                            <ButtonC text={""} title={"Cancelar"} background={"#888"}
                                width={"70%"} executar={() => {
                                    setVisibleModalDelete(false)
                                }} />
                        </div>
                    </div>
                </div>

            </ModalC>
        </Container>
    )
} 