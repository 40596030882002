import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  align-items: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  h1{
    text-align: center;
    margin-bottom: 15px;
    color: #f2760a;
  }
  @media (max-width: 750px) {
  h1{
    text-align: center;
    font-size: 22px;
    margin-bottom: 15px;
  }
  }
`;


export const List = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 25px 0;

  div{
  background: #f0f0f0;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  border-radius: 5px;
  margin: 15px; 
  padding: 15px;

        img{
          width: 100%;
          max-height: 250px;
          margin-bottom: 15px;
          border-radius: 5px;
        }
        p{
          width: 100%;
          font-size: 17px;
          text-align: center;
          color: #000
        }
        span{
          margin: 5px 0;
           width: 100%;
          font-size: 14px;
          text-align: left;
          color: #000
        }
     

        button{
        padding: 6px 25px;
        border-radius: 5px;
        background-color: #f2760a;
        color: #fff;
        height: 35px;
        margin: 0 4px;
        align-items: center;
        justify-content: center;

        
    }
  }
`;

export const ViewFilter = styled.div`
        display: flex;
        height: 40px;
        width: 100%;
        max-width: 580px;
        border: none;
        justify-content: space-between;
        font-size: 16px;


  button{
        padding: 6px 25px;
        border-radius: 5px;
        background-color: #f2760a;
        color: #fff;
        font-size: 16px;
        margin-left: 20px;
        height: 40px;
        align-items: center;
        justify-content: center;

        
    }
`;


export const InputFilter = styled.input`
        padding: 8px;
        border-radius: 5px;
        background-color: #f0f0f0;
        color: #000c;
        height: 40px;
        width: 100%;
        max-width: 450px;
        border: none;
        font-size: 16px;
`;

