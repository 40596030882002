import styled from "styled-components";

export const Container = styled.div`
  width: 90%; 
  align-items: center;
  min-min-height: 100vh;
  display: flex;
  padding: 40px 0;
  flex-direction: column;
  align-items: center;
  h1{
    text-align: center;
    color: #f2760a;
  }


  h3{
    text-align: center;
    margin-bottom: 25px;
  }

  a{
    background-color: #f2760a;
    color: #fff;
    width: 250px;
    margin-bottom: 15px;
    padding: 15px 20px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
  }
  
  @media (max-width: 750px) {
  padding: 20px 0;
  width: 100%;
  h1{
    text-align: center;
    font-size: 22px;
    margin-bottom: 15px;
  }
  a{
    width: 85%;
    max-width: 250px;
  }

  }

`;
