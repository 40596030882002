import React, { useMemo } from 'react';
import {
  MdAddCircle,
  MdPendingActions,
  MdDesignServices,
  MdAccountBalance,
  MdClose,
  MdHome,
  MdMenuBook,
  MdWork,
  MdBusiness,
  MdAutorenew,
  MdCalendarToday
} from 'react-icons/md';
import {
  FaFileContract,
  FaRegImages,
  FaDraftingCompass,
  FaUsers,
  FaSignature,
  FaRegistered
} from 'react-icons/fa';
import {
  RiFileEditLine,
} from 'react-icons/ri';
import {
  TbLetterT,
} from 'react-icons/tb';

import { Link, useNavigate } from 'react-router-dom';
import { Container, Logo, Menu, Close } from './styles';
import logo from '../../images/logo.jpeg';
import { useAuth } from '../../hooks/AuthContext';
import { toast } from 'react-toastify';

function Sidebar({isMenuMinimized, onChange}) {
  const { user } = useAuth();
  const navigate = useNavigate()

  return (
    <Container isMenuMinimized={isMenuMinimized}>
      <Close onClick={()=>{onChange()}}>
        <MdClose color='#001B25'/>
      </Close>
      <Link onClick={()=>{
        onChange(false)
      }} to={`/${user.type}s/perfil`}>
        <Logo isMenuMinimized={isMenuMinimized}>
          <img src={logo} alt="" />
        </Logo>
      </Link>
      <Menu isMenuMinimized={isMenuMinimized}>
        <li>
            <a onClick={() => {
                onChange(false)
                navigate(`/${user.type == "admin" ? user.type : user.type+"s"}/home`)
            }}>
            <MdHome size={25} />
              <span>Home</span></a>
        </li>
        {(user.type == "cliente" || user.type == "tecnico") && (
          <li>
            <a onClick={() => {
                
                onChange(false)
                navigate(`/${user.type}s/solicitacoes`)
            }}>
            <MdDesignServices size={25} />
            <span>Minhas OSs</span></a>
          </li>
        )}
        {(user.type == "cliente" || user.type == "tecnico") && (
          <li>
            <a onClick={() => {
                
                onChange(false)
                navigate(`/${user.type}s/financeiro`)
            }}>
            <MdAccountBalance size={25} />
            <span>Financeiro</span></a>
          </li>
        )}
        {user.type == "tecnico" && (
          <li>
            <a onClick={() => {
                
                onChange(false)
                navigate(`/${user.type}s/open`)
            }}>
            <MdPendingActions size={25} />
            <span>OSs em Aberto</span></a>
          </li>
        )}
        {user.type == "cliente"  && (
          <li>
            <a onClick={() => {
                
                onChange(false)
                navigate(`/solicitacao`)
            }}>
            <MdAddCircle size={25} />
            <span>Solicitar OS</span></a>
          </li>
        )}
        {user.type == "cliente" && user.course && (
          <li>
            <a onClick={() => {
                onChange(false)
                navigate(`/courses`)
            }}>
            <MdMenuBook size={25} />
            <span>Área do Conhecimento</span></a>
          </li>
        )}
        {(user.type == "admin") && (
          <li>
            <a onClick={() => {
                
                onChange(false)
                navigate(`/admin/tecnicos`)
            }}>
            <FaDraftingCompass size={22} />
            <span>Técnicos</span></a>
          </li>
        )}
        {(user.type == "admin") && (
          <li>
            <a onClick={() => {
                
                onChange(false)
                navigate(`/admin/clientes`)
            }}>
            <FaUsers size={22} />
            <span>Franqueados</span></a>
          </li>
        )}
        {(user.type == "admin") && (
          <li>
            <a onClick={() => {
                
                onChange(false)
                navigate(`/admin/revenda`)
            }}>
            <FaRegistered size={22} />
            <span>Revenda treinamentos</span></a>
          </li>
        )}
        {(user.type == "admin") && (
          <li>
            <a onClick={() => {
                
                onChange(false)
                navigate(`/admin/assinatura`)
            }}>
            <FaSignature size={22} />
            <span>Assinatura</span></a>
          </li>
        )}
        {(user.type == "admin") && (
          <li>
            <a onClick={() => {
                
                onChange(false)
                navigate(`/admin/terceirize`)
            }}>
            <TbLetterT  size={22} />
            <span>Terceirize</span></a>
          </li>
        )}
        {(user.type == "admin") && (
          <li>
            <a onClick={() => {
                
                onChange(false)
                navigate(`/admin/services`)
            }}>
            <MdWork size={25} />
            <span>Serviços</span></a>
          </li>
        )}
        {(user.type == "admin") && (
          <li>
            <a onClick={() => {
                
                onChange(false)
                navigate(`/admin/banners`)
            }}>
            <FaRegImages size={25} />
            <span>Banners</span></a>
          </li>
        )}
        {(user.type == "admin") && (
          <li>
            <a onClick={() => {
                
                onChange(false)
                navigate(`/admin/courses`)
            }}>
            <MdMenuBook size={25} />
            <span>Cursos</span></a>
          </li>
        )}
        {(user.type == "admin") && (
          <li>
            <a onClick={() => {
                
                onChange(false)
                navigate(`/contracts`)
            }}>
            <FaFileContract size={22} />
            <span>Contratos</span></a>
          </li>
        )}
        {(user.type == "admin") && (
          <li>
            <a onClick={() => {
                
                onChange(false)
                navigate(`/admin/credentials`)
            }}>
            <MdBusiness size={25} />
            <span>Credenciados</span></a>
          </li>
        )}
        {
          (user.type == "cliente" && !user.resale && !user.signature) && (
            <li>
                        <a onClick={() => {
                            if (user.course_restricted) {
                                toast.warn("Área restrita para franqueados")
                            } else {
                                navigate("/contracts")
                            }
                        }}>
                        <FaFileContract size={22} />
                        <span>Contratos</span></a>
                        </li>
                    )
                }
                {
          (user.type == "cliente" && !user.resale && !user.signature) && (
            <li>
                        <a onClick={() => {
                            if (user.course_restricted) {
                                toast.warn("Área restrita para franqueados")
                            } else {
                                navigate("/credentials")
                            }
                        }}>
                        <MdBusiness size={25} />
                        <span>Credenciados</span></a></li>
                    )
                }
        {(user.type == "cliente" && !user.resale && !user.signature) && (
          <li>
            <a onClick={() => {
              if (user.course_restricted) {
                toast.warn("Área restrita para franqueados")
            } else {
                navigate("/companies")
            }
            }}>
              <RiFileEditLine size={25} />
              <span>Formulário de Levantamento</span></a>
          </li>
        )}
          <li>
            <a onClick={() => {
                navigate(`/${user.type}/renewal`)
            }}>
            <MdAutorenew size={25} />
            <span>Renovação das Empresas</span></a>
          </li>
          <li>
            <a onClick={() => {
                navigate(`/${user.type}/timeline`)
            }}>
            <MdCalendarToday size={25} />
            <span>Cronograma das Empresas</span></a>
          </li>
      </Menu>
    </Container>
  );
}

export default Sidebar;