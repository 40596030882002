import React, { useEffect, useState } from 'react';
import { Container } from './styles';
import { useNavigate, useParams } from "react-router-dom";
import loadingfile from "./../../../images/loading.gif"
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { InputC, ButtonC } from "../../../components/simple";
import { MdDelete, MdUpload } from 'react-icons/md';
import { useMediaQuery } from 'react-responsive';


export default function LessonAdmin() {

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })

    const { course_id, id } = useParams()

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true)
    const [file, setFile] = useState("")
    const [fileName, setFileName] = useState("")
    const [idLesson, setIdLesson] = useState("")
    const [video, setVideo] = useState("")
    const [name, setName] = useState("")
    const [order, setOrder] = useState("")
    const [deleteFile, setDeleteFile] = useState("")
    const [description, setDescription] = useState("")

    useEffect(() => {
        if (id != "new") {
            getLession()
            setIdLesson(id)
        }
        setLoading(false)
    }, [])

    async function getLession() {
        await api.get(`/lesson/${id}`).then((response) => {
            let lesson = response.data
            setName(lesson.name)
            setDescription(lesson.description)
            if (lesson.file) {
                setFile({ name: lesson.file })
            }
            setVideo(lesson.video)
            setOrder(lesson.order)
            if (lesson.file_name != "null") {
                setFileName(lesson.file_name)
            }

        }).catch(({ response }) => {
            if (response) {
                if (response.data) {
                    if (response.data.message) {
                        toast.warn(response.data.message)
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            } else {
                toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
        })
    }

    async function createAndEditLesson() {
        if (!name || (!file && !description && !video)) {
            toast.warn('Preencha name, descrição e envia uma imagem para criar novo Aula');
        } else {
            const data = new FormData();
            data.append('name', name);
            data.append('order', order);
            data.append('video', video);
            data.append('course_id', course_id);
            data.append('file_name', fileName);
            data.append('delete_file', deleteFile);
            data.append('description', description);
            if (file.file_url) {
                data.append('file', file, file.name);
            }
            if (idLesson) {
                await api.put(`/lesson/${idLesson}`, data).then(async (ok) => {
                    toast.success("Aula editada com sucesso")
                }).catch((response) => {
                    if (response) {
                        if (response.data) {
                            if (response.data.message) {
                                toast.warn(response.data.message)
                            } else {
                                toast.error("Erro Interno. verifique sua conexão e tente novamente")
                            }
                        } else {
                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                        }
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                })
            } else {
                await api.post(`/lesson`, data).then(async (ok) => {
                    toast.success("Aula criada com sucesso")
                    navigate(-1)
                }).catch((response) => {
                    if (response) {
                        if (response.data) {
                            if (response.data.message) {
                                toast.warn(response.data.message)
                            } else {
                                toast.error("Erro Interno. verifique sua conexão e tente novamente")
                            }
                        } else {
                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                        }
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                })
            }
        }
    }

    return (
        <Container>
            {
                loading ? (
                    <img src={loadingfile} style={{ background: "transparent", height: 50, width: 50 }} />
                ) : (
                    <>

                        <h1>Aula</h1>
                        <InputC text={name} title={"Nome do Aula"} placeholder={"Aprendendo.."} width={"100%"} height={"55px"} color="#999" executar={(text) => { setName(text) }} />

                        <InputC text={order} title={"Ordem do Aula"} placeholder={"0"} width={"100%"} height={"55px"} color="#999" executar={(text) => { setOrder(text) }} />
                        <InputC text={video} title={"Link YouTube"} placeholder={"link"} width={"100%"} height={"55px"} color="#999" executar={(text) => { setVideo(text) }} />
                        <InputC text={description} title={"Descrição do Aula"} placeholder={"Aprendendo.."} width={"100%"} minHeight={"155px"} multiline={true} color="#999" executar={(text) => { setDescription(text) }} />
                        <InputC text={fileName} title={"Nome do Arquivo"} placeholder={"Conteúdo da aula"} width={"100%"} height={"55px"} color="#999" executar={(text) => { setFileName(text) }} />
                        <h3>Arquivo da aula</h3>
                        <div style={{ width: "100%", display: "flex" }}>
                            <div for="avatar" style={{ position: "relative", backgroundColor: "#f2760a", width: isDesktopOrLaptop ? "400px" : "100%", display: "flex", flexDirection: "column", alignItems: "center", padding: 10, borderRadius: 10 }}>
                                <p style={{ color: "#fff", marginBottom: 10, textAlign: "center" }}>{file ? fileName ? fileName : file.name : "Enviar arquivo"}</p>
                                <MdUpload size={25} color="#fff" />
                                <input id="avatar" style={{ cursor: "pointer", position: "absolute", top: 0, width: "100%", height: "100%", opacity: 0 }} multiple type="file" accept="image/" onChange={(file) => {
                                    let fileR = file.target.files[0]
                                    fileR.file_url = URL.createObjectURL(file.target.files[0])
                                    setFileName(fileR.name)
                                    setFile(fileR)
                                    setDeleteFile("")
                                }} />
                            </div>
                            {file &&
                                (
                                    <div onClick={() => {
                                        setFile("")
                                        setFileName("")
                                        setDeleteFile("true")
                                    }} style={{ position: "relative", backgroundColor: "#f2760a", width: 40, display: "flex", justifyContent: "center", alignItems: "center", padding: 10, borderRadius: 10, marginLeft: 15 }}>
                                        <MdDelete size={25} color="#fff" />
                                    </div>
                                )}
                        </div>

                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", margin: "35px 0" }}>
                            <ButtonC text={""} title={"Voltar"}
                                width={"40%"} executar={() => {
                                    navigate(-1)
                                }} />
                            <ButtonC text={""} title={"Salvar"}
                                width={"55%"} executar={() => {
                                    createAndEditLesson()
                                }} />
                        </div>

                    </>
                )
            }

        </Container>
    )
} 