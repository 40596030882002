import React, { useEffect, useState } from 'react';
import { Container, List } from './styles';
import { useParams, useNavigate } from "react-router-dom";
import { useAuth } from './../../../hooks/AuthContext';
import ModalC from '../../../components/modal';
import { IconButton } from '@mui/material';
import { MdAddPhotoAlternate } from 'react-icons/md';
import { ButtonC, InputC, InputTel } from '../../../components/simple';
import api from '../../../services/api';
import { toast } from 'react-toastify';

export default function PerfilFranqueados({ type }) {

    const navigate = useNavigate();
    const [visibleModal, setVisibleModal] = useState(false)
    const { signOut, user, editUser } = useAuth();
    const [id, setId] = useState("")
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone_number, setPhone_number] = useState("")
    const [photo, setPhoto] = useState("")
    const [password, setPassword] = useState("")

    async function editFranqueado() {
        if (!name || !email || !phone_number) {
            toast.warn('Preencha todos os campos para atualizar o perfil');
        } else {
            const data = new FormData();
            data.append('name', name);
            data.append('phone_number', phone_number);
            data.append('password', password);
            data.append('email', email);
            if (photo.name) {
                data.append('file', photo, photo.name);
            }
            await api.put(`/user/${id}`, data)
                .then((response) => {
                    editUser({ user: response.data })
                    toast.success("Perfil editado com sucesso")
                    setVisibleModal(false)
                }).catch(({ response }) => {
                    if (response) {
                        if (response.data) {
                            if (response.data.message) {
                                toast.warn(response.data.message)
                            } else {
                                toast.error("Erro Interno. verifique sua conexão e tente novamente")
                            }
                        } else {
                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                        }
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                })
        }
    }

    return (
        <Container>
            <img src={user.photo ? user.photo_url : "https://png.pngtree.com/png-vector/20190710/ourlarge/pngtree-user-vector-avatar-png-image_1541962.jpg"} />
            <p>{user.name}</p>
            <p>{user.email}</p>
            <p>{user.phone_number}</p>
            <h1>Franqueado</h1>
            <div>
                <button style={{ background: "#f2760a" }} onClick={() => {
                    setId(user.id)
                    setName(user.name)
                    setEmail(user.email)
                    setPhone_number(user.phone_number)
                    setPhoto({ photo_url: user.photo_url, photo: user.photo })
                    setVisibleModal(true)
                }}>
                    Editar Perfil
                </button>
                <button onClick={() => {
                    signOut()
                    navigate("/login")
                }}>
                    Sair
                </button>
            </div>
            <ModalC visible={visibleModal} executar={() => { setVisibleModal(false) }}>
                <div style={{ paddingTop: 60, width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div style={{ marginTop: -190, width: 260, height: 160, borderRadius: 10, marginBottom: 25, marginBottom: 20, justifyContent: "center" }}>
                        <img style={{ width: 160, marginLeft: 50, height: 160, borderRadius: 10, background: "#fff", }} src={photo.photo ? photo.photo_url : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__480.png"} />
                        <IconButton style={{ width: 50, height: 50, bottom: 15 }}>
                            <label for="avatar" style={{ display: "flex", width: 50, height: 50, justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                                <MdAddPhotoAlternate color="#FFF" />
                                <input id="avatar" style={{ display: "none" }} type="file" accept="image/" onChange={(file) => {
                                    let photo = file.target.files[0]
                                    photo.photo = file.target.files[0].name
                                    photo.photo_url = URL.createObjectURL(file.target.files[0])
                                    setPhoto(photo)
                                }
                                }
                                />
                            </label>
                        </IconButton>
                    </div>
                    <div style={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                        justifyContent: "space-between"
                    }}>
                        <InputC text={name} title={"Nome"} placeholder={"João"} width={"100%"} height={"55px"} executar={(text) => { setName(text) }} />
                        <InputC text={email} title={"Email"} placeholder={"usuario@gmail.com"} width={"100%"} height={"55px"} executar={(text) => { setEmail(text) }} />
                        <InputTel text={phone_number} title={"Telefone"} placeholder={"(00) 0000-0000"} width={"100%"} height={"55px"} executar={(text) => { setPhone_number(text) }} />
                        <InputC text={password} title={"Nova Senha"} placeholder={"****"} width={"100%"} height={"55px"} executar={(text) => { setPassword(text) }} />
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: "center", marginTop: 25
                        }}>

                            <ButtonC text={""} title={"Salvar"}
                                width={"60%"} executar={() => {
                                    editFranqueado()
                                }} />
                        </div>
                    </div>
                </div>

            </ModalC>
        </Container>
    )
} 