import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "./styles";
import logo from "../../images/logo.jpeg"
import { MdMenu, MdOutlineClose } from "react-icons/md"
import { useAuth } from "../../hooks/AuthContext";

export default function HeaderCourse() {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [openMenu, setOpenMenu] = useState(false)

    return (
        <Container active={openMenu}>
            <img src={logo} onClick={() => {
                navigate(`/${user.type == "admin" ? user.type : user.type + "s"}/home`)
            }} />
            <div>
                <a onClick={() => {
                    setOpenMenu(false)
                    navigate(-1)
                }}>Voltar</a>
                <a onClick={() => {
                    setOpenMenu(false)
                    navigate(`/courses`)
                }}>Área do conhecimento</a>
                <a onClick={() => {
                    setOpenMenu(false)
                    navigate(`/${user.type == "admin" ? user.type : user.type + "s"}/home`)
                }}>Home</a>
                <div onClick={() => {
                    navigate(`/${user.type == "admin" ? user.type : user.type + "s"}/perfil`)
                }}>
                    <img src={user.photo ? user.photo_url : "https://png.pngtree.com/png-vector/20190710/ourlarge/pngtree-user-vector-avatar-png-image_1541962.jpg"} />
                </div>
            </div>
            <div className="icon" onClick={() => {
                setOpenMenu(!openMenu)
            }}>
                {
                    openMenu ? (
                        <MdOutlineClose size={30} color="#FFF" />
                    ) : (
                        <MdMenu size={30} color="#FFF" />
                    )
                }

            </div>
            <div className="perfil" onClick={() => {
                navigate(`/${user.type == "admin" ? user.type : user.type + "s"}/perfil`)
            }}>
                <img src={user.photo ? user.photo_url : "https://png.pngtree.com/png-vector/20190710/ourlarge/pngtree-user-vector-avatar-png-image_1541962.jpg"} />
            </div>
        </Container>
    )

}