import React, { useEffect, useState } from 'react';
import { Container, Services } from './styles';
import { useNavigate, useParams } from "react-router-dom";
import loadingfile from "./../../../images/loading.gif"
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { ButtonC, InputC } from "../../../components/simple";
import { useAuth } from '../../../hooks/AuthContext';
import { AutoComplete } from 'antd';
import { addDays, format } from 'date-fns';


export default function CreateTimeline() {

    const { user } = useAuth()

    const navigate = useNavigate();

    const { id } = useParams()

    const [loading, setLoading] = useState(false)
    const [companies, setCompanies] = useState([])
    const [companiesC, setCompaniesC] = useState([])
    const [company, setCompany] = useState(null)
    const [companyName, setCompanyName] = useState("")
    const [name, setName] = useState("")
    const [date_reminder, setDate_reminder] = useState(addDays(new Date(),1))
    const [date_reminder_text, setDate_reminder_text] = useState(format(addDays(new Date(),1), "yyyy-MM-dd"))
    const [observation, setObservation] = useState("")

    useEffect(() => {
        if (id != "new") {
            loadingTimeline()
        }
        listCompanies()
    }, [])

        async function listCompanies() {
            await api.get(`/companies-renewal${user.type == "cliente" ? `?region=${user.region}` : ""}`).then(async (response) => {
                let companies = response.data
    
                await companies.map((item) => {
                    item.value = item.razao_social
                    item.label = item.razao_social
                })
                
                if (user.type == "tecnico") {
                    let companiesC = []
                    companies.map((item, index) => {
                        if (user.id == "cc0834ec-994b-4601-b5fe-381f8739c2dc" && index % 2 == 0) {
                            companiesC.push(item)
                        } else {
                            if (user.id == "73fe590a-7d24-4122-a208-5e6c1578b0b2" && index % 2 == 1) {
                                companiesC.push(item)
                            }
                        }
                    })
                    companies = companiesC
                }
                
                setCompanies([...companies])
                setCompaniesC(companies)
            }).catch(({ response }) => {
                if (response) {
                    if (response.data) {
                        if (response.data.message) {
                            toast.warn(response.data.message)
                        } else {
                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                        }
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            })
            setLoading(false)
        }
    
        async function loadingTimeline() {
            await api.get(`/timeline/${id}`).then(async (response) => {
                let timeline = response.data
                setName(timeline.name)
                setDate_reminder(new Date(timeline.date_reminder))
                setDate_reminder_text(format(new Date(timeline.date_reminder), "yyyy-MM-dd"))
                setObservation(timeline.observation)
                setCompany({ ...timeline.renewal, value: timeline.renewal.razao_social, label: timeline.renewal.razao_social})                
            }).catch(({ response }) => {
                if (response) {
                    if (response.data) {
                        if (response.data.message) {
                            toast.warn(response.data.message)
                        } else {
                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                        }
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            })
            setLoading(false)
        }

        async function createTimeline() {
            if (!company || !name || !date_reminder || !company) {
                toast.warn("Preencha todos os campos")
            } else {
                await api.post(`/company-timeline`, {
                    name: name,
                    date_reminder: date_reminder,
                    renewal_id: company.id,
                    observation: observation
                }).then(() => {
                    toast.success("Tarefa cadastrada com sucesso")
                    navigate(-1)
                }).catch(({ response }) => {
                    if (response) {
                        if (response.data) {
                            if (response.data.message) {
                                toast.warn(response.data.message)
                            } else {
                                toast.error("Erro Interno. verifique sua conexão e tente novamente")
                            }
                        } else {
                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                        }
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                })
            }
        }
    
        async function handlerTimeline() {
            if (!id || !date_reminder || !name || !company) {
                toast.warn("Preencha todos os campos")
            } else {
                await api.put(`/company-timeline/${id}`, {
                    name: name,
                    date_reminder: date_reminder,
                    renewal_id: company.id,
                    observation: observation
                }).then((response) => {
                    toast.success("Tarefa atualizada com sucesso")
                    navigate(-1)
                }).catch(({ response }) => {
                    if (response) {
                        if (response.data) {
                            if (response.data.message) {
                                toast.warn(response.data.message)
                            } else {
                                toast.error("Erro Interno. verifique sua conexão e tente novamente")
                            }
                        } else {
                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                        }
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                })
            }
        }
    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Container>
                {
                    loading ? (
                        <img src={loadingfile} style={{ background: "transparent", height: 50, width: 50 }} />
                    ) : (
                            <>
                            <h1>{id != "new" ? "Edição de Tarefa" : "Criação de tarefa"}</h1>
                            <InputC text={name} title={"Nome da tarefa"} placeholder={"nome"}
                                styleContent={{ margin: "0 10px 20px 10px" }} width={"100%"}
                                height={"55px"} color="#000" executar={(text) => { setName(text) }} />
                                <h3 style={{
                                    color: "#000",
                                    fontSize: 15,
                                    width: "100%",
                                    textAlign: "left",
                                    fontWeight: "bold"
                                }}>Vincular a Empresa</h3>
                        
                                <AutoComplete
                                    style={{ 
                                        width: "100%",
                                        fontSize: 14, 
                                        height: 40,
                                        textAlign: "left" }}
                                    options={companies}
                                    value={company || companyName}
                                    notFoundContent={(<>Nenhuma empresa encontrado</>)}
                                    onSelect={(text, client) => {
                                        setCompany(client)
                                        setCompanyName("")
                                        setCompanies(companiesC)
                                    }}
                                    onSearch={(text) => {
                                        setCompany(null)
                                        setCompanyName(text)
                                            if (text) {
                                                let companiesGet = companiesC.filter((item) => {
                                                    return item.label.toUpperCase().indexOf(text.toUpperCase()) != -1
                                                }) 
                                                setCompanies([...companiesGet])
                                            } else {
                                                setCompanies(companiesC)
                                            }
                                          
                                    }}
                                    placeholder="procurar por nome"
                                />
                    
                    <div style={{width: "100%", margin: "0 10px 0px 10px"}}>
                        <h3 style={{
                            color: "#000",
                            fontSize: 15,
                            width: "100%",
                            textAlign: "left",
                            margin: "10px 0 5px 0",
                            fontWeight: "bold"
                        }}>Data de Realizacao</h3>
                        <input
                            style={{
                                width: "100%",
                                backgroundColor: "#FFF",
                                borderWidth: 1,
                                borderColor: "#ccc",
                                borderStyle: "solid",
                                color: "#444",
                                padding: "10px",
                                fontSize: 14,
                                borderRadius: 5,
                                fontWeight: "400",
                                paddingLeft: 8,
                                marginBottom: 10
                            }}
                            type="date" min={format(new Date(), "yyyy-MM-dd")}  value={date_reminder_text} onChange={(date) => {
                                if (date.target.value.length <= 10) {
                                    setDate_reminder_text(date.target.value)
                                    if (date.target.value.length == 10) {
                                        let dateE = date.target.value.split("-")
                                        setDate_reminder(new Date(dateE[0], dateE[1] - 1, dateE[2]))
                                    }
                                }
                            }} />
                        </div>
                        <InputC text={observation} title={"Observação"} placeholder={"observação"}
                            styleContent={{ margin: "0 15px 20px 10px" }} width={"100%"} minHeight={"120px"}
                            multiline={true} color="#000" executar={(text) => { setObservation(text) }} />
                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", margin: "35px 0" }}>
                            <ButtonC text={""} title={"Voltar"}
                                width={"40%"} executar={() => {
                                    navigate(-1)
                                }} />
                            <ButtonC text={""} title={"Salvar"}
                                width={"55%"} 
                                executar={() => {
                                    if (id == "new") {
                                        createTimeline()
                                    } else {
                                        handlerTimeline()
                                    }
                            }}/>
                        </div>
                    </>
                )}
            </Container>
        </div>
    )
} 