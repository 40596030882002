import React, { useEffect, useState } from 'react';
import { Container } from './styles';
import { useNavigate, useParams } from "react-router-dom";
import loadingPhoto from "./../../../images/loading.gif"
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { InputC, ButtonC, SwitchC, InputTel } from "../../../components/simple";
import { Select } from 'antd';
import { getValue } from '@testing-library/user-event/dist/utils';


export default function NewEditFranqueado() {

    const {id} = useParams()

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true)
    const [name, setName] = useState("")
    const [phone_number, setPhone_number] = useState("")
    const [email, setEmail] = useState("")
    const [photo, setPhoto] = useState("")
    const [category, setCategory] = useState("franqueado")
    const [sector1_id, setSector1_id] = useState("")
    const [sector2_id, setSector2_id] = useState("")
    const [sector3_id, setSector3_id] = useState("")
    const [sector4_id, setSector4_id] = useState("")
    const [sector5_id, setSector5_id] = useState("")
    const [services, setServices] = useState([])
    const [servicesAll, setServicesAll] = useState([])
    const [region, setRegion] = useState("")
    const [tecnicos, setTecnicos] = useState([])
    const [password, setPassword] = useState("")
    const [course, setCourse] = useState(false)
    const [courseRestricted, setCourseRestricted] = useState(false)
    const [resale, setResale] = useState(false)
    const [signature, setSignature] = useState(false)

    useEffect(() => {
        setLoading(true)
        listServices()
        getFranqueado()
        listTecnicos() 
    }, [])

    async function listServices() {
        await api.get(`/services`).then((response) => {
            response.data.map((item)=>{
                item.label = item.name + " - " + item.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                item.value = item.id
            })
            setServicesAll(response.data)
        }).catch(({ response }) => {
            if (response) {
                if (response.data) {
                    if (response.data.message) {
                        toast.warn(response.data.message)
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            } else {
                toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
        })
    }

    async function listTecnicos() {
        setLoading(true)
        await api.get(`/collaborators`).then((response) => {
            let tecnicos = response.data
            tecnicos.map((item) => {
                item.label = item.name
                item.value = item.id
            })
            setTecnicos(tecnicos)
        }).catch(({ response }) => {
            if (response) {
                if (response.data) {
                    if (response.data.message) {
                        toast.warn(response.data.message)
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            } else {
                toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
        })
        setLoading(false)
    }

    async function getFranqueado() {
        setLoading(true)
        if(id != "new"){
            await api.get(`/user/${id}`).then((response) => {
                const item = response.data
                setName(item.name)
                setEmail(item.email)
                setPhoto({ photo_url: item.photo_url })
                setPhone_number(item.phone_number)
                setCategory(item.category)
                setRegion(item.region)
                setSector1_id(item.sector1_id)
                setSector2_id(item.sector2_id)
                setSector3_id(item.sector3_id)
                setSector4_id(item.sector4_id)
                setSector5_id(item.sector5_id)
                setPassword("")
                setCourse(item.course)
                setCourseRestricted(item.course_restricted)
                setResale(item.resale)
                let servicesArray = []
                item.services.split(";").map((item)=>{
                    if(item){
                        servicesArray.push(item)
                    }
                })
                setServices(servicesArray)
                setSignature(item.signature)
            }).catch(({ response }) => {
                if (response) {
                    if (response.data) {
                        if (response.data.message) {
                            toast.warn(response.data.message)
                        } else {
                             toast.error("Erro Interno. verifique sua conexão e tente novamente")
                        }
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                   toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            })
        }
        setLoading(false)
    }

    async function createAndEditTecnico() {
        let servicesString = ""
        services.map((item)=>{
            servicesString += `${item};`
        })
        if (id != "new") {
            if (!name || !email || !phone_number) {
                toast.warn('Preencha todos os campos para realizar o cadastro');
            } else {
                const data = new FormData();
                data.append('name', name);
                data.append('phone_number', phone_number);
                data.append('password', password);
                data.append('course', course);
                data.append('course_restricted', courseRestricted);
                data.append('resale', resale);
                data.append('signature', signature);
                data.append('region', region);
                data.append('category', category);
                data.append('sector1_id', sector1_id);
                data.append('sector2_id', sector2_id);
                data.append('sector3_id', sector3_id);
                data.append('sector4_id', sector4_id);
                data.append('sector5_id', sector5_id);
                data.append('services', servicesString);
                data.append('email', email);
                if (photo.name) {
                    data.append('file', photo, photo.name);
                }
                await api.put(`/user/${id}`, data)
                    .then(async (ok) => {
                        toast.success(`Franqueado editado com sucesso`)
                        navigate(-1)
                    }).catch(({ response }) => {
                        if (response) {
                            if (response.data) {
                                if (response.data.message) {
                                    toast.warn(response.data.message)
                                } else {
                                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                                }
                            } else {
                                toast.error("Erro Interno. verifique sua conexão e tente novamente")
                            }
                        } else {
                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                        }
                    })
            }
        } else {
            if (!name || !email || !password || !phone_number) {
                toast.warn('Preencha todos os campos para realizar o cadastro');
            } else {
                const data = new FormData();
                data.append('name', name);
                data.append('phone_number', phone_number);
                data.append('password', password);
                data.append('course_restricted', courseRestricted);
                data.append('email', email);
                data.append('course', course);
                data.append('region', region);
                data.append('category', category);
                data.append('sector1_id', sector1_id);
                data.append('sector2_id', sector2_id);
                data.append('sector3_id', sector3_id);
                data.append('sector4_id', sector4_id);
                data.append('sector5_id', sector5_id);
                data.append('signature', signature);
                data.append('services', servicesString);
                data.append('resale', resale);
                if (photo.name) {
                    data.append('file', photo, photo.name);
                }
                await api.post(`/user`, data).then(async (ok) => {
                    toast.success(`Franqueado criado com sucesso`)
                    navigate(-1)
                }).catch(({ response }) => {
                    if (response) {
                        if (response.data) {
                            if (response.data.message) {
                                toast.warn(response.data.message)
                            } else {
                                toast.error("Erro Interno. verifique sua conexão e tente novamente")
                            }
                        } else {
                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                        }
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                })
            }
        }
    }


    return (
        <Container>
            {
                loading ? (<img src={loadingPhoto} style={{ background: "transparent", height: 50, width: 50 }} />

                ) : (
                    <div style={{width: "90%", maxWidth: 600}}>
                        <h1>{id != "new" ? "Editar Franqueado" : "Novo Franqueado"}</h1>
                        <InputC text={name} title={"Nome"} color="#888" placeholder={"João"} width={"100%"} height={"55px"} executar={(text) => { setName(text) }} />
                        <InputC text={email} title={"Email"} color="#888" placeholder={"usuario@gmail.com"} width={"100%"} height={"55px"} executar={(text) => { setEmail(text) }} />
                        <InputC text={region} title={"Região"} color="#888" placeholder={"regiao"} width={"100%"} height={"55px"} executar={(text) => { setRegion(text) }} />
                        <div style={{width: "100%"}}>
                            <h3 style={{
                                color: "#888",
                                fontSize: 15,
                                width: "100%",
                                textAlign: "left",
                                margin: "10px 0 5px 0",
                                fontWeight: "bold"
                            }}>Tipo do Usuário</h3>
                            <select
                                value={category}
                                style={{
                                    width: "100%",
                                    backgroundColor: "#FFF",
                                    borderWidth: 1,
                                    borderColor: "#ccc",
                                    borderStyle: "solid",
                                    color: "#444",
                                    padding: "10px",
                                    fontSize: 14,
                                    borderRadius: 5,
                                    fontWeight: "400",
                                    paddingLeft: 8,
                                }}
                                onChange={(text) => {
                                    setCategory(text.target.value)
                                }}
                            >
                                <option value={"franqueado"} label={"Franqueado"}>{"Franqueado"}</option>
                                <option value={"revenda"} label={"Revenda treinamentos"}>{"Revenda treinamentos"}</option>
                                <option value={"assinatura"} label={"Assinatura"}>{"Assinatura"}</option>
                                <option value={"terceirize"} label={"Terceirize"}>{"Terceirize"}</option>
                            </select> 
                        </div>
                        <div style={{width: "100%"}}>
                            <h3 style={{
                                color: "#888",
                                fontSize: 15,
                                width: "100%",
                                textAlign: "left",
                                margin: "10px 0 5px 0",
                                fontWeight: "bold"
                            }}>Serviços <span style={{ fontSize: 13 }}>(caso n selecione nenhum, aparecerá todos)</span></h3>
                            <Select
                                placeholder="selecione serviços para esse franqueado"
                                value={services}
                                mode="multiple"
                                optionFilterProp="label"
                                options={servicesAll}
                                style={{
                                    width: "100%",
                                    backgroundColor: "#FFF",
                                    color: "#444",
                                    minHeight: "35px",
                                    alignItems: "center",
                                    fontSize: 14,
                                    borderRadius: 5,
                                    fontWeight: "400",
                                }}
                                onChange={(value) => {
                                    setServices(value)
                                }}
                            /> 
                        </div>
                        <div style={{ width: "100%" }}>
                            <h3 style={{
                                color: "#888",
                                fontSize: 15,
                                width: "100%",
                                textAlign: "left",
                                margin: "10px 0 5px 0",
                                fontWeight: "bold"
                            }}>Técnico <span style={{ fontSize: 13 }}>(Serviços de segurança do Trabalho)</span></h3>
                            <select
                                value={sector1_id}
                                style={{
                                    width: "100%",
                                    backgroundColor: "#FFF",
                                    borderWidth: 1,
                                    borderColor: "#ccc",
                                    borderStyle: "solid",
                                    color: "#444",
                                    padding: "10px",
                                    fontSize: 14,
                                    borderRadius: 5,
                                    fontWeight: "400",
                                    paddingLeft: 8,
                                }}
                                onChange={(text) => {
                                    setSector1_id(text.target.value)
                                }}
                            >
                                <option value={""}>Nenhum técnico</option>
                                {tecnicos.map((item) => {
                                    if (item.sector == "Serviços de segurança do Trabalho" || item.sector == "Todos") {
                                        return (
                                        <option value={item.id} label={item.name}>{item.name}</option>
                                    )
                                    }
                                })}
                            </select> 
                        </div>
                        <div style={{width: "100%"}}>
                            <h3 style={{
                                color: "#888",
                                fontSize: 15,
                                width: "100%",
                                textAlign: "left",
                                margin: "10px 0 5px 0",
                                fontWeight: "bold"
                            }}>Técnico <span style={{ fontSize: 13 }}>(Revenda de treinamentos online)</span></h3>
                            <select
                                value={sector2_id}
                                style={{
                                    width: "100%",
                                    backgroundColor: "#FFF",
                                    borderWidth: 1,
                                    borderColor: "#ccc",
                                    borderStyle: "solid",
                                    color: "#444",
                                    padding: "10px",
                                    fontSize: 14,
                                    borderRadius: 5,
                                    fontWeight: "400",
                                    paddingLeft: 8,
                                }}
                                onChange={(text) => {
                                    setSector2_id(text.target.value)
                                }}
                            >
                                <option value={""}>Nenhum técnico</option>
                                {tecnicos.map((item) => {
                                    if (item.sector == "Revenda de treinamentos online" || item.sector == "Todos") {
                                        return (
                                        <option value={item.id} label={item.name}>{item.name}</option>
                                    )
                                    }
                                })}
                            </select> 
                        </div>
                        <div style={{width: "100%"}}>
                            <h3 style={{
                                color: "#888",
                                fontSize: 15,
                                width: "100%",
                                textAlign: "left",
                                margin: "10px 0 5px 0",
                                fontWeight: "bold"
                            }}>Técnico <span style={{ fontSize: 13 }}>(Serviços Medicina ocupacional)</span></h3>
                            <select
                                value={sector3_id}
                                style={{
                                    width: "100%",
                                    backgroundColor: "#FFF",
                                    borderWidth: 1,
                                    borderColor: "#ccc",
                                    borderStyle: "solid",
                                    color: "#444",
                                    padding: "10px",
                                    fontSize: 14,
                                    borderRadius: 5,
                                    fontWeight: "400",
                                    paddingLeft: 8,
                                }}
                                onChange={(text) => {
                                    setSector3_id(text.target.value)
                                }}
                            >
                                <option value={""}>Nenhum técnico</option>
                                {tecnicos.map((item) => {
                                    if (item.sector == "Serviços Medicina ocupacional" || item.sector == "Todos") {
                                        return (
                                        <option value={item.id} label={item.name}>{item.name}</option>
                                    )
                                    }
                                })}
                            </select> 
                        </div>
                        <div style={{width: "100%"}}>
                            <h3 style={{
                                color: "#888",
                                fontSize: 15,
                                width: "100%",
                                textAlign: "left",
                                margin: "10px 0 5px 0",
                                fontWeight: "bold"
                            }}>Técnico <span style={{ fontSize: 13 }}>(Credenciamento SST)</span></h3>
                            <select
                                value={sector4_id}
                                style={{
                                    width: "100%",
                                    backgroundColor: "#FFF",
                                    borderWidth: 1,
                                    borderColor: "#ccc",
                                    borderStyle: "solid",
                                    color: "#444",
                                    padding: "10px",
                                    fontSize: 14,
                                    borderRadius: 5,
                                    fontWeight: "400",
                                    paddingLeft: 8,
                                }}
                                onChange={(text) => {
                                    setSector4_id(text.target.value)
                                }}
                            >
                                <option value={""}>Nenhum técnico</option>
                                {tecnicos.map((item) => {
                                    if (item.sector == "Credenciamento SST" || item.sector == "Todos") {
                                        return (
                                        <option value={item.id} label={item.name}>{item.name}</option>
                                    )
                                    }
                                })}
                            </select> 
                        </div>
                        <div style={{width: "100%"}}>
                            <h3 style={{
                                color: "#888",
                                fontSize: 15,
                                width: "100%",
                                textAlign: "left",
                                margin: "10px 0 5px 0",
                                fontWeight: "bold"
                            }}>Técnico <span style={{ fontSize: 13 }}>(Assinatura Documentos SST)</span></h3>
                            <select
                                value={sector5_id}
                                style={{
                                    width: "100%",
                                    backgroundColor: "#FFF",
                                    borderWidth: 1,
                                    borderColor: "#ccc",
                                    borderStyle: "solid",
                                    color: "#444",
                                    padding: "10px",
                                    fontSize: 14,
                                    borderRadius: 5,
                                    fontWeight: "400",
                                    paddingLeft: 8,
                                }}
                                onChange={(text) => {
                                    setSector5_id(text.target.value)
                                }}
                            >
                                <option value={""}>Nenhum técnico</option>
                                {tecnicos.map((item) => {
                                    if (item.sector == "Assinatura Documentos SST" || item.sector == "Todos") {
                                        return (
                                        <option value={item.id} label={item.name}>{item.name}</option>
                                    )
                                    }
                                })}
                            </select> 
                        </div>
                        <InputTel text={phone_number} title={"Telefone"} color="#888" placeholder={"(00) 0000-0000"} width={"100%"} height={"55px"} executar={(text) => { setPhone_number(text) }} />
                        <InputC text={password} title={id ? "Nova Senha" : "Senha"} color="#888" placeholder={"****"} width={"100%"} height={"55px"} executar={(text) => { setPassword(text) }} />
                        
                        <SwitchC value={resale} width={"50%"} executar={(value) => {
                            setResale(value)
                            if (value) {
                                setSignature(false)
                            }
                        }} title={"Revenda"} />
                        <SwitchC value={signature} width={"50%"} executar={(value) => {
                            setSignature(value)
                            if (value) {
                                setResale(false)
                            }
                        }} title={"Assinatura SST"} />
                        <SwitchC value={course} width={"50%"} executar={(value) => {
                            setCourse(value)
                        }} title={"Área do conhecimento"} />
                        {
                            course && (
                                <SwitchC value={courseRestricted} width={"50%"} executar={(value) => {
                                    setCourseRestricted(value)
                                }} title={"Acesso Restrito"} />
                            )
                        }
                       
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: "center", marginTop: 25
                        }}>

                            <ButtonC text={""} title={"Salvar"}
                                width={"60%"} executar={() => {
                                    createAndEditTecnico()
                                }} />
                        </div>
                </div>
            )}
        </Container>
    )
} 