import React, { useEffect, useState } from 'react';
import { Button, Container, Resume, Services } from './styles';
import { useNavigate, useParams } from "react-router-dom";
import loadingPhoto from "./../../../images/loading.gif"
import loadingPhotoWhite from "./../../../images/loadingBranco.gif"
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { ButtonC, InputC } from '../../../components/simple';
import ModalC from '../../../components/modal';
import { useAuth } from '../../../hooks/AuthContext';

export default function Alteracao() {
    const { id } = useParams()
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true)
    const [loadingAlteracao, setLoadingAlteracao] = useState(false)
    const [message, setMessage] = useState("")
    const [visibleModal, setVisibleModal] = useState(false)
    const [urgent, setUrgent] = useState(false)
    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [value, setValue] = useState(0)
    const [services, setServices] = useState([])
    const [order, setOrder] = useState({
        items: []
    })
    const [totalServices, setTotalServices] = useState(0)
    const [totalValue, setTotalValue] = useState(0)


    useEffect(() => {
        loadingOS()
    }, [])

    async function loadingOS() {
        await api.get(`/order/${id}`).then((response) => {
            let totalS = 0
            let totalV = 0
            response.data.items.map((item) => {
                totalS += item.amount
                totalV += item.amount * item.value
            })
            setTotalServices(totalS)
            setTotalValue(totalV)
            setOrder(response.data)
            setMessage(response.data.message)
            loadingServices(response.data)
        }).catch(({ response }) => {
            if (response) {
                if (response.data) {
                    if (response.data.message) {
                        toast.warn(response.data.message)
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            } else {
                toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
            setOrder(null)
        })
        setLoading(false)
    }

    async function loadingServices(order) {
        await api.get(`/services-client`).then((response) => {
            let servicesC = response.data
            servicesC = servicesC.filter((data) =>  data.sector == order.sector )
            servicesC.map((item) => {
                item.amount = 0
                let [oldService] = order.items.filter((data) => { return data.name == item.name })
                if (oldService) {
                    item.old = oldService.amount
                } else {
                    item.old = 0
                }
            })
            setServices(servicesC)
        }).catch(({ response }) => {
            if (response) {
                if (response.data) {
                    if (response.data.message) {
                        toast.warn(response.data.message)
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            } else {
                toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
        })
        setLoading(false)
    }

    function totalValueServices() {
        let value = 0
        services.map((item) => {
            value += item.amount * item.value
        })
        return value
    }

    function totalServicesOS() {
        let nServices = 0
        services.map((item) => {
            nServices += item.amount
        })
        return nServices
    }
    
    return (
        <Container>
            {
                loading ? (<img src={loadingPhoto} style={{ background: "transparent", height: 50, width: 50 }} />
                ) : (
                    <>
                        {order ?
                            <>
                                  <h1>Adicionando serviços a OS #{order.id}</h1>
                                    <Services>
                                        {
                                            services.map((item, index) => {
                                                    return (
                                                        <div>
                                                            <span>
                                                                Atual<br/>{item.old}
                                                            </span>
                                                            <div>
                                                                <button onClick={() => {
                                                                    let servicesA = [...services]
                                                                    servicesA[index].amount++
                                                                    setServices(servicesA)
                                                                }}>+</button>
                                                                <span>{item.amount}</span>
                                                                <button onClick={() => {
                                                                    let servicesA = [...services]
                                                                    if (servicesA[index].amount > 0) {
                                                                        servicesA[index].amount--
                                                                        setServices(servicesA)
                                                                    }

                                                                }}>-</button>
                                                            </div>
                                                            <p style={{ flex: 1, minWidth: 100 }}>{item.name}</p>
                                                            {item.description && (<button onClick={() => {
                                                                setDescription(item.description)
                                                                setName(item.name)
                                                                setValue(item.value)
                                                                setVisibleModal(true)
                                                            }}>detalhes</button>)}
                                                            <p style={{ flex: 1, maxWidth: 85, textAlign: "right" }}>{item.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>

                                                        </div>
                                                    )
                                            })
                                        }
                                    </Services>
                                    <div style={{width: "90%", display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "center", alignItems: "center"}}>
                                        <div>
                                    
                                        <h3>OS Atual</h3>
                                            <Resume>
                                                <p>{totalServices} serviço{totalServices > 1 ? "s" : ""}</p>
                                                {
                                                    urgent ? (
                                                        <>
                                                            <p style={{ fontSize: 16, marginTop: 0, marginBottom: 5 }}>Subtotal: {totalValue.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} </p>
                                                            <p style={{ fontSize: 16, marginTop: 0, marginBottom: 10 }}>10% da urgência: {(totalValue * 0.1).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                                                            <p style={{ fontWeight: "bold" }}>Total: {(totalValue * 1.1).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} </p>
                                                        </>
                                                    ) : (
                                                        <p style={{ fontWeight: "bold" }}>Total: {totalValue.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} </p>
                                                    )
                                                }
                                            </Resume>
                                        </div><div>
                                    
                                    <h3>Com novos serviços</h3>
                                        <Resume>
                                            <p>{totalServicesOS()+ totalServices} serviço{(totalServicesOS()+ totalServices) > 1 ? "s" : ""}</p>
                                            {
                                                urgent ? (
                                                    <>
                                                        <p style={{ fontSize: 16, marginTop: 0, marginBottom: 5 }}>Subtotal: {(totalValueServices()+totalValue).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} </p>
                                                        <p style={{ fontSize: 16, marginTop: 0, marginBottom: 10 }}>10% da urgência: {((totalValueServices()+totalValue) * 0.1).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                                                        <p style={{ fontWeight: "bold" }}>Total: {((totalValueServices()+totalValue) * 1.1).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} </p>
                                                    </>
                                                ) : (
                                                    <p style={{ fontWeight: "bold" }}>Total: {(totalValueServices()+totalValue).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} </p>
                                                )
                                            }
                                        </Resume>
                                    </div>
                                    </div>

                                    <h3>Mensagem para o técnico</h3>
                                    
                        <InputC type="text" multiline minHeight={100} styleInput={{maxWidth: 600, background: "#f0f0f0"}} placeholder='Deixe aqui uma mensagem para o técnico.' text={message} onChange={(text) => {
                            setMessage(text.target.value)
                                    }} />
                                    <Button disabled={loadingAlteracao} onClick={async () => {
                                        setLoadingAlteracao(true)
                                         let items = []
                                         services.map((item) => {
                                             if (item.amount > 0) {
                                                 items.push(item)
                                             }
                                         })
                                         if (items.length == 0) {
                                             toast.warn("Selecione pelo menos um item")
                                         } else {
                                             await api.put("/alteracao-order", {
                                                 items,
                                                 order_id: order.id
                                             }).then(() => {
                                                toast.success("Novos serviços adicionado a OS com sucesso")
                                                 navigate("/clientes/os/" + order.id)
                                             }).catch(() => {
                                                 toast.error("Erro Interno. verifique sua conexão e tente novamente")
                                             })
                                         }
                                         setLoadingAlteracao(false)
                                    }}>
                                        { loadingAlteracao ? (
                                            <img src={loadingPhotoWhite} style={{ background: "transparent", height: 35, width: 50, margin: "0 40px" }} />
                                        ) : "Adicionar Novos Serviços"
                                        }
                                </Button>
                            </>
                            : "Ocorreu um erro ao carregar OS para alteração"}
                    </>
                )
            }
            <ModalC visible={visibleModal} executar={() => { setVisibleModal(false) }}>
                <div style={{ paddingTop: 0, width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <h2 style={{ textAlign: "center", color: "#fff" }}>{name}</h2>
                    <p style={{ textAlign: "center", color: "#fff", margin: "15px 0", fontSize: 16 }}>{description}</p>
                    <h3 style={{ textAlign: "center", color: "#fff" }}>{value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</h3>
                    <div style={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                        justifyContent: "space-between"
                    }}>
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: "center", marginTop: 25
                        }}>
                            <ButtonC text={""} title={"Fechar"}background={"#888"}
                                width={"50%"} executar={() => {
                                    setVisibleModal(false)
                                }} />
                        </div>
                    </div>
                </div>

            </ModalC>
        </Container >
    )
} 