import styled from "styled-components";

export const Photo = styled.img`
  border-radius: 10px;
  max-height: 150px;
  max-width: 55%;
`;

export const Input = styled.input`
  border: none;
  background-color: #f0f0f0;
  height: 35px;
  border-radius: 10px;
  font-size: 16px;
  width: 75%;
  margin-bottom: 15px;
  text-align: center;
`;

export const Button = styled.button`
  border: none;
  background-color: #AAC8AA;
  height: 35px;
  border-radius: 10px;
  font-size: 17px;
  padding: 0 10px;
  width: 75%;
  margin-top: 10px;
  color: #FFF;
  text-align: center;
`;

export const Information = styled.h4`
  font-size: 16px;
  width: 50%;
  margin: 30px 0;
  text-align: center;
  @media only screen and (max-width: 600px) {
  width: 75%;
  }
`;

export const Link = styled.h4`
  font-size: 12px;
  width: 75%;
  margin: 30px 0 15px 0;
  color: #99BB99;
  text-align: center;
  text-decoration: underline;
`;

export const ViewLogin = styled.div`
max-width: 500px;
padding: 40px 0 60px 0;
border-radius: 10px;
width: 100%;
background-color: #fff;
display: flex;
flex-direction: column;
align-items: center;

h2{
  margin: 0 0 30px 0;
}


@media only screen and (max-width: 600px) {
  width: 95%;
padding: 30px 0 40px 0;
h2{
  font-size: 16px;
  margin: 0 0 20px 0;
}
  }
`;

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #AAC8AA;
  display: flex;
  justify-content: center;
  align-items: center;
`;


export const ModalConfirm = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  align-items: center;
  justify-content: center;
  display: ${props => (props.open ? 'flex' : 'none')};
  transition: 0.5s;
  .content {
    width: 350px;
    background: #fff;
    border-radius: 7px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 20px;
    display: ${props => (props.open ? 'block' : 'none')};
    transition: 0.5s;
    &-header {
      text-align: center;
      p {
        width: 100%;
      }
    }
    &-main {
      padding: 20px 0 40px 0;
      text-align: center;
      > div {
        padding: 5px;
        > span {
          display: block;
        }
      }
    }
    &-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        color: #6a6a6a;
        font-size: 14px;
        cursor: pointer;
      }
      button {
        border: none;
        padding: 10px 15px;
        border-radius: 5px;
        position: relative;
        text-transform: none;
        transition: all 0.15s ease;
        letter-spacing: 0.025em;
        font-size: 0.875rem;
        color: #fff;
        background-color: #AAC8AA;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
          0 1px 3px rgba(0, 0, 0, 0.08);
      }
    }
  }
`;
