import React, { useEffect, useState } from 'react';
import { Container, Input, Button } from './styles';
import { useNavigate, useParams } from "react-router-dom";
import loadingfile from "./../../../images/loading.gif"
import api from '../../../services/api';
import { toast } from 'react-toastify';


export default function ResetPassword() {

    const { code } = useParams()

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingReset, setIsLoadingReset] = useState(false)
    const [user, setUser] = useState({})
    const [password, setPassword] = useState("")
    const [passwordC, setPasswordC] = useState("")
    const [error, setError] = useState("")

    useEffect(() => {
        loadingScreen()
    }, [])


    async function loadingScreen() {
        await api.get(`/password-verify-reset/${code}`).then((response) => {
            setUser(response.data)
        }).catch(({ response }) => {
            if (response) {
                if (response.data) {
                    if (response.data.message) {
                        setError(response.data.message)
                    } else {
                        setError("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    setError("Erro Interno. verifique sua conexão e tente novamente")
                }
            } else {
                setError("Erro Interno. verifique sua conexão e tente novamente")
            }
        })
        setIsLoading(false)
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Container>
                {
                    isLoading ? (
                        <img src={loadingfile} style={{ background: "transparent", height: 50, width: 50 }} />
                    ) : (
                        <>
                            {error ? <h1 style={{ marginTop: 50 }}>{error}</h1> : (
                                <>
                                    <h1 style={{ marginTop: 50 }}>Olá, {user.name}. Seja bem vindo a recuperação de senha</h1>

                                    <Input placeholder='Nova senha' value={password} type="password" onChange={(text) => {
                                        setPassword(text.target.value)
                                    }} />
                                    <Input placeholder='Confirmação da nova senha' value={passwordC} type="password" onChange={(text) => {
                                        setPasswordC(text.target.value)
                                    }} />

                                    <Button disabled={isLoadingReset}
                                        width={"200px"} onClick={async () => {
                                            setIsLoadingReset(true)
                                            if (password == passwordC) {
                                                await api.post(`/password-reset/${code}`, { password }).then(() => {
                                                    toast.success("Senha recuparada com sucesso")
                                                    navigate("/login")
                                                }).catch(({ response }) => {
                                                    if (response) {
                                                        if (response.data) {
                                                            if (response.data.message) {
                                                                toast.warn(response.data.message)
                                                            } else {
                                                                toast.error("Erro Interno. verifique sua conexão e tente novamente")
                                                            }
                                                        } else {
                                                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                                                        }
                                                    } else {
                                                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                                                    }
                                                })
                                            } else {
                                                toast.warn("As senhas não correspondem")
                                            }
                                            setIsLoadingReset(false)
                                        }}>Confirmar Alteração</Button>
                                </>
                            )}

                        </>
                    )
                }

            </Container>
        </div>
    )
} 