import styled from "styled-components";

export const Container = styled.div`
  width: 100%; 
  align-items: center;
  min-height: 100vh;
  display: flex;
  padding: 40px 0;
  flex-direction: column;
  align-items: center;

  h1{
    text-align: center;
    margin-bottom: 35px;
    color: #f2760a;
  }
  h3{
    text-align: center;
    margin: 15px 0;
  }
  @media (max-width: 850px) {
  padding: 30px 0;
  width: 95%;
  h1{
    text-align: center;
    font-size: 22px;
    margin-bottom: 15px;
  }
  }
`;

export const Docs = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 80%;
  justify-content: center;
  div{
    display: flex;
  flex-direction: column;
    padding: 10px;
    width: 120px;
    min-height: 200px;
    margin: 10px;
    background-color:  #f0f0f0;
    margin-bottom: 15px;
    border-radius: 10px;
  align-items: center;
  justify-content: center;
    p{
      color: #000;
      text-align: center;
      width: 95%;
  word-break: break-all;
      margin-bottom: 15px;
    }
  }
`;

export const Name = styled.input`
    max-width: 80%;
    min-width: 50%;
    margin-bottom: 15px;
    padding: 10px 15px;
    border-radius: 5px;
    text-align: center;
    background-color: #f0f0f0;
    border: none;
      color: #363636;
    @media (max-width: 850px) {
    max-width: 100%;
  
    }
    :placeholder{
      color: #363636;
    }
`;

export const Observation = styled.input`
    max-width: 80%;
    min-width: 50%;
    margin-bottom: 15px;
    padding: 25px 50px;
    border-radius: 10px;
    text-align: center;
    background-color: #f0f0f0;
    border: none;
      color: #363636;
    @media (max-width: 850px) {
    max-width: 100%;
  
    }
    :placeholder{
      color: #363636;
    }
`;

export const Services = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;

  div{
    display: flex;
    padding: 10px;
    margin-bottom: 15px;
    flex-wrap: wrap;
      align-items: center;
    flex-direction: row;
    border-bottom: 2px solid #f2760a;
    button{
        display: flex;
        height: 20px;
        padding: 0 10px;
        border-radius: 5px;
        align-items: center;
        justify-content: center;
      background-color: #f2760a;
      font-size: 10px;
          color: #fff;
          line-height: 30px;
      }
    div{
      display: flex;
      padding: 0;
    margin: 0;
    margin-right: 15px;;
      flex-direction: row;
      border: none;

      align-items: center;
      button{
        display: flex;
        height: 30px;
        width: 30px;
        padding: 0;
        border-radius: 5px;
        align-items: center;
        justify-content: center;
      background-color: #f2760a;
      font-size: 25px;
          color: #fff;
          line-height: 30px;
      }
      span{
        margin: 0 10px;
      }
  }
    p{
      color: #000;
    }
    }
  

  @media (max-width: 850px) {
  width: 100%;
 
  }
`;


export const Resume = styled.div`
  display: flex;
  justify-content: center;
    display: flex;
  flex-direction: column;
    padding: 25px;
    min-width: 350px;
    margin: 10px 0;
    background-color:  #f2760a;
    margin-bottom: 15px;
    border-radius: 10px;
  align-items: center;
  justify-content: center;
    p{
      color: #fff;
      text-align: center;
      margin-bottom: 15px;
      font-size: 20px;
    }
    button{
        display: flex;
        padding: 10px;
        width: 100%;
        border-radius: 5px;
        align-items: center;
        justify-content: center;
      background-color: #fff;
      font-size: 18px;
        color: #000;
      }
  

  @media (max-width: 850px) {
  width: 90%;
    padding-bottom: 50px;
    border-radius: 10PX;
  }
`;


export const Urgente = styled.div`
display: flex;
flex-direction: row;
    max-width: 80%;
    min-width: 50%;
    margin-bottom: 15px;
    padding: 25px 15px;
    border-radius: 10px;
    align-items: center;
    background-color: #f0f0f0;
    border: none;
      color: #363636;
    @media (max-width: 850px) {
    max-width: 100%;
  
    }
`;
