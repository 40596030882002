import React, { useEffect, useState } from 'react';
import { Container, List, InputFilter } from './styles';
import { useNavigate } from "react-router-dom";
import loadingPhoto from "./../../../images/loading.gif"
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { states } from '../../../services/states';
import HeaderPublic from '../../../components/headerPublic';
import { useQuery } from '../../../hooks/Location';
import { Pagination } from 'antd';

export default function Credentials() {

    const navigate = useNavigate();

    const query = useQuery()

    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(parseInt(query.get("page")) || 0)
    const [credentials, setCredentials] = useState([])
    const [credentialsTotal, setCredentialsTotal] = useState(0)
    const [state, setState] = useState(query.get("state") || "")
    const [city, setCity] = useState(query.get("city") || "")
    const [service, setService] = useState(query.get("service") || "")
    const [cities, setCities] = useState([])


    useEffect(() => {
        setPage(parseInt(query.get("page")) || 0)
        let state = query.get("state") || ""
        setState(state)
        if(state){
            setCities(states.filter((item) => { return item.sigla == state })[0].cidades)
        }
        setCity(query.get("city") || "")
        setService(query.get("service") || "")
        listCredentials()
    }, [window.location.href])

    async function listCredentials() {
        setLoading(true)
        await api.get(`/list-credentials${window.location.href.split("/credentials")[1] || ""}`).then((response) => {
            setCredentials(response.data.credentials)
            setCredentialsTotal(response.data.total)
        }).catch(({ response }) => {
            if (response) {
                if (response.data) {
                    if (response.data.message) {
                        toast.warn(response.data.message)
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            } else {
                toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
        })
        setLoading(false)
    }

    return (
        <Container>
            <HeaderPublic />
            <div style={{ padding: "40px 0", width: "90%", flexDirection: "column", display: "flex", alignItems: "center" }}>
                            <h2 style={{ width: "60%", textAlign: "center", fontWeight: 500, marginBottom: 30 }}>Aqui você encontra nossos credenciados para te ajudar, usando os filtros abaixos você encontrará o mais proximo a sua região</h2>
                            <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                                <div style={{ flex: 1, marginRight: 20, display: "flex", flexDirection: "column" }}>
                                    <h4 style={{ marginTop: 10, fontSize: 14, color: "#666", marginBottom: 5 }}>Estado</h4>
                                    <select
                                        value={state}
                                        style={{
                                            width: "100%",
                                            backgroundColor: "#FFF",
                                            borderWidth: 1,
                                            borderColor: "#ccc",
                                            borderStyle: "solid",
                                            color: "#444",
                                            padding: "10px",
                                            fontSize: 14,
                                            borderRadius: 5,
                                            fontWeight: "400",
                                            paddingLeft: 8,
                                        }}
                                        onChange={(text) => {
                                            setState(text.target.value)
                                            setCity("")
                                            setCities(states.filter((item) => { return item.sigla == text.target.value })[0].cidades)
                                        }}
                                    >
                                        <option value={""}>Seleciona o estado</option>
                                        {
                                            states.map((item) => {
                                                return (
                                                    <option value={item.sigla}>{item.nome}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div style={{ flex: 1, marginRight: 20, display: "flex", flexDirection: "column" }}>
                                    <h4 style={{ marginTop: 10, fontSize: 14, color: "#666", marginBottom: 5 }}>Cidade</h4>
                                    <select
                                        disabled={state == ""}
                                        value={city}
                                        style={{
                                            width: "100%",
                                            backgroundColor: "#FFF",
                                            borderWidth: 1,
                                            borderColor: "#ccc",
                                            borderStyle: "solid",
                                            color: "#444",
                                            padding: "10px",
                                            fontSize: 14,
                                            borderRadius: 5,
                                            fontWeight: "400",
                                            paddingLeft: 8,
                                        }}
                                        onChange={(text) => {
                                            setCity(text.target.value)
                                        }}
                                    >
                                        <option value={""}>Selecione a cidade</option>
                                        {
                                            cities.map((item) =>
                                                <option value={item}>{item}</option>

                                            )
                                        }
                                    </select>
                                </div>
                                <div style={{ flex: 1, marginRight: 20, display: "flex", flexDirection: "column" }}>
                                    <h4 style={{ marginTop: 10, fontSize: 14, color: "#666", marginBottom: 5 }}>Serviço</h4>
                                    <select
                                        value={service}
                                        style={{
                                            width: "100%",
                                            backgroundColor: "#FFF",
                                            borderWidth: 1,
                                            borderColor: "#ccc",
                                            borderStyle: "solid",
                                            color: "#444",
                                            padding: "10px",
                                            fontSize: 14,
                                            borderRadius: 5,
                                            fontWeight: "400",
                                            paddingLeft: 8,
                                        }}
                                        onChange={(text) => {
                                            setService(text.target.value)
                                        }}
                                    >
                                        <option value={""}>Selecione o serviço</option>
                                        <option value={"Treinamentos"}>Treinamentos</option>
                                        <option value={"Visita Técnica"}>Visita Técnica</option>
                                        <option value={"Medições ambientais"}>Medições ambientais</option>
                                        <option value={"Elaboração de PGR, PCMSO LTCAT"}>Elaboração de PGR, PCMSO LTCAT</option>
                                    </select>
                                </div>
                                                <button onClick={() => {
                                                    let url = `/credentials`
                                                    if(state){
                                                        if(url != `/credentials`){
                                                            url = url + `&state=${state}`
                                                        }else{
                                                            url = url + `?state=${state}`
                                                        }
                                                        if(city){
                                                            url = url + `&city=${city}`
                                                        }
                                                    }
                                                    if(service){
                                                        if(url != `/credentials`){
                                                            url = url + `&service=${service}`
                                                        }else{
                                                            url = url + `?service=${service}`
                                                        }
                                                    }
                                                    navigate(url)
                                                }}>
                                                    Buscar
                                                </button>
                            </div>

                            <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 20, marginTop: 30 }}>
                                <h1 style={{ margin: 0 }}>{credentialsTotal} Credenciados</h1>
                            </div>

                {
                    loading ? (
                        <img src={loadingPhoto} style={{ background: "transparent", height: 50, width: 50, marginTop: 50 }} />
                    ) : (
                        <>
                            <List>
                                {credentials.length == 0 && (
                                    <h4 style={{ marginTop: 50, marginBottom: 50, fontWeight: 500, width: "80%", textAlign: "center" }}>Nenhum credenciado encontrado</h4>
                                )}
                                {credentials.map((item) => {
                                    return (
                                        <div style={{ maxWidth: 280 }}>
                                            <img src={item.photo ? item.photo_url : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__480.png"} style={{ width: 250, height: 250 }} />
                                            <p>{item.name}</p>
                                            <p style={{ textTransform: "capitalize", fontSize: 12, margin: "6px 0" }}>{item.description}</p>
                                            <p style={{ textTransform: "capitalize", fontSize: 15 }}>{item.city} - {item.state}</p>

                                            <div style={{ padding: 0, margin: 0, marginTop: 10 }}>
                                                <button onClick={() => {
                                                    navigate(`/credential/${item.id}`)
                                                }}>
                                                    Ver detalhes
                                                </button>
                                            </div>
                                        </div>
                                    )
                                })}
                            </List>
                            <center style={{marginTop: 15}}>
                                <Pagination simple defaultCurrent={page + 1} onChange={(page) => {
                                    let url = `/credentials`
                                    if(state){
                                        if(url != `/credentials`){
                                            url = url + `&state=${state}`
                                        }else{
                                            url = url + `?state=${state}`
                                        }
                                        if(city){
                                            url = url + `&city=${city}`
                                        }
                                    }
                                    if(service){
                                        if(url != `/credentials`){
                                            url = url + `&service=${service}`
                                        }else{
                                            url = url + `?service=${service}`
                                        }
                                    }
                                    if(url != `/credentials`){
                                        url = url + `&page=${page - 1}`
                                    }else{
                                        url = url + `?page=${page - 1}`
                                    }
                                    navigate(url)
                                }
                            } total={credentialsTotal} pageSize={30} showTotal={(total) => `${total} credenciados`}/>
                            </center>
                        </>
                    )
                }
            </div>
        </Container>
    )
} 