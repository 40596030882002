import React, { useEffect, useState } from 'react';
import { Container, Lesson, HeaderLeft, HeaderTop } from './styles';
import { useNavigate, useParams } from "react-router-dom";
import loadingPhoto from "./../../../images/loading.gif"
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { useMediaQuery } from 'react-responsive';
import { MdDownload } from 'react-icons/md';
import { useQuery } from "./../../../hooks/Location";
import HeaderCourse from '../../../components/headerCourse';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
import { useAuth } from '../../../hooks/AuthContext';

export default function CoursePublic() {

    const { user } = useAuth();

    const query = useQuery();
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1200px)'
    })

    const { course_id } = useParams()

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true)
    const [lessons, setLessons] = useState([])
    const [lesson, setLesson] = useState(0)
    const [error, setError] = useState(0)

    useEffect(() => {
        redirect()
    }, [])

    function redirect() {
        if (user.type == "admin") {
            listLessons()
        } else {
            if (user.type == "cliente" && user.course) {
                listLessons()
            } else {
                navigate(-1)
            }
        }
    }

    async function listLessons() {
        await api.get(`/${user.type == "admin" ? "admin-" : ""}course/${course_id}`).then((response) => {
            setLessons(response.data.lessons)
            let id = query.get("id")
            if (id) {
                response.data.lessons.map((item, index) => {
                    if (item.id == id) {
                        setLesson(index)
                    }
                })
            }
        }).catch(({ response }) => {
            setError("Erro Interno. verifique sua conexão e tente novamente")
            if (response) {
                if (response.data) {
                    if (response.data.message) {
                        toast.warn(response.data.message)
                        setError(response.data.message)
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            } else {
                toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
        })
            setLoading(false)
    }

    return (
        <Container>
            {
                loading ? (<img src={loadingPhoto} style={{ background: "transparent", height: 50, width: 50 }} />
                ) : (
                    <>
                        {
                            error ? <> 
                                    <HeaderCourse />
                                    <h3>{error}</h3>
                            </> : <>
                              {isDesktopOrLaptop && (<HeaderCourse />)}
                        <Lesson>
                            {
                                isDesktopOrLaptop ? (
                                    <HeaderLeft>
                                        {
                                            lessons.map((item, index) => {
                                                return (
                                                    <button onClick={() => {
                                                        setLesson(index)
                                                    }} className={lesson == index ? "active" : ""}>
                                                        Aula {index + 1}
                                                        <p>{item.name}</p>
                                                    </button>
                                                )
                                            })
                                        }
                                    </HeaderLeft>
                                ) : (
                                    <HeaderTop style={{ flexDirection: "row" }}>
                                        <button onClick={() => {
                                            if (lesson != 0) {
                                                setLesson(lesson - 1)
                                            } else {
                                                navigate(-1)
                                            }
                                        }}>
                                            <RiArrowLeftSLine size={35} color="#FFF" />
                                            {lesson == 0 ? "Sair do curso" : "Aula anterior"}
                                        </button>
                                        <div>
                                            <p style={{ fontSize: 18 }}>
                                                Aula {lesson + 1}
                                            </p>
                                            <p>{lessons[lesson].name}</p>
                                        </div>
                                        <button onClick={() => {
                                            if (lesson != lessons.length - 1) {
                                                setLesson(lesson + 1)
                                            }
                                        }}>
                                            {lesson != lessons.length - 1 &&
                                                <RiArrowRightSLine size={35} color="#FFF" />}

                                            {lesson != lessons.length - 1 && "Próxima aula"}

                                        </button>
                                    </HeaderTop>
                                )
                            }
                            <div style={isDesktopOrLaptop ? { padding: "60px" } : { maxWidth: "92%", width: "92%" }}>
                                <h3>{lessons[lesson].name}</h3>
                                {
                                    lessons[lesson].description && (
                                        <textarea id="textarea" value={lessons[lesson].description} />
                                    )
                                }
                                {
                                    lessons[lesson].video && (
                                        <iframe
                                            style={isDesktopOrLaptop ? { width: "856px", height: "452px" } : { width: "100%", height: "calc(92vw / 2)" }}
                                            src={lessons[lesson].video}
                                            frameborder='0'
                                            allow='autoplay; encrypted-media; fullscreen;'
                                            allowfullscreen
                                            title='video'
                                        />
                                    )
                                }
                                {
                                    lessons[lesson].file_url && (
                                        <div style={{ width: "100%", marginTop: 50, maxHeight: 100 }}
                                            onClick={() => {
                                                window.open(lessons[lesson].file_url)
                                            }}>
                                            <div style={{ position: "relative", cursor: "pointer", backgroundColor: "#f2760a", width: isDesktopOrLaptop ? "400px" : "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: 10, borderRadius: 10 }}>
                                                <p style={{ color: "#fff", marginBottom: 10, textAlign: "center" }}>{lessons[lesson].file_name ? lessons[lesson].file_name : "Baixar arquivo"}</p>
                                                <MdDownload size={25} color="#fff" />
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </Lesson>
                            </>
                                
                        }
                       
                    </>

                )
            }


        </Container>
    )
} 