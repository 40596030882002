import styled from "styled-components";

export const Container = styled.div`
  width: 90%; 
  max-width: 800px;
  align-items: center;
  min-height: 100vh;
  display: flex;
  padding: 40px 0;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  h1{
    width: 100%;
    text-align: center;
    margin-bottom: 35px;
    color: #f2760a;
  }
  h2{
  color: #000;
  font-size: 18px;
  width: 100%;
  text-align: left;
  margin: 10px 0;
  font-weight: bold;
}

h3{
  color: #999;
  font-size: 15px;
  width: 100%;
  text-align: left;
  margin: 0 0 5px 0;
  font-weight: bold;
}
  @media (max-width: 750px) {
  padding: 30px 0;
  width: 95%;
  h1{
    text-align: center;
    font-size: 22px;
    margin-bottom: 15px;
  }

  }
`;