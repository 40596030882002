import React, { useEffect, useState } from 'react';
import { Container, List, Saldo } from './styles';
import { useNavigate } from "react-router-dom";
import { useAuth } from '../../../hooks/AuthContext';
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { addDays, format } from 'date-fns';
import Status from '../../../components/status';
import loadingPhoto from "./../../../images/loading.gif"
import { TbUrgent } from 'react-icons/tb';

export default function FinanceiroFranqueados() {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true)
    const { user } = useAuth();
    const [orders, setOrders] = useState([])
    const [totalServices, setTotalServices] = useState(0)
    const [totalValue, setTotalValue] = useState(0)
    const [startDate, setStartDate] = useState(addDays(new Date(),-7))
    const [endDate, setEndDate] = useState(new Date())


    useEffect(() => {
        loadingOSs()
    }, [startDate, endDate])

    async function loadingOSs() {
        await api
            .get(`/orders/${user.type}?startDate=${startDate}&endDate=${endDate}&finance=true`).then((response) => {
                let totalS = 0;
                let totalV = 0;
                response.data.map((item) => {
                    item.totalValue = 0
                    item.totalServices = 0
                    item.items.map((data) => {
                        totalS += data.amount
                        totalV += data.amount * data.value
                        item.totalServices += data.amount
                        item.totalValue += data.amount * data.value
                    })
                    if (item.urgent) {
                        item.totalValue = item.totalValue * 1.1
                        totalV = totalV * 1.1
                    }
                })
                setTotalServices(totalS)
                setTotalValue(totalV)
                setOrders(response.data)
            }).catch(({ response }) => {
                if (response) {
                    if (response.data) {
                        if (response.data.message) {
                            toast.warn(response.data.message)
                        } else {
                            toast.error("Erro Interno. verifique sua conexão e tente novamente")
                        }
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            })
        setLoading(false)
    }

    return (
        <Container>
            {
                loading ? (<img src={loadingPhoto} style={{ background: "transparent", height: 50, width: 50 }} />

                ) : (
                    <>
                        <h1>Minhas Finanças</h1>
                        <p style={{ margin: "20px 0 10px 0" }}>
                                Altere  o periodo
                            </p>
                        <div style={{ width: 300, display: "flex", justifyContent: "space-between" }}>
                            <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                                de 
                                <input type="date" value={format(startDate, "yyyy-MM-dd")} onChange={(date) => {
                                let dateE = date.target.value.split("-")
                                setStartDate(new Date(dateE[0], dateE[1]-1, dateE[2]))
                            }} /> 
                            </div>
                            <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                                até
                            <input type="date" min={format(startDate, "yyyy-MM-dd")} max={format(new Date(), "yyyy-MM-dd")} value={format(endDate, "yyyy-MM-dd")} onChange={(date) => {
                                let dateE = date.target.value.split("-")
                                setEndDate(new Date(dateE[0], dateE[1]-1, dateE[2]))
                            }} />
                            </div>
                        </div>
                        <Saldo>
                            <p>
                                Foram solicitados {totalServices} serviços nesse periodo
                            </p>
                            <h2>{(totalValue).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</h2>
                        </Saldo >

                        <List>
                            {orders.map((item) => {
                                return (
                                    <div>
                                        <div>
                                            <p style={{ display: "flex", alignItems: "center" }}><a
                                                style={{ cursor: "pointer", margin: 0, marginRight: 5 }}
                                                onClick={() => {
                                                    navigate("/clientes/os/" + item.id)
                                                }}>{item.name ? item.name + " - " + ("0000" + item.id).slice(-4) : ("0000" + item.id).slice(-4)}</a>{" - " + item.totalServices} serviços solicitados {item.urgent && (<div style={{ marginLeft: 5, backgroundColor: "#eb4034", height: 25, width: 25, padding: 2, borderRadius: 5 }}><TbUrgent color="#FFF" size={22} /></div>)}</p>

                                            <Status status={item.status} urgent={item.urgent} />
                                            <p style={{ display: "flex", alignItems: "center", marginTop: 5, fontSize: 12 }}>Finalizado em {format(new Date(item.update_at), "dd/MM/yyyy HH:mm")}</p>
                                        </div>
                                        <h1>{(item.totalValue).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</h1>
                                    </div>
                                )
                            })}
                        </List>   </>
                )
            }


        </Container>
    )
} 