import React from "react";
import { Container } from "./styles";
import logo from "../../images/logo.jpeg"

export default function HeaderCredential({ logout }) {

    return (
        <Container>
            <img src={logo} />
            <div>
                <a onClick={() => {
                    logout()
                }}>Sair</a>
            </div>
        </Container>
    )

}