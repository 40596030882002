import React, { useState } from "react";
import { StatusDiv } from "./styles";
import { MdOutlineWatchLater, MdOutlineClose, MdOutlineHdrAuto, MdCheckCircleOutline, MdOutlineNoteAdd } from "react-icons/md"

export default function Status({ status }) {

    switch (status) {
        case "aberto": {
            return (
                <StatusDiv>
                    <MdOutlineHdrAuto size={22} color="#1860b8" />
                    <span style={{ color: "#1860b8" }}>Aguardando Técnico</span>
                </StatusDiv>
            );
            break;
        };
        case "andamento": {
            return (
                <StatusDiv>
                    <MdOutlineWatchLater size={22} color="#363636" />
                    <span style={{ color: "#363636" }}>Em andamento</span>
                </StatusDiv>
            );
            break;
        };
        case "pendente": {
            return (
                <StatusDiv>
                    <MdOutlineNoteAdd size={22} color="#c23e19" />
                    <span style={{ color: "#c23e19" }}>Documentação pendente</span>
                </StatusDiv>
            );
            break;
        };
        case "validacao": {
            return (
                <StatusDiv>
                    <MdOutlineNoteAdd size={22} color="#ba9c04" />
                    <span style={{ color: "#ba9c04" }}>Aguardando Aprovação</span>
                </StatusDiv>
            );
            break;
        };
        case "alteracao": {
            return (
                <StatusDiv>
                    <MdOutlineNoteAdd size={22} color="#ba9c04" />
                    <span style={{ color: "#ba9c04" }}>Alteração Solicitada</span>
                </StatusDiv>
            );
            break;
        };
        case "finalizado": {
            return (
                <StatusDiv>
                    <MdCheckCircleOutline size={22} color="#11b83d" />
                    <span style={{ color: "#11b83d" }}>Finalizado</span>
                </StatusDiv>
            );
            break;
        };
        case "cancelado": {
            return (
                <StatusDiv>
                    <MdOutlineClose size={22} color="#bd2424" />
                    <span style={{ color: "#bd2424" }}>Cancelado</span>
                </StatusDiv>
            );
            break;
        };
        case "recusado": {
            return (
                <StatusDiv>
                    <MdOutlineClose size={22} color="#bd2424" />
                    <span style={{ color: "#bd2424" }}>Recusado</span>
                </StatusDiv>
            );
            break;
        };
        default: {
            return (
                <StatusDiv>
                    <MdOutlineClose size={22} color="#363636" />
                    <span style={{ color: "#363636" }}>Outro</span>
                </StatusDiv>
            );
        };
    }



}