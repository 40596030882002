import React, { useEffect, useState } from 'react';
import { Container, List } from './styles';
import { useParams, useNavigate } from "react-router-dom";
import loadingPhoto from "./../../../images/loading.gif"
import api from '../../../services/api';
import { useAuth } from '../../../hooks/AuthContext';
import { toast } from 'react-toastify';
import Status from '../../../components/status';
import { TbUrgent } from 'react-icons/tb';
import { addYears, format } from 'date-fns';

export default function SolicitacoesFranqueados() {

    const navigate = useNavigate();
    const { user } = useAuth();
    const [loading, setLoading] = useState(true)
    const [orders, setOrders] = useState([])

    useEffect(() => {
        loadingOSs()
    }, [])

    async function loadingOSs() {
        await api.get(`/orders/${user.type}?startDate=${addYears(new Date(), -10)}&endDate=${new Date()}`).then((response) => {
            response.data.map((item) => {
                item.totalValue = 0
                item.totalServices = 0
                item.items.map((data) => {
                    item.totalServices += data.amount
                    item.totalValue += data.amount * data.value
                })
            })
            setOrders(response.data)
        }).catch(({ response }) => {
            if (response) {
                if (response.data) {
                    if (response.data.message) {
                        toast.warn(response.data.message)
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            } else {
                toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
        })
        setLoading(false)
    }

    return (
        <Container>
            {
                loading ? (<img src={loadingPhoto} style={{ background: "transparent", height: 50, width: 50 }} />

                ) : (
                    <>
                        <h1>Minhas Solicitações</h1>
                        <List>
                            {orders.length == 0 && (
                                <h4 style={{ marginTop: 50, fontWeight: 500, width: "80%", textAlign: "center" }}>Nenhuma Ordem de Serviço encontrada</h4>
                            )}
                            {orders.map((item) => {
                                return (
                                    <div>
                                        <div>
                                            <p style={{ display: "flex", alignItems: "center" }}>{item.name ? item.name + " - " + ("0000" + item.id).slice(-4) : ("0000" + item.id).slice(-4)} - {item.totalServices} serviços solicitados {item.urgent && (<div style={{ marginLeft: 5, backgroundColor: "#eb4034", height: 25, width: 25, padding: 2, borderRadius: 5 }}><TbUrgent color="#FFF" size={22} /></div>)}</p>
                                            <Status status={item.status} urgent={item.urgent} />
                                            <p style={{ display: "flex", alignItems: "center", marginTop: 5, fontSize: 12 }}>Última atualização: {format(new Date(item.update_at), "dd/MM/yyyy HH:mm")}</p>
                                        </div>
                                        <button onClick={() => {
                                            navigate("/clientes/os/" + item.id)
                                        }}>
                                            Ver detalhes
                                        </button>
                                    </div>
                                )
                            })}
                        </List>
                    </>
                )
            }



        </Container >
    )
} 