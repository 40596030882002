import React, { useEffect, useState } from 'react';
import { Container, Franqueado } from './styles';
import { useNavigate } from "react-router-dom";
import { useAuth } from '../../../hooks/AuthContext';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import { useMediaQuery } from 'react-responsive';
import { Carousel } from 'react-responsive-carousel';

export default function HomeFranqueados() {
    const user = useAuth();

    const [banners, setBanners] = useState([])

    const navigate = useNavigate();
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })

    useEffect(() => {
        loadingBanners()
    }, [])

    async function loadingBanners() {
        await api.get(`/banners-public?type=tecnico`).then((response) => {
            setBanners(response.data)
        }).catch(({ response }) => {
            if (response) {
                if (response.data) {
                    if (response.data.message) {
                        toast.warn(response.data.message)
                    } else {
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            } else {
                toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
        })
    }

    return (
        <Container>
            <h1>
                Seja bem-vindo, {user.user.name}
            </h1>
            {banners.length != 0 && (
                <div style={{ margin: isDesktopOrLaptop ? "25px 0" : "0px 15px", cursor: "pointer", height: isDesktopOrLaptop ? "25vw" : "33vw", width: isDesktopOrLaptop ? "75vw" : "100vw" }} >
                    <Carousel
                        showThumbs={false}
                        autoPlay={true}
                        interval={5000}
                        infiniteLoop={true}
                        showArrows={true}
                        showStatus={false}
                        centerMode="100%"
                        selectedItem={0}
                        onClickItem={(idx) => {
                            window.location.assign(banners[idx].url);
                        }}
                    >
                        {banners.map((item) => {
                            return (
                                <img src={item.photo_url} style={{ height: isDesktopOrLaptop ? "25vw" : "33vw", width: "100%", borderRadius: isDesktopOrLaptop ? 10 : 0, objectFit: "contain" }} />
                            )
                        })}
                    </Carousel>

                </div>
            )}
            <h3 style={{ marginTop: 25 }}>Navegue pelo sistema e solicite suas OS </h3>
            <a onClick={() => {
                navigate(`/tecnicos/solicitacoes`)
            }}>Minhas OSs</a>
            <a onClick={() => {
                navigate(`/tecnicos/financeiro`)
            }}>Financeiro</a>
            <a onClick={() => {
                navigate(`/tecnicos/open`)
            }}>OSs em Aberto</a>
        </Container >
    )
} 