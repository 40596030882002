import React, { useEffect, useState } from 'react';
import { Container } from './styles';
import { useParams } from "react-router-dom";
import loadingfile from "./../../../images/loading.gif"
import api from '../../../services/api';
import { toast } from 'react-toastify';
import HeaderPublic from '../../../components/headerPublic';


export default function Credential() {

    const [loading, setLoading] = useState(true)
    const [credential, setCredential] = useState({})
    const [error, setError] = useState("")

    const { id } = useParams()

    useEffect(() => {
        loadingCredential()
    }, [])

    async function loadingCredential() {
        await api.get(`/credential/${id}`).then((response) => {
            let credential = response.data
            setCredential(credential)
        }).catch(({ response }) => {
            if (response) {
                if (response.data) {
                    if (response.data.message) {
                        toast.warn(response.data.message)
                        setError(response.data.message)
                    } else {
                        setError("Erro Interno. verifique sua conexão e tente novamente")
                        toast.error("Erro Interno. verifique sua conexão e tente novamente")
                    }
                } else {
                    setError("Erro Interno. verifique sua conexão e tente novamente")
                    toast.error("Erro Interno. verifique sua conexão e tente novamente")
                }
            } else {
                setError("Erro Interno. verifique sua conexão e tente novamente")
                toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
        })
        setLoading(false)
    }



    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <HeaderPublic />
            <Container>
                {
                    loading ? (
                        <img src={loadingfile} style={{ background: "transparent", height: 50, width: 50 }} />
                    ) : (
                        <>
                            {
                                error ? (

                                    <h2 style={{ textAlign: "center", marginTop: 100 }}>{error}</h2>
                                ) : (
                                    <>

                                        <img src={credential.photo ? credential.photo_url : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__480.png"} style={{ width: 250, height: 250, margin: "25px 0", borderRadius: 10 }} />
                                        <p style={{ fontWeight: "500", fontSize: 24, textAlign: "center", maxWidth: 600 }}>{credential.name}</p>
                                        <p style={{ fontSize: 16, margin: "12px 0", textAlign: "center", maxWidth: 600 }}>{credential.description}</p>
                                        <p style={{ fontSize: 18, textAlign: "center", maxWidth: 600 }}>{credential.email}</p>
                                        <p style={{ fontSize: 18, margin: "8px 0", textAlign: "center", maxWidth: 600 }}>{credential.phone_number}</p>
                                        <p style={{ textTransform: "capitalize", fontSize: 20, textAlign: "center", maxWidth: 600 }}>{credential.city} - {credential.state}</p>
                                        <p style={{ fontSize: 16, margin: "8px 0", textAlign: "center", maxWidth: 600 }}>Cidades Atendidas</p>

                                        <div style={{ display: "flex", width: "60%", flexWrap: "wrap", justifyContent: "center" }}>
                                            {
                                                credential.served_cities.split(";").map((item) => {
                                                    if (item) {
                                                        return (
                                                            <div style={{ borderRadius: 5, fontSize: 14, display: "flex", justifyContent: "center", alignItems: "center", margin: "5px", padding: "5px 12px", background: "#fff" }}>
                                                                {item}
                                                            </div>
                                                        )
                                                    }

                                                })
                                            }
                                        </div>
                                        <p style={{ fontSize: 16, margin: "8px 0", textAlign: "center", maxWidth: "60%", }}>Serviços</p>
                                        <div style={{ display: "flex", width: "60%", flexWrap: "wrap", justifyContent: "center" }}>
                                            {
                                                credential.services.split(";").map((item) => {
                                                    if (item) {
                                                        return (
                                                            <div style={{ borderRadius: 5, fontSize: 14, display: "flex", justifyContent: "center", alignItems: "center", margin: "5px", padding: "5px 12px", background: "#fff" }}>
                                                                {item}
                                                            </div>
                                                        )
                                                    }

                                                })
                                            }

                                        </div>
                                    </>
                                )
                            }

                        </>
                    )
                }

            </Container>
        </div>
    )
} 